import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Countdown } from '@/components/library/Countdown'
import { calculateAuctionStatus, getEndTime } from "@/components/AuctionManagement/Utils";
import { getNewDateWithOffset } from 'prembid-shared-library-npm/helpers';
import { Typography } from '@mui/material';

interface TimeRemainingCountdownProps {
    isActive: boolean;
    startDateTimeAt: string;
    endDateTimeAt: string;
    extendedBy: number;
    onComplete: () => void;
    dateTimeOffset: number;
}

const TimeRemainingCountdown: FC<TimeRemainingCountdownProps> = ({ isActive, startDateTimeAt, endDateTimeAt, extendedBy, onComplete, dateTimeOffset }) => {
    const [lastRefreshed, setLastRefreshed] = useState(new Date().getTime());
    const status = useMemo(() => lastRefreshed ? calculateAuctionStatus(isActive, startDateTimeAt, endDateTimeAt, extendedBy, dateTimeOffset) : undefined,
    [isActive, startDateTimeAt, endDateTimeAt, extendedBy, dateTimeOffset, lastRefreshed]);

    const [previousStatus, setPreviousStatus] = useState<string>();

    const CountdownRenderer = useCallback(({ days, hours, minutes, seconds }) => {
        let text = `${days}D ${hours}H ${minutes}M ${seconds}S`;

        return <Typography variant="subtitle2" style={{ minWidth: text.length + 'ch' }}>{text}</Typography>
    }, [])

    return <Countdown
        date={getEndTime(endDateTimeAt, extendedBy)}
        renderer={CountdownRenderer}
        onComplete={() => {
            if (status !== 'Completed') setTimeout(() => {
                const newStatus = calculateAuctionStatus(isActive, startDateTimeAt, endDateTimeAt, extendedBy, dateTimeOffset);
                if (newStatus === 'Completed') onComplete();
                else { setLastRefreshed(new Date().getTime()) }
            }, 500)
        }}
        now={() => getNewDateWithOffset(dateTimeOffset).getTime()}
    />
}

export default memo(TimeRemainingCountdown)