import React, { useState } from "react";
import { Button, Tooltip, Table, CircularProgress, Switch, FormControlLabel } from '@mui/material';
import { connect } from "react-redux";
import { FaFlag, FaPlus, FaRedo } from "react-icons/fa";
import _ from 'lodash'
import { handleRefreshRegisteredProfile, invalidateRegistrationProcessDocument, requestAdditionalRegistrationProcessDocuments } from "./store/AuctionManagement";
import { downloadFile } from "@/pages/Documents/store/Documents";
import TextConfirmationDialog from "../../components/Modal/TextConfirmationDialog";
import DocumentTemplateAddDialog from "../../components/Modal/DocumentTemplateAddDialog";
import { DocumentTypeEnum } from "../../helpers/DocumentTypeEnum";
import { AiOutlineFileText } from "react-icons/ai";
import { hideProgressSpinner, showProgressSpinner } from "../../helpers/ProgressSpinnerService";
import InvalidateConfirmation from "../../components/Modal/InvalidateConfirmationDialog";
import ns from "../../helpers/NotificationService";
import { apiPaths } from "../../api/ApiPaths";
import { arrayBufferToBase64, formatDateTime } from "../../helpers/Utils";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
    registrationProcessDocuments: any[],
    settings: any,
    handleRefreshRegisteredProfile_d: (id: string, onCompletedCallback?: (res: any) => void) => void,
    invalidateRegistrationProcessDocument: (data: any, onCompletedCallback?: (res: any) => void) => void,
    requestAdditionalRegistrationProcessDocuments: (data: any, onCompletedCallback?: (res: any) => void) => void,
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
    auctionId: string,
    registrationId: string,
    profileId: string,
    deleted?: boolean
}

const DocumentsGrid: React.FC<Props> = props => {
    const {
        registrationProcessDocuments,
        settings,
        invalidateRegistrationProcessDocument,
        requestAdditionalRegistrationProcessDocuments,
        handleRefreshRegisteredProfile_d,
        auctionId,
        registrationId,
        profileId,
        deleted = false,
        downloadFile_d
    } = props

    const [documents, setDocuments] = useState<any[]>([]);
    const [reloading, setReloading] = useState<boolean>(false);

    const [invalidateModalState, setInvalidateModalState] = useState<any>({ open: false })
    const [documentTemplateModal, setDocumentTemplateModal] = useState<any>({
        open: false,
        id: ''
    })

    React.useEffect(() => {
        let tempDocs = registrationProcessDocuments?.map(doc => {
            return {
                ...doc,
                type: doc.type.replace('Auction', settings.AuctionName).replace('Lot', settings.LotName),
                date: doc.date,
            }
        }) || [];

        let sortedList = _.orderBy(tempDocs, ['documentTemplateName', 'date', (doc) => doc.status === "Document Invalidated" ? 0 : 1], ['asc', 'desc', 'desc'])

        setDocuments(sortedList);
    }, [registrationProcessDocuments])


    const renderDocumentStatus = (status: string, date: string | undefined) => {
        let color = 'success';

        switch (status) {
            case "Awaiting Acceptance":
            case "Awaiting Upload": color = 'warning'; break;
            case "Document Invalidated": color = 'danger'; break;
        }

        return <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                <div className={`badge badge-${color} m-1 badge-circle mr-2`} style={{ minWidth: 10 }}>Placeholder</div>
                {status}
            </div >
            {date && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: '0.6em', marginLeft: 24 }}>
                {formatDateTime(date, 'yyyy/MM/dd HH:mm')}
            </div>}
        </>
    }

    const requestAdditionalDocument = () => {
        setDocumentTemplateModal({ open: true });
    }

    const handleViewDocument = (url: string) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
        })
    }

    const onCancelDocumentTemplateModal = () => {
        setDocumentTemplateModal({ open: false });
    }

    const onAddDocumentTemplateModal = (selectedDocumentTemplates) => {
        showProgressSpinner({ description: "Requesting additional documents..." })

        let addingDocuments = selectedDocumentTemplates.map(doc => doc.id);

        if (addingDocuments.length > 0)
            requestAdditionalRegistrationProcessDocuments({
                auctionId,
                profileId,
                documentTemplateIds: addingDocuments,
            }, (res: any) => {
                if (res.success) {
                    if (res.response) ns.success("Successfully requested the additional documents")
                    else ns.error("Failed to request additional documents. These documents have already been requested or have not been marked as upload required or acceptance mandatory.")
                }
                else {
                    ns.error("Failed to request additional documents. " + res?.error ?? "")
                }
                handleReload();
            })

        onCancelDocumentTemplateModal();
    }


    const handleInvalidateDocument = (data: any, payload) => {
        showProgressSpinner({ description: "Invalidating document..." })
        invalidateRegistrationProcessDocument({
            ...payload,
            ...data,
            auctionId,
            profileId
        }, (res: any) => {
            setInvalidateModalState({ open: false })
            handleReload();
        })
    }

    const handleReload = () => {
        setReloading(true);

        handleRefreshRegisteredProfile_d(registrationId, () => {
            setReloading(false);
            hideProgressSpinner();
        })
    }

    const [showHistory, setShowHistory] = useState<boolean>(false);

    return <div>
        {documentTemplateModal.open && <DocumentTemplateAddDialog documentType={DocumentTypeEnum.Auction} open={documentTemplateModal.open} payload={{
            auctionId,
            profileId
        }} okButtonText="Ok" cancelButtonText="Cancel" description="Select document you want to add" title="Documents" onCancel={onCancelDocumentTemplateModal} onOk={onAddDocumentTemplateModal} />}
        {invalidateModalState?.open && <InvalidateConfirmation open={invalidateModalState.open} okButtonText="Ok" cancelButtonText="Cancel" title="Invalidate document" onCancel={() => setInvalidateModalState({ open: false })} onOk={handleInvalidateDocument} payload={invalidateModalState.payload} />}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <div style={{ paddingBottom: 6, paddingRight: 10 }}>
                <FormControlLabel label='Show History' labelPlacement="start" control={<Switch checked={showHistory} onChange={(e, checked) => setShowHistory(checked)} className="m-2 p-2 switch-medium toggle-switch-success" />} />
            </div>
            <Tooltip title="Refresh Documents">
                <LoadingButton loading={reloading} disabled={reloading} onClick={handleReload} variant="text" className={"btn-outline-primary d-40 rounded-pill ml-2 mb-2"}>
                    <FaRedo />
                </LoadingButton>
            </Tooltip>
            <Tooltip title={deleted ? "Additional documents cannot be requested on a deleted profile" : "Request an additional document"}>
                <span>
                    <Button onClick={requestAdditionalDocument} variant="text" disabled={deleted} className={"btn-outline-primary d-40 rounded-pill ml-2 mb-2"}>
                        <FaPlus />
                    </Button>
                </span>
            </Tooltip>
        </div>
        <div style={{ display: 'block', width: '100%', height: '300px', overflowY: 'scroll' }}>
            <Table className={'table table-bordered'} style={{ tableLayout: 'fixed', width: '100%' }}>
                <thead className="thead-light">
                    <tr>
                        <th colSpan={3}>Name</th>
                        <th colSpan={3}>Type</th>
                        <th colSpan={2}>Status</th>
                        <th colSpan={2}>Notes</th>
                        <th colSpan={1}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.filter(x => showHistory || x.status !== 'Document Invalidated').map((document, idx) => {
                        return (
                            <tr key={idx}>
                                <td colSpan={3}>{document.documentTemplateName}</td>
                                <td colSpan={3}>{document.type}</td>
                                <td colSpan={2}>{renderDocumentStatus(document.status, document.date)}</td>
                                <td colSpan={2}>{document.notes?.map((note, index) => <div key={index}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                                        {note.Note}
                                    </div >
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: '0.6em' }}>
                                        {formatDateTime(note.CreatedAt, 'yyyy/MM/dd HH:mm')}
                                    </div>
                                </div>)}</td>
                                <td colSpan={1}>
                                    {document.documentUrl && <>
                                        <Tooltip title={"View document"}>
                                            <Button onClick={() => handleViewDocument(document.documentUrl)} variant="text" className="btn-outline-primary align-items-center justify-content-center d-40 rounded-pill ml-2">
                                                <AiOutlineFileText />
                                            </Button>
                                        </Tooltip>
                                        {document.status !== "Document Invalidated" && <Tooltip title={"Invalidate this document"}>
                                            <Button onClick={() => setInvalidateModalState({
                                                open: true,
                                                payload: { id: document.id }
                                            })} variant="text" className="btn-outline-primary align-items-center justify-content-center d-40 rounded-pill ml-2">
                                                <FaFlag />
                                            </Button>
                                        </Tooltip>}
                                    </>}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table></div>
    </div>
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRefreshRegisteredProfile_d: (id: string, onCompletedCallback?: (res: any) => void) => dispatch(handleRefreshRegisteredProfile(id, onCompletedCallback)),
        invalidateRegistrationProcessDocument: (data: any, onCompletedCallback?: (res: any) => void) => dispatch(invalidateRegistrationProcessDocument(data, onCompletedCallback)),
        requestAdditionalRegistrationProcessDocuments: (data: any, onCompletedCallback?: (res: any) => void) => dispatch(requestAdditionalRegistrationProcessDocuments(data, onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsGrid);