import { Lot } from 'prembid-shared-library-npm/types';

export type LotsBidRows = {
    [lotId: string]: BidRow[]
}

export type BidRow = {
    bidId: string,
    name: string,
    contact: string,
    paddleNumber: string,
    date: string,
    time: string,
    amount: number,
    auctionId: string,
    maxBidAmount?: number,
    maxBidCreatedAt?: string;
    type?: string;
}

export type ExtendLotModalState = {
    lotId?: string;
    isOpen: boolean;
}

export type BidDeleteModalState = {
    lotId?: string;
    lotName?: string;
    bidId?: string;
    isOpen: boolean;
}

export type BidEditModalState = {
    lot?: Lot;
    bidId?: string;
    paddleNumber?: string;
    bidAmount?: number;
    minAllowedBidAmount?: number;
    isOpen: boolean;
}

export type TopBidderState = {
    amount?: string | number | null;
    bidder?: string | null;
    name?: string
}

export enum BidTableAction {
    Clear = "clear",
    Replace = "replace",
    Upsert = "upsert",
    RemoveBid = "removeBid",
    UpdateBidAmount = "updateBidAmount",
}

export type LockedLots = {
    [lotId: string]: boolean
}

export type ChangedLots = {
    [lotId: string]: boolean
}

export type LotsExtendedBy = {
    [lotId: string]: number
}

export type LotsAskingBids = {
    [lotId: string]: number
}

export type CurrentLotReserves = {
    [lotId: string]: number
}

export type RemoteLotsBidsLoaded = {
    [lotId: string]: boolean
}

export type IncrementTable = {
    incrementTableId?: Lot["incrementTableId"],
    incrementTableName?: Lot["incrementTableName"],
    incrementTableDataCaptured?: Lot["incrementTableDataCaptured"],
};

export type LotsIncrementTables = {
    [lotId: string]: IncrementTable
}

export type ExtensionDefaultTimes = {
    hour: number;
    min: number;
    sec: number;
}

export type PopoverPosition = {
    top: number;
    left: number;
}