import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
    return ({
        container: {
            flex: 1,
        },
    })
});

export default useStyles;