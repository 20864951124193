﻿import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

const initialState = {
    error: false,
    errorText: false,
    success: false
}
 
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case REGISTER_SUCCESS: return { ...state, success: true }
        case REGISTER_ERROR: return { ...state, error: true, errorText: action.payload }
        default: return state;
    }
}

//Action creator 

//Taking a token or else reducer does not work for some reason.
export const registerSuccess = (token: string) => {
    return { type: REGISTER_SUCCESS, payload: token }
}

export const registerError = (errorText: string) => {
    return { type: REGISTER_ERROR, payload: errorText }
}

//Thunks

export const handleSubmit = (email: string, phoneNumber: string) => {

    let userInfo = {
        email: email,
        phoneNumber: phoneNumber,
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.register, userInfo)
            .then(function (response) {
                if (response.success) {
                    dispatch(registerSuccess('Success'))
                }
                else {
                    dispatch(registerError(response.error))
                }
            })
    }

}