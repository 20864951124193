import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Actions
const ADD_SUCCESS = 'ADD_SUCCESS';
const SAVE_ERROR = 'SAVE_ERROR';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const FETCH_ERROR = 'FETCH_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const SET_USER = 'SET_USER';
const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
const USERS_FETCH_WITH_PAGINATION_SUCCESS = 'USERS_FETCH_WITH_PAGINATION_SUCCESS';
const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
const CLEAR_USER = 'CLEAR_USER';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';

const initialState = {
    error: false,
    errorText: '',
    success: false,
    reload: false,
    users: undefined,
    user: undefined,
    userId: undefined,
    userFilter: {}
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        //add/edit
        case ADD_SUCCESS: return { ...state, success: true, userId: action.payload }
        case SAVE_ERROR: return { ...state, error: true, errorText: action.payload }
        case UPDATE_USER_SUCCESS: return { ...state, loading: false, errorText: '', reload: !state.reload, success: true }

        //get
        case FETCH_ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_ERROR: return { ...state, error: true, errorText: '' }
        case DELETE_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload }
        case SET_USER: return { ...state, error: false, errorText: '', user: action.payload }
        case USERS_FETCH_SUCCESS: return { ...state, error: false, errorText: '', users: action.payload, success: false, user: undefined, userId: undefined }
        case USERS_FETCH_WITH_PAGINATION_SUCCESS: return { ...state, users: action.payload.list, pagination: action.payload.pagination }
        case USER_FETCH_SUCCESS: return { ...state, error: false, errorText: '', user: action.payload, success: false }

        //ui
        case CLEAR_USER: return { ...state, user: undefined }
        case CLEAR_API_RESPONSES: return { ...state, success: false, error: false }
        default: return state;
    }
}


//Action creator
export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const addSuccess = (userId: any) => {
    return { type: ADD_SUCCESS, payload: userId }
}

export const saveError = (errorText: string) => {
    return { type: SAVE_ERROR, payload: errorText }
}

export const error = (errorText: string) => {
    return { type: FETCH_ERROR, payload: errorText }
}

export const clearError = () => {
    return { type: CLEAR_ERROR, payload: CLEAR_ERROR }
}

export const deleteSuccess = (success: string) => {
    return { type: DELETE_SUCCESS, payload: success }
}

export const usersGetSuccess = (users: any) => {
    return { type: USERS_FETCH_SUCCESS, payload: users }
}

export const usersGetSuccessWithPagination = (payload: any) => {
    return { type: USERS_FETCH_WITH_PAGINATION_SUCCESS, payload }
}

export const userGetSuccess = (user: any) => {
    return { type: USER_FETCH_SUCCESS, payload: user }
}

export const setUser = (user: any) => {
    return { type: SET_USER, payload: user }
}

export const userUpdateSuccess = () => {
    return { type: UPDATE_USER_SUCCESS, payload: UPDATE_USER_SUCCESS }
}

export const clearUser = () => {
    return { type: CLEAR_USER, payload: CLEAR_USER }
}

export const handleUsersFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.account.retrieveAllAccounts, null, null)
            .then(function (res) {
                if (res.success) {
                    const jsonData = res.response
                    dispatch(usersGetSuccess(jsonData))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleUsersFetchWithPagination = (filters?: any, pagination?: any, onCompletedCallback?: (response: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.retrieveAllAccountsWithPagination, {
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(usersGetSuccessWithPagination(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleUserFetch = (id: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.account.retrieveAccount, null, { userId: id })
            .then(function (res) {
                if (res.success) {
                    const user = res.response
                    dispatch(userGetSuccess(user))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleUserAdd = (user: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {

        return requestClient.post(apiPaths.account.addUser, user)
            .then(function (res) {

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleUserUpdate = (user: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.updateUser, user)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleResendConfirmAccountEmail = (userId: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {

        let user = {
            id: userId,
        }

        return requestClient.post(apiPaths.account.resendConfirmAccountEmail, user)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleResendAdminConfirmAccountEmail = (userId: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.resendAdminConfirmAccountEmail, { userId })
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}