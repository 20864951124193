import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const CREATE_SUCCESS = 'IMAGE_CREATE_SUCCESS';
export const UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS';
export const DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const GET_SUCCESS = 'IMAGE_GET_SUCCESS';
export const GET_ALL_SUCCESS = 'IMAGE_GET_ALL_SUCCESS';
export const SAVE_ERROR = 'IMAGE_SAVE_ERROR';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';

const initialState = {
    error: false,
    errorText: '',
    success: false,
    lotImage: undefined,
    lotImages: [] as any
}

//Reducer
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case CREATE_SUCCESS: return { ...state, success: true }
        case UPDATE_SUCCESS: return { ...state, success: true }
        case DELETE_SUCCESS: return { ...state, success: true }
        case GET_SUCCESS: return { ...state, success: true, lotImage: action.payload }
        case GET_ALL_SUCCESS: return { ...state, success: true, lotImages: action.payload }
        case CLEAR_IMAGES: return { ...state, success: true, lotImages: undefined }
        case SAVE_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        default: return state;
    }
}

//Action creator
export const createSuccess = () => {
    return { type: CREATE_SUCCESS, payload: CREATE_SUCCESS }
}

export const updateSuccess = () => {
    return { type: UPDATE_SUCCESS, payload: UPDATE_SUCCESS }
}

export const deleteSuccess = () => {
    return { type: DELETE_SUCCESS, payload: DELETE_SUCCESS }
}

export const retrieveSuccess = (file) => {
    return { type: GET_SUCCESS, payload: file }
}

export const retrieveAllSuccess = (files) => {
    return { type: GET_ALL_SUCCESS, payload: files }
}

export const saveError = (errorText: string) => {
    return { type: SAVE_ERROR, payload: errorText }
}

export const clearImages = (errorText: string) => {
    return { type: CLEAR_IMAGES, payload: CLEAR_IMAGES }
}

//Thunks
//export const handleCreate = (lotId: string, isHeroImage: boolean, files: any[]) => {
//    return (dispatch: any) => {
//        const decodedFiles = [] as any;
//        const uploadPromises = [] as any;
//        const newFiles = files.filter(file => file.uploadedFile); //get all the new files not yet saved

//        if (newFiles.length > 0) { //check there are new files to upload
//            const decodePromise = new Promise((resolve, reject) =>
//                newFiles.forEach((fileInfo) => {
//                    if (fileInfo.uploadedFile) {
//                        const reader = new FileReader()
//                        reader.onabort = () => reject('File reading was aborted');
//                        reader.onerror = () => reject('File reading has failed');
//                        reader.onload = () => {
//                            const binaryStr = reader.result as string;
//                            const base64image = binaryStr.replace('data:*/*;base64', '');
//                            decodedFiles.push(base64image);

//                            if (newFiles.length === decodedFiles.length)
//                                resolve(decodedFiles);
//                        };
//                        reader.readAsDataURL(fileInfo.uploadedFile);
//                    }
//                })
//            );

//            decodePromise.then(function (decodedFiles: any) {
//                decodedFiles.forEach((base64image) => {
//                    uploadPromises.push(requestClient.post(apiPaths.lot.imagesCreate, { lotId: lotId, isDisplayImage: isHeroImage, lotImageUploadData: [{ imageBase64String: base64image }] }));
//                })
//                return uploadPromises;
//            }).then(function (results: any) {
//                Promise.allSettled(uploadPromises).then(function (responses) {
//                    if (responses) {
//                        (responses as unknown as any[]).forEach((result) => {
//                            if (result.status === 'rejected')
//                                dispatch(saveError(result.reason));
//                        });
//                    }
//                    dispatch(updateSuccess());
//                });
//            });
//        }
//        else
//            dispatch(updateSuccess()); //if no new files return success
//    }
//}

//export const handleDelete = (lotId: string) => {

//    let del = {
//        id: lotId
//    };

//    return (dispatch: any) => {
//        return requestClient.post(apiPaths.lot.imagesDelete, del)
//            .then(function (resp) {
//                if (resp.success) {
//                    dispatch(deleteSuccess());
//                }
//                else {
//                    dispatch(saveError(resp.error));
//                }
//            });
//    }
//}

//export const handleRetrieveAllByLotId = (lotId: string) => {

//    let getAll = {
//        lotId: lotId
//    };

//    return (dispatch: any) => {
//        return requestClient.get(apiPaths.lot.imagesRetrieveAllByLotId, null, getAll)
//            .then(function (resp) {
//                if (resp.success) {
//                    dispatch(retrieveAllSuccess(JSON.parse(resp.response)));
//                }
//                else {
//                    dispatch(saveError(resp.error));
//                }
//            });
//    }
//}