import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import {NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordionGrid';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import { Button, Tooltip } from '@mui/material';
import {  FaRedo } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";
import { clearBankDetailsState, retrieveBankDetailsList, handleBankDetailsDelete } from './store/BankDetails';

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleBankDetailsList_d: (filters: string, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleBankDetailsDelete_d: (id:string, onCompletedCallback:(res:any) => void) => void,
    clearBankDetails_d: () => void,
    bankDetails: any[],
    pagination: any,
    settings: any,
    loading: boolean
}

const BankDetails: React.FC<Props> = props => {
    const {
        handleBankDetailsList_d,
        clearBankDetails_d,
        handleBankDetailsDelete_d,
        bankDetails,
        settings,
        loading,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    });
    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingBankDetails, setLoadingBankDetails] = useState<boolean>(true);
    const [displayModel, setDisplayModel] = useState<any>();

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingBankDetails(true);
        else showProgressSpinner({ description: `Retrieving Bank Details...` });
        handleBankDetailsList_d(filters, pagination, (res:any) => {
            setLoadingBankDetails(false);
        });
    }

    useEffect(() => {
        refreshList(null, null, false);
        return (() => {
            clearBankDetails_d();
        });
    }, [])

    useEffect(() => {
        if (bankDetails) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" }
            ];

            bankDetails.forEach((item) => {
                let name = item.name?.substring(0, 35);
                if (item.name.length > 35) name += "...";

                let description = item.description?.substring(0, 50);
                if (item.description.length > 50) description += "...";

                const cells: Cell[] = [
                    { text: name, align: "inherit" },
                    { text: description, align: "inherit" },
                ];

                rows.push({
                    id: item.id,
                    cells: cells,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setDisplayModel(model);
        }

        hideProgressSpinner();
    }, [bankDetails])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const onBankDetailsAdd = () => {
        history.push('/BankDetailsAdd');
    }

    const onBankDetailsEdit = (id: any) => {
        history.push(`/BankDetailsEdit/${id}`);
    }

    const onBankDetailsDelete = (id: any) => {
        showDeleteModal(true, id);
    }

    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting Bank Details..." });
        handleBankDetailsDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Bank details deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the bank details")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    }

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateAvailableFilters = [

    ]

    return <div>
        {!loading && (<>
            <div className="card-header py-3">
                <div className="card-header--title font-size-lg">
                    Bank Details
                </div>
            </div>
            <div className="table-responsive-md">
                <NestedAccordionGrid
                    selectedFilters={selectedFilters}
                    dataModel={displayModel}
                    onDelete={onBankDetailsDelete}
                    onEdit={onBankDetailsEdit}
                    onAdd={onBankDetailsAdd}
                    pagination={pagination}
                    onFiltersChange={handleFiltersChange}
                    availableFilters={generateAvailableFilters}
                    onHandleChangePage={handlePageChange}
                    loading={loadingBankDetails}
                />
            </div>
            {deleteModal.open && <ConfirmationDialog open={deleteModal.open} payload={deleteModal.id} okButtonText="Ok" cancelButtonText="Cancel" description={"Are you sure you want to delete this bank details?"} title={"Delete Bank Details"} onCancel={onDeleteModalClosed} onOk={onDeleteModalOk} />}
        </>)}
    </div>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleBankDetailsList_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(retrieveBankDetailsList(filters, pagination, onCompletedCallback)),
        handleBankDetailsDelete_d: (id: string, onCompletedCallback: (result: any) => void) => dispatch(handleBankDetailsDelete(id, onCompletedCallback)),
        clearBankDetails_d: () => dispatch(clearBankDetailsState()),
    }
}

const mapStateToProps = (state: any) => ({
    bankDetails: state.bankDetails.bankDetails,
    pagination: state.bankDetails.pagination,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);
