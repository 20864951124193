import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline'
import { Provider } from 'react-redux';
import configureStore from './store/ConfigureStore';
import { SnackbarProvider } from 'notistack';
import { NetworkProvider } from '@/components/NetworkProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import reportWebVitals from './reportWebVitals';
import { SignalRContextProvider } from 'prembid-shared-library-npm/signalr';

import './index.css';
import { initializeLogging } from './helpers/LoggingHelper';

export const store = configureStore();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <NetworkProvider>
            <SignalRContextProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <SnackbarProvider maxSnack={5}>
                        <App />
                    </SnackbarProvider>
                </LocalizationProvider>
            </SignalRContextProvider>
        </NetworkProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

initializeLogging();