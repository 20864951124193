import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
    handleRegisteredProfileFetchWithPagination,
    handleRegisteredProfileUpdate,
    clearUsers,
    handleUpdateComplianceStatus,
    handleNotifyUserOfMissingDocuments,
    handleRefreshRegisteredProfile,
    handleRetrieveRegisteredUsersReport
} from './store/AuctionManagement';
import {
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@mui/material';
import { RegisteredProfileStatusEnum } from '@/helpers/RegisteredProfileStatusEnum';
import { FaUndo, FaTimes, FaCheck, FaRedo, FaUserPlus, FaFileCsv } from "react-icons/fa";
import TextConfirmationDialog from '@/components/Modal/TextConfirmationDialog'
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, CustomData, KeyValue } from '@/components/NestedAccordionGrid'
import {
    handleDocumentTemplatesFetch,
} from "@/pages/Documents/store/Documents";
import DocumentsGrid from '@/pages/AuctionManagement/DocumentsGrid'
import ns from '@/helpers/NotificationService';
import RegisterManualUserModal from "@/components/Modal/RegisterManualUserModal";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { AiOutlineMinus } from "react-icons/ai";
import { NotificationImportantOutlined } from "@mui/icons-material";
import { AuctionTypeEnum } from "../../helpers/AuctionTypeEnum";

import { makeStyles } from "tss-react/mui";
import { formatDateTime } from "../../helpers/Utils";
import { LoadingButton } from "@mui/lab";
import { FileExportType } from "../../helpers/FileExportTypeEnum";
import { onReceiveMessage, clearOnReceiveMessage } from 'prembid-shared-library-npm/signalr';
import { MessageSubject } from 'prembid-shared-library-npm/types';

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleRetrieveRegisteredUsersReport_d: (auctionId: string, exportType: FileExportType, profileType: string, registeredProfileStatus: string, onCompletedCallback: (response: any) => void) => void,
    handleRegisteredProfileFetch_d: (auctionId: string, filters?: any, pagination?: any, excludeAdminUsers?: boolean, onCompletedCallback?: (res: any) => void) => void,
    handleRegisteredProfileUpdate_d: (user: any, onCompletedCallback?: (res: any) => void) => void,
    handleClearComplianceStatus: (registeredProfileId: string, onCompletedCallback?: (res: any) => void) => void,
    handleNotifyUserOfMissingDocuments: (auctionId: string, registeredProfileId: string, onCompletedCallback?: (res: any) => void) => void,
    handleRefreshRegisteredProfile_d: (id: string, onCompletedCallback?: (res: any) => void) => void,
    handleDocumentTemplatesFetch_d: () => void,
    clearUsers_d: () => void,
    users: any,
    selectedAuction: any
    activeTab: string,
    reload: boolean,
    //documentTemplates: any,
    pagination: any
}

const RegisteredUsers: React.FC<Props> = (props) => {
    const { classes, cx } = useStyles();
    const {
        handleRetrieveRegisteredUsersReport_d,
        handleRegisteredProfileFetch_d,
        handleRegisteredProfileUpdate_d,
        handleDocumentTemplatesFetch_d,
        handleClearComplianceStatus,
        handleNotifyUserOfMissingDocuments,
        handleRefreshRegisteredProfile_d,
        clearUsers_d,
        users,
        selectedAuction,
        activeTab,
        reload,
        //documentTemplates,
        pagination
    } = props

    const [loader, setLoader] = React.useState({
        isBusy: false,
        description: "Loading Auctions..."
    });

    const [displayModel, setDisplayModel] = React.useState<NestedGridDataModel>();
    const [textModalOpen, setTextModalOpen] = React.useState({
        payload: 0,
        open: false,
        id: ''
    });

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [clearSearch, setClearSearch] = useState(false);




    useEffect(() => {
        refreshList(null, pagination);
        return (() => {
            clearUsers_d();
        });
    }, [])

    useEffect(() => {
        if (selectedAuction) {
            const newFilters = reload ? selectedFilters : {};
            setSelectedFilters(newFilters);
            setClearSearch(true);
            setTimeout(() => setClearSearch(false), 100);
            refreshList(newFilters, pagination)
        };
    }, [selectedAuction, reload])

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (selectedAuction) {
            if (withTableLoading) setLoadingUsers(true);
            else showProgressSpinner({ description: `Retrieving Registered Users...` });

            handleDocumentTemplatesFetch_d();
            handleRegisteredProfileFetch_d(selectedAuction.id, filters, pagination, true, (res) => {
                setLoadingUsers(false);
                hideProgressSpinner();
            });
        }
    }

    React.useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];
        let registeredUserExists = false;
        let hideApprovalButton = false;

        headers = [
            { title: "Name", align: "inherit" },
            { title: "Paddle Number", align: "inherit" },
            { title: "Type", align: "inherit", canFilter: true },
            { title: "Status", align: "inherit", canFilter: true },
            { title: "Compliance Status", align: "inherit" },
            { title: "Reason", align: "inherit" }
        ];

        users?.forEach((user) => {
            let cells: Cell[] = []
            let customData: CustomData[] = []
            let customUploadDocumentsData: KeyValue[] = [];
            let profileDeleted = false;

            if (user.profileType === "CompanyProfile") {
                if (user.companyProfileDeletedAt) {
                    profileDeleted = true;
                }

                cells = [
                    { text: user.companyProfileFirstName + ' ' + user.companyProfileLastName + ' (' + user.companyProfileCompanyName + ')', align: "inherit" },
                    { text: user.paddleNumber, align: "inherit" },
                    { text: 'Company Profile', align: "inherit" },
                    { text: profileDeleted ? 'Deleted' : RegisteredProfileStatusEnum[user.status], align: "inherit", element: profileDeleted ? statusBadge(5, user.modifiedAt) : statusBadge(user.status, user.modifiedAt) },
                    { element: renderComplianceStatus(user?.id, user?.complianceStatus), align: "inherit" },
                    { text: user.reason, align: "inherit" }
                ];

                users.forEach((innerUserDocuments) => {
                    if (user.id === innerUserDocuments.id && innerUserDocuments.companyProfileDocumentName) {
                        customUploadDocumentsData.push({
                            key: innerUserDocuments.companyProfileDocumentName,
                            value: urlAnchor(innerUserDocuments.companyDocumentUrl)
                        });
                    }

                    if (user.id === innerUserDocuments.id && innerUserDocuments.auctionDocumentName) {
                        customUploadDocumentsData.push({
                            key: innerUserDocuments.auctionDocumentName,
                            value: urlAnchor(innerUserDocuments.auctionDocumentUrl)
                        });
                    }
                });

                customData = [
                    {
                        title: "Company", values: [
                            { key: "Company Name", value: user.companyProfileCompanyName },
                            { key: "Trading Name", value: user.companyProfileTradingName },
                            { key: "Registration Number", value: user.companyProfileRegistrationNumber },
                            { key: "VAT Number", value: user.companyProfileVATNumber },
                            { key: "Contact First Name", value: user.companyProfileFirstName },
                            { key: "Contact Last Name", value: user.companyProfileLastName },
                            { key: "Contact Email", value: user.companyProfileEmail },
                            { key: "Contact Phone Number", value: user.companyProfilePhoneNumber }
                        ]
                    },
                    {
                        title: "Documents", element: <DocumentsGrid registrationProcessDocuments={user.registrationProcessDocuments} auctionId={user.auctionId} profileId={user.profileId} registrationId={user.id} deleted={profileDeleted} />
                    }
                ];
            } else if (user.profileType === "IndividualProfile") {
                if (user.individualProfileDeletedAt) {
                    profileDeleted = true;
                }

                cells = [
                    { text: user.individualProfileFirstName + ' ' + user.individualProfileLastName, align: "inherit" },
                    { text: user.paddleNumber, align: "inherit" },
                    { text: 'Individual Profile', align: "inherit" },
                    { text: profileDeleted ? 'Deleted' : RegisteredProfileStatusEnum[user.status], align: "inherit", element: profileDeleted ? statusBadge(5, user.modifiedAt) : statusBadge(user.status, user.modifiedAt) },
                    { element: renderComplianceStatus(user?.id, user?.complianceStatus), align: "inherit" },
                    { text: user.reason, align: "inherit" }
                ];

                users.forEach((innerUserDocuments) => {
                    if (user.id === innerUserDocuments.id && innerUserDocuments.individualProfileDocumentName) {
                        customUploadDocumentsData.push({
                            key: innerUserDocuments.individualProfileDocumentName,
                            value: urlAnchor(innerUserDocuments.individualDocumentUrl)
                        });
                    }

                    if (user.id === innerUserDocuments.id && innerUserDocuments.auctionDocumentName) {
                        customUploadDocumentsData.push({
                            key: innerUserDocuments.auctionDocumentName,
                            value: urlAnchor(innerUserDocuments.auctionDocumentUrl)
                        });
                    }
                });

                customData = [
                    {
                        title: "Individual", values: [
                            { key: "First Name", value: user.individualProfileFirstName },
                            { key: "Last Name", value: user.individualProfileLastName },
                            { key: "Email", value: user.individualProfileEmail },
                            { key: "Phone Number", value: user.individualProfilePhoneNumber }
                        ]
                    },
                    {
                        title: "Documents", element: <DocumentsGrid registrationProcessDocuments={user.registrationProcessDocuments} auctionId={user.auctionId} profileId={user.profileId} registrationId={user.id} deleted={profileDeleted} />
                    }
                ];
            }


            for (var i = 0; i < rows.length; i++) {
                if (rows[i].id == user.id) {
                    registeredUserExists = true;
                    break;
                } else {
                    registeredUserExists = false;
                }
            }

            if (!registeredUserExists) {
                hideApprovalButton = profileDeleted;

                if (!hideApprovalButton && user.status == RegisteredProfileStatusEnum["Not Registered"]) {
                    hideApprovalButton = true;
                }

                rows.push({
                    id: user.id,
                    cells: cells,
                    actionButtons: hideApprovalButton ? null : actions(user.id),
                    customData: customData
                });
            }
        });

        model = {
            headers: headers,
            rows: rows,
            headerButtons: headerActionbuttons(),
        };

        setDisplayModel(model);

        showHideLoader(false, "");
    }, [users])

    function GetRegisteredUsersCSV(props) {
        const [isGeneratingCSVReport, setIsGeneratingCSVReport] = React.useState<boolean>(false)

        const getRegisteredUsersCsv = () => {
            setIsGeneratingCSVReport(true);
            const profileType = selectedFilters?.type ?? "None";
            const registeredProfileStatus = selectedFilters?.status ?? "None";

            handleRetrieveRegisteredUsersReport_d(props.auctionId, FileExportType.CSV, profileType, registeredProfileStatus, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingCSVReport(false);
            });
        };

        return (
            <React.Fragment>
                <Tooltip title={"Download Registered Users Information in CSV Format"}>
                    <LoadingButton loading={isGeneratingCSVReport} onClick={getRegisteredUsersCsv} variant="text" className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                        <FaFileCsv />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    const notifyUserOfMissingDocuments = (registeredProfileId: string) => {
        showProgressSpinner({ description: "Notifying user..." })
        handleNotifyUserOfMissingDocuments(selectedAuction.id, registeredProfileId, (res: any) => {
            if (res.success) {
                ns.success("Successfully notified the user of their missing documents");
                handleRefreshRegisteredProfile_d(registeredProfileId, () => {
                    hideProgressSpinner();
                })
            }
            else {
                ns.error("Failed to notify the user of their missing documents");
                hideProgressSpinner();
            }
        });
    }

    const clearComplianceStatus = (registeredProfileId: string) => {
        showProgressSpinner({ description: "Clearing compliance status..." })
        handleClearComplianceStatus(registeredProfileId, (res: any) => {
            if (res.success) {
                ns.success("Successfully cleared the compliance status");
                handleRefreshRegisteredProfile_d(registeredProfileId, () => {
                    hideProgressSpinner();
                })
            }
            else {
                ns.error("Failed to clear the compliance status");
                hideProgressSpinner();
            }
        })
    }

    const renderComplianceStatus = (registeredProfileId: string, complianceStatus: any) => {
        switch (complianceStatus) {
            case 'Missing Documents': return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', maxWidth: 220 }}>
                <div>{complianceStatus}</div>
                <Tooltip title="Notify User of Missing Documents">
                    <Button onClick={() => notifyUserOfMissingDocuments(registeredProfileId)} variant="text" className={"btn-outline-primary d-40 rounded-pill"}>
                        <NotificationImportantOutlined />
                    </Button>
                </Tooltip>
            </div>
            case 'Documents Uploaded': return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', maxWidth: 220 }}>
                <div>{complianceStatus}</div>
                <Tooltip title="Clear Compliance Status">
                    <Button onClick={() => clearComplianceStatus(registeredProfileId)} variant="text" className={"btn-outline-primary d-40 rounded-pill"}>
                        <AiOutlineMinus />
                    </Button>
                </Tooltip>
            </div>
            default: return <div style={{ textAlign: 'center', maxWidth: 160 }}>-</div>
        }
    }

    const showHideLoader = (show: boolean, description: string) => {
        setLoader({
            isBusy: show,
            description: description
        })
    }

    const actions = (id: string) => {
        return [
            <ApprovalDropDown id={id} />
        ]
    }

    const RegisterManualUser = (props) => {
        const placeholderText = Number(selectedAuction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Manually Register User To Tender" : "Manually Register User To Bid";
        const [openClientUserModal, setOpenClientUserModal] = React.useState(false);
        const openClientUserModal_OnClick = () => {
            setOpenClientUserModal(!openClientUserModal);
        };

        const refresh = () => {
            props.callback()
        }

        return <React.Fragment>
            <Tooltip title={placeholderText} placement="bottom">
                <Button onClick={() => { openClientUserModal_OnClick() }} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize")}>
                    <FaUserPlus />
                </Button>
            </Tooltip >
            {openClientUserModal && <RegisterManualUserModal
                open={openClientUserModal}
                description={placeholderText}
                okButtonText="Ok"
                cancelButtonText="Cancel"
                selectedAuction={selectedAuction}
                title={`${placeholderText} on ${selectedAuction.name}`}
                onCancel={() => { openClientUserModal_OnClick() }}
                handleUserListReload_d={refresh}
            />}
        </React.Fragment>
    }

    const headerActionbuttons = () => {
        return [
            <RefreshUsers selectedAuction={selectedAuction} activeTab={activeTab} callback={() => refreshList(selectedFilters, pagination)} />,
            <RegisterManualUser callback={() => refreshList(selectedFilters, pagination)} />,
            <GetRegisteredUsersCSV auctionId={selectedAuction.id} />
        ]
    }

    const onTextModalClosed = () => {
        setTextModalOpen({
            payload: 0,
            open: false,
            id: ''
        });
    }

    const onTextModalOk = (text: string, status: RegisteredProfileStatusEnum) => {
        onSubmit(textModalOpen.id, status, text);
        setTextModalOpen({
            payload: 0,
            open: false,
            id: ''
        });
    }

    const onSubmit = (id: string, status: RegisteredProfileStatusEnum, reason: string) => {
        setTextModalOpen({ ...textModalOpen, open: false });
        showHideLoader(true, "Saving");
        const user = users.filter(regUser => regUser.id === id)[0];
        const updateRegUser = { ...user };
        updateRegUser.reason = reason;
        updateRegUser.status = status;
        handleRegisteredProfileUpdate_d(updateRegUser, (res: any) => {
            if (res.success) {
                refreshList(selectedFilters, pagination);
                ns.success("Successfully updated the profile");
            } else {
                ns.error(res.error);
                showHideLoader(false, "");
            }
        });
    }

    const statusBadge = (status: number, date: string | undefined) => {
        let color = 'success';
        let text = 'Approved';

        switch (status) {
            case 0: color = 'danger'; text = 'Declined'; break;
            case 1: color = 'success'; text = 'Approved'; break;
            case 2: color = 'warning'; text = 'Awaiting'; break;
            case 3: color = 'dark'; text = 'Not Registered'; break;
            case 4: color = 'info'; text = 'Registration Started'; break;
            case 5: color = 'danger'; text = 'Deleted'; break;
            default: return <></>
        }

        return <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                <div className={`badge badge-${color} m-1 badge-circle mr-2`} style={{ minWidth: 10 }}>Placeholder</div>
                {text}
            </div >
            {date && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: '0.6em', marginLeft: 24 }}>
                {formatDateTime(date, 'yyyy/MM/dd HH:mm')}
            </div>}
        </>
    }

    const documentTemplateStatusBadge = (isUploadMandatory: boolean) => {
        switch (isUploadMandatory) {
            case true: {
                return <div className="badge badge-danger badge-pill mr-1 text-capitalize ">Required</div>
            }
            case false: {
                return <div className="badge badge-success badge-pill mr-1 text-capitalize ">Not Required</div>
            }
            default: {
                return <React.Fragment></React.Fragment>
            }
        }
    }

    const urlAnchor = (url: string) => {
        return <a className="text-info" href={url} target='_blank' style={{ textDecoration: 'underline' }}>View</a>
    }

    function ApprovalDropDown(props) {

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

        const onReject = (id: string) => {
            setAnchorEl(null);
            setTextModalOpen({
                payload: RegisteredProfileStatusEnum.Declined,
                open: true,
                id: id
            });
        }

        const onAccept = (id: string) => {
            setAnchorEl(null);
            onSubmit(id, RegisteredProfileStatusEnum.Approved, '');
        }

        const onReset = (id: string) => {
            setAnchorEl(null);
            setTextModalOpen({
                payload: RegisteredProfileStatusEnum["Not Registered"],
                open: true,
                id: id
            });
        }

        const onAwaiting = (id: string) => {
            setAnchorEl(null);
            setTextModalOpen({
                payload: RegisteredProfileStatusEnum.Awaiting,
                open: true,
                id: id
            });
        }

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        }

        const handlClose = () => {
            setAnchorEl(null);
        }

        return (
            <React.Fragment>
                <Button onClick={handleClick} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                    <span className="btn-wrapper--icon">
                        Approval
                    </span>
                </Button >

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handlClose}>

                    <MenuItem onClick={() => { onAccept(props.id) }}>
                        <ListItemIcon>
                            <FaCheck className="text-success mb-2" />
                        </ListItemIcon>
                        <ListItemText primary="Approved" />
                    </MenuItem>

                    <MenuItem onClick={() => { onReject(props.id) }}>
                        <ListItemIcon>
                            <FaTimes className="text-danger mb-2" />
                        </ListItemIcon>
                        <ListItemText primary="Declined" />
                    </MenuItem>

                    <MenuItem onClick={() => { onReset(props.id) }}>
                        <ListItemIcon>
                            <FaUndo className="text-dark mb-2" />
                        </ListItemIcon>
                        <ListItemText primary="Not Registered" />
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }

    function RefreshUsers(props) {
        const [showNewUserNotification, setShowNewUserNotification] = React.useState(false);

        const refresh = () => {
            props.callback()
        }

        React.useEffect(() => {
            const callbackId = onReceiveMessage((message: string) => {
                if (message) {
                    var payload = JSON.parse(message);

                    if (payload?.data) {
                        const newUser: any = payload?.data['newRegisteredUser'];

                        if (props.selectedAuction && newUser !== undefined) {
                            const newUserData = JSON.parse(newUser);
                            if (newUserData.AuctionId === props.selectedAuction.id)
                                if (props.activeTab === '1') {
                                    setShowNewUserNotification(true);
                                }
                        }
                    }
                }
            });

            return () => clearOnReceiveMessage(callbackId);
        }, [])

        return (
            <React.Fragment>
                {showNewUserNotification && <Tooltip open={true} title="New registration(s). Please click Refresh to view" placement="left">
                    <Button onClick={refresh} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                        <FaRedo />
                    </Button>
                </Tooltip>}
                {!showNewUserNotification && <Tooltip title="Refresh" placement="bottom">
                    <Button onClick={refresh} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                        <FaRedo />
                    </Button>
                </Tooltip>}
            </React.Fragment>)
    }

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateAvailableFilters = [
        {
            description: 'Type',
            propertyMap: 'type',
            type: 'string',
            options: [
                {
                    label: "Individual Profile",
                    value: "IndividualProfile",
                },
                {
                    label: "Company Profile",
                    value: "CompanyProfile",
                }
            ]
        },
        {
            description: 'Status',
            propertyMap: 'status',
            type: 'string',
            options: Object.keys(RegisteredProfileStatusEnum).filter((item) => {
                return isNaN(parseInt(item));
            }).map(item => {
                return {
                    label: item.toString(),
                    value: item.replace(" ", "").toString(),
                }
            })
        }
    ]

    return (
        <React.Fragment>
            <TextConfirmationDialog open={textModalOpen.open} okButtonText="Ok" cancelButtonText="Cancel" description="Please provide a reason for updating this users status" title="Update user status for auction" onCancel={onTextModalClosed} onOk={onTextModalOk} payload={textModalOpen.payload} />
            <React.Fragment>
                <NestedAccordionGrid
                    selectedFilters={selectedFilters}
                    dataModel={displayModel}
                    pagination={pagination}
                    onFiltersChange={handleFiltersChange}
                    availableFilters={generateAvailableFilters}
                    onHandleChangePage={handlePageChange}
                    loading={loader.isBusy || loadingUsers}
                    clearSearch={clearSearch}
                />
            </React.Fragment>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRegisteredProfileFetch_d: (auctionId: string, filters?: any, pagination?: any, excludeAdminUsers?: boolean, onCompletedCallback?: (res: any) => void) => dispatch(handleRegisteredProfileFetchWithPagination(auctionId, filters, pagination, excludeAdminUsers, onCompletedCallback)),
        handleRegisteredProfileUpdate_d: (user: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRegisteredProfileUpdate(user, onCompletedCallback)),
        handleDocumentTemplatesFetch_d: () => dispatch(handleDocumentTemplatesFetch()),
        handleClearComplianceStatus: (registeredProfileId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleUpdateComplianceStatus(registeredProfileId, undefined, onCompletedCallback)),
        handleNotifyUserOfMissingDocuments: (auctionId: string, registeredProfileId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleNotifyUserOfMissingDocuments(auctionId, registeredProfileId, onCompletedCallback)),
        clearUsers_d: () => dispatch(clearUsers()),
        handleRefreshRegisteredProfile_d: (id: string, onCompletedCallback?: (res: any) => void) => dispatch(handleRefreshRegisteredProfile(id, onCompletedCallback)),
        handleRetrieveRegisteredUsersReport_d: (auctionId: string, exportType: FileExportType, profileType: string, registeredProfileStatus: string, onCompletedCallback: (response: any) => void) => dispatch(handleRetrieveRegisteredUsersReport(auctionId, exportType, profileType, registeredProfileStatus, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    users: state.auctionManagement.users,
    reload: state.auctionManagement.reload,
    pagination: state.auctionManagement.usersPagination
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);