import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Grid, TextField, Button, TableCell, TableRow, CircularProgress, Tooltip, TableContainer, Table, TableBody, TableHead, Paper } from "@mui/material";
import { FaSave, FaBackspace } from "react-icons/fa";
import useHistory from '@/hooks/useHistory';
import { handleLotsFetch } from "@/pages/Auctions/store/Auctions";
import { handleReorderLotsForAuction } from "@/pages/Lots/store/Lots";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { debounce, isEmpty } from 'lodash'

interface Props {
    handleLotsFetch_d: (auctionId: string, onCompletedCallback: (res: any) => void) => void,
    handleReorderLotsForAuction_d: (auctionId: string, orderedLots: any, onCompletedCallback: (res: any) => void) => void,
    loading: boolean,
    lots: any[],
    settings: any,
    auction: any,
}

const ReorderAuction: React.FC<Props> = (props) => {
    const { auctionId }: any = useParams();
    const history = useHistory();
    const {
        handleLotsFetch_d,
        handleReorderLotsForAuction_d,
        auction,
        lots,
        loading,
        settings,
    } = props;

    const [backRedirect, setBackRedirect] = React.useState<string | null>();
    const [formFields, setFormFields] = React.useReducer((state: any, action: any) => {
        if (action.type === 'clear') return {};

        let updated = { ...state, [action.id]: action.value }
        return updated;
    }, {});

    const [fieldErrors, setFieldErrors] = useState<any>({});

    useEffect(() => {
        const backRedirect = new URLSearchParams(history.location.search).get("backRedirect");
        setBackRedirect(backRedirect);
    }, [history.location.search]);

    useEffect(() => {
        if (auctionId) refresh();

    }, [auctionId]);

    const refresh = () => {
        showProgressSpinner({ description: "Loading " + settings.LotName + "s..." });
        handleLotsFetch_d(auctionId, (res) => {
            hideProgressSpinner();
        });
        setFormFields({ type: 'clear' });
    }

    const validateAll = () => {
        //TODO: Validate no duplicates
        let isAllValid = true;

        let seen = new Set();
        let newState: any = {};

        for (const [key, value] of Object.entries(formFields)) {
            if (value) if (seen.size === seen.add(value).size) {
                isAllValid = false;
                newState[key] = true;
            }
        }

        setFieldErrors(newState);
        return isAllValid;
    }

    const onSave = () => {
        const isFormValid = validateAll();

        if (isFormValid) {
            if (!isEmpty(formFields)) {
                showProgressSpinner({ description: `Reordering ${settings.LotName}s...` });
                handleReorderLotsForAuction_d(auctionId, formFields, (res) => {
                    if (res.success) {
                        ns.success(`Successfully reordered the ${settings.LotName}s`);
                        refresh();
                    }
                    else
                        ns.error(`Failed to reorder the ${settings.LotName}s`);
                    hideProgressSpinner();
                });
            }
            else {
                ns.information("No changes were applied")
            }
        }
        else {
            hideProgressSpinner();
            ns.error("Duplicate ordering has been detected, please ensure all values entered are unique")
        }
    };

    const onCancel = () => {
        history.push(backRedirect || `/AuctionEdit?auctionId=${auctionId}&tabIndex=3`);
    };

    const onChange = e => {
        setFormFields({ id: e.target.id, value: e.target.value });
    }

    return <>
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Card className="card-box mb-spacing-6-x2">
                    <div className="card-header py-3">
                        <div className="card-header--title font-size-lg">
                            Reordering {settings.LotName + "s"} for {settings.AuctionName} {auction?.name}
                        </div>
                    </div>
                    {!loading && <div className="table-responsive-md">
                        <TableContainer component={Paper}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: 180 }}><strong>Unique Code</strong></TableCell>
                                        <TableCell ><strong>Name</strong></TableCell>
                                        <TableCell align='center' style={{ width: 180 }}><strong>Current Number</strong></TableCell>
                                        <TableCell align='center' style={{ width: 180 }}><strong>New Number</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!lots && <TableRow><TableCell align='center' colSpan={4}><CircularProgress size={50} style={{ color: settings.Styles.Primary }} /></TableCell></TableRow>}
                                    {lots?.map((row) => (
                                        <TableRow>
                                            <TableCell style={{ width: 180 }}>{row.uniqueCode}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell align='center' style={{ width: 180 }}>{row.number}</TableCell>
                                            <TableCell align='center' style={{ paddingTop: 0, paddingBottom: 0, width: 180 }}>
                                                <TextField
                                                    type='number'
                                                    margin="normal"
                                                    value={formFields[row.id]}
                                                    error={fieldErrors[row.id]}
                                                    onChange={onChange}
                                                    id={row.id}
                                                    fullWidth
                                                    className="m-2"
                                                    onBlur={validateAll}
                                                    placeholder='(Optional)'
                                                    onFocus={event => { event.target.select() }}
                                                    inputProps={{ min: 1, style: { textAlign: 'center' } }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>}
                </Card>
            </Grid>
        </Grid>
        <FloatyButtonGroup>
            <Tooltip arrow title="Save" placement="left">
                <Button size="large" variant="contained" onClick={onSave}
                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                </Button>
            </Tooltip>
            <Tooltip arrow title="Back" placement="left">
                <Button size="large" variant="contained" onClick={onCancel}
                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaBackspace />
                    </span>
                </Button>
            </Tooltip>
        </FloatyButtonGroup>
    </>;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotsFetch_d: (auctionId: string, onCompletedCallback: (res: any) => void) => dispatch(handleLotsFetch(auctionId, onCompletedCallback)),
        handleReorderLotsForAuction_d: (auctionId: string, orderedLots: any, onCompletedCallback: (res: any) => void) => dispatch(handleReorderLotsForAuction(auctionId, orderedLots, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    auction: state.auctions.auction,
    lots: state.auctions.lots,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReorderAuction);
