import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearError, handleUsersFetch, handleResendConfirmAccountEmail, handleResendAdminConfirmAccountEmail, handleUsersFetchWithPagination } from './store/Users';
import { handleProfileAutoApproveRegistrationUpdateStatus, handleUserAccountUpdateStatus, handleUserProfilesFetch, resetAccountState } from '../Profile/store/Profile';
import {
    Card,
    Collapse,
    IconButton,
    List,
    ListItem,
    Switch,
    Button,
    Tooltip
} from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import { MdClose } from "react-icons/md";
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, CustomData } from '@/components/NestedAccordionGrid'
import { FaFileCsv, FaPaperPlane, FaRedo } from 'react-icons/fa';
import ns from '@/helpers/NotificationService';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { PrembidLocalStorage } from '../../helpers/PrembidLocalStorage';
import { IdentityRoles } from '../../dto/User';
import { makeStyles } from "tss-react/mui";
import { FileExportType } from '../../helpers/FileExportTypeEnum';
import { handleRetrieveClientUsersReport } from '../AuctionManagement/store/AuctionManagement';

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleRetrieveClientUsersReport_d: (exportType: FileExportType, onCompletedCallback: (response: any) => void) => void,
    clearError_d: () => void,
    handleUsersFetchWithPagination_d: (filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleResendConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => void,
    handleResendAdminConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => void,
    handleUserProfilesFetch_d: (onCompletedCallback?: (response: any) => void) => void,
    resetAccountState_d: () => void,
    handleUserAccountUpdateStatus_d: (userId, status, onCompletedCallback: (response: any) => void) => void,
    handleProfileAutoApproveRegistrationUpdateStatus_d: (profileId, status, profileType, onCompletedCallback: (response: any) => void) => void,
    error: boolean,
    errorText: string,
    users: any,
    profilesState: any,
    pagination: any,
}

const Users: React.FC<Props> = props => {
    const {
        handleRetrieveClientUsersReport_d,
        clearError_d,
        handleUsersFetchWithPagination_d,
        handleResendConfirmAccountEmail_d,
        handleResendAdminConfirmAccountEmail_d,
        handleUserProfilesFetch_d,
        resetAccountState_d,
        handleUserAccountUpdateStatus_d,
        handleProfileAutoApproveRegistrationUpdateStatus_d,
        error,
        errorText,
        users,
        profilesState,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();
    const [userDisplayModel, setUserDisplayModel] = useState<NestedGridDataModel>();
    const [activeTab, setActiveTab] = useState<string>('0');
    const [confirmationState, setConfirmationState] = useState<any>({
        open: false,
        payload: null
    });
    const [autoApproveRegistrationConfirmationState, setAutoApproveRegistrationConfirmationState] = useState<any>({
        open: false,
        payload: null
    });

    const [selectedFilters, setSelectedFilters] = useState<any>();

    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

    useEffect(() => {
        handleUserProfilesFetch_d();
        reloadData(null, null, false);
        return () => {
            resetAccountState_d();
        };
    }, [])

    useEffect(() => {
        setSelectedFilters(null);
        reloadData(null, null, false);
    }, [activeTab])

    const reloadData = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingUsers(true);
        else showProgressSpinner({ description: "Retrieving Users..." });

        filters = {
            ...(filters || {}),
            isAdminTab: activeTab === '0'
        }

        handleUsersFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingUsers(false);
        });
    }

    useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];
        let cells: Cell[];

        if (PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalSuperUser)) {
            headers = [
                { title: "Email", align: "inherit" },
                { title: "Phone Number", align: "inherit" },
                { title: "Active", align: "center", canFilter: true },
                { title: "Emailed Confirmed", align: "inherit", canFilter: true }
            ];
        } else {
            headers = [
                { title: "Email", align: "inherit" },
                { title: "Phone Number", align: "inherit" },
                { title: "Emailed Confirmed", align: "inherit", canFilter: true }
            ];
        }

        if (users)
            rows = users?.map(user => {
                if (PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalSuperUser)) {
                    cells = [
                        { text: user.email, align: "inherit" },
                        { text: user.phoneNumber, align: "inherit" },
                        {
                            text: user.isActive ? 'Active' : 'Inactive', align: "center",
                            element: <Switch checked={user.isActive} onChange={handleStatusChange} id={"isActive_" + user.id} className="m-2 p-2 switch-medium toggle-switch-success" />
                        },
                        {
                            text: user.emailConfirmed ? 'Yes' : 'No', align: "inherit",
                            element: emailConfirmedStatusBadge(user.emailConfirmed)
                        }
                    ];
                } else {
                    cells = [
                        { text: user.email, align: "inherit" },
                        { text: user.phoneNumber, align: "inherit" },
                        {
                            text: user.emailConfirmed ? 'Yes' : 'No', align: "inherit",
                            element: emailConfirmedStatusBadge(user.emailConfirmed)
                        }
                    ];
                }

                let profileModel: NestedGridDataModel;
                let profileRows: Row[] = [];
                let profileHeaders: Header[];

                profileHeaders = [
                    { title: "Name", align: "inherit" },
                    { title: "Email", align: "inherit" },
                    { title: "Phone Number", align: "inherit" },
                    { title: "Profile Type", align: "inherit" },
                    { title: "Status", align: "inherit" },
                    { title: "Auto Approve Registration", align: "inherit" },
                ];

                profileRows = profilesState?.profiles?.filter(profile => profile.userId === user.id).map(profile => {
                    const profileCells: Cell[] = [
                        { text: profile.name, align: "inherit" },
                        { text: profile.email, align: "inherit" },
                        { text: profile.phoneNumber, align: "inherit" },
                        { text: profile.profileType, align: "inherit" },
                        { text: profile.isActive ? 'Active' : 'Inactive', align: "center", element: statusBadge(profile.isActive) },
                        {
                            text: profile.autoApproveRegistration ? 'Yes' : 'No', align: "center",
                            element: <Switch checked={profile.autoApproveRegistration} onChange={(e) => handleAutoApproveRegistrationChange(e, profile.profileType)} id={"autoApproveRegistration_" + profile.id} className="m-2 p-2 switch-medium toggle-switch-success" />
                        }
                    ];

                    return {
                        id: profile.id,
                        cells: profileCells
                    };
                });

                profileModel = {
                    headers: profileHeaders,
                    rows: profileRows,
                };

                const customData: CustomData[] = [
                    {
                        title: "Bidding Profiles",
                        element: <>
                            <NestedAccordionGrid dataModel={profileModel} hideSearch />
                        </>
                    }
                ];

                return {
                    id: user.id,
                    cells: cells,
                    actionButtons: actions(user.id, user.emailConfirmed),
                    customData: activeTab === '1' ? customData : null
                };
            });

        model = {
            headers: headers,
            rows: rows,
            headerButtons: headerActionbuttons()
        };

        setUserDisplayModel(model);

        hideProgressSpinner();
    }, [profilesState?.profiles, users])

    const headerActionbuttons = () => {
        if (activeTab == '0') {
            return [
                <Tooltip title="Refresh" placement="bottom">
                    <Button onClick={() => reloadData(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                        <FaRedo />
                    </Button>
                </Tooltip>
            ]
        } else if (activeTab == '1') {
            return [
                <Tooltip title="Refresh" placement="bottom">
                    <Button onClick={() => reloadData(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                        <FaRedo />
                    </Button>
                </Tooltip>,
                <GetClientUsersCSV />
            ]
        } else {
            return []
        }
    }

    function GetClientUsersCSV() {
        const [isGeneratingCSVReport, setIsGeneratingCSVReport] = React.useState<boolean>(false)

        const getClientUsersCsv = () => {
            setIsGeneratingCSVReport(true);
            handleRetrieveClientUsersReport_d(FileExportType.CSV, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingCSVReport(false);
            });
        };

        return (
            <React.Fragment>
                <Tooltip title={"Download Client Users Information in CSV Format"}>
                    <LoadingButton loading={isGeneratingCSVReport} onClick={getClientUsersCsv} variant="text" className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                        <FaFileCsv />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    const handleStatusChange = (e) => {
        const { target: { id, checked } } = e;

        if (id) {
            let userId = id.replace('isActive_', '');

            setConfirmationState({ ...confirmationState, open: true, payload: { userId, checked } })
        }
    }

    const handleAutoApproveRegistrationChange = (e, profileType) => {
        const { target: { id, checked } } = e;

        if (id) {
            let profileId = id.replace('autoApproveRegistration_', '');
            setAutoApproveRegistrationConfirmationState({ ...autoApproveRegistrationConfirmationState, open: true, payload: { profileId, checked, profileType } })
        }
    }

    const navAddEdit = (userId?: string) => {
        let path = '/UserEdit'
        if (userId && userId !== '')
            path += '?userId=' + userId
        history.push(path);
    }

    const statusBadge = (active: boolean) => {
        switch (active) {
            case true: {
                return <div className="badge badge-success badge-pill m-1 text-capitalize">Active</div>
            }
            case false: {
                return <div className="badge badge-danger badge-pill m-1 text-capitalize">Inactive</div>
            }
        }
    }

    const emailConfirmedStatusBadge = (active: boolean) => {
        switch (active) {
            case true: {
                return <div className="badge badge-success badge-pill m-1 text-capitalize">Yes</div>
            }
            case false: {
                return <div className="badge badge-danger badge-pill m-1 text-capitalize">No</div>
            }
        }
    }

    const handleConfirmationOk = () => {
        showProgressSpinner({ description: "Saving User..." });
        handleUserAccountUpdateStatus_d(confirmationState.payload.userId, confirmationState.payload.checked, (res) => {
            if (res.success) {
                ns.success("Updated active status successfully");
            } else {
                ns.error(res.error);
            }
            hideProgressSpinner();
            setConfirmationState({ ...confirmationState, open: false, payload: null })
            reloadData(selectedFilters, pagination);
        });
    }

    const handleAutoApproveRegistrationConfirmationOk = () => {
        showProgressSpinner({ description: "Saving Profile..." });
        handleProfileAutoApproveRegistrationUpdateStatus_d(autoApproveRegistrationConfirmationState.payload.profileId, autoApproveRegistrationConfirmationState.payload.checked, autoApproveRegistrationConfirmationState.payload.profileType, (res) => {
            if (res.success) {
                ns.success("Updated auto approve registration status successfully");
            } else {
                ns.error(res.error);
            }
            hideProgressSpinner();
            setAutoApproveRegistrationConfirmationState({ ...autoApproveRegistrationConfirmationState, open: false, payload: null })
            handleUserProfilesFetch_d();
        });
    }

    const actions = (id: string, emailConfirmed: boolean) => {
        return [
            <ResendConfirmAccountEmail id={id} emailConfirmed={emailConfirmed} />
        ]
    }

    function ResendConfirmAccountEmail(props) {
        if (!props.emailConfirmed) {
            const handleResponse = (res: any) => {
                if (res.success) {
                    ns.success("Email sent successfully");
                }
                else {
                    ns.error("Failed to send the email")
                }
                hideProgressSpinner();
            }

            const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                showProgressSpinner({ description: "Resending confirm Account Email..." });

                if (activeTab === '0') handleResendAdminConfirmAccountEmail_d(props.id, handleResponse);
                else handleResendConfirmAccountEmail_d(props.id, handleResponse);
            }

            return (
                <React.Fragment>
                    <Tooltip arrow title="Resend Email" placement="bottom">
                        <Button onClick={handleClick} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2b2b2b' }}>
                            <FaPaperPlane />
                        </Button>
                    </Tooltip>

                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment />
            );
        }
    }

    const handlePageChange = (pagination: any) => {
        reloadData(selectedFilters, pagination);
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        reloadData(filters, null);
    }

    const generateAvailableFilters = [
        {
            description: 'Active',
            propertyMap: 'isActive',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        },
        {
            description: 'Email Confirmed',
            propertyMap: 'emailConfirmed',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        }
    ]

    return (
        <div>
            {confirmationState.open && <ConfirmationDialog
                cancelButtonText='Cancel'
                okButtonText='Confirm'
                title='Confirm'
                description='Are you sure you want to change the users status?'
                onOk={handleConfirmationOk}
                onCancel={() => setConfirmationState({ ...confirmationState, open: false, payload: null })}
                open={confirmationState.open}
                payload={() => { }}
            />}
            {autoApproveRegistrationConfirmationState.open && <ConfirmationDialog
                cancelButtonText='Cancel'
                okButtonText='Confirm'
                title='Confirm'
                description='Are you sure you want to change the profiles auto approve status?'
                onOk={handleAutoApproveRegistrationConfirmationOk}
                onCancel={() => setAutoApproveRegistrationConfirmationState({ ...autoApproveRegistrationConfirmationState, open: false, payload: null })}
                open={autoApproveRegistrationConfirmationState.open}
                payload={() => { }}
            />}

            <div className="bg-white">
                <div className="card-header py-3"><div className="card-header--title font-size-lg">Users</div></div>
                <div className="divider" />
                <div className="p-3">
                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem button disableRipple selected={activeTab === '0'} onClick={() => setActiveTab('0')}>Admin Users</ListItem>
                        <ListItem button disableRipple selected={activeTab === '1'} onClick={() => setActiveTab('1')}>Client Users</ListItem>
                    </List>
                    <div className='tab-item-wrapper active'>
                        <div className="text-centre p-3">
                            <NestedAccordionGrid
                                pagination={pagination}
                                selectedFilters={selectedFilters}
                                onFiltersChange={handleFiltersChange}
                                onHandleChangePage={handlePageChange}
                                availableFilters={generateAvailableFilters}
                                onAdd={activeTab === '0' && PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalSuperUser) ? () => navAddEdit() : undefined}
                                onEdit={activeTab === '0' && PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalSuperUser) ? navAddEdit : undefined}
                                dataModel={userDisplayModel}
                                loading={loadingUsers}
                                showTopDivider={false}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {error && (
                <Collapse in={error}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    clearError_d()
                                }}
                            >
                                <MdClose fontSize="inherit" />
                            </IconButton>
                        }
                        className="mb-4" severity="error">
                        {errorText}
                    </Alert>
                </Collapse>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        handleUsersFetchWithPagination_d: (filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleUsersFetchWithPagination(filters, pagination, onCompletedCallback)),
        handleResendConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => dispatch(handleResendConfirmAccountEmail(userId, onCompletedCallback)),
        handleResendAdminConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => dispatch(handleResendAdminConfirmAccountEmail(userId, onCompletedCallback)),
        handleUserProfilesFetch_d: (onCompletedCallback?: (response: any) => void) => dispatch(handleUserProfilesFetch(onCompletedCallback)),
        handleUserAccountUpdateStatus_d: (userId, status, onCompletedCallback: (response: any) => void) => dispatch(handleUserAccountUpdateStatus(userId, status, onCompletedCallback)),
        handleProfileAutoApproveRegistrationUpdateStatus_d: (profileId, status, profileType, onCompletedCallback: (response: any) => void) => dispatch(handleProfileAutoApproveRegistrationUpdateStatus(profileId, status, profileType, onCompletedCallback)),
        resetAccountState_d: () => dispatch(resetAccountState()),
        handleRetrieveClientUsersReport_d: (exportType: FileExportType, onCompletedCallback: (response: any) => void) => dispatch(handleRetrieveClientUsersReport(exportType, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    error: state.users.error,
    errorText: state.users.errorText,
    users: state.users.users,
    pagination: state.users.pagination,
    profilesState: state.account.profilesState
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);