import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {

    const border ='1px solid rgb(220, 222, 241)';


    return ({
        popover: {
            overflow: 'hidden',
            maxWidth:'1700px',
            width: '85%',
            [theme.breakpoints.between('xs', 'lg')]: {
                width: '90%',
            },
            [theme.breakpoints.down('sm')]: {
                overflowY:'scroll'
            }
        },
        table: {
            border,
        },
        header:{
            fontSize:'14px',
            textTransform:'uppercase',
            [theme.breakpoints.down('md')]:{
                fontSize:'12px',
            },
        },
        headerRow:{
            background:'#f4f4f9',
        },
        headerCell:{
            border,
            fontWeight: 'bold',
        },
        topBidderCell: {
            flexDirection: 'row',
            fontSize: '14px',
            textTransform: 'uppercase',
            border,
            [theme.breakpoints.down('md')]: {
                fontSize: '12px',
            },
        },
        cell:{
            fontSize:'14px',
            textTransform:'uppercase',
            border,
            [theme.breakpoints.down('md')]:{
                fontSize:'12px',
            },
        }
    })
});

export default useStyles;