import React, { memo } from 'react';
import { BulkUploadStatus } from '../../../helpers/BulkUploadStatusEnum';
import { formatDateTime } from '../../../helpers/Utils';

interface Props {
    status: BulkUploadStatus;
    error: string | undefined;
    date: string | undefined;
}

const BulkUploadStatusComponent: React.FC<Props> = props => {
    const { status, error, date } = props;

    let color = 'success';

    switch (status) {
        case BulkUploadStatus.Draft: color = 'dark'; break;
        case BulkUploadStatus.Processing: color = 'warning'; break;
        case BulkUploadStatus.Error: color = 'danger'; break;
    }

    return <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
            <div className={`badge badge-${color} m-1 badge-circle mr-2`} style={{ minWidth: 10 }}>Placeholder</div>
            {BulkUploadStatus[status].toString()}
        </div >
        {error && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: '0.6em', marginLeft: 24 }}>
            {error}
        </div>}
        {date && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', fontSize: '0.6em', marginLeft: 24 }}>
            {formatDateTime(date, 'yyyy/MM/dd HH:mm')}
        </div>}
    </>
}

export default memo(BulkUploadStatusComponent);