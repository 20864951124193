import { FC, memo, useEffect, useState } from "react";
import { Auction, AuctionTypeEnum } from "prembid-shared-library-npm/types";
import { Box, Card, Grid } from "@mui/material";
import useStyles from "../AuctionsOverviewFactsFigures/AuctionsOverviewFactsFigures.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ConfigureStore";
import { useRetrieveFactsAndFigures } from "prembid-shared-library-npm/networking";
import { Lot } from 'prembid-shared-library-npm/types';
import { FactsAndFigures, FinancialAmounts, LotAmounts, LotStatusAmounts } from "./AuctionsOverviewFactsFigures.types";
import RefreshButton from "./sub-components/RefreshButton";
import FinancialTotals from "./sub-components/FinancialTotals";
import LotTotals from "./sub-components/LotTotals";
import LotStatusTotals from "./sub-components/LotStatusTotals";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";

interface AuctionsOverviewFactsFiguresProps {
    auction: Auction,
};

const AuctionsOverviewFactsFigures: FC<AuctionsOverviewFactsFiguresProps> = ({ auction }) => {

    const { classes } = useStyles();

    const isTender = Number(auction?.auctionType) === Number(AuctionTypeEnum.Tender.toString());

    const settings = useSelector((state: RootState) => state.settings.settings);
    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [factsAndFigures, setFactsAndFigures] = useState<FactsAndFigures>(
        {
            financialAmounts: { auctionTotal: 0, lotsReserveMetTotal: 0, lotsReserveNotMetTotal: 0 },
            lotAmounts: { lotsTotal: 0, lotsWithBidsTotal: 0, lotsReserveMetTotal: 0, lotsReserveNotMetTotal: 0 },
            lotStatusAmounts: { openLotsTotal: 0, extendedLotsTotal: 0, completedLotsTotal: 0 }
        });

    const { refetch: refetchFactsAndFigures } = useRetrieveFactsAndFigures({
        pathParams: { auctionId: auction?.id }
    }, {
        enabled: false,
        onSuccess(lotsData: Lot[]) {

            setFactsAndFigures((current) => ({
                ...current,
                financialAmounts: getFinancialTotals(lotsData),
                lotAmounts: getLotAmounts(lotsData),
                lotStatusAmounts: getLotStatusAmounts(lotsData)
            }));

            setIsLoading(false);
        }
    });

    const getFinancialTotals = (data: Lot[]): FinancialAmounts => {
        const auctionTotal = data.reduce((accumulator, currentLot) => accumulator + (currentLot?.overrideWinningBidAmount ? currentLot?.overrideWinningBidAmount : (currentLot?.winningRegisteredProfileAmount ?? 0)), 0);
        const lotsReserveMetTotal = data.reduce((accumulator, currentLot) => accumulator + (currentLot?.reservePrice <= (currentLot?.overrideWinningBidAmount ? currentLot?.overrideWinningBidAmount : (currentLot?.winningRegisteredProfileAmount ?? 0)) ? (currentLot?.overrideWinningBidAmount ? currentLot?.overrideWinningBidAmount : (currentLot?.winningRegisteredProfileAmount ?? 0)) : 0), 0);
        const lotsReserveNotMetTotal = data.reduce((accumulator, currentLot) => accumulator + (currentLot?.reservePrice > (currentLot?.overrideWinningBidAmount ? currentLot?.overrideWinningBidAmount : (currentLot?.winningRegisteredProfileAmount ?? 0)) ? (currentLot?.overrideWinningBidAmount ? currentLot?.overrideWinningBidAmount : (currentLot?.winningRegisteredProfileAmount ?? 0)) : 0), 0);

        return {
            auctionTotal: auctionTotal,
            lotsReserveMetTotal: lotsReserveMetTotal,
            lotsReserveNotMetTotal: lotsReserveNotMetTotal
        } as FinancialAmounts;
    }

    const getLotAmounts = (data: Lot[]): LotAmounts => {
        const lotsTotal = data?.length ?? 0;
        const lotsWithBidsTotal = data?.filter(x => x.winningRegisteredProfileAmount)?.length ?? 0;
        const lotsReserveMetTotal = data?.filter(x => (x.reservePrice <= (x.overrideWinningBidAmount ? x.overrideWinningBidAmount : (x.winningRegisteredProfileAmount ?? 0))))?.length ?? 0;
        const lotsReserveNotMetTotal = data?.filter(x => (x.reservePrice > (x.overrideWinningBidAmount ? x.overrideWinningBidAmount : (x.winningRegisteredProfileAmount ?? 0))))?.length ?? 0;

        return {
            lotsTotal: lotsTotal,
            lotsWithBidsTotal: lotsWithBidsTotal,
            lotsReserveMetTotal: lotsReserveMetTotal,
            lotsReserveNotMetTotal: lotsReserveNotMetTotal
        } as LotAmounts;
    }

    const getLotStatusAmounts = (data: Lot[]): LotStatusAmounts => {
        const now = getNewDateWithOffset(currentDateTimeOffset);

        const openLotsTotal = data?.filter(x => now >= new Date(x.startDateTimeAt) && now < new Date(x.endDateTimeAt) && x.extendedBy == 0)?.length ?? 0;
        const extendedLotsTotal = data?.filter(x => now >= new Date(x.startDateTimeAt) && now < new Date(new Date(x.endDateTimeAt).setTime(new Date(x.endDateTimeAt).getTime() + ((x.extendedBy ?? 0) * 1000))) && x.extendedBy > 0)?.length ?? 0;
        const completedLotsTotal = data?.filter(x => now >= new Date(new Date(x.endDateTimeAt).setTime(new Date(x.endDateTimeAt).getTime() + ((x.extendedBy ?? 0) * 1000))))?.length ?? 0;

        return {
            openLotsTotal: openLotsTotal,
            extendedLotsTotal: extendedLotsTotal,
            completedLotsTotal: completedLotsTotal
        } as LotStatusAmounts;
    }

    const handleRefreshClick = () => {
        if (!isLoading) {
            setIsLoading(true);
            refetchFactsAndFigures();
        }
    }

    useEffect(() => {
        if (auction) {
            setIsLoading(true);
            refetchFactsAndFigures();
        }
    }, [auction, refetchFactsAndFigures]);

    return (
        <>
            <Card>
                <Grid
                    container
                    spacing={1}
                    classes={{ root: classes.mainContainer }}>
                    <Grid item md={12}>
                        <Box className={classes.refreshButton}>
                            <RefreshButton onClick={handleRefreshClick} isLoading={isLoading} />
                        </Box>
                    </Grid>

                    <Grid item md={4}>
                        <FinancialTotals settings={settings} financialAmounts={factsAndFigures?.financialAmounts} isLoading={isLoading} />
                    </Grid>

                    <Grid item md={4}>
                        <LotTotals settings={settings} lotAmounts={factsAndFigures?.lotAmounts} isTender={isTender} isLoading={isLoading} />
                    </Grid>

                    <Grid item md={4}>
                        <LotStatusTotals settings={settings} lotStatusAmounts={factsAndFigures?.lotStatusAmounts} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Card >
        </>
    );
}

export default memo(AuctionsOverviewFactsFigures);

