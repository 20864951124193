import React, { FC, memo } from "react";

import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import { Typography } from "@mui/material";

interface TopBidProps {
    topBid?: string | number | null;
    topBidder?: string | null;
    isTender: boolean;
    name?: string;
}

const TopBid: FC<TopBidProps> = ({ topBid, topBidder, isTender, name }) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}>
                <CurrencyLabel value={Number(topBid ?? 0)} />
                <Typography variant="subtitle2"
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    {topBidder ? topBidder : isTender ? "No Tenders" : "No Bids"}
                    {name && <span style={{ paddingLeft: 5 }}>({name})</span>}
                </Typography>
            </div>
        </>
    );
};

export default memo(TopBid);
