import { combineReducers } from "redux";

//Identity
import loginForm from '@/components/LoginForm/store/LoginForm';
import registerCard from '@/components/RegisterCard/store/RegisterCard';
import registerConfirmationCard from '@/components/RegisterConfirmationCard/store/RegisterConfirmationCard';
import confirmAccountConfirmationCard from '@/components/ConfirmAccountConfirmationCard/store/ConfirmAccountConfirmationCard';
import confirmEmailChangeConfirmationCard from '@/components/EmailChangeConfirmationCard/store/EmailChangeConfirmationCard';
import forgotPasswordCard from '@/components/ForgotPasswordCard/store/ForgotPasswordCard';
import forgotPasswordConfirmationCard from '@/components/ForgotPasswordConfirmationCard/store/ForgotPasswordConfirmationCard';
import resetPasswordCard from '@/components/ResetPasswordCard/store/ResetPasswordCard';
import resetPasswordConfirmationCard from '@/components/ResetPasswordConfirmationCard/store/ResetPasswordConfirmationCard';

//Auctions
import auctions from '@/pages/Auctions/store/Auctions';
import auctionManagement from '@/pages/AuctionManagement/store/AuctionManagement';


//Venue Screen
import venue from '@/pages/Venue/store/Venue';

//Lots
import lots from '@/pages/Lots/store/Lots';
import lotTypes from '@/pages/LotTypes/store/LotTypes';
import lotImages from '@/pages/Lots/store/LotImages';

//Users
import users from '@/pages/Users/store/Users';

//Documents
import documents from '@/pages/Documents/store/Documents';

//Account
import account from '@/pages/Profile/store/Profile';

//Setting
import settings from '../Settings';

import ThemeOptions from './ThemeOptions';

//Services
import progressSpinner from '@/components/ProgressSpinner/store/ProgressSpinner';
import dialog from '@/components/Dialog/store/Dialog';
import notificationServiceWrapper from '@/components/NotificationServiceWrapper/store/NotificationServiceWrapper'

//Contact
import contacts from '@/pages/Contacts/store/Contacts';

import integration from '@/pages/Integration/store/Integration'

import bankDetails from '@/pages/BankDetails/store/BankDetails'

import biddingCalculations from '@/pages/BiddingCalculations/store/BiddingCalculations'

import bulkUploads from '@/pages/BulkUpload/store/BulkUpload'

//VendorIncrementTable

import incrementTable from "../../pages/IncrementTable/store/IncrementTable";

import signalR from '@/store/SignalR'

export default combineReducers({
    //Identity
    loginForm,
    registerCard,
    registerConfirmationCard,
    confirmAccountConfirmationCard,
    confirmEmailChangeConfirmationCard,
    forgotPasswordCard,
    forgotPasswordConfirmationCard,
    resetPasswordCard,
    resetPasswordConfirmationCard,
    ThemeOptions,

    //Lots
    lots,
    lotTypes,
    lotImages,

    //Venue
    venue,

    //Users
    users,

    //Documents
    documents,

    //Auctions
    auctions,
    auctionManagement,

    //AccountProfile
    account,

    //Settings
    settings,

    //Services
    progressSpinner,
    dialog,
    notificationServiceWrapper,

    //Contacts
    contacts,

    integration,

    bankDetails,
    biddingCalculations,

    //IncrementTable
    incrementTable,

    bulkUploads,

    signalR
})