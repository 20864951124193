import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
    },
    link: {
        cursor: 'pointer',
    },
    successIconDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#009933',
        height: '90px',
        width: '90px',
        borderRadius: '100%',
        margin: '1rem auto'
    },
    successIcon: {
        fontSize: '60px',
        color: '#fafafa'
    },
    imgLogo: {
        textAlign: 'center',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        maxHeight: '100px',
        maxWidth: '300px'
    }
}));

export default useStyles;