import React, { FC, useMemo, useReducer } from "react";
import SimpleTable, { ColumnProps } from "@/components/SimpleTable/SimpleTable";
import { Box, Tooltip } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { ValidationHistoryItem, ValidationStatus } from "../../../dto/BulkUploader";
import { connect } from "react-redux";
import { determineValidationStatus } from "./BulkUploaderValidationStatusBadge";

interface BulkUploaderValidationTableProps {
    validationHistoryData: ValidationHistoryItem[],
    isTableLoading: boolean,
    settings: any,
    onViewCallback: (uploadedLotNumber: number) => void,
}

const BulkUploaderValidationTable: FC<BulkUploaderValidationTableProps> = props => {
    const { validationHistoryData, isTableLoading, settings, onViewCallback } = props;

    const [loadingIds, updateLoadingId] = useReducer((state: string[], action: { type: 'add' | 'remove' | 'clear'; id: string; }) => {
        switch (action.type) {
            case 'add': return [...state, action.id];
            case 'remove': return state.filter(id => id !== action.id);
            case 'clear': return [];
            default: return state;
        }
    }, []);

    const renderValidationErrors = (errors, bannerCount, otherCount) => {
        const validationResult = errors?.length > 0 || bannerCount !== 1 || otherCount < 1 || otherCount > 50 ? 'Invalid' : 'Valid';
        return validationResult;
    }

    const renderValidImageCount = (imageCount: number, limit) => {
        const limitExceeded = imageCount > limit;
        return <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', }}>
                <div className={`badge badge-${limitExceeded || imageCount === 0 ? 'danger' : 'success'} m-1 badge-circle mr-2`} style={{ minWidth: 10 }}>Placeholder</div>
                {imageCount} Uploaded
            </div >
            {imageCount === 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 24 }}>
                <div>- Please upload a minimum of 1 image</div>
            </div>}
            {limitExceeded && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 24 }}>
                <div>- Only {limit} images allowed</div>
            </div>}
        </>
    }

    const statusFilters: string[] = useMemo(() => {
        return[
            determineValidationStatus(ValidationStatus.Valid),
            determineValidationStatus(ValidationStatus.Invalid),
        ]
    }, []);

    const columns = useMemo<ColumnProps<ValidationHistoryItem>[]>(() => ([
        {
            id: "number",
            header: 'Number',
            accessorFn: (row: ValidationHistoryItem) => <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 10 }}>
                    <div>{row?.uploadedLotNumber}</div>
                    <div>(Row {row?.uploadedLotNumber + 1} in file)</div>
                </div>
            </>,
            enableSorting: false
        },
        {
            id: "lotName",
            header: settings.LotName + " Name",
            accessorFn: (row: any) => row?.name,
            enableSorting: true
        },
        {
            id: "bannerImageCount",
            header: 'Banner Image',
            accessorFn: (row: ValidationHistoryItem) => renderValidImageCount(row?.bannerImageCount ?? 0, 1),
            enableSorting: false
        },
        {
            id: "otherImageCount",
            header: 'Other Images',
            accessorFn: (row: ValidationHistoryItem) => renderValidImageCount(row?.otherImageCount ?? 0, 50),
            enableSorting: false
        },
        {
            id: "validationStatus",
            header: 'Validation Status',
            accessorKey: 'validationStatus',
            filterSelectOptions: statusFilters,
            filterVariant: 'multi-select',
            enableColumnFilter: true,
            accessorFn: (row: ValidationHistoryItem) => renderValidationErrors(row?.validationErrors, row?.bannerImageCount ?? 0, row?.otherImageCount ?? 0),
            enableSorting: true,
        },
    ]), [statusFilters]);

    const handleViewClicked = (uploadedLotNumber: number) => {
        onViewCallback(uploadedLotNumber);
    }

    return (
        <SimpleTable
            columnHeaders={columns}
            tableData={validationHistoryData}
            loadingIds={loadingIds}

            renderRowActions={(row, isLoading, loadingIds) => {
                const id = row.id;

                return <>
                    <Tooltip arrow title="Preview" placement="bottom">
                        <span>
                            <LoadingButton
                                onClick={() => handleViewClicked(validationHistoryData[row?.id]?.uploadedLotNumber)}
                                size="small"
                                disabled={isLoading}
                                loading={false}
                                className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaEye />
                            </LoadingButton>
                        </span>
                    </Tooltip>
                </>
            }}

            renderBottomTableActions={(table, isLoading) => {
                return <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }} />
            }}

            pagination={true}
            isLoading={isTableLoading}
        />
    );
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps, null)(BulkUploaderValidationTable);