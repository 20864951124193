import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { Image } from '@/components/ImageUploader/ImageUploader';
import ns from '@/helpers/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";

const ERROR = 'LOTS_CRUD_ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const ADD_LOT_SUCCESS = 'ADD_LOT_SUCCESS';
const UPDATE_LOT_SUCCESS = 'UPDATE_LOT_SUCCESS';
const DELETE_LOT_SUCCESS = 'DELETE_LOT_SUCCESS';
const FETCH_LOT_SUCCESS = 'FETCH_LOT_SUCCESS';
//const FETCH_LOT_WITH_PAGINATION_SUCCESS = "FETCH_LOT_WITH_PAGINATION_SUCCESS";
const FETCH_LOTS_SUCCESS = 'FETCH_LOTS_SUCCESS';
const FETCH_LOTS_WITH_PAGINATION_SUCCESS = "FETCH_LOTS_WITH_PAGINATION_SUCCESS";
const SET_LOT = 'SET_LOT';
const CLEAR_LOT = 'CLEAR_LOT';
const CLEAR_LOTS = 'CLEAR_LOTS';
const SET_AUCTION = 'SET_AUCTION';
const CLEAR_AUCTION = 'CLEAR_AUCTION';
const SET_LOT_TYPE_ID = 'SET_LOT_TYPE_ID';
const CLEAR_LOT_TYPE_ID = 'CLEAR_LOT_TYPE_ID';

//Images
export const IMAGE_CREATE_SUCCESS = 'IMAGE_CREATE_SUCCESS';
export const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS';
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const IMAGE_GET_ALL_SUCCESS = 'IMAGE_GET_ALL_SUCCESS';
export const SAVE_IMAGES_ERROR = 'SAVE_IMAGES_ERROR';
export const CLEAR_IMAGES = 'CLEAR_IMAGES';


export const LOT_VIDEO_DELETE_SUCCESS = 'LOT_VIDEO_DELETE_SUCCESS';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    lot: undefined,
    lotId: undefined,
    auction: undefined,
    lotTypeId: undefined,
    addSuccess: false,
    editSuccess: false,

    //Images
    lotImages: undefined,
    imagesSuccess: false,
    imagesError: false,

    lotVideoSuccess: false,

    lots: [],
    pagination: null,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, loading: false, error: false, errorText: '', addSuccess: false, editSuccess: false, lotVideoSuccess: false, imagesSuccess: false, lotId: undefined }
        case ADD_LOT_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload, addSuccess: true, editSuccess: false, lotId: action.payload }
        case FETCH_LOT_SUCCESS: return { ...state, error: false, errorText: '', lot: action.payload, reload: !state.reload, addSuccess: false, editSuccess: false, lotId: undefined }
        // case FETCH_LOT_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: '', lot: action.payload, reload: !state.reload, addSuccess: false, editSuccess: false, lotId: undefined }
        case FETCH_LOTS_SUCCESS: return { ...state, lots: action.payload }
        case FETCH_LOTS_WITH_PAGINATION_SUCCESS: return { ...state, lots: action.payload.list, pagination: action.payload.pagination }
        case UPDATE_LOT_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload, editSuccess: true, addSuccess: false }
        case DELETE_LOT_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload }
        case SET_LOT: return { ...state, lot: action.payload }
        case CLEAR_LOT: return { ...state, lot: undefined, lotId: undefined }
        case SET_AUCTION: return { ...state, auction: action.payload }
        case SET_LOT_TYPE_ID: return { ...state, lotTypeId: action.payload }
        case CLEAR_AUCTION: return { ...state, auction: undefined }
        case CLEAR_LOT_TYPE_ID: return { ...state, lotTypeId: undefined }

        case IMAGE_CREATE_SUCCESS: return { ...state, imagesSuccess: true }
        case IMAGE_UPDATE_SUCCESS: return { ...state, imagesSuccess: true }
        case IMAGE_DELETE_SUCCESS: return { ...state, imagesSuccess: true }
        case IMAGE_GET_ALL_SUCCESS: return { ...state, lotImages: action.payload, imagesSuccess: true }
        case CLEAR_IMAGES: return { ...state, lotImages: undefined }
        case CLEAR_LOTS: return { ...state, lots: [] }

        case LOT_VIDEO_DELETE_SUCCESS: return { ...state, lotVideoSuccess: true }

        case DELETE_LOT_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload }
        case SAVE_IMAGES_ERROR: return { ...state, addSuccess: false, editSuccess: false, error: true, errorText: action.payload }

        default: return state;
    }
}

export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const addLotSuccess = (lotId: any) => {
    return { type: ADD_LOT_SUCCESS, payload: lotId }
}

export const fetchLotSuccess = (lot: any) => {
    return { type: FETCH_LOT_SUCCESS, payload: lot }
}

//export const fetchLotWithPaginationSuccess = (lot: any) => {
//    return { type: FETCH_LOT_WITH_PAGINATION_SUCCESS, payload: lot }
//}

export const updateLotSuccess = () => {
    return { type: UPDATE_LOT_SUCCESS, payload: UPDATE_LOT_SUCCESS }
}

export const deleteLotSuccess = () => {
    return { type: DELETE_LOT_SUCCESS, payload: DELETE_LOT_SUCCESS }
}

export const setLot = (lot: any) => {
    return { type: SET_LOT, payload: lot }
}

export const clearLot = () => {
    return { type: CLEAR_LOT, payload: CLEAR_LOT }
}

export const setAuction = (auction: string) => {
    return { type: SET_AUCTION, payload: auction }
}

export const clearAuction = () => {
    return { type: CLEAR_AUCTION, payload: CLEAR_AUCTION }
}

export const setLotTypeId = (id: string) => {
    return { type: SET_LOT_TYPE_ID, payload: id }
}

export const clearLotTypeId = () => {
    return { type: CLEAR_LOT_TYPE_ID, payload: CLEAR_LOT_TYPE_ID }
}

export const createImagesSuccess = () => {
    return { type: IMAGE_CREATE_SUCCESS, payload: IMAGE_CREATE_SUCCESS }
}

export const updateImagesSuccess = () => {
    return { type: IMAGE_UPDATE_SUCCESS, payload: IMAGE_UPDATE_SUCCESS }
}

export const deleteImageSuccess = () => {
    return { type: IMAGE_DELETE_SUCCESS, payload: IMAGE_DELETE_SUCCESS }
}

export const deleteLotVideoSuccess = () => {
    return { type: LOT_VIDEO_DELETE_SUCCESS, payload: LOT_VIDEO_DELETE_SUCCESS }
}

export const retrieveAllImagesSuccess = (files) => {
    return { type: IMAGE_GET_ALL_SUCCESS, payload: files }
}

export const saveImagesError = (errorText: string) => {
    return { type: SAVE_IMAGES_ERROR, payload: errorText }
}

export const clearImages = () => {
    return { type: CLEAR_IMAGES, payload: CLEAR_IMAGES }
}

export const lotsFetchSuccess = (lots: any) => {
    return { type: FETCH_LOTS_SUCCESS, payload: lots }
}

export const lotsFetchWithPaginationSuccess = (pagedLots: any) => {
    return { type: FETCH_LOTS_WITH_PAGINATION_SUCCESS, payload: pagedLots, lotsTotal: 0 }
}

export const clearLots = () => {
    return { type: CLEAR_LOTS }
}

export const handleLotVideoDelete = (lotVideoId: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.delete(apiPaths.lot.deleteLotVideoById, undefined, { lotVideoId })
            .then(function (resp) {
                if (resp.success) dispatch(deleteLotVideoSuccess())
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleLotAdd = (formFields: any, additionalData: any, auctionId: string, images: any[], videos: any[], documents: any[], auction: any, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        let dataCaptured = { additionalProperties: additionalData };
        let latitude = formFields.locationLatitude ? formFields.locationLatitude : 0
        let longitude = formFields.locationLongitude ? formFields.locationLongitude : 0

        let newLot = {
            auctionId,
            lotTypeId: formFields.lotType,
            contactId: formFields.contact,
            name: formFields.name,
            reservePrice: Number(formFields.reservePrice),
            guidePrice: Number(formFields.guidePrice),
            description: formFields.description,
            number: formFields.number,
            depositAmount: Number(formFields.depositAmount),
            defaultMinimumIncrement: Number(formFields.defaultMinimumIncrement),
            dataCaptured: dataCaptured,
            startingPrice: Number(formFields.startingPrice),
            skuNumber: formFields.skuNumber,
            isActive: formFields.isActive,
            defaultBidExtension: (Number(formFields.hours) * 3600) + (Number(formFields.minutes) * 60) + Number(formFields.seconds),
            defaultBidExtensionWindow: (Number(formFields.timeWindowHours) * 3600) + (Number(formFields.timeWindowMinutes) * 60) + Number(formFields.timeWindowSeconds),
            stcStatus: formFields.stcStatus ? 1 : 0,
            showReserve: formFields.showReserve,
            showPaddleNumber: formFields.showPaddleNumber,
            watermarkImages: formFields.watermarkImages,
            commission: Number(formFields.commission),
            locationSearch: formFields.locationSearch?.length > 0 ? formFields.locationSearch : undefined,
            locationVisible: formFields.locationVisible,
            locationLatitude: latitude,
            locationLongitude: longitude,
            biddingCalculationId: formFields.biddingCalculationId,
            incrementTableId: formFields.incrementTableId
        }

        return requestClient.post(apiPaths.lot.addLot, newLot)
            .then(function (res) {
                if (res.success) {
                    handleImagesUploads('add', auctionId, res.response, images, videos, documents, formFields.watermarkImages, dispatch, onCompletedCallback);
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleLotUpdate = (formFields: any, additionalData: any, auctionId: string, images: any[], videos: any[], documents: any[], auction: any, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        let dataCaptured = { additionalProperties: additionalData };
        let latitude = formFields.locationLatitude ? formFields.locationLatitude : 0
        let longitude = formFields.locationLongitude ? formFields.locationLongitude : 0

        let newLot = {
            id: formFields.id,
            auctionId,
            lotTypeId: formFields.lotType,
            contactId: formFields.contact,
            name: formFields.name,
            reservePrice: Number(formFields.reservePrice),
            guidePrice: Number(formFields.guidePrice),
            description: formFields.description,
            number: formFields.number,
            depositAmount: Number(formFields.depositAmount),
            defaultMinimumIncrement: Number(formFields.defaultMinimumIncrement),
            dataCaptured: dataCaptured,
            startingPrice: Number(formFields.startingPrice),
            skuNumber: formFields.skuNumber,
            isActive: formFields.isActive,
            defaultBidExtension: (Number(formFields.hours) * 3600) + (Number(formFields.minutes) * 60) + Number(formFields.seconds),
            defaultBidExtensionWindow: (Number(formFields.timeWindowHours) * 3600) + (Number(formFields.timeWindowMinutes) * 60) + Number(formFields.timeWindowSeconds),
            stcStatus: formFields.stcStatus ? 1 : 0,
            showReserve: formFields.showReserve,
            showPaddleNumber: formFields.showPaddleNumber,
            watermarkImages: formFields.watermarkImages,
            commission: Number(formFields.commission),
            locationSearch: formFields.locationSearch?.length > 0 ? formFields.locationSearch : undefined,
            locationVisible: formFields.locationVisible,
            locationLatitude: latitude,
            locationLongitude: longitude,
            biddingCalculationId: formFields.biddingCalculationId,
            incrementTableId: formFields.incrementTableId
        }

        return requestClient.post(apiPaths.lot.updateLot, newLot)
            .then(function (res) {
                if (res.success) {
                    handleImagesUploads('update', auctionId, formFields.id, images, videos, documents, formFields.watermarkImages, dispatch, onCompletedCallback);
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleLotFetch = (lotId: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.retrieve, null, { lotId: lotId })
            .then(function (res) {
                if (res.success) {
                    let lot = res.response;

                    //if (lot?.dataCaptured)
                    //    lot.dataCaptured = JSON.parse(lot.dataCaptured);
                    dispatch(fetchLotSuccess(lot));
                } else {
                    dispatch(error(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotDelete = (id: string, onCompletedCallback?: (result: any) => void) => {
    return (dispatch: any) => {
        let deleteLot = {
            Id: id
        }

        return requestClient.post(apiPaths.lot.deleteLot, deleteLot)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            })
    }
}

const updateCounts = (obj: any, label: string) => {
    obj.completed = obj.completed + 1;
    showProgressSpinner({ description: `Uploading ${label} ${obj.completed} of ${obj.total}...` });
}

const handleImagesUploads = async (mode: any, auctionId: string, lotId: string, images: Image[], videos: any[], documents: any[], addWatermark: boolean, dispatch: any, onCompletedCallback: (res: any) => void) => {
    const newImages = images.filter(file => file.id === "");

    if (newImages.length > 0) {
        let imagesToUpload = newImages.map(image => {
            return {
                lotId: lotId,
                isDisplayImage: image.isDisplayImage,
                lotImageUploadData: [{ imageBase64String: image.imageUrl }],
                orderInt: image.orderInt,
                addWatermark
            }
        });

        let counts = {
            completed: 0,
            total: imagesToUpload.length
        };
        updateCounts(counts, 'image');

        let failedImages: any[] = [];
        while (imagesToUpload.length) {
            await Promise.all(imagesToUpload.splice(0, 10).map((imageToUpload, index, list) => requestClient.post(apiPaths.lot.imagesCreate, imageToUpload).then(function (resp) {
                if (resp?.success) {
                    updateCounts(counts, 'image');
                }
                else {
                    failedImages.push({ image: imagesToUpload, res: resp });
                }
            })));
        }
        if (failedImages.length > 0) {
            onCompletedCallback({ success: false, error: ` details ${mode === 'add' ? 'saved' : 'updated'} successfully, but some images failed to upload!` });
        }

        else {
            showProgressSpinner({ description: `Completed Uploading Images` });
            handleVideoUploads(mode, auctionId, lotId, videos, documents, dispatch, onCompletedCallback);
        }
    }
    else
        handleVideoUploads(mode, auctionId, lotId, videos, documents, dispatch, onCompletedCallback);
}

const handleVideoUploads = async (mode: any, auctionId: string, lotId: string, videos: any[], documents: any[], dispatch: any, onCompletedCallback: (res: any) => void) => {
    if (videos?.length > 0) {
        let videosToUpload = videos.map(video => {
            return {
                id: video.id,
                videoUrl: video.videoUrl,
                orderInt: video.orderInt,
                lotId: lotId
            };
        });

        let counts = {
            completed: 0,
            total: videosToUpload.length
        };
        updateCounts(counts, 'video');

        let failedVideos: any[] = [];
        while (videosToUpload.length) {
            await Promise.all(videosToUpload.splice(0, 5).map((videoToUpload, index, list) => requestClient.post(apiPaths.lot.upsertLotVideo, videoToUpload).then(function (resp) {
                if (resp?.success) {
                    updateCounts(counts, 'video');
                }
                else {
                    failedVideos.push({ image: videoToUpload, res: resp });
                }
            })));
        }
        if (failedVideos.length > 0) {
            onCompletedCallback({ success: false, error: ` details and images ${mode === 'add' ? 'saved' : 'updated'} successfully, but some videos failed to upload!` });
        }
        else {
            showProgressSpinner({ description: `Completed Uploading Videos` });
            handleDocumentUploads(mode, auctionId, lotId, documents, dispatch, onCompletedCallback);
        }
    }
    else
        handleDocumentUploads(mode, auctionId, lotId, documents, dispatch, onCompletedCallback);
}

const handleDocumentUploads = async (mode: any, auctionId: string, lotId: string, documents: any[], dispatch: any, onCompletedCallback: (res: any) => void) => {
    const newDocuments = documents.filter(doc => doc.fileUploadData || !doc.documentId);

    if (documents.length > 0) {
        let documentsToUpload = newDocuments.map(document => {
            return {
                documentTemplateId: document.documentTemplateId,
                auctionId: auctionId,
                lotId: lotId,
                documentUploadData: [{ documentBase64String: document.fileUploadData.base64 }]
            }
        });

        let counts = {
            completed: 0,
            total: documentsToUpload.length
        };
        updateCounts(counts, 'document');

        let failedDocuments: any[] = [];
        while (documentsToUpload.length) {
            await Promise.all(documentsToUpload.splice(0, 5).map((documentToUpload, index, list) => requestClient.post(apiPaths.document.upsertRegistrationDocument, documentToUpload).then(function (resp) {
                if (resp?.success) {
                    updateCounts(counts, 'document');
                }
                else {
                    failedDocuments.push({ image: documentToUpload, res: resp });
                }
            })));
        }
        if (failedDocuments.length > 0) {
            onCompletedCallback({ success: false, error: ` details, images and videos ${mode === 'add' ? 'saved' : 'updated'} successfully, but some documents failed to upload!` });
            hideProgressSpinner();
        }
        else {
            showProgressSpinner({ description: `Completed Uploading Documents` });
            dispatch(mode === 'add' ? addLotSuccess(lotId) : updateLotSuccess());
            onCompletedCallback({
                success: true,
                response: lotId
            });
            hideProgressSpinner();
        }
    }
    else {
        dispatch(mode === 'add' ? addLotSuccess(lotId) : updateLotSuccess());
        onCompletedCallback({
            success: true,
            response: lotId
        });
        hideProgressSpinner();
    }
}

export const handleImageDelete = (imageId: string) => {
    let del = {
        id: imageId
    };

    return (dispatch: any) => {
        return requestClient.post(apiPaths.lot.imagesDelete, del)
            .then(function (resp) {
                if (resp.success) {
                    dispatch(deleteImageSuccess());
                }
                else {
                    dispatch(saveImagesError(resp.error));
                }
            });
    }
}

export const handleRetrieveAllImagesByLotId = (lotId: string) => {
    let getAll = {
        lotId: lotId
    };

    return (dispatch: any) => {
        return requestClient.get(apiPaths.lot.imagesRetrieveAllByLotId, null, getAll)
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllImagesSuccess(resp.response));
                }
                else {
                    dispatch(saveImagesError(resp.error));
                }
            });
    }
}

export const handleClearImages = () => {
    return (dispatch: any) => {
        dispatch(clearImages());
    }
}

export const handleSetImages = (images: any[]) => {
    return (dispatch: any) => {
        dispatch(retrieveAllImagesSuccess(images));
    }
}

export const handleLotsFetch = (searchString: string | null, onlyIndividualLots: boolean = false, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.getLots, null, { searchString, onlyIndividualLots })
            .then(function (res) {
                if (res.success) {
                    let lots = res.response;
                    dispatch(lotsFetchSuccess(lots))
                } else {
                    dispatch(error(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotsFetchWithPagination = (onlyIndividualLots: boolean = false,
    filters?: any,
    pagination?: any,
    onCompletedCallback?: (response: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.getLots, {
            onlyIndividualLots,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(lotsFetchWithPaginationSuccess(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleIndividualLotsLinking = (auctionId: string, lotIds: string[], onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.linkLots + '/' + auctionId, lotIds)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleIndividualLotsUnlinking = (lotId: string, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.unlinkLot, lotId)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleIndividualLotsUnlinkAllFromAuction = (auctionId: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.unlinkAllLotsFromAuction, auctionId)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res);
            })
    }
}

export const handleReorderLotsForAuction = (auctionId: string, orderedLots: any, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.reorderLotsForAuction, { auctionId, orderedLots })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}