import { phoneRegex, youtubeRegex1, youtubeRegex2 } from './Regex';
import { format as fnsFormat } from 'date-fns';
import { isString, isNumber } from 'lodash';

interface Enum {
    [id: number]: string
};

export const getEnumLabels = (e: Enum) => {
    return Object.keys(e).filter((i, index, list) => isString(i) || isNumber(i)).map(key => makeFriendlyString(e[key])).filter(x => x !== '');
};

export const makeFriendlyString = str => {
    if (!str || !str.length) return '';
    let seperatedList = str.match(/([A-Z]+[a-z]+)|([a-z]+)|([\d]+)/g);
    if (seperatedList) str = seperatedList?.join(' ');
    if (str.length > 1) str = str[0].toUpperCase() + str.slice(1);
    return str;
};

// RFC822 email validation
//export const isValidEmail = (emailAddress, noneIsValid = false) => {
//    if (noneIsValid && (!emailAddress || emailAddress.trim() === '')) return true;
//    const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
//    const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
//    const sAtom =
//        '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
//    const sQuotedPair = '\\x5c[\\x00-\\x7f]';
//    const sDomainLiteral = `\\x5b(${sDtext}|${sQuotedPair})*\\x5d`;
//    const sQuotedString = `\\x22(${sQtext}|${sQuotedPair})*\\x22`;
//    const sDomainRef = sAtom;
//    const sSubDomain = `(${sDomainRef}|${sDomainLiteral})`;
//    const sWord = `(${sAtom}|${sQuotedString})`;
//    const sDomain = `${sSubDomain}(\\x2e${sSubDomain})*`;
//    const sLocalPart = `${sWord}(\\x2e${sWord})*`;
//    const sAddrSpec = `${sLocalPart}\\x40${sDomain}`; // complete RFC822 email address spec
//    const sValidEmail = `^${sAddrSpec}$`; // as whole string
//    const reValidEmail = new RegExp(sValidEmail);
//    return reValidEmail.test(emailAddress);
//};

export const isValidEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidPhoneNumber = (phoneNumber, noneIsValid = false) => {
    if (noneIsValid && (!phoneNumber || phoneNumber.trim() === '')) return true;
    return phoneRegex.test(phoneNumber);
}

export const validateYoutubeUrl = (url: any) => {
    let match = url.match(youtubeRegex1);
    let match2 = url.match(youtubeRegex2);

    return (match && match[2].length == 11 && match2);
}

export const validateMultipleWordsInString = (value: string, validator: string) => {
    if (occurrences(value, validator, false) > 1) {
        return true;
    } else {
        return false;
    }
}

function occurrences(string, subString, allowOverlapping) {

    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length;

    while (true) {
        pos = string.indexOf(subString, pos);
        if (pos >= 0) {
            ++n;
            pos += step;
        } else break;
    }
    return n;
}

export function containsSpecialChars(str) {
    const specialChars =
        /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
}

export function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}

export function toPascalCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});


export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const setJsonValue = (json, path, data) => {
    const isJson = obj => {
        return obj && typeof obj === 'object';
    };

    if (!isJson(json) || !path || typeof path !== 'string') return;

    const parts = path.split('.');
    let value = json;
    for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        const existing = value[part];

        if (existing === null || typeof existing === 'undefined') {
            value[part] = {};
        }

        value = value[part];
    }

    value[parts[parts.length - 1]] = data;
};

export function formatDateTime(date: Date | string, format: string) {
    if (date === null || date === undefined) return "";
    if (isString(date)) return fnsFormat(new Date(date), format);
    else return fnsFormat(date as Date, format)
}

const LOT_NUMBER_CHAR_LIMIT = 30;

export const limitConsolidatedLotNumbers = (lotNumbers: string) => {
    if (lotNumbers === undefined) return '-';

    if (lotNumbers.length > LOT_NUMBER_CHAR_LIMIT) {
        return lotNumbers.substring(0, LOT_NUMBER_CHAR_LIMIT) + '...';
    }
    return lotNumbers;
}

export const formatCurrency = (value) => {
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9.]/g, "");
    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = numericValue.split(".");
    // Remove left padded zeros except when the integer value is zero
    let formattedIntegerPart = integerPart.replace(/^0+(?!\.|$)/, "");
    // Add commas to the integer part every three digits
    formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Concatenate integer and decimal parts
    const formattedValue = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    return formattedValue;
};
