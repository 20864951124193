import { useDropzone, FileError } from 'react-dropzone';
import { Button, Grid, Card, Badge, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { FaTrashAlt } from "react-icons/fa";
import { MdClose, MdCheck } from "react-icons/md";
import Resizer from 'react-image-file-resizer';
import ns from '@/helpers/NotificationService';
import HeaderText from '../HeaderText/HeaderText';

interface Props {
    newFilesAdded: (images: Image[]) => void;
    deleteImage: (id: string) => void;
    lotImages: any;
    isDisplayImage: boolean;
    header: string;
    description: string;
    min?: number;
    max?: number;
    onValidate?: (valid: boolean) => void;
    acceptedFileExtensions: string;
}

export interface Image {
    id: string;
    uploadedFile: File | undefined;
    imageUrl: string;
    isDisplayImage: boolean;
    orderInt: number;
}

const ImageUploader: React.FC<Props> = props => {
    const {
        deleteImage,
        newFilesAdded,
        onValidate,
        lotImages,
        isDisplayImage,
        header,
        description,
        min = 0,
        max = 50,
        acceptedFileExtensions
    } = props;
    const [uploadedFiles, setUploadedFiles] = React.useState([] as Image[]);
    const [isBusy, setIsBusy] = React.useState(false);

    const imageValidator = (file: any): any => {
        if (!acceptedFileExtensions.includes(file.type)) {
            setIsBusy(false);
            // ns.error(`Image ${file.name} is in the incorrect format. We only support .jpeg, .jpg and .png file types`);
            return {
                code: "incorrect-format",
                message: "Supported file types are .JPEG | .JPG | .PNG"
            };
        }

        /* Added code */
        if (file.size > 10485760) {
            setIsBusy(false);
            ns.error(`Image ${file.name} is too big. A maximum image size of 10MB is allowed`);
            return {
                code: "size-too-large",
                message: "Size is larger than 10mb"
            };
        }

        return null;
    }

    const {
        acceptedFiles,
        fileRejections,
        isDragActive,
        isDragAccept,
        isDragReject, getRootProps, getInputProps
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'text/html': ['.html', '.htm'],
            'image/jpeg': ['.jpeg', '.jpg']
        },
        multiple: true,
        validator: imageValidator
    });

    React.useEffect(() => {
        let countProcessing = acceptedFiles?.length ?? 0;
        let countUploaded = 0;
        if (isDisplayImage) {
            countUploaded = uploadedFiles.filter(file => file.isDisplayImage).length;
        } else {
            countUploaded = uploadedFiles.filter(file => !file.isDisplayImage).length;
        }

        if ((min === 1 && max === 1 && countProcessing === 1) || max >= (countUploaded + countProcessing)) {
            const imageUrls: Image[] = [];
            const resizedImages: any[] = acceptedFiles.map((file, i) => resizeFile(file, i,
                uploadedFiles?.length > 0 ? uploadedFiles[uploadedFiles.length - 1].orderInt + 1 : 0, isDisplayImage));

            Promise.allSettled(resizedImages).then((responses) => {
                (responses as unknown as any[]).forEach((resizeResult) => {
                    imageUrls.push(resizeResult.value);
                    setIsBusy(false);
                });

                if (min === 1 && max === 1 && imageUrls.length === 1 && countUploaded === 1) {
                    let fileToDelete: any = undefined;
                    if (isDisplayImage) {
                        fileToDelete = uploadedFiles.filter(file => file.isDisplayImage)[0];
                    } else {
                        fileToDelete = uploadedFiles.filter(file => !file.isDisplayImage)[0];
                    }
                    if ((fileToDelete?.id ?? "") !== "" && deleteImage) deleteImage(fileToDelete.id);
                    setUploadedFiles(imageUrls);
                }
                else if (imageUrls.length > 0)
                    setUploadedFiles(uploadedFiles.concat(imageUrls));
            });
        } else {
            ns.error("The maximum amount of images allowed has been reached");
            setIsBusy(false);
        }
    }, [acceptedFiles]);

    React.useEffect(() => {
        if (lotImages) {
            let imageUrls: Image[] = lotImages.map(image => {
                return {
                    id: image.id,
                    uploadedFile: undefined,
                    imageUrl: image.imageUrl,
                    isDisplayImage: image.isDisplayImage,
                    orderInt: image.orderInt
                } as Image
            });

            setUploadedFiles(imageUrls);
        } else {
            setUploadedFiles([]);
        }
    }, [lotImages]);

    React.useEffect(() => {
        if (uploadedFiles) newFilesAdded(uploadedFiles);

        validate(uploadedFiles);
    }, [uploadedFiles]);

    const validate = (uploadedFiles) => {
        let valid = false;

        if (uploadedFiles?.length >= min)
            valid = true;

        if (onValidate) {
            onValidate(valid);
        }
    }

    useEffect(() => {
        if (fileRejections?.length === 1) ns.error(`Image ${fileRejections?.[0]?.file.name} is in the incorrect format. We only support .jpeg, .jpg and .png file types`);
        else if (fileRejections?.length > 1) ns.error(`Some images are in the incorrect format. We only support .jpeg, .jpg and .png file types`);
    }, [fileRejections])

    const resizeFile = (file: File, orderInt: number, offset: number, isDisplayImage: boolean) => new Promise(resolve => {
        if (!isBusy) setIsBusy(true);
        Resizer.imageFileResizer(file, 1920, 1080, 'JPEG', 50, 0,
            base64 => {
                const file = {
                    id: "",
                    uploadedFile: undefined,
                    imageUrl: base64,
                    isDisplayImage: isDisplayImage,
                    orderInt: offset + orderInt
                } as Image
                resolve(file);
            }, 'base64', undefined, undefined
        );
    });

    const remove = (file, index, list) => {
        if (min > 0 && list?.length === min) return;

        if (file && file.id && file.id !== "") {
            if (deleteImage)
                deleteImage(file.id);
        }

        const currentIndex = uploadedFiles.indexOf(file);
        const newState = uploadedFiles.slice();
        newState.splice(currentIndex, 1);
        setUploadedFiles(newState);
    }

    return (
        <div>
            <HeaderText header={header} description={description} className="pl-4" />
            <div className="dropzone">
                <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                    <input {...getInputProps()} />
                    <div className="dropzone-inner-wrapper">
                        {uploadedFiles.length < min && (<Badge badgeContent={'*'} className="badge badge-position badge-position--top-left badge-circle mt-2" color="error" />)}

                        {isDragAccept && (
                            <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success rounded-circle text-white">
                                    <MdCheck className="d-50" />
                                </div>
                                <div className="font-size-sm text-success">Drop to add</div>
                            </div>
                        )}
                        {isDragReject && (
                            <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger rounded-circle text-white">
                                    <MdClose className="d-50" />
                                </div>
                                <div className="font-size-sm text-danger">
                                    {isDisplayImage === false ? (<>Incorrect file type. A max of 50 JPEG / JPG / PNG images no larger than 10mb are accepted.</>) :
                                        (<>Incorrect file type. A max of 1 JPEG / JPG / PNG image no larger than 10mb is accepted.</>)}
                                </div>

                            </div>
                        )}

                        <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            spacing={0}>
                            <Grid item xs={12}>
                                {!isDragActive && (
                                    <span className="text-black">
                                        {
                                            isDisplayImage === false ? (<>Please drag and drop or browse your files to upload up to 50 JPEG / JPG / PNG images.</>)
                                                : (<>Please drag and drop or browse your files to upload a single JPEG / JPG / PNG image.</>)
                                        }
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {!isDragActive && (
                                    <span className="text-black">Please upload an image with 16x9 dimensions for best results (Examples 3840 x 2160, 2560 x 1440, 1920 x 1080, 1280 x 720)</span>
                                )}
                            </Grid>
                            <Grid item xs={12} className="mt-3">
                                {!isDragActive && (
                                    <span className="text-black">Restrictions</span>
                                )}
                            </Grid>
                            <Grid item xs={12} className="mt-3">
                                {!isDragActive && (
                                    <span className="text-black">
                                        {
                                            isDisplayImage === false ? (<>1 - Maximum of 50 Photos with a maximum size of 10MB per Image</>)
                                                : (<>1 - A single image with a maximum size of 10MB</>)
                                        }
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {!isDragActive && (
                                    <span className="text-black">2 - Dimensions are not forced, but images may be cut to scale</span>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={isBusy} className="btn-primary p-3 text-white mt-3 text-capitalize">
                                    <span className="px-2">{!isBusy ? "Browse Images" : "Processing..."}</span>
                                </Button>
                            </Grid>
                            {fileRejections?.length > 0 && <Grid item xs={12} className='mt-4 text-left'>
                                <span className='text-black'>Images with incorrect format: </span> <br />
                                <span className="text-danger p-0 m-0">
                                    {fileRejections.map(x => x.file.name).filter(x => x).join(", ")}
                                </span>
                            </Grid>}
                        </Grid>
                    </div>
                </div>
            </div>

            {isDisplayImage === true ? (<>
                <div className="m-2">
                    <Grid container spacing={1}>
                        {uploadedFiles.filter(file => file.isDisplayImage).sort((image1, image2) => {
                            if (image1.orderInt > image2.orderInt)
                                return 1;
                            if (image1.orderInt < image2.orderInt)
                                return -1;
                            return 0;
                        }).map((file, index, list) => (
                            <Grid item xs={4} sm={2} key={index}>
                                <a href="#/" onClick={e => e.preventDefault()} className="card-img-wrapper rounded">
                                    {list?.length > min && <div className="img-wrapper-overlay">
                                        <div className="overlay-btn-wrapper">
                                            <Tooltip arrow title="Remove" placement="bottom">
                                                <Button onClick={() => remove(file, index, list)} size="small" className="btn-neutral-danger m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                                                    <FaTrashAlt />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>}
                                    <img src={file.imageUrl} className="card-img-top rounded" alt="..." />
                                </a>
                            </Grid>
                        ))}</Grid>
                </div></>)
                :
                (<>
                    <div className="m-2">
                        <Grid container spacing={1}>
                            {uploadedFiles.filter(file => !file.isDisplayImage).sort((image1, image2) => {
                                if (image1.orderInt > image2.orderInt)
                                    return 1;
                                if (image1.orderInt < image2.orderInt)
                                    return -1;
                                return 0;
                            }).map((file, index, list) => (
                                <Grid item xs={4} sm={2} key={index}>
                                    <a href="#/" onClick={e => e.preventDefault()} className="card-img-wrapper rounded">
                                        {list?.length > min && <div className="img-wrapper-overlay">
                                            <div className="overlay-btn-wrapper">
                                                <Tooltip arrow title="Remove" placement="bottom">
                                                    <Button onClick={() => remove(file, index, list)} size="small" className="btn-neutral-danger m-2 btn-icon hover-scale-lg btn-animated-icon d-50 p-0 border-0 rounded-lg">
                                                        <FaTrashAlt />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        </div>}
                                        <img src={file.imageUrl} className="card-img-top rounded" alt="..." />
                                    </a>
                                </Grid>
                            ))}
                        </Grid></div></>)
            }
        </div>
    );
}

export default ImageUploader