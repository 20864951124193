import { memo, FC } from "react";
import { Button, Fab } from "@mui/material";

interface ReOrderButtonProps {
    onClick: () => void;
    settings: any;
}

const ReOrderButton: FC<ReOrderButtonProps> = (props) => {

    const {
        onClick,
        settings
    } = props;

    return (
        <Button
            onClick={onClick}
            variant="contained"
            className="btn-primary p-3 mt-2 text-white text-capitalize"
            size="large"
            style={{
                position: 'fixed',
                bottom: 25,
                background: "#2b2b2b",
            }}>
            {'Reorder ' + settings.LotName + 's'}
        </Button>
    );
};

export default memo(ReOrderButton);
