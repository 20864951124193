import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const ERROR = 'LOT_TYPE_LIST_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const ADD_LOT_TYPE_SUCCESS = 'ADD_LOT_TYPE_SUCCESS';
const LOT_TYPES_FETCH_SUCCESS = 'LOT_TYPES_FETCH_SUCCESS';
const LOT_TYPES_FETCH_WITH_PAGINATION_SUCCESS = 'LOT_TYPES_FETCH_WITH_PAGINATION_SUCCESS';
const UPDATE_LOT_TYPE_SUCCESS = 'UPDATE_LOT_TYPE_SUCCESS';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const SET_LOT_TYPE = 'SET_LOT_TYPE';
const CLEAR_LOT_TYPE = 'CLEAR_LOT_TYPE';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const LOT_TYPE_FETCH_SUCCESS = 'LOT_TYPE_FETCH_SUCCESS';
const NEW_LOT_TYPE = 'NEW_LOT_TYPE';
const NEW_LOT_TYPE_LOT_ID = 'NEW_LOT_TYPE_LOT_ID';
const NEW_LOT_TYPE_AUCTION = 'NEW_LOT_TYPE_AUCTION';
const NEW_LOT_TYPE_LOTS = 'NEW_LOT_TYPE_LOTS';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    lotTypes: [],
    lotType: undefined,
    lotTypeRequest: 'Add',
    lotTypeId: '',
    selectLotType: undefined,
    newLotType: undefined,
    selectLotTypeAuction: undefined,
    selectLotTypeLots: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_ERROR: return { ...state, error: false, errorText: '', success: false }
        case ADD_LOT_TYPE_SUCCESS: return { ...state, error: false, errorText: '', lotTypeId: action.payload, reload: !state.reload, lotTypeRequest: 'Edit', success: true }
        case LOT_TYPES_FETCH_SUCCESS: return { ...state, error: false, errorText: '', lotTypes: action.payload, lotTypeRequest: 'Add', lotTypeId: '', lotType: undefined }
        case LOT_TYPES_FETCH_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: '', lotTypes: action.payload.list, pagination: action.payload.pagination, lotTypeRequest: 'Add', lotTypeId: '', lotType: undefined }
        case LOT_TYPE_FETCH_SUCCESS: return { ...state, error: false, errorText: '', lotType: action.payload, lotTypeRequest: 'Edit' }
        case UPDATE_LOT_TYPE_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload, success: true, venueTypeRequest: 'Edit' }
        case DELETE_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload, lotTypeRequest: 'Add' }
        case SET_LOT_TYPE: return { ...state, error: false, errorText: '', lotType: action.payload, lotTypeRequest: 'Edit' }
        case CLEAR_LOT_TYPE: return { ...state, lotType: undefined, lotTypeRequest: 'Add' }
        case CLEAR_API_RESPONSES: return { ...state, success: false, lotTypeId: '' }
        case NEW_LOT_TYPE: return { ...state, selectLotType: action.payload }
        case NEW_LOT_TYPE_LOT_ID: return { ...state, newLotType: action.payload }
        case NEW_LOT_TYPE_AUCTION: return { ...state, selectLotTypeAuction: action.payload }
        case NEW_LOT_TYPE_LOTS: return { ...state, selectLotTypeLots: action.payload }
        default: return state;
    }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearError = () => {
    return { type: CLEAR_ERROR, payload: CLEAR_ERROR }
}

export const addLotTypeSuccess = (lotTypeId: string) => {
    return { type: ADD_LOT_TYPE_SUCCESS, payload: lotTypeId }
}

export const deleteSuccess = (success: string) => {
    return { type: DELETE_SUCCESS, payload: success }
}

export const lotTypesGetSuccess = (lotTypesData: any) => {
    return { type: LOT_TYPES_FETCH_SUCCESS, payload: lotTypesData }
}

export const lotTypesGetWithPaginationSuccess = (lotTypesData: any) => {
    return { type: LOT_TYPES_FETCH_WITH_PAGINATION_SUCCESS, payload: lotTypesData, lotTypesTotal: 0 }
}

export const lotTypeGetSuccess = (lotTypeData: any) => {
    return { type: LOT_TYPE_FETCH_SUCCESS, payload: lotTypeData }
}

export const lotTypeUpdateSuccess = () => {
    return { type: UPDATE_LOT_TYPE_SUCCESS, payload: UPDATE_LOT_TYPE_SUCCESS }
}

export const setLotType = (lotType: any) => {
    return { type: SET_LOT_TYPE, payload: lotType }
}

export const clearLotType = () => {
    return { type: CLEAR_LOT_TYPE, payload: CLEAR_LOT_TYPE }
}

export const newLotTypeId = (selectLotType) => {
    return { type: NEW_LOT_TYPE, payload: selectLotType }
}

export const newLotType = (newLotType) => {
    return { type: NEW_LOT_TYPE_LOT_ID, payload: newLotType }
}

export const newLotTypeAuction = (selectLotTypeAuction) => {
    return { type: NEW_LOT_TYPE_AUCTION, payload: selectLotTypeAuction }
}

export const newLotTypeLots = (selectLotTypeLots) => {
    return { type: NEW_LOT_TYPE_LOTS, payload: selectLotTypeLots }
}

export const handleLotTypeAdd = (lotTypeInfo: any, additionalData: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        let dataCaptured = { defaultAdditionalProperties: additionalData };

        let newLotType = {
            name: lotTypeInfo.name,
            description: lotTypeInfo.description,
            dataCaptured: dataCaptured
        }

        return requestClient.post(apiPaths.lot.addLotType, newLotType)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotTypesFetch = (onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.getLotTypes, null, null)
            .then(function (res) {
                if (res.success) {
                    let lotTypes = res.response;
                    dispatch(lotTypesGetSuccess(lotTypes))
                } else {
                    dispatch(error(res.error))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotTypesFetchWithPagination = (filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };
    
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.getLotTypesWithPagination, {
            pagination,
            ...(filters || {})
        })
            .then(function (res) {
                if (res.success) {
                    let lotTypes = res.response;
                    dispatch(lotTypesGetWithPaginationSuccess(lotTypes))
                } else {
                    dispatch(error(res.error))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotTypeFetch = (id: string) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.retrieveLotType, null, { lotTypeId: id })
            .then(function (res) {
                if (res.success) {
                    let lotType = res.response;
                    //if (lotType.dataCaptured)
                    //    lotType.dataCaptured = JSON.parse(lotType.dataCaptured);
                    dispatch(lotTypeGetSuccess(lotType))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleLotTypeUpdate = (lotTypeInfo: any, additionalData: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {

        let dataCaptured = { defaultAdditionalProperties: additionalData };

        let newLotType = {
            name: lotTypeInfo.name,
            id: lotTypeInfo.id,
            description: lotTypeInfo.description,
            dataCaptured: dataCaptured
        }

        return requestClient.post(apiPaths.lot.updateLotType, newLotType)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleDelete = (Id: string, onCompletedCallback?: (result: any) => void) => {

    return (dispatch: any) => {

        let lotTypeInfo = {
            Id: Id,
        };

        return requestClient.post(apiPaths.lot.deleteLotTypes, lotTypeInfo)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            })
    }
}