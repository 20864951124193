export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
export const phoneRegex = /^[\+0-9]+$/
export const passwordStrongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{6,})");
export const passwordMediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
export const passwordValidationError = ["\u2022 Passwords must have at least 6 characters", "\u2022 Include one capital letter", "\u2022 Include one lowercase letter", "\u2022 Include one number", "\u2022 Include one special character"]
export const youtubeRegex1 = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*)/;
export const youtubeRegex2 = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const zeroToHundredPattern = /^(100(\.0+)?|0?(\.\d{1,2})?|\d{1,2}(\.\d{1,2})?)$/;

export function getCurrency(num: any, currency: string) {
    return (
        currency +
        parseFloat(num)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
}