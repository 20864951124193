import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { handleSubmit, forgotPasswordResetState } from "./store/ForgotPasswordCard";
import useStyles from "./ForgotPasswordCardStyles";
import ns from '@/helpers/NotificationService';
import {
    Button,
    Typography,
    CssBaseline,
    Container,
    TextField
} from "@mui/material";
import useHistory from '@/hooks/useHistory';
import { Alert } from "@mui/lab";

import { emailRegex } from "@/helpers/Regex";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { isValidEmail } from '../../helpers/Utils';

interface Props {
    handleSubmit_d: (email: string, logoUrl: string) => void,
    forgotPasswordResetState_d: () => void,
    error: boolean,
    errorText: string,
    success: boolean,
    settings: any,
    loading: boolean,
}

const ForgotPasswordCard: React.FC<Props> = (props) => {

    const history = useHistory();

    const {
        handleSubmit_d,
        forgotPasswordResetState_d,
        error,
        errorText,
        success,
        settings,
        loading
    } = props;

    const { classes, cx } = useStyles();

    const intialValues = { email: "" };

    const [formFields, setFormFields] = useState(intialValues);
    const [formErrors, setFormErrors] = useState(intialValues);

    const onChange = (e: { target: { id: any; value: string } }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value });
    };

    const onSubmit = () => {
        const onSubmitErrors = validate(formFields);
        setFormErrors(onSubmitErrors);

        if (onSubmitErrors.email.length === 0) {
            showProgressSpinner({ description: 'Sending Email...' })
            handleSubmit_d(formFields.email, settings.VendorLogoUrl);
        }
    };

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            redirect();
        }
        forgotPasswordResetState_d();

    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) ns.error(errorText);

        forgotPasswordResetState_d();
    }, [error]);

    const validate = (values) => {
        let errors = { email: '' };

        if (!values.email) {
            errors.email = "Email field cannot be blank";
        } else if (!isValidEmail(values.email.replace(" ", ""))) {
            errors.email = "Please enter a valid email address.";
        }
        return errors;
    };

    const redirect = () => {
        let path = '/ForgotPasswordConfirmation';
        history.push(path);
    };

    const navigateBack = () => {
        let path = '/login';
        history.push(path);
    };

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <>
            {loading === false && (<>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper} style={{ marginLeft: "29" }}>
                        <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                        <h3 className="text-black text-center">Forgot Password</h3>

                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            value={formFields.email}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            label="Email"
                            autoFocus={true}
                        />

                        {formErrors.email && (<Alert severity='error'>{formErrors.email}</Alert>)}
                        <h5 className="text-center">We will send you an email to reset your password.</h5>
                        <br />
                        <Button
                            className="btn-primary p-3 mt-4 mb-2 text-white text-capitalize"
                            type="submit"
                            onClick={onSubmit}>
                            Submit
                        </Button>
                        <Button
                            variant="contained" className="btn-primary p-3 mb-1 text-white text-capitalize"
                            style={{background: '#2b2b2b'}}
                            type="submit"
                            onClick={navigateBack}>
                            Cancel
                        </Button>
                    </div>
                </Container>
            </>)
            }
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (email: string, logoUrl: string) => dispatch(handleSubmit(email, logoUrl)),
        forgotPasswordResetState_d: () => dispatch(forgotPasswordResetState())
    };
};

const mapStateToProps = (state: any) => ({
    success: state.forgotPasswordCard.success,
    error: state.forgotPasswordCard.error,
    errorText: state.forgotPasswordCard.errorText,
    token: state.forgotPasswordCard.token,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordCard);
