import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import {NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordionGrid'
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { clearContactsState, handleContactsFetchWithPagination, handleDeleteContact } from './store/Contacts';
import ns from '@/helpers/NotificationService';
import { DialogConfig } from '@/components/Dialog/store/Dialog';
import { Avatar, Button, Chip, Tooltip } from '@mui/material';
import { FaRedo } from 'react-icons/fa';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    contactsState: any,
    handleContactsFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    clearContactsState_d: () => void,
    handleContactDelete_d: (contactId: string, onCompletedCallback: (response: any) => void) => void,
    loading: boolean,
    pagination: any,
}

const Contacts: React.FC<Props> = props => {
    const {
        handleContactsFetchWithPagination_d,
        clearContactsState_d,
        handleContactDelete_d,
        contactsState,
        loading,
        pagination,
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingContacts, setLoadingContacts] = useState<boolean>(true);
    const [contactsDisplayModel, setContactsDisplayModel] = useState<NestedGridDataModel>();

    useEffect(() => {
        refreshList(null, null, false);
        return () => {
            clearContactsState_d();
        };
    }, [])

    useEffect(() => {
        if (contactsState.list) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "First Name", align: "inherit" },
                { title: "Last Name", align: "inherit" },
                { title: "Email", align: "inherit" },
                { title: "Phone Number", align: "inherit" }
            ];

            rows = contactsState.list?.map(contact => {

                let contactFirstName = contact.firstName?.substring(0, 35);
                if (contact.firstName.length > 35) contactFirstName += "...";

                let contactLastName = contact.lastName?.substring(0, 35);
                if (contact.lastName.length > 35) contactLastName += "...";

                const cells: Cell[] = [
                    { text: contactFirstName, align: "inherit", element: <>{contactFirstName}{contact.source && contact.source !== 'Internal' && <><br/><Chip color="primary" size="small" label={contact.source} style={{ marginLeft: -8 }} /></>}</> },
                    { text: contactLastName, align: "inherit" },
                    { text: contact.email, align: "inherit" },
                    { text: contact.phoneNumber, align: "inherit" },
                ];

                return {
                    id: contact.id,
                    cells: cells
                };
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setContactsDisplayModel(model);
        }

        var waitForGridRender = new Promise(r => setTimeout(r, 500));
        waitForGridRender.then(() => hideProgressSpinner());
    }, [contactsState.list])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const handleAddEdit = (id?: string) => {
        id ? history.push(`/ContactEdit?contactId=${id}`) : history.push("/ContactAdd");
    }

    const handleDelete = (id: string) => {
        let contact = contactsState?.list?.find(x => x.id === id);

        let dialogConfig: DialogConfig = {
            title: 'Confirm',
            message: `Are you sure you would like to delete the contact${contact ? ' ' + contact.firstName : ''}?`,
            locales: { submitText: 'Yes', cancelText: 'Cancel' },
            onCancel: () => {

            },
            onSubmit: () => {
                showProgressSpinner({ description: "Deleting Contact..." });
                handleContactDelete_d(id, (res) => {
                    if (res.success) {
                        refreshList(selectedFilters, pagination);
                        ns.success("Contact/Agent deleted successfully");
                    }
                    else {
                        ns.error("Failed to delete the contact")
                    }
                });
            }
        }
        ns.showDialog(dialogConfig);
    }

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingContacts(true);
        else showProgressSpinner({ description: `Retrieving Contacts...` });
        handleContactsFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingContacts(false);
        })
    }

    const handlePageChange = (pagination?: any) => {
        refreshList(selectedFilters, pagination);
    }
    const handleFiltersChange = (filters?: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    return (<>
        <React.Fragment>
            {!loading && <><div className="card-header py-3"><div className="card-header--title font-size-lg">Contacts/Agents</div></div>
                <div className="table-responsive-md">
                    <NestedAccordionGrid
                        dataModel={contactsDisplayModel}
                        onDelete={handleDelete}
                        onEdit={handleAddEdit}
                        onAdd={() => handleAddEdit()}
                        selectedFilters={selectedFilters}
                        pagination={pagination}
                        onHandleChangePage={handlePageChange}
                        onFiltersChange={handleFiltersChange}
                        loading={loadingContacts}
                    />
                </div>
            </>}
        </React.Fragment>
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleContactsFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleContactsFetchWithPagination(filters, pagination, onCompletedCallback)),
        handleContactDelete_d: (contactId: string, onCompletedCallback: (response: any) => void) => dispatch(handleDeleteContact(contactId, onCompletedCallback)),
        clearContactsState_d: () => dispatch(clearContactsState())
    }
}

const mapStateToProps = (state: any) => ({
    contactsState: state.contacts,
    loading: state.progressSpinner.loading,
    pagination: state.contacts.pagination,
})

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);