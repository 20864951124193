import React from "react";
import { apiPaths } from "../../api/ApiPaths";
import { Button, FormControlLabel, Switch } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF = apiPaths.account.termsandconditions;

export default function TermsOfUseModal({
    isDialogOpened,
    handleCloseDialog,
    onSubmit,
}) {
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const [numPages, setNumPages] = React.useState(null);
    const [bottom, setBottom] = React.useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setBottom(false);
        handleCloseDialog(false);
    };

    const submit = () => {
        onSubmit();
        handleCloseDialog(false);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    //document.addEventListener("contextmenu", (event) => {
    //    event.preventDefault();
    //});

    const handleScroll = (e) => {
        const calc = Math.floor(e.target.scrollHeight - e.target.scrollTop)
        const topVariable = calc + 10;
        const lowerVariable = calc - 10;

        if (e.target.clientHeight <= topVariable && e.target.clientHeight >= lowerVariable) {
            setBottom(true);
        } else {
            setBottom(false);
        }
    };

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean; }; }) => {
        if (e.target.checked) {
            setPrivacyPolicyAccepted(true);
        } else {
            setPrivacyPolicyAccepted(false);
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                disableScrollLock
            >
                <DialogTitle id="max-width-dialog-title">Terms of Use</DialogTitle>
                <DialogContent onScroll={handleScroll}>
                    <div>
                        <center>
                            <Document
                                file={PDF}
                                onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        scale={1.0}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ))}
                            </Document>
                        </center>
                    </div>
                </DialogContent>

                <div className="divider mb-2" />
                <center>
                    <FormControlLabel control={<Switch onChange={onChangeCheckbox} id="accepted" className="mt-3 m-2 p-2 switch-medium toggle-switch-success" />} labelPlacement="start" label="I Accept" id="accepted" />
                </center>
                <center>
                    <Button
                        size="large"
                        onClick={submit}
                        disabled={bottom === false || privacyPolicyAccepted === false}
                        variant="contained"
                        className="btn-primary mb-3 p-3 text-white m-1 text-capitalize"
                        style={{ width: "200px" }}>
                        <span className="btn-wrapper--label">Accept</span>
                    </Button>
                </center>
            </Dialog>
        </React.Fragment>
    );
}
