import { UserProfiles } from "./UserProfiles";

export class User {
    jwtToken: string | null;
    email: string | null;
    userId: string | null;
    roles: string[];
    profiles: UserProfiles[] | null;
    currentProfileId: string;

    constructor(userId: string | null, email: string | null, jwtToken: string | null, roles: string[] = [], profiles: UserProfiles[] | null, currentProfileId: string) {
        this.userId = userId;
        this.email = email;
        this.jwtToken = jwtToken;
        this.roles = roles;
        this.profiles = profiles;
        this.currentProfileId = currentProfileId;
    }
}

export enum IdentityRoles {
    AdminPortalSuperUser = "AdminPortalSuperUser",
    ClientPortalUser = "ClientPortalUser",
    ExternalIntegrationUser = "ExternalIntegrationUser",
    AdminPortalUser = "AdminPortalUser",
    AdminPortalBiddingUser = "AdminPortalBiddingUser",
}

export enum IdentityRoleDescriptions {
    AdminPortalSuperUser = "A privileged user with the ability to create, manage, and assign roles to other users within the platform. This role has full administrative rights, including overseeing user permissions and access levels.",
    ClientPortalUser = "",
    ExternalIntegrationUser = "",
    AdminPortalUser = "A standard user with access to the platform's core features, excluding the ability to create or manage other users. This role is designed for individuals who need to utilize the system without administrative responsibilities.",
    AdminPortalBiddingUser = "A specialized user with permissions to place bids on auctions through the admin portal. This role is focused solely on bidding activities and does not include management or administrative capabilities.",
}