import { apiPaths } from '../api/ApiPaths';
import requestClient from '../api/RequestClient';

//Action
//const SETTINGS_DATA_FETCH_SUCCESS = "SETTINGS_DATA_FETCH_SUCCESS";
const CONFIG_VERSION_FETCH_SUCCESS = "CONFIG_VERSION_FETCH_SUCCESS";
const CONFIGS_FETCH_SUCCESS = "CONFIGS_FETCH_SUCCESS";

//const ERROR = "ERROR";
const CONFIG_ERROR = "CONFIG_ERROR";
const SERVER_DATETIME_DIFF_FETCH_SUCCESS = "SERVER_DATETIME_DIFF_FETCH_SUCCESS";
const SERVER_DATETIME_DIFF_ERROR = "SERVER_DATETIME_DIFF_ERROR";

const initialState = {
    error: false,
    errorText: null,
    success: false,
    settings: undefined,
    //settingsRetrieved: false,
    configVersion: undefined,
    configVersionRetrieved: false,
    //configs: undefined,
    configsRetrieved: false,
    serverDateTimeDifference: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        //case SETTINGS_DATA_FETCH_SUCCESS: return { ...state, settings: action.payload, settingsRetrieved: true }
        //case SETTINGS_ERROR: return { ...state, error: true, errorText: action.payload, settingsRetrieved: false }
        case CONFIG_VERSION_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, configVersion: action.payload, configVersionRetrieved: true }
        case CONFIGS_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, configs: action.payload, settings: action.payload, configsRetrieved: true }
        case CONFIG_ERROR: return { ...state, error: true, errorText: action.payload, configVersionRetrieved: false, configsRetrieved: false }
        case SERVER_DATETIME_DIFF_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, serverDateTimeDifference: action.payload }
        case SERVER_DATETIME_DIFF_ERROR: return { ...state, error: true, errorText: action.payload, serverDateTimeDifference: undefined }
        default: return state
    }
}

//Action creator
//export const settingsFetchSuccess = (settingsData: any) => {
//    return { type: SETTINGS_DATA_FETCH_SUCCESS, payload: settingsData }
//}

//export const settingsFetchError = (errorText: string) => {
//    return { type: SETTINGS_ERROR, payload: errorText }

//}

export const configVersionFetchSuccess = (configVersion: any) => {
    return { type: CONFIG_VERSION_FETCH_SUCCESS, payload: configVersion }
}

export const configVersionFetchError = (errorText: string) => {
    return { type: CONFIG_ERROR, payload: errorText }

}

export const configsFetchSuccess = (configs: any) => {
    return { type: CONFIGS_FETCH_SUCCESS, payload: configs }
}

export const configsFetchError = (errorText: string) => {
    return { type: CONFIG_ERROR, payload: errorText }
}

export const serverDateTimeDifferenceFetchSuccess = (payload: any) => {
    return { type: SERVER_DATETIME_DIFF_FETCH_SUCCESS, payload: payload }
}

export const serverDateTimeDifferenceFetchError = (errorText: string) => {
    return { type: SERVER_DATETIME_DIFF_ERROR, payload: errorText }
}

//Thunks
//export const handleSettingsFetch = () => {
//    return function (dispatch: any) {
//        return requestClient.get(apiPaths.vendor.retrieveWhiteLabelling, null, null)
//        .then(function (res) {
//            if (res.success) {
//                dispatch(settingsFetchSuccess(JSON.parse(res.response)))
//            } else {
//                dispatch(settingsFetchError(res.error))
//            }
//        })
//    }
//}

export const handleConfigVersionFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveConfigVersion, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(configVersionFetchSuccess(res.response))
                } else {
                    dispatch(configVersionFetchError(res.error))
                }
            })
    }
}

export const handleConfigsFetch = (version: any) => {

    let requestConfigs = {
        Version: version
    }

    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveConfigByVersion, null, requestConfigs)
            .then(function (res) {
                if (res.success) {
                    let configs = res.response;
                    dispatch(
                        configsFetchSuccess(
                            configs
                        )
                    )
                } else {
                    dispatch(configsFetchError(res.error))
                }
            })
    }
}