export enum ProcessingStatus {
    New = 0,
    Failed = 1,
    Proccessed = 2,
    ProccessedAsTest = 3,
    VendorNotFoundForUser = 4,
    ProcessingDisabledForVendor = 5,
    IntegrationConfigNotFound = 6,
    ImageUploadFailed = 7
}

export enum ActionStatus {
    New = 0,
    AwaitingEntity = 1,
    ManualInterventionRequired = 2,
    Resolved = 3,
    ResolvedByOverrideRequest = 4,
    ResolvedAsTest = 5
}

export enum ActionPriority {
    Default = 0,
    Low = 1,
    Medium = 2,
    High = 3,
}