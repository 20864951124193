import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import { FaSave, FaTimes } from "react-icons/fa";
import { Alert } from '@mui/lab';
import { useState } from "react";


interface InvalidateConfirmationDialogProps {
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    open: boolean;
    onOk: (data: any, payload: any) => void;
    onCancel: () => void;
    payload: any
}

export const InvalidateConfirmation: React.FC<InvalidateConfirmationDialogProps> = props => {
    const {
        title,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk,
        payload
    } = props

    const [reason, setReason] = useState<string>('');
    const [reasonError, setReasonError] = useState<string>('');

    const [notifyUser, setNotifyUser] = useState<boolean>(true);
    const [message, setMessage] = useState<string>('');
    const [messageError, setMessageError] = useState<string>('');


    const validate = () => {
        let valid = true;
        if (reason === '') {
            setReasonError('Please add a reason for invalidating the document');
            valid = false;
        }
        else setReasonError('');

        if (notifyUser && message === '') {
            setMessageError('Please enter a message for the user');
            valid = false;
        }
        else setMessageError('');

        return valid;
    }

    const onSubmit = () => {
        if (!validate()) return;

        onOk({requestNewDocument: notifyUser, reason, message}, payload);
    }

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>Please provide a reason for invalidating the document</DialogContentText>
                <TextField value={reason}
                    margin="normal"
                    autoFocus
                    onChange={e => setReason(e.target.value)}
                    name="reason"
                    fullWidth
                    required
                    onKeyPress={onKeyPress}
                    multiline
                    minRows="4"
                    placeholder='Enter reason for invalidating the document'
                />
                {reasonError && (<Alert severity='error'>{reasonError}</Alert>)}
                {/*<div style={{ paddingTop: 10 }}>*/}
                {/*    <FormControlLabel label='Request New Document' labelPlacement="end" control={<Switch checked={notifyUser} onChange={(e, checked) => setNotifyUser(checked)} className="m-2 p-2 switch-medium toggle-switch-success" />} />*/}
                {/*</div>*/}
                {notifyUser && <div style={{paddingTop: 10}}>
                    <DialogContentText>Please provide a message for the user</DialogContentText>
                    <TextField value={message}
                        margin="normal"
                        onChange={e => setMessage(e.target.value)}
                        name="message"
                        fullWidth
                        required
                        onKeyPress={onKeyPress}
                        multiline
                        minRows="4"
                        placeholder='Enter a message for the user'
                    />
                    {messageError && (<Alert severity='error'>{messageError}</Alert>)}
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">{cancelButtonText}</span>
                </Button>

                <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white mr-1 text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                    <span className="btn-wrapper--label">{okButtonText}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InvalidateConfirmation