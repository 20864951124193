import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { TextField, Button } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

const coloredMarker = (color: string, maps: any) => {
  return {
    path: "M15 13 15 8 16 8 12 5 8 8 9 8 9 13 11 13 11 11 13 11 13 13Zm-3-10.984Q14.906 2.016 16.945 4.055T18.984 9Q18.984 10.453 18.257 12.328T16.499 15.844 14.46 18.914 12.749 21.187L11.999 21.984Q11.718 21.656 11.249 21.117T9.561 18.961 7.428 15.82 5.764 12.375 5 9Q5 6.094 7.039 4.055T11.984 2.016ZM11.25 10 11.25 9 10.25 9 10.25 10Zm1.5-1L13.75 9 13.75 10 12.75 10Z",
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new maps.Point(14, 20),
  };
};

interface SearchBoxProps {
  maps: any;
  placeholder: string;
  onPlacesChanged: (place: any) => void;
  onLocationChanged?: (location: any) => void;
  onUseSecondary: (value: boolean) => void;
  locationState: any;
  searchStringDefault?: string;
  style?: any;
}

const SearchBox: FC<SearchBoxProps> = (props) => {
  const {
    maps,
    onPlacesChanged,
    placeholder,
    onUseSecondary,
    locationState,
    searchStringDefault,
    onLocationChanged,
    style,
  } = props;

  const input = useRef<any>(null);
  const autoComplete = useRef<any>(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) onPlacesChanged(autoComplete.current.getPlace());
  }, [onPlacesChanged, autoComplete]);

  useEffect(() => {
    if (input.current.value !== locationState?.primaryLocation?.searchString)
      input.current.value =
        locationState?.primaryLocation?.searchString ??
        searchStringDefault ??
        "";
  }, [searchStringDefault, locationState?.primaryLocation]);

  useEffect(() => {
    if (!autoComplete.current && maps) {
      autoComplete.current = new maps.places.Autocomplete(input.current);
      //autoComplete.current.setComponentRestrictions({ country: ["ZA"] }); //Limited to South Africa
      autoComplete.current.addListener("place_changed", handleOnPlacesChanged);
    }

    return () => {
      if (maps) {
        autoComplete.current = null;
        maps.event.clearInstanceListeners(autoComplete);
      }
    };
  }, [maps, handleOnPlacesChanged]);

  const clear = () => {
    if (locationState.secondaryLocation)
      autoComplete.current.set("place", "clearSecondary");
    else {
      autoComplete.current.set("place", "clear");
      if (onLocationChanged)
        onLocationChanged({
          searchString: "",
          latitude: "",
          longitude: "",
          address: {
            addressLine1: "",
            addressLine2: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            searchString: "",
          },
        });
    }
  };

  return (
    <div style={style}>
      <div
        style={{
          borderRadius: 4,
          backgroundColor: "white",
          width: "calc(100% - 70px)",
          marginTop: 10,
          border: "none",
        }}
      >
              <TextField
                  margin="normal"
          fullWidth
          inputRef={input}
          placeholder={placeholder ?? "Enter a location"}
          autoFocus={true}
          style={{
            margin: 0,
            boxShadow: "0 0.25rem 0.55rem rgb(0 0 0 / 35%)",
            borderRadius: 4,
          }}
        />
      </div>
      {(searchStringDefault ||
        locationState?.primaryLocation ||
        locationState?.secondaryLocation) && (
        <Button
          style={{ padding: 10, marginRight: 10 }}
          variant="contained"
          className="btn-danger mt-1 text-white text-capitalize"
          type="submit"
          onClick={clear}
        >
          {locationState.secondaryLocation
            ? "Clear Secondary"
            : "Clear Location"}
        </Button>
      )}
      {locationState?.secondaryLocation && (
        <Button
          style={{ padding: 10, background: "#2b2b2b" }}
          variant="contained"
          className="btn-secondary mt-1 text-white text-capitalize blue"
          type="submit"
          onClick={() => onUseSecondary(!locationState?.useSecondary)}
        >
          Use {locationState?.useSecondary ? "Primary" : "Secondary"} Location
          Coordinates
        </Button>
      )}
    </div>
  );
};

interface GoogleMapContainerProps {
  onLocationChanged?: (location: any) => void;
  placeHolder?: string;

  canEdit?: boolean;
  center?: {
    lat: number;
    lng: number;
  };
  overrideDefaultCenter?: {
    lat?: number;
    lng?: number;
    zoom?: number;
  };
  searchString?: string;
  apiKey: string;
  style?: CSSProperties;
}

const GoogleMapContainer: FC<GoogleMapContainerProps> = (props) => {
  const {
    onLocationChanged,
    canEdit = true,
    center,
    searchString,
    apiKey,
    overrideDefaultCenter,
    style,
  } = props;

  const [mapState, setMapState] = useState<any>();
  const [primaryLocation, setPrimaryLocation] = useState<any>(undefined);
  const [secondaryLocation, setSecondaryLocation] = useState<any>(undefined);
  const [useSecondary, setUseSecondary] = useState<boolean>(false);

  const primaryMarker = useRef<any>(null);
  const secondaryMarker = useRef<any>(null);

  const defaultProps = {
    center: {
      lat: overrideDefaultCenter?.lat ?? -28.7,
      lng: overrideDefaultCenter?.lng ?? 23.83,
    },
    zoom: overrideDefaultCenter?.zoom ?? 5,
  };

  const configureMapOnMount = useCallback(() => {
    if (
      mapState?.googlemaps &&
      center &&
      center.lat &&
      center.lng &&
      (!primaryLocation ||
        (!secondaryLocation &&
          primaryLocation.latitude !== center.lat &&
          primaryLocation.longitude !== center.lng))
    ) {
      setPrimaryLocation({
        ...(primaryLocation || {}),
        latitude: center?.lat,
        longitude: center?.lng,
      });
    }
  }, [center, mapState?.googlemaps]);

  const configurePrimaryLocationMarker = useCallback(
    (coordinates) => {
      primaryMarker.current?.setPosition(coordinates);
      primaryMarker.current?.setLabel({
        text: "Primary Location",
        className: "googleMarkerLabelPrimary",
      });
      primaryMarker.current?.setIcon(
        coloredMarker("red", mapState?.googlemaps)
      );
      primaryMarker.current?.setVisible(true);
    },
    [mapState?.googlemaps]
  );

  const configureSecondaryLocationMarker = useCallback(
    (coordinates) => {
      secondaryMarker.current.setPosition(coordinates);
      secondaryMarker.current.setLabel({
        text: "Secondary Location",
        className: "googleMarkerLabelSecondary",
      });
      secondaryMarker.current.setVisible(true);
      secondaryMarker.current.setIcon(
        coloredMarker("blue", mapState?.googlemaps)
      );
    },
    [mapState?.googlemaps]
  );

  const configureMapLayout = useCallback(
    (coordinates, offset) => {
      const newCenter =
        coordinates?.lat && coordinates?.lng
          ? coordinates
          : defaultProps.center;

      mapState?.map.setCenter(newCenter);
      mapState?.map.setZoom(
        coordinates?.lat && coordinates?.lng ? 15 : defaultProps.zoom
      );
      mapState?.map.panTo({
        lat: newCenter.lat,
        lng: newCenter.lng,
      });

      mapState?.map.panBy(0, canEdit ? offset : offset + 30);
    },
    [canEdit, defaultProps.center, defaultProps.zoom, mapState?.map]
  );

  const toggleBetweenLocations = useCallback(
    (useSecondaryLocation) => {
      const coordinates = useSecondaryLocation
        ? {
            lat: secondaryLocation?.latitude,
            lng: secondaryLocation?.longitude,
          }
        : {
            lat: primaryLocation?.latitude,
            lng: primaryLocation?.longitude,
          };

      configureMapLayout(coordinates, 180);

      if (!onLocationChanged || (!primaryLocation && !secondaryLocation))
        return;

      let location =
        useSecondary && secondaryLocation
          ? secondaryLocation
          : primaryLocation || {};

      onLocationChanged({
        searchString: isEmpty(location) ? "" : searchString,
        ...location,
      });
    },
    [
      configureMapLayout,
      onLocationChanged,
      primaryLocation,
      secondaryLocation,
      useSecondary,
      searchString,
    ]
  );

  useEffect(() => {
    configureMapOnMount();
  }, [center, configureMapOnMount]);

  useEffect(() => {
    toggleBetweenLocations(useSecondary);
  }, [useSecondary]);

  useEffect(() => {
    if (primaryLocation && !secondaryLocation) {
      const primaryLocationCoordinates = {
        lat: primaryLocation?.latitude,
        lng: primaryLocation?.longitude,
      };
      configurePrimaryLocationMarker(primaryLocationCoordinates);
      configureMapLayout(primaryLocationCoordinates, 180);

      let location = primaryLocation || {};

      if (!onLocationChanged || !primaryLocation) return;

      onLocationChanged({
        searchString: isEmpty(location) ? "" : searchString,
        ...location,
      });
    }

    if (secondaryLocation) {
      const secondaryLocationCoordinates = {
        lat: secondaryLocation?.latitude,
        lng: secondaryLocation?.longitude,
      };

      configureSecondaryLocationMarker(secondaryLocationCoordinates);
      configureMapLayout(secondaryLocationCoordinates, 180);
    }
  }, [primaryLocation, secondaryLocation]);

  const placeMarkersOnSelectedCoordinates = useCallback(
    (e) => {
      if (primaryLocation) {
        secondaryMarker.current.setVisible(false);

        configureSecondaryLocationMarker({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });

        setSecondaryLocation({
          ...(secondaryLocation || {}),
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        });
      } else {
        configurePrimaryLocationMarker({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });

        setPrimaryLocation({
          ...(primaryLocation || {}),
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        });
      }
    },
    [
      configurePrimaryLocationMarker,
      configureSecondaryLocationMarker,
      primaryLocation,
      secondaryLocation,
    ]
  );

  useEffect(() => {
    if (mapState?.googlemaps && canEdit) {
      mapState.googlemaps.event.clearListeners(mapState.map, "click");
      mapState.googlemaps.event.addListener(
        mapState.map,
        "click",
        function (e) {
          placeMarkersOnSelectedCoordinates(e);
        }
      );
    }
  }, [
    canEdit,
    mapState?.googlemaps,
    mapState?.map,
    placeMarkersOnSelectedCoordinates,
  ]);

  const createMapOptions = (maps: any) => {
    let options: any = {
      mapTypeId: "roadmap",
      keyboardShortcuts: false,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: 1,
      },
    };

    if (!canEdit) {
      options = {
        ...options,
        //panControl: false,
        //scrollwheel: false,
        navigationControl: false,
        //scaleControl: false,
        //draggable: false,
      };
    }

    return options;
  };

  const handleApiLoaded = (map, maps) => {
    primaryMarker.current = new maps.Marker({ map });
    secondaryMarker.current = new maps.Marker({ map });

    setMapState({
      apiReady: true,
      map: map,
      googlemaps: maps,
    });
  };

  const handleOnPlacesChanged = (place: any) => {
    if (place === "clearSecondary") {
      setUseSecondary(false);
      setSecondaryLocation(undefined);
      secondaryMarker.current?.setVisible(false);

      return;
    } else if (place === "clear") {
      primaryMarker.current?.setVisible(false);
      secondaryMarker.current?.setVisible(false);

      setPrimaryLocation(undefined);
      setSecondaryLocation(undefined);
      setUseSecondary(false);

      return;
    }

    if (!place?.geometry) return; //No place selected

    if (place.geometry.viewport) {
      mapState.map.fitBounds(place.geometry.viewport);

      configurePrimaryLocationMarker({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }

    let address: any = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };

    place.address_components.forEach((x) => {
      if (x.types.includes("street_number")) address.addressLine1 = x.long_name;
      if (x.types.includes("route")) {
        if (address.addressLine1) address.addressLine1 += " " + x.long_name;
        else address.addressLine1 = x.long_name;
      }
      if (x.types.includes("locality")) address.city = x.long_name;
      if (x.types.includes("administrative_area_level_1"))
        address.state = x.long_name;
      if (x.types.includes("country")) address.country = x.long_name;
      if (x.types.includes("postal_code")) address.zip = x.long_name;
    });

    setPrimaryLocation({
      searchString: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address,
    });
  };

  const handleUseSecondary = (value: boolean) => {
    setUseSecondary(value);
  };

  return (
    <div style={{ height: "100vh", width: "100%", position: "relative" }}>
      {mapState?.apiReady && canEdit && mapState?.map && (
        <SearchBox
          style={{
            position: "absolute",
            left: 60,
            zIndex: 100,
            width: "100%",
          }}
          maps={mapState?.googlemaps}
          onLocationChanged={onLocationChanged}
          onPlacesChanged={handleOnPlacesChanged}
          searchStringDefault={searchString}
          placeholder="Enter a location"
          onUseSecondary={handleUseSecondary}
          locationState={{ primaryLocation, secondaryLocation, useSecondary }}
        />
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: apiKey,
          libraries: ["places"],
        }}
        keyboardShortcuts={false}
        streetViewControl={false}
        fullscreenControl={false}
        mapTypeControl={false}
        zoomControl={true}
        options={createMapOptions}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        style={{ ...(style || {}) }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

const mapStateToProps = (state: any) => ({
  apiKey: state.settings.settings?.GoogleMapsApiKey,
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapContainer);
