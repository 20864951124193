import React,{memo,FC, useState, useEffect} from 'react';
import { isReserveMet } from 'prembid-shared-library-npm/helpers';
import { BidRow } from '../AuctionsOverviewGrid.types';
import CurrencyLabel from '@/components/CurrencyComponent/CurrencyLabel';

interface ReserveCellProps {
    bidRows:BidRow[];
    currentReserve: number;
    topBid: number;
}

const ReserveCell:FC<ReserveCellProps>=(props)=> {

    const {
        bidRows,
        currentReserve,
        topBid
    } = props;

    const [currentReserveMet, setCurrentReserveMet] = useState(() => isReserveMet({ reservePrice: currentReserve, topBid: topBid }));

    useEffect(() => {
        const { amount: highestBid = 0 } = bidRows?.[0] ?? {};

        if (Number((topBid ?? 0)) > Number((highestBid)))
            setCurrentReserveMet(isReserveMet({ reservePrice: currentReserve, topBid: topBid }));
        else {
            setCurrentReserveMet(isReserveMet({ reservePrice: currentReserve, topBid: highestBid }));
        }
    }, [topBid, bidRows, currentReserve]);

    return (
        <div style={{ marginTop: -18, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CurrencyLabel value={currentReserve} />
            {
                <div className={`badge ${currentReserveMet ? 'badge-success' : 'badge-danger'} badge-pill m-1 text-capitalize`}>{currentReserveMet ? "Yes" : "No"}</div>
            }
        </div>
    )
}

export default memo(ReserveCell);