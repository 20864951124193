import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const CLEAR_BULK_UPLOADS_STATE = 'CLEAR_BULK_UPLOADS_STATE';

export interface BulkUploadState {
    [key: string]: any
}

const initialState: BulkUploadState = {
    data: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case CLEAR_BULK_UPLOADS_STATE: return { ...initialState };
        default: return state;
    }
}

export const clearBulkUploadState = () => {
    return { type: CLEAR_BULK_UPLOADS_STATE }
}

export const handleGenerateBulkUploadTemplate = (onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.downloadFile(apiPaths.lot.generateBulkUploadTemplate, null, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                if (onCompletedCallback) onCompletedCallback(null);
            })
    }
}


export const handleFetchBulkUpload = (id: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.retrieveBulkUpload, { id }, null)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleFetchBulkUploadsWithPagination = (auctionId: string, pagination: any, onCompletedCallback: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.lot.retrieveAllBulkUploads, {
                pagination,
                auctionId: auctionId
            })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleFetchBulkUploadImagesWithPagination = (bulkUploadId: string, pagination: any, onCompletedCallback: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: -1 };

    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.lot.retrieveAllBulkUploadImages, {
                pagination,
                bulkUploadId: bulkUploadId
            })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleUpsertBulkUpload = (payload: any, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.lot.upsertBulkUpload, payload)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleProcessBulkUpload = (id: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.processBulkUpload, { id }, null)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleDeleteBulkUpload = (id: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.delete(apiPaths.lot.deleteBulkUpload, null, { id })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleAddBulkUploadImage = (payload: any, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.lot.addBulkUploadImage, payload)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleDeleteBulkUploadImage = (id: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.delete(apiPaths.lot.deleteBulkUploadImage, null, { id })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}