import React from 'react'
import useStyles from './ForgotPasswordConfirmationStyles';
import ForgotPasswordConfirmationCard from '@/components/ForgotPasswordConfirmationCard/ForgotPasswordConfirmationCard';

const ForgotPasswordConfirmation: React.FC = () => {

    const { classes, cx } = useStyles();

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);

    return (
        <ForgotPasswordConfirmationCard />
    )
}

export default ForgotPasswordConfirmation
