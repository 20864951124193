import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import { connect } from 'react-redux';
import ns from '@/helpers/NotificationService';
import { handleDetermineWinningAmount, handleMarkAsSold } from '../../pages/AuctionManagement/store/AuctionManagement';
import Button from '../Button';
import LoadingButton from '../LoadingButton';

interface MarkAsSoldProps {
    lot: any,
    settings: any,
    isInvoicingEnabled: boolean,
    handleMarkAsSold_d: (lotId: string, soldAmount: number, onCompletedCallback: (response: any) => void) => void,
    handleDetermineWinningAmount_d: (lotId: string, onCompletedCallback: (response: any) => void) => void,
    refetchLotsData: (options: any) => Promise<any>
}

class MarkAsSold extends React.PureComponent<MarkAsSoldProps, { updatedSoldAmount: number, isLoading: boolean }> {
    private lot;
    private settings;
    private isInvoicingEnabled;
    private handleMarkAsSold_d;
    private refetchLotsData;

    constructor(props) {
        super(props);

        this.lot = props.lot;
        this.settings = props.settings;
        this.isInvoicingEnabled = props.isInvoicingEnabled;
        this.handleMarkAsSold_d = props.handleMarkAsSold_d;
        this.refetchLotsData = props.refetchLotsData;

        this.state = {
            updatedSoldAmount: 0,
            isLoading: false
        };
    }

    componentWillUpdate(nextProps) {
        this.lot = nextProps.lot;
        this.settings = nextProps.settings;
        this.isInvoicingEnabled = nextProps.isInvoicingEnabled;
        this.handleMarkAsSold_d = nextProps.handleMarkAsSold_d;
        this.refetchLotsData = nextProps.refetchLotsData;
    }

    private onSoldAmountChange = (e) => {
        this.setState({ updatedSoldAmount: e.target.value })
        this.onClick(this.onSoldAmountChange, e.target.value)
    };

    private onClick = (onSoldAmountChange, amount) => {
        ns.showDialog({
            title: 'Confirm Mark As Sold',
            content: <div>
                {
                    this.isInvoicingEnabled ? (
                        <p className="mt-3">An invoice will be generated once this {this?.settings?.LotName} has been marked as sold. Are you sure you want to continue?</p>
                    ) : (
                        <p className="mt-3">Are you sure you want to mark this {this?.settings.LotName} as sold?</p>
                    )
                }
                <p>
                    {this?.settings.LotName} Number: {this?.lot.number}<br />
                    {this?.settings.LotName} SKU Number: {this?.lot.skuNumber}<br />
                    {this?.settings.LotName} Unique Code: {this?.lot.uniqueCode}
                </p>

                <TextField
                    margin="normal"
                    onFocus={(e) =>
                        e.target.setSelectionRange(0, e.target.value.length)
                    }
                    id="soldAmount"
                    defaultValue={amount}
                    onChange={onSoldAmountChange}
                    fullWidth
                    className="mt-2"
                    label="Amount"
                    InputProps={{
                        inputComponent: CurrencyInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Currency priceWithSymbol={""} />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>,
            onSubmit: () => {
                if (amount > 0) {
                    this.setState({ isLoading: true })

                    this?.handleMarkAsSold_d(this.lot?.id, amount, async (res) => {
                        if (res.success) {
                            await this.refetchLotsData();
                            ns.success(`Successfully marked the ${this?.settings?.LotName} as sold`);
                        }
                        else {
                            if (res.error) ns.error(res.error)
                            else ns.error(`Failed to mark the ${this?.settings?.LotName} as sold`);
                            this.setState({ isLoading: false })
                        }
                    })
                } else {
                    ns.error(`The amount must be greater than 0`);
                }
            },
            onCancel: () => { },
            locales: {
                submitText: 'Mark As Sold'
            }
        });
    };

    determineWinningAmount = () => {
        this.setState({ isLoading: true })
        setTimeout(() => {
            this.props.handleDetermineWinningAmount_d(this.lot?.id, (res) => {
                if (res.success) {
                    var amount = res.response;
                    this.setState({ updatedSoldAmount: amount })
                    this.onClick(this.onSoldAmountChange, amount)
                }
                else {
                    if (res.error) ns.error(res.error)
                    else ns.error(`Failed to determine the winning amount for the ${this?.settings?.LotName}`);
                }
                this.setState({ isLoading: false })
            })
        }, 50)
    }

    render() {
        return (
            <React.Fragment>
                <LoadingButton
                    loading={this?.state?.isLoading}
                    disabled={false}
                    type="danger"
                    onClick={this.determineWinningAmount}
                    label={`Mark as Sold`}
                    fullWidth={true}
                    width={200}
                />
            </React.Fragment>)
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleMarkAsSold_d: (lotId: string, soldAmount: number, onCompletedCallback: (response: any) => void) => dispatch(handleMarkAsSold(lotId, soldAmount, onCompletedCallback)),
        handleDetermineWinningAmount_d: (lotId: string, onCompletedCallback: (response: any) => void) => dispatch(handleDetermineWinningAmount(lotId, onCompletedCallback)),
    }
}

export default connect(null, mapDispatchToProps)(MarkAsSold);