import React, { useEffect } from "react";
import LoginForm from '@/components/LoginForm/LoginForm';
import { hideProgressSpinner } from "../../helpers/ProgressSpinnerService";
import { PrembidLocalStorage } from "../../helpers/PrembidLocalStorage";

const Login: React.FC = () => {
    useEffect(() => {
        !PrembidLocalStorage.currentUser?.jwtToken && hideProgressSpinner(undefined, true);
    }, []);

    return <LoginForm />
};


export default Login
