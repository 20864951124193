import React, {FC, ReactNode, memo } from "react";
import {
	Paper,
	TableRow,
	TableCell,
	TableContainer,
	TableBody,
	Table,
	CircularProgress,
} from "@mui/material";

interface MainTableProps {
	loading: boolean;
	paginationComponent: ReactNode;
	headerComponent:ReactNode;
	rowComponents:ReactNode;
}

const MainTable:FC<MainTableProps> = (props) => {
	const {
		loading,
		paginationComponent,
		headerComponent,
		rowComponents,
	} = props;

	return (
		<TableContainer component={Paper}>
			<Table
				aria-label="collapsible table"
				style={{
					minHeight: loading ? 300 : undefined,
					pointerEvents: loading ? "none" : "all",
				}}>
				{headerComponent}
				{loading && (
					<TableBody
						style={{
							position: "absolute",
							top: "100px",
							left: "calc(50% - 50px)",
						}}>
						<TableRow>
							<TableCell style={{ border: "none" }}>
								<div className="primaryColor">
									<CircularProgress color="inherit" size={100} />
								</div>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
				<TableBody style={{ opacity: loading ? 0.5 : 1 }}>
					{rowComponents}
				</TableBody>
			</Table>
			{paginationComponent}
		</TableContainer>
	);
};

export default memo(MainTable);
