import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const RETRIEVED_INCREMENT_TABLES_SUCCESS = 'RETRIEVED_INCREMENT_TABLES_SUCCESS';
const RETRIEVED_INCREMENT_TABLE_SUCCESS = 'RETRIEVED_INCREMENT_TABLE_SUCCESS';
const RETRIEVED_INCREMENT_TABLES_LOOKUP_SUCCESS = 'RETRIEVED_INCREMENT_TABLES_LOOKUP_SUCCESS';
const DELETE_INCREMENT_TABLE = 'DELETE_INCREMENT_TABLE';
const CLEAR_STATE = 'CLEAR_STATE';


export interface IncrementTableState {
    list: any[],
    pagination: any,
    incrementTable: any,
    lookups: any[],
    lookupsPagination: any
}

const initialState: IncrementTableState = {
    list: [],
    pagination: undefined,
    incrementTable: undefined,
    lookups: [],
    lookupsPagination: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case RETRIEVED_INCREMENT_TABLE_SUCCESS: return { ...state, incrementTable: action.incrementTable };
        case RETRIEVED_INCREMENT_TABLES_SUCCESS: return { ...state, list: action.payload.list, pagination: action.payload.pagination };
        case RETRIEVED_INCREMENT_TABLES_LOOKUP_SUCCESS: return { ...state, lookups: action.payload };
        case DELETE_INCREMENT_TABLE:
            {
                let tempList = [...state.list];
                let index = tempList.findIndex(x => x.id === action.incrementTableId);
                let tempPagination = { ...state.pagination };
                if (index > -1) {
                    tempList.splice(index, 1);
                    tempPagination.total = tempPagination.total - 1;
                }
                return { ...state, list: tempList, pagination: tempPagination }
            }
        case CLEAR_STATE: return { ...initialState };
        default: return state;
    }
}

export const deleteIncrementTable = (incrementTableId: string) => {
    return { type: DELETE_INCREMENT_TABLE, incrementTableId }
}

export const retrieveIncrementTables = (incrementTableResponse: any) => {
    return { type: RETRIEVED_INCREMENT_TABLES_SUCCESS, payload: incrementTableResponse }
}

export const retrieveIncrementTable = (incrementTable: any) => {
    return { type: RETRIEVED_INCREMENT_TABLE_SUCCESS, incrementTable }
}

export const clearIncrementTableState = () => {
    return { type: CLEAR_STATE }
}

export const retriveIncrementTablesLookup = (payload: any) => {
    return { type: RETRIEVED_INCREMENT_TABLES_LOOKUP_SUCCESS, payload }
}


export const handleIncrementTableUpsert = (incrementTableData: any, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.vendorIncrementTable.upsertVendorIncrementTable, incrementTableData)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleIncrementTablesLookupFetch = (onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendorIncrementTable.retrieveVendorIncrementTableLookup, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(retriveIncrementTablesLookup(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleIncrementTableFetchWithPagination = (filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.vendorIncrementTable.retrieveVendorIncrementTableListWithPagination, {
            pagination,
            ...(filters || {})
        })
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveIncrementTables(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleDeleteIncrementTable = (incrementTableId: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.delete(apiPaths.vendorIncrementTable.deleteVendorIncrementTableById, undefined, { incrementTableId })
            .then(function (res) {
                if (res.success) dispatch(deleteIncrementTable(incrementTableId))
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleRetrieveIncrementTable = (incrementTableId: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.vendorIncrementTable.retrieveVendorIncrementTable, { incrementTableId }, undefined)
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveIncrementTable(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}