import React from "react";

const FloatyButtonGroup: React.FC<any> = (props: any) => {

    if (props) {
        if (props.children) {
            const btns = (props.children) as HTMLElement[]
            return (
                <div className="promo-section-wrapper">
                    <div className="promo-section-buttons-wrapper">
                        <div className="promo-section-buttons">
                            {(Array.isArray(btns) ? btns : [btns]).map((btn, index) => (<>{btn}</>))}
                        </div>
                    </div>
                </div>
            );
        }
    }

    return null;
};

export default FloatyButtonGroup;
