import React, { FC, useReducer, useState, useEffect } from "react";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import {
    Button,
    Card,
    Grid,
    TextField,
    Tooltip
} from "@mui/material";
import { Alert } from '@mui/lab';
import {
    FaSave,
    FaBackspace,
    FaTimes
} from 'react-icons/fa';
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { connect } from "react-redux";
import {
    handleBankDetailsAdd,
    handleBankDetailsUpdate,
    handleBankDetailsFetch
} from "./store/BankDetails";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { containsSpecialChars } from '@/helpers/Utils'

interface Props {
    handleBankDetailsAdd_d: (data: any, onCompletedCallback: (res: any) => void) => void,
    handleBankDetailsUpdate_d: (id: string, data: any, onCompletedCallback: (res: any) => void) => void,
    handleRetrieveBankDetails_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    settings: any,
    dialog?: boolean,
    onClose?: (id?: string) => void
}

const initialState = {
    name: "",
    description: "",
    bankName: "",
    accountNumber: "",
    branchCode: "",
    branchName: "",
    swiftCode: ""
}

const formFieldsReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'update':
            return { ...state, ...action.data }
        case 'clear':
            return { ...initialState };
        default:
            throw new Error();
    }
}

const BankDetailsAddEdit: FC<Props> = (props) => {
    const history = useHistory();

    const { id }: any = useParams();

    const {
        handleBankDetailsAdd_d,
        handleBankDetailsUpdate_d,
        handleRetrieveBankDetails_d,
        settings,
        dialog = false,
        onClose
    } = props;

    const [formFields, setFormFields] = useReducer(formFieldsReducer, initialState);
    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] = useState<any>({
        name: false,
        bankName: false,
        accountNumber: false,
        branchCode: false,
        branchName: false,
        swiftCode: false
    });

    const [bankDetails, setBankDetails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!dialog && id) {
            setLoading(true);
            handleRetrieveBankDetails_d(id, (res: any) => {
                if (res.success) {
                    setBankDetails(res.response);
                }
                hideProgressSpinner();
                setLoading(false)
            })
        }
        else hideProgressSpinner();
    }, [id])

    useEffect(() => {
        if (bankDetails) {
            setFormFields({
                type: 'update',
                data: {
                    name: bankDetails.name || '',
                    description: bankDetails.description || '',
                    bankName: bankDetails.bankName || "",
                    accountNumber: bankDetails.accountNumber || "",
                    branchCode: bankDetails.branchCode || "",
                    branchName: bankDetails.branchName || "",
                    swiftCode: bankDetails.swiftCode || ""
                }
            });
        }
    }, [bankDetails]);

    const navEdit = (id: string | undefined = undefined) => {
        if (onClose) onClose(id);
        else {
            let path = `/BankDetailsEdit/` + id;
            history.replace(path);
        }
    };

    const onChange = (e: { target: { id: any; value: string } }) => {
        setFormFields({ type: 'update', data: { [e.target.id]: e.target.value } });
    };

    const onCancel = () => {
        if (onClose) onClose();
        else history.goBack();
    };

    const clearAllStates = () => {
        setFormFieldsValidationShowErrors({
            name: false,
            bankName: false,
            accountNumber: false,
            branchCode: false,
            branchName: false,
        });
        setFormFields({ type: 'clear' });
    }

    const onSubmit = () => {
        const isFormValid = validateAll();

        if (isFormValid) {
            showProgressSpinner({ description: "Saving Bank Details..." });

            if (bankDetails?.id) {
                handleBankDetailsUpdate_d(bankDetails.id, formFields, (res: any) => {
                    if (res.success) {
                        ns.success("Bank details successfully updated");
                    }
                    else {
                        ns.error("Failed to update the bank details");
                    }
                    hideProgressSpinner();
                });
            } else {
                handleBankDetailsAdd_d(formFields, (res: any) => {
                    if (res.success) {
                        ns.success("Bank details successfully added");
                        clearAllStates();
                        navEdit(res.response);
                    }
                    else {
                        ns.error("Failed to add the bank details");
                        hideProgressSpinner();
                    }
                });
            }
        } else {
            hideProgressSpinner();
            ns.error('Please fill in all required fields and ensure no special characters have been used');
        }
    };

    const validateField = (e) => {
        if (!loading) {
            let showError = false;
            if (e.target.value === "") showError = true;

            if (e.target.id !== undefined)
                setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError, });
            else
                setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError, });
        }
    };

    const validateSwiftCode = (e) => {
        if (!loading) {
            let showError = false;
            if (containsSpecialChars(e.target.value)) showError = true;

            if (e.target.id !== undefined)
                setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError, });
            else
                setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError, });
        }
    };

    const validateAll = () => {
        let isAllValid = true;
        let newState: any = {
            name: false,
            bankName: false,
            accountNumber: false,
            branchCode: false,
            branchName: false
        };

        const excludedFields = ['name', 'description'];

        for (const [key, value] of Object.entries(newState)) {
            const formFieldsvalue = formFields[key];

            if (newState[key] === false) {
                if (formFieldsvalue === "" || formFieldsvalue == "0.00" || formFieldsvalue === null || formFieldsvalue === 0 || formFieldsvalue === "0" || (!excludedFields.includes(key) && containsSpecialChars(formFieldsvalue))) {
                    isAllValid = false;
                    newState[key] = true;
                }
            }
        }

        if (containsSpecialChars(formFields.swiftCode)) {
            newState.swiftCode = true;
            isAllValid = false;
        }
        else {
            newState.swiftCode = false;
        }

        setFormFieldsValidationShowErrors(newState);
        return isAllValid;
    };

    return !loading ? <>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Card className="card-box mb-spacing-6-x2">
                    <div className="card-header dpy-3">
                        <div className="card-header--title font-size-lg">
                            Bank Details
                        </div>
                    </div>
                    <div className="p-3">
                        <TextField
                            margin="normal"
                            autoFocus
                            value={formFields.name}
                            required
                            error={formFieldsValidationShowErrors.name}
                            onChange={onChange}
                            id="name"
                            fullWidth
                            className="m-2"
                            label="Name"
                            onBlur={validateField}
                        />
                        <TextField
                            margin="normal"
                            value={formFields.description}
                            onChange={onChange}
                            id="description"
                            fullWidth
                            className="m-2"
                            label="Description"
                        />
                        <TextField
                            margin="normal"
                            id="bankName"
                            fullWidth
                            className="m-2"
                            label="Bank Name"
                            value={formFields.bankName}
                            onChange={onChange}
                            required
                            onBlur={validateField}
                            error={formFieldsValidationShowErrors.bankName}
                        />
                        <TextField
                            margin="normal"
                            id="accountNumber"
                            fullWidth
                            className="m-2"
                            label="Account Number"
                            value={formFields.accountNumber}
                            onChange={onChange}
                            required
                            onBlur={validateField}
                            error={formFieldsValidationShowErrors.accountNumber}
                        />
                        <TextField
                            margin="normal"
                            id="branchCode"
                            fullWidth
                            className="m-2"
                            label="Branch Code"
                            value={formFields.branchCode}
                            onChange={onChange}
                            required
                            onBlur={validateField}
                            error={formFieldsValidationShowErrors.branchCode}
                        />
                        <TextField
                            margin="normal"
                            id="branchName"
                            fullWidth
                            className="m-2"
                            label="Branch Name"
                            value={formFields.branchName}
                            onChange={onChange}
                            required
                            onBlur={validateField}
                            error={formFieldsValidationShowErrors.branchName}
                        />
                        <TextField
                            margin="normal"
                            id="swiftCode"
                            fullWidth
                            className="m-2"
                            label="SWIFT Code"
                            value={formFields.swiftCode}
                            onChange={onChange}
                            onBlur={validateSwiftCode}
                            error={formFieldsValidationShowErrors.swiftCode}
                        />
                    </div>
                </Card>
            </Grid>
        </Grid>
        {dialog === false ? <FloatyButtonGroup>
            <Tooltip arrow title="Save" placement="left">
                <Button size="large" variant="contained" onClick={onSubmit}
                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                </Button>
            </Tooltip>
            <Tooltip arrow title="Back" placement="left">
                <Button size="large" variant="contained" onClick={onCancel}
                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaBackspace />
                    </span>
                </Button>
            </Tooltip>
        </FloatyButtonGroup> : <div className="text-right">
            <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                <span className="btn-wrapper--icon">
                    <FaTimes />
                </span>
                <span className="btn-wrapper--label">Cancel</span>
            </Button>

            <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                <span className="btn-wrapper--icon">
                    <FaSave />
                </span>
                <span className="btn-wrapper--label">Save</span>
            </Button>
        </div>
        }
    </> : <></>
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleBankDetailsAdd_d: (data: any, onCompletedCallback: (any) => void) => dispatch(handleBankDetailsAdd(data, onCompletedCallback)),
        handleBankDetailsUpdate_d: (id: string, data: any, onCompletedCallback: (any) => void) => dispatch(handleBankDetailsUpdate(id, data, onCompletedCallback)),
        handleRetrieveBankDetails_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleBankDetailsFetch(id, onCompletedCallback))
    };
};

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsAddEdit);