import React from "react";

const Dashboard: React.FC = () => {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
   )
}

export default Dashboard;