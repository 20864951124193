import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { FileExportType } from '@/helpers/FileExportTypeEnum';
import { Pagination as PaginationPayload } from 'prembid-shared-library-npm/types'
import { RegisteredProfileStatusEnum } from '../../../helpers/RegisteredProfileStatusEnum';
import { FinanceDocumentType } from '../../../dto/FinanceDocument';

const AUCTION_MANAGEMENT_ERROR = 'AUCTION_MANAGEMENT_ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
const LOT_REALTIME_UPDATE_SUCCESS = 'LOT_REALTIME_UPDATE_SUCCESS';
const LOT_OVERVIEW_GET_SUCCESS = 'LOT_OVERVIEW_GET_SUCCESS';
const LOT_OVERVIEW_GET_WITH_PAGINATION_SUCCESS = 'LOT_OVERVIEW_GET_WITH_PAGINATION_SUCCESS';
const AUCTION_OVERVIEW_PAGINATION = 'AUCTION_OVERVIEW_PAGINATION';
const GET_BID_HISTORY_SUCCESS = "GET_BID_HISTORY_SUCCESS";
const CLEAR_LOTS = "CLEAR_LOTS";
const RELOAD_LOTS = "RELOAD_LOTS";
const CLEAR_USERS = "CLEAR_USERS";
const LOTS_FETCH_CATALOGUE_SUCCESS = "LOTS_FETCH_CATALOGUE_SUCCESS";
const CLEAR_WISHLIST_PROFILES = "CLEAR_WISHLIST_PROFILES";
const FETCH_USERS_WITH_PAGINATION_SUCCESS = "FETCH_USERS_WITH_PAGINATION_SUCCESS";
const FETCH_USERS_WITH_PAGINATION_SUCCESS_WITH_UPDATE = "FETCH_USERS_WITH_PAGINATION_SUCCESS_WITH_UPDATE";
const RESET = "RESET";


const initialState = {
    error: false,
    errorText: '',
    reload: false,
    auctions: undefined,
    users: undefined,
    success: false,
    lots: undefined,
    lotsCatalogue: undefined,
    auction: undefined,
    settings: undefined,
    bidHistory: undefined,
    wishlistProfileData: undefined,
    wishListPagination: undefined,
    bidHistoryPagination: undefined,
    auctionOverviewPagination: undefined,
    usersPagination: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case AUCTION_MANAGEMENT_ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false }
        case FETCH_USERS_SUCCESS: return { ...state, error: false, errorText: '', users: action.payload }
        case FETCH_USERS_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: '', users: action.payload.list, usersPagination: action.payload.pagination }
        case FETCH_USERS_WITH_PAGINATION_SUCCESS_WITH_UPDATE:
            let tempProfiles: any[] = [...(state.users ?? [])];
            action.payload.forEach((x: any) => {
                const index = tempProfiles.findIndex((y: any) => y.id === x.id);
                if (index > -1)
                    tempProfiles.splice(index, 1, x);
            })
            return { ...state, users: tempProfiles }
        case UPDATE_USERS_SUCCESS: return { ...state, success: true, reload: !state.reload }
        case LOT_REALTIME_UPDATE_SUCCESS: return { ...state, success: true, reload: !state.reload }
        case LOT_OVERVIEW_GET_SUCCESS: return { ...state, lots: action.payload, reload: false }
        case LOT_OVERVIEW_GET_WITH_PAGINATION_SUCCESS:
            if (action.source === 'bidHistory') return { ...state, lots: action.payload.list, reload: false, bidHistoryPagination: action.payload.pagination }
            else return { ...state, lots: action.payload.list, reload: false, auctionOverviewPagination: action.payload.pagination }
        case AUCTION_OVERVIEW_PAGINATION: return { ...state, auctionOverviewPagination: action.payload }
        case GET_BID_HISTORY_SUCCESS: return { ...state, bidHistory: action.payload }
        case CLEAR_LOTS: return { ...state, lots: [] }
        case RELOAD_LOTS: return { ...state, lots: [], reload: true }
        case CLEAR_USERS: return { ...state, users: [] }
        case CLEAR_WISHLIST_PROFILES: return { ...state, wishlistProfileData: [] }
        case LOTS_FETCH_CATALOGUE_SUCCESS: return { ...state, lotsCatalogue: action.payload.lots, auction: action.payload.auction, settings: action.payload.settings }
        case RESET: return initialState;
        default: return state
    }
}

// Action creators
const error = (errorText: string) => {
    return { type: AUCTION_MANAGEMENT_ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const regUsersFetchSuccess = (users: any) => {
    return { type: FETCH_USERS_SUCCESS, payload: users }
}

const regUsersFetchWithPaginationSuccess = (payload: any, updateOnly: boolean = false) => {
    if (updateOnly) return { type: FETCH_USERS_WITH_PAGINATION_SUCCESS_WITH_UPDATE, payload }
    else return { type: FETCH_USERS_WITH_PAGINATION_SUCCESS, payload }
}


const regUsersUpdateSuccess = () => {
    return { type: UPDATE_USERS_SUCCESS, payload: UPDATE_USERS_SUCCESS }
}

const lotRealtimeUpdateSuccess = () => {
    return { type: LOT_REALTIME_UPDATE_SUCCESS, payload: LOT_REALTIME_UPDATE_SUCCESS }
}

const retrieveLotsOverviewSuccess = (lotOverviewData: string) => {
    return { type: LOT_OVERVIEW_GET_SUCCESS, payload: lotOverviewData }
}

export const retrieveLotsOverviewDataWithPaginationSuccess = (payload: string, source: string) => {
    return { type: LOT_OVERVIEW_GET_WITH_PAGINATION_SUCCESS, payload, source }
}

export const setAuctionOverviewPagination = (payload: PaginationPayload) => {
    return { type: AUCTION_OVERVIEW_PAGINATION, payload }
}

const retrieveLotBiddingHistorySuccess = (lotBids: any) => {
    return { type: GET_BID_HISTORY_SUCCESS, payload: lotBids }
}

export const clearLots = () => {
    return { type: CLEAR_LOTS, payload: CLEAR_LOTS }
}

export const reloadLots = () => {
    return { type: RELOAD_LOTS, payload: RELOAD_LOTS }
}

export const clearUsers = () => {
    return { type: CLEAR_USERS, payload: CLEAR_USERS }
}

export const clearWishlistProfiles = () => {
    return { type: CLEAR_WISHLIST_PROFILES, payload: CLEAR_WISHLIST_PROFILES }
}

const lotsFetchCatalogueSuccess = (printData: any) => {
    return { type: LOTS_FETCH_CATALOGUE_SUCCESS, payload: printData }
}

export const resetAuctionManagementState = () => {
    return { type: RESET }
}

// Thunks
export const handleRegisteredProfileFetch = (auctionId: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.user.retrieveAllRegisterdUsersByAuctionId, null, { auctionId: auctionId })
            .then(function (res) {
                if (res.success) {
                    let profiles = res.response;

                    //profiles = profiles.map(x => {
                    //    if (x.uploadedDocuments)
                    //        x.uploadedDocuments = JSON.parse(x.uploadedDocuments);
                    //    if (x.requiredDocuments)
                    //        x.requiredDocuments = JSON.parse(x.requiredDocuments);
                    //    return x;
                    //});

                    dispatch(regUsersFetchSuccess(profiles))
                } else {
                    dispatch(error(res.error))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

/* export const handleRegisteredProfileFetchWithPagination = (auctionId: string,
    filters?: any,
    pagination?: any,
    onCompletedCallback?: (response: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.user.retrieveAllRegisterdUsersByAuctionIdWithPagination, {
            auctionId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(regUsersFetchWithPaginationSuccess(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
} */


export const handleRegisteredProfileFetchWithPagination = (auctionId: string,
    filters?: any,
    pagination?: any,
    excludeAdminUsers?: boolean, 
    onCompletedCallback?: (response: any) => void) => {

    return async function (dispatch: any) {
        try {
            const res = await requestClient.post(apiPaths.user.retrieveAllRegisterdUsersByAuctionIdWithPagination, {
                auctionId,
                pagination,
                excludeAdminUsers,
                ...(filters || {})
            });

            if (res.success) {
                // Sort the users by id
                const sortedUsers = res.response.list.sort((a, b) => a.id.localeCompare(b.id));
                const newResponse = {
                    ...res.response,
                    list: sortedUsers
                };
                dispatch(regUsersFetchWithPaginationSuccess(newResponse));
            }

            if (onCompletedCallback) onCompletedCallback(res);
        } catch (error) {
            console.error(error);
        }
    }
}
export const handleRefreshRegisteredProfile = (id: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.user.retrieveAllRegisterdUsersByAuctionIdWithPagination, { id }).then(function (res) {
            if (res.success) {
                dispatch(regUsersFetchWithPaginationSuccess(res.response, true))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleRegisteredProfileUpdate = (user: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.user.update, user)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleRetrieveLotsOverviewData = (auctionId: string) => {
    return (dispatch: any) => {

        return requestClient.get(apiPaths.lot.retrieveLotsOverviewData, null, { auctionId: auctionId })
            .then(function (res) {
                if (res.success) {
                    let lots = res.response;

                    //lots = lots.map(x => {
                    //    if (x.dataCaptured)
                    //        x.dataCaptured = JSON.parse(x.dataCaptured);
                    //    return x;
                    //});

                    dispatch(retrieveLotsOverviewSuccess(lots));
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}


export const handleRetrieveLotsOverviewDataWithPagination = (auctionId: string,
    filters?: any,
    pagination?: any,
    onCompletedCallback?: (response: any) => void,
    source: string = "auctionOverview") => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.retrieveLotsOverviewDataWithPagination, {
            auctionId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(retrieveLotsOverviewDataWithPaginationSuccess(res.response, source))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleRetrieveLotBiddingHistory = (lotId: string, auctionId: string, includeHistory: boolean = false, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {

        return requestClient.get(apiPaths.lot.retrieveLotBiddingHistory, null, { lotId: lotId, auctionId, includeHistory })
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveLotBiddingHistorySuccess(res.response))
                } else {
                    dispatch(error(res.error))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleRetrieveDeletedBidAuditHistory = (lotId: string, auctionId: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {

        return requestClient.get(apiPaths.bids.retrieveDeletedBidAuditHistory, { lotId: lotId }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleRetrievePostAuctionLot = (auctionId: string) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.lot.retrieveAuctionLotsForPostAuction, null, { auctionId: auctionId }, { 'Content-Type': 'text/csv' }, 'arrayBuffer')
            .then(function (res) {
                const url = window.URL.createObjectURL(
                    new Blob([res]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'History.csv',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveAuctionCatalogReport = (auctionId: string, exportType: FileExportType, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveAuctionCatalogReport, { auctionId: auctionId, exportType }, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveClientUsersReport = (exportType: FileExportType, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveClientUsersReport, { exportType }, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveRegisteredUsersReport = (auctionId: string, exportType: FileExportType, profileType: string, registeredProfileStatus: string, onCompletedCallback: (response: any) => void) => {

    let paramObj = {
        auctionId: auctionId,
        exportType: exportType,
        profileType: profileType,
        registeredProfileStatus: registeredProfileStatus
    }

    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveRegisteredUsersReport, paramObj, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveAuctionHistoryReport = (auctionId: string, exportType: FileExportType, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveAuctionHistoryReport, { auctionId: auctionId, exportType }, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveLotBidHistoryReport = (auctionId: string, lotId: string, exportType: FileExportType, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveLotBidHistoryReport, { auctionId, lotId, exportType }, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}



export const handleLotsCatalogueFetch = (auctionId: any, lotTypeId?: any) => {

    const requestLotPageData = {
        auctionId: auctionId,
        lotTypeId: lotTypeId
    }

    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.retrieveLotPageData, null, requestLotPageData)
            .then(function (res) {
                if (res.success) {
                    let lots = res.response;

                    //lots = lots.map(x => {
                    //    if (x.dataCaptured)
                    //        x.dataCaptured = JSON.parse(x.dataCaptured);
                    //    return x;
                    //});

                    return lots;
                } else {
                    dispatch(error(res.error))
                }
            }).then(function (lotPageData) {
                requestClient.get(apiPaths.vendor.retrieveConfigVersion, null, null)
                    .then(function (res) {
                        if (res.success) {
                            let settings = res.response
                            let response = {
                                lots: lotPageData,
                                settings: settings
                            }
                            return response
                        } else {
                            dispatch(error(res.error))
                        }
                    }).then(function (lotsAndSettings) {
                        requestClient.get(apiPaths.auction.getAuctionById, null, { id: auctionId })
                            .then(function (res) {
                                if (res.success) {
                                    let lotsAuctionSettings = lotsAndSettings ? lotsAndSettings : {}
                                    lotsAuctionSettings['auction'] = res.response;
                                    dispatch(lotsFetchCatalogueSuccess(lotsAuctionSettings))
                                } else {
                                    dispatch(error(res.error))
                                }
                            })
                    })
            })
    }
}

export const handleOverrideWinningBidId = (lotId: any, bidId: any, onCompletedCallback: (res: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.overrideWinningBidId, { lotId, bidId })
            .then(function (res) {
                if (res.success) {
                    dispatch({ type: RELOAD_LOTS })
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleMarkAsSold = (lotId: string, soldAmount: number, onCompletedCallback: (res: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.markLotAsSold, { lotId, soldAmount })
            .then(function (res) {
                if (res.success) {
                    dispatch({ type: RELOAD_LOTS })
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleMarkAsUnsold = (lotId: string, onCompletedCallback: (res: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.markLotAsUnsold, { lotId })
            .then(function (res) {
                if (res.success) {
                    dispatch({ type: RELOAD_LOTS })
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}

export const handleRetrieveWishlistProfilesByLotId = (lotId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.retrieveWishlistProfilesByLotId, {
            lotId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleRetrieveWishlistLotsByAuctionId = (auctionId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.retrieveWishlistLotsByAuctionId, {
            auctionId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const invalidateRegistrationProcessDocument = (data: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.document.invalidateRegistrationProcessDocument, data).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const requestAdditionalRegistrationProcessDocuments = (data: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.document.requestAdditionalRegistrationProcessDocuments, data).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleUpdateComplianceStatus = (registeredProfileId: string, complianceStatus: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.updateComplianceStatus, { id: registeredProfileId, complianceStatus }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleNotifyUserOfMissingDocuments = (auctionId: string, registeredProfileId: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.notifyUserOfMissingDocuments, { auctionId, registeredProfileId }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleDetermineWinningAmount = (lotId: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {

        return requestClient.get(apiPaths.lot.determineWinningAmount, { lotId: lotId }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleRetrieveFinanceDocumentsForAuctionReport = (auctionId: string, type: FinanceDocumentType, exportType: FileExportType, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveFinanceDocumentsForAuctionReport, { auctionId, type, exportType }, null)
            .then(function (res) {
                if (res?.success) {
                    const binaryString = window.atob(res.response.data);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; ++i) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: res.response.contentType });
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        res.response.filename,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                }
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                dispatch(error(err))
            })
    }
}