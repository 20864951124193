import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { handleFetchImageUpsertRequests } from '@/pages/Integration/store/Integration';
import { Button, Card, CardContent, CircularProgress, Tooltip } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { NestedAccordionGrid, Header, NestedGridDataModel, Row } from "@/components/NestedAccordionGrid";
import { FaRedo } from "react-icons/fa";
import { ProcessingStatus } from '@/helpers/Integration';
import { formatDateTime } from "../../helpers/Utils";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
    handleFetchImageUpsertRequests_d: (onCompletedCallback: (res: any) => void) => void,
    loading: boolean,
    settings: any,
    integration: any
}

const ImageUpsertRequestList: React.FC<Props> = (props) => {
    const {
        handleFetchImageUpsertRequests_d,
        loading,
        settings,
        integration,
        integration: { imageUpsertRequests }
    } = props;

    const [imageUpsertRequestsModel, setImageUpsertRequestsModel] = useState<NestedGridDataModel>();
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        reload();
    }, [])

    useEffect(() => {
        resetImageUpsertRequests();
    }, [imageUpsertRequests, reloading])

    const resetImageUpsertRequests = () => {
        if (imageUpsertRequests) renderRequestsTable();
    }

    const reload = (withLoading = true) => {
        setReloading(true);
        if (withLoading) showProgressSpinner({ description: 'Loading image upsert requests...' });
        handleFetchImageUpsertRequests_d((res) => {
            if (!res.success) {
                ns.error('Failed to load the image upsert requests');
            }
            hideProgressSpinner();
            setReloading(false);
        })
    }

    const renderRequestsTable = () => {
        let headers: Header[] = [
            { title: "User", align: "inherit" },
            { title: "Unique Reference", align: "inherit" },
            { title: "Processing Status", align: "inherit" },
            { title: "Created Date", align: "inherit" },
            { title: "Process Date", align: "inherit" },
        ];

        let rows: Row[] = imageUpsertRequests.map(request => {
            return {
                id: request.id,
                cells: [
                    { text: request.username, align: "inherit" },
                    { text: request.uniqueReference, align: "inherit" },
                    { text: ProcessingStatus[request.processingStatus], align: "inherit" },
                    { text: formatDateTime(request.createdAt, 'yyyy/MM/dd HH:mm'), align: "inherit" },
                    { text: request.processedAt ? formatDateTime(request.processedAt, 'yyyy/MM/dd HH:mm') : 'N/A', align: "inherit" },
                ],
                //actionButtons: [
                //    <Tooltip title={`Generate Integration Token`} >
                //        <Button onClick={() => generateToken(user.id)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                //            {generatingTokens?.find(x => x === user.id) ? <CircularProgress size={20} /> : <AiOutlineReload />}
                //        </Button>
                //    </Tooltip>
                //]
            }
        });

        setImageUpsertRequestsModel({
            headers: headers,
            rows: rows,
            headerButtons: [
                <Tooltip title="Refresh Table" placement="left">
                    <LoadingButton disabled={reloading} loading={reloading} onClick={() => reload(false)} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                        <FaRedo />
                    </LoadingButton>
                </Tooltip>
            ]
        });
    }

    return <Card className='card-transparent'>
        <CardContent className='pt-0'>
            <NestedAccordionGrid dataModel={imageUpsertRequestsModel} showTopDivider={false} />
        </CardContent>
    </Card>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleFetchImageUpsertRequests_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchImageUpsertRequests(onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    integration: state.integration
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpsertRequestList);