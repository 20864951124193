import { Dialog, DialogContent, DialogTitle, Grid, DialogActions, Button, Autocomplete, TextField, TablePagination, Tooltip, } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { FaEnvelope, FaTimes } from 'react-icons/fa';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { emailPattern } from '../../../helpers/Regex';
import { limitConsolidatedLotNumbers } from '@/helpers/Utils';
import LoadingButton from '../../LoadingButton';

interface EmailInvoiceDialogProps {
    seedValues?: any;
    onValuesChanged?: (values: any) => void;

    data: any;
    contacts: any;

    onSubmit: (isBulk: boolean, payload: any) => void;

    onClose: () => void;
    isBulk?: boolean;

    loading: boolean;
}

const columnNames = ['Doc.No.', 'Lot Number', 'Customer Name', 'Entity Type',  'Cust.Ref.', 'To', 'Cc'];

const EmailInvoiceDialog: React.FC<EmailInvoiceDialogProps> = (props) => {
    const {
        seedValues,
        onValuesChanged,
        data,
        contacts,
        onSubmit,
        onClose,
        isBulk = false,
        loading
    } = props;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [errors, setErrors] = useState({});

    const [values, setValues] = useState<any>({
        ...(seedValues ?? {}),
    });
    const [inputValues, setInputValues] = useState<any>({});

    const uniqueContacts = useMemo(() => contacts.filter((item, index, self) => index === self.findIndex((t) => (t.email === item.email))), [contacts]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        onValuesChanged?.(values);
    }, [isBulk, values])

    const validate = () => {
        let isValid = true;

        const errors = {};

        Object.keys(values).forEach((key) => {
            if (key === 'selectedRowId') return;

            const value = values[key];

            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    let email = item;
                    if (typeof email !== 'string') email = email?.email;

                    if (!email || !emailPattern.test(email)) {
                        errors[key] = "Please enter a valid email address.";
                        isValid = false;
                    }
                });
            }
            else {
                let email = value;
                if (typeof email !== 'string') email = email?.email;

                if (!email || email === '' || !emailPattern.test(email)) {
                    errors[key] = "Please enter a valid email address.";
                    isValid = false;
                }
            }
        });

        setErrors(errors);

        return isValid;
    }

    const calculateEmailArray = (value: any) => {
        if (!value) return [];
        if (typeof value === 'string') return [value];
        if (Array.isArray(value)) return value.map((item) => {
            if (typeof item === 'string') return item;
            return item.email
        });
        return [value.email];
    }

    const handleEmailProcessOnClick = () => {
        if (!validate()) return;

        if (isBulk) {
            onSubmit(isBulk, {
                bulkSendRequests: data.map((item) => {
                    return {
                        toOverride: calculateEmailArray(values[item.id + "_to"]),
                        ccOverride: calculateEmailArray(values[item.id + "_cc"] ?? []),
                        financeDocumentIds: [item.id]
                    }
                })
            });
        } else {
            onSubmit(isBulk, {
                id: values.selectedRowId,
                toOverride: calculateEmailArray(values.toEmail),
                ccOverride: calculateEmailArray(values.ccEmail),
            });
        }
    }

    useEffect(() => {
        validate();
    }, [values])

    const orderedData = useMemo(() => {
        return data.sort((a, b) => {
            const aNumber:string = a.original.number;
            const bNumber:string = b.original.number;
            return aNumber.localeCompare(bNumber, 'en', { numeric: true, sensitivity: 'base' })
        }).slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage);
    }, [data, page, rowsPerPage]);

    const hasErrors = Object.keys(errors).length > 0;

    return (
        <>
            <Dialog
                open
                onClose={(event, reason) => {
                    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
                        return;
                    onClose();
                }}
                aria-labelledby="max-width-dialog-title"
                fullWidth
                maxWidth={isBulk ? false : 'lg'}
            >
                <DialogTitle style={{ paddingLeft: 40 }} id="max-width-dialog-title">
                    Email Invoice
                    {Object.keys(errors).length > 0 && <><br /><small style={{ color: 'red', fontSize: '0.7em' }}>Please correct the errors before proceeding.</small></>}
                </DialogTitle>
                <DialogContent>
                    {!isBulk && <>
                        <Grid container spacing={2} style={{ justifyContent: 'center', alignItems: 'center', padding: 20, marginTop: 10 }} >
                            <Grid item xs={1}>
                                <span style={{ fontSize: 16 }}> To: </span>
                            </Grid>
                            <Grid item xs={11}>
                                <div className="dropdown-menu-xxl" style={{ width: '100%', justifyContent: 'flex-end', padding: 10 }}>
                                    <Autocomplete
                                        disableClearable
                                        freeSolo
                                        autoSelect
                                        options={uniqueContacts}
                                        getOptionLabel={(option) => (typeof option === 'string' ? option : option['email'])}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Email Address"
                                                margin="normal"
                                                helperText={errors['toEmail']}
                                                error={!!errors['toEmail']}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            setValues(currentValue => ({ ...currentValue, toEmail: value }));
                                        }}
                                        value={values['toEmail']}
                                        inputValue={inputValues['toEmail'] ?? ''}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValues(currentValue => ({ ...currentValue, toEmail: newInputValue.replace(" ", "") }));
                                        }}
                                        typeof="email"
                                        onBlur={() => {
                                            if (inputValues['toEmail'] === '') {
                                                let email = values['toEmail'];
                                                if (typeof email !== 'string') email = email.email;

                                                setInputValues(currentValue => ({ ...currentValue, ['toEmail']: email }));
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                            <br />
                            <Grid item xs={1}>
                                <span style={{ fontSize: 16 }}> Cc: </span>
                            </Grid>
                            <Grid item xs={11}>
                                <div className="dropdown-menu-xxl" style={{ width: '100%', justifyContent: 'flex-end', padding: 10 }}>
                                    <Autocomplete
                                        disableClearable={true}
                                        multiple
                                        freeSolo
                                        autoSelect
                                        options={uniqueContacts}
                                        getOptionLabel={(option) => {
                                            return (typeof option === 'string' ? option : option['email'])
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Email Address"
                                                margin="normal"
                                                helperText={errors['ccEmail']}
                                                error={!!errors['ccEmail']}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            setValues(currentValue => ({ ...currentValue, ['ccEmail']: value }));
                                        }}
                                        value={values['ccEmail'] ?? []}
                                        inputValue={inputValues['ccEmail'] ?? ''}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValues(currentValue => ({ ...currentValue, ['ccEmail']: newInputValue.replace(" ", "") }));
                                        }}
                                        typeof="email"

                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </>}

                    {isBulk && <>
                        <div className="mx-2 mt-4" style={{ padding: 10 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ tableLayout: "auto" }} >
                                    <TableHead >
                                        <TableRow>
                                            {columnNames.map((header) => (<TableCell align="left" style={{ fontWeight: 'bold' }}>{header}</TableCell>))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orderedData.map((row, index) => {
                                            const toId = row?.original?.id + "_to";
                                            const ccId = row?.original?.id + "_cc";

                                            return (<TableRow
                                                key={row.original.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">{row.original.number}</TableCell>
                                                <TableCell component="th" scope="row" align="left">{row.original.extraData?.lotNumber ?? limitConsolidatedLotNumbers(row.original.extraData?.consolidatedLotNumbers)}</TableCell>
                                                <TableCell align="left">{row.original.customerName}</TableCell>
                                                <TableCell align="left">{row.original.customerType}</TableCell>
                                                <TableCell align="left">{row.original.reference}</TableCell>
                                                <TableCell align="left" style={{ minWidth: 350, paddingTop: 0, paddingBottom: 0 }} >
                                                    <Autocomplete
                                                        freeSolo
                                                        autoSelect
                                                        disableClearable={true}
                                                        id={`toEmail_id#${row.original.id}`}
                                                        options={uniqueContacts}
                                                        getOptionLabel={(option) => (typeof option === 'string' ? option : option['email'])}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                required
                                                                label="Email Address"
                                                                margin="normal"
                                                                helperText={errors[toId]}
                                                                error={!!errors[toId]}
                                                            />
                                                        )}
                                                        onChange={(e, value) => {
                                                            setValues(currentValue => ({ ...currentValue, [toId]: value }));
                                                        }}
                                                        value={values[toId] ?? ''}
                                                        inputValue={inputValues[toId] ?? ''}
                                                        onInputChange={(event, newInputValue) => {
                                                            setInputValues(currentValue => ({ ...currentValue, [toId]: newInputValue.replace(" ", "") }));
                                                        }}
                                                        typeof="email"
                                                        onBlur={() => {
                                                            if (inputValues[toId] === '') {
                                                                let email = values[toId];
                                                                if (typeof email !== 'string') email = email.email;

                                                                setInputValues(currentValue => ({ ...currentValue, [toId]: email }));
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="left" style={{ minWidth: 400, paddingTop: 0, paddingBottom: 0 }}>
                                                    <Autocomplete
                                                        freeSolo
                                                        autoSelect
                                                        disableClearable={true}
                                                        multiple
                                                        id={`ccEmail_id#${row.original.id}`}
                                                        options={uniqueContacts}
                                                        getOptionLabel={(option: any) => (typeof option === 'string' ? option : option['email'])}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Email Address"
                                                                margin="normal"
                                                                helperText={errors[ccId]}
                                                                error={!!errors[ccId]}
                                                            />
                                                        )}
                                                        onChange={(e, value) => {
                                                            setValues(currentValue => ({ ...currentValue, [ccId]: value }));
                                                        }}
                                                        value={values[ccId] ?? []}
                                                        inputValue={inputValues[ccId] ?? ''}
                                                        onInputChange={(event, newInputValue) => {
                                                            setInputValues(currentValue => ({ ...currentValue, [ccId]: newInputValue.replace(" ", "") }));
                                                        }}
                                                        typeof="email"
                                                    />
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showLastButton={true}
                                showFirstButton={true}
                                sx={{
                                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                        "marginTop": "1em",
                                        "marginBottom": "1em"
                                    }
                                }}
                            />
                        </div>
                    </>}

                    <div style={{ padding: 10 }}>
                        <DialogActions >
                            <LoadingButton
                                onClick={() => !hasErrors && handleEmailProcessOnClick()}
                                style={{
                                    opacity: hasErrors ? 0.5 : 1
                                }}
                                loading={loading}
                                label="Send"
                                icon={<FaEnvelope />}
                                disabled={hasErrors}
                                width={100}
                                disabledToolTip="Please correct the errors before proceeding."
                            />
                            <Button onClick={() => onClose()} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                                <span className="btn-wrapper--icon">
                                    <FaTimes />
                                </span>
                                <span className="btn-wrapper--label">Cancel</span>
                            </Button>
                        </DialogActions>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EmailInvoiceDialog;