import { FC, memo } from "react";
import useStyles from "../AuctionsOverviewFactsFigures.styles";
import { Box, Grid, Typography } from "@mui/material";
import { LotStatusAmounts } from "../AuctionsOverviewFactsFigures.types";
import { Skeleton } from "../../library/Skeleton";

interface LotStatusTotalsProps {
    settings: any;
    lotStatusAmounts: LotStatusAmounts;
    isLoading: boolean;
}

const LotStatusTotals: FC<LotStatusTotalsProps> = ({ settings, lotStatusAmounts, isLoading }) => {

    const { classes } = useStyles();

    return (
        <>
            <Grid
                container
                classes={{ root: classes.subContainer }}>
                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.successText }}>Open {settings?.LotName}s</Typography>
                                <Typography>{lotStatusAmounts?.openLotsTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.warningText }}>Extended {settings?.LotName}s</Typography>
                                <Typography>{lotStatusAmounts?.extendedLotsTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.dangerText }}>Completed {settings?.LotName}s</Typography>
                                <Typography>{lotStatusAmounts?.completedLotsTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(LotStatusTotals);
