import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleLotsCatalogueFetch } from '../AuctionManagement/store/AuctionManagement';
import { handleSettingsFetch } from '../Settings/store/Settings';
import { Grid, Card, Container } from '@mui/material';
import { MdDateRange, MdAccessTime, } from "react-icons/md";
import { ImListNumbered } from "react-icons/im";
import CurrencyLabel from '@/components/CurrencyComponent/CurrencyLabel';
import { HiOutlineLocationMarker, HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import useStyles from "./CatalogPrintStyles";
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import { formatDateTime } from '../../helpers/Utils';

interface Props {
    handleLotsCatalogueFetch_d: (auctionId: string, lotTypeId?: string) => void,
    handleSettingsFetch_d: () => void,
    componentLoaded: () => void,
    lotsData: any,
    errorText: any,
    error: any,
    success: any,
    settings: any,
    auction: any
}

const Catalogue: React.FC<Props> = props => {
    const {
        handleLotsCatalogueFetch_d,
        handleSettingsFetch_d,
        componentLoaded,
        lotsData,
        settings,
        auction
    } = props;

    const { auctionId }: any = useParams();
    const { classes, cx } = useStyles();
    const [lotFeatures, setLotFeatures]: any = React.useState([]);
    const [currentAuctiontype, setCurrentAuctionType]: any = React.useState(0);

    React.useEffect(() => {
        if (auctionId) {
            handleLotsCatalogueFetch_d(auctionId)
            handleSettingsFetch_d()
        }
    }, [auctionId])

    React.useEffect(() => {
        if (lotsData && lotsData !== undefined && settings && auction) {
            setCurrentAuctionType(auction.auctionType);

            var allFeaturesArr: any = []

            for (var i = 0; i < lotsData.length; i++) {
                var featuresArr: any = [];

                lotsData[i]?.dataCaptured?.additionalProperties?.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        featuresArr.push({ ...x, key: x.name });
                });

                allFeaturesArr.push(featuresArr);
            }

            setLotFeatures(allFeaturesArr)
            componentLoaded()
        }
    }, [lotsData, settings, auction])

    const getSTCStatus = (paramStcStatus) => {
        if (paramStcStatus.toString() === '0') {
            return 'STC No';
        } else if (paramStcStatus.toString() === '1') {
            return 'STC Yes';
        } else if (paramStcStatus.toString() === '2') {
            return 'STC Awaiting';
        }
    }

    return (
        <div>
            <div className="bg-primary mb-5 pt-5 pl-5 pr-5 pb-4" />
            <Container className="mb-5 mt-5">
                {auction && lotsData && (
                    <React.Fragment>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start">
                            <Grid item sm={12}>
                                <div className='d-flex flex-direction-row align-items-start justify-content-start'>
                                    <img src={settings.VendorLogoUrl} className={classes.logo} />
                                </div>
                                <h3 className=" text-black text-right">{auction.name}</h3>
                                <p className="text-black-50  text-right">{settings.VendorContactNumber} <HiOutlinePhone /></p>
                                <p className="text-black-50  text-right">{settings.VendorEmailAddress} <HiOutlineMail /></p>
                                <p className="text-black-50  text-right">{settings.VendorAddress} <HiOutlineLocationMarker /></p>

                            </Grid>
                        </Grid>

                        <div className="divider border-1 d-md-block rounded-circle border-dark opacity-5 mx-auto mx-xl-0 mb-3 w-100" />

                        <Card className="overflow-visible card-transparent m-1 p-1">
                            <div className="text-center">
                                <Grid container
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}>
                                    <Grid item md={4} xs={6}>
                                        <a
                                            href="#/"
                                            onClick={(e) => e.preventDefault()}
                                            className="p-4">
                                            <div className="d-flex flex-direction-row align-items-center justify-content-center">
                                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center bg-primary">
                                                    <MdDateRange className="font-size-lg center" />
                                                </div>
                                                <div>
                                                    <div className="text-black-50 pb-1">{settings.AuctionName + " Date"}</div>
                                                    <div className="text-black"><div className="text-black">{formatDateTime(auction.startDateTimeAt, 'yyyy/MM/dd')}</div></div>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>

                                    <Grid item md={4} xs={6}>
                                        <a
                                            href="#/"
                                            onClick={(e) => e.preventDefault()}
                                            className="p-4">
                                            <div className="d-flex flex-direction-row align-items-center justify-content-center">
                                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center bg-primary">
                                                    <MdAccessTime className="font-size-lg" />
                                                </div>
                                                <div>
                                                    <div className="text-black-50 pb-1">{settings.AuctionName + " Time"}</div>
                                                    <div className="text-black"><div className="text-black">{formatDateTime(auction.startDateTimeAt, 'HH:mm')}</div></div>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>

                                    <Grid item md={4} xs={6}>
                                        <a
                                            href="#/"
                                            onClick={(e) => e.preventDefault()}
                                            className="p-4">
                                            <div className="d-flex flex-direction-row align-items-center justify-content-center">
                                                <div className="d-40 p-2 btn-icon rounded-circle text-white mr-3 text-center bg-primary">
                                                    <ImListNumbered className="font-size-lg" />
                                                </div>
                                                <div>
                                                    <div className="text-black-50 pb-1">{"No. of " + settings.LotName + "s"}</div>
                                                    <div className="text-black">
                                                        {lotsData.length}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>

                                    <Grid item md={4} xs={6} />
                                </Grid>
                            </div>

                            <div className="divider border-1 d-md-block rounded-circle border-dark opacity-5 mx-auto mx-xl-0 mb-5 w-100" />
                        </Card>
                    </React.Fragment>)
                }

                <div className="pb-4">
                    {auction && lotsData && (
                        lotsData.map((lot, idx) => {
                            return <div>
                                <Card className="overflow-visible mb-4">
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        spacing={0}>

                                        <Grid item xs={4}>
                                            <div style={{ maxHeight: "232px", aspectRatio: "1.78" }}>
                                                <img
                                                    src={lot.imageUrl}
                                                    className="card-img-top rounded img-fit-container"
                                                    alt="Missing"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        cursor: "pointer",
                                                        objectFit: "scale-down",
                                                        background: "#000000",
                                                    }}
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={8} className="pl-2 pr-2">
                                            <div className="">
                                                {!lot.isActive && <div className="badge badge-pill badge-dark mt-1 text-capitalize ">Unavailable</div>}
                                                <div className="badge badge-pill badge-dark mt-1 text-capitalize" style={{ background: '#2B2B2B' }}>{getSTCStatus(lot.stcStatus)}</div>
                                                <div className="badge badge-pill badge-primary mt-1 ml-1 text-capitalize ">{"Buyer's Commission " + lot.commission + '%'}</div>
                                                {
                                                    Number(currentAuctiontype) !== Number(AuctionTypeEnum.Tender.toString()) &&
                                                    <div className="badge badge-pill badge-info mt-1 ml-1 text-capitalize "><CurrencyLabel value={lot.startingPrice} prefixString='Opening Bid ' /></div>
                                                }
                                            </div>

                                            <h5 className="mt-4 mb-4">
                                                <span className="text-black-75 font-weight-bold" style={{ color: "#5a5a5a" }}>{lot.number + ' | '}</span>
                                                <span
                                                    className="text-black font-weight-bold"
                                                    style={{
                                                        whiteSpace: "pre-wrap",
                                                        wordWrap: "break-word",
                                                    }}>{lot.name}</span>
                                            </h5>

                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="flex-start"
                                            >
                                                {lotFeatures[idx] && lotFeatures[idx].filter((x, index) => index <= 5).map((lotFeature, idx) => {
                                                    return (
                                                        <>
                                                            <Grid item xs={6} key={"lotF-" + idx}>
                                                                <h6 className=" mb-2 text-black">
                                                                    {lotFeature.key}
                                                                </h6>
                                                                <h6
                                                                    className="text-black-75 mb-2"
                                                                    style={{ color: "#5a5a5a" }}
                                                                >
                                                                    {lotFeature.value}
                                                                </h6>
                                                            </Grid>
                                                        </>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                        })
                    )}
                </div>
            </Container>
        </div >
    );
}

const mapStateToProps = (state: any) => ({
    lotsData: state.auctionManagement.lotsCatalogue,
    error: state.lots.error,
    errorText: state.lots.errorText,
    success: state.lots.success,
    settings: state.settings.settings,
    auction: state.auctionManagement.auction
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotsCatalogueFetch_d: (auctionId: string, lotTypeId?: string) => dispatch(handleLotsCatalogueFetch(auctionId, lotTypeId)),
        handleSettingsFetch_d: () => dispatch(handleSettingsFetch())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);