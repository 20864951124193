import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import { Card, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, InputAdornment } from '@mui/material';
import { FaTimes, FaSave, FaPlus, FaTrashAlt, FaBackspace } from "react-icons/fa";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import ns from '@/helpers/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { clearIncrementTableState, handleDeleteIncrementTable, handleIncrementTableUpsert, handleRetrieveIncrementTable } from "./store/IncrementTable";
import CurrencyLabel from '../../components/CurrencyComponent/CurrencyLabel';
import CurrencyInput from '../../components/CurrencyComponent/CurrencyInput';
import Currency from "@/components/CurrencyComponent/CurrencyComponent";

interface IncrementTableEditAddProps {
    incrementTable: any,
    handleRetrieveIncrementTable_d: (incrementTableId: string, onCompletedCallback: (response: any) => void) => void,
    handleIncrementTableUpsert_d: (incrementTableData, onCompletedCallback: (response: any) => void) => void,
    clearIncrementTableState_d: () => void,
    isDialog?: boolean,
    handleClose?: (data?: any) => void,
    settings: any,
}

const IncrementTableEditAdd: FC<IncrementTableEditAddProps> = props => {
    const {
        incrementTable,
        handleRetrieveIncrementTable_d,
        handleIncrementTableUpsert_d,
        clearIncrementTableState_d,
        isDialog,
        handleClose = null,
        settings,
    } = props;

    const [formFields, setFormFields] = useState({
        name: '',
        description: ''
    })
    const [formFieldsShowErrors, setFormFieldsShowErrors] = useState({
        name: false,
    })
    const [additionalFieldsShowErrors, setAdditionalFieldsShowErrors] = useState<any[]>([])
    const [additionalData, setAdditionalData] = useState<any[]>([]);

    const history = useHistory();

    const { id }: any = useParams();

    useEffect(() => {
        if (id) {
            showProgressSpinner({ description: "Retrieving Data" });
            handleRetrieveIncrementTable_d(id, (res) => {
                hideProgressSpinner();
            });
        }
    }, []);

    useEffect(() => {
        if (incrementTable !== undefined) {
            const addFields = incrementTable?.dataCaptured?.increments?.map(field => {
                let tempField = { ...field };
                tempField.isNameValid = true;
                return tempField;
            }) || [];
            const fields = {
                id: incrementTable.id,
                name: incrementTable.name,
                description: incrementTable.description
            };

            let validationObject = {
                incrementError: false,
                toAmountError: false
            }
            const validationArray = incrementTable?.dataCaptured?.increments?.map((item) => {
                return validationObject;
            })

            setAdditionalFieldsShowErrors(validationArray);
            setAdditionalData(addFields);
            setFormFields(fields);

            hideProgressSpinner();
        }
    }, [incrementTable])

    const validate = (e) => {
        if (e.target.required === true) {
            if (e.target.value === '')
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: true, });
            else
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: false, });
        }
    }

    const validateAll = () => {
        let isAllValid = true;
        let newState = {
            name: false,
        }
        for (const [key, value] of Object.entries(formFieldsShowErrors)) {
            if (newState[key] instanceof Object == false) {
                const formFieldsValue = formFields[key];
                if (formFieldsValue === "") {
                    isAllValid = false;
                    newState[key] = true;
                } else {
                    newState[key] = false;
                }
            }
        }
        setFormFieldsShowErrors(newState)
        return isAllValid;
    }

    const validateToAndFromAmount = () => {
        let tempValidArray = JSON.parse(JSON.stringify(additionalFieldsShowErrors));
        let selectedObj;
        let validToAmount: any[] = [];
        let validIncrement: any[] = [];
        let validToAndFromAmount: any[] = [];

        if (additionalData.length > 0) {

            additionalData.map((item, index) => {
                selectedObj = tempValidArray[index];
                if (item.toAmount <= item.fromAmount) {
                    selectedObj.toAmountError = true;
                    validToAmount.push(false);
                } else {
                    selectedObj.toAmountError = false;
                }

                if (item.increment === '' || item.increment == 0) {
                    selectedObj.incrementError = true;
                    validIncrement.push(false);
                } else {
                    selectedObj.incrementError = false;
                }

                tempValidArray.splice(index, 1, selectedObj)
            })
            setAdditionalFieldsShowErrors(tempValidArray);
        } else {
            ns.error("Please capture atleast one custom increment record in order to proceed.");
            return false;
        }

        if (validToAmount.includes(false) || validIncrement.includes(false) || validToAndFromAmount.includes(false)) {
            return false;
        } else {
            return true;
        }
    }


    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const updateFieldChanged = (index: any, nameOfField: any = undefined, value: any = undefined) => {
        let tempAdditionalData = [...additionalData];
        let fieldIndex = tempAdditionalData[index];
        let nextFieldIndex = tempAdditionalData[index + 1];
        if (fieldIndex !== undefined) {
            switch (nameOfField) {
                case "toAmount":
                    fieldIndex.toAmount = Number(value);

                    if (nextFieldIndex) {
                        nextFieldIndex.fromAmount = Number(value) + 1;
                    }
                    break;
                case "fromAmount":
                    fieldIndex.fromAmount = Number(value);
                    break;
                case "increment":
                    fieldIndex.increment = Number(value);
                    break;
                default:
                    break;
            }
            tempAdditionalData.splice(index, 1, fieldIndex);
            setAdditionalData(tempAdditionalData);
        }
    };


    const handleAddClicked = (index: any = undefined) => {
        let field;
        if (additionalData.length > 0) {
            field = { toAmount: 0, fromAmount: additionalData[additionalData.length - 1].toAmount + 1, increment: 0, isNameValid: true };
        } else {
            field = { fromAmount: 0, toAmount: 0, increment: 0, isNameValid: true };
        }
        let newValidationErrorField = { toAmountError: false, fromAmountError: false, incrementError: false }

        if (index > -1) {
            let newState = [...additionalData];
            newState.splice(index + 1, 0, field);
            setAdditionalData(newState);

            let tempValid = [...additionalFieldsShowErrors];
            tempValid.splice(index + 1, 0, newValidationErrorField);
            setAdditionalFieldsShowErrors(tempValid);
        }
        else {
            setAdditionalFieldsShowErrors([...additionalFieldsShowErrors, newValidationErrorField])
            setAdditionalData([...additionalData, field]);
        }
    }

    const onCancel = () => {
        if (isDialog === true && handleClose) handleClose();
        else {
            history.push('/incrementTable')
            clearIncrementTableState_d();
        }
    }

    const handleRemoveClicked = (index: any) => {
        let list = [...additionalData];
        list.splice(index, 1);
        setAdditionalData(list);
    }

    const navAddEdit = (incrementTableId) => {
        if (isDialog === true && handleClose) handleClose(incrementTableId);
        else {
            let path = '/IncrementTableEdit/' + incrementTableId
            history.push(path);
        }
    }

    const onSubmit = () => {
        const x = validateToAndFromAmount();
        const NameAndDescriptionValid = validateAll();

        if (x && NameAndDescriptionValid) {
            showProgressSpinner();
            let tempAdditionalData = additionalData.map(field => {
                let tempField = { ...field };
                delete tempField.isNameValid;
                return tempField;
            });

            let completeAPIPayload = {
                id: incrementTable?.id ?? "",
                name: formFields?.name,
                description: formFields?.description,
                dataCaptured: {
                    increments: tempAdditionalData
                }
            }

            if (tempAdditionalData) {
                handleIncrementTableUpsert_d(completeAPIPayload, (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success("Increment Table successfully saved");
                        navAddEdit(res?.response?.id)
                    } else {
                        ns.error(res?.error);
                    }
                })
            }
        }

    }

    const renderAdditionalField = (row: any, index: any, length: number) => <TableRow key={index}>
        <TableCell component="th" scope="row">
            <CurrencyLabel value={row.fromAmount} />
        </TableCell>
        <TableCell>
            <TextField InputProps={{
                inputComponent: CurrencyInput,
                startAdornment: (
                    <InputAdornment position="start">
                        <Currency priceWithSymbol={""} />
                    </InputAdornment>
                )
            }} value={row.toAmount} required error={additionalFieldsShowErrors[index]?.toAmountError ?? false} onChange={e => updateFieldChanged(index, "toAmount", e?.target?.value || '')} name="toAmountTxt" fullWidth className="m-2" label="To Amount" autoComplete="new-password" margin="normal" />
        </TableCell>
        <TableCell>
            <TextField InputProps={{
                inputComponent: CurrencyInput,
                startAdornment: (
                    <InputAdornment position="start">
                        <Currency priceWithSymbol={""} />
                    </InputAdornment>
                )
            }} value={row.increment} required error={additionalFieldsShowErrors[index]?.incrementError ?? false} onChange={e => updateFieldChanged(index, "increment", e?.target?.value || '')} name="incrementTxt" fullWidth className="m-2" label="Increment" autoComplete="new-password" margin="normal" />
        </TableCell>
        <TableCell align="right">
            <Tooltip arrow title="Delete" placement="bottom">
                <Button className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" onClick={() => handleRemoveClicked(index)}>
                    <FaTrashAlt className="font-size-sm" />
                </Button>
            </Tooltip>
        </TableCell>
    </TableRow>


    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header py-3">
                            <div className="card-header--title font-size-lg">
                                Increment Table
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="pr-2 pb-4">
                                <TextField value={formFields.name} autoFocus required error={formFieldsShowErrors.name} onChange={onChange} id="name" fullWidth className="m-2" label="Name" onBlur={validate} autoComplete="new-password" margin="normal" />
                                <TextField value={formFields.description} onChange={onChange} id="description" fullWidth className="m-2" label="Description" autoComplete="new-password" margin="normal" />
                            </div>

                            <div className="card-header--title font-size-lg">
                                Increment Breakdown
                            </div>
                            <p className="pt-2">The increments below will be applied to each {settings.LotName?.toLowerCase()} where this increment table is selected.</p>
                            <p style={{ color: 'red' }}>Please ensure that you read the below:
                                <li>
                                    At least one entry must be added for this increment table.
                                </li>
                                <li>
                                    The 'To Amount' must be greater than the 'From Amount'.
                                </li>
                                <li>
                                    All issues will be highlighted, per entry, in red.
                                </li>
                                <li>
                                    The last entry will be used as the final increment for each {settings.LotName?.toLowerCase()}.
                                </li>
                            </p>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>From Amount</TableCell>
                                                <TableCell>To Amount</TableCell>
                                                <TableCell>Increment</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip arrow title="Add" placement="bottom">
                                                        <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" onClick={() => handleAddClicked()} disableFocusRipple={true}>
                                                            <FaPlus />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {additionalData.map((row, index, list) => renderAdditionalField(row, index, list.length))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {
                Boolean(isDialog) === false ? (
                    <FloatyButtonGroup>
                        <Tooltip arrow title="Save" placement="left">
                            <Button size="large" variant="contained" onClick={onSubmit}
                                className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow title="Back" placement="left">
                            <Button size="large" variant="contained" onClick={onCancel}
                                className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                <span className="btn-wrapper--icon">
                                    <FaBackspace />
                                </span>
                            </Button>
                        </Tooltip>
                    </FloatyButtonGroup>)
                    :
                    (<div className="text-right">
                        <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaTimes />
                            </span>
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>

                        <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                            <span className="btn-wrapper--label">Save</span>
                        </Button>
                    </div>)
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRetrieveIncrementTable_d: (incrementTableId: string, onCompletedCallback: (res: any) => void) => dispatch(handleRetrieveIncrementTable(incrementTableId, onCompletedCallback)),
        handleDeleteIncrementTable_d: (incrementTableId: string, onCompletedCallback: (response: any) => void) => dispatch(handleDeleteIncrementTable(incrementTableId, onCompletedCallback)),
        handleIncrementTableUpsert_d: (incrementTableData, onCompletedCallback: (response: any) => void) => dispatch(handleIncrementTableUpsert(incrementTableData, onCompletedCallback)),
        clearIncrementTableState_d: () => dispatch(clearIncrementTableState())
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.incrementTable.loading,
    pagination: state.incrementTable.pagination,
    list: state.incrementTable.list,
    incrementTable: state.incrementTable.incrementTable,
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(IncrementTableEditAdd);