import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { RegisteredProfileStatusEnum } from '@/helpers/RegisteredProfileStatusEnum';
import { PrembidLocalStorage } from '../../../helpers/PrembidLocalStorage';
import { showProgressSpinner } from '../../../helpers/ProgressSpinnerService';

//Actions

const ACCOUNT_DATA_FETCH_SUCCESS = 'ACCOUNT_DATA_FETCH_SUCCESS';
const GET_USER_ACCEPTANCE_SUCCESS = 'GET_USER_ACCEPTANCE_SUCCESS';
const ACCOUNT_DATA_FETCH_ERROR = 'ACCOUNT_DATA_FETCH_ERROR';
const ACCOUNT_RESET_STATE = 'ACCOUNT_RESET_STATE';
const SAVE_USER_PROFILES_STATE = 'SAVE_USER_PROFILES_STATE';
const SAVE_UPDATE_STATE = 'SAVE_UPDATE_STATE';
const CLEAR_STATE = 'CLEAR_STATE';
const PARTICIPATE_ERROR = 'PARTICIPATE_ERROR';
const PARTICIPATE_ERROR_COMPLEX = 'PARTICIPATE_ERROR_COMPLEX';
const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const LOG_OUT = 'LOG_OUT';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    userAccount: undefined,
    userId: '',
    userTermsAcceptance: '',

    userAccountUpdateState: {
        successText: undefined,
        errorText: undefined,
        success: false,
        error: false
    },

    profilesState: {
        success: false,
        profiles: [],
        error: false,
        errorText: null
    },

    logout: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ACCOUNT_DATA_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, userAccount: action.payload }
        case GET_USER_ACCEPTANCE_SUCCESS: return { ...state, userTermsAcceptance: action.payload }
        case ACCOUNT_DATA_FETCH_ERROR: return { ...state, error: true, errorText: action.payload }
        case ACCOUNT_RESET_STATE: return { ...state, success: false, error: false }
        case SAVE_USER_PROFILES_STATE: return { ...state, profilesState: { ...action.payload } }
        case SAVE_UPDATE_STATE: return { ...state, userAccountUpdateState: { ...action.payload } }
        case CLEAR_STATE: return { ...initialState }
        case CREATE_USER_SUCCESS: return { ...state, error: false, errorText: '', success: true, id: action.payload }
        case PARTICIPATE_ERROR_COMPLEX:
            return {
                ...state,
                error: true,
                errorText: '',
                errorResult: action.payload,
            };
        case PARTICIPATE_ERROR:
            return { ...state, error: true, errorText: action.payload };
        case LOG_OUT:
            if (!state.logout) {
                showProgressSpinner({ keepOpen: true });
            }
            return { ...initialState, logout: true }

        default: return state;
    }
}


//Action creator
export const userAccountFetchError = (errorText: string) => {
    return { type: ACCOUNT_DATA_FETCH_ERROR, payload: errorText }
}

export const accountGetSuccess = (userAccount: any) => {
    return { type: ACCOUNT_DATA_FETCH_SUCCESS, payload: userAccount }
}

export const accountResetState = () => {
    return { type: ACCOUNT_RESET_STATE, payload: ACCOUNT_RESET_STATE }
}

export const saveUserProfiles = (profilesState) => {
    return { type: SAVE_USER_PROFILES_STATE, payload: profilesState }
}

export const saveUpdateUser = (state: any) => {
    return { type: SAVE_UPDATE_STATE, payload: state }
}

export const resetAccountState = () => {
    return { type: CLEAR_STATE }
}

export const getTermsAcceptance = (termsAccepted) => {
    return { type: GET_USER_ACCEPTANCE_SUCCESS, payload: termsAccepted }
}

export const handleUserAccountFetch = () => {

    return function (dispatch: any) {
        return requestClient.get(apiPaths.account.retrieveProfileAccount, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(accountGetSuccess(res.response))
                } else {
                    dispatch(userAccountFetchError(res.error))
                }
            })
    }
}

export const handleLogout = () => {
    return { type: LOG_OUT }
}

export const handleUserAccountUpdate = (user: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.updateAccountDetails, user)
            .then(function (res) {
                if (res.success) {
                    dispatch(saveUpdateUser({
                        successText: "Account updated successfully",
                        success: true,
                        error: false,
                        errorText: undefined
                    }))
                } else {
                    dispatch(saveUpdateUser({
                        success: false,
                        successText: undefined,
                        error: true,
                        errorText: res.error
                    }))
                }
            })
    }
}

export const handleUserAccountUpdateStatus = (userId: any, isActive: boolean, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.updateAccountStatus, { userId, isActive })
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleProfileAutoApproveRegistrationUpdateStatus = (profileId: any, autoApproveRegistration: boolean, profileType: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.updateProfileAutoApproveRegistrationStatus, { profileId, autoApproveRegistration, profileType })
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleUserProfilesFetch = (onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.profile.retrieveAllProfiles, null, null)
            .then(function (res) {
                if (res.success) {
                    const jsonData = res.response
                    dispatch(saveUserProfiles({
                        success: true,
                        error: false,
                        errorText: null,
                        profiles: jsonData
                    }))
                } else {
                    dispatch(
                        saveUserProfiles({
                            success: false,
                            error: true,
                            profiles: [],
                            errorText: res.error
                        }))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleUpsertUserDevice = (payload: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.upsertUserDevice + "?platform=adminportal", payload)
            .then(function (res) {
                if (res.success) {

                } else {
                    //TODO: Show error!
                }
            })
    }
}

export const getUserTermsAcceptance = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.account.getUserTermsAcceptance, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(getTermsAcceptance(res.response))
                } else {
                }
            })
    }
}

export const handleSubmitTermsAcceptance = () => {

    return (dispatch: any) => {
        let now = new Date(Date.now()).toISOString();
        let termsAcceptanceInfo = {
            termsAcceptedAt: now,
            userId: PrembidLocalStorage.currentUser?.userId,
        };
        requestClient.post(apiPaths.account.upsertTermsAcceptance, termsAcceptanceInfo).then(function (upsertTermsResponse) {
            dispatch(getTermsAcceptance(upsertTermsResponse.success));
        });
    }
}


const regUsersCreateSuccess = (id: string) => {
    return { type: CREATE_USER_SUCCESS, payload: id }
}

const errorComplex = (errorResponse: any) => {
    return { type: PARTICIPATE_ERROR_COMPLEX, payload: errorResponse };
};

const error = (errorText: string) => {
    return { type: PARTICIPATE_ERROR, payload: errorText };
};

export const handleRegisteredUserCreate = (auctionId: string, profileId: string, profileType: string, manualRegistration?: boolean, onCompletedCallback?: (result: any) => void) => {
    return function (dispatch: any) {

        const currentUser = PrembidLocalStorage.currentUser;
        if (currentUser === null || !currentUser.userId) {
            if (onCompletedCallback)
                onCompletedCallback({ success: false, error: "Log In is required in order to register" })
        } else {
            if (profileId.length > 0) {
                const newRegUser = {
                    profileId,
                    auctionId,
                    status: RegisteredProfileStatusEnum.Approved,
                    manualRegistration,
                    profileType
                }

                return requestClient.post(apiPaths.profile.create, newRegUser)
                    .then(function (res) {
                        if (res.success) {
                            dispatch(regUsersCreateSuccess(auctionId))
                        } else if (res.success === false && res.response !== null) {
                            dispatch(errorComplex(res.response));
                        } else {
                            dispatch(error(res.error));
                        }

                        if (onCompletedCallback)
                            onCompletedCallback(res)
                    })
            } else {
                if (onCompletedCallback)
                    onCompletedCallback({ success: false, error: "Please create a bidding profile before participating in an auction" })
            }
        }
    }
}