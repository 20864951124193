import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordionGrid';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import { Button, Card, Grid, Tooltip } from '@mui/material';
import { FaBackspace, FaRedo } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";
import { handleDeleteBulkUpload, handleFetchBulkUploadsWithPagination } from './store/BulkUpload';
import BulkUploadStatusComponent from './components/BulkUploadStatus';
import { handleAuctionFetch } from '../Auctions/store/Auctions';
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => void,
    handleFetch_d: (auctionId: string, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleDelete_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    settings: any,
    auction: any,
}

const BulkUploads: React.FC<Props> = props => {
    const {
        handleAuctionFetch_d,
        handleFetch_d,
        handleDelete_d,
        settings,
        auction,
    } = props;

    const { classes, cx } = useStyles();

    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    });
    const history = useHistory();

    const { auctionId }: any = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [displayModel, setDisplayModel] = useState<any>();

    const [bulkUploads, setBulkUploads] = useState<any>(undefined);

    useEffect(() => {
        if (auctionId && auction?.id !== auctionId) {
            handleAuctionFetch_d(auctionId)
        }
    }, [auctionId, auction])

    const refreshList = (pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoading(true);
        else showProgressSpinner({ description: `Retrieving Bulk Uploads...` });
        handleFetch_d(auctionId, pagination, (res: any) => {
            setLoading(false);
            if (res.success) {
                setBulkUploads(res.response);
            }
        });
    }

    useEffect(() => {
        refreshList(null, false);
    }, [])

    useEffect(() => {
        if (bulkUploads) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Number of " + settings.LotName + "s", align: "center", canSort: false },
                { title: "Status", align: "inherit" }
            ];

            bulkUploads?.list?.forEach((item) => {
                const cells: Cell[] = [
                    { text: item.name, align: "inherit" },
                    { text: item.count.toString(), align: "center" },
                    { text: item.status, element: <BulkUploadStatusComponent status={item.status} date={item.modifiedAt} error={item.error} />, align: "inherit" },
                ];

                rows.push({
                    id: item.id,
                    cells: cells,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setDisplayModel(model);
        }

        hideProgressSpinner();
    }, [bulkUploads])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(bulkUploads?.pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const onBulkUploadAdd = () => {
        history.push(`/BulkUploadsAdd?auctionId=${auctionId}`);
    }

    const onBulkUploadEdit = (id: any) => {
        history.push(`/BulkUploadsEdit/${id}?auctionId=${auctionId}`);
    }

    const onBulkUploadDelete = (id: any) => {
        showDeleteModal(true, id);
    }

    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting Bulk Upload..." });
        handleDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Bulk upload deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the bulk upload")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    }

    const handlePageChange = (pagination: any) => {
        refreshList(pagination)
    }

    const onCancel = () => {
        history.goBack();
    }

    return <div>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Card className="card-box mb-spacing-6-x2">
                    <div className="card-header py-3">
                        <div className="card-header--title font-size-lg">
                            Bulk Uploader
                        </div>
                    </div>
                    <div className="card-header py-3">
                        <div className='text-center'>
                            <b>See the Bulk Uploads for {auction?.name} below.</b>
                        </div>
                    </div>
                    <div className="table-responsive-md">
                        {
                            bulkUploads?.list?.length > 0 ? (
                                <NestedAccordionGrid
                                    dataModel={displayModel}
                                    onDelete={onBulkUploadDelete}
                                    onEdit={onBulkUploadEdit}
                                    pagination={bulkUploads?.pagination}
                                    onHandleChangePage={handlePageChange}
                                    loading={loading}
                                    showTopDivider={false}
                                />
                            ) : (
                                <NestedAccordionGrid
                                    dataModel={displayModel}
                                    onDelete={onBulkUploadDelete}
                                    onEdit={onBulkUploadEdit}
                                    onAdd={onBulkUploadAdd}
                                    pagination={bulkUploads?.pagination}
                                    onHandleChangePage={handlePageChange}
                                    loading={loading}
                                    showTopDivider={false}
                                />
                            )
                        }
                    </div>
                </Card>
            </Grid>
        </Grid>
        {
            deleteModal.open &&
            <ConfirmationDialog
                open={deleteModal.open}
                payload={deleteModal.id}
                okButtonText="Yes"
                cancelButtonText="Cancel"
                description={'Are you sure you want to delete this bulk upload?'}
                title={"Confirm Bulk Upload Delete"}
                onCancel={onDeleteModalClosed}
                onOk={onDeleteModalOk} />
        }
        <FloatyButtonGroup>
            <Tooltip arrow title="Back" placement="left">
                <Button size="large" variant="contained" onClick={onCancel}
                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                    <span className="btn-wrapper--icon">
                        <FaBackspace />
                    </span>
                </Button>
            </Tooltip>
        </FloatyButtonGroup>
    </div>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionFetch(id, onCompletedCallback)),
        handleFetch_d: (auctionId: string, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleFetchBulkUploadsWithPagination(auctionId, pagination, onCompletedCallback)),
        handleDelete_d: (id: string, onCompletedCallback: (result: any) => void) => dispatch(handleDeleteBulkUpload(id, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    auction: state.auctions.auction,
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploads);