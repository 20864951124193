import React, { } from "react";
import { Auction } from 'prembid-shared-library-npm/types';
import { AuctionOverviewGrid } from "@/components/AuctionsOverviewGrid";
import { AuctionsOverviewFactsFigures } from "../../components/AuctionsOverviewFactsFigures";
import { Box } from "@mui/system";

interface Props {
    selectedAuction: Auction,
}

const AuctionOverview: React.FC<Props> = ({ selectedAuction }) => {
    return (
        <>
            <Box marginBottom={5}>
                <AuctionsOverviewFactsFigures auction={selectedAuction} />
            </Box>

            <Box>
                <AuctionOverviewGrid auction={selectedAuction} />
            </Box>
        </>
    )
};

export default AuctionOverview;