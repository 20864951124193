import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearError, clearLotType, handleLotTypeAdd, handleLotTypeUpdate, handleLotTypeFetch, newLotTypeAuction, newLotTypeLots } from '@/pages/LotTypes/store/LotTypes';
import { Card, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, InputAdornment, Select, MenuItem, Checkbox, Switch, FormControlLabel, Divider, Box } from '@mui/material';
import { FaTimes, FaSave, FaPlus, FaTrashAlt, FaBackspace } from "react-icons/fa";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import ns from '@/helpers/NotificationService';
import CurrencyLabel from '../../components/CurrencyComponent/CurrencyLabel';
import CurrencyInput from '../../components/CurrencyComponent/CurrencyInput';
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import { forEach, sortBy } from "lodash";
import { handleAddBiddingCalculation, handleFetchBiddingCalculation, handleUpdateBiddingCalculation } from './store/BiddingCalculations';

const CalculationBasedOn = {
    "bid": "Bid Amount",
    "cumulativeTotal": "Cumulative Total"
}

const CalculationTypes = {
    "numeric": "Numeric Value",
    "percentage": "Percentage"
};


interface Props {
    handleFetchBiddingCalculation_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    handleAddBiddingCalculation_d: (payload: any, onCompletedCallback: (res: any) => void) => void,
    handleUpdateBiddingCalculation_d: (id: string, payload: any, onCompletedCallback: (res: any) => void) => void,
    isDialog?: boolean,
    handleClose?: (data?: any) => void,
    settings: any,
}

const BiddingCalculationsAddEdit: React.FC<Props> = props => {
    const {
        handleAddBiddingCalculation_d,
        handleUpdateBiddingCalculation_d,
        handleFetchBiddingCalculation_d,
        isDialog,
        handleClose = null,
        settings,
    } = props;

    const [formFields, setFormFields] = useState({
        name: '',
        description: ''
    })
    const [formFieldsShowErrors, setFormFieldsShowErrors] = useState<{ name: boolean, biddingCalculationIds: string[] }>({
        name: false,
        biddingCalculationIds: []
    })
    const history = useHistory();

    const { id }: any = useParams();

    const [bidAmount, setBidAmount] = useState<number>(0);
    const [buyersCommissionPercentage, setBuyersCommissionPercentage] = useState<number>(0);
    const buyersCommissionAmount = useMemo(() => { return bidAmount * buyersCommissionPercentage / 100; }, [bidAmount, buyersCommissionPercentage]);

    const [applyVat, setApplyVat] = useState<boolean>(true);
    const [vatPercentage, setVatPercentage] = useState<number>(15);

    const [additionalCalculations, setAdditionalCalculations] = useState<any[]>([]);

    useEffect(() => {
        if (id) {
            showProgressSpinner({ description: "Retrieving Bidding Calculation..." });
            handleFetchBiddingCalculation_d(id, (res: any) => {
                if (res?.success) {
                    let data = res.response;
                    setFormFields({
                        name: data.name,
                        description: data.description,
                    })
                    if (data?.dataCaptured) {
                        setAdditionalCalculations(data?.dataCaptured?.calculations);
                        setApplyVat(data?.dataCaptured?.applyVat);
                    }
                }
                else {
                    //TODO
                }
                hideProgressSpinner();
            });
        }
    }, [id])

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onCancel = () => {
        if (isDialog === true && handleClose) handleClose();
        else {
            history.push('/BiddingCalculations')
        }
    }

    const onSubmit = () => {
        const isAllFieldsValid = validateAll()
        if (isAllFieldsValid) {
            showProgressSpinner({ description: "Saving Bidding Calculation..." });

            let payload = {
                ...formFields,
                dataCaptured: {
                    calculations: additionalCalculations,
                    applyVat
                }
            }

            if (id === undefined) {
                handleAddBiddingCalculation_d(payload, (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success("Bidding calculation successfully added");
                        if (isDialog === true && handleClose) handleClose(res.response);
                        else navAddEdit(res.response)
                    } else {
                        ns.error(res?.error);
                    }
                })
            } else {
                handleUpdateBiddingCalculation_d(id, payload, (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success("Bidding calculation successfully updated");
                        if (isDialog === true && handleClose) handleClose(res.response);
                    } else {
                        ns.error(res?.error);
                    }
                })
            }
        }
        else {
            ns.error("Please fill in all required fields");
        }
    }

    const validate = (e) => {
        if (e.target.required === true) {
            if (e.target.value === '')
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: true, });
            else
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: false, });
        }
    }

    const validateAll = () => {
        let isAllValid = true;
        let newState: any = {
            name: false,
            biddingCalculationIds: []
        }
        for (const [key, value] of Object.entries(formFieldsShowErrors)) {
            if (key === "biddingCalculationIds") {
                forEach(additionalCalculations, (item) => {
                    if (item.description === "") {
                        newState.biddingCalculationIds.push(item.id);
                        isAllValid = false;
                    }
                });
            }
            if (newState[key] instanceof Object == false) {
                const formFieldsValue = formFields[key];
                if (formFieldsValue === "") {
                    isAllValid = false;
                    newState[key] = true;
                } else {
                    newState[key] = false;
                }
            }
        }
        setFormFieldsShowErrors(newState)
        return isAllValid;
    }



    const updateAdditionalCalculations = (id: string, field: string, value: any) => {
        let tempList = [...additionalCalculations];
        let index = tempList.findIndex(x => x.id === id);
        if (index >= -1) {
            let item = tempList[index];
            item[field] = value;
        }
        setAdditionalCalculations(tempList);
    }

    const determineCalculationAmount = (basedOn: any, subTotal: number, bidAmount: number, amount: number, type: any) => {
        let total = bidAmount;
        if (basedOn === "cumulativeTotal") total = subTotal;

        switch (type) {
            case "percentage": return total * amount / 100;
            default: return amount;
        }
    }

    const calculationTotals = useMemo<number[]>(() => {
        if (additionalCalculations.length === 0) return [];

        let orderdedList = sortBy(additionalCalculations, ["order"]);

        let totals: number[] = [];

        let subTotal = bidAmount + buyersCommissionAmount;

        for (let i = 0; i < orderdedList.length; i++) {
            let item = orderdedList[i];
            let calculationAmount = determineCalculationAmount(item.basedOn, subTotal, bidAmount, item.amount, item.type);
            subTotal += calculationAmount;
            totals.push(subTotal);
        }

        return totals;
    }, [bidAmount, buyersCommissionAmount, additionalCalculations]);

    const vatAmount = useMemo(() => {
        return (calculationTotals.length > 0 ? calculationTotals[calculationTotals.length - 1] : bidAmount + buyersCommissionAmount) * vatPercentage / 100;
    }, [bidAmount, buyersCommissionAmount, calculationTotals])

    const totalExVatAmount = useMemo(() => {
        return calculationTotals.length > 0 ? calculationTotals[calculationTotals.length - 1] : (bidAmount + buyersCommissionAmount);
    }, [bidAmount, buyersCommissionAmount, calculationTotals])

    const handleAddClicked = (index: any = undefined) => {
        let tempList = [...additionalCalculations];
        let lastRow: any = null;

        if (index === undefined && additionalCalculations.length > 0) {
            lastRow = additionalCalculations[additionalCalculations.length - 1];
        }
        else if (index > 0) {
            lastRow = additionalCalculations[index - 1];
        }

        let newRow = { id: uuidv4(), description: "", type: "percentage", basedOn: "cumulativeTotal", amount: 0 }

        tempList.splice((index ?? additionalCalculations.length) + 1, 0, newRow);

        reorderAdditionalCalculations(tempList);
    }

    const handleRemoveClicked = (index: any) => {
        let tempList = [...additionalCalculations];
        tempList.splice(index, 1);
        reorderAdditionalCalculations(tempList);
    }

    const reorderAdditionalCalculations = (list: any[]) => {
        var finalList = list.map((x, index) => {
            let item = { ...x };
            item.order = index + 1;
            return item;
        })

        setAdditionalCalculations(finalList);
    }

    const handleUpClicked = (index: any) => {
        let list = [...additionalCalculations];
        let current = { ...list[index] };
        let previous = { ...list[index - 1] };
        list.splice(index - 1, 1, current);
        list.splice(index, 1, previous);
        reorderAdditionalCalculations(list);
    }

    const handleDownClicked = (index: any) => {
        let list = [...additionalCalculations];
        let current = { ...list[index] };
        let next = { ...list[index + 1] };
        list.splice(index, 1, next);
        list.splice(index + 1, 1, current);
        reorderAdditionalCalculations(list);
    }

    const navAddEdit = (biddingCalculationId) => {
        if (isDialog === true && handleClose) handleClose();
        else {
            let path = '/BiddingCalculationsEdit/' + biddingCalculationId
            history.push(path);
        }
    }

    const renderRow = (item: any, index: number, list: any[]) => {
        return <TableRow>
            <TableCell >
                <TextField margin="normal"
                    onChange={(e) => { updateAdditionalCalculations(item.id, "description", e.target.value) }}
                    id="description"
                    value={item.description}
                    fullWidth
                    error={formFieldsShowErrors.biddingCalculationIds.includes(item.id)}
                />
            </TableCell>
            <TableCell>
                <Box className="mt-2">
                    {renderTypeSelector(item)}
                </Box>
            </TableCell>
            <TableCell >
                <TextField margin="normal"
                    onChange={(e) => { updateAdditionalCalculations(item.id, "amount", Number(e.target.value)) }}
                    id="amount"
                    value={item.amount}
                    fullWidth
                    InputProps={{
                        inputComponent: CurrencyInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Currency prefixString={item.type === "percentage" ? "%" : undefined} hideCurrencySymbol={item.type === "percentage" ? true : false} priceWithSymbol={""} />
                            </InputAdornment>
                        )
                    }} />
            </TableCell>
            <TableCell>
                <Box className="mt-2">
                    {renderBasedOnSelector(item)}
                </Box>
            </TableCell>
            <TableCell >
                <CurrencyLabel value={calculationTotals?.[item.order - 1]} />
            </TableCell>
            <TableCell align="right">
                <div style={{ minWidth: 100 }}>
                    {index > 0 &&
                        <Button className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize"
                            onClick={() => handleUpClicked(index)}>
                            <MdArrowUpward />
                        </Button>
                    }
                    {index < list.length - 1 &&
                        <Button className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize"
                            onClick={() => handleDownClicked(index)}>
                            <MdArrowDownward />
                        </Button>
                    }
                    <Tooltip arrow title="Add" placement="bottom">
                        <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize mt-2 mb-2" onClick={() => handleAddClicked(index)}>
                            <FaPlus className="font-size-sm" />
                        </Button>
                    </Tooltip>
                    <Tooltip arrow title="Delete" placement="bottom">
                        <Button className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" onClick={() => handleRemoveClicked(index)}>
                            <FaTrashAlt className="font-size-sm" />
                        </Button>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    }

    const renderTypeSelector = (item: any) => <Select
        fullWidth
        value={item.type ?? "numeric"}
        onChange={(e) => { updateAdditionalCalculations(item.id, "type", e.target.value) }}
    >
        {Object.keys(CalculationTypes).map((key, index) => <MenuItem key={index} value={key}>
            {CalculationTypes[key]}
        </MenuItem>)}
    </Select>

    const renderBasedOnSelector = (item: any) => item.type === "percentage" ? <Select
        fullWidth
        value={item.basedOn ?? "bid"}
        onChange={(e) => { updateAdditionalCalculations(item.id, "basedOn", e.target.value) }}
    >
        {Object.keys(CalculationBasedOn).map((key, index) => <MenuItem key={index} value={key}>
            {CalculationBasedOn[key]}
        </MenuItem>)}
    </Select> : CalculationBasedOn.bid;

    const renderTableHeader = (showAdd: boolean, titles?: any) => <TableHead>
        <TableRow>
            <TableCell width='20%'>Description</TableCell>
            <TableCell width='20%'>Type</TableCell>
            <TableCell width='20%'>Amount</TableCell>
            <TableCell width='20%'>Calculated From</TableCell>
            <TableCell width='10%'>{titles?.summary ?? 'Summary (Cumulative Total)'}</TableCell>
            <TableCell width='10%' align="right" >
                <div style={{ minWidth: 100 }}>
                    {showAdd && <Tooltip arrow title="Add" placement="bottom">
                        <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" onClick={() => handleAddClicked()} disableFocusRipple={true}>
                            <FaPlus />
                        </Button>
                    </Tooltip>}
                </div>
            </TableCell>
        </TableRow>
    </TableHead>

    return <>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Card className="card-box mb-spacing-6-x2">
                    <div className="card-header py-3">
                        <div className="card-header--title font-size-lg">
                            Bidding Calculations
                        </div>
                    </div>
                    <div className="p-4">
                        <div>
                            <p>These calculations determine the invoice totals and are used for invoicing. They will be displayed to bidders to inform them of their liabilities during bidding.</p>
                            <p style={{ color: 'red' }}>Please ensure all related fees, commissions, and taxes are included as invoices are automatically generated.
                                These calculations are used <u><b>PER {settings.LotName?.toUpperCase()}.</b></u>
                                <br />Order: Enter the calculations in the order of their execution.
                            </p>
                        </div>

                        <div className="pr-2 pb-4">
                            <TextField value={formFields.name} required error={formFieldsShowErrors.name} onChange={onChange} id="name" fullWidth label="Name" onBlur={validate} margin="normal" />
                            <TextField value={formFields.description} onChange={onChange} id="description" fullWidth label="Description" style={{ marginTop: 15 }} margin="normal" />
                        </div>

                        <div className="pb-4">
                            <div className="card-header--title font-size-lg">
                                Example Costs
                            </div>
                            <p className="pt-2">The values entered below will be replaced using the actual values configured or submitted for a {settings.LotName?.toLowerCase()} and are simply used for demonstration purposes of the calculations.</p>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        {renderTableHeader(false)}
                                        <TableBody>
                                            <TableRow>
                                                <TableCell >Bid Amount</TableCell>
                                                <TableCell >{CalculationTypes.numeric}</TableCell>
                                                <TableCell >
                                                    <TextField margin="normal"
                                                        onChange={e => setBidAmount(Number(e.target.value))}
                                                        id="commission"
                                                        value={bidAmount}
                                                        fullWidth
                                                        InputProps={{
                                                            inputComponent: CurrencyInput,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Currency priceWithSymbol={""} />
                                                                </InputAdornment>
                                                            )
                                                        }} />
                                                </TableCell>
                                                <TableCell>N/A</TableCell>
                                                <TableCell ><CurrencyLabel value={bidAmount} /></TableCell>
                                                <TableCell>{' '}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell >Buyers Commission</TableCell>
                                                <TableCell >{CalculationTypes.percentage}</TableCell>
                                                <TableCell >
                                                    <TextField margin="normal"
                                                        onChange={e => setBuyersCommissionPercentage(Number(e.target.value))}
                                                        id="commission"
                                                        value={buyersCommissionPercentage}
                                                        fullWidth
                                                        InputProps={{
                                                            inputComponent: CurrencyInput,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Currency prefixString={"%"} hideCurrencySymbol priceWithSymbol={""} />
                                                                </InputAdornment>
                                                            )
                                                        }} />
                                                </TableCell>
                                                <TableCell>{CalculationBasedOn.bid}</TableCell>
                                                <TableCell ><CurrencyLabel value={bidAmount + buyersCommissionAmount} /></TableCell>
                                                <TableCell>{' '}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="pb-4">
                            <div className="card-header--title font-size-lg">
                                Additional Costs
                            </div>
                            <p className="pt-2">The calculations below will be applied to each {settings.LotName?.toLowerCase()} where this bidding calculation is selected.</p>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        {renderTableHeader(true)}
                                        <TableBody>
                                            {additionalCalculations.map(renderRow)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div className="pb-4">
                            <div className="card-header--title font-size-lg">
                                Add VAT <Switch checked={applyVat} onChange={(e, checked) => setApplyVat(checked)} className="m-2 p-2 switch-medium toggle-switch-success" />
                            </div>
                            <p >{applyVat ? `VAT will be applicable on all items above and added to the cumulative total of all costs configured for the ${settings.LotName}.` : 'VAT not applicable'}</p>

                            {applyVat && <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        {renderTableHeader(false, { summary: "Calculated Value" })}
                                        <TableBody>
                                            <TableRow>
                                                <TableCell >VAT</TableCell>
                                                <TableCell >{CalculationTypes.percentage}</TableCell>
                                                <TableCell >
                                                    <TextField margin="normal"
                                                        onChange={e => setBuyersCommissionPercentage(Number(e.target.value))}
                                                        id="commission"
                                                        value={vatPercentage}
                                                        fullWidth
                                                        disabled
                                                        InputProps={{
                                                            inputComponent: CurrencyInput,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Currency prefixString={"%"} hideCurrencySymbol priceWithSymbol={""} />
                                                                </InputAdornment>
                                                            )
                                                        }} />
                                                </TableCell>
                                                <TableCell>{CalculationBasedOn.cumulativeTotal}</TableCell>
                                                <TableCell ><CurrencyLabel value={vatAmount} /></TableCell>
                                                <TableCell>{' '}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>}
                        </div>

                        <div className="pb-4">

                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width='20%'></TableCell>
                                                <TableCell width='20%'></TableCell>
                                                <TableCell width='20%'></TableCell>
                                                <TableCell width='20%'></TableCell>
                                                <TableCell width='10%'></TableCell>
                                                <TableCell width='10%'><div style={{ minWidth: 100 }}></div></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <div className="card-header--title font-size-lg" style={{ fontWeight: 'bold' }}>
                                                        Example Total
                                                    </div>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <div className="card-header--title font-size-lg">
                                                        <CurrencyLabel style={{ fontWeight: 'bold' }} value={totalExVatAmount + (applyVat ? vatAmount : 0)} />
                                                    </div>
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
        {
            Boolean(isDialog) === false ? (
                <FloatyButtonGroup>
                    <Tooltip arrow title="Save" placement="left">
                        <Button size="large" variant="contained" onClick={onSubmit}
                            className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                        </Button>
                    </Tooltip>
                    <Tooltip arrow title="Back" placement="left">
                        <Button size="large" variant="contained" onClick={onCancel}
                            className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                            <span className="btn-wrapper--icon">
                                <FaBackspace />
                            </span>
                        </Button>
                    </Tooltip>
                </FloatyButtonGroup>)
                :
                (<div className="text-right">
                    <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>

                    <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                        <span className="btn-wrapper--label">Save</span>
                    </Button>
                </div>)
        }
    </>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleFetchBiddingCalculation_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleFetchBiddingCalculation(id, onCompletedCallback)),
        handleAddBiddingCalculation_d: (payload: any, onCompletedCallback: (res: any) => void) => dispatch(handleAddBiddingCalculation(payload, onCompletedCallback)),
        handleUpdateBiddingCalculation_d: (id: string, payload: any, onCompletedCallback: (res: any) => void) => dispatch(handleUpdateBiddingCalculation(id, payload, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(BiddingCalculationsAddEdit);