import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordionGrid';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import { Button, Tooltip } from '@mui/material';
import { FaRedo } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";
import { handleDeleteBiddingCalculation, handleFetchBiddingCalculationsWithPagination } from './store/BiddingCalculations';

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleFetch_d: (filters: string, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleDelete_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    settings: any,
}

const BiddingCalculations: React.FC<Props> = props => {
    const {
        handleFetch_d,
        handleDelete_d,
        settings,
    } = props;

    const { classes, cx } = useStyles();

    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    });
    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [displayModel, setDisplayModel] = useState<any>();

    const [biddingCalculations, setBiddingCalculations] = useState<any>(undefined);

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoading(true);
        else showProgressSpinner({ description: `Retrieving Bidding Calculations...` });
        handleFetch_d(filters, pagination, (res: any) => {
            setLoading(false);
            if (res.success) {
                setBiddingCalculations(res.response);
            }
        });
    }

    useEffect(() => {
        refreshList(null, null, false);
    }, [])

    useEffect(() => {
        if (biddingCalculations) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" }
            ];

            biddingCalculations?.list?.forEach((item) => {
                let name = item.name?.substring(0, 35);
                if (item.name.length > 35) name += "...";

                let description = item.description?.substring(0, 50);
                if (item.description.length > 50) description += "...";

                const cells: Cell[] = [
                    { text: name, align: "inherit" },
                    { text: description, align: "inherit" },
                ];

                rows.push({
                    id: item.id,
                    cells: cells,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setDisplayModel(model);
        }

        hideProgressSpinner();
    }, [biddingCalculations])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, biddingCalculations?.pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const onBiddingCalculationAdd = () => {
        history.push('/BiddingCalculationsAdd');
    }

    const onBiddingCalculationEdit = (id: any) => {
        history.push(`/BiddingCalculationsEdit/${id}`);
    }

    const onBiddingCalculationDelete = (id: any) => {
        showDeleteModal(true, id);
    }

    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting Bidding Calculation..." });
        handleDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Bidding calculation deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the bidding calculation")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    }

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateAvailableFilters = [

    ]

    return <div>
        <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
                Bidding Calculations
            </div>
        </div>
        <div className="table-responsive-md">
            <NestedAccordionGrid
                selectedFilters={selectedFilters}
                dataModel={displayModel}
                onDelete={onBiddingCalculationDelete}
                onEdit={onBiddingCalculationEdit}
                onAdd={onBiddingCalculationAdd}
                pagination={biddingCalculations?.pagination}
                onFiltersChange={handleFiltersChange}
                availableFilters={generateAvailableFilters}
                onHandleChangePage={handlePageChange}
                loading={loading}
            />
        </div>
        {deleteModal.open && <ConfirmationDialog open={deleteModal.open} payload={deleteModal.id} okButtonText="Ok" cancelButtonText="Cancel" description={"Are you sure you want to delete this bidding calculation?"} title={"Delete Bidding Calculation"} onCancel={onDeleteModalClosed} onOk={onDeleteModalOk} />}
    </div>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleFetch_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleFetchBiddingCalculationsWithPagination(filters, pagination, onCompletedCallback)),
        handleDelete_d: (id: string, onCompletedCallback: (result: any) => void) => dispatch(handleDeleteBiddingCalculation(id, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(BiddingCalculations);
