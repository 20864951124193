import React, { FC, memo } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    CircularProgress,
    Tooltip
} from '@mui/material';
import { FaFileCsv } from "react-icons/fa";
import LoadingButton from "@mui/lab/LoadingButton";

interface GetAuctionCatalogueCSVProps {
    handleRetrieveAuctionCatalogReport: () => void;
    isLoading: boolean;
}

const GetAuctionCatalogueCSV: FC<GetAuctionCatalogueCSVProps> = ({ handleRetrieveAuctionCatalogReport, isLoading }) => {
    const { settings } = useSelector((state: any) => state.settings);

    return (
        <Tooltip title={"Download the " + settings.AuctionName + " Overview in CSV"}>
            <LoadingButton
                onClick={handleRetrieveAuctionCatalogReport}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"
                loading={isLoading}
            >
                <FaFileCsv />
            </LoadingButton>
        </Tooltip>
    )
}

export default memo(GetAuctionCatalogueCSV)