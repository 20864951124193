import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

export type IconButtonProps = {
    onClick: () => void;
    toolTip: string;
    icon: React.ReactNode;
    type: "primary" | "secondary" | "success" | "danger" | "default";
}

function IconButton({
    onClick,
    toolTip,
    icon,
    type
}: IconButtonProps) {
    const { settings } = useSelector((state: any) => state.settings);
    return (<Tooltip title={toolTip}>
        <Button onClick={onClick} variant="text" className={`btn-outline-${type} d-flex align-items-center justify-content-center d-40 p-0 rounded-pill text-capitalize`}>
            {icon}
        </Button>
    </Tooltip>)
}

export default IconButton;