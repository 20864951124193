import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import {
    clearApiResponses,
    handleRetrievePostAuctionLot,
    handleRetrieveAuctionHistoryReport,
    clearLots,
    handleRegisteredProfileFetch,
    clearUsers,
    retrieveLotsOverviewDataWithPaginationSuccess
} from '@/pages/AuctionManagement/store/AuctionManagement';
import { FaFileCsv, FaFilePdf, FaRedo } from "react-icons/fa";
import ns from '@/helpers/NotificationService';
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, CustomData } from '@/components/NestedAccordionGrid'
import HistoryGrid from '@/pages/AuctionManagement/HistoryGrid'
import { Button, Tooltip } from '@mui/material';
import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { FileExportType } from '@/helpers/FileExportTypeEnum';
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import MarkAsSold from "@/components/MarkAsSold/MarkAsSold";
import MarkAsUnsold from "@/components/MarkAsUnsold/MarkAsUnsold";
import { makeStyles } from "tss-react/mui";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFetchPaginatedLotsOverview } from "prembid-shared-library-npm/networking";
import { Pagination } from "prembid-shared-library-npm/types";
import MarkNonSTCLotsAsSold from "./sub-components/MarkNonSTCLotsAsSold";
import {
    useMarkNonSTCLotsWithReserveMetAsSold
} from "prembid-shared-library-npm/networking";
const useStyles = makeStyles()((theme) => ({}))

interface BidHistoryGridProps {
    clearResponses_d: () => void,
    handleRetrievePostAuctionLot_d: (auctionId: string) => void,
    handleRetrieveAuctionHistoryReport_d: (auctionId: string, exportType: FileExportType, onCompletedCallback: (response: any) => void) => void,
    handleRegisteredProfileFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    clearUsers_d: () => void,
    error: boolean,
    clearLots_d: () => void,
    errorText: string,
    success: any,
    users: any,
    loading: boolean,
    settings: any,
    auction: any,
}

const BidHistoryGrid: React.FC<BidHistoryGridProps> = props => {

    const {
        handleRetrieveAuctionHistoryReport_d,
        handleRegisteredProfileFetch_d,
        clearLots_d,
        users,
        loading,
        settings,
        auction,
        clearUsers_d
    } = props;

    const { cx } = useStyles();

    const [lotsDisplayModel, setLotsDisplayModel] = React.useState<NestedGridDataModel>()

    const dispatch = useDispatch();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingLots, setLoadingLots] = useState<boolean>(true);
    const [clearSearch, setClearSearch] = useState(false);
    const [pagination, setPagination] = useState<Pagination>();


    const { data: { list: lots = [], pagination: bidHistoryPagination } = {},
        refetch: refetchLotsData,
        isFetching,
        invalidateLotsOverview
    } = useFetchPaginatedLotsOverview({
        auctionId: auction.id,
        pagination: pagination ?? { currentPage: 1 },
        ...selectedFilters,
    }, {
        onSuccess(data) {
            dispatch(retrieveLotsOverviewDataWithPaginationSuccess(data, "bidHistory"))
        }
    });

    const { isLoading: markNonSTCLotsAsSoldIsLoading, mutate: markNonSTCLotsAsSold } = useMarkNonSTCLotsWithReserveMetAsSold();

    useEffect(() => {
        return (() => {
            clearUsers_d();
            clearLots_d();
        });
    }, [])

    useEffect(() => {
        setLoadingLots(isFetching);
    }, [isFetching])

    useEffect(() => {
        if (auction) {
            setPagination(undefined);
            handleRegisteredProfileFetch_d(auction.id);
            const newFilters = selectedFilters;
            setSelectedFilters(newFilters);
            setClearSearch(true);
            setTimeout(() => setClearSearch(false), 100);
        }
    }, [auction])

    React.useEffect(() => {
        if (lots) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];
            let cells: Cell[];
            let canMarkItemSold = false;

            if (auction) {
                if (Number(auction.auctionType) === Number(AuctionTypeEnum.Tender.toString())) {
                    headers = [
                        { title: "Number", align: "inherit" },
                        { title: "Image", align: "inherit", canSort: false },
                        { title: "Top Tender Name", align: "inherit", canFilter: true },
                        { title: "Top Tender Contact", align: "inherit", canFilter: true },
                        { title: "Top Tender", align: "inherit" },
                        { title: "Paddle Number", align: "inherit", canFilter: true },
                        { title: "SKU Number", align: "inherit" },
                        { title: "Reserve", align: "inherit" },
                        { title: "Reserve Met", align: "inherit" },
                    ];
                } else {
                    headers = [
                        { title: "Number", align: "inherit" },
                        { title: "Image", align: "inherit" },
                        { title: "Top Bidder Name", align: "inherit", canFilter: true },
                        { title: "Top Bidder Contact", align: "inherit", canFilter: true },
                        { title: "Top Bid", align: "inherit" },
                        { title: "Paddle Number", align: "inherit", canFilter: true },
                        { title: "SKU Number", align: "inherit" },
                        { title: "Reserve", align: "inherit" },
                        { title: "Reserve Met", align: "inherit" },
                    ];
                }

                lots.forEach((lot) => {
                    canMarkItemSold = false;
                    const customData: CustomData[] = []
                    let bidderName = ""
                    let bidderEmail = ""

                    if (users) {
                        const profile = users.find(u => u.id === lot.winningRegisteredProfileId);
                        if (profile) {
                            if (Number(auction.auctionType) === Number(AuctionTypeEnum.Tender.toString())) {
                                if (profile.profileType === "CompanyProfile") {
                                    bidderName = [profile.companyProfileFirstName, profile.companyProfileLastName].join(' ')
                                    bidderEmail = profile.companyProfileEmail
                                    customData.push({
                                        title: "Top Tender: Company", values: [
                                            { key: "Company Name", value: profile.companyProfileCompanyName },
                                            { key: "Trading Name", value: profile.companyProfileTradingName },
                                            { key: "Registration Number", value: profile.companyProfileRegistrationNumber },
                                            { key: "VAT Number", value: profile.companyProfileVATNumber },
                                            { key: "Contact First Name", value: profile.companyProfileFirstName },
                                            { key: "Contact Last Name", value: profile.companyProfileLastName },
                                            { key: "Contact Email", value: profile.companyProfileEmail },
                                            { key: "Contact Phone Number", value: profile.companyProfilePhoneNumber }
                                        ]
                                    })
                                }
                                else if (profile.profileType === "IndividualProfile") {
                                    bidderName = [profile.individualProfileFirstName, profile.individualProfileLastName].join(' ')
                                    bidderEmail = profile.individualProfileEmail
                                    customData.push({
                                        title: "Top Tender: Individual", values: [
                                            { key: "First Name", value: profile.individualProfileFirstName },
                                            { key: "Last Name", value: profile.individualProfileLastName },
                                            { key: "Email", value: profile.individualProfileEmail },
                                            { key: "Phone Number", value: profile.individualProfilePhoneNumber }
                                        ]
                                    })
                                }
                            } else {
                                if (profile.profileType === "CompanyProfile") {
                                    bidderName = [profile.companyProfileFirstName, profile.companyProfileLastName].join(' ')
                                    bidderEmail = profile.companyProfileEmail
                                    customData.push({
                                        title: "Top Bidder: Company", values: [
                                            { key: "Company Name", value: profile.companyProfileCompanyName },
                                            { key: "Trading Name", value: profile.companyProfileTradingName },
                                            { key: "Registration Number", value: profile.companyProfileRegistrationNumber },
                                            { key: "VAT Number", value: profile.companyProfileVATNumber },
                                            { key: "Contact First Name", value: profile.companyProfileFirstName },
                                            { key: "Contact Last Name", value: profile.companyProfileLastName },
                                            { key: "Contact Email", value: profile.companyProfileEmail },
                                            { key: "Contact Phone Number", value: profile.companyProfilePhoneNumber }
                                        ]
                                    })
                                }
                                else if (profile.profileType === "IndividualProfile") {
                                    bidderName = [profile.individualProfileFirstName, profile.individualProfileLastName].join(' ')
                                    bidderEmail = profile.individualProfileEmail
                                    customData.push({
                                        title: "Top Bidder: Individual", values: [
                                            { key: "First Name", value: profile.individualProfileFirstName },
                                            { key: "Last Name", value: profile.individualProfileLastName },
                                            { key: "Email", value: profile.individualProfileEmail },
                                            { key: "Phone Number", value: profile.individualProfilePhoneNumber }
                                        ]
                                    })
                                }
                            }
                        }
                    }

                    cells = [
                        { text: lot.number, align: "inherit" },
                        { text: "", element: <Thumbnail imageUrl={lot.thumbnailUrl} />, align: "inherit" },
                        { text: bidderName, align: "inherit" },
                        { text: bidderEmail, align: "inherit" },
                        { text: lot.winningBid, align: "inherit", element: <CurrencyLabel value={lot.winningRegisteredProfileAmount} /> },
                        { text: lot.paddleNumber, align: "inherit" },
                        { text: lot.skuNumber, align: "inherit" },
                        { text: lot.reservePrice, align: "inherit", element: <CurrencyLabel value={lot.reservePrice} /> },
                        { text: reserveMet(Number(lot.reservePrice), Number(lot.winningRegisteredProfileAmount)), element: <ReserveBadge reserve={lot.reservePrice} topBid={lot.winningRegisteredProfileAmount} />, align: "inherit" },
                    ];

                    canMarkItemSold = true;
                    cells.push({
                        text: lot.sold, element: lot.sold ? <MarkAsUnsold refetchLotsData={async (options) => {
                            invalidateLotsOverview();
                        }} lot={lot} settings={settings} /> : <MarkAsSold refetchLotsData={async (options) => {
                            invalidateLotsOverview();
                        }} lot={lot} settings={settings} isInvoicingEnabled={auction?.defaultApplyInvoicing} />, align: "inherit"
                    });

                    customData.push({
                        title: "History", element: <HistoryGrid lot={lot} auction={auction} overrideBidCallback={() => {
                            invalidateLotsOverview();
                        }} />
                    });

                    rows.push({
                        id: lot.id,
                        cells: cells,
                        customData: customData,
                    });
                });

                if (lots[0]?.auctionClosed && canMarkItemSold) {
                    headers.push({ title: "Actions", align: "inherit" });
                }

                model = {
                    headers: headers,
                    rows: rows,
                    headerButtons: headerActionbuttons()
                };

                setLotsDisplayModel(model);
            }
        }

        // hideProgressSpinner();
    }, [lots, users, markNonSTCLotsAsSoldIsLoading])

    const handlePageChange = (pagination: any) => {
        setPagination(pagination);
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
    }

    const reportProgress = (successCount: number = 0, failedCount: number = 0, totalCount: number = 0) => {
        let message = `Processed ${successCount} of ${totalCount} successfully...`;
        if (failedCount) {
            message += `\nFailed to process ${failedCount} of ${totalCount}`;
        }

        showProgressSpinner({ description: `Processing...\n\nThis can take several minutes.\nPlease do not close or refresh your browser.\n\n${message}` });
    }

    const handleMarkNonSTCLotsAsSold = useCallback((auctionId: string, previousResponse?: any) => {
        reportProgress(previousResponse?.successCount, previousResponse?.failedCount, previousResponse?.totalProcessed);
        markNonSTCLotsAsSold({ auctionId, ...(previousResponse ?? {}) },
            {
                onSuccess(res: any) {
                    if (res?.hasMore) {
                        handleMarkNonSTCLotsAsSold(auctionId, res);
                        return;
                    }

                    if (Number(res?.failedCount > 0)) {
                        if (Number(res?.successCount > 0)) {
                            refetchLotsData();
                        }
                        ns.error(`Failed to process ${res?.failedCount} of ${res?.totalProcessed} ${settings.LotName}s that meet the criteria. Please try again`);
                    } else {
                        if (Number(res?.successCount > 0)) {
                            refetchLotsData();
                            ns.success(`Successully processed ${res?.successCount} of ${res?.totalProcessed} ${settings.LotName}s that meet the criteria`);
                        } else {
                            ns.information(`No ${settings.LotName}s meet the criteria`);
                        }
                    }
                    reportProgress(res?.successCount, res?.failedCount, res?.totalProcessed);
                    setTimeout(() => hideProgressSpinner(), 500);
                },
                onError() {
                    hideProgressSpinner();
                }
            }
        );
    }, [markNonSTCLotsAsSold, refetchLotsData, settings.LotName])


    const showMarkNonSTCLotsAsSoldConfirmDialog = useCallback(() => {
        const auctionId = auction?.id;

        if (!auctionId || (auctionId && auctionId.length === 0)) return;

        ns.showDialog({
            title: 'Confirm Mark As Sold',
            content: <div>
                <p className="mt-3">Are you sure you want to mark all Non-STC {settings.LotName}s, that have met the reserve, as sold?</p>
            </div>,
            onSubmit: () => {
                handleMarkNonSTCLotsAsSold(auctionId);
            },
            onCancel: () => { },
            locales: {
                submitText: 'Mark As Sold',
            }
        });
    }, [auction?.id, settings.LotName, handleMarkNonSTCLotsAsSold]);

    const headerActionbuttons = () => {
        return [
            <div className="mr-2">
                <MarkNonSTCLotsAsSold onClick={showMarkNonSTCLotsAsSoldConfirmDialog} settings={settings} />
            </div>,
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refetchLotsData()} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>,
            <GetPostAuctionLotPDF auctionId={auction.id} />,
            <GetPostAuctionLotCSV auctionId={auction.id} />
        ]
    }

    const reserveMet = (reserve: number, topBid: number) => {
        if (topBid >= reserve)
            return "Yes"

        return "No"
    }

    function ReserveBadge(props) {
        const reserve = reserveMet(Number(props.reserve), Number(props.topBid))
        switch (reserve) {
            case 'Yes':
                return <div className="badge badge-success badge-pill m-1 text-capitalize ">{reserve}</div>
            case 'No':
                return <div className="badge badge-danger badge-pill m-1 text-capitalize ">{reserve}</div>

            default:
                return <React.Fragment />
        }
    }

    function Thumbnail(props) {
        return <img style={{ objectFit: "cover", height: "80px", width: "80px" }} alt="..." src={props.imageUrl} />
    }

    function GetPostAuctionLotCSV(props) {
        const [isGeneratingCSVReport, setIsGeneratingCSVReport] = React.useState<boolean>(false)

        const getAuctionCsv = () => {
            setIsGeneratingCSVReport(true);
            handleRetrieveAuctionHistoryReport_d(props.auctionId, FileExportType.CSV, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingCSVReport(false);
            });
        };
        return (
            <React.Fragment>
                <Tooltip title={"Download the Post " + settings.AuctionName + " Information in CSV Format"}>
                    <LoadingButton loading={isGeneratingCSVReport} onClick={getAuctionCsv} variant="text" className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                        <FaFileCsv />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    function GetPostAuctionLotPDF(props) {
        const [isGeneratingPDFReport, setIsGeneratingPDFReport] = React.useState<boolean>(false)

        const getAuctionPdf = () => {
            setIsGeneratingPDFReport(true);
            handleRetrieveAuctionHistoryReport_d(props.auctionId, FileExportType.PDF, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingPDFReport(false);
            });
        };
        return (
            <React.Fragment>
                <Tooltip title={"Download the Post " + settings.AuctionName + " Information in PDF Format"}>
                    <LoadingButton loading={isGeneratingPDFReport} onClick={getAuctionPdf} variant="text" className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                        <FaFilePdf />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    return (
        <React.Fragment>
            {!loading && (<React.Fragment>
                <NestedAccordionGrid
                    selectedFilters={selectedFilters}
                    dataModel={lotsDisplayModel}
                    pagination={bidHistoryPagination}
                    onFiltersChange={handleFiltersChange}
                    availableFilters={[]}
                    onHandleChangePage={handlePageChange}
                    loading={loadingLots}
                    clearSearch={clearSearch}
                />
            </React.Fragment>)}
        </React.Fragment>
    )
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleRetrievePostAuctionLot_d: (auctionId: string) => dispatch(handleRetrievePostAuctionLot(auctionId)),
        handleRetrieveAuctionHistoryReport_d: (auctionId: string, exportType: FileExportType, onCompletedCallback: (response: any) => void) => dispatch(handleRetrieveAuctionHistoryReport(auctionId, exportType, onCompletedCallback)),
        clearLots_d: () => dispatch(clearLots()),
        clearUsers_d: () => dispatch(clearUsers()),
        handleRegisteredProfileFetch_d: (auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleRegisteredProfileFetch(auctionId, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
    error: state.auctionManagement.error,
    errorText: state.auctionManagement.errorText,
    success: state.auctionManagement.success,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    users: state.auctionManagement.users,
})

export default connect(mapStateToProps, mapDispatchToProps)(BidHistoryGrid);
