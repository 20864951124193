import React from "react";
import { connect } from 'react-redux';
import {
    handleRetrieveLotBiddingHistory,
    handleRetrieveLotBidHistoryReport,
    handleOverrideWinningBidId,
    handleRetrieveDeletedBidAuditHistory
} from './store/AuctionManagement';
import {
    Button,
    Tooltip,
    Table,
    CircularProgress
} from '@mui/material';
import { zeroPad } from 'react-countdown'
import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import { FiTag, FiInfo } from "react-icons/fi";
import ns from '@/helpers/NotificationService';
import { FileExportType } from "@/helpers/FileExportTypeEnum";
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { AiOutlineInfo } from 'react-icons/ai';
import { orderBy } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
    handleRetrieveLotBiddingHistory_d: (lotId: string, auctionId: string, includeHistory: boolean, onCompletedCallback?: (res: any) => void) => void,
    handleRetrieveDeletedBidAuditHistory_d: (lotId: string, auctionId: string, onCompletedCallback?: (res: any) => void) => void,
    handleRetrieveLotBidHistoryReport_d: (auctionId: string, lotId: string, exportType: FileExportType, onCompletedCallback: (res: any) => void) => void,
    handleOverrideWinningBidId_d: (lotId: string, bidId: string, onCompletedCallback: (res: any) => void) => void,
    lot: any,
    users: any,
    auction: any,
    overrideBidCallback: () => void,
}

interface BidRow {
    id: string,
    name: string,
    contact: string,
    paddleNumber: string,
    date: string,
    time: string,
    amount: number,
    auctionId: string,
    deleted: boolean,
    createdDateTime: Date,
    registeredProfileId: string | null,
}

const HistoryGrid: React.FC<Props> = props => {
    const {
        handleRetrieveLotBiddingHistory_d,
        handleRetrieveLotBidHistoryReport_d,
        handleRetrieveDeletedBidAuditHistory_d,
        handleOverrideWinningBidId_d,
        lot,
        users,
        auction,
        overrideBidCallback,
    } = props

    const [loading, setLoading] = React.useState<boolean>(false);
    const [history, setHistory] = React.useState<BidRow[]>([]);
    const [deletedBids, setDeletedBids] = React.useState<any[]>([]);
    const [bidHistory, setBidHistory] = React.useState<any[]>([]);

    React.useEffect(() => {
        setLoading(true);
        //TODO: Ticket 4357 - If needed set last property to true to include history
        handleRetrieveLotBiddingHistory_d(lot.id, lot.auctionId, false, res => {
            if (res.success) {
                if (res.response?.length > 0) setBidHistory(res.response)
            }
        });
        handleRetrieveDeletedBidAuditHistory_d(lot.id, lot.auctionId, res => {
            if (res.success) {
                if (res.response?.length > 0) setDeletedBids(res.response)

                setLoading(false);
            }
        });
    }, [])

    React.useEffect(() => {
        const historyRows: any[] = [];

        if (bidHistory && bidHistory.length > 0) {
            let profileIdlist = bidHistory.map((bid) => {
                return bid.registeredProfileId;
            });
            const onlyUnique = function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            const uniqueProfileIds = profileIdlist.filter(onlyUnique)
            if (users) {
                const profiles = users.filter(user => uniqueProfileIds.includes(user.id))
                const containsBidsForLotId = bidHistory.filter(b => b.lotId === lot.id)[0]
                if (containsBidsForLotId) {
                    bidHistory.forEach((bid) => {
                        const profile = profiles.filter(p => p.id === bid.registeredProfileId)[0]
                        const paddleNumber = profile ? profile.paddleNumber : "Paddle not found"
                        const createdDateTime = new Date(bid.createdAt)
                        const dateString = createdDateTime.getFullYear() + "-" + zeroPad((createdDateTime.getMonth() + 1)) + "-" + zeroPad(createdDateTime.getDate())
                        const timeString = zeroPad(createdDateTime.getHours()) + ":" + zeroPad(createdDateTime.getMinutes()) + ":" + zeroPad(createdDateTime.getSeconds())

                        let bidderName = ""
                        let bidderEmail = ""
                        if (profile) {
                            if (profile.profileType === "CompanyProfile") {
                                bidderName = [profile.companyProfileFirstName, profile.companyProfileLastName].join(' ')
                                bidderEmail = profile.companyProfileEmail
                            }
                            else if (profile.profileType === "IndividualProfile") {
                                bidderName = [profile.individualProfileFirstName, profile.individualProfileLastName].join(' ')
                                bidderEmail = profile.individualProfileEmail
                            }
                        }

                        historyRows.push({
                            id: bid.id,
                            name: bidderName,
                            contact: bidderEmail,
                            paddleNumber: paddleNumber,
                            date: dateString,
                            time: timeString,
                            amount: Number(bid.amount),
                            auctionId: bid.auctionId,
                            deleted: false,
                            createdDateTime,
                            registeredProfileId: bid.registeredProfileId
                        })
                    })
                }
            }

            if (deletedBids?.length > 0) {
                deletedBids.map((bid) => {
                    const createdDateTime = new Date(bid.date)
                    const dateString = createdDateTime.getFullYear() + "-" + zeroPad((createdDateTime.getMonth() + 1)) + "-" + zeroPad(createdDateTime.getDate())
                    const timeString = zeroPad(createdDateTime.getHours()) + ":" + zeroPad(createdDateTime.getMinutes()) + ":" + zeroPad(createdDateTime.getSeconds())

                    const newBid = {
                        id: uuidv4(),
                        name: bid.bidderName,
                        contact: bid.bidderContact,
                        paddleNumber: bid.paddleNumber,
                        date: dateString,
                        time: timeString,
                        amount: Number(bid.amount),
                        auctionId: bid.auctionId,
                        deleted: true,
                        createdDateTime
                    }

                    historyRows.push(newBid);
                });
            }

            if (historyRows && historyRows.length > 0) {
                const orderedList = orderBy(historyRows, ['createdDateTime', 'amount'], ['desc', 'desc'])
                setHistory(orderedList)
            }
        }
    }, [bidHistory, deletedBids])

    function GetLotBidHistoryCSV(props: any) {
        const [isGeneratingCSVReport, setIsGeneratingCSVReport] = React.useState<boolean>(false)

        const getHistoryPdf = () => {
            setIsGeneratingCSVReport(true);
            handleRetrieveLotBidHistoryReport_d(props.lot.auctionId, props.lot.id, FileExportType.CSV, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingCSVReport(false);
            });
        };
        return (
            <React.Fragment>
                <Tooltip
                    title={Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Download the tender history in CSV format" : "Download the bid history in CSV format"}>
                    <LoadingButton onClick={getHistoryPdf} loading={isGeneratingCSVReport} variant="text" className="btn-outline-primary d-40 rounded-pill ml-2 mb-2">
                        <FaFileCsv />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    function GetLotBidHistoryPDF(props: any) {
        const [isGeneratingPDFReport, setIsGeneratingPDFReport] = React.useState<boolean>(false)

        const getAuctionPdf = () => {
            setIsGeneratingPDFReport(true);
            handleRetrieveLotBidHistoryReport_d(props.lot.auctionId, props.lot.id, FileExportType.PDF, (response) => {
                if (!response?.success) {
                    ns.error(response?.error || "The file could not be downloaded");
                }
                setIsGeneratingPDFReport(false);
            });
        };
        return (
            <React.Fragment>
                <Tooltip
                    title={Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Download the tender history in PDF format" : "Download the bid history in PDF format"}>
                    <LoadingButton onClick={getAuctionPdf} loading={isGeneratingPDFReport} variant="text" className="btn-outline-primary d-40 rounded-pill ml-2 mb-2">
                        <FaFilePdf />
                    </LoadingButton>
                </Tooltip>
            </React.Fragment>)
    }

    function OverrideWinningBid(props: any) {
        const onClick = () => {
            ns.showDialog({
                title: Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? 'Confirm Winning Tender Override' : 'Confirm Winning Bid Override',
                content: <div>
                    <p>{Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Are you sure you want to change the winning tender to?" : "Are you sure you want to change the winning bid to?"}</p>
                    <p>
                        Contact Name: {props.bid.name}
                        <br />
                        <span style={{ float: 'left' }}>Amount:&nbsp;</span><CurrencyLabel value={props.bid.amount} style={{ float: 'left' }} />
                    </p>
                </div>,
                onSubmit: () => {
                    showProgressSpinner({ description: Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Updated the winning tender..." : "Updated the winning bid..." })
                    handleOverrideWinningBidId_d(props.lot.id, props.bid.id, (res) => {
                        if (res.success) {
                            ns.success(Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Updated the winning tender successfully" : "Updated the winning bid successfully");
                            overrideBidCallback();
                        }
                        hideProgressSpinner();
                    })
                },
                onCancel: () => { },
                locales: {
                    submitText: 'Override'
                }
            });
        };

        return (
            <React.Fragment>
                {((props.idx !== 0 && !props.lot.overrideWinningBidId) || (props.lot.overrideWinningBidId && props.lot.overrideWinningBidId !== props.bid.id)) && !props.lot.sold &&
                    <Tooltip title={Number(props.auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Mark this tender as the winning tender" : "Mark this bid as the winning bid"}>
                        <Button onClick={onClick} variant="text" className="btn-outline-primary align-items-center justify-content-center d-40 rounded-pill ml-2">
                            <FiTag />
                        </Button>
                    </Tooltip>}
            </React.Fragment>)
    }

    const renderHistoricBidButton = () => <Tooltip title={"This bid was placed on a previous auction"}>
        <Button variant="text" className="btn-outline-light align-items-center justify-content-center d-40 rounded-pill ml-2">
            <AiOutlineInfo size={25} />
        </Button>
    </Tooltip>

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <GetLotBidHistoryPDF lot={lot} auction={auction} /><GetLotBidHistoryCSV lot={lot} auction={auction} />
            </div>

            <div style={{ display: 'block', width: '100%', height: '300px', overflowY: 'scroll', marginTop: '5px' }}>
                <Table className={'table table-bordered'} style={{ tableLayout: 'fixed', width: '100%' }}>
                    <thead className="thead-light">
                        <tr>
                            <th colSpan={2}>Name</th>
                            <th colSpan={2}>Contact</th>
                            <th colSpan={1}>Paddle number</th>
                            <th colSpan={1}>Date</th>
                            <th colSpan={1}>Time Stamp</th>
                            <th colSpan={2} style={{ textAlign: "right" }}>Amount</th>
                            <th colSpan={1}>Actions</th>
                        </tr>
                    </thead>

                    {loading && (
                        <tbody
                            style={{
                                position: "absolute",
                                top: "250px",
                                left: "calc(50% - 50px)",
                            }}>
                            <tr>
                                <td style={{ border: "none" }}>
                                    <div className="primaryColor">
                                        <CircularProgress color="inherit" size={100} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}

                    <tbody style={{ opacity: loading ? 0.5 : 1 }}>
                        {history.map((bid, idx) => {
                            if (bid.auctionId !== auction?.id)
                                return (
                                    <tr className={"bg-secondary-disabled " + bid.deleted ? "bid-deleted" : ""} key={idx}>
                                        <td colSpan={2} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.name}</td>
                                        <td colSpan={2} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.contact}</td>
                                        <td colSpan={1} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.paddleNumber}</td>
                                        <td colSpan={1} style={{ color: "white" }}>{bid.date}</td>
                                        <td colSpan={1} style={{ color: "white" }}>{bid.time}</td>
                                        <td colSpan={2} style={{ textAlign: "right", color: "white" }}> <CurrencyLabel value={bid.amount} /></td>
                                        <td colSpan={1}>{!bid.deleted && renderHistoricBidButton()}</td>
                                    </tr>
                                )

                            if ((!bid.deleted && lot.winningRegisteredProfileId === bid.registeredProfileId && lot.winningRegisteredProfileAmount === bid.amount && !lot.overrideWinningBidId) || lot.overrideWinningBidId === bid.id) {
                                return (
                                    <tr className={"bg-primary"} key={idx}>
                                        <td colSpan={2} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.name}</td>
                                        <td colSpan={2} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.contact}</td>
                                        <td colSpan={1} style={{ color: "white", overflowWrap: 'anywhere' }}>{bid.paddleNumber}</td>
                                        <td colSpan={1} style={{ color: "white" }}>{bid.date}</td>
                                        <td colSpan={1} style={{ color: "white" }}>{bid.time}</td>
                                        <td colSpan={2} style={{ textAlign: "right", color: "white" }}> <CurrencyLabel value={bid.amount} /></td>
                                        <td colSpan={1}><OverrideWinningBid bid={bid} lot={lot} idx={idx} auction={auction} /></td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={idx} className={bid.deleted ? "bid-deleted" : ""}>
                                        <td colSpan={2} style={{ overflowWrap: 'anywhere' }}>{bid.name}</td>
                                        <td colSpan={2} style={{ overflowWrap: 'anywhere' }}>{bid.contact}</td>
                                        <td colSpan={1} style={{ overflowWrap: 'anywhere' }}>{bid.paddleNumber}</td>
                                        <td colSpan={1} style={{ overflowWrap: 'anywhere' }}>{bid.date}</td>
                                        <td colSpan={1} style={{ overflowWrap: 'anywhere' }}>{bid.time}</td>
                                        <td colSpan={2} style={{ textAlign: "right" }}> <CurrencyLabel value={bid.amount} /></td>
                                        <td colSpan={1}>{!bid.deleted && <OverrideWinningBid bid={bid} lot={lot} idx={idx} auction={auction} />}</td>
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </Table>
            </div>
        </div>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRetrieveLotBiddingHistory_d: (lotId: string, auctionId: string, includeHistory: boolean, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveLotBiddingHistory(lotId, auctionId, includeHistory, onCompletedCallback)),
        handleRetrieveDeletedBidAuditHistory_d: (lotId: string, auctionId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveDeletedBidAuditHistory(lotId, auctionId, onCompletedCallback)),
        handleRetrieveLotBidHistoryReport_d: (auctionId: string, lotId: string, exportType: FileExportType, onCompletedCallback: (res: any) => void) => dispatch(handleRetrieveLotBidHistoryReport(auctionId, lotId, exportType, onCompletedCallback)),
        handleOverrideWinningBidId_d: (lotId: string, bidId: string, onCompletedCallback: (res: any) => void) => dispatch(handleOverrideWinningBidId(lotId, bidId, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
    bidHistory: state.auctionManagement.bidHistory,
    users: state.auctionManagement.users
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryGrid);