import React, { useCallback, useEffect, useState } from "react";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearApiResponses, handleAuctionsFetch, handleAuctionDelete, handleAuctionsFetchWithPagination } from './store/Auctions';
import { handleIndividualLotsUnlinkAllFromAuction } from '@/pages/Lots/store/Lots';
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import {
    Collapse,
    IconButton,
    Button,
    Tooltip
} from '@mui/material';
import { FaList, FaRedo } from "react-icons/fa";
import {
    Alert
} from '@mui/lab';
import { MdClose } from "react-icons/md";
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import {
    NestedAccordionGrid,
    Cell,
    Header,
    Row,
    NestedGridDataModel
} from '@/components/NestedAccordionGrid';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { AuctionStatusEnum } from '@/helpers/AuctionStatusEnum';
import ns from '@/helpers/NotificationService';
import {
    clearDocuments
} from "@/pages/Documents/store/Documents";
import isNumber from 'lodash'
import { makeStyles } from "tss-react/mui";
import { formatDateTime } from "../../helpers/Utils";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleAuctionsFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleAuctionDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => void,
    handleIndividualLotsUnlinkAllFromAuction_d: (auctionId: string, onCompletedCallback?: (result: any) => void) => void,
    clearResponses_d: () => void,
    clearDocuments_d: () => void,
    error: boolean,
    errorText: string,
    reload: boolean,
    auctions: any,
    loading: boolean,
    settings: any,
    pagination: any
    //userTermsAcceptance: any,
}

const Auctions: React.FC<Props> = props => {

    const {
        handleAuctionsFetchWithPagination_d,
        handleIndividualLotsUnlinkAllFromAuction_d,
        handleAuctionDelete_d,
        clearResponses_d,
        clearDocuments_d,
        error,
        errorText,
        reload,
        auctions,
        loading,
        settings,
        pagination
    } = props;
    const { classes, cx } = useStyles();
    const [auctionDisplayModel, setAuctionDisplayModel] = React.useState<NestedGridDataModel>()
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    })

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingAuctions, setLoadingAuctions] = useState<boolean>(true);

    useEffect(() => {
        refreshList(true);
    }, [reload])

    useEffect(() => {
        clearDocuments_d();
    }, [])

    useEffect(() => {
        if (auctions) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Type", align: "inherit" },
                { title: "Start Time", align: "inherit" },
                { title: "End Time", align: "inherit" },
                { title: "Active", align: "inherit", canFilter: true },
                { title: "Status", align: "inherit", canFilter: true }
            ];

            auctions.forEach((auction) => {

                let auctionName = auction.name?.substring(0, 35);
                if (auction.name.length > 35) auctionName += "...";

                const cells: Cell[] = [
                    { text: auctionName, align: "inherit" },
                    { text: AuctionTypeEnum[auction.auctionType], align: "inherit" },
                    { text: formatDateTime(auction.startDateTimeAt, 'yyyy/MM/dd HH:mm'), align: "inherit" },
                    { text: formatDateTime(auction.endDateTimeAt, 'yyyy/MM/dd HH:mm'), align: "inherit" },
                    { text: auction.isActive ? 'Active' : 'Inactive', align: "inherit", element: statusBadge(auction.isActive) },
                    { text: auctionStatus(auction), align: "inherit", element: auctionStatusBadge(auction) },
                ];

                rows.push({
                    id: auction.id,
                    cells: cells,
                    actionButtons: actions(auction.id)
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setAuctionDisplayModel(model);
        }

        var waitForGridRender = new Promise(r => setTimeout(r, 500));
        waitForGridRender.then(() => { hideProgressSpinner(); });
    }, [auctions])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(false, selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const history = useHistory();

    const refreshList = useCallback((showProgress: boolean, filters?: any, pagination?: any) => {
        if (showProgress) showProgressSpinner({ description: "Retrieving " + settings.AuctionName + "s..." });
        else setLoadingAuctions(true);
        handleAuctionsFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingAuctions(false);
        });
    }, [handleAuctionsFetchWithPagination_d, settings.AuctionName])

    const actions = (id: string) => {
        return [
            <Tooltip arrow title={"Go to " + settings.LotName + "s"}>
                <Button onClick={() => navAddEdit(id, 3)} size="small" className="text-white btn-neutral-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2B2B2B' }}>
                    <FaList />
                </Button>
            </Tooltip>
        ]
    }


    const navAddEdit = useCallback((auctionId: string, tabIndex: number) => {
        let path = '/AuctionEdit'
        if (auctionId !== '') {
            path += '?auctionId=' + auctionId
            if (tabIndex !== undefined && tabIndex !== null)
                path += '&tabIndex=' + tabIndex

            history.push(path);
        } else if (tabIndex !== undefined && tabIndex !== null) {
            path += '?tabIndex=' + tabIndex
            history.push(path);
        }
    }, [history])

    const showDeleteModal = useCallback((open: boolean, id: string) => {
        setDeleteModal((prevState) => ({ ...prevState, open: open, id: id }));
    }, [])

    const onDelete = useCallback((id: any) => {
        showDeleteModal(true, id);
    }, [showDeleteModal])

    const onEdit = useCallback((id: string) => {
        navAddEdit(id, 0);
    }, [navAddEdit]);

    const onAdd = useCallback(() => { navAddEdit('', 0) }, [navAddEdit])

    //Modal
    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalAdditional = (id: string) => {
        showProgressSpinner({ description: "Unlinking " + settings.LotName + "s..." });
        handleIndividualLotsUnlinkAllFromAuction_d(id, (response) => {
            if (response.success) {
                onDeleteModalOk(id);
            }
            else {
                ns.error("Failed to unlink the " + settings.LotName + "s")
                hideProgressSpinner();
                showDeleteModal(false, "");
            }
        });
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting " + settings.AuctionName + "..." });
        handleAuctionDelete_d(id, (response) => {
            if (response.success) {
                ns.success(settings.AuctionName + " deleted successfully");
                refreshList(false);
            }
            else {
                ns.error("Failed to delete the " + settings.AuctionName)
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const statusBadge = (active: boolean) => {
        switch (active) {
            case true: {
                return <div className="badge badge-success badge-pill text-capitalize ">Yes</div>
            }
            case false: {
                return <div className="badge badge-danger badge-pill text-capitalize ">No</div>
            }
        }
    }

    const auctionStatusBadge = (props: any) => {
        if (!!props.isActive === false) return <div className="badge badge-dark badge-pill text-capitalize ">Unavailable</div>

        const findMe = Object.keys(AuctionStatusEnum)[Object.values(AuctionStatusEnum).indexOf(props.auctionStatus)];

        if (findMe == Object.keys(AuctionStatusEnum)[Object.values(AuctionStatusEnum).indexOf(AuctionStatusEnum.Open)]) {
            return <div className="badge badge-success badge-pill text-capitalize ">{findMe}</div>
        } else if (findMe == Object.keys(AuctionStatusEnum)[Object.values(AuctionStatusEnum).indexOf(AuctionStatusEnum.Awaiting)]) {
            return <div className="badge badge-dark badge-pill text-capitalize ">{findMe}</div>
        } else if (findMe == Object.keys(AuctionStatusEnum)[Object.values(AuctionStatusEnum).indexOf(AuctionStatusEnum.Closed)]) {
            return <div className="badge badge-danger badge-pill text-capitalize ">{findMe}</div>
        }
    }

    const auctionStatus = (props: any) => {
        if (!!props?.isActive === false) return "Unavailable";
        return Object.keys(AuctionStatusEnum)[Object.values(AuctionStatusEnum).indexOf(props.auctionStatus)];
    }

    const handlePageChange = useCallback((pagination: any) => {
        refreshList(false, selectedFilters, pagination)
    }, [refreshList, selectedFilters])

    const handleFiltersChange = useCallback((filters: any) => {
        setSelectedFilters(filters);
        refreshList(false, filters, null);
    }, [refreshList])

    const generateAvailableFilters = [
        {
            description: `Active`,
            propertyMap: 'isActive',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        },
        {
            description: 'Status',
            propertyMap: 'status',
            type: 'string',
            options: Object.keys(AuctionStatusEnum).filter((item) => {
                return isNaN(parseInt(item));
            }).map(item => {
                return {
                    label: item.toString(),
                    value: item.toString(),
                }
            })
        },
        {
            description: 'Type',
            propertyMap: 'type',
            type: 'string',
            options: Object.keys(AuctionTypeEnum).filter((item) => {
                return isNaN(parseInt(item));
            }).map(item => {
                return {
                    label: item.toString(),
                    value: item.toString(),
                }
            })
        }
    ]

    return (
        <div>
            {!loading && (<>
                <div className="card-header py-3">
                    <div className="card-header--title font-size-lg">
                        {settings.AuctionName + "s/Create " + props.settings.AuctionName}
                    </div>
                </div>
                <div className="table-responsive-md">
                    <NestedAccordionGrid
                        selectedFilters={selectedFilters}
                        dataModel={auctionDisplayModel}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onAdd={onAdd}
                        pagination={pagination}
                        onFiltersChange={handleFiltersChange}
                        availableFilters={generateAvailableFilters}
                        onHandleChangePage={handlePageChange}
                        loading={loadingAuctions}
                    />
                </div>
                {deleteModal.open && <ConfirmationDialog open={deleteModal.open} payload={deleteModal.id} okButtonText="Delete All" additionalButtonText="Unlink and Delete" cancelButtonText="Cancel" description={"Please note you are about to delete this " + settings.AuctionName + ", by default we delete all the related " + settings.LotName + "s off your " + settings.LotName + "s section. Please click on 'Delete All' to proceed, or click 'Unlink and Delete' to keep your saved " + settings.LotName + "s in the " + settings.LotName + "s section and only delete the " + settings.AuctionName + "."} title={"Delete " + settings.AuctionName} onCancel={onDeleteModalClosed} onOk={onDeleteModalOk} onAdditionalButton={onDeleteModalAdditional} />}
            </>)}

            {error && (
                <Collapse in={error}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    clearResponses_d()
                                }}
                            >
                                <MdClose fontSize="inherit" />
                            </IconButton>
                        }
                        className="mb-4" severity="error">
                        {errorText}
                    </Alert>
                </Collapse>
            )}
        </div>
    )
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleAuctionsFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleAuctionsFetchWithPagination(filters, pagination, onCompletedCallback)),
        handleAuctionDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleAuctionDelete(Id, onCompletedCallback)),
        handleIndividualLotsUnlinkAllFromAuction_d: (auctionId: string, onCompletedCallback?: (result: any) => void) => dispatch(handleIndividualLotsUnlinkAllFromAuction(auctionId, onCompletedCallback)),
        clearDocuments_d: () => dispatch(clearDocuments()),
    }
}

const mapStateToProps = (state: any) => ({
    error: state.auctions.error,
    errorText: state.auctions.errorText,
    reload: state.auctions.reload,
    auctions: state.auctions.auctions,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    pagination: state.auctions.pagination,
})

export default connect(mapStateToProps, mapDispatchToProps)(Auctions);