import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const UPDATE_VENUE_SUCCESS = 'UPDATE_VENUE_SUCCESS';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const SAVE_ERROR = 'SAVE_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const VENUES_FETCH_SUCCESS = 'VENUES_FETCH_SUCCESS';
export const VENUES_FETCH_WITH_PAGINATION_SUCCESS = 'VENUES_FETCH_WITH_PAGINATION_SUCCESS';
export const VENUE_FETCH_SUCCESS = 'VENUE_FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_VENUE = 'SET_VENUE';
export const CLEAR_VENUE = 'CLEAR_VENUE';
export const REVERSE_GEO = 'REVERSE_GEO';
export const MAP_SEARCH = 'MAP_SEARCH';
export const CLEAR_REVERSE_GEO = 'CLEAR_REVERSE_GEO';
export const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
export const VENUE_LOTS = 'VENUE_LOTS';
export const NEW_VENUE_LOTS = 'NEW_VENUE_LOTS';
export const NEW_VENUE_AUCTION = 'NEW_VENUE_AUCTION';
export const RESET = 'RESET';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    venues: [],
    venue: undefined,
    venueTypeRequest: 'Add',
    venueId: '',
    venueLots: undefined,
    selectNewVenueLot: undefined,
    selectNewVenueAuction: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        //add/edit
        case SAVE_SUCCESS: return { ...state, success: true, venueTypeRequest: 'Edit', venueId: action.payload }
        case SAVE_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case UPDATE_VENUE_SUCCESS: return { ...state, error: false, errorText: false, success: true }
        //get
        case FETCH_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_ERROR: return { ...state, error: false, errorText: false, success: false }
        case DELETE_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, venueTypeRequest: 'Add' }
        case SET_VENUE: return { ...state, error: false, errorText: null, venue: action.payload, venueTypeRequest: 'Edit' }
        case VENUES_FETCH_SUCCESS: return { ...state, error: false, errorText: null, venues: action.payload, success: false, venueTypeRequest: 'Add', venueId: '', venue: undefined }
        case VENUES_FETCH_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: null, venues: action.payload.list, pagination: action.payload.pagination, success: false, venueTypeRequest: 'Add', venueId: '', venue: undefined }
        case VENUE_FETCH_SUCCESS: return { ...state, error: false, errorText: null, venue: action.payload, success: false, venueId: '', venueTypeRequest: 'Edit' }
        case REVERSE_GEO: return { ...state, reverseGeoResult: action.payload }
        case MAP_SEARCH: return { ...state, mapSearchResult: action.payload }
        //ui
        case CLEAR_VENUE: return { ...state, venue: undefined, reverseGeoResult: undefined }
        case CLEAR_REVERSE_GEO: return { ...state, reverseGeoResult: undefined }
        case CLEAR_API_RESPONSES: return { ...state, success: false, venueId: '' }
        case VENUE_LOTS: return { ...state, venueLots: action.payload }
        case NEW_VENUE_LOTS: return { ...state, selectNewVenueLot: action.payload }
        case NEW_VENUE_AUCTION: return { ...state, selectNewVenueAuction: action.payload }
        case RESET: return { ...initialState }
        default: return state;
    }
}

//Action creator
export const saveSuccess = (id: string) => {
    return { type: SAVE_SUCCESS, payload: id }
}

export const saveError = (errorText: string) => {
    return { type: SAVE_ERROR, payload: errorText }
}

export const error = (errorText: string) => {
    return { type: FETCH_ERROR, payload: errorText }
}

export const clearError = () => {
    return { type: CLEAR_ERROR, payload: CLEAR_ERROR }
}

export const deleteSuccess = (success: string) => {
    return { type: DELETE_SUCCESS, payload: success }
}

export const venuesFetchSuccess = (venues: any) => {
    return { type: VENUES_FETCH_SUCCESS, payload: venues }
}

export const venuesFetchWithPaginationSuccess = (venues: any) => {
    return { type: VENUES_FETCH_WITH_PAGINATION_SUCCESS, payload: venues, venueTotal: 0 }
}

export const venueFetchSuccess = (venue: any) => {
    return { type: VENUE_FETCH_SUCCESS, payload: venue }
}

export const setVenue = (venue: any) => {
    return { type: SET_VENUE, payload: venue }
}

export const venueUpdateSuccess = () => {
    return { type: UPDATE_VENUE_SUCCESS, payload: UPDATE_VENUE_SUCCESS }
}

export const clearVenue = () => {
    return { type: CLEAR_VENUE, payload: CLEAR_VENUE }
}

export const reset = () => {
    return { type: RESET }
}

export const mapSearch = (mapSearchResult: any) => {
    return { type: MAP_SEARCH, payload: mapSearchResult }
}

export const reverseGeo = (reverseGeoResult: any) => {
    return { type: REVERSE_GEO, payload: reverseGeoResult }
}

export const clearReverseGeo = () => {
    return { type: CLEAR_REVERSE_GEO, payload: CLEAR_REVERSE_GEO }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const venueLots = (venueLots) => {
    return { type: VENUE_LOTS, payload: venueLots }
}

export const newVenueLots = (selectNewVenueLot) => {
    return { type: NEW_VENUE_LOTS, payload: selectNewVenueLot }
}

export const newVenueAuction = (selectVenueAuction) => {
    return { type: NEW_VENUE_AUCTION, payload: selectVenueAuction }
}

//Thunks
export const handleCreate = (venueInfo: any, additionalData: any, onCompetedCallback?: (res: any) => void) => {

    let additionalDataObject = {};
    for (let row of [...additionalData]) {
        additionalDataObject[row.name] = row.value
    }

    const venueRequest = {
        name: venueInfo.name,
        description: venueInfo.description,
        isVisible: venueInfo.isVisible,
        dataCapturedDictionary: additionalDataObject,
        address: venueInfo.address
    };

    return (dispatch: any) => {
        return requestClient.post(apiPaths.venue.create, venueRequest)
            .then(function (resp) {
                if (resp.success) {
                    const id = resp.response;
                    dispatch(saveSuccess(id));
                }
                else {
                    dispatch(saveError(resp.error));
                }

                if (onCompetedCallback) onCompetedCallback(resp);
            });
    }
}

export const handleUpdate = (venueInfo: any, additionalData: any, onCompetedCallback?: (res: any) => void) => {

    let additionalDataObject = {}

    for (let row of [...additionalData]) {
        additionalDataObject[row.name] = row.value
    }

    let updateVenue = {
        name: venueInfo.name,
        id: venueInfo.id,
        description: venueInfo.description,
        isVisible: venueInfo.isVisible,
        dataCapturedDictionary: additionalDataObject,
        address: venueInfo.address
    }

    return function (dispatch: any) {
        return requestClient.post(apiPaths.venue.update, updateVenue)
            .then(function (res) {
                if (res.success) {
                    dispatch(venueUpdateSuccess())
                } else {
                    dispatch(error(res.error))
                }

                if (onCompetedCallback) onCompetedCallback(res);
            })
    }
}

export const handleDelete = (id: string, onCompletedCallback?: (result: any) => void) => {
    return (dispatch: any) => {
        let venueInfo = {
            Id: id,
        };
        return requestClient.post(apiPaths.venue.delete, venueInfo)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            });
    }
}

export const handleVenuesFetch = (onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.venue.retrieveAll, null, null)
            .then(function (res) {
                if (res.success) {
                    const venuesJson = res.response
                    let venues = [] as any;
                    for (let venue of venuesJson) {
                        let venueModel = venue;
                        venueModel["additionalData"] = [] as any;
                        for (const [key, value] of Object.entries(venue.dataCaptured)) {
                            venueModel.additionalData.push({
                                name: key,
                                value: value
                            });
                        }
                        venues.push(venueModel);
                    }
                    dispatch(venuesFetchSuccess(venues))

                    if (onCompletedCallback) onCompletedCallback(res);
                } else {
                    dispatch(error(res.error))
                    if (onCompletedCallback) onCompletedCallback(res);
                }
            })
    }
}

export const handleVenuesFetchWithPagination = (filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.venue.retrieveAllWithPagination, {
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                const venuesJson = res.response.list;
                let venues = [] as any;
                for (let venue of venuesJson) {
                    let venueModel = venue;
                    venueModel["additionalData"] = [] as any;
                    for (const [key, value] of Object.entries(venue.dataCaptured)) {
                        venueModel.additionalData.push({
                            name: key,
                            value: value
                        });
                    }
                    venues.push(venueModel);
                }

                res.response.list = venues;
                dispatch(venuesFetchWithPaginationSuccess(res.response))

                if (onCompletedCallback) onCompletedCallback(res);
            } else {
                dispatch(error(res.error))
                if (onCompletedCallback) onCompletedCallback(res);
            }
        })
    }
}

export const handleVenueFetch = (id: string) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.venue.retrieve, null, { venueId: id })
            .then(function (res) {
                if (res.success) {
                    const venue = res.response
                    let venueModel = venue;
                    venueModel["additionalData"] = [] as any;
                    for (const [key, value] of Object.entries(venue.dataCaptured)) {
                        venueModel.additionalData.push({
                            name: key,
                            value: value
                        });
                    }
                    dispatch(venueFetchSuccess(venue))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleReverseGeo = (lat: number, lng: number) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.mapping.reverseGeo, null, { lat: lat, lng: lng })
            .then(function (res) {
                if (res.success) {
                    dispatch(reverseGeo(res.response));
                } else {
                    dispatch(error(res.error))
                }
            });
    }
}

export const handleMapSearch = (searchQuery: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.mapping.search, null, { searchQuery: searchQuery })
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
                else
                    if (res.success) {
                        dispatch(mapSearch(res.response));
                    } else {
                        dispatch(error(res.error))
                    }
            });
    }
}