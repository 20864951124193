import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { handleFetchActions } from '@/pages/Integration/store/Integration';
import { Button, Card, CardContent, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { NestedAccordionGrid, Header, NestedGridDataModel, Row } from "@/components/NestedAccordionGrid";
import { FaRedo } from "react-icons/fa";
import { ActionPriority, ActionStatus } from '@/helpers/Integration';
import { MdInfo } from "react-icons/md";
import useHistory from '@/hooks/useHistory';
import { formatDateTime } from "../../helpers/Utils";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
    handleFetchActions_d: (onCompletedCallback: (res: any) => void) => void,
    loading: boolean,
    settings: any,
    integration: any
}

const ActionList: React.FC<Props> = (props) => {
    const {
        handleFetchActions_d,
        loading,
        settings,
        integration,
        integration: { actions }
    } = props;

    const history = useHistory();

    const [actionsModel, setActionsModel] = useState<NestedGridDataModel>();
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        reload();
    }, [])

    useEffect(() => {
        resetActions();
    }, [actions, reloading])

    const resetActions = () => {
        if (actions) renderActionsTable();
    }

    const reload = (withLoading = true) => {
        setReloading(true);
        if (withLoading) showProgressSpinner({ description: 'Loading actions...' });
        handleFetchActions_d((res) => {
            if (!res.success) {
                ns.error('Failed to load the actions');
            }
            hideProgressSpinner();
            setReloading(false);
        })
    }

    const renderActionsTable = () => {
        let headers: Header[] = [
            { title: "Type", align: "inherit" },
            { title: "Unique Reference", align: "inherit" },
            { title: "Priority", align: "inherit" },
            { title: "Status", align: "inherit" },
            { title: "Created Date", align: "inherit" },
        ];

        let rows: Row[] = actions.map(action => {
            return {
                id: action.id,
                cells: [
                    { text: action.type.replace('Lot', settings.LotName).replace('Auction', settings.AuctionName), align: "inherit" },
                    { text: action.uniqueReference, align: "inherit" },
                    { text: ActionPriority[action.priority], align: "inherit" },
                    {
                        text: ActionStatus[action.status], element: <div>{ActionStatus[action.status]} <Tooltip arrow title={<><ul style={{ padding: '10px 20px', margin: 0 }}>{action.summaries?.filter((x, index) => index < 5).map(x => <li>{x.description.replace(/Lot/gi, settings.LotName).replace(/Auction/gi, settings.AuctionName)}</li>)}</ul>{action.summaries?.length > 5 && <p style={{ paddingTop: 10, textAlign: 'center' }}>** Edit action to show additional summaries **</p>}</>} placement="top">
                            <IconButton style={{ padding: 0 }}>
                                <MdInfo size={20}
                                    color={settings.Styles.Primary}
                                    style={{ marginTop: -3 }}
                                />
                            </IconButton>
                        </Tooltip></div>, align: "inherit"
                    },
                    { text: formatDateTime(action.createdAt, 'yyyy/MM/dd HH:mm'), align: "inherit" },
                ]
            }
        });

        setActionsModel({
            headers: headers,
            rows: rows,
            headerButtons: [
                <Tooltip title="Refresh Table" placement="left">
                    <LoadingButton disabled={reloading} loading={reloading} onClick={() => reload(false)} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                        <FaRedo />
                    </LoadingButton>
                </Tooltip>
            ]
        });
    }

    const handleEditAction = (actionId: string) => {
        history.push(`/Action/${actionId}`)
    }

    return <Card className='card-transparent'>
        <CardContent className='pt-0'>
            <NestedAccordionGrid dataModel={actionsModel} onEdit={handleEditAction} showTopDivider={false} />
        </CardContent>
    </Card>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleFetchActions_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchActions(onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    integration: state.integration
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionList);