import React, {
    FC,
    memo,
    useCallback,
    useMemo,
    useReducer,
    useState,
    useEffect,
    useRef,
} from "react";
import { makeStyles } from 'tss-react/mui';
import { useSelector } from "react-redux";
import _, { isNumber } from "lodash";

import { Pagination } from "@/components/Pagination";
import { Pagination as PaginationPayload } from "prembid-shared-library-npm/types";

import MainTable from "./sub-components/MainTable";
import EnhancedTableHead from "./sub-components/EnhancedTableHead";
import NestedAccordionGridRow from "./sub-components/NestedAccordionGridRow";
import FiltersButton from "./sub-components/FiltersButton";
import FiltersModal from "./sub-components/FiltersModal";
import HeaderButtonList from "./sub-components/HeaderButtonList";

import {
    AvailableFilters,
    NestedGridDataModel,
    OnAddHandler,
    OnDeleteHandler,
    OnEditHandler,
    Order,
    Row,
    SelectedFilters,
    TableActions,
} from "./NestedAccordionGrid.types";
import SearchField from "@/components/SearchField/SearchField";
import { Grid, MenuItem, Select } from "@mui/material";

interface NestedAccordionGridContainerProps {
    dataModel?: NestedGridDataModel;
    onEdit?: OnEditHandler;
    onDelete?: OnDeleteHandler;
    onAdd?: OnAddHandler;
    hideSearch?: boolean;
    tableActions?: TableActions;
    payload?: any;
    pagination?: PaginationPayload;
    rowsPerPageOptions?: number[];
    onHandleChangePage?: (pagination: PaginationPayload) => void;
    availableFilters?: AvailableFilters;
    selectedFilters?: SelectedFilters;
    onFiltersChange?: (filters: any) => void;
    loading?: boolean;
    pageSizeIsFixed?: boolean; // remove the selector for page size by setting this to true
    lotSorting?: boolean;
    lotNoSearch?: boolean;
    clearSearch?: boolean; // clear the search fields when this is set to true
    showTopDivider?: boolean;
}

const useStyles = makeStyles()((theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const sortByOptions: string[] = ["Number: Ascending", "Number: Descending", "Opening Bid: Low to High", "Opening Bid: High to Low", "Time: Remaining"];

const NestedAccordionGridContainer: FC<NestedAccordionGridContainerProps> = ({
    dataModel,
    onEdit,
    onDelete,
    onAdd,
    hideSearch = false,
    tableActions,
    onHandleChangePage,
    pagination,
    rowsPerPageOptions,
    availableFilters = [],
    selectedFilters: seedSelectedFilters,
    onFiltersChange,
    loading = false,
    pageSizeIsFixed = false,
    lotSorting = false,
    lotNoSearch = false,
    clearSearch = false,
    showTopDivider = true
}) => {
    const { settings } = useSelector((state: any) => state.settings);
    const { searchQuery: searchQuerySeed = null, ...customFilters } = seedSelectedFilters || {};
    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState<any>(customFilters || {});
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("");
    const [filteredDataModel, setFilteredDataModel] = useState<NestedGridDataModel | undefined>(dataModel);
    const [searchQuery, setSearchQuery] = useState<any>(searchQuerySeed || "");
    const [lotSearchQuery, setLotSearchQuery] = useState<string>(searchQuerySeed || "");
    const [sortByQuery, setSortByQuery] = useState<any>(searchQuerySeed || sortByOptions[sortByOptions.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]);
    const [filterLists, setFilterLists] = useState<any>([]);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);
    const { classes, cx } = useStyles();
    const rowRefs = useRef<{ text: string; ref: HTMLElement }[]>([]);
    const columnIndexOfLotNumber = useMemo(
        () => dataModel?.headers?.findIndex(({ title }) => title?.toLowerCase() === "number"),
        [dataModel]
    );
    const showFiltersSelector = useMemo(() => (
        !!(
            showFilters &&
            ((!availableFilters && (filterLists?.length || 0) > 0) ||
                (availableFilters && (availableFilters?.length || 0) > 0))
        )
    ), [availableFilters, filterLists?.length, showFilters])

    const showRemoteSearchField = useMemo(() => !hideSearch, [hideSearch]);
    const showRemoteLotSearchField = useMemo(() => lotNoSearch, [lotNoSearch]);
    const canSortLots = useMemo(() => lotSorting, [lotSorting]);
    const showAdd = useMemo(() => !!onAdd, [onAdd]);
    const showEdit = useMemo(() => !!onEdit, [onEdit]);
    const showDelete = useMemo(() => !!onDelete, [onDelete]);
    const [expanded, setExpanded] = useReducer((state: any, action: any) => {
        switch (action.type) {
            case "add":
                const foundItem = state?.findIndex((row: any) => row[0] === action.payload[0] && row[1] === action.payload[1])

                if (foundItem !== undefined && foundItem > -1)
                    return state;
                return [...state, action.payload];
            case "remove":
                let tempArray = [...state];
                const index = state?.findIndex(
                    (row: any) =>
                        row[0] === action.payload[0] && row[1] === action.payload[1]
                );
                if (index !== undefined && index > -1) {
                    tempArray.splice(index, 1);
                }
                return tempArray;
            default:
                return { ...state };
        }
    }, [] as any[]);

    const unique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const handleClickFilter = useCallback((event) => {
        setAnchorElFilter(event.target);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setAnchorElFilter(null);
    }, []);

    const handleRequestSort = useCallback(
        (property: string) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const onFilterChanged = useCallback((filterInfo) => {
        setSelectedFilters(currentState => ({
            ...currentState,
            [filterInfo.name]: filterInfo.value,
        }));
    }, []);

    const onFilterApplied = useCallback(() => {
        if (!availableFilters) {
            let newResultsSet = { ...dataModel } as NestedGridDataModel;
            for (const [key, value] of Object.entries(selectedFilters)) {
                if (value !== "All") {
                    const header = dataModel?.headers.filter(
                        (head) => head.title === key
                    )[0];
                    const headerIndex = dataModel?.headers.indexOf(
                        header ? header : { title: "", align: "left" }
                    );

                    if (headerIndex !== undefined) {
                        const newRows = newResultsSet.rows.filter(
                            (row) => row.cells[headerIndex].text === value
                        );
                        newResultsSet.rows = newRows;
                    }
                }
            }

            setFilteredDataModel(newResultsSet);
        }

        if (onFiltersChange) onFiltersChange({ searchQuery, ...selectedFilters });
    }, [
        availableFilters,
        dataModel,
        onFiltersChange,
        searchQuery,
        selectedFilters,
    ]);

    const handleSelect = useCallback(
        (event) => {
            let filterInfo = {
                name: event.target.name,
                value: event.target.value,
            };

            let filter = availableFilters?.find(
                (x) => x.propertyMap === event.target.name
            );
            if (filter) {
                switch (filter.type) {
                    case "boolean":
                        filterInfo.value = filterInfo.value === "true";
                        break;
                    case "number":
                        filterInfo.value = Number(filterInfo.value);
                        break;
                }
                if (event.target.value === "All") filterInfo.value = undefined;
            }

            onFilterChanged(filterInfo);
        },
        [availableFilters, onFilterChanged]
    );

    const onFilterClick = useCallback(
        (event) => {
            onFilterApplied();
        },
        [onFilterApplied]
    );

    const handleLotNumberRowRef = useCallback((ref: HTMLElement) => {
        if (ref) {
            if (rowRefs.current.find((cellRef) => cellRef.ref === ref) !== undefined)
                return; // if we already have this ref, don't add it again

            rowRefs.current.push({
                text: ref?.innerHTML.toLowerCase().trim(), // we will find the text in the cell here, and then use the corresponding ref to find the cell in the dom
                ref,
            });
        }
    }, []);

    const onSearchApplied = () => {
        if (onFiltersChange) {
            const payload = { ...selectedFilters, searchQuery };
            if (lotSorting) payload.sortByQuery = sortByQuery;
            if (lotNoSearch) payload.lotSearchQuery = lotSearchQuery;
            onFiltersChange(payload);
        }
    };

    const onRemoteSearchChange = useCallback(
        (e: { target: { id: any; value: string } }) => {
            setSearchQuery(e.target.value);
        }, []
    );

    const onRemoteLotSearchChange = useCallback(
        (e: { target: { id: any; value: string } }) => {
            setLotSearchQuery(e.target.value);
        }, []
    );

    const onRemoteSortValueChange = (e: any) => {
        let index = sortByOptions.findIndex((item) => item === e.target.value)
        setSortByQuery(sortByOptions[index]);
    }

    const handleRemoteSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearchApplied();
        }
    };

    const handleRemoteLotSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearchApplied();
        }
    };

    const rowData: Row[] = useMemo(() => {
        if (filteredDataModel) {

            const indexToFilterRowsBy = filteredDataModel?.headers?.findIndex(heading => heading?.title === orderBy);
            const rowsToSort = filteredDataModel?.rows ?? [];

            if (indexToFilterRowsBy !== undefined && indexToFilterRowsBy !== -1) {
                return _.orderBy(rowsToSort, row => {
                    const text = row?.cells[indexToFilterRowsBy]?.text;

                    if (typeof text === 'string') {
                        return text.trim();
                    }

                    return Number(text);

                }, order);
            }

            return rowsToSort;
        }

        return [];
    }, [filteredDataModel, order, orderBy]);

    const rowComponents = useMemo(() => {
        return (
            <>
                {rowData.map((row, index) => (
                    <NestedAccordionGridRow
                        key={`${row.id}-${index}`}
                        lotNumberRowRef={handleLotNumberRowRef}
                        row={row}
                        setExpanded={setExpanded}
                        showEdit={showEdit}
                        onEdit={onEdit}
                        showDelete={showDelete}
                        onDelete={onDelete}
                        columnIndexOfLotNumber={columnIndexOfLotNumber}
                        openRow={
                            expanded?.findIndex(
                                (x: any) => x[0] === row.id && x[1] === undefined
                            ) > -1 ?? false
                        }
                    />
                ))}
            </>
        );
    }, [
        columnIndexOfLotNumber,
        expanded,
        handleLotNumberRowRef,
        onDelete,
        onEdit,
        rowData,
        showDelete,
        showEdit,
    ]);

    const paginationComponent = useMemo(
        () => (
            <>
                {pagination && (
                    <Pagination
                        onHandleChangePage={onHandleChangePage}
                        pagination={pagination}
                        loading={loading}
                        rowsPerPageOptions={rowsPerPageOptions}
                        pageSizeIsFixed={pageSizeIsFixed}
                    />
                )}
            </>
        ),
        [loading, onHandleChangePage, pageSizeIsFixed, pagination, rowsPerPageOptions]
    );

    const headerComponent = useMemo(
        () => (
            <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                showAdd={showAdd}
                onAdd={onAdd}
                dataModel={filteredDataModel}
                actions={tableActions}
                loading={loading}
            />
        ),
        [
            classes,
            filteredDataModel,
            handleRequestSort,
            loading,
            onAdd,
            order,
            orderBy,
            showAdd,
            tableActions,
        ]
    );

    useEffect(() => {
        if (sortByQuery && lotSorting) {
            onSearchApplied();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotSorting, sortByQuery]);

    useEffect(() => {
        if (clearSearch) {
            setSearchQuery("");
            setLotSearchQuery("");
            setSortByQuery(sortByOptions[sortByOptions.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]);
            setSelectedFilters({});
        }
    }, [clearSearch, settings.DefaultLotOrdering])

    useEffect(() => {
        rowRefs.current = [];
    }, [dataModel])

    useEffect(() => {

        setFilteredDataModel(dataModel);

        if (dataModel) {
            const filterLists = dataModel.headers.map((head, index) => {
                if (head.canFilter) {
                    let list = dataModel.rows.map((row) => {
                        return row.cells[index].text;
                    });

                    let distinctList = list.filter(unique);
                    return {
                        key: head.title,
                        values: distinctList,
                    };
                }
            });
            const hasFilterItems =
                dataModel.headers.filter((head) => head?.canFilter === true).length > 0;
            setShowFilters(hasFilterItems);
            setFilterLists(filterLists.filter((value) => value !== undefined));
            setHeaderButtons(dataModel.headerButtons ? dataModel.headerButtons : []);
        }
    }, [dataModel]);

    return (
        <div className="bg-white">
            {
                showTopDivider && <div className="divider" />
            }
            <Grid
                container
                spacing={1}
                flexDirection='row'
                alignItems='center'>
                <Grid item md={9}>
                    <Grid
                        container
                        spacing={1}>
                        <Grid item md={4}>
                            {showRemoteSearchField && (
                                <SearchField
                                    type="text"
                                    onChange={onRemoteSearchChange}
                                    value={searchQuery}
                                    onKeyPress={handleRemoteSearchKeyPress}
                                    disabled={loading}
                                    placeholder={"Search..."}
                                />
                            )}
                        </Grid>
                        <Grid item md={4}>
                            {showRemoteLotSearchField && (
                                <SearchField
                                    type="text"
                                    onChange={onRemoteLotSearchChange}
                                    onKeyPress={handleRemoteLotSearchKeyPress}
                                    value={lotSearchQuery}
                                    disabled={loading}
                                    placeholder={"Search " + settings.LotName + " Number..."}
                                />
                            )}
                        </Grid>
                        <Grid item md={4}>
                            {canSortLots && settings && (
                                <div className="px-3 py-3">
                                    <Select
                                        value={sortByQuery}
                                        onChange={onRemoteSortValueChange}
                                        fullWidth
                                        disabled={loading}
                                        readOnly={loading}
                                        defaultValue={sortByOptions[sortByOptions.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")]}
                                        style={{ border: '0px solid #cecece' }} >
                                        {
                                            sortByOptions?.map((value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={3} style={{ display: 'flex', justifyContent: 'flex-end' }} right={10}>
                    <div className="d-flex align-items-right"
                        style={{
                            opacity: loading ? 0.5 : 1,
                            pointerEvents: loading ? "none" : "all",
                        }}>
                        <HeaderButtonList headerButtons={headerButtons} />
                        {showFiltersSelector && (
                            <FiltersButton onClick={handleClickFilter} />
                        )}
                    </div>
                    <FiltersModal
                        onSubmit={onFilterClick}
                        onClose={handleCloseFilter}
                        anchorEl={anchorElFilter}
                        loading={loading}
                        availableFilters={availableFilters}
                        selectedFilters={selectedFilters}
                        onSelectionChange={handleSelect}
                        filterLists={filterLists}
                    />
                </Grid>
            </Grid>

            <MainTable
                loading={loading}
                rowComponents={rowComponents}
                paginationComponent={paginationComponent}
                headerComponent={headerComponent}
            />
        </div>
    );
};

export default memo(NestedAccordionGridContainer);

