import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearDocumentTemplate, handleDocumentTemplateAdd, handleDocumentTemplateUpdate, clearApiResponses, handleDocumentTemplateFetch, newDocumentTemplateId } from './store/Documents';
import {
    Card,
    Button,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    FormControlLabel,
    Switch,
    InputLabel
} from '@mui/material';
import { FaTimes, FaSave, FaBackspace } from "react-icons/fa";
import { Alert } from "@mui/lab";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { DocumentTypeEnum } from '@/helpers/DocumentTypeEnum';
import { getEnumLabels, toCamel } from '@/helpers/Utils';
import ns from '@/helpers/NotificationService';
import { merge } from 'lodash';

interface Props {
    clearDocumentTemplate_d: () => void,
    handleDocumentTemplateAdd_d: (documentTemplateInfo: any) => void,
    handleDocumentTemplateUpdate_d: (documentTemplateInfo: any) => void,
    clearResponses_d: () => void,
    handleDocumentTemplateFetch_d: (id: string) => void,
    documentTemplate: any,
    documentTemplateRequest: string,
    success: boolean,
    error: boolean,
    errorText: string,
    documentTemplateId: string,
    isDialog?: boolean,
    handleClose?: () => void,
    loading: boolean,
    settings: any,
}

function createData(
    name: string,
    value: string,
    isNameValid: boolean
) {
    return { name, value, isNameValid };
}

const DocumentTemplateEditAdd: React.FC<Props> = (props) => {
    const {
        clearDocumentTemplate_d,
        handleDocumentTemplateAdd_d,
        handleDocumentTemplateUpdate_d,
        clearResponses_d,
        handleDocumentTemplateFetch_d,
        documentTemplate,
        documentTemplateRequest,
        success,
        error,
        errorText,
        documentTemplateId,
        isDialog = false,
        handleClose,
        loading,
        settings
    } = props;

    const [documentTypeData, setDocumentTypeData] = React.useState<any>([] as any);
    const [formFields, setFormFields] = React.useReducer((state: any, payload: any) => {
        return merge({}, state, payload);
    }, {
        name: '',
        description: '',
        dataCapturedDictionary: {
            lot: {
                isApplied: false,
                isAccessDriven: false,
                isUploadMandatory: false,
                isAcceptanceMandatory: false,
                isRegistrationDocument: false
            },
            auction: {
                isApplied: false,
                isAccessDriven: false,
                isUploadMandatory: false,
                isAcceptanceMandatory: false,
                isRegistrationDocument: false
            },
            companyProfile: {
                isApplied: false,
                isAccessDriven: false,
                isUploadMandatory: false,
                isAcceptanceMandatory: false,
                isRegistrationDocument: false
            },
            individualProfile: {
                isApplied: false,
                isAccessDriven: false,
                isUploadMandatory: false,
                isAcceptanceMandatory: false,
                isRegistrationDocument: false
            }
        }
    })
    const history = useHistory();
    const [isFormValid, setIsFormValid] = React.useState(true);

    const onIsAppliedCheckChange = (checked, id) => {
        const draft = {
            dataCapturedDictionary: {
                [id]: {
                    isApplied: checked
                }
            }
        };
        setFormFields(draft)
    }

    const onIsUploadMandatoryCheckChange = (checked, id) => {
        const draft = {
            dataCapturedDictionary: {
                [id]: {
                    isUploadMandatory: checked,
                    isAcceptanceMandatory: false
                }
            }
        };
        setFormFields(draft)
    }

    const onIsAcceptanceMandatoryCheckChange = (checked, id) => {
        const draft = {
            dataCapturedDictionary: {
                [id]: {
                    isAcceptanceMandatory: checked,
                    isUploadMandatory: false
                }
            }
        };
        setFormFields(draft)
    }

    const onIsRegistrationDocumentCheckChange = (checked, id) => {
        const draft = {
            dataCapturedDictionary: {
                [id]: {
                    isRegistrationDocument: checked,
                }
            }
        };
        setFormFields(draft)
    }

    const onIsAccessDrivenCheckChange = (checked, id) => {
        const draft = {
            dataCapturedDictionary: {
                [id]: {
                    isAccessDriven: checked,
                }
            }
        };
        setFormFields(draft)
    }

    useEffect(() => {
        if (location) {
            const documentTemplateId = new URLSearchParams(history.location.search).get("documentTemplateId")
            if (documentTemplateId != '' && !documentTemplate && documentTemplateId !== null) {
                showProgressSpinner({ description: "Retrieving Document..." });
                handleDocumentTemplateFetch_d(documentTemplateId);
            } else {
                hideProgressSpinner();
            }
        }

        const tempDocumentTypes: any = getEnumLabels(DocumentTypeEnum).map((name, index) => { return { key: index, name } });
        setDocumentTypeData(tempDocumentTypes);
    }, []);

    useEffect(() => {
        if (success) {
            if (isDialog === true && handleClose) {
                handleClose();
            }
            clearResponses_d();
            ns.success("Template successfully saved");
            hideProgressSpinner();
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            if (isDialog === true && handleClose) {
                handleClose();
            }
            clearResponses_d();
            ns.error(errorText);
            hideProgressSpinner();
        }
    }, [error]);

    useEffect(() => {
        if (documentTemplate !== undefined) {
            const fields = {
                ...formFields,
                id: documentTemplate.id,
                name: documentTemplate.name,
                description: documentTemplate.description
            };

            const final = merge(fields, { dataCapturedDictionary: toCamel(documentTemplate.dataCaptured) });

            setFormFields(final);
            hideProgressSpinner();
        }
    }, [documentTemplate])

    useEffect(() => {
        if (documentTemplateId !== '') {
            if (formFields)
                formFields['id'] = documentTemplateId;

            navAddEdit(documentTemplateId);
        }
    }, [documentTemplateId]);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onCancel = () => {
        if (isDialog === true && handleClose) {
            handleClose();
        }
        else {
            history.push('/DocumentTemplates')
            clearDocumentTemplate_d()
        }
    }

    const onSubmit = () => {

        if (isFormValid) {
            showProgressSpinner({ description: "Saving Document..." });
            if (documentTemplateRequest === 'Add') {
                handleDocumentTemplateAdd_d(formFields)
            } else {
                handleDocumentTemplateUpdate_d(formFields)
            }
        }
        else {
            hideProgressSpinner();
            ns.error("Please fill in all required fields");
        }
    }

    const validate = (e) => {
        if (e.target.required === true) {
            if (e.target.value === '')
                setIsFormValid(false);
            else
                setIsFormValid(true);
        }
    }

    const navAddEdit = (documentTemplateId) => {
        if (isDialog === true && handleClose) {
            handleClose();
        }
        else {
            let path = '/DocumentTemplateEdit'
            if (documentTemplateId !== '')
                path += '?documentTemplateId=' + documentTemplateId
            history.push(path);
        }
    }

    const mapRow = (item: any) => {
        let id = item.name.substring(0, 1).toLowerCase() + item.name.substring(1);
        id = id.replace(' ', '');

        return (
            <TableRow key={id}>
                <TableCell>
                    <InputLabel>{item.name === 'Lot' ? settings.LotName : item.name === 'Auction' ? settings.AuctionName : item.name}</InputLabel>
                </TableCell>
                <TableCell>
                    <Switch id={item.key} checked={formFields?.dataCapturedDictionary?.[id]?.isApplied ?? false} onChange={(e) => onIsAppliedCheckChange(e.target.checked, id)} className="m-2 p-2 switch-medium toggle-switch-success" />
                </TableCell>
                <TableCell>
                    <Switch id={item.key} checked={formFields?.dataCapturedDictionary?.[id]?.isAccessDriven ?? false} onChange={(e) => onIsAccessDrivenCheckChange(e.target.checked, id)} className="m-2 p-2 switch-medium toggle-switch-success" />
                </TableCell>
                <TableCell>
                    <Switch id={item.key} checked={formFields?.dataCapturedDictionary?.[id]?.isUploadMandatory ?? false} onChange={(e) => onIsUploadMandatoryCheckChange(e.target.checked, id)} className="m-2 p-2 switch-medium toggle-switch-success" />
                </TableCell>
                <TableCell>
                    <Switch id={item.key} checked={formFields?.dataCapturedDictionary?.[id]?.isAcceptanceMandatory ?? false} onChange={(e) => onIsAcceptanceMandatoryCheckChange(e.target.checked, id)} className="m-2 p-2 switch-medium toggle-switch-success" />
                </TableCell>
                <TableCell>
                    <Switch id={item.key} checked={formFields?.dataCapturedDictionary?.[id]?.isRegistrationDocument ?? false} onChange={(e) => onIsRegistrationDocumentCheckChange(e.target.checked, id)} className="m-2 p-2 switch-medium toggle-switch-success" />
                </TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>
        );
    };

    return (
        <div>
            {!loading && (<>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Card className="card-box mb-spacing-6-x2">
                            <div className="card-header dpy-3">
                                <div className="card-header--title font-size-lg">
                                    Document Information
                                </div>
                            </div>
                            <div className="p-3">
                                <TextField value={formFields.name} autoFocus required error={!isFormValid} onChange={onChange} id="name" fullWidth className="m-2" label="Name" onBlur={validate} autoComplete="new-password" margin="normal" />
                                <TextField value={formFields.description} onChange={onChange} id="description" fullWidth className="m-2" label="Description" autoComplete="new-password" margin="normal" />
                            </div>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Document Type</TableCell>
                                                <TableCell>Apply To Document Type</TableCell>
                                                <TableCell>Access Controlled</TableCell>
                                                <TableCell>Upload Required</TableCell>
                                                <TableCell>Acceptance Required</TableCell>
                                                <TableCell>Registration Document</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documentTypeData.map(item => mapRow(item))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                {
                    Boolean(isDialog) === false ? (
                        <FloatyButtonGroup>
                            <Tooltip arrow title="Save" placement="left">
                                <Button size="large" variant="contained" onClick={onSubmit}
                                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                    <span className="btn-wrapper--icon">
                                        <FaSave />
                                    </span>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow title="Back" placement="left">
                                <Button size="large" variant="contained" onClick={onCancel}
                                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                    <span className="btn-wrapper--icon">
                                        <FaBackspace />
                                    </span>
                                </Button>
                            </Tooltip>
                        </FloatyButtonGroup>)
                        :
                        (<div className="text-right">
                            <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1" size="large">
                                <span className="btn-wrapper--icon">
                                    <FaTimes />
                                </span>
                                <span className="btn-wrapper--label">Cancel</span>
                            </Button>

                            <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1" size="large">
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                                <span className="btn-wrapper--label">Save</span>
                            </Button>
                        </div>)
                }
            </>)}

        </div>
    )
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearDocumentTemplate_d: () => dispatch(clearDocumentTemplate()),
        handleDocumentTemplateAdd_d: (documentTemplateInfo: any) => dispatch(handleDocumentTemplateAdd(documentTemplateInfo)),
        handleDocumentTemplateUpdate_d: (documentTemplateInfo: any) => dispatch(handleDocumentTemplateUpdate(documentTemplateInfo)),
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleDocumentTemplateFetch_d: (id: string) => dispatch(handleDocumentTemplateFetch(id)),
    }
};

const mapStateToProps = (state: any) => ({
    documentTemplate: state.documents.documentTemplate,
    documentTemplateRequest: state.documents.documentTemplateRequest,
    success: state.documents.success,
    error: state.documents.error,
    errorText: state.documents.errorText,
    documentTemplateId: state.documents.documentTemplateId,
    selectDocumentTemplate: state.documents.selectDocumentTemplate,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplateEditAdd);
