import React, { FC, memo } from "react";
import { Button, Tooltip } from "@mui/material";
import { MdFilterList } from "react-icons/md";

interface FiltersButtonProps {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const FiltersButton: FC<FiltersButtonProps> = ({ onClick }) => {
	return (
		<Tooltip arrow title="Filter" placement="bottom">
			<Button
				onClick={onClick}
				variant="text"
				className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize">
				<MdFilterList />
			</Button>
		</Tooltip>
	);
};

export default memo(FiltersButton);
