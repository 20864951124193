import React, { useRef } from 'react';
import ProgressSpinner from '@/components/ProgressSpinner/ProgressSpinner';
import { connect, } from 'react-redux';
import ReactToPrint from "react-to-print";
import Catalogue from "./Catalogue";
import { Button, Container } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';

interface Props {
    //printData: any,
    //loading: boolean
}

const CataloguePrint: React.FC<Props> = props => {

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);

    React.useEffect(() => {
        showProgressSpinner({ description: 'Generating Catalog...' });
    }, [])

    const componentLoaded = () => {
        hideProgressSpinner();
    }

    const componentRef = useRef<HTMLDivElement>(null)

    return (
        <div>
            <div className="ml-2" >
                <ReactToPrint
                    trigger={() => <Container><div className=" d-flex flex-direction-row align-items-end justify-content-end"><Button variant="contained" id='printButton' className="btn-primary mt-1 mb-1 p-3 text-white text-capitalize">Print</Button></div></Container>}
                    content={() => componentRef.current}
                />
            </div>
            <div ref={componentRef}>
                <Catalogue componentLoaded={componentLoaded} />
            </div>
        </div>
    )
}

export default CataloguePrint;