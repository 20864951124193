import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import VenueEdit from "@/pages/Venue/VenueEdit"

export default function VenueAddDialog({ isDialogOpened, handleCloseDialog }) {

    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('xl');

    const handleClose = (data?: any) => {
        handleCloseDialog(data);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                onClose={() => handleClose()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Venue</DialogTitle>
                <DialogContent>
                    <VenueEdit isDialog={true} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}