import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card, Grid, TextField, Button, FormLabel, Tooltip } from "@mui/material";
import { FaTimes, FaSave, FaBackspace } from "react-icons/fa";
import useHistory from '@/hooks/useHistory';
import { clearError, handleCreate, handleUpdate, clearVenue, handleReverseGeo, handleMapSearch, handleVenueFetch, newVenueLots, newVenueAuction, reset } from "./store/Venue";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import GoogleMapContainer from "../../components/GoogleMapContainer/GoogleMapContainer";

function createData(name: string, value: string, isNameValid: boolean) {
    return { name, value, isNameValid };
}

interface Props {
    createVenue_d: (venueInfo: any, additionalData: any, onCompeltedCallback: (res: any) => void) => void,
    updateVenue_d: (venueInfo: any, additionalData: any, onCompeltedCallback: (res: any) => void) => void,
    clearVenue_d: () => void,
    reverseGeo_d: (lat: number, lng: number) => void,
    mapSearch_d: (searchQuery: string) => void,
    handleVenueFetch_d: (id: string) => void,
    venueLots_d: (venueLots: any) => void,
    newVenueLots_d: (venueLots: any) => void,
    newVenueAuction_d: (venueAuction: any) => void,
    clearError_d: () => void,
    venue: any,
    venueTypeRequest: string,
    reverseGeoResult: any,
    mapSearchResult: any,
    venueId: string,
    isDialog?: boolean,
    handleClose?: (data?: any) => void,
    loading: boolean,
    error: boolean,
    errorText: any,
}

const VenueEdit: React.FC<Props> = (props) => {
    const history = useHistory();
    const {
        clearError_d,
        createVenue_d,
        updateVenue_d,
        clearVenue_d,
        mapSearch_d,
        reverseGeo_d,
        handleVenueFetch_d,
        newVenueLots_d,
        newVenueAuction_d,
        venue,
        venueTypeRequest,
        reverseGeoResult,
        mapSearchResult = [] as any,
        venueId,
        isDialog = false,
        handleClose = null,
        loading,
        error,
        errorText,
    } = props;

    const initialState: any = {
        name: '',
        description: '',
        address: {
            addressId: '',
            addressLine1: '',
            addressLine2: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            searchString: '',
            longitude: '',
            latitude: '',
        },
    }

    const [formFields, setFormFields] = React.useReducer((state: any, updatedFields: any) => {
        return { ...state, ...updatedFields };
    }, initialState);

    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] = React.useState<any>({
        name: false,
        //address: {
        //    addressLine1: false,
        //    country: false,
        //    state: false,
        //    city: false,
        //    zip: false
        //}
    });
    const [additionalData, setAdditionalData] = React.useState([] as any);
    const [isFormValid, setIsFormValid] = React.useState(true);

    useEffect(() => {
        if (history.location) {
            const venueId = new URLSearchParams(history.location.search).get("venueId");
            if (venueId != "" && !venue && venueId !== null) {
                showProgressSpinner({ description: "Retrieving Venue..." });
                handleVenueFetch_d(venueId);
            } else {
                hideProgressSpinner();
            }
        }
    }, []);

    useEffect(() => {
        if (venueId !== "") {
            if (formFields) formFields["id"] = venueId;

            navAddEdit(venueId);
        }
    }, [venueId]);

    useEffect(() => {
        if (venue !== undefined) {
            const addFields = [] as any;
            for (const [key, value] of Object.entries(
                venue.dataCaptured
            )) {
                addFields.push({
                    name: key,
                    value: value,
                    isNameValid: true,
                });
            }

            const fields = {
                id: venue.id,
                name: venue.name,
                description: venue.description,
                address: venue.address,
            };

            setAdditionalData(addFields);
            setFormFields(fields);

            hideProgressSpinner();

        }
    }, [venue]);

    useEffect(() => {
        if (reverseGeoResult) {
            setAddressFields(reverseGeoResult);
        }
    }, [reverseGeoResult]);

    React.useEffect(() => {
        if (error) {
            hideProgressSpinner()
            clearError_d()
            ns.error(errorText);
        }
    }, [error]);

    const navAddEdit = (venueId) => {
        if (isDialog === true && handleClose) handleClose();
        else {
            let path = "/VenueEdit";
            if (venueId !== "") path += "?venueId=" + venueId;
            history.push(path);
        }
    };

    const setAddressFields = (geoAddress: any) => {
        if (geoAddress) {
            let addressId = formFields.address.addressId;
            let newAddress = {
                addressId: addressId,
                addressLine1: geoAddress.address?.addressLine1 ?? geoAddress.addressLine1 ?? formFields.address.addressLine1,
                addressLine2: geoAddress.address?.addressLine2 ?? geoAddress.addressLine2 ?? formFields.address.addressLine2,
                country: geoAddress.address?.country ?? geoAddress.country ?? formFields.address.country,
                state: geoAddress.address?.state ?? geoAddress.state ?? formFields.address.state,
                city: geoAddress.address?.city ?? geoAddress.city ?? formFields.address.city,
                zip: geoAddress.address?.zip ?? geoAddress.zip ?? formFields.address.zip,
                searchString: geoAddress.searchString ?? formFields.address.searchString,
                latitude: geoAddress.latitude ?? formFields.address.latitude,
                longitude: geoAddress.longitude ?? formFields.address.longitude,
            };

            setFormFields({ address: newAddress });
        }
    };

    const onChange = (e) => {
        const props = e.target.id.split(".");
        if (props[0] === "address")
            setFormFields({ address: { ...formFields.address, [props[1]]: e.target.value } });
        else setFormFields({ [props[0]]: e.target.value });
    };

    const onAddressChange = (address) => {
        setAddressFields(address);
    };

    const validateField = (e) => {
        const props = e.target.id.split(".");
        let showError = false;
        if (e.target.required === true) {
            if (e.target.value === "") showError = true;
        }
        //if (props[0] === "address") {
        //    setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, address: { ...formFieldsValidationShowErrors.address, [props[1]]: showError } });
        //} else {
        if (e.target.id !== undefined)
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError, });
        else
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError, });
        //}
    };

    const validateAll = () => {
        let isAllValid = true;
        let newState = {
            name: false,
            //address: {
            //    addressLine1: false,
            //    country: false,
            //    state: false,
            //    city: false,
            //    zip: false
            //}
        }
        for (const [key, value] of Object.entries(formFieldsValidationShowErrors)) {
            if (newState[key] instanceof Object == false) {
                const formFieldsValue = formFields[key];
                if (formFieldsValue === "") {
                    isAllValid = false;
                    newState[key] = true;
                } else {
                    newState[key] = false;
                }
            }
        }
        //for (const [key, value] of Object.entries(formFieldsValidationShowErrors.address)) {
        //    const formFieldsValue = formFields.address[key];
        //    if (formFieldsValue === "") {
        //        isAllValid = false;
        //        newState.address[key] = true;
        //    } else {
        //        newState.address[key] = false;
        //    }
        //}
        setFormFieldsValidationShowErrors(newState)
        return isAllValid;
    }

    const onAddAdditionalData = (index) => (e) => {
        if (index > -1) {
            const newState = [...additionalData];
            newState.splice(index + 1, 0, createData("", "", true));
            setAdditionalData(newState);
        } else {
            setAdditionalData([...additionalData, createData("", "", true)]);
        }
    };

    const updateFieldChanged = (index) => (e) => {
        let newArr = [...additionalData];
        newArr[index][e.target.name] = e.target.value;

        setAdditionalData(newArr);
    };

    const onRemoveAdditionalData = (index) => (e) => {
        const prevState = additionalData.slice();
        prevState.splice(index, 1);
        setAdditionalData(prevState);
    };

    const validateAdditionalDataName = () => {
        let isAllValid = true;
        let allAdditionalDataField = [...additionalData].slice();
        for (const data of allAdditionalDataField) {
            if (data.name === "") {
                isAllValid = false;
                data.isNameValid = false;
            } else {
                data.isNameValid = true;
            }
        }

        if (isAllValid == false) {
            setAdditionalData(allAdditionalDataField);
            return false;
        } else {
            return true;
        }
    };

    const onSave = () => {
        const isFormValid = validateAll()

        if (isFormValid) {
            showProgressSpinner({ description: "Saving Venue..." });
            if (venueTypeRequest === "Add")
                createVenue_d(formFields,
                    [],
                    (res) => {
                        hideProgressSpinner();
                        if (res?.success) {
                            ns.success("Venue successfully added");

                            if (isDialog === true && handleClose) {
                                let id = res.response
                                handleClose(id);
                            }
                        }
                    });
            else updateVenue_d(formFields,
                [],
                (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success("Venue successfully updated");
                        if (isDialog === true && handleClose) {
                            let id = res.response
                            handleClose(id);
                        }
                    }
                });
        } else {
            hideProgressSpinner();
            ns.error("Please fill in all required fields")
        }
    };

    const onCancel = () => {
        if (isDialog === true && handleClose) handleClose();
        else {
            history.push("/Venue");
            clearVenue_d();
        }
    };

    React.useEffect(() => {
        if (isDialog === true && venueId !== undefined) {
            newVenueLots_d(venueId);
            newVenueAuction_d(venueId);
        }
    }, [venueId]);

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean } }) => {
        setFormFields({ [e.target.id]: e.target.checked });
    };

    return (<>
        {!loading && <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header py-3">
                            <div className="card-header--title font-size-lg">
                                Venue Information
                            </div>
                        </div>
                        <div className="p-3">
                            <Grid
                                container
                                direction="row"
                                //justify="flex-start"
                                alignItems="flex-start"
                                spacing={0}
                            >
                                <TextField
                                    value={formFields.name}
                                    autoFocus
                                    required
                                    error={formFieldsValidationShowErrors.name}
                                    onChange={onChange}
                                    id="name"
                                    fullWidth
                                    className="m-2"
                                    label="Name"
                                    onBlur={validateField}
                                />
                                <TextField
                                    value={formFields.description}
                                    onChange={onChange}
                                    id="description"
                                    fullWidth
                                    className="m-2"
                                    label="Description"
                                />
                                <Grid item xs={6} style={{ paddingRight: 5 }}>
                                    <TextField
                                        onChange={onChange}
                                        id="address.latitude"
                                        value={formFields.address.latitude}
                                        fullWidth
                                        className="m-2"
                                        label="Latitude"
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: 5, paddingRight: 15 }}>
                                    <TextField
                                        onChange={onChange}
                                        id="address.longitude"
                                        value={formFields.address.longitude}
                                        fullWidth
                                        className="m-2"
                                        label="Longitude"
                                    />
                                </Grid>
                                <TextField
                                    value={formFields.address.addressLine1}
                                    onChange={onChange}
                                    id="address.addressLine1"
                                    fullWidth
                                    className="m-2"
                                    label="Address Line 1"
                                    autoComplete="new-password"
                                />
                                <TextField
                                    value={formFields.address.addressLine2}
                                    onChange={onChange}
                                    id="address.addressLine2"
                                    fullWidth
                                    className="m-2"
                                    label="Address Line 2"
                                    autoComplete="new-password"
                                />
                                <TextField
                                    value={formFields.address.country}
                                    onChange={onChange}
                                    id="address.country"
                                    fullWidth
                                    className="m-2"
                                    label="Country"
                                />
                                <TextField
                                    value={formFields.address.state}
                                    onChange={onChange}
                                    id="address.state"
                                    fullWidth
                                    className="m-2"
                                    label="State/Province"
                                />
                                <TextField
                                    value={formFields.address.city}
                                    onChange={onChange}
                                    id="address.city"
                                    fullWidth
                                    className="m-2"
                                    label="City"
                                />
                                <TextField
                                    value={formFields.address.zip}
                                    type="number"
                                    onChange={onChange}
                                    onKeyDown={(e) =>
                                        (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
                                    }
                                    id="address.zip"
                                    fullWidth
                                    className="m-2"
                                    label="Zip/Postal Code"
                                />
                            </Grid>
                        </div>
                    </Card>
                </Grid>
                <Grid item sm={6}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header py-3">
                            <div className="card-header--title font-size-lg">
                                Map Information
                            </div>
                        </div>
                        <div className="p-3">
                            <Card style={{ height: 606, }}>
                                <GoogleMapContainer
                                    onLocationChanged={onAddressChange}
                                    center={{
                                        lat: Number(formFields.address.latitude),
                                        lng: Number(formFields.address.longitude)
                                    }}
                                    searchString={formFields.address.searchString}
                                />
                            </Card>
                        </div>
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: 16 }}>
                            <FormLabel>Click on the map to drop a pin.</FormLabel>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {
                Boolean(isDialog) === false ? (
                    <FloatyButtonGroup>
                        <Tooltip arrow title="Save" placement="left">
                            <Button size="large" variant="contained" onClick={onSave}
                                className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow title="Back" placement="left">
                            <Button size="large" variant="contained" onClick={onCancel}
                                className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                                <span className="btn-wrapper--icon">
                                    <FaBackspace />
                                </span>
                            </Button>
                        </Tooltip>
                    </FloatyButtonGroup>)
                    :
                    (<div className="text-right">
                        <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaTimes />
                            </span>
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>

                        <Button onClick={onSave} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                            <span className="btn-wrapper--label">Save</span>
                        </Button>
                    </div>)
            }
        </>}
    </>);
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        clearVenue_d: () => dispatch(clearVenue()),
        createVenue_d: (ff: any, ad: any, onCompletedCallback: (res: any) => void) => dispatch(handleCreate(ff, ad, onCompletedCallback)),
        updateVenue_d: (ff: any, ad: any, onCompletedCallback: (res: any) => void) => dispatch(handleUpdate(ff, ad, onCompletedCallback)),
        reverseGeo_d: (lat: number, lng: number) => dispatch(handleReverseGeo(lat, lng)),
        mapSearch_d: (searchQuery: string) => dispatch(handleMapSearch(searchQuery)),
        handleVenueFetch_d: (id: string) => dispatch(handleVenueFetch(id)),
        venueLots_d: (venueLots: any) => dispatch(venueLots(venueLots)),
        newVenueLots_d: (venueLots: any) => dispatch(newVenueLots(venueLots)),
        newVenueAuction_d: (venueAuction: any) => dispatch(newVenueAuction(venueAuction))
    };
};

const mapStateToProps = (state: any) => ({
    error: state.venue.error,
    errorText: state.venue.errorText,
    venue: state.venue.venue,
    venueTypeRequest: state.venue.venueTypeRequest,
    reverseGeoResult: state.venue.reverseGeoResult,
    mapSearchResult: state.venue.mapSearchResult,
    venueId: state.venue.venueId,
    selectNewVenue: state.venue.selectNewVenue,
    loading: state.progressSpinner.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueEdit);
