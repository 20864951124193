import { FC, MouseEventHandler, memo, useState } from "react";
import LoadingButton from '@/components/LoadingButton';
import { useSelector } from "react-redux";

interface MarkNonSTCLotsAsSoldProps {
    isLoading?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    settings?: any;
}

const MarkNonSTCLotsAsSold: FC<MarkNonSTCLotsAsSoldProps> = ({ isLoading, onClick, settings }) => {
    
	return (
        <div>
            <LoadingButton
                loading={isLoading ?? false}
                disabled={false}
                type="primary"
                onClick={onClick ?? undefined}
                label={`Mark Non-STC ${settings.LotName}s Meeting Reserve as Sold`}
                fullWidth={true}
                width={400}
            />
        </div>
	);
};

export default memo(MarkNonSTCLotsAsSold);