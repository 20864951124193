import React from "react";
import { ValidationStatus } from "@/dto/BulkUploader";

type BulkUploaderValidationStatusBadgeProps = {
    status: ValidationStatus;
}

export function determineValidationStatus(status: ValidationStatus) {
    if (status === ValidationStatus.Valid) return 'Valid';
    if (status === ValidationStatus.Invalid) return 'Invalid';

    return 'Unknown';
}


function BulkUploaderValidationStatusBadge({ status }: BulkUploaderValidationStatusBadgeProps) {
    return <>{determineValidationStatus(status)}</>;
}

export default BulkUploaderValidationStatusBadge;