export const SHOW_DIALOG = 'SHOW_DIALOG';
export const RESET_DIALOG = 'RESET_DIALOG';

//Action
export interface DialogConfig {
    title?: string,
    message?: string,
    content?: JSX.Element,
    onSubmit?: () => void,
    onCancel?: () => void,
    locales?: {
        submitText?: string,
        cancelText?: string
    },
    actions?: JSX.Element[],
    contentStyle?: React.DetailedHTMLProps<React.StyleHTMLAttributes<HTMLStyleElement>, HTMLStyleElement> | any,
    allowCloseOnBackDropClick?: boolean
}

export interface DialogState extends DialogConfig {
    open: boolean
}

const initialState: DialogState = {
    open: false,
    title: undefined,
    message: undefined,
    content: undefined,
    onSubmit: undefined,
    onCancel: undefined,
    locales: {
        submitText: undefined,
        cancelText: undefined
    },
    actions: [],
    contentStyle: undefined,
    allowCloseOnBackDropClick: true
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SHOW_DIALOG: return { ...state, open: true, ...action.config }
        case RESET_DIALOG: return { ...state, ...initialState }
        default: return state;
    }
}