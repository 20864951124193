import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@mui/material';
import { FinanceDocument } from '@/dto/FinanceDocument';
import SimpleTable, { ColumnProps } from '@/components/SimpleTable/SimpleTable';
import CurrencyLabel from '@/components/CurrencyComponent/CurrencyLabel';
import ns from '@/helpers/NotificationService';
import { handleGenerateConsolidatedProformaInvoice, retrieveAllConsolidatableFinanceDocuments } from './store/Invoicing';
import { FaFileInvoiceDollar, FaTimes } from 'react-icons/fa';
import { useAppDispatch } from '../../store/ConfigureStore';
import { determineFinanceDocumentStatus } from "./FinanceDocumentStatusBadge";
import _ from 'lodash';

type FinanceDocumentModalProps = {
    onClose: (reload?: boolean) => void;
    auctionId: string;
}

function FinanceDocumentModal({
    onClose,
    auctionId,
}: FinanceDocumentModalProps) {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<Partial<FinanceDocument>[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedRowsForModal, setSelectedRowsForModal] = useState<any>([]);
    const [searchString, setSearchString] = useState<string>("");

    const columns = useMemo<ColumnProps<Partial<FinanceDocument>>[]>(() => ([
        { id: "type", header: 'Type', accessorKey: 'type' },
        {
            id: "number", header: 'Doc.No.', accessorKey: 'number',
            sortingFn: (a, b) => {
                return a.getValue<string>('finDocNumber')?.localeCompare(b.getValue<string>('finDocNumber')) ?? 0
            },
            enableSorting: true
        },
        { id: "finDocNumber", header: 'Doc.No.', accessorKey: 'number' },
        { id: "lotNumber", header: 'Lot Number', accessorFn: (row) => row.extraData?.lotNumber ?? row.extraData?.consolidatedLotNumbers ?? '-' },
        { id: "customerName", header: 'Customer Name', accessorKey: 'customerName' },
        { id: "customerType", header: 'Entity Type', accessorKey: 'customerType' },
        { id: "reference", header: 'Cust.Ref.', accessorKey: 'reference' },
        { id: "date", header: 'Date', accessorKey: 'date' },
        {
            id: "total",
            header: 'Total',
            accessorKey: 'totalInclVat',
            Cell: ({ cell }) => (<CurrencyLabel value={cell.getValue<number>()} />),
        },
        {
            id: "status",
            header: 'Status',
            accessorFn: (row) => determineFinanceDocumentStatus(row.status, row.type),
        },
    ]), []);

    const handleOnSearch = (searchQuery: string) => {
        setSearchString(searchQuery);
    }

    const reload = () => {
        setLoading(true);
        setData([]);
        dispatch(retrieveAllConsolidatableFinanceDocuments({ auctionId }, (res: any) => {
            if (res?.success) {
                setData(res.response);
            }

            setLoading(false);
        }));
    }

    const handleSelectionModelChange = (selectionModel) => {
        setSelectedRowsForModal(selectionModel);
    };

    const handleClose = () => {
        onClose();
    }

    const handleConsolidateClicked = () => {
        if (selectedRowsForModal.length < 2) {
            ns.error("Please select at least two Pro-Forma Invoices to consolidate.");
            return;
        }

        const selectedRows = selectedRowsForModal.map(row => row.original);
        const groupedInvoices = _.groupBy(selectedRows, 'reference');
        const groupedConsolidatedFinanceDocumentIds = Object.keys(groupedInvoices).map((key) => (groupedInvoices[key].map(x => x.id as string)));

        ns.showDialog({
            title: "Consolidate Pro-Forma Invoices",
            message:
                Object.keys(groupedInvoices).length === 1 ?
                `Are you sure you want to consolidate the ${selectedRows.length} selected Pro-Forma Invoices for customer reference ${Object.keys(groupedInvoices)[0]}?` :
                `Are you sure you want to consolidate the ${selectedRows.length} selected Pro-Forma Invoices for ${Object.keys(groupedInvoices).length} customer references?`,

            onSubmit: () => {
                setLoading(true);
                dispatch(handleGenerateConsolidatedProformaInvoice(groupedConsolidatedFinanceDocumentIds, (res: any) => {
                    if (res?.success) {
                        ns.success("Pro-Forma Invoices consolidated successfully.");
                        onClose(true);
                    }
                }))
            },
            onCancel: () => {
            },
            locales: {
                submitText: "Yes",
                cancelText: "No"
            }
        });
    }

    useEffect(() => {
        reload();
    }, [auctionId]);

    return <Dialog
        open={true}
        onClose={(event, reason) => {
            if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
                return;
            onClose();
        }}
        fullWidth
        maxWidth={false}
    >
        <DialogTitle style={{ paddingLeft: 40 }} id="max-width-dialog-title">Consolidate Pro-Forma Invoices</DialogTitle>
        <DialogContent style={{ padding: 20 }}>
            <div>Pro-Forma Invoices will be consolidated on the customer reference by selecting two or more pro-forma invoices per customer reference.</div>
            <SimpleTable
                enableRowSelection
                columnHeaders={columns}
                hideColumns={['type', 'finDocNumber']}
                tableData={data}
                renderTopTableActions={(table, isLoading) => {
                    const selectedRows = table.getSelectedRowModel()?.flatRows ?? [];
                    setSelectedRowsForModal(selectedRows);
                    return <>
                        {selectedRows?.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Selected {selectedRows?.length} of {data?.length ?? 0} Pro-Forma Invoices</div>}
                    </>
                }}

                sorting={[
                    { id: 'number', desc: false }
                ]}

                onSearch={handleOnSearch}
                isSaving={loading}
                searchString={searchString}
                onRefresh={() => {
                    reload()
                }}
                groupBy={['reference']}
                tableContainerStyle={{ maxHeight: 600, minHeight: 200 }}
                rowVirtualization={true}
            />
            <div style={{ padding: 10 }}>
                <DialogActions >
                    <Button onClick={handleConsolidateClicked} variant="contained" className="btn-primary p-3 text-white text-capitalize" disabled={loading}>
                        <span className="btn-wrapper--icon">
                            <FaFileInvoiceDollar />
                        </span>
                        <span className="btn-wrapper--label">Consolidate</span>
                    </Button>
                    <Button onClick={handleClose} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                </DialogActions>
            </div>
        </DialogContent>
    </Dialog >
}

export default FinanceDocumentModal;