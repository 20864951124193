import React, { memo, FC, useCallback } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
	TableRow,
	TableCell,
	TableHead,
	Button,
	Tooltip,
} from "@mui/material";
import { FaPlus } from "react-icons/fa";

import {
	Classes,
	NestedGridDataModel,
	OnAddHandler,
	Order,
	TableActions,
} from "../NestedAccordionGrid.types";

interface EnhancedTableHeadProps {
	classes: Classes;
	onRequestSort: (property: string) => void;
	order: Order;
	orderBy: string;
	showAdd: boolean;
	onAdd?: OnAddHandler;
	dataModel?: NestedGridDataModel;
	actions?: TableActions;
	loading: boolean;
}

const EnhancedTableHead: FC<EnhancedTableHeadProps> = (props) => {
	const {
		classes,
		order,
		orderBy,
		onRequestSort,
		showAdd,
		onAdd,
		dataModel,
		actions,
		loading,
	} = props;

	//return a function that will be called when the user clicks on the header. Makes it possible to use useCallback even when it is meant to take an argument

	const handleSorting = useCallback((property) => () => onRequestSort(property), [onRequestSort])

	return (
		<>
			{dataModel && (
				<TableHead>
					<TableRow>
						{dataModel.headers.map((header, index) => (
							<TableCell
								key={`${header.title} ${index}`}
								align={header.align}
								sortDirection={orderBy === header.title ? order : false}>
								{header.canSort === false ? <strong>{header.title}</strong> : (
									<TableSortLabel
										active={orderBy === header.title}
										direction={orderBy === header.title ? order : "asc"}
										onClick={handleSorting(header.title)}>
										<strong>{header.title}</strong>
										{orderBy === header.title ? (
											<span className={classes.visuallyHidden}>
												{order === "desc"
													? "sorted descending"
													: "sorted ascending"}
											</span>
										) : null}
									</TableSortLabel>
								)}
							</TableCell>
						))}
						<TableCell align="right" style={{ opacity: loading ? 0.5 : 1 }}>
							{actions?.map((btn, index) => (
								<React.Fragment key={index}>{btn}</React.Fragment>
							))}
							{showAdd && (
								<Tooltip arrow title="Add" placement="bottom">
									<Button
										color="primary"
										className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
										onClick={onAdd}
										disableFocusRipple={true}>
										<FaPlus />
									</Button>
								</Tooltip>
							)}
						</TableCell>
					</TableRow>
				</TableHead>
			)}
		</>
	);
};

export default memo(EnhancedTableHead);
