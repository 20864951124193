import React, { useState, useEffect, useCallback } from "react";
import ImageUploader, { Image } from "@/components/ImageUploader/ImageUploader";
import FileUploader from "@/components/FileUploader/FileUploader";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import DocumentTemplateAddDialog from "@/components/Modal/DocumentTemplateAddDialog";
import {
    Button,
    Card,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    List,
    ListItem,
    FormControlLabel,
    Switch,
    IconButton,
    FormLabel,
    InputAdornment,
    Tooltip,
    CircularProgress,
} from "@mui/material";
import { Alert } from "@mui/lab";
import {
    FaSave,
    FaTrashAlt,
    FaBackspace,
    FaPlus,
    FaEye,
    FaEyeSlash,
} from "react-icons/fa";
import useHistory from '@/hooks/useHistory';
import { connect } from "react-redux";
import {
    handleLotAdd,
    handleLotUpdate,
    clearApiResponses,
    clearAuction,
    clearLot,
    handleRetrieveAllImagesByLotId,
    handleImageDelete,
    clearImages,
    handleLotFetch,
    handleSetImages,
    clearLotTypeId,
    handleLotVideoDelete,
} from "./store/Lots";
import {
    handleRegistrationDocumentDelete,
    handleRetrieveRegistrationDocuments,
    clearDocuments,
} from "@/pages/Documents/store/Documents";
import { handleLotTypesFetch } from "../LotTypes/store/LotTypes";
import AddIcon from "@mui/icons-material/Add";
import LotTypeDialog from "@/components/Modal/LotTypeDialog";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import {
    hideProgressSpinner,
    showProgressSpinner,
} from "@/helpers/ProgressSpinnerService";
import { handleContactLookupFetch } from "../Contacts/store/Contacts";
import ContactAddDialog from "@/components/Modal/ContactAddDialog";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { DocumentTypeEnum } from "@/helpers/DocumentTypeEnum";
import ns from "@/helpers/NotificationService";
import { handleLotEditAddAuctionFetch } from "@/pages/Auctions/store/Auctions";
import { forEach, toInteger } from "lodash";
import {
    validateYoutubeUrl,
    validateMultipleWordsInString,
    formatDateTime,
} from "../../helpers/Utils";
import AddressSearchField from "@/components/AddressSearchField/AddressSearchField";
import Chip from "@/components/Chip/Chip";
import { handleManualSync } from "@/pages/Integration/store/Integration";
import { AuctionTypeEnum } from "@/helpers/AuctionTypeEnum";
import GoogleMapContainer from "../../components/GoogleMapContainer/GoogleMapContainer";
import { downloadFile } from "@/pages/Documents/store/Documents";
import { handleFetchBiddingCalculationsLookup } from '../BiddingCalculations/store/BiddingCalculations';
import BiddingCalculationsAddDialog from '../../components/Modal/BiddingCalculationsAddDialog';
import IncrementTableAddDialog from '../../components/Modal/IncrementTableAddDialog';
import { handleIncrementTablesLookupFetch } from '../IncrementTable/store/IncrementTable';
import TextField from "../../components/TextField";
import { zeroToHundredPattern } from "../../helpers/Regex";
import { combineLatestInit } from "rxjs/internal/observable/combineLatest";

interface Props {
    handleLotAdd_d: (
        formFields: any,
        additionalData: any,
        auctionId: string,
        images: any[],
        videos: any[],
        documents: any[],
        auction: any,
        onCompletedCallback: (res: any) => void
    ) => void;
    handleLotUpdate_d: (
        formFields: any,
        additionalData: any,
        auctionId: string,
        images: any[],
        videos: any[],
        documents: any[],
        auction: any,
        onCompletedCallback: (res: any) => void
    ) => void;
    handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) => void;
    handleRetrieveAllImagesByLotId_d: (lotId: string) => void;
    handleDocumentDelete_d: (documentId: string) => void;
    handleRetrieveRegistrationDocuments_d: (
        auctionId: string,
        lotId: string
    ) => void;
    handleImageDelete_d: (imageId: string) => void;
    handleLotVideoDelete_d: (
        videoId: string,
        onCompletedCallback: (res: any) => void
    ) => void;
    handleSetImages_d: (images: any[]) => void;
    handleLotEditAddAuctionFetch_d: (id: string) => void;
    handleManualSync_d: (
        lotId: string,
        onCompletedCallback: (res: any) => void
    ) => void;
    clearLot_d: () => void;
    clearLotTypeId_d: () => void;
    clearAuction_d: () => void;
    clearResponses_d: () => void;
    clearImages_d: () => void;
    handleLotFetch_d: (
        lotId: string,
        onCompletedCallback: (res: any) => void
    ) => void;
    handleContactsFetch_d: (
        pagination: any,
        onCompletedCallback?: (res: any) => void
    ) => void;
    clearDocuments_d: () => void;
    error: boolean;
    errorText: string;
    lotImages: Image[];
    documents: any;
    lot: any;
    lotId: string;
    auction: any;
    lotTypes: any;
    selectLotTypeLots: any;
    loading: boolean;
    settings: any;
    contactsState: any;
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
    biddingCalculationsLookup: any[],
    retrieveBiddingCalculationsLookup_d: (onCompletedCallback: (res: any) => void) => void,
    incrementTableLookup: any[],
    retrieveIncrementTableLookup_d: (onCompletedCallback: (res: any) => void) => void,
}

const initialState = {
    id: "",
    name: "",
    description: "",
    auction: "",
    lotType: "",
    contact: "",
    biddingCalculationId: "",
    incrementTableId: "",
    reservePrice: 0,
    guidePrice: 0,
    depositAmount: 0,
    defaultMinimumIncrement: 0,
    startingPrice: 0,
    skuNumber: "",
    lotNumber: "",
    isActive: true,
    hours: 0,
    minutes: 0,
    seconds: 0,
    stcStatus: true,
    showReserve: true,
    showPaddleNumber: true,
    watermarkImages: false,
    commission: "0",
    _tempLocationString: "",
    locationSearch: "",
    locationVisible: true,
    locationLatitude: "",
    locationLongitude: "",
    biddingCalculationsLookup: [],
    incrementTableLookup: [],
    timeWindowHours: 0,
    timeWindowMinutes: 0,
    timeWindowSeconds: 0,
};

const formFieldsReducer = (state, action) => {
    switch (action.type) {
        case "update":
            return { ...state, ...action.data };
        case "clear":
            return { ...initialState };
        default:
            throw new Error();
    }
};

const LotsEditAdd: React.FC<Props> = (props) => {
    const history = useHistory();

    const {
        handleLotAdd_d,
        handleLotUpdate_d,
        handleLotTypesFetch_d,
        clearResponses_d,
        clearLot_d,
        clearLotTypeId_d,
        clearAuction_d,
        clearImages_d,
        handleLotFetch_d,
        handleSetImages_d,
        handleRetrieveAllImagesByLotId_d,
        handleDocumentDelete_d,
        handleRetrieveRegistrationDocuments_d,
        handleImageDelete_d,
        handleLotVideoDelete_d,
        handleContactsFetch_d,
        handleLotEditAddAuctionFetch_d,
        clearDocuments_d,
        error,
        errorText,
        lot,
        lotId,
        lotImages,
        documents,
        auction,
        lotTypes,
        selectLotTypeLots,
        loading,
        settings,
        contactsState,
        handleManualSync_d,
        downloadFile_d,
        biddingCalculationsLookup,
        retrieveBiddingCalculationsLookup_d,
        incrementTableLookup,
        retrieveIncrementTableLookup_d,
    } = props;

    const [loadingStates, setLoadingStates] = useState({
        venues: true,
        lotTypes: true,
        contacts: true,
        biddingCalculations: true,
        incrementTables: true
    })

    const [documentTemplateModal, setDocumentTemplateModal] = React.useState<any>(
        {
            open: false,
            id: "",
        }
    );

    const [synchronizingManully, setSynchronizingManully] =
        useState<boolean>(false);

    const [additionalData, setAdditionalData] = React.useState<any[]>([]);
    const [hideFieldsForAuctionType, setHideFieldsForAuctionType] =
        React.useState(false);
    const [formFields, setFormFields] = React.useReducer(
        formFieldsReducer,
        initialState
    );
    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] =
        React.useState<any>({
            name: false,
            description: false,
            lotType: false,
            contact: false,
            number: false,
            heroImage: false,
            lotImage: false,
            locationSearch: false,
            locationLatitude: false,
            locationLongitude: false,
        });
    const [lotInformationTabError, setLotInformationTabError] =
        React.useState<boolean>(false);
    const [imagesTabError, setImagesTabError] = React.useState<boolean>(false);
    const [lotVideosTabError, setLotVideosTabError] =
        React.useState<boolean>(false);
    const [showCreateAnother, setShowCreateAnother] = React.useState(false);
    const [heroFiles, setHeroFiles] = React.useState([] as Image[]);
    const [lotFiles, setLotFiles] = React.useState([] as Image[]);
    const [activeTab, setActiveTab] = React.useState("0");
    const [lotDocuments, setLotDocuments] = React.useState<any>([] as any);
    const [lotVideos, setLotVideos] = React.useState<any>([] as any);
    const [isLotTypeOpen, setIsLotTypeOpen] = useState(false);
    const [isContactAddOpen, setIsContactAddOpen] = React.useState(false);
    const [isBiddingCalculationsAddOpen, setIsBiddingCalculationsAddOpen] = React.useState(false);
    const [isIncrementTableAddOpen, setIsIncrementTableAddOpen] = React.useState(false);

    const [params, setParams] = useState<any>({
        lotIdParam: undefined,
        auctionIdParam: undefined,
    });

    const [imagesToBeDeleted, setImagesToBeDeleted] = useState<any[]>([]);
    const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<any[]>([]);

    const [mapLoaded, setMapLoaded] = useState<boolean>(true);

    const [commissionValid, setCommissionValid] = useState(false);

    useEffect(() => {
        return () => {
            clearAuction_d();
            clearLot_d();
        };
    }, []);

    useEffect(() => {
        if (!mapLoaded) setMapLoaded(true);
    }, [mapLoaded]);

    useEffect(() => {
        const lotIdParam: any = new URLSearchParams(history.location.search).get("lotId");
        const auctionIdParam: any = new URLSearchParams(history.location.search).get(
            "auctionId"
        );
        const backRedirect = new URLSearchParams(history.location.search).get(
            "backRedirect"
        );

        setParams({ lotIdParam, auctionIdParam, backRedirect });
    }, [history.location.search]);

    React.useEffect(() => {
        if (selectLotTypeLots) {
            setFormFields({ type: "update", data: { lotType: selectLotTypeLots } });
        }
    }, [selectLotTypeLots]);

    React.useEffect(() => {
        handleLotTypesFetch_d(() => {
            setLoadingStates(state => ({ ...state, lotTypes: false }));
        });
        handleContactsFetch_d({ ...contactsState.pagination, pageSize: -1 }, () => {
            setLoadingStates(state => ({ ...state, contacts: false }));
        });
        retrieveBiddingCalculationsLookup_d(() => {
            setLoadingStates(state => ({ ...state, biddingCalculations: false }));
        });
        retrieveIncrementTableLookup_d(() => {
            setLoadingStates(state => ({ ...state, incrementTables: false }));
        });

        clearAllStates(false);

        if (history.location) {
            if (params.lotIdParam && params.lotIdParam !== "") {
                showProgressSpinner({
                    description: "Retrieving " + settings.LotName + "...",
                });
                handleLotFetch_d(params.lotIdParam, (res) => {
                    hideProgressSpinner();
                    setShowCreateAnother(true);
                });
            }
        }
    }, [params]);

    React.useEffect(() => {
        if (lotTypes) {
            hideProgressSpinner();

            if (formFields.lotType && formFields.lotType !== "") {
                parseAndSetAdditionalFields(formFields.lotType, true);
            }
        }
    }, [lotTypes]);

    React.useEffect(() => {
        if (params.auctionIdParam) {
            if (auction) {
                hideFieldsForAuctionTypeFunc(auction.auctionType);
                const auctionData = copyAuctionDefaults();
                setFormFields({ type: "update", data: auctionData });
            }
            if (!auction && params.auctionIdParam) {
                handleLotEditAddAuctionFetch_d(params.auctionIdParam);
            }
        } else if (!auction && lot?.auctionId) {
            handleLotEditAddAuctionFetch_d(lot.auctionId);
        }
    }, [auction, params.auctionIdParam, lot]);

    React.useEffect(() => {
        if (lot) {
            const time = getDefaultTimes(lot.defaultBidExtension);
            const timeWindow = getDefaultTimes(lot?.defaultBidExtensionWindow);

            setFormFields({
                type: "update",
                data: {
                    id: lot.id,
                    name: lot.name,
                    description: lot.description,
                    auction: lot.auctionId,
                    lotType: lot.lotTypeId,
                    contact: lot.contactId,
                    biddingCalculationId: lot.biddingCalculationId,
                    incrementTableId: lot.incrementTableId,
                    number: lot.number,
                    reservePrice: lot.reservePrice ? lot.reservePrice : 0,
                    guidePrice: lot.guidePrice ? lot.guidePrice : 0,
                    depositAmount:
                        params?.auctionIdParam && auction ? auction.depositAmount : 0,
                    defaultMinimumIncrement: lot.defaultMinimumIncrement
                        ? lot.defaultMinimumIncrement
                        : 0,
                    startingPrice: lot.startingPrice ? lot.startingPrice : 0,
                    skuNumber: lot.skuNumber,
                    lotNumber: lot.number,
                    isActive: lot.isActive,
                    hours: time.hours,
                    minutes: time.minutes,
                    seconds: time.seconds,
                    stcStatus: Number(lot.stcStatus) === 0 ? false : true,
                    showReserve: lot.showReserve,
                    showPaddleNumber: lot.showPaddleNumber,
                    watermarkImages: lot.watermarkImages,
                    commission: lot.commission ? lot.commission : 0,
                    _tempLocationString: lot.locationSearch || "",
                    locationSearch: lot.locationSearch || "",
                    locationVisible: lot.locationVisible,
                    locationLatitude: lot.locationLatitude || "",
                    locationLongitude: lot.locationLongitude || "",
                    timeWindowHours: timeWindow?.hours ?? 0,
                    timeWindowMinutes: timeWindow?.minutes ?? 0,
                    timeWindowSeconds: timeWindow?.seconds ?? 0
                },
            });

            if (lot.lotVideos) {
                const newVideoData: any = [];

                for (const [key, value] of Object.entries<any>(lot.lotVideos)) {
                    const newFields: any = {
                        id: value.id,
                        videoUrl: value.videoUrl,
                        orderInt: value.orderInt,
                        lotId: value.lotId,
                    };
                    newVideoData.push(newFields);
                }

                setLotVideos(newVideoData);
            }

            handleRetrieveAllImagesByLotId_d(lot.id);
            handleRetrieveRegistrationDocuments_d(lot.auctionId, lot.id);

            parseAndSetAdditionalFields(lot.lotTypeId, true);
        }
    }, [lot]);

    React.useEffect(() => {
        if (documents !== undefined) {
            const newDocumentsData: any = documents.map((doc) => {
                return {
                    documentId: doc.id,
                    documentUrl:
                        doc.documentUrl === undefined || doc.documentUrl === null
                            ? ""
                            : doc.documentUrl,
                    documentTemplateId: doc.documentTemplate.id,
                    documentTemplateName: doc.documentTemplate.name,
                    documentTemplate: doc.documentTemplate,
                    fileUploadData: "",
                };
            });

            setLotDocuments(newDocumentsData);
        }
    }, [documents]);

    React.useEffect(() => {
        if (lotId && lotId !== "") {
            if (formFields) formFields["id"] = lotId;

            navAddEdit(lotId);
        }
    }, [lotId]);

    React.useEffect(() => {
        if (error) {
            clearResponses_d();
            ns.error(errorText);
            hideProgressSpinner();

            const unsavedImages = getConsolidatedImages();
            handleSetImages_d(unsavedImages);
        }
    }, [error]);

    const getDefaultTimes = (seconds: number) => {
        const time = {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        time.hours = ~~(seconds / 3600);
        time.minutes = ~~((seconds % 3600) / 60);
        time.seconds = ~~seconds % 60;

        return time;
    };

    //if there is an error from the http all to the API like on save or something, the images are in local state but the reducer lotImages prop is clear which is what the ImageUploader relies on.
    // So if error, set lotImages with unsaved files to display them on the UI so the user doesnt think it lost images
    const getConsolidatedImages = () => {
        const localHeroImages = heroFiles.filter((f) => f.isDisplayImage);
        const localLotImages = lotFiles.filter((f) => !f.isDisplayImage);

        return localHeroImages.concat(localLotImages);
    };

    const hideFieldsForAuctionTypeFunc = (selectedAuctionType: number) => {
        if (
            Number(selectedAuctionType) ===
            Number(AuctionTypeEnum.Tender.toString()) ||
            Number(selectedAuctionType) ===
            Number(AuctionTypeEnum.Streamed.toString())
        ) {
            setHideFieldsForAuctionType(true);
        } else {
            setHideFieldsForAuctionType(false);
        }
    };

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const navAddEdit = (lotId) => {
        let path = "/LotEdit?";
        const query: any[] = [];

        if (params.backRedirect) query.push(`backRedirect=${params.backRedirect}`);

        if (params?.auctionIdParam || auction?.id)
            query.push("auctionId=" + params?.auctionIdParam || auction.id);

        if (lotId && lotId !== "") {
            query.push("lotId=" + lotId);
        }

        let finalPath = path + (query.length > 0 ? query.join("&") : "");
        history.replace(finalPath);
    };

    const onChange = (e: { target: { id: any; value: string } }) => {
        setFormFields({ type: "update", data: { [e.target.id]: e.target.value } });
    };

    const onChangeVideoUrl = (value, index) => {
        let tempList = [...lotVideos];

        if (index > -1) tempList[index].videoUrl = value;

        setLotVideos(tempList);
    };

    const onChangeCommission = (e: any) => {
        if (e.target.value !== "") {
            var numericValue = Number(e.target.value);
            var valid = numericValue >= 0 && numericValue <= 100;

            if (valid)
                setFormFields({
                    type: "update",
                    data: { [e.target.id]: e.target.value },
                });
            else
                setFormFields({
                    type: "update",
                    data: { [e.target.id]: formFields.commission },
                });
        } else {
            setFormFields({ type: "update", data: { [e.target.id]: "" } });
        }
    };

    const onChangeNumeric = (e: any) => {
        if (e.target.value !== "") {
            var numericValue = Number(e.target.value);
            var type = e.target.id;
            var valid = false;

            switch (type) {
                case "timeWindowHours":
                case "hours":
                    valid = numericValue > -1 && numericValue <= 72;
                    break;
                case "timeWindowMinutes":
                case "minutes":
                    valid = numericValue > -1 && numericValue <= 59;
                    break;
                case "timeWindowSeconds":
                case "seconds":
                    valid = numericValue > -1 && numericValue <= 59;
                    break;
            }

            if (valid)
                setFormFields({
                    type: "update",
                    data: { [e.target.id]: e.target.value },
                });
            else setFormFields({ type: "update", data: { [e.target.id]: 0 } });
        }
    };

    const onLotTypeChange = (e: any) => {
        parseAndSetAdditionalFields(e.target.value, false);
        setFormFields({ type: "update", data: { ["lotType"]: e.target.value } });
    };

    const onSelectChange = (e: { target: { name: any; value: string } }) => {
        setFormFields({
            type: "update",
            data: { [e.target.name]: e.target.value },
        });
    };

    const onIncrementChange = (e: { target: { id: any; value: any } }) => {
        setFormFields({ type: "update", data: { [e.target.id]: e.target.value } });

        if (e.target.value && e.target.value > 0) {
            setFormFields({
                type: "update",
                data: { 'incrementTableId': '' },
            });
        }
    }

    const onIncrementTableSelectChange = (e: { target: { name: any; value: string } }) => {
        setFormFields({
            type: "update",
            data: { [e.target.name]: e.target.value },
        });

        if (e.target.value) {
            setFormFields({
                type: "update",
                data: { 'defaultMinimumIncrement': 0 },
            });
        }
    };

    const handleCloseBiddingCalculationsDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Bidding Calculations..." });
            retrieveBiddingCalculationsLookup_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'biddingCalculationId', value: data });
                hideProgressSpinner();
            })
            setIsBiddingCalculationsAddOpen(false)
        }
        else setIsBiddingCalculationsAddOpen(false);
    }

    const handleCloseIncrementTableDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Increment Table..." });
            retrieveIncrementTableLookup_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'incrementTableId', value: data });
                hideProgressSpinner();
            })
            setIsIncrementTableAddOpen(false)
        }
        else setIsIncrementTableAddOpen(false);
    }

    const updateFieldChanged = (
        id: any,
        name: any = undefined,
        value: any = undefined
    ) => {
        let tempAdditionalData = [...additionalData];
        let fieldIndex = tempAdditionalData.findIndex((x) => x.id === id);
        if (fieldIndex > -1) {
            let field = { ...tempAdditionalData[fieldIndex] };
            if (name || name === "") field.name = name;
            if (value || value === "") field.value = value;
            tempAdditionalData.splice(fieldIndex, 1, field);
            setAdditionalData(tempAdditionalData);
        }
    };

    const onCancel = () => {
        clearDocuments_d();
        history.goBack();
    };

    const auctionNavAddEdit = (auctionId: string, tabIndex: number) => {
        if (params.backRedirect) history.push(`/${params.backRedirect}`);
        else {
            let path = "/AuctionEdit";
            if (auctionId !== "") {
                path += "?auctionId=" + auctionId;
                if (tabIndex !== undefined && tabIndex !== null)
                    path += "&tabIndex=" + tabIndex;

                history.push(path);
            } else if (tabIndex !== undefined && tabIndex !== null) {
                path += "?tabIndex=" + tabIndex;
                history.push(path);
            }
        }
    };

    const clearAllStates = (OnTheFlyLotAdd) => {
        setLotInformationTabError(false);
        setImagesTabError(false);
        setLotVideosTabError(false);
        setActiveTab("0");
        setIsLotTypeOpen(false);

        setAdditionalData([] as any);
        setHeroFiles([] as Image[]);
        setLotFiles([] as Image[]);
        setLotDocuments([] as any);
        setLotVideos([] as any);

        clearLotTypeId_d();
        clearLot_d();
        clearImages_d();
        clearDocuments_d();
        clearResponses_d();

        setFormFieldsValidationShowErrors({
            name: false,
            description: false,
            lotType: false,
            contact: false,
            number: false,
            heroImage: false,
            lotImage: false,
            locationSearch: false,
            locationLatitude: false,
            locationLongitude: false,
        });

        if (OnTheFlyLotAdd) {
            resetFormFields();
            setShowCreateAnother(false);
        }
    };

    const resetFormFields = () => {
        const auctionDetails = copyAuctionDefaults();
        let newState = { ...initialState, ...auctionDetails };
        parseAndSetAdditionalFields(newState.lotType, false);
        setFormFields({ type: "update", data: newState });
        setMapLoaded(false);
    };

    const copyAuctionDefaults = () => {
        if (auction !== undefined) {
            return {
                auction: auction,
                depositAmount: auction.depositAmount,
                defaultMinimumIncrement: auction.increment,
                stcStatus: Number(auction.stcStatus) === 0 ? false : true,
                showReserve: auction.defaultShowReserve,
                showPaddleNumber: auction.defaultShowPaddleNumber,
                watermarkImages: auction.defaultWatermarkImages,
                commission: auction.commission,
                hours: auction.hours,
                minutes: auction.minutes,
                seconds: auction.seconds,
                lotType: auction.lotTypeId,
                contact: auction.contactId,
                biddingCalculationId: auction.defaultBiddingCalculationId,
                incrementTableId: auction.defaultIncrementTableId,
                timeWindowHours: auction.timeWindowHours,
                timeWindowMinutes: auction.timeWindowMinutes,
                timeWindowSeconds: auction.timeWindowSeconds
            };
        }
        else return {};
    };

    const onCreate = () => {
        clearAllStates(true);
        navAddEdit("");
    };

    const onSubmit = () => {
        const commValid = validateCommission();
        const isFormValid = validateAll();
        const isVideoUrlsValid = validateYoutubeUrls();
        const incrementsValid = validateIncrements();

        if (commValid === false) {
            if (isFormValid) {
                if (isVideoUrlsValid) {
                    if (incrementsValid) {
                        showProgressSpinner({
                            description: "Saving " + settings.LotName + "...",
                        });

                        let temp = [...imagesToBeDeleted];
                        forEach(imagesToBeDeleted, (image) => {
                            if (image && image !== "") {
                                handleImageDelete_d(image);
                            }
                            temp.shift();
                        });
                        setImagesToBeDeleted(temp);

                        temp = [...documentsToBeDeleted];
                        forEach(documentsToBeDeleted, (document) => {
                            if (document && document !== "") {
                                handleDocumentDelete_d(document);
                            }
                            temp.shift();
                        });
                        setDocumentsToBeDeleted(temp);

                        const unsavedImages = getConsolidatedImages();

                        let tempAdditionalData = additionalData.map((x) => x);
                        if (formFields.id === "") {
                            handleLotAdd_d(
                                formFields,
                                tempAdditionalData,
                                params.auctionIdParam,
                                unsavedImages,
                                lotVideos,
                                lotDocuments,
                                auction,
                                (res) => {
                                    if (res.success) {
                                        ns.success(settings.LotName + " successfully added");
                                        let redirect = `/LotEdit?lotId=${res.response}`;
                                        if (params.auctionIdParam)
                                            redirect += "&auctionId=" + params.auctionIdParam;
                                        history.replace(redirect);
                                    } else {
                                        ns.error(settings.LotName + res.error);
                                        hideProgressSpinner();
                                    }
                                }
                            );
                        } else {
                            handleLotUpdate_d(
                                formFields,
                                tempAdditionalData,
                                formFields.auction,
                                unsavedImages,
                                lotVideos,
                                lotDocuments,
                                auction,
                                (res) => {
                                    if (res.success) {
                                        clearResponses_d();
                                        ns.success(settings.LotName + " successfully updated");
                                        setShowCreateAnother(true);
                                        handleLotFetch_d(res.response, (res) => {
                                            hideProgressSpinner();
                                        });
                                    } else {
                                        ns.error(settings.LotName + res.error);
                                        hideProgressSpinner();
                                    }
                                }
                            );
                        }
                    } else {
                        if (!incrementsValid) {
                            ns.error(`Please ensure to either use a default increment table or specify an actual default increment`);
                        }
                    }
                } else {
                    hideProgressSpinner();
                    ns.error("Please make sure that all YouTube video URL's are valid");
                }
            } else {
                hideProgressSpinner();
                ns.error("Please fill in all required fields");
            }
        } else {
            hideProgressSpinner();
            ns.error("Please ensure to capture an appropriate buyer's commission");
        }

    };

    const parseAndSetAdditionalFields = (
        lotTypeId: string,
        loadPreviousValues: boolean,
        seedList?: any[]
    ) => {
        let tempAdditionalProperties: any[] = [];

        if (loadPreviousValues) {
            if (lot?.dataCaptured && lot.dataCaptured.additionalProperties) {
                tempAdditionalProperties = [...lot.dataCaptured.additionalProperties];
            }
        }

        const lotTypeObject = (seedList ?? lotTypes).find(
            (lotType) => lotType.id === lotTypeId
        );

        if (lotTypeObject !== undefined) {
            let tempList =
                lotTypeObject?.dataCaptured?.defaultAdditionalProperties || [];

            tempList.map((field) => {
                const existingProperty = tempAdditionalProperties.find(
                    (existing) => existing.id === field.id
                );
                if (!existingProperty) {
                    const newField: any = {
                        ...field,
                        order: tempAdditionalProperties.length,
                    };
                    tempAdditionalProperties.push(newField);
                }
            });

            tempAdditionalProperties.sort((a, b) => a.order - b.order);

            setAdditionalData(tempAdditionalProperties);
        }
    };

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean } }) => {
        setFormFields({
            type: "update",
            data: { [e.target.id]: e.target.checked },
        });
    };

    const newLotImageFilesAdded = (images: Image[]) => {
        setLotFiles(images);
    };

    const newHeroImageFilesAdded = (images: Image[]) => {
        setHeroFiles(images);
    };

    const deleteImage = (imageId: string) => {
        let temp = [...imagesToBeDeleted];
        temp.push(imageId);
        setImagesToBeDeleted(temp);
    };

    const deleteDocument = (documentId: string) => {
        let temp = [...documentsToBeDeleted];
        if (documentId && documentId !== "") temp.push(documentId);
        setDocumentsToBeDeleted(temp);
    };

    const handleVideoUpClicked = (index: any) => {
        let list = [...lotVideos];
        let current = { ...list[index] };
        let previous = { ...list[index - 1] };
        current.orderInt = index - 1;
        previous.orderInt = index;
        list.splice(index - 1, 1, current);
        list.splice(index, 1, previous);
        list.sort((a, b) => a.orderInt - b.orderInt);

        setLotVideos(list);
    };

    const handleVideoDownClicked = (index: any) => {
        let list = [...lotVideos];
        let current = { ...list[index] };
        let next = { ...list[index + 1] };
        current.orderInt = index + 1;
        next.orderInt = index;
        list.splice(index, 1, next);
        list.splice(index + 1, 1, current);
        list.sort((a, b) => a.orderInt - b.orderInt);

        setLotVideos(list);
    };

    const handleVideoDeleteClicked = (index: any) => {
        let mustRemove = true;

        if (lotVideos[index].id) {
            handleLotVideoDelete_d(lotVideos[index].id, (res) => {
                if (!res.success) {
                    ns.error("Failed to delete the video");
                    mustRemove = false;
                }
            });
        }

        if (mustRemove) {
            let list = [...lotVideos];
            let current = { ...list[index] };
            list.splice(index, 1);

            setLotVideos(list);
        }
    };

    const handleUpClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        let previous = { ...list[index - 1] };
        current.order = index - 1;
        previous.order = index;
        list.splice(index - 1, 1, current);
        list.splice(index, 1, previous);
        list.sort((a, b) => a.order - b.order);
        setAdditionalData(list);
    };

    const handleDownClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        let next = { ...list[index + 1] };
        current.order = index + 1;
        next.order = index;
        list.splice(index, 1, next);
        list.splice(index + 1, 1, current);
        list.sort((a, b) => a.order - b.order);
        setAdditionalData(list);
    };

    const handleHideClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        current.hidden = true;
        list.splice(index, 1, current);
        setAdditionalData(list);
    };

    const handleDeleteClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };

        if (current.deletable) {
            list.splice(index, 1);
            setAdditionalData(list);
        }
    };

    const handleUnhideClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        current.hidden = false;
        list.splice(index, 1, current);
        setAdditionalData(list);
    };

    const handleAddNewFieldClicked = (index: any = undefined) => {
        let field: any = { id: uuidv4(), name: "", value: "", deletable: true };

        if (index > -1) {
            let newState = [...additionalData];

            field.order = index;
            newState.splice(index + 1, 0, field);

            for (let i = index + 1; i < newState.length; i++) {
                let tempField = { ...newState[i] };
                tempField.order += 1;
                newState.splice(i, 1, tempField);
            }

            setAdditionalData(newState);
        } else {
            field.order = additionalData.length;
            setAdditionalData([...additionalData, field]);
        }
    };

    const onCancelDocumentTemplateModal = () => {
        setDocumentTemplateModal({ ...documentTemplateModal, open: false });
    };

    const onShowDocumentTemplateModal = () => {
        setDocumentTemplateModal({ ...documentTemplateModal, open: true });
    };

    const onAddDocumentTemplateModal = (selectedDocumentTemplates) => {
        let addingDocuments = selectedDocumentTemplates.map((doc) => {
            return {
                documentId: "",
                documentUrl: "",
                documentTemplateId: doc.id,
                documentTemplateName: doc.name,
                documentTemplate: doc.documentTemplate,
                fileUploadData: "",
            };
        });

        setLotDocuments([...lotDocuments, ...addingDocuments]);
        onCancelDocumentTemplateModal();
    };

    const onAddLotVideo = () => {
        const newVideoData: any = [];
        var currentOrder = -1;

        for (const [key, value] of Object.entries<any>(lotVideos)) {
            currentOrder++;

            const newFields: any = {
                id: value.id,
                videoUrl: value.videoUrl,
                orderInt: currentOrder,
                lotId: params.lotIdParam,
            };
            newVideoData.push(newFields);
        }

        currentOrder++;
        const newFields: any = {
            id: null,
            videoUrl: "",
            orderInt: currentOrder,
            lotId: params.lotIdParam,
        };
        newVideoData.push(newFields);

        setLotVideos(newVideoData);
    };

    const mapRow = (row: any, index: any, length: number) => {
        let mappedRow;
        mappedRow = (
            <TableRow key={index}>
                <TableCell component="th" scope="row">
                    <TextField
                        disabled={row.isIntegrationProperty}
                        value={row.name}
                        onChange={(e) => updateFieldChanged(row.id, e?.target?.value || "")}
                        name="name"
                        label="Field"
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        disabled={row.isIntegrationProperty}
                        value={row.value}
                        onChange={(e) =>
                            updateFieldChanged(row.id, undefined, e?.target?.value || "")
                        }
                        name="value"
                        label="Value"
                    />
                </TableCell>
                <TableCell align="right">
                    {index > 0 && (
                        <Button
                            onClick={() => handleUpClicked(index)}
                            className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        >
                            <MdArrowUpward />
                        </Button>
                    )}
                    {index < length - 1 && (
                        <Button
                            onClick={() => handleDownClicked(index)}
                            className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        >
                            <MdArrowDownward />
                        </Button>
                    )}
                    {!row.hidden && (
                        <Tooltip arrow title="Hide field" placement="top">
                            <Button
                                onClick={() => handleHideClicked(index)}
                                className="btn-danger text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            >
                                <FaEyeSlash />
                            </Button>
                        </Tooltip>
                    )}
                    {row.hidden && (
                        <Tooltip arrow title="Show field" placement="top">
                            <Button
                                onClick={() => handleUnhideClicked(index)}
                                className="btn-success text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            >
                                <FaEye />
                            </Button>
                        </Tooltip>
                    )}
                    { }
                    <Tooltip arrow title="Add" placement="bottom">
                        <Button
                            className="btn-neutral-success mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            onClick={() => handleAddNewFieldClicked(index)}
                        >
                            <FaPlus className="font-size-sm" />
                        </Button>
                    </Tooltip>
                    {row.deletable && (
                        <Tooltip arrow title="Delete" placement="bottom">
                            <Button
                                className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => handleDeleteClicked(index)}
                            >
                                <FaTrashAlt className="font-size-sm" />
                            </Button>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        );
        return mappedRow;
    };

    const handleViewDocument = (url: string) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
        })
    }

    const mapLotDocumentRow = (row: any, index: any) => {
        let mappedRow;
        mappedRow = (
            <TableRow key={index + ""}>
                <TableCell hidden component="th" scope="row">
                    {row.documentId}
                </TableCell>
                <TableCell hidden component="th" scope="row">
                    {row.documentTemplateId}
                </TableCell>
                <TableCell scope="row">{row.documentTemplateName}</TableCell>
                <TableCell scope="row">
                    {row.documentUrl !== undefined &&
                        row.documentUrl !== null &&
                        row.documentUrl.length > 0 && (
                            <a
                                className="text-info"
                                href={undefined}
                                onClick={() => handleViewDocument(row.documentUrl)}
                                style={{
                                    textDecoration: 'underline', cursor: 'pointer'
                                }}
                            >View</a>
                        )}
                </TableCell>
                <TableCell scope="row">
                    {row.documentTemplate !== undefined &&
                        (!row.documentTemplate.dataCaptured.lot.isUploadMandatory ||
                            row.documentTemplate.dataCaptured.lot.isRegistrationDocument) ? (
                        <FileUploader
                            uploadedFile={row.fileUploadData}
                            allowedToUpload={true}
                            buttonFileUploadedText={"Attached"}
                            acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                            onFileUpload={(file) => handleFileUpload(file, index)}
                            onDelete={() => handleDeleteDocument(index)}
                            maxImageSize={10240}
                            maxFileSize={10240}
                        />
                    ) : (
                        <FileUploader
                            uploadedFile={row.fileUploadData}
                            allowedToUpload={false}
                            buttonFileUploadedText={"Attached"}
                            acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                            onFileUpload={(file) => handleFileUpload(file, index)}
                            onDelete={() => handleDeleteDocument(index)}
                            maxImageSize={10240}
                            maxFileSize={10240}
                        />
                    )}
                </TableCell>
            </TableRow>
        );
        return mappedRow;
    };

    const mapLotVideoRow = (row: any, index: any, length: number) => {
        let mappedRow;
        mappedRow = (
            <TableRow key={index + ""}>
                <TableCell hidden component="th" scope="row">
                    {row.videoId}
                </TableCell>
                <TableCell scope="row">
                    <TextField
                        onChange={(e) => onChangeVideoUrl(e.target.value, index)}
                        id={index + ""}
                        value={row.videoUrl}
                        required
                        label="YouTube URL"
                    />
                </TableCell>
                <TableCell align="right">
                    {index > 0 && (
                        <Button
                            onClick={() => handleVideoUpClicked(index)}
                            className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        >
                            <MdArrowUpward />
                        </Button>
                    )}
                    {index < length - 1 && (
                        <Button
                            onClick={() => handleVideoDownClicked(index)}
                            className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        >
                            <MdArrowDownward />
                        </Button>
                    )}
                    <Tooltip arrow title="Delete" placement="bottom">
                        <Button
                            className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            onClick={() => handleVideoDeleteClicked(index)}
                        >
                            <FaTrashAlt className="font-size-sm" />
                        </Button>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
        return mappedRow;
    };

    const handleDeleteDocument = (rowIndex) => {
        let tempDoc = { ...lotDocuments[rowIndex] };

        if (tempDoc.documentId) {
            deleteDocument(tempDoc.documentId);
        }

        let tempList = [...lotDocuments];
        tempList.splice(rowIndex, 1);
        setLotDocuments(tempList);
    };

    const handleFileUpload = (file, rowIndex) => {
        let tempDoc = { ...lotDocuments[rowIndex] };
        if (file) {
            tempDoc.fileUploadData = file;

            if (tempDoc.documentId) {
                deleteDocument(tempDoc.documentId);
                tempDoc.documentId = "";
            }
        } else {
            tempDoc.fileUploadData = "";
        }

        let tempList = [...lotDocuments];
        tempList.splice(rowIndex, 1, tempDoc);
        setLotDocuments(tempList);
    };

    const validateField = (e) => {
        if (!loading) {
            let showError = false;
            if (e.target.value === "") showError = true;

            if (e.target.id !== undefined)
                setFormFieldsValidationShowErrors({
                    ...formFieldsValidationShowErrors,
                    [e.target.id]: showError,
                });
            else
                setFormFieldsValidationShowErrors({
                    ...formFieldsValidationShowErrors,
                    [e.target.name]: showError,
                });
        }
    };

    const validateIncrements = () => {
        const incrementTable = formFields.incrementTableId;
        const increment = formFields.defaultMinimumIncrement;

        if (!hideFieldsForAuctionType) {
            if ((incrementTable === '' || incrementTable === undefined || incrementTable === null) &&
                (increment === '' || increment === undefined || increment === null || Number.parseInt(increment.toString()) === 0)) {
                return false;
            }
        }

        return true;
    }

    const validateYoutubeUrls = () => {
        let isAllValid = true;

        if (isAllValid) {
            for (const [key, value] of Object.entries<any>(lotVideos)) {
                isAllValid = !validateMultipleWordsInString(value.videoUrl, "http");
                isAllValid = isAllValid
                    ? validateYoutubeUrl(value.videoUrl)
                    : isAllValid;

                if (!isAllValid) break;
            }
        }

        return isAllValid;
    };

    const validateCommission = () => {
        if ((formFields.commission) > 0) {
            if (!zeroToHundredPattern.test(formFields.commission)) {
                setCommissionValid(true);
                return true;
            } else {
                setCommissionValid(false);
                return false;
            }
        } else {
            setCommissionValid(false);
            return false;
        }

    }

    const validateAll = () => {
        let isAllValid = true;
        setLotInformationTabError(false);
        let newState: any = {
            name: false,
            description: false,
            lotType: false,
            contact: false,
            number: false,
            heroImage: false,
            lotImage: false,
        };

        if (settings.ProductName !== "PremBid") {
            newState.locationSearch = false;
            newState.locationLatitude = false;
            newState.locationLongitude = false;
        }

        for (const [key, value] of Object.entries(formFieldsValidationShowErrors)) {
            const formFieldsvalue = formFields[key];

            if (newState[key] === false) {
                if (
                    formFieldsvalue === "" ||
                    formFieldsvalue == "0.00" ||
                    formFieldsvalue === null ||
                    formFieldsvalue === 0 ||
                    formFieldsvalue === "0"
                ) {
                    isAllValid = false;
                    newState[key] = true;
                    setLotInformationTabError(true);
                }
            }
        }

        if (!handleLotVideoValidation()) {
            isAllValid = false;
        }

        if (!handleImageValidation()) {
            isAllValid = false;
        }

        setFormFieldsValidationShowErrors(newState);
        return isAllValid;
    };

    const handleLotVideoValidation = () => {
        let isAllValid = true;

        for (const [key, value] of Object.entries<any>(lotVideos)) {
            if (value.videoUrl === undefined || value.videoUrl.length == 0) {
                isAllValid = false;
                break;
            }
        }

        if (isAllValid) {
            setLotVideosTabError(false);
        } else {
            setLotVideosTabError(true);
        }

        return isAllValid;
    };

    const handleImageValidation = () => {
        let isAllValid = true;

        const hasHeroImage = heroFiles.filter((f) => f.isDisplayImage)[0];
        const hasLotImage = lotFiles.filter((f) => !f.isDisplayImage)[0];

        if (hasHeroImage === undefined || hasLotImage === undefined) {
            isAllValid = false;
            setFormFieldsValidationShowErrors({
                ...formFieldsValidationShowErrors,
                ["heroImage"]: hasHeroImage === undefined,
            });
            setFormFieldsValidationShowErrors({
                ...formFieldsValidationShowErrors,
                ["lotImage"]: hasLotImage === undefined,
            });
            setImagesTabError(true);
        } else {
            setImagesTabError(false);
        }

        return isAllValid;
    };

    const handleLotTypeOpen = () => {
        setIsLotTypeOpen(!isLotTypeOpen);
    };

    const handleContactAddOpen = () => {
        setIsContactAddOpen(!isContactAddOpen);
    };

    const handleBiddingCalculationsOpen = () => {
        setIsBiddingCalculationsAddOpen(!isBiddingCalculationsAddOpen);
    };

    const handleIncrementTableOpen = () => {
        setIsIncrementTableAddOpen(!isIncrementTableAddOpen);
    };

    const AddLotTypeModal = (props) => {
        if (settings.ProductName === "PremBid") {
            return (
                <Grid item xs={1} className='mt-2'>
                    <IconButton
                        aria-label="Add"
                        className="p-3"
                        onClick={() => {
                            handleLotTypeOpen();
                        }}
                        {...props}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            );
        } else {
            return <Grid item xs={1}></Grid>;
        }
    };

    function CreatNewLotButton(props) {
        if (props.show) {
            return (
                <Tooltip
                    arrow
                    title={
                        auction
                            ? "Create another " +
                            settings.LotName +
                            " for this " +
                            settings.AuctionName
                            : `Create another individual ${settings.LotName}`
                    }
                    placement="left"
                >
                    <Button
                        size="large"
                        variant="contained"
                        onClick={onCreate}
                        className="btn-neutral-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared"
                        style={{ background: "#2B2B2B" }}
                    >
                        <span className="btn-wrapper--icon">
                            <FaPlus />
                        </span>
                    </Button>

                </Tooltip>
            );
        } else return null;
    }

    const onAddressChange = (address) => {
        if (address) {
            let data: any = {
                locationSearch: address.searchString ?? "",
                _tempLocationString: address.searchString ?? "",
                locationLatitude: address.latitude ?? "",
                locationLongitude: address.longitude ?? "",
            };
            setFormFields({
                type: "update",
                data,
            });
        }
    };

    const renderInnerComponents = () => {
        return (
            <>
                <Card className="shadow-xxl p-3">
                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "0"}
                            onClick={() => {
                                toggle("0");
                            }}
                        >
                            {settings.LotName + " Information"}
                            {lotInformationTabError && (
                                <div className="badge badge-danger m-1 badge-circle">
                                    Danger
                                </div>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "1"}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            {settings.LotName + " Images"}
                            {imagesTabError && (
                                <div className="badge badge-danger m-1 badge-circle">
                                    Danger
                                </div>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "2"}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            {settings.LotName + " Videos"}
                            {lotVideosTabError && (
                                <div className="badge badge-danger m-1 badge-circle">
                                    Danger
                                </div>
                            )}
                        </ListItem>
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "3"}
                            onClick={() => {
                                toggle("3");
                            }}
                        >
                            {settings.LotName + " Documents"}
                        </ListItem>
                    </List>

                    <div className="p-1">
                        <div className={"tab-item-wrapper px-3 " + (activeTab === '0' ? 'active' : '')} key={0}>
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-start"
                                spacing={0}>
                                {lot?.source && lot.source !== "Internal" && (
                                    <Grid item xs={12} className="mt-2">
                                        <Alert severity="warning">
                                            The source for this data is {lot.source} and therefor
                                            only the following is allowed:
                                            <br />1 - Ordering of relative information
                                            <br />2 - Updating images (Please note, if the image
                                            was not removed from the {lot.source} portal, the
                                            image will be re-uploaded during the next data update)
                                            <br />3 - Updating videos
                                            <br />4 - Updating documents
                                            <br />
                                            <br />
                                            To make other data changes to this {
                                                settings.LotName
                                            }{" "}
                                            please use the {lot.source} portal.
                                            <br />
                                            <br />
                                            Changes can take up to 2 hours to reflect or
                                            optionally use the button below to synchronize the{" "}
                                            {settings.LotName} now.
                                            <br />
                                            <br />
                                            Last synchronized:{" "}
                                            {lot.lastSynchronized
                                                ? formatDateTime(lot.lastSynchronized, "yyyy/MM/dd HH:mm")
                                                : "Synchronize Status Unknown"}
                                            <br />
                                            <br />
                                            <Button
                                                onClick={() => {
                                                    setSynchronizingManully(true);
                                                    handleManualSync_d(lot.id, (res) => {
                                                        if (res.success) {
                                                            ns.success(
                                                                `Successfully synchronized the ${settings.LotName}`
                                                            );
                                                            showProgressSpinner({
                                                                description:
                                                                    "Retrieving " + settings.LotName + "...",
                                                            });
                                                            handleLotFetch_d(params.lotIdParam, (res) => {
                                                                hideProgressSpinner();
                                                                setShowCreateAnother(true);
                                                            });
                                                        } else {
                                                            ns.error(
                                                                `Failed to synchronize the ${settings.LotName}`
                                                            );
                                                        }
                                                        setSynchronizingManully(false);
                                                    });
                                                }}
                                                variant="contained"
                                                className="btn-primary p-3 text-white m-1 text-capitalize"
                                                disabled={synchronizingManully}
                                                size="small"
                                            >
                                                <span className="btn-wrapper--icon">
                                                    {synchronizingManully ? (
                                                        <div className="primaryColor">
                                                            <CircularProgress color="inherit" size={20} />
                                                            <div
                                                                style={{
                                                                    paddingLeft: 10,
                                                                    paddingTop: 2,
                                                                    float: "right",
                                                                }}
                                                            >
                                                                Synchronizing {settings.LotName}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>Synchronize {settings.LotName} Now</>
                                                    )}
                                                </span>
                                            </Button>
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={5}>
                                    <TextField
                                        onChange={onChange}
                                        value={formFields.name}
                                        id="name"
                                        required
                                        onBlur={validateField}
                                        onFocus={validateField}
                                        error={formFieldsValidationShowErrors.name}
                                        label="Name (Max 100 Characters)"
                                        disabled={lot?.source && lot.source !== "Internal"}
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1} />

                                <Grid item xs={5}>
                                    <TextField
                                        value={lot?.number ?? "Auto Generated..."}
                                        id="lotNumber"
                                        required
                                        disabled
                                        onBlur={validateField}
                                        label={settings.LotName + " Number"}
                                    />
                                </Grid>

                                <Grid item xs={11}>
                                    <TextField
                                        onChange={onChange}
                                        value={formFields.description}
                                        id="description"
                                        required
                                        error={formFieldsValidationShowErrors.description}
                                        onBlur={validateField}
                                        label="Description"
                                        multiline
                                        rows={4}
                                        disabled={lot?.source && lot.source !== "Internal"}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        onChange={onLotTypeChange}
                                        value={formFields.lotType}
                                        name="lotType"
                                        required
                                        error={formFieldsValidationShowErrors.lotType}
                                        onBlur={validateField}
                                        label={settings.LotName + " Type"}
                                        select
                                        disabled={lot?.source && lot.source !== "Internal"}
                                        loading={loadingStates.lotTypes}
                                    >
                                        {lotTypes !== undefined &&
                                            lotTypes.map((lotType, idx) => (
                                                <MenuItem key={lotType.id} value={lotType.id}>
                                                    {lotType.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>

                                <AddLotTypeModal disabled={lot?.source && lot.source !== "Internal"} />

                                <Grid item xs={5}>
                                    <TextField
                                        onChange={onSelectChange}
                                        value={formFields.contact}
                                        name="contact"
                                        required
                                        error={formFieldsValidationShowErrors.contact}
                                        onBlur={validateField}
                                        label="Contact/Agent"
                                        select
                                        disabled={lot?.source && lot.source !== "Internal"}
                                        loading={loadingStates.contacts}
                                    >
                                        {contactsState.lookups?.length > 0 &&
                                            contactsState.lookups.map((contact, idx) => (
                                                <MenuItem
                                                    key={contact.id}
                                                    value={contact.id}
                                                    hidden={contact.deleted}
                                                >
                                                    {contact.name}{" "}
                                                    {contact.source &&
                                                        contact.source !== "Internal" && (
                                                            <Chip
                                                                color="primary"
                                                                size="small"
                                                                label={contact.source}
                                                                style={{ marginLeft: 6 }}
                                                            />
                                                        )}{" "}
                                                    {contact.deleted && (
                                                        <Chip
                                                            color="error"
                                                            size="small"
                                                            label="Deleted"
                                                            style={{ marginLeft: 6 }}
                                                        />
                                                    )}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={1} className='mt-2'>
                                    <IconButton
                                        aria-label="Add"
                                        className="p-3"
                                        disabled={lot?.source && lot.source !== "Internal"}
                                        onClick={() => {
                                            handleContactAddOpen();
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        spacing={0}>

                                        <Grid item xs={5}>

                                            <FormControlLabel
                                                className="ml-0 mt-2"
                                                disabled={lot?.source && lot.source !== "Internal"}
                                                control={
                                                    <span style={{ marginLeft: 10 }}>
                                                        <Switch
                                                            checked={formFields.locationVisible}
                                                            onChange={onChangeCheckbox}
                                                            id="locationVisible"
                                                            className="p-2 switch-medium toggle-switch-success"
                                                        />
                                                    </span>
                                                }
                                                labelPlacement="start"
                                                label="Display Location on Client Portal"
                                            />

                                            <TextField
                                                onChange={onChange}
                                                id="locationSearch"
                                                value={formFields.locationSearch}
                                                error={formFieldsValidationShowErrors.locationSearch}
                                                onBlur={settings.ProductName !== "PremBid" ? validateField : undefined}
                                                onFocus={settings.ProductName !== "PremBid" ? validateField : undefined}
                                                label="Location"
                                                required={settings.ProductName !== "PremBid" ? true : false}
                                                disabled={lot?.source && lot.source !== "Internal"}
                                            />

                                            <TextField
                                                onChange={onChange}
                                                id="locationLatitude"
                                                value={formFields.locationLatitude}
                                                error={
                                                    formFieldsValidationShowErrors.locationLatitude
                                                }
                                                onBlur={settings.ProductName !== "PremBid" ? validateField : undefined
                                                }
                                                onFocus={settings.ProductName !== "PremBid" ? validateField : undefined}
                                                required={settings.ProductName !== "PremBid" ? true : false}
                                                label="Latitude"
                                                disabled={lot?.source && lot.source !== "Internal"}
                                            />

                                            <TextField
                                                onChange={onChange}
                                                id="locationLongitude"
                                                value={formFields.locationLongitude}
                                                error={
                                                    formFieldsValidationShowErrors.locationLongitude
                                                }
                                                onBlur={
                                                    settings.ProductName !== "PremBid" ? validateField : undefined
                                                }
                                                onFocus={settings.ProductName !== "PremBid" ? validateField : undefined}
                                                required={settings.ProductName !== "PremBid" ? true : false}
                                                label="Longitude"
                                                disabled={lot?.source && lot.source !== "Internal"}
                                            />
                                        </Grid>

                                        <Grid item xs={1} />

                                        <Grid item xs={5}>
                                            {mapLoaded && (
                                                <Card
                                                    className="mb-5"
                                                    style={{
                                                        height: 320,
                                                    }}
                                                >
                                                    <GoogleMapContainer
                                                        onLocationChanged={onAddressChange}
                                                        center={{
                                                            lat: Number(formFields.locationLatitude),
                                                            lng: Number(formFields.locationLongitude),
                                                        }}
                                                        searchString={formFields._tempLocationString}
                                                        overrideDefaultCenter={{
                                                            lat: -32,
                                                        }}
                                                        canEdit={lot?.source && lot.source === "Internal"}
                                                    />
                                                    {lot?.source && lot.source === "Internal" && (
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                textAlign: "center",
                                                                marginTop: 5,
                                                            }}
                                                        >
                                                            <FormLabel>
                                                                Click on the map to drop a pin.
                                                            </FormLabel>
                                                        </div>
                                                    )}
                                                </Card>
                                            )}
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        value={lot?.uniqueCode ?? "Auto Generated..."}
                                        id="uniqueCode"
                                        required
                                        onBlur={validateField}
                                        label={"Unique Code"}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        onChange={onChange}
                                        id="skuNumber"
                                        value={formFields.skuNumber}
                                        label="SKU Number"
                                        disabled={lot?.source && lot.source !== "Internal"}
                                    />
                                </Grid>
                                <Grid item xs={1} />

                                <Grid item xs={5}>
                                    <TextField
                                        margin="dense"
                                        onFocus={(e) =>
                                            e.target.setSelectionRange(0, e.target.value.length)
                                        }
                                        id="depositAmount"
                                        value={formFields.depositAmount}
                                        label="Registration Deposit"
                                        disabled
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        onFocus={(e) =>
                                            e.target.setSelectionRange(0, e.target.value.length)
                                        }
                                        onChange={onChange}
                                        id="reservePrice"
                                        value={formFields.reservePrice}
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        label="Reserve Price"
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        onFocus={(e) =>
                                            e.target.setSelectionRange(0, e.target.value.length)
                                        }
                                        onChange={onChange}
                                        id="commission"
                                        value={formFields.commission}
                                        error={commissionValid}
                                        helperText={commissionValid ? "Please capture an amount between 0 - 100%" : null}
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        label="Buyer's Commission"
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency prefixString={"%"} hideCurrencySymbol />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        onFocus={(e) =>
                                            e.target.setSelectionRange(0, e.target.value.length)
                                        }
                                        onChange={onChange}
                                        id="guidePrice"
                                        value={formFields.guidePrice}
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        label="Guide Price"
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        tooltip={lot?.winningRegisteredProfileAmount ? "A bid has already been placed" : undefined}
                                        hidden={
                                            Number(auction?.auctionType) ===
                                                Number(AuctionTypeEnum.Streamed.toString())
                                                ? false
                                                : hideFieldsForAuctionType
                                        }
                                        onFocus={(e) =>
                                            e.target.setSelectionRange(0, e.target.value.length)
                                        }
                                        onChange={onChange}
                                        id="startingPrice"
                                        value={formFields.startingPrice}
                                        disabled={(lot?.source && lot.source !== "Internal" && lot.source !== "PropData") || lot?.winningRegisteredProfileAmount}
                                        label="Opening Bid"
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}><div></div></Grid>

                                <Grid item xs={5}>
                                    <FormLabel hidden={hideFieldsForAuctionType} className="mb-0 text-primary">Please either create/use a default increment table or set a default increment</FormLabel>
                                    <TextField
                                        required={!hideFieldsForAuctionType}
                                        hidden={hideFieldsForAuctionType}
                                        onChange={onIncrementTableSelectChange}
                                        value={formFields.incrementTableId}
                                        name="incrementTableId"
                                        label="Increment Table"
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        select
                                        loading={loadingStates.incrementTables}
                                    >
                                        <MenuItem key={'_blank'} value=''>
                                            None
                                        </MenuItem>
                                        {incrementTableLookup?.map(incrementTable => <MenuItem key={incrementTable.id} value={incrementTable.id}>
                                            {incrementTable.name}
                                        </MenuItem>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={1} className='mt-2'>
                                    <div style={{ marginTop: '1em', paddingTop: 10 }}>
                                        <IconButton
                                            hidden={hideFieldsForAuctionType}
                                            aria-label="Add"
                                            className="p-3"
                                            disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                            onClick={() => {
                                                handleIncrementTableOpen();
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </Grid>

                                <Grid item xs={6} />

                                <Grid item xs={5} className="text-center">
                                    <FormLabel hidden={hideFieldsForAuctionType} className="text-primary text-center">OR</FormLabel>
                                </Grid>

                                <Grid item xs={5} style={{ marginTop: '-50px' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        spacing={0}>
                                        <Grid item xs={5}>
                                            {
                                                !hideFieldsForAuctionType &&
                                                <div>
                                                    <FormLabel className="mt-2 mb-2 font-size-xs">Late Bid Extension Window</FormLabel>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.timeWindowHours} onChange={onChangeNumeric} id="timeWindowHours" className="mt-1 mb-1 mr-1" label="Hours" type='number' />
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.timeWindowMinutes} onChange={onChangeNumeric} id="timeWindowMinutes" className="m-1" label="Mins" type='number' />
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.timeWindowSeconds} onChange={onChangeNumeric} id="timeWindowSeconds" className="mt-1 mb-1 ml-1" label="Secs" type='number' />
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={5}>
                                            {
                                                !hideFieldsForAuctionType &&
                                                <div>
                                                    <FormLabel className="mt-2 mb-2 font-size-xs">Late Bid Extension</FormLabel>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.hours} onChange={onChangeNumeric} id="hours" className="mt-1 mb-1 mr-1" label="Hours" type='number' />
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.minutes} onChange={onChangeNumeric} id="minutes" className="m-1" label="Mins" type='number' />
                                                        <TextField disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} value={formFields.seconds} onChange={onChangeNumeric} id="seconds" className="mt-1 mb-1 ml-1" label="Secs" type='number' />
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} />

                                <Grid item xs={5}>
                                    <TextField
                                        required={!hideFieldsForAuctionType}
                                        hidden={hideFieldsForAuctionType}
                                        value={formFields.defaultMinimumIncrement}
                                        onChange={onIncrementChange}
                                        onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                        id="defaultMinimumIncrement"
                                        label="Increment"
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        InputProps={{
                                            inputComponent: CurrencyInput,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Currency priceWithSymbol={""} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} />

                                <Grid item xs={5}>
                                    <TextField
                                        hidden={hideFieldsForAuctionType}
                                        onChange={onSelectChange}
                                        value={formFields.biddingCalculationId}
                                        name="biddingCalculationId"
                                        label="Bidding Calculation"
                                        disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"}
                                        loading={loadingStates.biddingCalculations}
                                        select>
                                        <MenuItem key={'_blank'} value=''>
                                            None
                                        </MenuItem>
                                        {biddingCalculationsLookup?.map(biddingCalculation => <MenuItem key={biddingCalculation.id} value={biddingCalculation.id}>
                                            {biddingCalculation.name}
                                        </MenuItem>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={1} className='mt-2'>
                                    <IconButton
                                        hidden={hideFieldsForAuctionType}
                                        aria-label="Add"
                                        className="p-3"
                                        onClick={() => {
                                            handleBiddingCalculationsOpen();
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Grid>

                                <Grid item xs={6} />

                                <Grid item xs={6} className='mt-2'>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="flex-start"
                                        spacing={0}>
                                        <Grid item xs={5}>
                                            <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Show when the Reserve is Met" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Switch checked={formFields.showReserve} onChange={onChangeCheckbox} id="showReserve" className="p-2 switch-medium toggle-switch-success" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="STC Status" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Switch disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} checked={formFields.stcStatus} onChange={onChangeCheckbox} id="stcStatus" className="p-2 switch-medium toggle-switch-success" />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Watermark Images" />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Switch checked={formFields.watermarkImages} onChange={onChangeCheckbox} id="watermarkImages" className="p-2 switch-medium toggle-switch-success" />
                                        </Grid>

                                        {
                                            !hideFieldsForAuctionType &&
                                            <>
                                                <Grid item xs={4}>
                                                    <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Show Paddle Number" />
                                                </Grid><Grid item xs={1}>
                                                    <Switch checked={formFields.showPaddleNumber} onChange={onChangeCheckbox} id="showPaddleNumber" className="p-2 switch-medium toggle-switch-success" />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel disabled={lot?.source && lot.source !== "Internal" && lot.source !== "PropData"} className="ml-0 mt-2" control={<span style={{ marginLeft: 10 }}><Switch checked={formFields.isActive} onChange={onChangeCheckbox} id="isActive" className="p-2 switch-medium toggle-switch-success" /></span>} labelPlacement="start" label="Available" id="isActive" />
                                </Grid>
                            </Grid>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Field</TableCell>
                                                <TableCell>Related Information</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip arrow title="Add" placement="bottom">
                                                        <Button
                                                            color="primary"
                                                            className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                            onClick={handleAddNewFieldClicked}
                                                            disableFocusRipple={true}
                                                        >
                                                            <FaPlus />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {additionalData.map((row, index, list) =>
                                                mapRow(row, index, list.length)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div
                            className={
                                "tab-item-wrapper px-3 " + (activeTab === "1" ? "active" : "")
                            }
                            key={1}
                            style={{ paddingLeft: 6 }}
                        >
                            <ImageUploader
                                acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                                lotImages={lotImages}
                                newFilesAdded={newHeroImageFilesAdded}
                                deleteImage={deleteImage}
                                isDisplayImage={true}
                                header={settings.LotName + " Banner Image"}
                                description={`This is the main banner image that will display at the top of the ${settings.LotName} information page on the Client Portal.`}
                                min={1}
                                max={1}
                            />
                            <ImageUploader
                                acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                                lotImages={lotImages}
                                newFilesAdded={newLotImageFilesAdded}
                                deleteImage={deleteImage}
                                isDisplayImage={false}
                                header={settings.LotName + " Images"}
                                description={`These are the images taken of the ${settings.LotName}.`}
                                min={1}
                                max={50}
                            />
                        </div>

                        <div
                            className={
                                "tab-item-wrapper px-3 " + (activeTab === "2" ? "active" : "")
                            }
                            key={2}
                        >
                            <div className="table-responsive-md mb-4">
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>YouTube Video URL's</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip arrow title="Add" placement="bottom">
                                                        <Button
                                                            color="primary"
                                                            className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                            onClick={onAddLotVideo}
                                                            disableFocusRipple={true}
                                                        >
                                                            <FaPlus />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {lotVideos !== undefined &&
                                                lotVideos.map((row, index, list) =>
                                                    mapLotVideoRow(row, index, list.length)
                                                )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <div
                            className={
                                "tab-item-wrapper px-3 " + (activeTab === "3" ? "active" : "")
                            }
                            key={3}
                        >
                            <div className="table-responsive-md mb-4">
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="80%">Document Name</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right" width="20%">
                                                    <Tooltip arrow title="Add" placement="bottom">
                                                        <Button
                                                            color="primary"
                                                            className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center"
                                                            onClick={onShowDocumentTemplateModal}
                                                            disableFocusRipple={true}
                                                        >
                                                            <FaPlus />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {lotDocuments.map((row, index) =>
                                                mapLotDocumentRow(row, index)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {documentTemplateModal.open && (
                                    <DocumentTemplateAddDialog
                                        documentType={DocumentTypeEnum.Lot}
                                        open={documentTemplateModal.open}
                                        payload={documentTemplateModal.id}
                                        okButtonText="Ok"
                                        cancelButtonText="Cancel"
                                        description="Select documents you want to add"
                                        title="Documents"
                                        onCancel={onCancelDocumentTemplateModal}
                                        onOk={(selectedDocumentTemplates) =>
                                            onAddDocumentTemplateModal(selectedDocumentTemplates)
                                        }
                                        filterOutUploadMandatory={true}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <FloatyButtonGroup>
                        <Tooltip arrow title="Save" placement="left">
                            <Button
                                size="large"
                                variant="contained"
                                onClick={onSubmit}
                                className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared"
                            >
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                            </Button>
                        </Tooltip>
                        <Tooltip arrow title="Back" placement="left">
                            <Button
                                size="large"
                                variant="contained"
                                onClick={onCancel}
                                className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared"
                            >
                                <span className="btn-wrapper--icon">
                                    <FaBackspace />
                                </span>
                            </Button>
                        </Tooltip>
                        <CreatNewLotButton show={showCreateAnother} />
                    </FloatyButtonGroup>

                    <LotTypeDialog
                        isDialogOpened={isLotTypeOpen}
                        handleCloseDialog={(data?: any) => {
                            if (data) {
                                showProgressSpinner({
                                    description: "Saving " + settings.LotName + " Type...",
                                });
                                handleLotTypesFetch_d((res) => {
                                    if (res.success) {
                                        setFormFields({
                                            type: "update",
                                            data: { lotType: data },
                                        });
                                        parseAndSetAdditionalFields(data, false, res.response);
                                    }
                                    hideProgressSpinner();
                                    setIsLotTypeOpen(false);
                                });
                            } else {
                                setIsLotTypeOpen(false);
                            }
                        }}
                    />
                    <ContactAddDialog
                        isDialogOpened={isContactAddOpen}
                        handleCloseDialog={(data?: any) => {
                            if (data) {
                                showProgressSpinner({ description: "Saving Contact..." });
                                handleContactsFetch_d(
                                    { ...contactsState.pagination, pageSize: -1 },
                                    (res) => {
                                        if (res.success)
                                            setFormFields({
                                                type: "update",
                                                data: { contact: data },
                                            });
                                        hideProgressSpinner();
                                    }
                                );
                                setIsContactAddOpen(false);
                            } else setIsContactAddOpen(false);
                        }}
                    />

                    <BiddingCalculationsAddDialog
                        isDialogOpened={isBiddingCalculationsAddOpen}
                        handleCloseDialog={(data) => handleCloseBiddingCalculationsDialog(data)}
                    />

                    <IncrementTableAddDialog
                        isDialogOpened={isIncrementTableAddOpen}
                        handleCloseDialog={(data) => handleCloseIncrementTableDialog(data)}
                    />
                </Card>
            </>
        );
    };

    return <>{renderInnerComponents()}</>;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotAdd_d: (
            formFields: any,
            additionalData: any,
            auctionId: string,
            images: any[],
            videos: any[],
            documents: any[],
            auction: any,
            onCompletedCallback: (any) => void
        ) =>
            dispatch(
                handleLotAdd(
                    formFields,
                    additionalData,
                    auctionId,
                    images,
                    videos,
                    documents,
                    auction,
                    onCompletedCallback
                )
            ),
        handleLotUpdate_d: (
            formFields: any,
            additionalData: any,
            auctionId: string,
            images: any[],
            videos: any[],
            documents: any[],
            auction: any,
            onCompletedCallback: (any) => void
        ) =>
            dispatch(
                handleLotUpdate(
                    formFields,
                    additionalData,
                    auctionId,
                    images,
                    videos,
                    documents,
                    auction,
                    onCompletedCallback
                )
            ),
        clearLot_d: () => dispatch(clearLot()),
        clearLotTypeId_d: () => dispatch(clearLotTypeId()),
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) =>
            dispatch(handleLotTypesFetch(onCompletedCallback)),
        clearAuction_d: () => dispatch(clearAuction()),
        handleRetrieveAllImagesByLotId_d: (lotId: string) =>
            dispatch(handleRetrieveAllImagesByLotId(lotId)),
        handleDocumentDelete_d: (documentId: string) =>
            dispatch(handleRegistrationDocumentDelete(documentId)),
        handleRetrieveRegistrationDocuments_d: (auctionId: string, lotId: string) =>
            dispatch(handleRetrieveRegistrationDocuments(auctionId, lotId)),
        handleImageDelete_d: (imageId: string) =>
            dispatch(handleImageDelete(imageId)),
        handleLotVideoDelete_d: (
            videoId: string,
            onCompletedCallback: (res: any) => void
        ) => dispatch(handleLotVideoDelete(videoId, onCompletedCallback)),
        clearImages_d: () => dispatch(clearImages()),
        handleLotFetch_d: (
            lotId: string,
            onCompletedCallback: (res: any) => void
        ) => dispatch(handleLotFetch(lotId, onCompletedCallback)),
        handleSetImages_d: (images: any[]) => dispatch(handleSetImages(images)),
        handleContactsFetch_d: (
            pagination: any,
            onCompletedCallback?: (res: any) => void
        ) => dispatch(handleContactLookupFetch(true, onCompletedCallback)),
        handleLotEditAddAuctionFetch_d: (id: string) =>
            dispatch(handleLotEditAddAuctionFetch(id)),
        clearDocuments_d: () => dispatch(clearDocuments()),
        handleManualSync_d: (
            lotId: string,
            onCompletedCallback: (res: any) => void
        ) => dispatch(handleManualSync({ lotId }, onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
        retrieveBiddingCalculationsLookup_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchBiddingCalculationsLookup(onCompletedCallback)),
        retrieveIncrementTableLookup_d: (onCompletedCallback: (res: any) => void) => dispatch(handleIncrementTablesLookupFetch(onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    auction: state.lots.auction,
    error: state.lots.error,
    errorText: state.lots.errorText,
    lot: state.lots.lot,
    lotId: state.lots.lotId,
    lotTypes: state.lotTypes.lotTypes,
    lotImages: state.lots.lotImages,
    documents: state.documents.documents,
    selectLotTypeLots: state.lotTypes.selectLotTypeLots,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    contactsState: state.contacts,
    biddingCalculationsLookup: state.biddingCalculations.biddingCalculationsLookup,
    incrementTableLookup: state.incrementTable.lookups,
});

export default connect(mapStateToProps, mapDispatchToProps)(LotsEditAdd);
