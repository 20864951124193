import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { FaTimes, FaSave } from "react-icons/fa";
import { connect } from "react-redux";
import { DialogState, RESET_DIALOG } from "./store/Dialog";

export interface DialogProps extends DialogState {
    resetDialog: () => void
}

export const CustomDialog: React.FC<DialogProps> = props => {
    const {
        open,
        title,
        message,
        content,
        onSubmit,
        onCancel,
        locales,
        actions,
        resetDialog,
        contentStyle,
    } = props;

    const handleCancel = () => {
        resetDialog();
        if (onCancel) onCancel();
    }

    const handleSubmit = () => {
        resetDialog();
        if (onSubmit) onSubmit();
    }

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" >
            {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
            <DialogContent style={{ minWidth: '350px', ...contentStyle }}>
                {message && <DialogContentText>{message}</DialogContentText>}
                {content && <>{content}</>}
            </DialogContent>
            <DialogActions>
                {(actions && actions.length > 0) ? <>{actions}</> : <>
                    {onCancel && <Button onClick={handleCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">{locales?.cancelText || 'Cancel'}</span>
                    </Button>}
                    {onSubmit && <Button onClick={handleSubmit} variant="contained" className="btn-primary p-3 text-white text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                        <span className="btn-wrapper--label">{locales?.submitText || 'Ok'}</span>
                    </Button>}
                </>}
            </DialogActions>
        </Dialog>
    );
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        resetDialog: () => dispatch({ type: RESET_DIALOG })
    }
}

const mapStateToProps = (state: any) => ({
    open: state.dialog.open,
    title: state.dialog.title,
    message: state.dialog.message,
    content: state.dialog.content,
    onSubmit: state.dialog.onSubmit,
    onCancel: state.dialog.onCancel,
    locales: state.dialog.locales,
    actions: state.dialog.actions,
    contentStyle: state.dialog.contentStyle
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialog);