export enum ValidationStatus {
    Invalid = 0,
    Valid = 1,
}

export type FileHistoryItem = {
    index: number;
    fileName: string;
    createdAt: string;
    status: string;
    newFile: boolean;
}

export type ImageHistoryItem = {
    id: string;
    imageName: string;
    imageUrl: string;
    watermarkedImageName: string;
    watermarkedImageUrl: string;
    order: string;
    isDisplayImage: boolean;
}

export type ValidationHistoryItem = {
    name: string;
    uploadedLotNumber: number;
    bannerImageCount: number,
    otherImageCount: number,
    validationErrors: any
}