import { FC, memo } from "react";
import useStyles from "../AuctionsOverviewFactsFigures.styles";
import { Box, Grid, Typography } from "@mui/material";
import CurrencyLabel from "../../CurrencyComponent/CurrencyLabel";
import { FinancialAmounts } from "../AuctionsOverviewFactsFigures.types";
import { Skeleton } from "../../library/Skeleton";

interface FinancialTotalsProps {
    settings: any;
    financialAmounts: FinancialAmounts;
    isLoading: boolean;
}

const FinancialTotals: FC<FinancialTotalsProps> = ({ settings, financialAmounts, isLoading }) => {

    const { classes } = useStyles();

    return (
        <>
            <Grid
                container
                classes={{ root: classes.subContainer }}>
                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.lineText }}>Current {settings?.AuctionName} Total</Typography>
                                <CurrencyLabel value={financialAmounts?.auctionTotal} />
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.successText }}>Reserve Met Total</Typography>
                                <CurrencyLabel value={financialAmounts?.lotsReserveMetTotal} />
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.dangerText }}>Reserve Not Met Total</Typography>
                                <CurrencyLabel value={financialAmounts?.lotsReserveNotMetTotal} />
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(FinancialTotals);
