import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
    const border = '1px solid rgb(220, 222, 241)';
    const borderRadius = 10;
    const fontWeight = 600;

    return ({
        mainContainer: {
            flexDirection: 'row',
            alignItems: 'stretch', // Makes children stretch to fill height
            justifyContent: 'space-between', // Space between the subcontainers
            width: '100%',
            padding: 0,
            margin: 0,
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 15,
            paddingBottom: 15,
            height: '100%',
        },
        subContainer: {
            border,
            borderRadius,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: 15,
            height: '100%',
            overflow: 'hidden', // Restrict content overflow
        },
        gridRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        refreshButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        lineText: {
            fontWeight
        },
        successText: {
            color: '#1BC943',
            fontWeight
        },
        dangerText: {
            color: '#F83245',
            fontWeight
        },
        warningText: {
            color: '#FFA319',
            fontWeight
        },
    })
});

export default useStyles;