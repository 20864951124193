import React, { useState } from 'react';
import {
    Switch
} from '@mui/material';

interface Props {
    profile: any;
    selectedProfileRef: any;
    setSelectedProfile?: (data: any, onCompletedCallback?: (response: any) => void) => void | undefined,
}

const RegisterManualUserSelect: React.FC<Props> = props => {
    const { profile, selectedProfileRef, setSelectedProfile } = props;

    const [checked, setChecked] = useState<boolean>(false);
    const handleUserSelectChange = (e) => {
        e.stopPropagation();

        const { target: { checked } } = e;
        if (selectedProfileRef?.current?.profile?.id !== profile.id)
            selectedProfileRef.current?.setChecked(false);

        selectedProfileRef.current = { profile, checked, setChecked };
        if (setSelectedProfile) setSelectedProfile(profile);
        setChecked(checked);
    }

    return (
        <React.Fragment>
            <Switch checked={checked} onChange={handleUserSelectChange} id={profile.id} className="m-2 p-2 switch-medium toggle-switch-success" />
        </React.Fragment>
    )
}

export default RegisterManualUserSelect;