import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../../store/reducers/ThemeOptions';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import { makeStyles } from 'tss-react/mui';
import { ConnectionStatusIndicator } from '../../../components/ConnectionStatusIndicator';

const useStyles = makeStyles()((theme) => ({

}))

const Header = (props) => {
    const { classes, cx } = useStyles();
    const {
        headerShadow,
        headerBgTransparent,
        sidebarToggleMobile,
        setSidebarToggleMobile
    } = props;

    const toggleSidebarMobile = () => {
        setSidebarToggleMobile(!sidebarToggleMobile);
    };

    return (
        <div
            className={cx('app-header', {
                'app-header--shadow': headerShadow,
            })}>
            <div className="app-header--pane">
                <button
                    className={cx(
                        'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                        { 'is-active': sidebarToggleMobile }
                    )}
                    onClick={toggleSidebarMobile}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner" />
                    </span>
                </button>
                <ConnectionStatusIndicator />
            </div>
            <div className="app-header--pane">
                <HeaderUserbox />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
