import React, { useState } from "react";
import useHistory from '@/hooks/useHistory';
import { connect } from "react-redux";
import {
    clearError,
    handleDelete,
    handleDocumentTemplatesFetchWithPagination,
} from "./store/Documents";
import ConfirmationDialog from "@/components/Modal/ConfirmationDialog";
import ns from '@/helpers/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import{
    NestedAccordionGrid,
    CustomData,
    Cell,
    Header,
    Row,
    KeyValue,
    NestedGridDataModel,
} from "@/components/NestedAccordionGrid";
import { makeStyles } from "tss-react/mui";
import { Button, Tooltip } from "@mui/material";
import { FaRedo } from "react-icons/fa";
import { toCamel } from "../../helpers/Utils";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    clearError_d: () => void,
    handleDocumentTemplatesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => void,
    error: boolean,
    errorText: string | any,
    reload: boolean,
    documentTemplates: any,
    loading: boolean,
    settings: any,
    pagination: any,
}

const DocumentTemplate: React.FC<Props> = (props) => {
    const {
        clearError_d,
        handleDocumentTemplatesFetchWithPagination_d,
        handleDelete_d,
        error,
        errorText,
        reload,
        documentTemplates,
        loading,
        settings,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingDocumentTemplates, setLoadingDocumentTemplates] = useState<boolean>(true);

    const [documentTemplatesDisplayModel, setDocumentTemplatesDisplayModel] =
        React.useState<NestedGridDataModel>();
    const [deleteModal, setDeleteModal] = useState<any>({
        open: false,
        id: "",
    });
    const statusBadge = (isApplied: boolean, isUploadMandatory: boolean, isAccessDriven: boolean, isRegistrationDocument: boolean) => {
        return (<>
            {isApplied && (<div className="badge badge-success badge-pill mr-1 text-capitalize ">Applied</div>)}
            {isUploadMandatory && (<div className="badge badge-success badge-pill mr-1 text-capitalize ">Is Upload Mandatory</div>)}
            {isAccessDriven && (<div className="badge badge-success badge-pill mr-1 text-capitalize ">Is Access Driven</div>)}
            {isRegistrationDocument && (<div className="badge badge-success badge-pill mr-1 text-capitalize ">Is Registration Document</div>)}
            {!isApplied && !isUploadMandatory && !isAccessDriven && !isRegistrationDocument && <>N/A</>}
        </>)
    }

    React.useEffect(() => {
        showProgressSpinner({ description: "Retrieving Documents..." });
        refreshList(selectedFilters, pagination, false);
    }, [reload]);

    React.useEffect(() => {
        if (documentTemplates) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" },
            ];

            documentTemplates.forEach((documentTemplate) => {
                const custom: KeyValue[] = [];

                let documentTemplateName = documentTemplate.name?.substring(0, 35);
                if (documentTemplate.name.length > 35) documentTemplateName += "...";

                let documentTemplateDescription = documentTemplate.description?.substring(0, 35);
                if (documentTemplate.description.length > 35) documentTemplateDescription += "...";

                const cells: Cell[] = [
                    { text: documentTemplateName, align: "inherit" },
                    { text: documentTemplateDescription, align: "inherit" },
                ];

                const dataCaptured: any = toCamel(documentTemplate.dataCaptured);

                for (const [key, value] of Object.entries(dataCaptured) as any) {
                    let name = key === 'lot' ? settings.LotName : key === 'auction' ? settings.AuctionName : key;
                    name = name.substring(0, 1).toUpperCase() + name.substring(1);
                    custom.push({
                        key: name,
                        value: statusBadge(value.isApplied, value.isUploadMandatory, value.isAccessDriven, value.isRegistrationDocument)
                    });
                }
                const customData: CustomData[] = [
                    {
                        title: "Custom Data",
                        values: custom,
                    },
                ];
                rows.push({
                    id: documentTemplate.id,
                    cells: cells,
                    customData: customData,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setDocumentTemplatesDisplayModel(model);
        }

        var waitForGridRender = new Promise((r) => setTimeout(r, 500));
        waitForGridRender.then(() => {
            hideProgressSpinner();
        });
    }, [documentTemplates]);

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    React.useEffect(() => {
        if (error) {
            clearError_d()
            ns.error(errorText);
        }
    }, [error]);

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingDocumentTemplates(true);
        else showProgressSpinner({ description: "Retrieving Documents..." });
        handleDocumentTemplatesFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingDocumentTemplates(false);
        });
    }

    const onDelete = (id: string) => {
        showDeleteModal(true, id);
    };

    const onEdit = (id: any) => {
        navAddEdit(id);
    };

    const navAddEdit = (documentTemplateId) => {
        let path = "/DocumentTemplateEdit";
        if (documentTemplateId !== "") path += "?documentTemplateId=" + documentTemplateId;
        history.push(path);
    };

    //Modal
    const onDeleteModalClosed = () => {
        showDeleteModal(false, "");
    };
    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting Document..." });
        handleDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Document deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the document")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    };
    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    };

    const handlePageChange = (pagination?: any) => {
        refreshList(selectedFilters, pagination);
    }

    const handleFiltersChange = (filters?: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    return (
        <div>

            {!loading && (
                <>
                    <div className="card-header py-3">
                        <div className="card-header--title font-size-lg">Documents</div>
                    </div>
                    <div className="table-responsive-md">
                        <NestedAccordionGrid
                            dataModel={documentTemplatesDisplayModel}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onAdd={() => {
                                navAddEdit("");
                            }}
                            selectedFilters={selectedFilters}
                            pagination={pagination}
                            onHandleChangePage={handlePageChange}
                            onFiltersChange={handleFiltersChange}
                            loading={loadingDocumentTemplates}
                        />

                        {deleteModal.open && <ConfirmationDialog
                            open={deleteModal.open}
                            payload={deleteModal.id}
                            okButtonText="Ok"
                            cancelButtonText="Cancel"
                            description="Are you sure you want to delete this document?"
                            title="Delete Document"
                            onCancel={onDeleteModalClosed}
                            onOk={onDeleteModalOk}
                        />}

                    </div>
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleDelete(Id, onCompletedCallback)),
        handleDocumentTemplatesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleDocumentTemplatesFetchWithPagination(filters, pagination, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    error: state.documents.error,
    errorText: state.documents.errorText,
    reload: state.documents.reload,
    documentTemplates: state.documents.documentTemplates,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    pagination: state.documents.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTemplate);
