import React, { FC, useMemo, useReducer } from "react";
import SimpleTable, { ColumnProps } from "@/components/SimpleTable/SimpleTable";
import { Box, Tooltip } from "@mui/material";
import { FaDownload, FaTrash } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { ImageHistoryItem } from "../../../dto/BulkUploader";
import ns from '@/helpers/NotificationService';
import { connect } from "react-redux";
import { downloadFile } from "../../Documents/store/Documents";
import { handleDeleteBulkUploadImage } from "../store/BulkUpload";

interface BulkUploaderImageUploadTableProps {
    imageHistoryData: ImageHistoryItem[],
    handleDownloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
    handleDeleteBulkUploadImage_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    isTableLoading: boolean,
    isTableSaving: boolean,
    onRemoveImageCallback: (url: string) => void,
}

const BulkUploaderImageUploadTable: FC<BulkUploaderImageUploadTableProps> = props => {
    const { imageHistoryData, handleDownloadFile_d, handleDeleteBulkUploadImage_d, isTableLoading, isTableSaving, onRemoveImageCallback } = props;

    const [loadingIds, updateLoadingId] = useReducer((state: string[], action: { type: 'add' | 'remove' | 'clear'; id: string; }) => {
        switch (action.type) {
            case 'add': return [...state, action.id];
            case 'remove': return state.filter(id => id !== action.id);
            case 'clear': return [];
            default: return state;
        }
    }, []);

    const columns = useMemo<ColumnProps<ImageHistoryItem>[]>(() => ([
        {
            id: 'imageUrl',
            header: '',
            accessorFn: (row: ImageHistoryItem) => <>
                <div style={{ maxHeight: 35, aspectRatio: "1.78" }}>
                    <img
                        src={row?.imageUrl}
                        className="card-img-top rounded img-fit-container"
                        alt="Missing"
                        style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                            objectFit: "scale-down",
                            background: "#000000",
                        }}
                    />
                </div>
            </>,
            enableSorting: false
        },
        {
            id: "order",
            header: 'Image Name',
            accessorKey: 'order',
            enableSorting: true
        },
    ]), []);

    const handleDownloadFile = (id: string) => {
        const imageHistoryRecord = imageHistoryData.filter(x => x.id === id);
        const imageUrl = imageHistoryRecord[0]?.watermarkedImageUrl ? imageHistoryRecord[0]?.watermarkedImageUrl : imageHistoryRecord[0]?.imageUrl;

        updateLoadingId({ type: 'add', id });
        handleDownloadFile_d(imageUrl, (response: any) => {
            if (response?.ok) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank');
                });
            }
            else {
                ns.error('Failed to download the image');
            }
            updateLoadingId({ type: 'remove', id });
        })
    }

    const handleRemoveImage = (id: string) => {
        handleDeleteBulkUploadImage_d(id, res => {
            if (res.success) {
                onRemoveImageCallback(id);
            }
        })
    };

    return (
        <SimpleTable
            columnHeaders={columns}
            tableData={imageHistoryData}
            loadingIds={loadingIds}
            renderRowActions={(row, isLoading, loadingIds) => {
                const id = row.id;
                const loading = loadingIds.includes(id);

                return <>
                    <Tooltip arrow title="Download Image" placement="bottom">
                        <span>
                            <LoadingButton
                                onClick={() => handleDownloadFile(id)}
                                size="small"
                                disabled={isLoading}
                                loading={loading}
                                className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaDownload />
                            </LoadingButton>
                        </span>
                    </Tooltip>
                    <Tooltip arrow title="Remove Image" placement="bottom">
                        <span>
                            <LoadingButton
                                onClick={() => handleRemoveImage(id)}
                                size="small"
                                disabled={isLoading}
                                loading={loading}
                                className="btn-danger rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaTrash />
                            </LoadingButton>
                        </span>
                    </Tooltip>
                </>
            }}

            renderBottomTableActions={(table, isLoading) => {
                return <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }} />
            }}

            pagination={true}
            isLoading={isTableLoading}
            isSaving={isTableSaving}
        />
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleDownloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
        handleDeleteBulkUploadImage_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleDeleteBulkUploadImage(id, onCompletedCallback)),
    }
}

export default connect(null, mapDispatchToProps)(BulkUploaderImageUploadTable);