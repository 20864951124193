import React, { FC, useEffect, useState } from "react";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import {
    Button,
    Tooltip,
    Chip
} from '@mui/material';
import { FaList, FaRedo } from "react-icons/fa";
import {
    NestedAccordionGrid,
    Cell,
    Header,
    Row,
    NestedGridDataModel,
    KeyValue,
    CustomData
} from '@/components/NestedAccordionGrid';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { makeStyles } from "tss-react/mui";
import { clearIncrementTableState, handleIncrementTableFetchWithPagination, handleDeleteIncrementTable } from './store/IncrementTable';
import { DialogConfig } from '@/components/Dialog/store/Dialog';

const useStyles = makeStyles()((theme) => ({

}))


interface IncrementTableProps {
    handleIncrementTableFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    clearIncrementTableState_d: () => void,
    handleDeleteIncrementTable_d: (incrementTableId: string, onCompletedCallback: (response: any) => void) => void,
    loading: boolean,
    pagination: any,
    list: any;
}

const IncrementTable: FC<IncrementTableProps> = props => {
    const {
        handleIncrementTableFetchWithPagination_d,
        clearIncrementTableState_d,
        handleDeleteIncrementTable_d,
        loading,
        pagination,
        list,
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingIncrementTables, setLoadingIncrementTables] = useState<boolean>(false);
    const [incrementTableDisplayModel, setIncrementTableDisplayModel] = useState<NestedGridDataModel>();

    useEffect(() => {
        refreshList(null, null, false);
        return () => {
            clearIncrementTableState_d();
        };

    }, [])


    useEffect(() => {
        if (list) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];


            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" },
            ];

            rows = list?.map(incrementTable => {


                const custom: KeyValue[] = incrementTable?.dataCaptured?.increments?.map((item, index) => {
                    return {
                        key: (index + 1) + ") " + "From Amount: " + item?.fromAmount + " To Amount: " + item?.toAmount + " Increment: " + item?.increment,
                        value: ""
                    }
                });

                const customData: CustomData[] = [
                    {
                        title: "Custom Data",
                        values: custom,
                    },
                ];
                let incrementTableName = incrementTable.name?.substring(0, 35);
                if (incrementTable?.name?.length > 35) incrementTableName += "...";

                let incrementTableDescription = incrementTable.description?.substring(0, 35);
                if (incrementTable?.description?.length > 35) incrementTableDescription += "...";

                const cells: Cell[] = [
                    { text: incrementTableName, align: "inherit", element: <>{incrementTableName}{incrementTable.source && incrementTable.source !== 'Internal' && <><br /><Chip color="primary" size="small" label={incrementTable.source} style={{ marginLeft: -8 }} /></>}</> },
                    { text: incrementTableDescription, align: "inherit" },
                ];

                return {
                    id: incrementTable.id,
                    cells: cells,
                    customData: customData
                };
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setIncrementTableDisplayModel(model);
        }

        var waitForGridRender = new Promise(r => setTimeout(r, 500));
        waitForGridRender.then(() => hideProgressSpinner());
    }, [list])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const handleAddEdit = (id?: string) => {
        id ? history.push(`/IncrementTableEdit/${id}`) : history.push(`/IncrementTableAdd`);
    }

    const handleDelete = (id: string) => {
        let incrementItem = list?.find(x => x.id === id);

        let dialogConfig: DialogConfig = {
            title: 'Confirm',
            message: `Are you sure you would like to delete the increment table ${incrementItem.name}`,
            locales: { submitText: 'Yes', cancelText: 'Cancel' },
            onCancel: () => {

            },
            onSubmit: () => {
                showProgressSpinner({ description: "Deleting Increment Table..." });
                handleDeleteIncrementTable_d(id, (res) => {
                    if (res.success) {
                        refreshList(selectedFilters, pagination);
                        ns.success("Increment Table deleted successfully");
                    }
                    else {
                        ns.error("Failed to delete the Increment Table")
                    }
                });
            }
        }
        ns.showDialog(dialogConfig);
    }

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingIncrementTables(true);
        else showProgressSpinner({ description: `Retrieving Auction Increments...` });
        handleIncrementTableFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingIncrementTables(false);
        })
    }

    const handlePageChange = (pagination?: any) => {
        refreshList(selectedFilters, pagination);
    }
    const handleFiltersChange = (filters?: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    return <div>
        <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
                Increment Tables
            </div>
        </div>
        <div className="table-responsive-md">
            <NestedAccordionGrid
                dataModel={incrementTableDisplayModel}
                onDelete={handleDelete}
                onEdit={handleAddEdit}
                onAdd={() => handleAddEdit()}
                selectedFilters={selectedFilters}
                pagination={pagination}
                onHandleChangePage={handlePageChange}
                onFiltersChange={handleFiltersChange}
                loading={loadingIncrementTables}
            />
        </div>
    </div>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleIncrementTableFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleIncrementTableFetchWithPagination(filters, pagination, onCompletedCallback)),
        handleDeleteIncrementTable_d: (incrementTableId: string, onCompletedCallback: (response: any) => void) => dispatch(handleDeleteIncrementTable(incrementTableId, onCompletedCallback)),
        clearIncrementTableState_d: () => dispatch(clearIncrementTableState())
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.incrementTable.loading,
    pagination: state.incrementTable.pagination,
    list: state.incrementTable.list,
})

export default connect(mapStateToProps, mapDispatchToProps)(IncrementTable);