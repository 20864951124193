import { FC, memo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import ns from "@/helpers/NotificationService";
import { ceil } from "lodash";
import { useCreateBid } from "prembid-shared-library-npm/networking";
import CurrencyLabel from "../../CurrencyComponent/CurrencyLabel";
import { LoadingButton } from "@mui/lab";
import { FaTimes } from "react-icons/fa";

interface Props {
    bidAmount: any;
    auctionId: any;
    lotId: any;
    profileId: any;
    onBidSuccessfullySubmitted: (paddleNumber: string) => void;
}

const BidButton: FC<Props> = (props) => {
    const { bidAmount, auctionId, lotId, profileId, onBidSuccessfullySubmitted } = props;
    const [confirm, setConfirm] = useState(false);

    const { mutate: createBid, isLoading: loading } = useCreateBid({
        onSuccess(data: any) {
            ns.success("Bid successfully submitted");
            onBidSuccessfullySubmitted(data?.paddleNumber);
            setConfirm(false);
        },
        onError() {
            setConfirm(false);
        },
    });

    const handleQuickBid = async () => {
        if (confirm) {
            const bid = {
                auctionId: auctionId,
                lotId: lotId,
                amount: ceil(bidAmount, 2),
                profileId: profileId,
                isTenderSubmission: false,
            };

            createBid(bid);
        } else {
            setConfirm(true);
        }
    };

    return (
        <>
            <div style={{ flexDirection: "row" }}>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                            <LoadingButton
                                style={{ height: 56 }}
                                onClick={handleQuickBid}
                                fullWidth
                                variant="contained"
                                className={
                                    confirm
                                        ? "btn-success p-3 text-white text-capitalize"
                                        : "btn-primary p-3 text-white text-capitalize"
                                }
                                loading={loading}
                            >
                                {confirm ? (
                                    "Confirm"
                                ) : (
                                        <Typography variant="subtitle1">
                                            <CurrencyLabel style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'wrap',
                                            }} textColour="#ffffff" value={bidAmount} />
                                        </Typography>
                                )}
                            </LoadingButton>
                            {
                                confirm &&
                                <Button onClick={() => setConfirm(false)}
                                    style={{ height: 56, width: 56 }}
                                    variant="contained"
                                    className="btn-danger p-3 text-white text-capitalize">
                                    <span className="btn-wrapper--icon">
                                        <FaTimes />
                                    </span>
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default memo(BidButton);