import React, { FC, memo } from "react";

interface ThumbnailProps {
	imageUrl: string;
}

const Thumbnail: FC<ThumbnailProps> = ({ imageUrl }) => {
	return (
		<div className="avatar-icon-wrapper avatar-icon-xl">
			<img
				style={{ objectFit: "cover", height: "80px", width: "80px" }}
				alt="..."
				src={imageUrl}
			/>
		</div>
	);
};

export default memo(Thumbnail);
