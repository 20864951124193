import React, { FC, memo } from "react";
import { HeaderButtons } from "../NestedAccordionGrid.types";

interface HeaderButtonListProps {
	headerButtons: HeaderButtons;
}

const HeaderButtonList: FC<HeaderButtonListProps> = ({ headerButtons }) => {
	return (
		<>
			{headerButtons.map((btn, index) => (
				<React.Fragment key={index}>{btn}</React.Fragment>
			))}
		</>
	);
};

export default memo(HeaderButtonList);
