import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    popover: {
        position: 'absolute'!,
        zIndex: 2!,
        top: '54px'!,
        left: '15px'!,
        opacity: '1'!,
        visibility: 'visible'!
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
}))

export default useStyles;