import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import LotTypeEditAdd from "@/pages/LotTypes/LotTypeEditAdd"

export default function LotTypeDialog({ isDialogOpened, handleCloseDialog }) {
    const [fullWidth] = useState(true);
    const [maxWidth] = useState<DialogProps['maxWidth']>('xl');

    const handleClose = (data?: any) => {
        handleCloseDialog(data);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                onClose={() => handleClose()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title"></DialogTitle>
                <DialogContent>
                    <LotTypeEditAdd isDialog={true} handleClose={handleClose} />
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
