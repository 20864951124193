import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    table: {
        minWidth: 650,
    },
    img: {
        height: 'auto',
        width: '240px',
        objectFit: 'contain'
    },
    imgGridItem: {
        height: 'auto',
        width: '180px'
    },
    cardGrid: {
        flexGrow: 1,
    },
    logo: {
        maxHeight: '100px',
        maxWidth: '300px',
        borderRadius: '10px'
    },
}))

export default useStyles;