import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { Image } from '@/components/ImageUploader/ImageUploader';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";

const ERROR = 'AUCTIONS_CRUD_ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const SET_AUCTION = 'SET_AUCTION';
const CLEAR_AUCTION = 'CLEAR_AUCTION';
const GET_AUCTIONS_SUCCESS = 'GET_AUCTIONS_SUCCESS';
const GET_AUCTIONS_WITH_PAGINATION_SUCCESS = 'GET_AUCTIONS_WITH_PAGINATION_SUCCESS';
const GET_AUCTION_SUCCESS = 'GET_AUCTION_SUCCESS';
const ADD_AUCTION_SUCCESS = 'ADD_AUCTION_SUCCESS';
const UPDATE_AUCTION_SUCCESS = 'UPDATE_AUCTION_SUCCESS';
const DELETE_AUCTION_SUCCESS = 'DELETE_AUCTION_SUCCESS';
const LOTS_FETCH_SUCCESS = 'LOTS_FETCH_SUCCESS';
const LOTS_FETCH_WITH_PAGINATION_SUCCESS = 'LOTS_FETCH_WITH_PAGINATION_SUCCESS';
const AUCTION_IMAGE_FETCH_SUCCESS = 'AUCTION_IMAGE_FETCH_SUCCESS';
export const CLEAR_LOTS = 'CLEAR_LOTS';

//Images
export const IMAGE_CREATE_SUCCESS = 'IMAGE_CREATE_SUCCESS';
export const IMAGE_UPDATE_SUCCESS = 'IMAGE_UPDATE_SUCCESS';
export const IMAGE_GET_ALL_SUCCESS = 'IMAGE_GET_ALL_SUCCESS';
export const AUCTION_HERO_IMAGE_ADDED = 'AUCTION_HERO_IMAGE_ADDED ';
export const SAVE_IMAGES_ERROR = 'SAVE_IMAGES_ERROR';
export const CLEAR_AUCTION_IMAGES = 'CLEAR_AUCTION_IMAGES';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    auctions: [],
    auctionId: undefined,
    auction: undefined,
    lots: undefined,
    success: false,
    editSuccess: false,
    addSuccess: false,
    pagination: null,

    //Images
    auctionHeroImage: undefined,
    auctionImage: undefined,
    imagesSuccess: false,
    imagesError: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false, addSuccess: false, editSuccess: false }
        case SET_AUCTION: return { ...state, error: false, errorText: '', auction: action.payload, auctionId: action.payload.id }
        case CLEAR_AUCTION: return { ...state, error: false, errorText: '', auction: undefined, auctionId: undefined, lots: undefined, auctionImage: undefined }
        case GET_AUCTIONS_SUCCESS: return { ...state, error: false, errorText: '', addSuccess: false, editSuccess: false, auctions: action.payload, auction: undefined, auctionId: undefined }
        case GET_AUCTIONS_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: '', addSuccess: false, editSuccess: false, auctions: action.payload.list, auction: undefined, auctionId: undefined, pagination: action.payload.pagination }
        case GET_AUCTION_SUCCESS: return { ...state, error: false, errorText: '', addSuccess: false, editSuccess: false, auction: action.payload, auctionId: undefined, reload: !state.reload }
        case ADD_AUCTION_SUCCESS: return { ...state, error: false, errorText: '', auctionId: action.payload, success: true, addSuccess: true, editSuccess: false }
        case UPDATE_AUCTION_SUCCESS: return { ...state, success: true, addSuccess: false, editSuccess: true, }
        case DELETE_AUCTION_SUCCESS: return { ...state, error: false, errorText: '', reload: !state.reload }
        case LOTS_FETCH_SUCCESS: return { ...state, error: false, errorText: '', lots: action.payload }
        case LOTS_FETCH_WITH_PAGINATION_SUCCESS: return { ...state, error: false, errorText: '', lots: action.payload.list, pagination: action.payload.pagination }
        case CLEAR_LOTS: return { ...state, error: false, errorText: '', lots: undefined }

        case IMAGE_CREATE_SUCCESS: return { ...state, imagesSuccess: true }
        case IMAGE_UPDATE_SUCCESS: return { ...state, imagesSuccess: true }
        case AUCTION_HERO_IMAGE_ADDED: return { ...state, auctionHeroImage: action.payload }
        case CLEAR_AUCTION_IMAGES: return { ...state, auctionImage: undefined }
        case SAVE_IMAGES_ERROR: return { ...state, success: false, addSuccess: false, editSuccess: false, error: true, errorText: action.payload }
        case AUCTION_IMAGE_FETCH_SUCCESS: return { ...state, error: false, errorText: '', auctionImage: action.payload }
        default: return state
    }
}

// Action creators
export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const setAuction = (auction: any) => {
    return { type: SET_AUCTION, payload: auction }
}

export const clearAuction = () => {
    return { type: CLEAR_AUCTION, payload: CLEAR_AUCTION }
}

export const getAuctionsSuccess = (auctions: any) => {
    return { type: GET_AUCTIONS_SUCCESS, payload: auctions }
}

export const getAuctionsWithPaginationSuccess = (payload: any) => {
    return { type: GET_AUCTIONS_WITH_PAGINATION_SUCCESS, payload: payload }
}

export const getAuctionSuccess = (auction: any) => {
    return { type: GET_AUCTION_SUCCESS, payload: auction }
}

export const updateAuctionSuccess = () => {
    return { type: UPDATE_AUCTION_SUCCESS, payload: UPDATE_AUCTION_SUCCESS }
}

export const addAuctionSuccess = (id: string) => {
    return { type: ADD_AUCTION_SUCCESS, payload: id }
}

export const lotsFetchSuccess = (lots: any) => {
    return { type: LOTS_FETCH_SUCCESS, payload: lots }
}

export const lotsFetchWithPaginationSuccess = (payload: any) => {
    return { type: LOTS_FETCH_WITH_PAGINATION_SUCCESS, payload }
}

export const handleReload = () => {
    return { type: DELETE_AUCTION_SUCCESS, payload: DELETE_AUCTION_SUCCESS }
}

export const auctionImageFetchSuccess = (auctionImage) => {
    return { type: AUCTION_IMAGE_FETCH_SUCCESS, payload: auctionImage }
}

export const auctionHeroImageAdded = (images: any) => {
    return { type: AUCTION_HERO_IMAGE_ADDED, payload: images }
}

export const saveImagesError = (errorText: string) => {
    return { type: SAVE_IMAGES_ERROR, payload: errorText }
}

export const clearAuctionImages = () => {
    return { type: CLEAR_AUCTION_IMAGES, payload: CLEAR_AUCTION_IMAGES }
}

export const clearLots = () => {
    return { type: CLEAR_LOTS, payload: CLEAR_LOTS }
}

// export const auctionImageFetchError = (errorText) => {
//     return { type: AUCTION_IMAGE_FETCH_SUCCESS, payload: auctionImage }
// }

//Thunks
export const handleAuctionsFetch = (onCompletedCallback?: () => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.auction.getAuctions, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(getAuctionsSuccess(res.response))
                } else {
                    dispatch(error(res.error))
                }
                if (onCompletedCallback) onCompletedCallback();
            })
    }
}

export const handleAuctionsFetchWithPagination = (filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.auction.getAuctions, {
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(getAuctionsWithPaginationSuccess(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleAuctionFetch = (id: string, onCompletedCallback?: (res:any)=>void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.auction.getAuctionById, null, { id: id })
            .then(function (res) {
                if (res.success) {
                    dispatch(getAuctionSuccess(res.response))
                } else {
                    dispatch(error(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotEditAddAuctionFetch = (id: string) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.auction.getAuctionById, null, { id: id })
            .then(function (res) {
                if (res.success) {
                    const auction = res.response

                    const time = {
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }

                    const timeWindow = {
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    }

                    time.hours = ~~(auction.defaultBidExtension / 3600);
                    time.minutes = ~~((auction.defaultBidExtension % 3600) / 60);
                    time.seconds = ~~auction.defaultBidExtension % 60;

                    timeWindow.hours = ~~(auction.defaultBidExtensionWindow / 3600);
                    timeWindow.minutes = ~~((auction.defaultBidExtensionWindow % 3600) / 60);
                    timeWindow.seconds = ~~auction.defaultBidExtensionWindow % 60;

                    const newAuction = {
                        id: auction.id,
                        depositAmount: auction.depositAmount,
                        name: auction.name,
                        description: auction.description,
                        lotTypeId: auction.defaultLotTypeId,
                        venueId: auction.defaultVenueId,
                        auctionType: auction.auctionType,
                        streamUrl: auction.streamUrl,
                        increment: auction.defaultMinimumIncrement,
                        stcStatus: auction.defaultSTCStatus,
                        defaultShowReserve: auction.defaultShowReserve,
                        defaultShowPaddleNumber: auction.defaultShowPaddleNumber,
                        defaultWatermarkImages: auction.defaultWatermarkImages,
                        commission: auction.defaultCommission,
                        startTime: auction.startDateTimeAt,
                        finishTime: auction.endDateTimeAt,
                        isActive: auction.isActive,
                        hours: time.hours,
                        minutes: time.minutes,
                        seconds: time.seconds,
                        contactId: auction.defaultContactId,
                        defaultBiddingCalculationId: auction.defaultBiddingCalculationId,
                        defaultIncrementTableId: auction.defaultIncrementTableId,
                        timeWindowHours: timeWindow.hours,
                        timeWindowMinutes: timeWindow.minutes,
                        timeWindowSeconds: timeWindow.seconds
                    }

                    dispatch(setAuction(newAuction))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleAuctionAdd = (formFields: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        let auction:any = {
            DefaultLotTypeId: formFields.lotTypeId,
            DefaultVenueId: formFields.venueId,
            DefaultContactId: formFields.contactId,
            DepositAmount: Number(formFields.depositAmount),
            Name: formFields.name,
            Description: formFields.description,
            AuctionType: Number(formFields.auctionType),
            StreamUrl: formFields.streamUrl,
            DefaultMinimumIncrement: Number(formFields.increment),
            DefaultStcStatus: formFields.stcStatus ? 1 : 0,
            DefaultShowReserve: formFields.defaultShowReserve,
            DefaultShowPaddleNumber: formFields.defaultShowPaddleNumber,
            DefaultWatermarkImages: formFields.defaultWatermarkImages,
            DefaultCommission: Number(formFields.commission),
            DefaultBiddingCalculationId: formFields.defaultBiddingCalculationId,
            DefaultIncrementTableId: formFields.defaultIncrementTableId,
            StartDateTimeAt: new Date(formFields.startTime).toISOString(),
            EndDateTimeAt: new Date(formFields.finishTime).toISOString(),
            IsActive: formFields.isActive,
            DefaultBidExtension: (Number(formFields.hours) * 3600) + (Number(formFields.minutes) * 60) + Number(formFields.seconds),
            DefaultBidExtensionWindow: (Number(formFields.timeWindowHours) * 3600) + (Number(formFields.timeWindowMinutes) * 60) + Number(formFields.timeWindowSeconds),
            BankDetailsId: formFields.bankDetailsId,
            DefaultRollingTimerExtention: (Number(formFields.rollingTimerHours) * 3600) + (Number(formFields.rollingTimerMinutes) * 60) + Number(formFields.rollingTimerSeconds),
            DefaultApplyInvoicing: formFields.defaultApplyInvoicing,
        }

        if (auction.DefaultRollingTimerExtention === 0)
            auction.DefaultRollingTimerExtention = null;

        return requestClient.post(apiPaths.auction.Add, auction)
            .then(function (res) {
                if (res.success) {
                    handleImagesUploads('add', res.response, images, documents, formFields.defaultWatermarkImages, dispatch, onCompletedCallback)
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleAuctionUpdate = (formFields: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => {

    let auction:any = {
        Id: formFields.id,
        DefaultLotTypeId: formFields.lotTypeId,
        DefaultVenueId: formFields.venueId,
        DefaultContactId: formFields.contactId,
        DepositAmount: Number(formFields.depositAmount),
        Name: formFields.name,
        Description: formFields.description,
        AuctionType: formFields.auctionType,
        StreamUrl: formFields.streamUrl,
        DefaultMinimumIncrement: Number(formFields.increment),
        DefaultStcStatus: formFields.stcStatus ? 1 : 0,
        DefaultShowReserve: formFields.defaultShowReserve,
        DefaultShowPaddleNumber: formFields.defaultShowPaddleNumber,
        DefaultWatermarkImages: formFields.defaultWatermarkImages,
        DefaultCommission: Number(formFields.commission),
        DefaultBiddingCalculationId: formFields.defaultBiddingCalculationId,
        DefaultIncrementTableId: formFields.defaultIncrementTableId,
        StartDateTimeAt: new Date(formFields.startTime).toISOString(),
        EndDateTimeAt: new Date(formFields.finishTime).toISOString(),
        IsActive: formFields.isActive,
        DefaultBidExtension: (Number(formFields.hours) * 3600) + (Number(formFields.minutes) * 60) + Number(formFields.seconds),
        DefaultBidExtensionWindow: (Number(formFields.timeWindowHours) * 3600) + (Number(formFields.timeWindowMinutes) * 60) + Number(formFields.timeWindowSeconds),
        BankDetailsId: formFields.bankDetailsId,
        DefaultRollingTimerExtention: (Number(formFields.rollingTimerHours) * 3600) + (Number(formFields.rollingTimerMinutes) * 60) + Number(formFields.rollingTimerSeconds),
        DefaultApplyInvoicing: formFields.defaultApplyInvoicing,
    }

    if (auction.DefaultRollingTimerExtention === 0)
        auction.DefaultRollingTimerExtention = null;

    return function (dispatch: any) {
        return requestClient.post(apiPaths.auction.Update, auction)
            .then(function (res) {
                if (res.success) {
                    handleImagesUploads('update', formFields.id, images, documents, formFields.defaultWatermarkImages, dispatch, onCompletedCallback);
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleAuctionDelete = (id: string, onCompletedCallback?: (result: any) => void) => {
    return function (dispatch: any) {
        const deleteData = {
            Id: id
        }

        return requestClient.post(apiPaths.auction.Delete, deleteData)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            })
    }
}

export const handleLotsFetch = (auctionId: string, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.lot.getLots, null, { auctionId })
            .then(function (res) {
                if (res.success) {
                    let lots = res.response;
                    dispatch(lotsFetchSuccess(lots))
                } else {
                    dispatch(error(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotsFetchWithPagination = (auctionId: string,
    filters?: any,
    pagination?: any,
    onCompletedCallback?: (response: any) => void) => {

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lot.getLots, {
            auctionId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {
                dispatch(lotsFetchWithPaginationSuccess(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}


export const handleImageFetch = (auctionId: string) => {

    return (dispatch: any) => {
        return requestClient.get(apiPaths.auction.retrieveAllAuctionImagesByAuctionId, { auctionId }, null)
            .then(function (resp) {
                if (resp.success) {

                    const jsonData = resp.response;

                    if (jsonData[0]) {


                        const newAuctionImageData = [{
                            auctionId: jsonData[0].auctionId,
                            createdAt: jsonData[0].createdAt,
                            createdByUserId: jsonData[0].createdByUserId,
                            deletedAt: jsonData[0].deletedAt,
                            deletedByUserId: jsonData[0].deletedByUserId,
                            id: jsonData[0].id,
                            imageName: jsonData[0].imageName,
                            imageUrl: jsonData[0].imageUrl,
                            vendorId: jsonData[0].vendorId,
                            isDisplayImage: true,
                        }]

                        dispatch(auctionImageFetchSuccess(newAuctionImageData));

                    }


                } else {
                    dispatch(error(resp.error))
                }
            })
    }
}

const updateCounts = (obj: any, label: string) => {
    obj.completed = obj.completed + 1;
    showProgressSpinner({ description: `Uploading ${label} ${obj.completed} of ${obj.total}...` });
}

const handleImagesUploads = async (mode: any, auctionId: string, files: Image[], documents: any[], addWatermark: boolean, dispatch: any, onCompletedCallback: (res: any) => void) => {
    const newFiles = files.filter(file => file.id === "");

    if (newFiles.length > 0) {
        let imagesToUpload = newFiles.map(image => {
            return {
                auctionId,
                isDisplayImage: true,
                auctionImageUploadData: [{ imageBase64String: image.imageUrl }],
                addWatermark
            }
        });

        let counts = {
            completed: 0,
            total: imagesToUpload.length
        };
        updateCounts(counts, 'image');

        let failedImages: any[] = [];
        while (imagesToUpload.length) {
            await Promise.all(imagesToUpload.splice(0, 10).map((imageToUpload, index, list) => requestClient.post(apiPaths.auction.createAuctionImage, imageToUpload).then(function (resp) {
                if (resp?.success) {
                    updateCounts(counts, 'image');
                }
                else {
                    failedImages.push({ image: imagesToUpload, res: resp });
                }
            })));
        }
        if (failedImages.length > 0) {
            onCompletedCallback({ success: false, error: ` details ${mode === 'add' ? 'saved' : 'updated'} successfully, but some images failed to upload!` });
        }
        else {
            showProgressSpinner({ description: `Completed Uploading Images` });
            handleDocumentUploads(mode, auctionId, documents, dispatch, onCompletedCallback);
        }
    }
    else
        handleDocumentUploads(mode, auctionId, documents, dispatch, onCompletedCallback);
}

const handleDocumentUploads = async (mode: any, auctionId: string, documents: any[], dispatch: any, onCompletedCallback: (res: any) => void) => {
    const newDocuments = documents.filter(doc => doc.fileUploadData || !doc.documentId);

    if (documents.length > 0) {
        let documentsToUpload = newDocuments.map(document => {
            return {
                documentTemplateId: document.documentTemplateId,
                auctionId: auctionId,
                documentUploadData: [{ documentBase64String: document.fileUploadData.base64 }]
            }
        });

        let counts = {
            completed: 0,
            total: documentsToUpload.length
        };
        updateCounts(counts, 'document');

        let failedDocuments: any[] = [];
        while (documentsToUpload.length) {
            await Promise.all(documentsToUpload.splice(0, 5).map((documentToUpload, index, list) => requestClient.post(apiPaths.document.upsertRegistrationDocument, documentToUpload).then(function (resp) {
                if (resp?.success) {
                    updateCounts(counts, 'document');
                }
                else {
                    failedDocuments.push({ image: documentToUpload, res: resp });
                }
            })));
        }
        if (failedDocuments.length > 0) {
            onCompletedCallback({ success: false, error: ` details and images ${mode === 'add' ? 'saved' : 'updated'} successfully, but some documents failed to upload!` });
            hideProgressSpinner();
        }
        else {
            showProgressSpinner({ description: `Completed Uploading Documents` });
            dispatch(mode === 'add' ? addAuctionSuccess(auctionId) : updateAuctionSuccess());
            onCompletedCallback({ success: true, response: auctionId });
            hideProgressSpinner();
        }
    }
    else {
        dispatch(mode === 'add' ? addAuctionSuccess(auctionId) : updateAuctionSuccess());
        onCompletedCallback({ success: true, response: auctionId });
        hideProgressSpinner();
    }
}

export const handleAuctionImageDelete = (imageId: string) => {

    let del = {
        id: imageId,
    }

    return (dispatch: any) => {
        return requestClient.post(apiPaths.auction.deleteAuctionImage, del)
            .then(function (resp) {
                if (resp.success) {
                }
                else {
                    dispatch(saveImagesError(resp.error));
                }
            });
    }
}