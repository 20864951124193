import React, { useState } from "react";
import { connect } from "react-redux";
import { clearError, handleDelete, handleVenuesFetchWithPagination } from "./store/Venue";
import useHistory from '@/hooks/useHistory';
import {
    Cell,
    Header,
    Row,
    VenueData,
    NestedGridDataModel,
    NestedAccordionGrid,
} from "@/components/NestedAccordionGrid";
import ConfirmationDialog from "@/components/Modal/ConfirmationDialog";
import ns from '@/helpers/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { Button, Tooltip } from "@mui/material";
import { FaRedo } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))



interface Props {
    clearError_d: () => void,
    handleVenuesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => void,
    error: boolean,
    errorText: string | any,
    reload: boolean,
    venues: any,
    loading: boolean,
    pagination: any
}

const VenueList: React.FC<Props> = (props) => {
    const {
        clearError_d,
        handleVenuesFetchWithPagination_d,
        handleDelete_d,
        error,
        errorText,
        reload,
        venues,
        loading,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();
    const [venuesDisplayModel, setVenuesDisplayModel] =
        React.useState<NestedGridDataModel>();
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: "",
    });

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingVenues, setLoadingVenues] = useState<boolean>(true);

    React.useEffect(() => {
        showProgressSpinner({ description: "Retrieving Venues..." });
        refreshList(selectedFilters, pagination, false);
    }, [reload]);

    React.useEffect(() => {
        if (venues) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" },
            ];

            venues.forEach((venue) => {
                let venueName = venue.name?.substring(0, 35);
                if (venue.name.length > 35) venueName += "...";

                let venueDecription = venue.description?.substring(0, 35);
                if (venue.description.length > 35) venueDecription += "...";

                const cells: Cell[] = [
                    { text: venueName, align: "inherit" },
                    { text: venueDecription, align: "inherit" },
                ];

                const venueData: VenueData = {
                    name: venue.name,
                    description: venue.description,
                    addressLine1: venue.address.addressLine1,
                    addressLine2: venue.address.addressLine2,
                    country: venue.address.country,
                    state: venue.address.state,
                    city: venue.address.city,
                    zip: venue.address.zip,
                    lng: venue.address.longitude,
                    lat: venue.address.latitude,
                };

                rows.push({
                    id: venue.id,
                    cells: cells,
                    //customData: customData,
                    venueData: venueData,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setVenuesDisplayModel(model);
        }

        var waitForGridRender = new Promise((r) => setTimeout(r, 500));
        waitForGridRender.then(() => {
            hideProgressSpinner();
        });
    }, [venues]);

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    React.useEffect(() => {
        if (error) {
            ns.error(errorText);
            clearError_d()
        }
    }, [error]);

    const navAddEdit = (venueId) => {
        let path = "/VenueEdit";
        if (venueId !== "") path += "?venueId=" + venueId;
        history.push(path);
    };

    const onDelete = (id: string) => {
        showDeleteModal(true, id);
    };

    const onEdit = (id: string) => {
        navAddEdit(id);
    };

    //Modal
    const onDeleteModalClosed = () => {
        showDeleteModal(false, "");
    };

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting Venue..." });
        handleDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Venue deleted successfully");
                refreshList();
            }
            else {
                ns.error(response.error ?? "Failed to delete the venue")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    };

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    };

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingVenues(true);
        else showProgressSpinner({ description: `Retrieving Venues...` });
        handleVenuesFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingVenues(false);
        })
    }

    const handlePageChange = (pagination?: any) => {
        refreshList(selectedFilters, pagination);
    }

    const handleFiltersChange = (filters?: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    return (
        <div>

            {!loading && (
                <>
                    <div className="card-header py-3">
                        <div className="card-header--title font-size-lg">Venues</div>
                    </div>
                    <div className="table-responsive-md">
                        <NestedAccordionGrid
                            dataModel={venuesDisplayModel}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onAdd={() => { navAddEdit(""); }}
                            selectedFilters={selectedFilters}
                            pagination={pagination}
                            onHandleChangePage={handlePageChange}
                            onFiltersChange={handleFiltersChange}
                            loading={loadingVenues}
                        />

                        {deleteModal.open && <ConfirmationDialog
                            open={deleteModal.open}
                            payload={deleteModal.id}
                            okButtonText="Ok"
                            cancelButtonText="Cancel"
                            description="Are you sure you want to delete this venue?"
                            title="Delete Venue"
                            onCancel={onDeleteModalClosed}
                            onOk={onDeleteModalOk}
                        />}

                    </div>
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleDelete(Id, onCompletedCallback)),
        handleVenuesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleVenuesFetchWithPagination(filters, pagination, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    error: state.venue.error,
    errorText: state.venue.errorText,
    reload: state.venue.reload,
    venues: state.venue.venues,
    loading: state.progressSpinner.loading,
    pagination: state.venue.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueList);
