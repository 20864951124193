import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { handleLotsFetch, handleLotDelete, clearLots, handleIndividualLotsUnlinking, clearLot, clearAuction, handleLotsFetchWithPagination } from './store/Lots';
import {NestedAccordionGrid, CustomData, Cell, Header, Row, KeyValue, NestedGridDataModel } from '@/components/NestedAccordionGrid'
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import { Button, Tooltip, FormControlLabel, Switch } from '@mui/material';
import { FaGavel, FaRedo, FaUnlink } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";
import Chip from '../../components/Chip/Chip';

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleLotsFetch_d: (searchString: string) => void,
    handleLotsFetchWithPagination_d: (onlyIndividualLots: boolean, filters: string, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleLotDelete_d: (id: string, onCompletedCallback?: (result: any) => void) => void,
    handleIndividualLotsUnlinking_d: (id: string, onCompletedCallback: (result: any) => void) => void,
    clearLots_d: () => void,
    clearLot_d: () => void,
    clearAuction_d: () => void,
    lots: any,
    pagination: any,
    settings: any,
    loading: boolean
}

const Lots: React.FC<Props> = props => {
    const {
        handleLotsFetchWithPagination_d,
        handleLotDelete_d,
        clearLots_d,
        clearLot_d,
        clearAuction_d,
        handleIndividualLotsUnlinking_d,
        lots,
        settings,
        loading,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const [showLinkedAuctions, setShowLinkedAuctions] = useState<boolean>(true);
    const [onlyIndividualLots, setOnlyIndividualLots] = useState<boolean>(false);
    const [lotsDisplayModel, setLotsDisplayModel] = React.useState<NestedGridDataModel>();
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    });
    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingLots, setLoadingLots] = useState<boolean>(true);

    const statusBadge = (active: boolean) => {
        switch (active) {
            case true: {
                return <div className="badge badge-success badge-pill text-capitalize">Yes</div>
            }
            case false: {
                return <div className="badge badge-danger badge-pill text-capitalize">No</div>
            }
        }
    }

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingLots(true);
        else showProgressSpinner({ description: `Retrieving ${settings.LotName}s...` });
        handleLotsFetchWithPagination_d(onlyIndividualLots, filters, pagination, (res) => {
            setLoadingLots(false);
        });
    }

    useEffect(() => {
        clearLot_d();
        clearAuction_d();
        refreshList(null, null, false);
        return (() => {
            clearLots_d();
        });
    }, [])

    useEffect(() => {
        if (lots) {
            hideProgressSpinner();

            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "", align: "inherit", canFilter: false, canSort: false },
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" },
                { title: 'Linked To ' + settings.AuctionName, align: "inherit", canFilter: true },
                { title: "Available", align: "inherit", canFilter: true },
                { title: "Sold", align: "inherit", canFilter: true },
            ];

            lots.filter(x => showLinkedAuctions || (!showLinkedAuctions && !x.auctionId)).forEach((lot) => {
                let custom: KeyValue[] = [];

                lot?.dataCaptured?.additionalProperties?.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        custom.push({ ...x, key: x.name });
                });

                let lotName = lot.name?.substring(0, 35);
                if (lot.name.length > 35) lotName += "...";

                let lotDescription = lot.description?.substring(0, 50);
                if (lot.description.length > 50) lotDescription += "...";

                const cells: Cell[] = [
                    {
                        text: '', align: "inherit", element: <>{lot.source && lot.source !== 'Internal' ? <>{lot.uniqueCode}{lot.skuNumber ? <><br />{lot.skuNumber}</> : <></>}<br /><Chip color="primary" size="small" label={lot.source} style={{ marginLeft: -8 }} /></> : <>{lot.uniqueCode}{lot.skuNumber ? <><br />{lot.skuNumber}</> : <></>}</>}</>
                    },
                    { text: lotName, align: "inherit" },
                    { text: lotDescription, align: "inherit" },
                    { text: lot.auctionId ? `Linked` : `Not Linked`, align: "inherit", element: statusBadge(lot.auctionId ? true : false) },
                    { text: lot.isActive ? 'Available' : 'Unavailable', align: "inherit", element: statusBadge(lot.isActive) },
                    { text: lot.sold ? 'Sold' : 'Not Sold', align: "inherit", element: statusBadge(lot.sold) },
                ];

                const customData: CustomData[] = [
                    {
                        title: "Custom Data", values: custom
                    },
                    {
                        title: `${settings.LotName} Data`, values: [
                            { key: `${settings.LotName} Type`, value: lot.lotType.name },
                            { key: "STC Status", value: lot.stcStatus == '0' ? 'No' : 'Yes' },
                            { key: `Registration Deposit (${settings.CurrencySymbol})`, value: lot.depositAmount },
                            { key: `Default Minimum Increment (${settings.CurrencySymbol})`, value: lot.defaultMinimumIncrement },
                            { key: "Buyer's Commission %", value: lot.commission },
                            { key: `Opening Bid (${settings.CurrencySymbol})`, value: lot.startingPrice },
                            { key: `Reserve Price (${settings.CurrencySymbol})`, value: lot.reservePrice },
                            { key: `Guide Price (${settings.CurrencySymbol})`, value: lot.guidePrice },
                            { key: "SKU Number", value: lot.skuNumber },
                        ]
                    }
                ];

                rows.push({
                    id: lot.id,
                    cells: cells,
                    customData: customData,
                    actionButtons: lot.auctionId ? [
                        <Tooltip title={`Edit ${settings.AuctionName}`} >
                            <Button onClick={() => viewAuction(lot.auctionId)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" style={{ background: '#2b2b2b' }}>
                                <FaGavel />
                            </Button>
                        </Tooltip>,
                        <Tooltip title={`Unlink ${settings.LotName} from ${settings.AuctionName}`}>
                            <Button onClick={() => {


                                ns.showDialog({
                                    title: `Confirm Unlink ${settings.LotName}`,
                                    content: <div>
                                        <p>Are you sure you want to unlink the {settings.LotName}?</p>
                                    </div>,
                                    onSubmit: () => {
                                        showProgressSpinner({ description: `Unlinking ${settings.LotName}s from ${settings.AuctionName}...` })
                                        handleIndividualLotsUnlinking_d(lot.id, (res: any) => {
                                            hideProgressSpinner();
                                            if (res.success) {
                                                refreshList(selectedFilters, pagination);
                                                ns.success(`Successfully unlinked the ${settings.LotName}`);
                                            }
                                            else {
                                                ns.error(`Failed to unlink the ${settings.LotName}s`);
                                            }
                                        })
                                    },
                                    onCancel: () => { },
                                    locales: {
                                        submitText: 'Ok'
                                    }
                                });


                            }} size="small" className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaUnlink />
                            </Button>
                        </Tooltip>
                    ] : []
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setLotsDisplayModel(model);
        }

        hideProgressSpinner();
    }, [lots, showLinkedAuctions])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const viewAuction = (auctionId: string) => {
        clearLots_d();
        if (auctionId) {
            history.push(`/AuctionEdit?auctionId=${auctionId}&tabIndex=${0}&backRedirect=${'Lots'}`);
        }
    }

    const onLotAdd = () => {
        clearLot_d();
        clearAuction_d();
        lotNavAddEdit('');
    }

    const onLotEdit = (id: any) => {
        lotNavAddEdit(id);
    }

    const lotNavAddEdit = (lotId) => {
        let path = '/LotEdit?backRedirect=Lots'

        if (lotId && lotId !== '') {
            path += '&lotId=' + lotId

            let lot = lots.find(x => x.id === lotId)
            if (lot?.auctionId) {
                path += '&auctionId=' + lot.auctionId;
            }
        }

        history.push(path);
    }

    const onLotDelete = (id: any) => {
        showDeleteModal(true, id);
    }

    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting " + settings.LotName + "..." });
        handleLotDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Lot deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the lot")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    }

    const additionalFilters = [
        <FormControlLabel control={<Switch checked={showLinkedAuctions} onChange={(e) => setShowLinkedAuctions(e.target.checked)} id="showLinked" className="mt-3 m-2 p-2 switch-medium toggle-switch-success" />} labelPlacement="start" label={`Show Linked ${settings.AuctionName}s`} id="showLinked" />
    ];

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateLotFilters = [
        {
            description: `Linked To ${settings.AuctionName}`,
            propertyMap: 'linkedToAuction',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        },
        {
            description: 'Available',
            propertyMap: 'available',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        },
        {
            description: 'Sold',
            propertyMap: 'sold',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        }
    ]

    return <div>
        {!loading && (<>
            <div className="card-header py-3">
                <div className="card-header--title font-size-lg">
                    {settings.LotName + "s"}
                </div>
            </div>
            <div className="table-responsive-md">
                <NestedAccordionGrid
                    selectedFilters={selectedFilters}
                    dataModel={lotsDisplayModel}
                    onDelete={onLotDelete}
                    onEdit={onLotEdit}
                    onAdd={onLotAdd}
                    pagination={pagination}
                    onFiltersChange={handleFiltersChange}
                    availableFilters={generateLotFilters}
                    onHandleChangePage={handlePageChange}
                    loading={loadingLots}
                />
            </div>
            {deleteModal.open && <ConfirmationDialog open={deleteModal.open} payload={deleteModal.id} okButtonText="Ok" cancelButtonText="Cancel" description={"Are you sure you want to delete this " + settings.LotName + "?"} title={"Delete " + settings.LotName} onCancel={onDeleteModalClosed} onOk={onDeleteModalOk} />}
        </>)}
    </div>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleLotsFetch_d: (searchString: string) => dispatch(handleLotsFetch(searchString)),
        handleLotsFetchWithPagination_d: (onlyIndividualLots: boolean, filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleLotsFetchWithPagination(onlyIndividualLots, filters, pagination, onCompletedCallback)),
        handleLotDelete_d: (id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleLotDelete(id, onCompletedCallback)),
        handleIndividualLotsUnlinking_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleIndividualLotsUnlinking(id, onCompletedCallback)),
        clearLots_d: () => dispatch(clearLots()),
        clearLot_d: () => dispatch(clearLot()),
        clearAuction_d: () => dispatch(clearAuction()),
    }
}

const mapStateToProps = (state: any) => ({
    lots: state.lots.lots,
    pagination: state.lots.pagination,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(Lots);
