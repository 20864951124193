import React, { FC, memo, useReducer } from "react";
import {
	TableRow,
	TableCell,
	Collapse,
	IconButton,
	Button,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Tooltip,
} from "@mui/material";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FaTrashAlt, FaEdit } from "react-icons/fa";

import VenueDisplay from "./VenueDisplay";
import NestedAccordionCell from "./NestedAccordionCell";
import { OnDeleteHandler, OnEditHandler, Row } from "../NestedAccordionGrid.types";

interface NestedAccordionGridRowProps {
	row: Row;
	setExpanded: (data: any) => void;
	showEdit:boolean;
	showDelete:boolean;
	onEdit?: OnEditHandler;
	onDelete?: OnDeleteHandler;
	columnIndexOfLotNumber?:number;
	lotNumberRowRef: (ref:HTMLElement)=>void;
	openRow:boolean;
}

const NestedAccordionGridRow:FC<NestedAccordionGridRowProps> = (props) => {
	const {
		row,
		setExpanded,
		showEdit,
		onEdit,
		showDelete,
		onDelete,
		columnIndexOfLotNumber,
		lotNumberRowRef,
		openRow,
	} = props;

	const actionButtons = row.actionButtons ? row.actionButtons : [];

	const [internalExpanded, setInternalExpanded] = useReducer((state: any, action: any) => {
		switch (action.type) {
			case "add":
				const foundItem = state?.findIndex((row: any) => row[0] === action.payload[0] && row[1] === action.payload[1])

				if (foundItem !== undefined && foundItem > -1)
					return state;
				return [...state, action.payload];
			case "remove":
				let tempArray = [...state];
				const index = state?.findIndex(
					(row: any) =>
						row[0] === action.payload[0] && row[1] === action.payload[1]
				);
				if (index !== undefined && index > -1) {
					tempArray.splice(index, 1);
				}
				return tempArray;
			default:
				return { ...state };
		}
	}, [] as any[]);

	return (
		<>
			<TableRow>
				{row.cells.map((rowData, index) => (
					<TableCell key={index} align={rowData.align}>
						<NestedAccordionCell
							ref={ref=>{
								if(ref && index === columnIndexOfLotNumber ){
									lotNumberRowRef(ref);
								}
							}}
							cellEl={rowData.element}
							text={rowData.text}
							image={rowData.thumbnailUrl}
						/>
					</TableCell>
				))}
				<TableCell align="right">
					{row.customData || row.venueData ? (
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => {
								if (openRow)
									setExpanded({ type: "remove", payload: [row.id, undefined] });
								else setExpanded({ type: "add", payload: [row.id, undefined] });
							}}>
							{openRow ? <MdExpandLess /> : <MdExpandMore />}
						</IconButton>
					) : (
						<></>
					)}
					{actionButtons.map((btn, index) => (
						<React.Fragment key={index}>{btn}</React.Fragment>
					))}
					{showEdit && (
						<Tooltip arrow title="Edit" placement="bottom">
							<Button
								onClick={() => {
									if(onEdit) onEdit(row.id)
								}}
								size="small"
								className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
								<FaEdit />
							</Button>
						</Tooltip>
					)}
					{showDelete && (
						<Tooltip arrow title="Delete" placement="bottom">
							<Button
								onClick={() =>{
									if(onDelete) onDelete(row.id);
								}}
								size="small"
								className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
								<FaTrashAlt />
							</Button>
						</Tooltip>
					)}
				</TableCell>
			</TableRow>

			{row.customData || row.venueData ? (
				<TableRow>
					<TableCell
						style={{
							paddingTop: 0,
							paddingBottom: 0,
							paddingLeft: 0,
							paddingRight: 0,
						}}
						colSpan={row.cells.length + 1}>
						<Collapse in={openRow} timeout="auto" unmountOnExit>
							<div
								style={{
									paddingTop: 10,
									paddingBottom: 10,
									paddingLeft: 10,
									paddingRight: 10,
								}}>
								{row.venueData ? (
									<VenueDisplay
										venueData={row.venueData}
										expanded={internalExpanded?.findIndex(
											(x: any) => x[0] === row.id && x[1] === "Venue"
										) > -1 ?? false}
										onExpand={(isExpanded: boolean) => {
											if (!isExpanded)
												setInternalExpanded({
													type: "remove",
													payload: [row.id, "Venue"],
												});
											else
												setInternalExpanded({
													type: "add",
													payload: [row.id, "Venue"],
												});
										}}
									/>
								) : (
									<></>
								)}
								{row.customData ? (
									row.customData.map((customData, index) => (
										<Accordion
											key={index}
											expanded={internalExpanded?.findIndex(
												(x: any) => x[0] === row.id && x[1] === customData.title
											) > -1 ?? false}
											onChange={(panel, isExpanded) => {
												if (!isExpanded)
													setInternalExpanded({
														type: "remove",
														payload: [row.id, customData.title],
													});
												else
													setInternalExpanded({
														type: "add",
														payload: [row.id, customData.title],
													});
											}}>
											<AccordionSummary
												className="bg-neutral-primary"
												expandIcon={<MdExpandMore />}
												aria-controls="panel1a-content"
												id="panel1a-header">
												<Typography>{customData.title}</Typography>
											</AccordionSummary>
											<AccordionDetails>
												{customData.element ? (
													<>{customData.element}</>
												) : (
													<Grid
														container
														direction="row"
														alignItems="flex-start"
														spacing={2}>
														{customData.values?.map((values, index) => (
															<Grid item key={index}>
																<div>
																	{values.key + ": "}
																	{values.value}
																</div>
															</Grid>
														))}
													</Grid>
												)}
											</AccordionDetails>
										</Accordion>
									))
								) : (
									<></>
								)}
							</div>
						</Collapse>
					</TableCell>
					<TableCell
						style={{
							paddingTop: 0,
							paddingBottom: 0,
							paddingLeft: 0,
							paddingRight: 0,
						}}
					/>
				</TableRow>
			) : (
				<></>
			)}
		</>
	);
};

export default memo(NestedAccordionGridRow);
