import { FC, memo, useState } from "react";
import { useCreateBid } from "prembid-shared-library-npm/networking";
import { useSelector } from "react-redux";
import ns from "@/helpers/NotificationService";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import clsx from "clsx";
import { ceil } from "lodash";
import { LoadingButton } from "@mui/lab";
import { FaTimes } from "react-icons/fa";
interface CustomBidButtonProps {
    auctionId: any;
    lotId: any;
    profileId: any;
    onBidSuccessfullySubmitted: (customAmount: number, paddleNumber: string) => void;
}

const CustomBidButton: FC<CustomBidButtonProps> = (props) => {
    const { auctionId, lotId, profileId, onBidSuccessfullySubmitted } = props;
    const [confirm, setConfirm] = useState(false);

    const [customAmountField, setCustomAmountField] = useState<any>({
        customAmount: 0,
    });

    const { mutate: createBid, isLoading: loading } = useCreateBid({
        onSuccess(data: any) {
            ns.success("Custom bid successfully submitted");
            onBidSuccessfullySubmitted(Number(customAmountField.customAmount), data?.paddleNumber);
            setCustomAmountField({ customAmount: 0 });
            setConfirm(false);
        },
        onError() {
            setConfirm(false);
        },
    });

    const handleChangeCustomBid = (e: { target: { id: any; value: string } }) => {
        setCustomAmountField({
            ...customAmountField,
            [e.target.id]: e.target.value,
        });
    };

    const handleCustomBidButton = () => {
        if (confirm) {
            const bid = {
                auctionId: auctionId,
                lotId: lotId,
                amount: ceil(customAmountField.customAmount, 2),
                profileId: profileId,
                isTenderSubmission: false,
            };

            createBid(bid);
        } else {
            setConfirm(true);
        }
    };

    return (
        <>
            <div style={{ flexDirection: "row" }}>
                <Grid
                    className="mt-1"
                    container
                    spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={customAmountField.customAmount}
                            onChange={(e) => handleChangeCustomBid(e)}
                            id="customAmount"
                            fullWidth
                            variant="outlined"
                            label="Custom Bid"
                            InputProps={{
                                style: { height: 56 },
                                inputComponent: CurrencyInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Currency priceWithSymbol={""} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                            <LoadingButton
                                style={{ height: 56 }}
                                onClick={handleCustomBidButton}
                                fullWidth
                                variant="contained"
                                className={
                                    confirm
                                        ? "btn-success p-3 text-white text-capitalize"
                                        : "btn-primary p-3 text-white text-capitalize"
                                }
                                loading={loading}
                            >
                                {confirm ? (
                                    "Confirm"
                                ) : (
                                    "Submit"
                                )}
                            </LoadingButton>
                            {
                                confirm &&
                                <Button onClick={() => setConfirm(false)}
                                    style={{ height: 56, width: 56 }}
                                    variant="contained"
                                    className="btn-danger p-3 text-white text-capitalize">
                                    <span className="btn-wrapper--icon">
                                        <FaTimes />
                                    </span>
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default memo(CustomBidButton);
