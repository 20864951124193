import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	TextField,
} from "@mui/material";
import { FaSave, FaTimes } from "react-icons/fa";

import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import ns from "@/helpers/NotificationService";
import { useSelector } from "react-redux";
import { AuctionTypeEnum } from "@/helpers/AuctionTypeEnum";

interface BidUpdateDialogProps {
	isOpen: boolean;
	onOk: (data: { bidId: string; bidAmount: number }) => void;
	onCancel: () => void;
	bidId?: string;
	paddleNumber?: string;
	bidAmount?: number;
	minAllowedBidAmount?: number;
	auctionType:AuctionTypeEnum;
}

const BidUpdateDialog: FC<BidUpdateDialogProps> = ({
	isOpen,
	onOk,
	onCancel,
	bidId,
	paddleNumber,
	bidAmount = 0,
	minAllowedBidAmount = 0,
	auctionType
}) => {
	const [amount, setAmount] = useState<number>(bidAmount);
	const isTender = useMemo(()=>Number(auctionType) === Number(AuctionTypeEnum.Tender.toString()),[auctionType])

	useEffect(()=>{
		setAmount(bidAmount);
	},[bidAmount])

	const {settings} = useSelector((state:any)=>state.settings);

	const onClose = useCallback(() => {
		onCancel();
	}, [onCancel]);

	const handleConfirmation = useCallback(() => {

		if(isTender && amount <= 0){
			ns.error(`Amount must be greater than ${settings?.CurrencySymbol??""}0.`);
			return;
		}

		if (!isTender && amount <= minAllowedBidAmount) {
			ns.error(`Amount must be greater than ${settings?.CurrencySymbol??""}${minAllowedBidAmount.toFixed(2)}.`);
			return;
		}

		if (bidId) {
			onOk({
				bidId,
				bidAmount: amount,
			});
		}
	}, [amount, bidId, isTender, minAllowedBidAmount, onOk, settings?.CurrencySymbol]);

	return (
		<Dialog
			fullWidth
			maxWidth={"md"}
			open={isOpen}
			onClose={onClose}
			aria-labelledby="max-width-dialog-title">
			<DialogTitle id="max-width-dialog-title">Edit {isTender ? 'Tender': 'Bid'} </DialogTitle>
			<DialogContent>
				{paddleNumber && (
					<Box marginY={"10px"}>
						<span className="card-header--title font-size-lg">Paddle Number:</span>{" "}
						{paddleNumber}{" "}
					</Box>
				)}
				<h6 className="mb-1 text-black font-weight-bold">Amount</h6>
				<TextField
					margin="normal"
					value={amount}
					onChange={(e) => {
						const { value } = e.target;
						const numberValue = Number(value);
						setAmount(numberValue);
					}}
					name="value"
					onFocus={(event) => {
						event.target.select();
					}}
					fullWidth
					InputProps={{
						inputComponent: CurrencyInput,
						id: "amount",
						startAdornment: (
							<InputAdornment position="start" disableTypography={false}>
								<Currency priceWithSymbol={""} />
							</InputAdornment>
						),
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					variant="contained"
					className="btn-danger p-3 text-white text-capitalize">
					<span className="btn-wrapper--icon">
						<FaTimes />
					</span>
					<span className="btn-wrapper--label">Cancel</span>
				</Button>
				<Button
					onClick={handleConfirmation}
					variant="contained"
					className="btn-primary p-3 text-white text-capitalize">
					<span className="btn-wrapper--icon">
						<FaSave />
					</span>
					<span className="btn-wrapper--label">Confirm</span>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default memo(BidUpdateDialog);
