import React, { FC, useState } from "react";
import FinanceDocumentTable from "@/components/Invoicing/FinanceDocumentTable";
import { List, ListItem } from "@mui/material";
import FinanceDocumentModal from "@/components/Invoicing/FinanceDocumentModal";
import { FinanceDocumentType } from "@/dto/FinanceDocument";
interface InvoiceTabProps {
    auction: any
}

const InvoiceTab: FC<InvoiceTabProps> = props => {
    const { auction } = props;
    const [selectedType, setSelectedType] = useState<FinanceDocumentType>(FinanceDocumentType.ProFormaInvoice);

    const [financeDocumentModalState, setFinanceDocumentModalState] = useState<{
        open: boolean,
        financeDocumentId: string,
        financeDocumentType: FinanceDocumentType
    } | null>(null);

    const [lastReloadDate, setLastReloadDate] = useState<Date>(new Date());

    return <>
        <List className="nav-line d-flex">
            <ListItem button disableRipple selected={selectedType === FinanceDocumentType.ProFormaInvoice} onClick={() => { setSelectedType(FinanceDocumentType.ProFormaInvoice); }}>Pro-Formas</ListItem>
            <ListItem button disableRipple selected={selectedType === FinanceDocumentType.Invoice} onClick={() => { setSelectedType(FinanceDocumentType.Invoice); }}>Tax Invoices</ListItem>
            <ListItem button disableRipple selected={selectedType === FinanceDocumentType.ReleaseNote} onClick={() => { setSelectedType(FinanceDocumentType.ReleaseNote); }}>Release Notes</ListItem>
        </List>
        <FinanceDocumentTable
            auction={auction}
            type={selectedType}
            onView={(financeDocumentId: string, financeDocumentType: FinanceDocumentType) => setFinanceDocumentModalState({ open: true, financeDocumentId, financeDocumentType })}
            lastReloadDate={lastReloadDate}
        />
        {financeDocumentModalState?.open && <FinanceDocumentModal
            onClose={(reload?: boolean) => {
                setFinanceDocumentModalState(null)
                if (reload) setLastReloadDate(new Date());
            }}
            {...financeDocumentModalState}
        />}
    </>
}

export default InvoiceTab;