import React from 'react';
export default function HeaderText(props: {
    header: any,
    description?: any,
    [key: string]: any
}) {
    const { header, description = undefined, ...rest } = props;
    const renderDescription = (description) => description ? <div className="text-black-50 pb-1">
        {description}
    </div> : <></>

    const renderDescriptionArray = () => Array.isArray(description) ? description.map(renderDescription) : renderDescription(description);

    return <div {...rest}>
        <div className="pt-1 pb-1 font-size-lg text-capitalize">
            {header}
        </div>
        {renderDescriptionArray()}
    </div >
}