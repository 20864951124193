import React from "react";
import { FinanceDocumentType } from "@/dto/FinanceDocument";

type FinanceDocumentTypeBadgeProps = {
    type: FinanceDocumentType;
}

function FinanceDocumentTypeBadge({ type }: FinanceDocumentTypeBadgeProps) {

    switch (type) {
        case FinanceDocumentType.ConsolidatedProfomaInvoice:
        case FinanceDocumentType.ProFormaInvoice: return <>Pro Forma</>;
        case FinanceDocumentType.Invoice: return <>Tax Invoice</>;
        case FinanceDocumentType.ReleaseNote: return <>Release Note</>;

        default: return <>Type Not Handled</>;
    }
}

export default FinanceDocumentTypeBadge;