import React, { FC, memo } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))

interface SearchFieldProps {
    onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
    type: "number" | "text";
    value: string;
    onKeyPress: React.KeyboardEventHandler<HTMLDivElement> | undefined;
    disabled: boolean;
    placeholder?: string;
}

const SearchField: FC<SearchFieldProps> = ({
    onChange,
    onKeyPress,
    type,
    value,
    disabled,
    placeholder
}) => {

    const { classes, cx } = useStyles();
    return (
        <div>
            <div className="px-3">
                <TextField
                    margin="normal"
                    style={{ backgroundColor: '#ffffff', borderRadius: 5 }}
                    type={type}
                    fullWidth
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FaSearch />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default memo(SearchField);
