import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearError, clearLotType, handleLotTypeAdd, handleLotTypeUpdate, handleLotTypeFetch, newLotTypeAuction, newLotTypeLots } from './store/LotTypes';
import { Card, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { FaTimes, FaSave, FaPlus, FaTrashAlt, FaBackspace } from "react-icons/fa";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import ns from '@/helpers/NotificationService';

interface Props {
    clearError_d: () => void,
    clearLotType_d: () => void,
    handleLotTypeAdd_d: (lotTypeInfo: any, additionalData: any, onCompletedCallback: (res: any) => void) => void,
    handleLotTypeUpdate_d: (lotTypeInfo: any, additionalData: any, onCompletedCallback: (res: any) => void) => void,
    handleLotTypeFetch_d: (id: string) => void,
    newLotTypeAuction_d: (selectLotType: any) => void,
    newLotTypeLots_d: (newLotType: any) => void,
    lotType: any,
    lotTypeRequest: string,
    lotTypeId: string,
    isDialog?: boolean,
    handleClose?: (data?: any) => void,
    loading: boolean,
    settings: any,
    error: boolean,
    errorText: any,
}

const LotTypeEditAdd: React.FC<Props> = props => {
    const {
        clearError_d,
        clearLotType_d,
        handleLotTypeAdd_d,
        handleLotTypeUpdate_d,
        handleLotTypeFetch_d,
        newLotTypeAuction_d,
        newLotTypeLots_d,
        lotType,
        lotTypeRequest,
        lotTypeId,
        isDialog = false,
        handleClose = null,
        loading,
        settings,
        error,
        errorText,
    } = props;

    const [formFields, setFormFields] = useState({
        name: '',
        description: ''
    })
    const [formFieldsShowErrors, setFormFieldsShowErrors] = useState({
        name: false,
    })
    const history = useHistory();
    const [additionalData, setAdditionalData] = useState<any[]>([]);
    const [isFormValid, setIsFormValid] = useState(true);

    useEffect(() => {
        if (location) {
            const lotTypeId = new URLSearchParams(history.location.search).get("lotTypeId")
            if (lotTypeId != '' && !lotType && lotTypeId !== null) {
                showProgressSpinner({ description: "Retrieving " + settings.LotName + " Type..." });
                handleLotTypeFetch_d(lotTypeId);
            } else {
                hideProgressSpinner();
            }
        }
    }, []);

    useEffect(() => {
        if (lotType !== undefined) {
            const addFields = lotType?.dataCaptured?.defaultAdditionalProperties?.map(field => {
                let tempField = { ...field };
                tempField.isNameValid = true;
                return tempField;
            }) || [];
            const fields = {
                id: lotType.id,
                name: lotType.name,
                description: lotType.description
            };

            setAdditionalData(addFields);
            setFormFields(fields);

            hideProgressSpinner();
        }
    }, [lotType])

    useEffect(() => {
        if (lotTypeId !== '') {
            if (formFields)
                formFields['id'] = lotTypeId;

            navAddEdit(lotTypeId);
        }
    }, [lotTypeId]);

    useEffect(() => {
        if (isDialog === true && lotTypeId !== undefined) {
            newLotTypeAuction_d(lotTypeId);
        }
    }, [lotTypeId]);

    useEffect(() => {
        if (isDialog === true && lotTypeId !== undefined) {
            newLotTypeLots_d(lotTypeId);
        }
    }, [lotTypeId]);

    React.useEffect(() => {
        if (error) {
            clearError_d();
            ns.error(errorText);
            hideProgressSpinner();
        }
    }, [error])

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onCancel = () => {
        if (isDialog === true && handleClose) handleClose();
        else {
            history.push('/LotTypes')
            clearLotType_d()
        }
    }

    const updateFieldChanged = (id: any, name: any = undefined, value: any = undefined) => {
        let tempAdditionalData = [...additionalData];
        let fieldIndex = tempAdditionalData.findIndex(x => x.id === id);
        if (fieldIndex > -1) {
            let field = { ...tempAdditionalData[fieldIndex] };
            if (name || name === '') field.name = name;
            if (value || value === '') field.value = value;
            tempAdditionalData.splice(fieldIndex, 1, field);

            setAdditionalData(tempAdditionalData);
        }
    };

    const onSubmit = () => {
        const isAdditionalDataValid = validateAdditionalDataName();
        const isAllFieldsValid = validateAll()
        if (isAllFieldsValid && isAdditionalDataValid) {
            showProgressSpinner({ description: "Saving " + settings.LotName + " Type..." });

            let tempAdditionalData = additionalData.map(field => {
                let tempField = { ...field };
                delete tempField.isNameValid;
                return tempField;
            });

            if (lotTypeRequest === 'Add') {
                handleLotTypeAdd_d(formFields, tempAdditionalData, (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success(settings.LotName + " type successfully added");
                        if (isDialog === true && handleClose) handleClose(res.response);
                    } else {
                        ns.error(res?.error);
                    }
                })
            } else {
                handleLotTypeUpdate_d(formFields, tempAdditionalData, (res) => {
                    hideProgressSpinner();
                    if (res?.success) {
                        ns.success(settings.LotName + " type successfully updated");
                        if (isDialog === true && handleClose) handleClose(res.response);
                    } else {
                        ns.error(res?.error);
                    }
                })
            }
        }
        else {
            ns.error("Please fill in all required fields");
        }
    }

    const validate = (e) => {
        if (e.target.required === true) {
            if (e.target.value === '')
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: true, });
            else
                setFormFieldsShowErrors({ ...formFieldsShowErrors, [e.target.id]: false, });
        }
    }

    const validateAll = () => {
        let isAllValid = true;
        let newState = {
            name: false,
        }
        for (const [key, value] of Object.entries(formFieldsShowErrors)) {
            if (newState[key] instanceof Object == false) {
                const formFieldsValue = formFields[key];
                if (formFieldsValue === "") {
                    isAllValid = false;
                    newState[key] = true;
                } else {
                    newState[key] = false;
                }
            }
        }
        setFormFieldsShowErrors(newState)
        return isAllValid;
    }

    const validateAdditionalDataName = () => {
        let isAllValid = true;
        let allAdditionalDataField = [...additionalData];
        for (const data of allAdditionalDataField) {
            if (data.name === '') {
                isAllValid = false;
                data.isNameValid = false;
            }
            else {
                data.isNameValid = true;
            }
        }

        if (isAllValid == false) {
            setAdditionalData(allAdditionalDataField);
            return false;
        }
        else {
            return true;
        }
    }

    const navAddEdit = (lotTypeId) => {
        if (isDialog === true && handleClose) handleClose();
        else {
            let path = '/LotTypeEdit'
            if (lotTypeId !== '')
                path += '?lotTypeId=' + lotTypeId
            history.push(path);
        }
    }

    const handleUpClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        let previous = { ...list[index - 1] };
        list.splice(index - 1, 1, current);
        list.splice(index, 1, previous);
        setAdditionalData(list);
    }

    const handleDownClicked = (index: any) => {
        let list = [...additionalData];
        let current = { ...list[index] };
        let next = { ...list[index + 1] };
        list.splice(index, 1, next);
        list.splice(index + 1, 1, current);
        setAdditionalData(list);
    }

    const handleAddClicked = (index: any = undefined) => {
        let field = { id: uuidv4(), name: '', value: '', isNameValid: true };

        if (index > -1) {
            let newState = [...additionalData];
            newState.splice(index + 1, 0, field);
            setAdditionalData(newState);
        }
        else {
            setAdditionalData([...additionalData, field]);
        }
    }

    const handleRemoveClicked = (index: any) => {
        let list = [...additionalData];
        list.splice(index, 1);
        setAdditionalData(list);
    }

    const renderAdditionalField = (row: any, index: any, length: number) => <TableRow key={index}>
        <TableCell component="th" scope="row">
            <TextField value={row.name} error={!row.isNameValid} onChange={e => updateFieldChanged(row.id, e?.target?.value || '')} name="name" fullWidth className="m-2" label="Field" required autoComplete="new-password" margin="normal" />
        </TableCell>
        {/*<TableCell>*/}
        {/*    <TextField value={row.value} onChange={e => updateFieldChanged(row.id, undefined, e?.target?.value || '')} name="value" fullWidth className="m-2" label="Default" autoComplete="new-password" margin="normal" />*/}
        {/*</TableCell>*/}
        <TableCell align="right" className="pr-4">
            {index > 0 &&
                <Button className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize"
                    onClick={() => handleUpClicked(index)}>
                    <MdArrowUpward />
                </Button>
            }
            {index < length - 1 &&
                <Button className="btn-primary text-white mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize"
                    onClick={() => handleDownClicked(index)}>
                    <MdArrowDownward />
                </Button>
            }
            <Tooltip arrow title="Add" placement="bottom">
                <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" onClick={() => handleAddClicked(index)}>
                    <FaPlus className="font-size-sm" />
                </Button>
            </Tooltip>
            <Tooltip arrow title="Delete" placement="bottom">
                <Button className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize" onClick={() => handleRemoveClicked(index)}>
                    <FaTrashAlt className="font-size-sm" />
                </Button>
            </Tooltip>
        </TableCell>
    </TableRow>

    return (
        <div>
            {settings.ProductName === "PremBid" && !loading && (<>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Card className="card-box mb-spacing-6-x2">
                            <div className="card-header dpy-3">
                                <div className="card-header--title font-size-lg">
                                    {settings.LotName + " Type Information"}
                                </div>
                            </div>
                            <div className="p-3">
                                <TextField value={formFields.name} autoFocus required error={formFieldsShowErrors.name} onChange={onChange} id="name" fullWidth className="m-2" label="Name" onBlur={validate} autoComplete="new-password" margin="normal" />
                                <TextField value={formFields.description} onChange={onChange} id="description" fullWidth className="m-2" label="Description" autoComplete="new-password" margin="normal" />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className="card-box mb-spacing-6-x2">
                            <div className="card-header py-3">
                                <div className="card-header--title font-size-lg">
                                    {"Custom " + settings.LotName + " Type Information"}
                                </div>
                            </div>
                            <div className="table-responsive-md">
                                <TableContainer>
                                    <Table aria-label="simple table" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Field</TableCell>
                                                {/*<TableCell>Default Related Information</TableCell>*/}
                                                <TableCell align="right" className="pr-4">
                                                    <Tooltip arrow title="Add" placement="bottom">
                                                        <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" onClick={() => handleAddClicked()} disableFocusRipple={true}>
                                                            <FaPlus />
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {additionalData.map((row, index, list) => renderAdditionalField(row, index, list.length))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                {
                    Boolean(isDialog) === false ? (
                        <FloatyButtonGroup>
                            <Tooltip arrow title="Save" placement="left">
                                <Button size="large" variant="contained" onClick={onSubmit}
                                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                    <span className="btn-wrapper--icon">
                                        <FaSave />
                                    </span>
                                </Button>
                            </Tooltip>
                            <Tooltip arrow title="Back" placement="left">
                                <Button size="large" variant="contained" onClick={onCancel}
                                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                                    <span className="btn-wrapper--icon">
                                        <FaBackspace />
                                    </span>
                                </Button>
                            </Tooltip>
                        </FloatyButtonGroup>)
                        :
                        (<div className="text-right">
                            <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                                <span className="btn-wrapper--icon">
                                    <FaTimes />
                                </span>
                                <span className="btn-wrapper--label">Cancel</span>
                            </Button>

                            <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                                <span className="btn-wrapper--label">Save</span>
                            </Button>
                        </div>)
                }
            </>)}
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        clearLotType_d: () => dispatch(clearLotType()),
        handleLotTypeAdd_d: (lotTypeInfo: any, additionalData: any, onCompletedCallback: (res: any) => void) => dispatch(handleLotTypeAdd(lotTypeInfo, additionalData, onCompletedCallback)),
        handleLotTypeUpdate_d: (lotTypeInfo: any, additionalData: any, onCompletedCallback: (res: any) => void) => dispatch(handleLotTypeUpdate(lotTypeInfo, additionalData, onCompletedCallback)),
        handleLotTypeFetch_d: (id: string) => dispatch(handleLotTypeFetch(id)),
        newLotTypeAuction_d: (selectLotType: any) => dispatch(newLotTypeAuction(selectLotType)),
        newLotTypeLots_d: (newLotType: any) => dispatch(newLotTypeLots(newLotType))
    }
}

const mapStateToProps = (state: any) => ({
    lotType: state.lotTypes.lotType,
    lotTypeRequest: state.lotTypes.lotTypeRequest,
    lotTypeId: state.lotTypes.lotTypeId,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    error: state.lotTypes.error,
    errorText: state.lotTypes.errorText,
})

export default connect(mapStateToProps, mapDispatchToProps)(LotTypeEditAdd);