import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProgressSpinner from "@/components/ProgressSpinner/ProgressSpinner";
import { handleUserAccountFetch, handleUserAccountUpdate, resetAccountState } from "./store/Profile";

import useStyles from "./ProfileStyles";
import {
    Button,
    Typography,
    Grid,
    Card,
    Avatar,
    CardContent,
    Divider,
    TextField,
    Container,
} from "@mui/material";
import useHistory from '@/hooks/useHistory';
import { FaTimes, FaSave } from "react-icons/fa";
import ns from '@/helpers/NotificationService';
import PasswordConfirmationDialog from "@/components/Modal/PasswordConfirmationDialog";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";

interface Props {
    handleUserAccountFetch_d: () => void,
    handleUserAccountUpdate_d: (user) => void,
    resetAccountState_d: () => void,
    userAccount: any,
    userAccountUpdateState: any,
    success: boolean,
    error: boolean,
    loading: boolean
}

const ProfileEdit: React.FC<Props> = (props) => {
    const { handleUserAccountFetch_d,
        handleUserAccountUpdate_d,
        resetAccountState_d,
        userAccount,
        userAccountUpdateState,
        loading } = props;

    const history = useHistory();
    const { classes, cx } = useStyles();

    const [confirmationState, setConfirmationState] = useState({
        open: false
    });

    const [formFields, setFormFields] = React.useState({
        email: '',
        phoneNumber: '',
        userId: '',
    });

    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] = React.useState<any>({
        email: false,
        phoneNumber: false,
    });

    useEffect(() => {
        showProgressSpinner({ description: "Retrieving Account..." });
        handleUserAccountFetch_d();
        return () => {
            resetAccountState_d();
        }
    }, []);

    useEffect(() => {
        if (userAccount) {
            setFormFields({ email: userAccount.email, phoneNumber: userAccount.phoneNumber, userId: userAccount.id });
            hideProgressSpinner();
        }
    }, [userAccount]);

    useEffect(() => {
        setConfirmationState({ ...confirmationState, open: false })
        hideProgressSpinner();

        if (!(userAccountUpdateState.errorText === undefined || userAccountUpdateState.errorText === null))
            ns.error(userAccountUpdateState.errorText);

        else if (!(userAccountUpdateState.successText === undefined || userAccountUpdateState.successText === null))
            ns.success(userAccountUpdateState.successText);

    }, [userAccountUpdateState]);

    const onGoBack = () => {
        history.push("/Auctions");
    };

    const onChangeUppercase = (e: any) => {
        const props = e.target.id.split(".");
        setFormFields({ ...formFields, [props[0]]: e.target.value.toUpperCase() });
    }

    const onChange = (e: any) => {
        const props = e.target.id.split(".");
        setFormFields({ ...formFields, [props[0]]: e.target.value });
    }

    const validate = () => {
        let valid = true;

        let newState = {
            email: false,
            phoneNumber: false,
        }

        for (const [key, value] of Object.entries(formFieldsValidationShowErrors)) {
            const formFieldsvalue = formFields[key];
            if (formFieldsvalue === '') {
                newState[key] = true;
                valid = false;
                break;
            }
            else {
                newState[key] = false;
            }
        }

        setFormFieldsValidationShowErrors(newState);

        return valid;
    }

    const validateField = (e: any) => {
        const props = e.target.id.split(".");
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id !== undefined)
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError })
    }


    const handleSave = () => {
        const isValid = validate();
        if (isValid) {
            setConfirmationState({ open: true });
        } else {
            ns.error("Please fill in all required fields");
        }
    };

    const handleConfirmationOk = (password) => {
        showProgressSpinner({ description: "Saving Account..." });
        handleUserAccountUpdate_d({ ...formFields, password });
    }

    return (
        <div>
            <PasswordConfirmationDialog
                cancelButtonText='Cancel'
                okButtonText='Submit'
                title='Confirm Password'
                onOk={handleConfirmationOk}
                onCancel={() => setConfirmationState({ ...confirmationState, open: false })}
                open={confirmationState.open}
                description='Please enter your current password'
            />
            {userAccount !== undefined && (
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Card className="card-box mb-spacing-6-x2">
                                <div className="card-header dpy-3">
                                    <div className="card-header--title font-size-lg">My Account</div>
                                </div>
                                <div className="card-header dpy-3">
                                    <div className="card-header--title font-size-lg">Log In Account Information</div>
                                </div>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <div className="p-3">
                                            <TextField
                                                margin="normal"
                                                id="email"
                                                fullWidth
                                                className="m-2"
                                                label="Email Address"
                                                type="text"
                                                value={formFields.email}
                                                onChange={onChangeUppercase}
                                                required
                                                inputProps={{ readOnly: true }}
                                                onBlur={validateField}
                                                error={formFieldsValidationShowErrors.email}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className="p-3">
                                            <TextField
                                                margin="normal"
                                                defaultValue={userAccount.phoneNumber}
                                                id="phoneNumber"
                                                fullWidth
                                                className="m-2"
                                                label="Phone Number"
                                                type="tel"
                                                value={formFields.phoneNumber}
                                                onChange={onChange}
                                                inputProps={{ readOnly: true }}
                                                onKeyDown={(e) =>
                                                    (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
                                                }
                                                required
                                                onBlur={validateField}
                                                error={formFieldsValidationShowErrors.phoneNumber}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    userAccount: state.account.userAccount,
    success: state.account.success,
    error: state.account.error,
    userAccountUpdateState: state.account.userAccountUpdateState,
    loading: state.progressSpinner.loading
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleUserAccountFetch_d: () => dispatch(handleUserAccountFetch()),
        handleUserAccountUpdate_d: (user) => dispatch(handleUserAccountUpdate(user)),
        resetAccountState_d: () => dispatch(resetAccountState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
