﻿import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const REGISTER_CONFIRMATION_SUCCESS = 'REGISTER_CONFIRMATION_SUCCESS';
export const REGISTER_CONFIRMATION_ERROR = 'REGISTER_CONFIRMATION_ERROR';

const initialState = {
    error: false,
    errorText: false,
    token: '',
}
 
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case REGISTER_CONFIRMATION_SUCCESS: return { ...state, token: action.payload }
        case REGISTER_CONFIRMATION_ERROR: return { ...state, error: true, errorText: action.payload}
        default: return state;
    }
}

//Action creator

export const registerConfirmationSuccess = (token: any) => {
    return { type: REGISTER_CONFIRMATION_SUCCESS, payload: token }
}

export const registerConfirmationError = (errorText: string) => {
    return { type: REGISTER_CONFIRMATION_ERROR, payload: errorText }
}


//Thunks
export const handleSubmit = () => {

    let userInfo = {
        
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.registerConfirmation, userInfo)
            .then(function (response) {
                if (response.success) {
                    //navigate
                    //write token to store/reducer
                }
                else {
                    //modify state to show the error message element
                    //responseObject.Error
                }
            }).catch(function (error) {

            });
    }

}