import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import RegisterManualUser from "../ClientUserComponent/RegisterManualUser";
import { FaSave, FaTimes } from "react-icons/fa";
import { handleRegisteredUserCreate } from "@/pages/Profile/store/Profile";
import ns from '@/helpers/NotificationService';

interface Props {
    handleRegisteredUserCreate_d: (auctionId: string, profileId: string, profileType: string, manualRegistration: boolean, onCompletedCallback?: (result: any) => void) => void,
    handleUserListReload_d: (onCompletedCallback?: (result: any) => void) => void;
    title: string,
    description: string,
    okButtonText: string,
    cancelButtonText: string,
    open: boolean,
    selectedAuction: any,
    onCancel: () => void,
}

const RegisterManualUserModal: React.FC<Props> = props => {
    const {
        handleRegisteredUserCreate_d,
        title,
        open,
        okButtonText,
        cancelButtonText,
        selectedAuction,
        onCancel
    } = props

    const [selectedAuctionId, setSelectedAuctionId] = React.useState<any>();
    const [selectedProfile, setSelectedProfile] = React.useState<any>();

    React.useEffect(() => {
        setSelectedAuctionId(selectedAuction.id)
    }, [selectedAuction]);

    const handleRegistrationResult = (result: any) => {
        if (result.success) {
            ns.success("Profile Successfully Registered");
            props.handleUserListReload_d();
        } else {
            ns.error(result.error);
        }
        setSelectedProfile(undefined);
        showHideLoader(false, '');
    }

    const [spinner, setLoader] = React.useState({
        isBusy: false,
        description: ""
    });

    const showHideLoader = (show: boolean, description: string) => {
        setLoader({
            isBusy: show,
            description: description
        })
    }

    const onClick = (e: any) => {
        e.preventDefault();
        if (selectedProfile) {
            showHideLoader(true, 'Registering Profile');
            handleRegisteredUserCreate_d(selectedAuctionId, selectedProfile.id, selectedProfile.profileType, true, handleRegistrationResult);
        }
    }

    return (
        <React.Fragment>
            <Dialog fullWidth
                maxWidth="lg" className="" open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent style={{ padding: 0, margin: 0 }}>
                    <div aria-disabled={spinner.isBusy} >
                        {spinner.isBusy && <div className="text-primary text-center" style={{ position: 'absolute', top: '50%', bottom: 0, right: 0, left: 0, margin: 'auto', zIndex: 99999 }}>
                            <CircularProgress className="text-primary text-center" size={60} />
                        </div>
                        }
                        <RegisterManualUser
                            setSelectedProfile={setSelectedProfile}
                            selectedAuction={selectedAuction} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">{cancelButtonText}</span>
                    </Button>

                    <Button onClick={onClick} disabled={spinner.isBusy} variant="contained" className="btn-primary p-3 text-white text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                        <span className="btn-wrapper--label">{okButtonText}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >

    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRegisteredUserCreate_d: (auctionId: string, profileId: string, profileType: string, manualRegistration: boolean, onCompletedCallback?: (result: any) => void) => dispatch(handleRegisteredUserCreate(auctionId, profileId, profileType, manualRegistration, onCompletedCallback)),
    }
}


const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterManualUserModal);


