import { useDropzone, } from 'react-dropzone';
import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdClose, MdCheck } from "react-icons/md";
import Resizer from 'react-image-file-resizer';
import ns from '@/helpers/NotificationService';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { showProgressSpinner } from '../../../helpers/ProgressSpinnerService';

interface Props {
    onImagesUpdated: (images: any[]) => void;
    acceptedFileExtensions: string;
    settings: any;
}

const BulkUploaderImageUploader: React.FC<Props> = props => {
    const {
        onImagesUpdated,
        acceptedFileExtensions,
        settings
    } = props;
    const [isBusy, setIsBusy] = useState(false);

    const imageValidator = (file: any): any => {
        if (!acceptedFileExtensions.includes(file.type)) {
            setIsBusy(false);
            return {
                code: "incorrect-format",
                message: "Supported file types are .JPEG | .JPG | .PNG"
            };
        }

        /* Added code */
        if (file.size > 10485760) {
            setIsBusy(false);
            ns.error(`Image ${file.name} is too big. A maximum image size of 10MB is allowed`);
            return {
                code: "size-too-large",
                message: "Size is larger than 10mb"
            };
        }

        return null;
    }

    const {
        acceptedFiles,
        fileRejections,
        isDragActive,
        isDragAccept,
        isDragReject, getRootProps, getInputProps
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'text/html': ['.html', '.htm'],
            'image/jpeg': ['.jpeg', '.jpg']
        },
        multiple: true,
        validator: imageValidator
    });

    React.useEffect(() => {
        const process = async () => {
            if (acceptedFiles.length > 0) {
                showProgressSpinner({ description: "Preparing Images for Processing.\n\nThis can take several minutes.\nPlease do not close or refresh your browser." });

                const imageUrls: any[] = [];

                let batchNumber = 0;
                const batchSize = 10;

                let batch = acceptedFiles.slice(batchNumber * batchSize, (batchNumber + 1) * batchSize);

                while (batch.length > 0) {
                    showProgressSpinner({ description: `Preparing Images for Processing.\n\nThis can take several minutes.\nPlease do not close or refresh your browser.`, extra: `Compressing Image ${batchNumber * batchSize} of ${acceptedFiles?.length}...` });
                    const promises = batch.map(x => resizeFile(x));
                    var responses = await Promise.allSettled(promises);
                    responses.map((response: any) => imageUrls.push(response.value));
                    batchNumber++;
                    batch = acceptedFiles.slice(batchNumber * batchSize, (batchNumber + 1) * batchSize);
                }

                onImagesUpdated(imageUrls);
                setIsBusy(false);
            }
        }
        process();
    }, [acceptedFiles]);

    const resizeFile = (file: File) => new Promise(resolve => {
        if (!isBusy)
            Resizer.imageFileResizer(file, 1920, 1080, 'JPEG', 50, 0,
                base64 => {
                    let order = 0;
                    try {
                        order = Number(file.name.toUpperCase().replace(".JPG", "").replace(".JPEG", "").replace(".PNG", ""));
                    }
                    catch { }

                    const tempId = uuidv4();
                    resolve({
                        id: "",
                        fileName: file.name,
                        fileData: base64,
                        order: order,
                        _tempId: tempId
                    });
                }, 'base64', undefined, undefined
            );
    });

    useEffect(() => {
        if (fileRejections?.length === 1) ns.error(`Image ${fileRejections?.[0]?.file.name} is in the incorrect format. We only support .jpeg, .jpg and .png file types`);
        else if (fileRejections?.length > 1) ns.error(`Some images are in the incorrect format. We only support .jpeg, .jpg and .png file types`);
    }, [fileRejections])

    return (
        <div>
            <div className='p-4'>
                <div className="dropzone">
                    <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                        <input {...getInputProps()} />
                        <div className="dropzone-inner-wrapper">
                            {isDragAccept && (
                                <div>
                                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success rounded-circle text-white">
                                        <MdCheck className="d-50" />
                                    </div>
                                    <div className="font-size-sm text-success">Drop to add</div>
                                </div>
                            )}
                            {isDragReject && (
                                <div>
                                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger rounded-circle text-white">
                                        <MdClose className="d-50" />
                                    </div>
                                    <div className="font-size-sm text-danger">
                                        Incorrect file type. Only JPEG / JPG / PNG images no larger than 10mb are accepted.
                                    </div>
                                </div>
                            )}

                            <Grid
                                container
                                direction="column"
                                alignItems="flex-start"
                                spacing={0}>
                                <Grid item xs={12}>
                                    {!isDragActive && (
                                        <span className="text-black">
                                            Please drag and drop or browse your files to upload JPEG / JPG / PNG images.
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {!isDragActive && (
                                        <span className="text-black">Please upload images with 16x9 dimensions for best results (Examples 3840 x 2160, 2560 x 1440, 1920 x 1080, 1280 x 720)</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} className="mt-3">
                                    {!isDragActive && (
                                        <span className="text-black">Restrictions</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} className="mt-3">
                                    {!isDragActive && <span className="text-black">1 - Single images cannot exceed 10 MB</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    {!isDragActive && <span className="text-black">2 - Dimensions are not forced, but images may be cut to scale</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    {!isDragActive && <span className="text-black">3 - We support JPEG / JPG / PNG images only</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    {!isDragActive && <span className="text-black">4 - Please ensure you use a rounded number for the image you wish to use as your banner.main image</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    {!isDragActive && <span className="text-black">5 - Please use a single decimal place for the remainder of your images</span>}
                                </Grid>
                                <br />
                                <Grid item xs={12}>
                                    {!isDragActive && <span className="text-black">e.g. For {settings.LotName?.toLowerCase()} 1, the banner image should be "1.jpg" and the rest of the images should be named "1.1.jpg", "1.2.jpg" etc Please use a single decimal place for the remainder of your images</span>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button disabled={isBusy} className="btn-primary p-3 text-white mt-3 text-capitalize">
                                        <span className="px-2">{!isBusy ? "Browse Images" : "Processing..."}</span>
                                    </Button>
                                </Grid>
                                {fileRejections?.length > 0 && <Grid item xs={12} className='mt-4 text-left'>
                                    <span className='text-black'>Images with incorrect format: </span> <br />
                                    <span className="text-danger p-0 m-0">
                                        {fileRejections.map(x => x.file.name).filter(x => x).join(", ")}
                                    </span>
                                </Grid>}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploaderImageUploader);