import React, { useEffect } from "react";
import { connect } from "react-redux";
import useStyles from './EmailChangeConfirmationCardStyles';
import {
    Container,
} from '@mui/material';
import { MdClear, MdDone } from "react-icons/md";
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { handleConfirmEmailChangeSubmit } from "./store/EmailChangeConfirmationCard";
import { hideProgressSpinner } from "@/helpers/ProgressSpinnerService";

interface Props {
    handleConfirmEmailChangeSubmit_d: (userId, tokenId) => void,
    confirmEmailState: any
}

const EmailChangeConfirmationCard: React.FC<Props> = (props) => {
    const { confirmEmailState: { success, successData, error, errorText }, handleConfirmEmailChangeSubmit_d } = props;

    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const { classes, cx } = useStyles();
    const history = useHistory();

    useEffect(() => {
        handleConfirmEmailChangeSubmit_d(userId, tokenId)
    }, [userId, tokenId]);

    useEffect(() => {
        hideProgressSpinner();
    }, [success, successData, error, errorText]);

    const navigateHome = () => {
        let path = '/login';
        history.push(path);
    }

    return (
        <>
            {success && <Container maxWidth="xs">
                <div className={classes.paper}>
                    <div className={classes.successIconDiv}>
                        <MdDone className={classes.successIcon} />
                    </div>
                    <h5 className="mb-2 text-black" >Your email address has been successfully changed and you may now log in.</h5>
                    <h6 className="text-black">Return to <a onClick={navigateHome} className={classes.link + " text-info"} style={{ textDecoration: 'underline' }}>Log In</a></h6>
                </div>
            </Container>}
            {error && <Container maxWidth="xs">
                <div className={classes.paper}>
                    <div className={classes.errorIconDiv}>
                        <MdClear className={classes.errorIcon} />
                    </div>
                    <h5 className="mb-2 text-black" >{errorText}</h5>
                </div>
            </Container>}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    confirmEmailState: state.confirmEmailChangeConfirmationCard
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleConfirmEmailChangeSubmit_d: (userId, tokenId) => dispatch(handleConfirmEmailChangeSubmit(userId, tokenId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailChangeConfirmationCard);