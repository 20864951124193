import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";

export const getEndTime = (endDateTimeAt: string, extendedBy: number) => {
    const endTime = new Date(endDateTimeAt)
    endTime.setTime(endTime.getTime() + (extendedBy * 1000))
    return endTime
}

export const calculateAuctionStatus = (isActive: boolean, startDateTimeAt: string, endDateTimeAt: string, extendedBy: number, dateTimeOffset: number) => {
    if (!isActive) return "Unavailable";

    const endTimeExt = getEndTime(endDateTimeAt, extendedBy)
    const now = getNewDateWithOffset(dateTimeOffset)
    const start = new Date(startDateTimeAt)
    const end = new Date(endDateTimeAt)

    if (now < start)
        return "Awaiting"

    if (start < now && now < end && extendedBy === 0) {
        return "Open"
    }

    if (now < end && extendedBy > 0) return "Extended"

    if (now > end) {
        if (extendedBy && extendedBy > 0) {
            if (now > endTimeExt)
                return "Completed"
            else
                return "Extended"
        }
        else
            return "Completed"
    }
};