import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const RETRIEVED_CONTACT_SUCCESS = 'RETRIEVED_CONTACT_SUCCESS';
const RETRIEVED_CONTACTS_SUCCESS = 'RETRIEVED_CONTACTS_SUCCESS';
const RETRIEVED_CONTACT_LOOKUP_SUCCESS = 'RETRIEVED_CONTACT_LOOKUP_SUCCESS';
const DELETE_CONTACT = 'DELETE_CONTACT';
const CLEAR_STATE = 'CLEAR_STATE';

export interface ContactsState {
    list: any[],
    pagination: any,
    contact: any,
    lookups: any[],
    lookupsPagination: any
}

const initialState: ContactsState = {
    list: [],
    pagination: undefined,
    contact: undefined,
    lookups: [],
    lookupsPagination: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case RETRIEVED_CONTACT_SUCCESS: return { ...state, contact: action.contact };
        case RETRIEVED_CONTACTS_SUCCESS: return { ...state, ...action.contactResponse };
        case RETRIEVED_CONTACT_LOOKUP_SUCCESS: return { ...state, lookups: action.payload };
        case DELETE_CONTACT:
            {
                let tempList = [...state.list];
                let index = tempList.findIndex(x => x.id === action.contactId);
                let tempPagination = { ...state.pagination };
                if (index > -1) {
                    tempList.splice(index, 1);
                    tempPagination.total = tempPagination.total - 1;
                }
                return { ...state, list: tempList, pagination: tempPagination }
            }
        case CLEAR_STATE: return { ...initialState };
        default: return state;
    }
}

export const deleteContact = (contactId: string) => {
    return { type: DELETE_CONTACT, contactId }
}

export const retrievedContact = (contact: any) => {
    return { type: RETRIEVED_CONTACT_SUCCESS, contact }
}

export const retrieveContacts = (contactResponse: any) => {
    return { type: RETRIEVED_CONTACTS_SUCCESS, contactResponse }
}

export const retrieveContactLookups = (payload: any) => {
    return { type: RETRIEVED_CONTACT_LOOKUP_SUCCESS, payload }
}

export const clearContactsState = () => {
    return { type: CLEAR_STATE }
}

export const handleContactsFetch = (pagination: any, includeExternalSources: boolean = true, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.contact.retrieveContactsList + "?includeExternalSources=" + includeExternalSources, { ...pagination })
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveContacts(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleContactLookupFetch = (includeExternalSources: boolean = true, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.contact.retrieveContactsLookup, null, { includeExternalSources })
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveContactLookups(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleContactsFetchWithPagination = (filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.contact.retrieveContactsListWithPagination, {
            pagination,
            ...(filters || {})
        })
            .then(function (res) {
                if (res.success) {
                    dispatch(retrieveContacts(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleContactFetch = (contactId: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.contact.retrieveContact, { contactId }, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(retrievedContact(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleContactUpsert = (contactData: any, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.contact.upsertContact, contactData)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleContactImageUpsert = (contactImageData: any, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.contact.upsertContactImage, contactImageData)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleDeleteContact = (contactId: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.delete(apiPaths.contact.deleteContact, undefined, { contactId })
            .then(function (res) {
                if (res.success) dispatch(deleteContact(contactId))
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

//public const string UpsertContactImage = "/api/Image/UpsertContactImage";
//public const string RetrieveContactImage = "/api/Image/RetrieveContactImage/{imageId}";
//public const string DeleteContactImage = "/api/Image/DeleteContactImage/{imageId}";
