import React, { FC, memo} from 'react'
import { FaGlobe } from "react-icons/fa";
import GoogleMapContainer from '@/components/GoogleMapContainer/GoogleMapContainer';
import { VenueData } from '../NestedAccordionGrid.types';

interface MapDataProps {
    venueData:VenueData;
}

const MapData:FC<MapDataProps> = ({venueData:{lat,lng}}) => {

    let mapSection = <GoogleMapContainer
        center={{
            lat: Number(lat),
            lng: Number(lng)
        }}
        canEdit={false}
    />

    if (lng == undefined || lat == undefined || lng == 0 || lat == 0) {
        mapSection =
            <div className="text-center my-5">
                <FaGlobe className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-primary shadow-primary-sm text-primary mb-2 d-90" />
                <h6 className="font-size-xxl mb-1 mt-3 text-primary">No map data</h6>
            </div>
    }

    return mapSection;
}

export default memo(MapData)