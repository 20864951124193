import React, { MouseEventHandler } from 'react';
import {
    Button as MaterialUIButton,
    ButtonProps as MaterialUIButtonProps
} from '@mui/material';
import { useSelector } from 'react-redux';

type ButtonStyle = MaterialUIButtonProps['style'];

type ButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'primary' | 'secondary' | 'danger' | 'success' | 'default';
    label?: string;
    style?: ButtonStyle;
    width?: number;
    height?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
}

function Button({
    onClick,
    type = 'primary',
    label,
    width,
    height,
    style,
    disabled,
    fullWidth = true,
    fullHeight = true
}: ButtonProps) {
    const { settings } = useSelector((state: any) => state.settings);

    return <MaterialUIButton
        disabled={disabled}
        onClick={onClick}
        variant="contained"
        className={type === 'default' ? `btn-white p-3 text-capitalize bordered` : `btn-${type} p-3 text-white text-capitalize`}
        style={{
            ...(type === 'default' ? { border: `1px ${settings?.Styles?.Primary} solid` } : {}),
            ...(type === 'secondary' ? { background: '#2B2B2B' } : {}),
            width: width ?? fullWidth ? '100%' : undefined,
            height: height ?? fullHeight ? '100%' : undefined,
            ...(style ?? {}),
        }}
    >
        {label && <span className="btn-wrapper--label">{label}</span>}
    </MaterialUIButton>
}

export default Button;