import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
    handleRetrieveWishlistProfilesByLotId
} from './store/AuctionManagement';
import {
    Button,
    Tooltip
} from '@mui/material';
import { FaRedo } from "react-icons/fa";
import {NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel } from '@/components/NestedAccordionGrid'
import { makeStyles } from "tss-react/mui";
import { formatDateTime } from "../../helpers/Utils";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleRetrieveWishlistProfilesByLotId_d: (lotId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => void,
    lotId: string
}

const Wishlist: React.FC<Props> = (props) => {
    const {
        handleRetrieveWishlistProfilesByLotId_d,
        lotId
    } = props;
    const { classes, cx } = useStyles();

    const [profilesData, setProfilesData] = useState<any>();
    const [displayModel, setDisplayModel] = useState<NestedGridDataModel>();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingProfiles, setLoadingProfiles] = useState<boolean>(true);

    useEffect(() => {
        refreshList(null, profilesData?.pagination);
    }, [])

    const refreshList = (filters?: any, pagination?: any) => {
        setLoadingProfiles(true);

        handleRetrieveWishlistProfilesByLotId_d(lotId, filters, pagination, (res) => {
            if (res.success) {
                setProfilesData(res.response);
                setLoadingProfiles(false);
            };
        });
    }

    useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];
        let cells: Cell[]

        headers = [
            { title: "Name", align: "inherit" },
            { title: "Contact", align: "inherit" },
            { title: "Type", align: "inherit" },
            { title: "Wishlist Date", align: "inherit" },
        ];

        profilesData?.list?.forEach((profile) => {
            cells = [
                { text: profile.companyProfileFirstName ? [profile.companyProfileFirstName, profile.companyProfileLastName].join(' ') : [profile.individualProfileFirstName, profile.individualProfileLastName].join(' '), align: "inherit" },
                { text: profile.companyProfileEmail || profile.individualProfileEmail, align: "inherit" },
                { text: profile.profileType, align: "inherit" },
                { text: formatDateTime(profile.wishListedAt, 'yyyy/MM/dd HH:mm'), align: "inherit" },
            ];

            rows.push({
                id: profile.id,
                cells: cells,
            });
        });

        model = {
            headers: headers,
            rows: rows,
            headerButtons: headerActionbuttons()
        };

        setDisplayModel(model);
    }, [lotId, profilesData])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, profilesData?.pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>,
        ]
    }

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateAvailableFilters = [

    ]

    return <React.Fragment>
        <NestedAccordionGrid
            selectedFilters={selectedFilters}
            dataModel={displayModel}
            pagination={profilesData?.pagination}
            onFiltersChange={handleFiltersChange}
            availableFilters={generateAvailableFilters}
            onHandleChangePage={handlePageChange}
            loading={loadingProfiles}
        />
    </React.Fragment>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRetrieveWishlistProfilesByLotId_d: (lotId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveWishlistProfilesByLotId(lotId, filters, pagination, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);