import React from 'react';

import { Button, Tooltip } from '@mui/material';

import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import LinkedCameraTwoToneIcon from '@mui/icons-material/LinkedCameraTwoTone';
import MarkunreadTwoToneIcon from '@mui/icons-material/MarkunreadTwoTone';

const SidebarFooter = () => {
    return (
      <>
      </>
    //<>
    //  <div className="app-sidebar--footer">
    //    <ul>
    //      <li>
    //        <Tooltip arrow placement="top" title="Analytics Dashboard">
    //          <Button
    //            variant="text"
    //            className="btn-transition-none d-40 mx-2 p-0">
    //            <ListAltTwoToneIcon />
    //          </Button>
    //        </Tooltip>
    //      </li>
    //      <li>
    //        <Tooltip arrow placement="top" title="Crypto Components">
    //          <Button
    //            variant="text"
    //            className="btn-transition-none d-40 mx-2 p-0">
    //            <MarkunreadTwoToneIcon />
    //          </Button>
    //        </Tooltip>
    //      </li>
    //      <li>
    //        <Tooltip arrow placement="top" title="Buttons">
    //          <Button
    //            variant="text"
    //            className="btn-transition-none d-40 mx-2 p-0">
    //            <LinkedCameraTwoToneIcon />
    //          </Button>
    //        </Tooltip>
    //      </li>
    //    </ul>
    //  </div>
    //</>
  );
};

export default SidebarFooter;
