import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Thunks
export const handleSubmit = (userId: string, tokenId: string, onCompletedCallback: (res: any) => void) => {

    let userInfo = {
        userId: userId,
        tokenId: tokenId
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.confirmAccount, userInfo)
            .then(function (response) {
                if (onCompletedCallback) onCompletedCallback(response);
            })
    }

}