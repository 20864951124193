import React from 'react'
import useStyles from './ResetPasswordStyles';
import ResetPasswordCard from '@/components/ResetPasswordCard/ResetPasswordCard';

const ResetPassword: React.FC = () => {

    const { classes, cx } = useStyles();

    return (
        <ResetPasswordCard />
    )
}

export default ResetPassword
