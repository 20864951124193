import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { Card, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, List, ListItem } from '@mui/material';
import { FaSave, FaBackspace, FaDownload, FaBolt, } from "react-icons/fa";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { v4 as uuidv4 } from 'uuid';
import ns from '@/helpers/NotificationService';
import { groupBy, orderBy } from "lodash";
import HeaderText from '../../components/HeaderText/HeaderText';
import { handleFetchBulkUpload, handleFetchBulkUploadImagesWithPagination, handleGenerateBulkUploadTemplate, handleProcessBulkUpload, handleUpsertBulkUpload } from './store/BulkUpload';
import BulkUploadFileUploader from './components/BulkUploadFileUploader';
import { formatDateTime, toBase64 } from '../../helpers/Utils';
import { handleAuctionFetch } from '../Auctions/store/Auctions';
import BulkUploaderImageUploader from './components/BulkUploaderImageUploader';
import BulkUploadValidationRow from './components/BulkUploadValidationRow';
import requestClient from '../../api/RequestClient';
import { apiPaths } from '../../api/ApiPaths';
import BulkUploaderPreviewLotsModal from './components/BulkUploaderPreviewLotModal';
import LoadingButton from '@mui/lab/LoadingButton';
import BulkUploaderFileUploadTable from './components/BulkUploaderFileUploadTable';
import { FileHistoryItem, ImageHistoryItem, ValidationHistoryItem } from "@/dto/BulkUploader";
import BulkUploaderImageUploadTable from './components/BulkUploaderImageUploadTable';
import BulkUploaderValidationTable from './components/BulkUploaderValidationTable';

interface Props {
    handleGenerateTemplate_d: (onCompletedCallback: (res: any) => void) => void,
    handleProcessBulkImport_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    handleFetchBulkUpload_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    handleFetchBulkUploadImagesWithPagination_d: (bulkUploadId: string, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleUpsertBulkUpload_d: (payload: any, onCompletedCallback: (res: any) => void) => void,
    handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => void,
    settings: any,
    auction: any,
    loading: boolean,
}

const BulkUploadAddEdit: React.FC<Props> = props => {
    const {
        handleGenerateTemplate_d,
        handleProcessBulkImport_d,
        handleFetchBulkUpload_d,
        handleFetchBulkUploadImagesWithPagination_d,
        handleUpsertBulkUpload_d,
        handleAuctionFetch_d,
        settings,
        auction,
        loading
    } = props;

    const history = useHistory();

    const { id }: any = useParams();

    const [bulkUpload, setBulkUpload] = useState<any>({
        name: ''
    });

    const [fileHistoryItems, setFileHistoryItems] = useState<FileHistoryItem[]>([]);
    const [imageHistoryItems, setImageHistoryItems] = useState<ImageHistoryItem[]>([]);
    const [validationHistoryItems, setValidationHistoryItems] = useState<ValidationHistoryItem[]>([]);

    const [attachedFile, setAttachedFile] = useState<any>(undefined);
    const [generatingTemplate, setGeneratingTemplate] = useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState('0');
    const [uploadOnSelect, setUploadOnSelect] = useState<boolean>(true);
    const [uploading, setUploading] = useState<boolean>(false);
    const [previewModalState, setPreviewModalState] = useState<any>({ open: false });
    const [nameInvalid, setNameInvalid] = useState(false);
    const [processingBulkImport, setProcessingBulkImport] = useState(false);
    const hasValidationErrors = useMemo(() => bulkUpload?.lots?.some(x => x.validationErrors?.length > 0), [bulkUpload?.lots])

    const [bulkUploadImages, updateBulkUploadImages] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                var index = state?.list.findIndex(x => x.id === action.payload.id);
                if (index > -1) {
                    const tempList = [...state.list];
                    tempList[index] = action.payload;
                    return { ...state, list: tempList };
                }
                else {
                    return { ...state, list: orderBy((state?.list ?? []).concat([action.payload]), ["order"], ['asc']) };
                }
            case 'remove': return { ...state, list: state.list.filter(x => x.id !== action.id) };
            case 'set': return action.payload
            default: return state;
        }
    }, undefined);

    const [images, updateImages] = useReducer((state, action) => {
        switch (action.type) {
            case 'add': return orderBy(state.concat(action.list), ["order"], ['asc']);
            case 'remove': return state.filter(x => x._tempId !== action.id);
            case 'clear': return [];
            default: return state;
        }
    }, []);

    //const [uploadingImageIds, updateUploadingState] = useReducer((state, action) => {
    //    switch (action.type) {
    //        case 'add': return [...state, action.id];
    //        case 'remove': return state.filter(x => x !== action.id);
    //        default: return state;
    //    }
    //}, []);

    useEffect(() => {
        refreshData();
    }, [id])

    useEffect(() => {
        let query = new URLSearchParams(history.location.search);
        const auctionId = query.get('auctionId');

        if (auctionId && auction?.id !== auctionId) {
            handleAuctionFetch_d(auctionId)
        }
    }, [auction])

    useEffect(() => {
        if (uploadOnSelect && images && images?.length > 0) {
            setUploading(true);
        }
    }, [uploadOnSelect, images])

    useEffect(() => {
        if (uploading) {
            handleUploadImages(images);
        }
    }, [uploading])

    useEffect(() => {
        const newArr: FileHistoryItem[] = [];
        let index = bulkUpload?.fileHistory ? (bulkUpload?.fileHistory?.length - 1) : 0;

        if (attachedFile) {
            newArr.push({
                index: 0,
                fileName: attachedFile?.name,
                createdAt: formatDateTime(new Date(), 'yyyy/MM/dd HH:mm'),
                status: '',
                newFile: true
            })
        }

        bulkUpload?.fileHistory?.map(x => {
            newArr.push({ ...x, index: index--, fileName: x.fileName, createdAt: x.createdAt, status: x.status, newFile: false });
        });

        setFileHistoryItems(orderBy(newArr, ["createdAt"], ['desc']));
    }, [bulkUpload, attachedFile, activeTab])

    useEffect(() => {
        if (!uploading) setImageHistoryItems(orderBy(bulkUploadImages?.list, ["order"], ['asc']));
    }, [uploading, bulkUploadImages?.list, activeTab])


    const imageValidation = useMemo(() => {
        if (!bulkUploadImages?.list) return {};

        var groups = groupBy(bulkUploadImages?.list, x => Math.floor(x.order));
        var data: any = {};

        Object.keys(groups).map((key, index) => {
            var lotExists = bulkUpload?.lots?.filter(x => x.uploadedLotNumber == key)

            if (lotExists?.length > 0) {
                data[key] = {
                    bannerImageCount: groups[key].filter(x => x.order % 1 === 0)?.length,
                    otherImageCount: groups[key].filter(x => x.order % 1 > 0)?.length
                }
            }
        });

        return data;
    }, [bulkUpload?.lots, bulkUploadImages?.list])

    useEffect(() => {
        if (!uploading) {
            const reorderedLots: ValidationHistoryItem[] = [];

            bulkUpload?.lots?.map((x: ValidationHistoryItem, index) => {
                const record = imageValidation[x.uploadedLotNumber];
                if (record) {
                    reorderedLots.push({ ...x, bannerImageCount: record?.bannerImageCount ?? 0, otherImageCount: record?.otherImageCount ?? 0 });
                } else {
                    reorderedLots.push({ ...x, bannerImageCount: 0, otherImageCount: 0 });
                }
            });

            setValidationHistoryItems(orderBy(reorderedLots, ["uploadedLotNumber"], ['asc']));
        }
    }, [uploading, imageValidation, bulkUploadImages?.lots, activeTab, bulkUpload?.lots])

    const refreshData = () => {
        if (id) {
            showProgressSpinner({ description: "Retrieving Bulk Upload..." });
            handleFetchBulkUpload_d(id, (res: any) => {
                if (res?.success) {
                    let data = res.response;
                    setBulkUpload(data);
                    setAttachedFile(undefined);
                }
                else {
                    ns.error("Failed to retrieve the bulk upload")
                }
                hideProgressSpinner();
            });

            refreshImageList(null, false);
        }
    }

    const refreshImageList = (pagination?: any, withTableLoading: boolean = true) => {
        if (!withTableLoading) showProgressSpinner({ description: `Retrieving Bulk Uploads...` });
        handleFetchBulkUploadImagesWithPagination_d(id, pagination, (res: any) => {
            if (res.success) {
                updateBulkUploadImages({ type: 'set', payload: res.response });
            }
        });
    }

    const onCancel = () => {
        history.goBack();
    }

    const onSubmit = async () => {
        const isAllFieldsValid = validate();
        if (isAllFieldsValid) {
            showProgressSpinner({ description: "Saving Bulk Upload..." });

            var query = new URLSearchParams(history.location.search);
            var auctionId = query.get("auctionId");

            let payload: any = {
                id: bulkUpload?.id ?? uuidv4(),
                name: bulkUpload?.name,
                auctionId
            }

            if (attachedFile) {
                var base64 = await toBase64(attachedFile);
                payload.fileBase64String = base64;
                payload.fileName = attachedFile.name;
                payload.originalFileName = attachedFile.name;
            }

            handleUpsertBulkUpload_d(payload, (res) => {
                hideProgressSpinner();
                if (res?.success) {
                    ns.success("Bulk upload successfully saved");

                    if (id === undefined) {
                        history.replace("/BulkUploadsEdit/" + res.response.id + window.location.search);
                    }
                    else refreshData();
                } else {
                    ns.error(res?.error);
                }
            })
        }
        else {
            ns.error("Please fill in all required fields");
        }
    }

    const validate = () => {
        let isAllValid = true;

        if (bulkUpload.name === "") {
            setNameInvalid(true);
            isAllValid = false;
        }
        else {
            setNameInvalid(false);
        }

        return isAllValid;
    }

    const handleDownloadTemplate = () => {
        setGeneratingTemplate(true);
        handleGenerateTemplate_d((response: any) => {
            if (response.ok) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        settings.LotName + ' Bulk Upload Template.xlsx',
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                });
            }
            else {
                ns.error("Failed to generate the template");
            }
            setGeneratingTemplate(false);
        });
    }

    const handleFileUploaded = file => {
        if (file) setAttachedFile(file);
    }

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleUploadImages = async (images) => {
        const imagesToUpload:any[] = images.map(x => ({
            bulkUploadId: id,
            fileName: x.fileName,
            fileData: x.fileData,
            _tempId: x._tempId
        }));

        let imageProcessedCounter = 0;
        showProgressSpinner({ description: "Processing Images.\n\nThis can take several minutes.\nPlease do not close or refresh your browser..." });

        let batchNumber = 0;
        const batchSize = 3;

        let batch = imagesToUpload.slice(batchNumber * batchSize, (batchNumber + 1) * batchSize);

        while (batch.length) {
            await Promise.all(batch.map(async (imageToUpload, index, list) => {
                //updateUploadingState({ type: "add", id: imageToUpload._tempId });
                return requestClient.post(apiPaths.lot.addBulkUploadImage, imageToUpload).then(function (resp) {
                    //updateUploadingState({ type: "remove", id: imageToUpload._tempId });
                    updateImages({ type: "remove", id: imageToUpload._tempId });

                    if (resp.success) {
                        imageProcessedCounter++;
                        showProgressSpinner({ description: "Processing Images.\n\nThis can take several minutes.\nPlease do not close or refresh your browser.", extra: `Uploading Image ${imageProcessedCounter} of ${imagesToUpload?.length}...` });
                        updateBulkUploadImages({ type: 'add', payload: resp.response });
                    }
                });
            }));

            batchNumber++;
            batch = imagesToUpload.slice(batchNumber * batchSize, (batchNumber + 1) * batchSize);
        }
        setUploading(false);
        hideProgressSpinner();
    }

    const handleProcessBulkImport = () => {
        setProcessingBulkImport(true);
        handleProcessBulkImport_d(id, (response: any) => {
            if (response.success) {
                ns.success("Successfully processed the bulk import");
                history.goBack();
            }
            else {
                ns.error(response.error);
            }
            setProcessingBulkImport(false);
        });

    }

    const hasImageValidationErrors = useMemo(() => {
        if (Object.keys(imageValidation)?.length < bulkUpload?.lots?.length) return true;

        return Object.keys(imageValidation).some(key => {
            return imageValidation[key].bannerImageCount !== 1 || imageValidation[key].otherImageCount < 1 || imageValidation[key].otherImageCount > 50;
        });
    }, [bulkUpload, imageValidation]);

    const handlePreviewLot = (uploadedLotNumber: number) => {
        const lotIndex = bulkUpload?.lots?.findIndex(x => x.uploadedLotNumber === uploadedLotNumber);

        const images = orderBy(bulkUploadImages.list.filter(x => Math.floor(x.order) === uploadedLotNumber), "order");

        setPreviewModalState({
            open: true,
            lot: {
                ...(bulkUpload.lots[lotIndex] ?? {}),
                ...(imageValidation?.[uploadedLotNumber] ?? { bannerImageCount: 0, otherImageCount: 0 })
            },
            images
        });
    }

    return <>
        <BulkUploaderPreviewLotsModal {...previewModalState} onClose={() => setPreviewModalState({ open: false })} />
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Card className="card-box mb-spacing-6-x2">
                    <div className="card-header dpy-3">
                        <div className="card-header--title font-size-lg">
                            Bulk Uploader
                        </div>
                    </div>
                    <div className='p-4'>
                        <b>
                            Please remember to click SAVE if you are not completing the process in a single session, or when you make changes.
                            If you do not click save, your progress will be lost.
                            If applicable please edit individual {settings.LotName?.toLowerCase()} in the {settings?.LotName?.toLowerCase()} section.
                        </b>
                    </div>

                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem
                            button
                            disableRipple
                            selected={activeTab === "0"}
                            onClick={() => {
                                toggle("0");
                            }}>
                            {settings.LotName}s Upload Template
                        </ListItem>
                        <ListItem
                            disabled={!bulkUploadImages?.list}
                            button
                            disableRipple
                            selected={activeTab === "1"}
                            onClick={() => {
                                toggle("1");
                            }}>
                            Photo's / Images
                        </ListItem>
                        <ListItem
                            disabled={!bulkUploadImages?.list}
                            button
                            disableRipple
                            selected={activeTab === "2"}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            Validation & Processing
                        </ListItem>
                    </List>

                    <div className={"tab-item-wrapper pl-3 pr-3 " + (activeTab === '0' ? 'active' : '')} key={0}>
                        <div className="p-4">
                            <div className="pr-2 pb-4">
                                <TextField disabled={id !== undefined && bulkUpload?.status > 0} value={bulkUpload?.name} required error={nameInvalid} onChange={e => setBulkUpload({ ...bulkUpload, name: e.target.value })} id="name" fullWidth label="Name" onBlur={validate} margin="normal" />
                            </div>

                            <div className="pb-4">
                                <HeaderText header={'Generate Bulk Upload Template'} description={[`Use the button below to generate a bulk upload template. Please note that this will be used to create ${settings.LotName?.toLowerCase()}s for the ${settings.AuctionName.toLowerCase()} ${auction?.name}`]} />
                                <div className="p-4 pl-5">
                                    <LoadingButton loading={generatingTemplate} onClick={handleDownloadTemplate} variant="contained" className="btn-primary p-3 text-white text-capitalize" disabled={generatingTemplate}>
                                        <span className="btn-wrapper--icon">
                                            <FaDownload />
                                        </span>
                                        <span className="btn-wrapper--label">Generate Bulk Upload Template</span>
                                    </LoadingButton>
                                </div>
                                <HeaderText header={'Upload Completed Bulk Upload Template'} description={[`Use the area below to upload or replace the completed bulk upload template.`]} />
                                <div className="text-center p-4">
                                    <BulkUploadFileUploader onFileUploaded={handleFileUploaded} />
                                </div>

                                <HeaderText header={'File History'} description={[`The most recently uploaded file in the table below will be used during the validation and processing of this bulk upload. If you would like to replace or update this file, please use the section above to browser or drop a new file.`]} />
                                <div className="pt-4">
                                    {
                                        activeTab === '0' &&
                                        <BulkUploaderFileUploadTable fileHistoryData={fileHistoryItems} isTableLoading={loading} />
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"tab-item-wrapper pl-3 pr-3 " + (activeTab === '1' ? 'active' : '')} key={1}>
                        <div className="p-4">
                            {id === undefined && <div className="pb-4">
                                <HeaderText header={`Bulk Upload Photo's / Images`} description={[`Photo/Image uploads are currently unavailable. Please fill in the required fields on the "${settings.LotName?.toUpperCase()} UPLOAD TEMPLATE" tab and click save to continue with the bulk upload process.`]} />
                            </div>}

                            {id && <>
                                <HeaderText header={`Bulk Upload Photo's / Images`} description={`Use the area below to upload photo's/images. Please note that these images will be used to create ${settings.LotName?.toLowerCase()}s for the ${settings.AuctionName.toLowerCase()} ${auction?.name}`} />
                                <BulkUploaderImageUploader
                                    onImagesUpdated={(list: any[]) => updateImages({ type: 'add', list })}
                                    acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                                />

                                <HeaderText header={`Uploaded Images`} description={`The list below displays all images and their upload status. Please ensure that all image uploads succeed.`} />

                                <div className="pt-4">
                                    {
                                        activeTab === '1' && !uploading &&
                                        <BulkUploaderImageUploadTable
                                            imageHistoryData={imageHistoryItems}
                                            isTableLoading={false}
                                            isTableSaving={loading}
                                            onRemoveImageCallback={(id) => updateBulkUploadImages({ type: 'remove', id })}
                                        />
                                    }
                                </div>

                            </>}
                        </div>
                    </div>

                    <div className={"tab-item-wrapper pl-3 pr-3 " + (activeTab === '2' ? 'active' : '')} key={2}>
                        <div className="p-4">
                            {id === undefined && <div className="pb-4">
                                <HeaderText header={`Validation & Processing`} description={[`Validation & Processing are currently unavailable. Please fill in the required fields on the "${settings.LotName?.toUpperCase()} UPLOAD TEMPLATE" tab and click save to continue with the bulk upload process.`]} />
                            </div>}

                            {id && <>
                                <HeaderText header={`Validation`} description={[`Please validate the ${settings.LotName?.toLowerCase()}s data and photo's/images below before processing this bulk upload. Once all validation errors have been corrected, you may proceed to process the bulk upload by clicking on the "PROCESS BULK UPLOAD" button in the next section.`]} />
                                <div className="pt-4 pb-4">
                                    {
                                        activeTab === '2' &&
                                        <BulkUploaderValidationTable validationHistoryData={validationHistoryItems} isTableLoading={loading} onViewCallback={(e) => handlePreviewLot(e)} />
                                    }
                                </div>
                                <HeaderText header={`Processing`} description={[`Click on the button below to start processing this bulk upload.`]} />
                                <div className="text-center p-4">
                                    <LoadingButton loadingPosition="center" loading={processingBulkImport} onClick={handleProcessBulkImport} variant="contained" className="btn-primary p-3 text-white text-capitalize" disabled={((bulkUpload?.lots?.length ?? 0) === 0) || hasValidationErrors || hasImageValidationErrors || processingBulkImport}>
                                        <span className="btn-wrapper--icon">
                                            <FaBolt />
                                        </span>
                                        <span className="btn-wrapper--label">Process Bulk Upload</span>
                                    </LoadingButton>
                                </div>
                            </>}
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
        <FloatyButtonGroup>
            <Tooltip arrow title="Save" placement="left">
                <Button size="large" variant="contained" onClick={onSubmit}
                    disabled={uploading}
                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                </Button>
            </Tooltip>
            <Tooltip arrow title="Back" placement="left">
                <Button size="large" variant="contained" onClick={onCancel}
                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                    <span className="btn-wrapper--icon">
                        <FaBackspace />
                    </span>
                </Button>
            </Tooltip>
        </FloatyButtonGroup>
    </>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleGenerateTemplate_d: (onCompletedCallback: (res: any) => void) => dispatch(handleGenerateBulkUploadTemplate(onCompletedCallback)),
        handleProcessBulkImport_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleProcessBulkUpload(id, onCompletedCallback)),
        handleFetchBulkUpload_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleFetchBulkUpload(id, onCompletedCallback)),
        handleFetchBulkUploadImagesWithPagination_d: (bulkUploadId: string, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleFetchBulkUploadImagesWithPagination(bulkUploadId, pagination, onCompletedCallback)),
        handleUpsertBulkUpload_d: (payload: any, onCompletedCallback: (res: any) => void) => dispatch(handleUpsertBulkUpload(payload, onCompletedCallback)),
        handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionFetch(id, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    auction: state.auctions.auction,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadAddEdit);