import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { FinanceDocument, FinanceDocumentType } from '../../../dto/FinanceDocument';

//Thunks
export const retrieveAllFinanceDocuments = (data: Partial<{
    auctionId: string,
    type: FinanceDocumentType,
    status: string,
    searchString: string,
}>, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.retrieveAll, data)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }

}

export const retrieveAllConsolidatableFinanceDocuments = (data: Partial<{
    auctionId: string,
}>, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.retrieveAllConsolidatable, data)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }

}

export const retrieveFinanceDocument = (id: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.get(apiPaths.auction.financeDocument.retrieve, { id }, null)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }

}


export const handleUpdateFinanceDocument = (data: FinanceDocument, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.update, data, { id: data.id })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleSendFinanceDocument = (object: any, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.send, object, { id: object.id })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleBulkSendFinanceDocument = (object: any, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.bulkSend, object)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleProcessFinanceDocument = (id: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.process, {}, { id })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleCancelFinanceDocument = (id: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.get(apiPaths.auction.financeDocument.cancel, { id }, null)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleBulkProcessFinanceDocument = (financeDocumentIds: string[], onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.bulkProcess, { financeDocumentIds })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleGenerateInvoice = (proformaInvoiceId: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.generateInvoice, {}, { proformaInvoiceId })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleGenerateConsolidatedProformaInvoice = (groupedConsolidatedFinanceDocumentIds: string[][], onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.generateConsolidatedProformaInvoice, {
            consolidatedFinanceDocumentIds: groupedConsolidatedFinanceDocumentIds
        }).then(function (res) {
            onCompletedCallback(res)
        })
    }
}

export const handleGenerateReleaseNote = (invoiceId: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.post(apiPaths.auction.financeDocument.generateReleaseNote, {}, { invoiceId })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleDownloadFinanceDocument = (id: string, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.downloadFile(apiPaths.auction.financeDocument.download, { id }, null)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleBulkDownloadFinanceDocument = (financeDocumentIds: string[], onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        requestClient.downloadFile(apiPaths.auction.financeDocument.bulkDownload, null, null, null, 'post', { financeDocumentIds })
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}