import React, { FC, memo } from "react";
import {
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from "@mui/material";
import { MdExpandMore } from "react-icons/md";

import MapData from "./MapData";
import { VenueData } from "../NestedAccordionGrid.types";

interface VenueDisplayProps{
	venueData?:VenueData;
	expanded?:boolean;
	onExpand:(isExpanded:boolean) => void;
}

const VenueDisplay:FC<VenueDisplayProps> = (props) => {
	const { venueData, expanded, onExpand } = props;

	return (
		<>
			{venueData && (
				<Accordion
					expanded={expanded}
					onChange={(e, isExpanded) => onExpand(isExpanded)}>
					<AccordionSummary
						className="bg-neutral-primary"
						expandIcon={<MdExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header">
						<Typography>{venueData.title ?? "Venue"}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2} justifyContent="center">
							<Grid item sm={6}>
								<div className="p-3">
									{venueData.name && (
										<>
											<strong>Name:</strong> {venueData.name}
											<br />
										</>
									)}
									{venueData.description && (
										<>
											<strong>Description:</strong> {venueData.description}
											<br />
										</>
									)}
									{venueData.addressLine1 && (
										<>
											<strong>Address line 1:</strong> {venueData.addressLine1}
											<br />
										</>
									)}
									{venueData.addressLine2 && (
										<>
											<strong>Address line 2:</strong> {venueData.addressLine2}
											<br />
										</>
									)}
									{venueData.country && (
										<>
											<strong>Country:</strong> {venueData.country}
											<br />
										</>
									)}
									{venueData.state && (
										<>
											<strong>State:</strong> {venueData.state}
											<br />
										</>
									)}
									{venueData.city && (
										<>
											<strong>City:</strong> {venueData.city}
											<br />
										</>
									)}
									{venueData.zip && (
										<>
											<strong>Zip:</strong> {venueData.zip}
											<br />
										</>
									)}
								</div>
							</Grid>
							<Grid item sm={6} className="p-3">
								<MapData venueData={venueData} />
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			)}
		</>
	);
};

export default memo(VenueDisplay);
