import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import {
    handleRegisteredProfileUpdate,
    handleRetrieveWishlistLotsByAuctionId,
} from './store/AuctionManagement';
import {
    Button,
    Tooltip
} from '@mui/material';
import { FaRedo } from "react-icons/fa";
import {NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, CustomData } from '@/components/NestedAccordionGrid'
import WishlistProfiles from './WishlistProfiles';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    handleRetrieveWishlistLotsByAuctionId_d: (auctionId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => void,
    selectedAuction: any
}

const Wishlist: React.FC<Props> = (props) => {
    const {
        handleRetrieveWishlistLotsByAuctionId_d,
        selectedAuction
    } = props;

    const { classes, cx } = useStyles();

    const [lotsData, setLotsData] = useState<any>();
    const [displayModel, setDisplayModel] = useState<NestedGridDataModel>();
    const [clearSearch,setClearSearch] = useState(false);

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingLots, setLoadingLots] = useState<boolean>(true);

    useEffect(() => {
       if(selectedAuction){
            const newFilters = {};
            refreshList(newFilters, lotsData?.pagination);
            setSelectedFilters(newFilters);
            setClearSearch(true);
            setTimeout(()=>setClearSearch(false) , 100);
       }
    }, [selectedAuction])

    const refreshList = (filters?: any, pagination?: any) => {
        setLoadingLots(true);

        handleRetrieveWishlistLotsByAuctionId_d(selectedAuction.id, filters, pagination, (res) => {
            if (res.success) {
                setLotsData(res.response);
                setLoadingLots(false);
            };
        });
    }

    useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];
        let cells: Cell[]

        headers = [
            { title: "Number", align: "inherit" },
            { title: "Image", align: "inherit", canSort: false },
            { title: "Name", align: "inherit" },
            { title: "SKU Number", align: "inherit" },
            { title: "Sold", align: "inherit" },
        ];

        lotsData?.list?.forEach((lot) => {
            const customData: CustomData[] = []

            cells = [
                { text: lot.number, align: "inherit" },
                { text: "", element: <img style={{ objectFit: "cover", height: "80px", width: "80px" }} alt="..." src={lot.thumbnailUrl} />, align: "inherit" },
                { text: lot.name, align: "inherit" },
                { text: lot.skuNumber, align: "inherit" },
                { text: lot.sold, element: lot.sold ? <div className="badge badge-success badge-pill m-1 text-capitalize">Yes</div > : <div className="badge badge-danger badge-pill m-1 text-capitalize">No</div >, align: "inherit" }
            ];

                cells.push();

            customData.push({
                title: "Profiles", element: <div style={{ width: '100%' }}><WishlistProfiles lotId={lot.id} /></div>
            });

            rows.push({
                id: lot.id,
                cells: cells,
                customData: customData,
            });
        });

        model = {
            headers: headers,
            rows: rows,
            headerButtons: headerActionbuttons()
        };

        setDisplayModel(model);
    }, [lotsData])

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, lotsData?.pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>,
        ]
    }

    const handlePageChange = (pagination: any) => {
        refreshList(selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const generateAvailableFilters = [

    ]

    return <React.Fragment>
        <NestedAccordionGrid
            selectedFilters={selectedFilters}
            dataModel={displayModel}
            pagination={lotsData?.pagination}
            onFiltersChange={handleFiltersChange}
            availableFilters={generateAvailableFilters}
            onHandleChangePage={handlePageChange}
            loading={loadingLots}
            clearSearch={clearSearch}
        />
    </React.Fragment>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRetrieveWishlistLotsByAuctionId_d: (auctionId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRetrieveWishlistLotsByAuctionId(auctionId, filters, pagination, onCompletedCallback)),
        handleRegisteredProfileUpdate_d: (user: any, onCompletedCallback?: (res: any) => void) => dispatch(handleRegisteredProfileUpdate(user, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);