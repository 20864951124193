import React, { LabelHTMLAttributes, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { clear, handleFetchAction } from '@/pages/Integration/store/Integration';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, List, ListItem, MenuItem, TextField, Tooltip } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import { useParams } from "react-router-dom";
import useHistory from '@/hooks/useHistory';

import ActionList from '@/components/Integration/ActionList';
import EntityUpsertRequestList from '@/components/Integration/EntityUpsertRequestList';
import ImageUpsertRequestList from '@/components/Integration/ImageUpsertRequestList';
import WebhookRequestList from '@/components/Integration/WebhookRequestList';
import Configuration from '@/components/Integration/Configuration';
import FloatyButtonGroup from "../../components/FloatyButtonGroup/FloatyButtonGroup";
import { FaBackspace, FaSave } from "react-icons/fa";
import SetObjectValue from 'lodash/set';
import AddIcon from "@mui/icons-material/Add";
import { handleContactsFetch } from '../Contacts/store/Contacts';
import { handleLotTypesFetch } from "../LotTypes/store/LotTypes";
import { handleVenuesFetch } from "../Venue/store/Venue";

import { handleAuctionsFetch } from '@/pages/Auctions/store/Auctions';
import ContactAddDialog from "../../components/Modal/ContactAddDialog";
import VenueAddDialog from "../../components/Modal/VenueAddDialog";
import LotTypeDialog from "../../components/Modal/LotTypeDialog";

import { handleFetchIntegrationConfig, handleManualOverride } from '@/pages/Integration/store/Integration';

interface Props {
    clear_d: () => void,
    loading: boolean,
    settings: any,
    integration: any,
    handleFetchAction_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    handleFetchIntegrationConfig_d: (onCompletedCallback: (res: any) => void) => void,
    handleAuctionsFetch_d: (onCompletedCallBack: () => void) => void,
    handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) => void,
    handleVenuesFetch_d: (onCompletedCallback?: (res: any) => void) => void,
    handleContactsFetch_d: (pagination: any, onCompletedCallback?: (res: any) => void) => void,
    handleManualOverride_d: (data: any, onCompletedCallback: (res: any) => void) => void,

    contacts: any[],
    venues: any[],
    lotTypes: any[],
    auctions: any[]
}

const ActionEdit: React.FC<Props> = (props) => {
    const history = useHistory();
    const { id }: any = useParams();

    const [loadingContacts, setLoadingContacts] = useState<boolean>(true);
    const [loadingVenues, setLoadingVenues] = useState<boolean>(true);
    const [loadingLotTypes, setLoadingLotTypes] = useState<boolean>(true);
    const [loadingAuctions, setLoadingAuctions] = useState<boolean>(true);

    const [lotTypeModalState, setLotTypeModalState] = useState({ isOpen: false, id: undefined });
    const [venueModalState, setVenueModalState] = useState({ isOpen: false, id: undefined });
    const [contactModalState, setContactModalState] = useState({ isOpen: false, id: undefined });

    const [formFields, setFormFields] = React.useReducer((state, action) => {
        let tempData = { ...state };

        SetObjectValue(tempData, action.path, action.value);
        return tempData;

    }, {})

    const {
        clear_d,
        loading,
        settings,
        integration: { action, config },
        handleFetchAction_d,
        handleLotTypesFetch_d,
        handleVenuesFetch_d,
        handleContactsFetch_d,
        handleAuctionsFetch_d,
        handleFetchIntegrationConfig_d,
        handleManualOverride_d,
        contacts,
        venues,
        lotTypes,
        auctions
    } = props;

    useEffect(() => {
        handleFetchIntegrationConfig_d((res) => {
            if (!res.success) {
                ns.error('Failed to load the integration configuration');
            }
        });
        handleLotTypesFetch_d(() => setLoadingLotTypes(false));
        handleVenuesFetch_d(() => setLoadingVenues(false));
        handleContactsFetch_d({ pageSize: -1 }, () => setLoadingContacts(false));
        handleAuctionsFetch_d(() => setLoadingAuctions(false));

        return (() => {
            clear_d();
        });
    }, [])

    useEffect(() => {
        if (id) {
            showProgressSpinner({ description: 'Loading action...' });
            handleFetchAction_d(id, (res) => {
                hideProgressSpinner();
            })
        }
    }, [id])

    const handleCancel = () => {
        history.goBack();
    }

    const handleSubmit = () => {
        handleManualOverride_d({ actionId: action.id, overrides: formFields }, (res) => {
            if (res.success) {
                ns.success("Successfully queued a manual entity update");
                handleCancel();
            }
            else {
                ns.error("Failed to queue a manual entity update");
            }
        });
    }

    const renderUsers = (userSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title='Users' className='pb-0' />
            <CardContent className='pt-0'>

            </CardContent>
        </Card>
    }

    const onSelectEntityChange = (e: any) => {
        setFormFields({ type: 'update', path: e.target.id || e.target.name, value: e.target.value });
    };

    const renderContacts = (contactSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title='Contacts' className='pb-0' />
            <CardContent className='pt-0'>
                {contactSummaries.map(x => <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                value={x.uniqueReference}
                                name={x.uniqueReference + '-label'}
                                fullWidth
                                className="m-2"
                                label='Unique Reference'
                                disabled
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                onChange={onSelectEntityChange}
                                value={formFields[x.id] || ''}
                                name={x.id}
                                fullWidth
                                required
                                className="m-2"
                                label='Contact Person'
                                select
                                disabled={loadingContacts}
                            >
                                {contacts?.map(contact => <MenuItem key={contact.id} value={contact.id}>
                                    {contact.firstName} {contact.lastName}
                                </MenuItem>)}
                            </TextField>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="p-3">
                            <IconButton
                                aria-label="Add"
                                className="p-3"
                                onClick={() => {
                                    setContactModalState({ isOpen: true, id: x.id });
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>)}
            </CardContent>
        </Card>
    }

    const renderVenues = (venueSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title='Venues' className='pb-0' />
            <CardContent className='pt-0'>
                {venueSummaries.map(x => <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                value={x.uniqueReference}
                                name={x.uniqueReference + '-label'}
                                fullWidth
                                className="m-2"
                                label='Unique Reference'
                                disabled
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                onChange={onSelectEntityChange}
                                value={formFields[x.id] || ''}
                                name={x.id}
                                fullWidth
                                required
                                className="m-2"
                                label='Venue'
                                select
                                disabled={loadingVenues}
                            >
                                {venues?.map(venue => <MenuItem key={venue.id} value={venue.id}>
                                    {venue.name}
                                </MenuItem>)}
                            </TextField>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="p-3">
                            <IconButton
                                aria-label="Add"
                                className="p-3"
                                onClick={() => {
                                    setVenueModalState({ isOpen: true, id: x.id });
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>)}
            </CardContent>
        </Card>
    }

    const renderLotTypes = (lotTypeSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title={settings.LotName + ' Types'} className='pb-0' />
            <CardContent className='pt-0'>
                {lotTypeSummaries.map(x => <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                value={x.uniqueReference}
                                name={x.uniqueReference + '-label'}
                                fullWidth
                                className="m-2"
                                label='Unique Reference'
                                disabled
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                onChange={onSelectEntityChange}
                                value={formFields[x.id] || ''}
                                name={x.id}
                                fullWidth
                                required
                                className="m-2"
                                label={settings.LotName + ' Type'}
                                select
                                disabled={loadingLotTypes}
                            >
                                {lotTypes?.map(lotType => <MenuItem key={lotType.id} value={lotType.id}>
                                    {lotType.name}
                                </MenuItem>)}
                            </TextField>
                        </div>
                    </Grid>
                    {config?.allowedEntities?.lotTypes && <Grid item xs={1}>
                        <div className="p-3">
                            <IconButton
                                aria-label="Add"
                                className="p-3"
                                onClick={() => {
                                    setLotTypeModalState({ isOpen: true, id: x.id });
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </Grid>}
                </Grid>)}
            </CardContent>
        </Card>
    }

    const renderAuctions = (auctionSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title={settings.AuctionName + 's'} className='pb-0' />
            <CardContent className='pt-0'>
                {auctionSummaries.map(x => <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                value={x.uniqueReference}
                                name={x.uniqueReference + '-label'}
                                fullWidth
                                className="m-2"
                                label='Unique Reference'
                                disabled
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                onChange={onSelectEntityChange}
                                value={formFields[x.id] || ''}
                                name={x.id}
                                fullWidth
                                required
                                className="m-2"
                                label={settings.AuctionName}
                                select
                                disabled={loadingAuctions}
                            >
                                {auctions?.map(auction => <MenuItem key={auction.id} value={auction.id}>
                                    {auction.name}
                                </MenuItem>)}
                            </TextField>
                        </div>
                    </Grid>
                </Grid>)}
            </CardContent>
        </Card>
    }

    const renderImages = (imageSummaries: any[]) => {
        return <Card className='card-transparent'>
            <CardHeader title='Images' className='pb-0' />
            <CardContent className='pt-0'>

            </CardContent>
        </Card>
    }

    const renderActionSummaries = () => {
        let elements: any[] = [];

        let users = action?.summaries?.filter(x => x.type === 'User') || [];
        if (users.length > 0) elements.push(renderUsers(users));

        let contacts = action?.summaries?.filter(x => x.type === 'Contact') || [];
        if (contacts.length > 0) elements.push(renderContacts(contacts));

        let venues = action?.summaries?.filter(x => x.type === 'Venue') || [];
        if (venues.length > 0) elements.push(renderVenues(venues));

        let lotTypes = action?.summaries?.filter(x => x.type === 'Lot Type') || [];
        if (lotTypes.length > 0) elements.push(renderLotTypes(lotTypes));

        let auctions = action?.summaries?.filter(x => x.type === 'Auction') || [];
        if (auctions.length > 0) elements.push(renderAuctions(auctions));

        return elements;
    }

    const renderPage = () => {
        return <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header dpy-3">
                            <div className="card-header--title font-size-lg">
                                Missing entities for the {action.type.replace('Lot', settings.LotName).replace('Auction', settings.AuctionName)} with unique reference [{action.uniqueReference}]
                            </div>
                        </div>
                        <div className="p-3">
                            {renderActionSummaries()}
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <LotTypeDialog
                isDialogOpened={lotTypeModalState.isOpen}
                handleCloseDialog={(data?: any) => {
                    if (data) {
                        showProgressSpinner({ description: "Saving " + settings.LotName + " Type..." });
                        handleLotTypesFetch_d((res) => {
                            if (res.success) {
                                setFormFields({ type: 'update', path: lotTypeModalState.id, value: data });
                            }
                            hideProgressSpinner();
                            setLotTypeModalState({ isOpen: false, id: undefined });
                        })
                    } else {
                        setLotTypeModalState({ isOpen: false, id: undefined });
                    }
                }}
            />
            <VenueAddDialog
                isDialogOpened={venueModalState.isOpen}
                handleCloseDialog={(data?: any) => {
                    if (data) {
                        showProgressSpinner({ description: "Saving Venue..." });
                        handleVenuesFetch_d((res) => {
                            setFormFields({ type: 'update', path: venueModalState.id, value: data });
                            hideProgressSpinner();
                        })
                        setVenueModalState({ isOpen: false, id: undefined });
                    }
                    else setVenueModalState({ isOpen: false, id: undefined });
                }}
            />
            <ContactAddDialog
                isDialogOpened={contactModalState.isOpen}
                handleCloseDialog={(data?: any) => {
                    if (data) {
                        showProgressSpinner({ description: "Saving Contact..." });
                        handleContactsFetch_d({ pageSize: -1 }, (res) => {
                            if (res.success) setFormFields({ type: 'update', path: contactModalState.id, value: data });
                            hideProgressSpinner();
                        })
                        setContactModalState({ isOpen: false, id: undefined });
                    }
                    else setContactModalState({ isOpen: false, id: undefined });
                }}
            />
            <FloatyButtonGroup>
                <Tooltip arrow title="Save" placement="left">
                    <Button size="large" variant="contained" onClick={() => handleSubmit()}
                        className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                    </Button>
                </Tooltip>
                <Tooltip arrow title="Back" placement="left">
                    <Button size="large" variant="contained" onClick={() => handleCancel()}
                        className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaBackspace />
                        </span>
                    </Button>
                </Tooltip>
            </FloatyButtonGroup>
        </>
    }

    return <>{!loading && action && renderPage()}</>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clear_d: () => dispatch(clear()),
        handleFetchAction_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleFetchAction(id, onCompletedCallback)),
        handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) => dispatch(handleLotTypesFetch(onCompletedCallback)),
        handleVenuesFetch_d: (onCompletedCallback?: (res: any) => void) => dispatch(handleVenuesFetch(onCompletedCallback)),
        handleContactsFetch_d: (pagination: any, onCompletedCallback?: (res: any) => void) => dispatch(handleContactsFetch(pagination, true, onCompletedCallback)),
        handleAuctionsFetch_d: (onCompletedCallback?: () => void) => dispatch(handleAuctionsFetch(onCompletedCallback)),
        handleFetchIntegrationConfig_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchIntegrationConfig(onCompletedCallback)),
        handleManualOverride_d: (data: any, onCompletedCallback: (res: any) => void) => dispatch(handleManualOverride(data, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    integration: state.integration,
    contacts: state.contacts.list,
    lotTypes: state.lotTypes.lotTypes,
    venues: state.venue.venues,
    auctions: state.auctions.auctions
})

export default connect(mapStateToProps, mapDispatchToProps)(ActionEdit);