import * as React from 'react';
import './bamburgh-theme/assets/base.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from "./Routes";
import NewVersionModal from './NewVersionModal'
import ProgressSpinner from '@/components/ProgressSpinner/ProgressSpinner';
import Dialog from '@/components/Dialog/Dialog';
import NotificationServiceWrapper from '@/components/NotificationServiceWrapper/NotificationServiceWrapper'
import ScrollUpButton from './components/ScrollUpButton/ScrollUpButton';
import ErrorBoundary from '@/components/wrappers/ErrorBoundary/ErrorBoundary';
import { signalRUpdateConnectionStatus } from './store/SignalR';
import { useSignalRNegotiation } from 'prembid-shared-library-npm/signalr';
import { useDispatch } from 'react-redux';


const ErrorFallback = ({ error, errorInfo }) => (
    <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
        </details>
    </div>
);

export default () => {
    const dispatch = useDispatch();
    useSignalRNegotiation({
        onConnectionStatusChange: (connectionStatus) => dispatch(signalRUpdateConnectionStatus(connectionStatus)),
    });

    return <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.StrictMode>
                <ScrollUpButton ShowAtPosition={600} StopPosition={0} style={{ width: 35, height: 35, background: "#2b2b2b", borderRadius: "100px", padding: "5px", right: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                <NotificationServiceWrapper />
                <ProgressSpinner />
                <Dialog />
                <Routes />
                <NewVersionModal />
            </React.StrictMode>
        </ErrorBoundary>
    </BrowserRouter>
};
