import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import { setSidebarToggleMobile } from "../../../store/reducers/ThemeOptions";

import { AiOutlineMenu } from "react-icons/ai";
import { FaGavel, FaUserFriends, FaUser, FaCaretDown, FaCaretUp, FaCog, FaInfoCircle, FaPaperclip } from "react-icons/fa";
import {
    Link,
    List,
    ListItem,
    Collapse,
} from "@mui/material";
import ConfirmationDialog from "@/components/Modal/ConfirmationDialog";
import useHistory from "../../../hooks/useHistory";

//TODO ARNO
//Active flags and styles on nav links have been removed in the latest version of react
// => need to either extend the component with the logic or research a different way to do it
// => seems to be working without modifications => Need more testing 

const SidebarMenu = (props) => {
    const history = useHistory();

    const { setSidebarToggleMobile, sidebarUserbox } = props;

    const toggleSidebarMobile = () => setSidebarToggleMobile(false);
    const [confirmationModalToggle, setConfirmationModalToggle] = useState(false);
    const [collapseShow, setCollapseShow] = React.useState({
        auctionCollapse: true,
        systemSettingsCollapse: false,
        systemDocumentationCollapse: false,
        listingsCollapse: false
    })

    useEffect(() => {
        confirmationModalToggle && closeConfirmationModal();
    }, [history.location.key])

    const showConfirmationModalToggle = () => {
        setConfirmationModalToggle(true);
    }

    const closeConfirmationModal = () => {
        setConfirmationModalToggle(false);
    }

    const openTrainingDocuments = () => {
        closeConfirmationModal();
        if (props.settings.ProductTrainingDocuments) window.open(props.settings.ProductTrainingDocuments);
    }

    const openIntroPack = () => {
        if (props.settings.ProductIntroPack) window.open(props.settings.ProductIntroPack);
    }


    const LotTypesNavigation = () => {
        if (props.settings.ProductName === "PremBid") {
            return <NavLink
                style={{ borderRadius: 100 }}
                className={"nav-link-simple" + " font-weight-normal"}
                onClick={toggleSidebarMobile}
                to="/LotTypes"
            >
                {props.settings.LotName + " Types"}
            </NavLink>
        } else {
            return <></>
        }
    }

    return (
        <>
            <div className="sidebar-navigation">
                <div className="sidebar-header text-white font-weight-normal">
                    <span>Navigation menu</span>
                </div>
                <List className="p-2">
                    <ListItem style={{ cursor: "pointer" }} alignItems="flex-start" className="py-3" onClick={() => { setCollapseShow({ ...collapseShow, auctionCollapse: !collapseShow.auctionCollapse }) }}>
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span>
                                    <FaGavel className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1, display: 'inline-flex', alignItems: 'center' }}>
                                <a className="hide">
                                    <span className="text-white mx-3 font-weight-bold" >{props.settings.AuctionName + "s"}</span>
                                </a>
                                <span className="sidebar-collapsed hide" style={{ position: 'absolute', right: 0 }}>
                                    {collapseShow.auctionCollapse ? <FaCaretUp className="text-white" size={20} /> : <FaCaretDown className="text-white" size={20} />}
                                </span>
                            </div>
                        </div>
                    </ListItem>
                    <Collapse className="mx-4 hide" in={collapseShow.auctionCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/Auctions"
                                >
                                    {props.settings.AuctionName + "s/Create " + props.settings.AuctionName}
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/AuctionManagement"
                                >
                                    {props.settings.AuctionName + " Management"}
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem alignItems="flex-start" className="py-3">
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span>
                                    <AiOutlineMenu className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1 }}>
                                <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/Lots"
                                    className="hide"
                                >
                                    <span className=" text-white mx-3 font-weight-bold">{props.settings.LotName + "s"}</span>
                                </NavLink>
                            </div>
                        </div>

                    </ListItem>
                    <ListItem alignItems="flex-start" className="py-3">
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span>
                                    <FaUser className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1 }}>
                                <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/Contacts"
                                    className="hide"
                                >
                                    <span className=" text-white mx-3 font-weight-bold">Contacts/Agents</span>
                                </NavLink>
                            </div>
                        </div>

                    </ListItem>
                    <ListItem alignItems="flex-start" className="py-3">
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span>
                                    <FaUserFriends className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1 }}>
                                <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/Users"
                                    className="hide"
                                >
                                    <span className=" text-white mx-3 font-weight-bold">Users</span>
                                </NavLink>
                            </div>
                        </div>

                    </ListItem>
                    <ListItem style={{ cursor: "pointer" }} alignItems="flex-start" className="py-3" onClick={() => {
                        setCollapseShow({ ...collapseShow, systemSettingsCollapse: !collapseShow.systemSettingsCollapse })
                    }}>
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span>
                                    <FaCog className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1, display: 'inline-flex', alignItems: 'center' }}>
                                <a className="hide">
                                    <span className=" text-white mx-3 font-weight-bold">System Management</span>
                                </a>
                                <span className="sidebar-icon hide" style={{ position: 'absolute', right: 0 }}>
                                    {collapseShow.systemSettingsCollapse ? <FaCaretUp className="text-white" size={20} /> : <FaCaretDown className="text-white" size={20} />}
                                </span>
                            </div>
                        </div>
                    </ListItem>
                    <Collapse className="mx-4 hide" in={collapseShow.systemSettingsCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem alignItems="flex-start">
                                <LotTypesNavigation />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/DocumentTemplates"
                                >
                                    Documents
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/IncrementTable"
                                >
                                    {"Increment Tables"}
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/BankDetails"
                                >
                                    Bank Details
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/BiddingCalculations"
                                >
                                    Bidding Calculations
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/Venue"
                                >
                                    Venues
                                </NavLink>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <NavLink
                                    style={{ borderRadius: 100 }}
                                    className={"nav-link-simple" + " font-weight-normal"}
                                    onClick={toggleSidebarMobile}
                                    to="/Integration"
                                >
                                    Integration
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem style={{ cursor: "pointer" }} alignItems="flex-start" className="py-3" onClick={() => {
                        setCollapseShow({ ...collapseShow, systemDocumentationCollapse: !collapseShow.systemDocumentationCollapse })
                    }}>
                        <div className="hide-icon" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                            <div>
                                <span className="sidebar-icon">
                                    <FaInfoCircle className="text-white" size={24} />
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', flex: 1, display: 'inline-flex', alignItems: 'center' }}>
                                <a className="hide">
                                    <span className=" text-white mx-3 font-weight-bold">System Documentation</span>
                                </a>
                                <span className="hide" style={{ position: 'absolute', right: 0 }}>
                                    {collapseShow.systemDocumentationCollapse ? <FaCaretUp className="text-white" size={20} /> : <FaCaretDown className="text-white" size={20} />}
                                </span>
                            </div>
                        </div>
                    </ListItem>
                    <Collapse className="mx-4 hide" in={collapseShow.systemDocumentationCollapse} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem style={{ cursor: "pointer" }} alignItems="flex-start">
                                <Link
                                    style={{ borderRadius: 100 }}
                                    onClick={showConfirmationModalToggle}
                                    className={"nav-link-simple" + " font-weight-normal"}>
                                    System Training
                                </Link>
                            </ListItem>
                            {
                                props.settings.ProductIntroPack &&
                                <ListItem style={{ cursor: "pointer" }} alignItems="flex-start">
                                    <Link
                                        style={{ borderRadius: 100 }}
                                        onClick={openIntroPack}
                                        className={"nav-link-simple" + " font-weight-normal"}>
                                        {props.settings.AuctionName} Introduction Pack
                                    </Link>
                                </ListItem>
                            }
                        </List>
                    </Collapse>
                </List>
            </div>

            {confirmationModalToggle && <ConfirmationDialog
                open={confirmationModalToggle}
                okButtonText="Ok"
                cancelButtonText="Cancel"
                description={"This documentation is private and confidential, and you, the user, admin entity, hereby accept that the documentation cannot be distributed, shared or made available to any third party or for any purpose other than training on how to use the admin portal of the solution"}
                title={"Confidentiality Acceptance"}
                onCancel={closeConfirmationModal}
                onOk={openTrainingDocuments}
            />}
        </>
    );
};

const mapStateToProps = (state) => ({
    sidebarUserbox: state.ThemeOptions.sidebarUserbox,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
