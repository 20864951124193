import React, { useState } from "react";
import useHistory from '@/hooks/useHistory';
import { connect } from "react-redux";
import {
    Typography,
    Badge,
    Menu,
    Button,
    List,
    ListItem,
    Divider,
    Avatar,
    CircularProgress,
} from "@mui/material";

import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage';
import { showProgressSpinner } from "../../../helpers/ProgressSpinnerService";

const HeaderUserbox = (props) => {

    const {
        settings,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateLogout = () => {
        PrembidLocalStorage.clearCurrentUser();
        let path = "/login";
        history.push(path);
    };
    const navigateProfile = () => {
        handleClose();
        let path = `/Account`;
        if (history.location.pathname !== path) {
            history.push(path);
            showProgressSpinner();
        }
    };

    const getAvatarInitial = () => {
        if (PrembidLocalStorage.currentUser?.email)
            return PrembidLocalStorage.currentUser?.email[0]
    };

    const openVendorWebsite = () => {
        window.open("https://" + settings.ClientWebPortalBaseUrl);
    }

    return (
        <>
            {PrembidLocalStorage.currentUser === undefined ? (
                <CircularProgress size={20} thickness={2} color="primary" />
            ) : (
                    <>
                        <h6 className={"pt-2"} style={{ background: "#ffffff" }}>
                            Go to
                            <a onClick={openVendorWebsite} className={"font-weight-normal ml-1"} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{settings.ClientWebPortalBaseUrl}</a>
                        </h6>

                    <Button
                        variant="text"
                        onClick={handleClick}
                        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
                        disableRipple>
                        <div className="d-block p-0 avatar-icon-wrapper">

                            <div className="rounded">
                                <Avatar className="text-white bg-primary">{getAvatarInitial()}</Avatar>
                            </div>

                        </div>
                    </Button>
                </>
            )}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                //getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                classes={{ list: "p-0" }}
                onClose={handleClose}
            >
                <div className="dropdown-menu-lg overflow-hidden p-0">
                    <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                        <Typography className="text-capitalize pl-1">
                            <span>Profile Options</span>
                        </Typography>
                        <div className="font-size-xs pr-1"></div>
                    </div>
                    <List
                        component="div"
                        className="nav-neutral-primary text-left d-block px-3 pb-3"
                    >
                        <ListItem
                            button
                            className="d-block text-left"
                            onClick={navigateProfile}
                        >
                            My Account
                        </ListItem>
                    </List>
                    <Divider className="w-100" />
                    <List
                        component="div"
                        className="nav-neutral-primary text-left d-block px-3 pb-3"
                    >
                        <ListItem
                            button
                            className="d-block text-left"
                            onClick={navigateLogout}
                        >
                            Log Out
                        </ListItem>
                    </List>
                </div>
            </Menu>
        </>
    );
};

const mapStateToProps = (state) => ({
    loginSuccess: state.loginForm.success,
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
