import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const CLEAR_BIDDING_CALCULATION_STATE = 'CLEAR_BIDDING_CALCULATION_STATE';
const FETCH_BIDDING_CALCULATION_LOOKUP = 'FETCH_BIDDING_CALCULATION_LOOKUP';

export interface BiddingCalculationState {
    data: any;
    biddingCalculationsLookup: any[];
}

const initialState: BiddingCalculationState = {
    data: undefined,
    biddingCalculationsLookup: []
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case CLEAR_BIDDING_CALCULATION_STATE: return { ...initialState };
        case FETCH_BIDDING_CALCULATION_LOOKUP: return { ...state, biddingCalculationsLookup: action.payload }
        default: return state;
    }
}

export const biddingCalculationsFetchLookup = (payload: any) => {
    return { type: FETCH_BIDDING_CALCULATION_LOOKUP, payload }
}

export const handleFetchBiddingCalculation = (id: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveBiddingCalculation, { id }, null)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleFetchBiddingCalculationsLookup = (onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient
            .get(apiPaths.vendor.retrieveBiddingCalculationLookup, null, null)
            .then(function (res) {
                dispatch(biddingCalculationsFetchLookup(res.response))
                onCompletedCallback(res)
            })
    }
}

export const handleFetchBiddingCalculationsWithPagination = (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.vendor.retrieveAllBiddingCalculation, {
                pagination,
                ...(filters || {})
            })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleAddBiddingCalculation = (payload: any, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient
            .post(apiPaths.vendor.addVendorBiddingCalculation, payload)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleUpdateBiddingCalculation = (id: string, payload: any, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.vendor.updateVendorBiddingCalculation, payload, { id })
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleDeleteBiddingCalculation = (id: string, onCompletedCallback: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.delete(apiPaths.vendor.deleteVendorBiddingCalculation, null, { id })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}