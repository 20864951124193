import React from 'react';
import { default as ImageComponent } from 'mui-image';

export type ImageProps = {
    src: string;
    width?: number;
    height?: number;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

function Image({
    src,
    width,
    height,
    imageStyle,
    style
}: ImageProps) {
    return <div style={{ width: '100%' }}>
        <ImageComponent
            src={src}
            imageStyle={{
                width: width ?? '100%',
                height: height ?? '100%',
                objectFit: 'scale-down',
                ...(imageStyle ?? {})
            }}
            style={{
                padding: 10,
                ...(style ?? {})
            }}
        />
    </div>;
}

export default Image;