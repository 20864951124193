import React, { useEffect } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { clearUser, handleUserAdd, handleUserUpdate, handleUserFetch } from './store/Users';
import {
    Card,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Tooltip,
    FormControl,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import ns from '@/helpers/NotificationService';
import PasswordConfirmationDialog from '@/components/Modal/PasswordConfirmationDialog';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { isValidEmail } from '../../helpers/Utils';
import FloatyButtonGroup from '../../components/FloatyButtonGroup/FloatyButtonGroup';
import { FaBackspace, FaSave } from 'react-icons/fa';
import { IdentityRoleDescriptions, IdentityRoles } from '../../dto/User';
import { useAdminCompanyBiddingProfileUpsert } from "prembid-shared-library-npm/networking";

interface Props {
    clearUser_d: () => void,
    handleUserAdd_d: (userInfo: any, onCompletedCallback: (res: any) => void) => void,
    handleUserUpdate_d: (userInfo: any, onCompletedCallback: (res: any) => void) => void,
    handleUserFetch_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    user: any,
    loading: boolean
}

const intialErrorValues: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    roles: undefined
}

const UserEditAdd: React.FC<Props> = props => {
    const {
        clearUser_d,
        handleUserAdd_d,
        handleUserUpdate_d,
        handleUserFetch_d,
        user,
        loading
    } = props;

    const intialValues: {
        id: string,
        isActive: boolean,
        firstName: string,
        lastName: string,
        email: string,
        phoneNumber: string,
        roles: string[],
        originalEmail: string,
    } = {
        id: '',
        isActive: true,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        roles: [],
        originalEmail: ''
    }

    const history = useHistory();
    const [formFields, setFormFields] = React.useState(intialValues);
    const [formErrors, setFormErrors] = React.useState(intialErrorValues);
    const [passwordModalOpen, setPasswordModalOpen] = React.useState<boolean>(false);
    const [canEdit, setCanEdit] = React.useState<boolean>(true);

    const { mutate: adminCompanyBiddingProfileUpsert } = useAdminCompanyBiddingProfileUpsert();

    useEffect(() => {
        return (() => {
            clearUser_d();
        });
    }, [])

    React.useEffect(() => {
        if (location) {
            const userId = new URLSearchParams(history.location.search).get("userId")

            if (userId && userId != '') {
                showProgressSpinner({ description: "Loading user..." });
                handleUserFetch_d(userId, (res: any) => {
                    if (!res.success) {
                        ns.error(res.error);
                        history.goBack();
                    }
                });
            }
        }
    }, [location]);

    React.useEffect(() => {
        if (user !== undefined) {

            setFormFields({
                id: user.id || '',
                isActive: user.isActive,
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                phoneNumber: user.phoneNumber || '',
                roles: user.roles || [],
                originalEmail: user.email
            })

            if (user.emailConfirmed)
                setCanEdit(false);
        }
        hideProgressSpinner();
    }, [user])

    const validate = (values) => {
        let valid = true;
        let errors = {
            ...intialErrorValues
        };

        if (!values.firstName) {
            errors.firstName = "First Name field cannot be blank";
            valid = false;
        } else if (values.firstName.length < 2) {
            errors.firstName = "First Name must be more than 4 characters";
            valid = false;
        }
        if (!values.lastName) {
            errors.lastName = "Last Name field cannot be blank";
            valid = false;
        } else if (values.lastName.length < 2) {
            errors.lastName = "Last Name must be more than 4 characters";
            valid = false;
        }
        if (!values.email) {
            errors.email = "Email field cannot be blank";
            valid = false;
        } else if (!isValidEmail(values.email.replace(" ", ""))) {
            errors.email = "Please enter a valid email address";
            valid = false;
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = "Mobile Number field cannot be blank";
            valid = false;
        } else if (values.phoneNumber.length < 4) {
            errors.phoneNumber = "Mobile Number must be more than 4 characters";
            valid = false;
        }
        if (!values.roles || values.roles.filter(x => x !== IdentityRoles.AdminPortalBiddingUser).length === 0) {
            errors.roles = "At least one user role is required";
            valid = false;
        }
        setFormErrors(errors);

        return valid;
    }

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.checked })
    }

    const onCancel = () => {
        history.push("/Users");
    }

    const submit = (dataToSubmit: any) => {
        console.log("dataToSubmit", dataToSubmit)
        if (dataToSubmit) {
            //Replace any spaces due to certain devices allowing space entry
            if (dataToSubmit.email) dataToSubmit.email = dataToSubmit.email.replace(/\s/g, "").replace(" ", "");
            if (user !== undefined) {
                showProgressSpinner({ description: "Updating user..." });
                handleUserUpdate_d(dataToSubmit, (res: any) => {
                    if (res.success) {
                        setFormFields(existingValues => ({ ...existingValues, originalEmail: existingValues.email }))
                        if (dataToSubmit?.roles.includes(IdentityRoles.AdminPortalBiddingUser)) {
                            showProgressSpinner({ description: "Updating profile..." });
                            const companyProfileData = {
                                identityUserId: dataToSubmit?.id,
                                contactPersonFirstName: dataToSubmit?.firstName,
                                contactPersonLastName: dataToSubmit?.lastName,
                                phoneNumber: dataToSubmit?.phoneNumber,
                                email: dataToSubmit?.email,
                            };

                            adminCompanyBiddingProfileUpsert(companyProfileData);
                        }

                        ns.success("User successfully updated");
                        navAddEdit(res.response);
                    }
                    else {
                        ns.error(res.error);
                    }

                    hideProgressSpinner();
                });
            }
            else {
                showProgressSpinner({ description: "Adding user..." });
                handleUserAdd_d(dataToSubmit, (res: any) => {
                    if (res.success) {
                        if (dataToSubmit?.roles.includes(IdentityRoles.AdminPortalBiddingUser)) {
                            showProgressSpinner({ description: "Creating profile..." });
                            const companyProfileData = {
                                identityUserId: res.response,
                                contactPersonFirstName: dataToSubmit?.firstName,
                                contactPersonLastName: dataToSubmit?.lastName,
                                phoneNumber: dataToSubmit?.phoneNumber,
                                email: dataToSubmit?.email,
                            };

                            adminCompanyBiddingProfileUpsert(companyProfileData);
                        }

                        ns.success("User successfully added");
                        navAddEdit(res.response);
                        showProgressSpinner({ description: "Loading user..." });
                        handleUserFetch_d(res.response, (res: any) => {
                            if (!res.success) {
                                ns.error(res.error);
                                history.goBack();
                            }
                        });
                    }
                    else {
                        ns.error(res.error);
                    }

                    hideProgressSpinner();
                });
            }
        }
    }

    const navAddEdit = (userId) => {
        let path = '/UserEdit';

        if (userId !== '')
            path += '?userId=' + userId;

        history.replace(path);
    }

    const onPasswordModalClosed = () => {
        setPasswordModalOpen(false);
    }

    const onPasswordModalOk = (password: string) => {
        setPasswordModalOpen(false);
        submit({ ...formFields, authorisePassword: password });
    }

    const onSave = () => {
        let isValid = validate(formFields);

        if (isValid) {
            setPasswordModalOpen(true);
        }
        else {
            hideProgressSpinner();
            ns.error("Please fill in all required fields");
        }
    }

    const onUserRoleChanged = (option: any, checked: boolean) => {
        let tempRoles = [...formFields.roles];

        if (checked) {
            if (tempRoles.indexOf(option) === -1) tempRoles.push(option);
        }
        else {
            let index = tempRoles.indexOf(option);
            if (index > -1) tempRoles.splice(index, 1);
        }

        setFormFields({ ...formFields, roles: tempRoles });
        setFormErrors({ ...formErrors, roles: undefined })
    }

    const getIdentityRoleDescription = (option: IdentityRoles) => {
        if (option === IdentityRoles.AdminPortalSuperUser) return IdentityRoleDescriptions.AdminPortalSuperUser;
        if (option === IdentityRoles.AdminPortalUser) return IdentityRoleDescriptions.AdminPortalUser;
        if (option === IdentityRoles.AdminPortalBiddingUser) return IdentityRoleDescriptions.AdminPortalBiddingUser;
    }

    return (<>
        {!loading && (<>
            <PasswordConfirmationDialog open={passwordModalOpen} okButtonText="Ok" cancelButtonText="Cancel" description="Please confirm your password to update the user." title="Confirm Password" onCancel={onPasswordModalClosed} onOk={onPasswordModalOk} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header dpy-3">
                            <div className="card-header--title font-size-lg">
                                User Information
                            </div>
                        </div>
                        <div className="p-3">
                            <TextField
                                autoFocus
                                value={formFields.firstName}
                                onChange={onChange}
                                id="firstName"
                                fullWidth
                                className="m-2"
                                label="First Name"
                                onBlur={() => setFormErrors({ ...formErrors, firstName: '' })}
                                error={formErrors.firstName === '' ? false : true}
                                helperText={formErrors.firstName}
                                required />

                            <TextField
                                value={formFields.lastName}
                                onChange={onChange}
                                id="lastName"
                                fullWidth
                                className="m-2"
                                label="Last Name"
                                onBlur={() => setFormErrors({ ...formErrors, lastName: '' })}
                                error={formErrors.lastName === '' ? false : true}
                                helperText={formErrors.lastName}
                                required />

                            <TextField
                                value={formFields.email}
                                onChange={onChange}
                                id="email"
                                fullWidth
                                className="m-2"
                                label="Email Address"
                                onBlur={() => setFormErrors({ ...formErrors, email: '' })}
                                error={formErrors.email === '' ? false : true}
                                helperText={formErrors.email}
                                required
                                onKeyDown={(e) =>
                                    (e.key === " ") && e.preventDefault()
                                }
                                disabled={!canEdit} />

                            <TextField
                                id="phoneNumber"
                                fullWidth
                                className="m-2"
                                label="Phone Number"
                                type="tel"
                                value={formFields.phoneNumber}
                                onChange={onChange}
                                onKeyDown={(e) =>
                                    (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
                                }
                                required
                                onBlur={() => setFormErrors({ ...formErrors, phoneNumber: '' })}
                                error={formErrors.phoneNumber === '' ? false : true}
                                helperText={formErrors.phoneNumber}
                            />

                            <FormControl
                                variant='outlined'
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formFields.isActive}
                                            onChange={onChangeCheckbox}
                                            id="isActive"
                                            className="m-2 p-2 switch-medium toggle-switch-success"
                                        />}
                                    labelPlacement="start"
                                    label='Is Active'
                                />
                            </FormControl>
                        </div>
                    </Card>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header dpy-3">
                            <div className={"card-header--title font-size-lg "} style={{ color: formErrors.roles ? "#f83245" : "unset" }}>
                                User Access
                            </div>
                        </div>
                        <div className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <List>
                                        {Object.keys(IdentityRoles).filter(x => x.includes('Admin') && x !== IdentityRoles.AdminPortalBiddingUser)
                                            .map((option) => <>
                                                <ListItem sx={{ p: 2 }}>
                                                    <ListItemText
                                                        primaryTypographyProps={{ variant: 'body1' }}
                                                        secondaryTypographyProps={{ variant: 'subtitle2' }}
                                                        primary={option}
                                                        secondary={getIdentityRoleDescription(option as IdentityRoles)}
                                                    />

                                                    <Switch
                                                        checked={formFields.roles.indexOf(option) > -1}
                                                        onChange={e => onUserRoleChanged(option, e.target.checked)}
                                                        id="roles"
                                                        className="m-2 p-2 switch-medium toggle-switch-success"
                                                    />

                                                </ListItem>
                                                <Divider className="p-0 mt-3" />
                                            </>)}
                                    </List>
                                    {formErrors.roles && <div className="text-danger pt-2">{formErrors.roles}</div>}
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header dpy-3">
                            <div className="card-header--title font-size-lg">
                                User Settings
                            </div>
                        </div>
                        <div className="p-4">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <List>
                                        <ListItem sx={{ p: 2 }}>
                                            <ListItemText
                                                primaryTypographyProps={{ variant: 'body1' }}
                                                secondaryTypographyProps={{ variant: 'subtitle2' }}
                                                primary={"Allow Bidding"}
                                                secondary={getIdentityRoleDescription(IdentityRoles.AdminPortalBiddingUser)}
                                            />

                                            <Switch
                                                checked={formFields.roles.indexOf(IdentityRoles.AdminPortalBiddingUser) > -1}
                                                onChange={e => onUserRoleChanged(IdentityRoles.AdminPortalBiddingUser, e.target.checked)}
                                                id="roles"
                                                className="m-2 p-2 switch-medium toggle-switch-success"
                                            />

                                        </ListItem>
                                        <Divider className="p-0 mt-3" />
                                    </List>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <FloatyButtonGroup>
                <Tooltip arrow title="Save" placement="left">
                    <Button size="large" variant="contained" onClick={() => onSave()}
                        className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                    </Button>
                </Tooltip>
                <Tooltip arrow title="Back" placement="left">
                    <Button size="large" variant="contained" onClick={() => onCancel()}
                        className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaBackspace />
                        </span>
                    </Button>
                </Tooltip>
            </FloatyButtonGroup>
        </>)}
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearUser_d: () => dispatch(clearUser()),
        handleUserAdd_d: (userInfo: any, onCompletedCallback: (res: any) => void) => dispatch(handleUserAdd(userInfo, onCompletedCallback)),
        handleUserUpdate_d: (userInfo: any, onCompletedCallback: (res: any) => void) => dispatch(handleUserUpdate(userInfo, onCompletedCallback)),
        handleUserFetch_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleUserFetch(id, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
    user: state.users.user,
    userId: state.users.userId,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEditAdd);