import React, { FC, useMemo, useReducer, useState } from "react";
import SimpleTable, { ColumnProps } from "@/components/SimpleTable/SimpleTable";
import { Box, Tooltip } from "@mui/material";
import { FaDownload } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { formatDateTime } from "../../../helpers/Utils";
import { FileHistoryItem } from "../../../dto/BulkUploader";
import ns from '@/helpers/NotificationService';
import { connect } from "react-redux";
import { downloadFile } from "../../Documents/store/Documents";

interface BulkUploaderFileUploadTableProps {
    fileHistoryData: FileHistoryItem[],
    handleDownloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
    isTableLoading: boolean
}

const BulkUploaderFileUploadTable: FC<BulkUploaderFileUploadTableProps> = props => {
    const { fileHistoryData, handleDownloadFile_d, isTableLoading } = props;

    const [loadingIds, updateLoadingId] = useReducer((state: string[], action: { type: 'add' | 'remove' | 'clear'; id: string; }) => {
        switch (action.type) {
            case 'add': return [...state, action.id];
            case 'remove': return state.filter(id => id !== action.id);
            case 'clear': return [];
            default: return state;
        }
    }, []);

    const columns = useMemo<ColumnProps<FileHistoryItem>[]>(() => ([
        {
            id: "fileName",
            header: 'File Name',
            accessorKey: 'fileName',
            enableSorting: true
        },
        {
            id: "createdAt",
            header: 'Upload Date',
            accessorFn: (row: FileHistoryItem) => !row?.newFile ? formatDateTime(row.createdAt, 'yyyy/MM/dd HH:mm') : 'Awaiting Upload',
            enableSorting: true
        },
        {
            id: "status",
            header: 'Status',
            accessorFn: (row: FileHistoryItem) => !row?.newFile ? (row?.index > 0 ? "Replaced by previous file" : "Currently used for Validation & Processing") : "Please SAVE to prepare for Validation & Processing",
            enableSorting: true
        },
    ]), []);

    const handleDownloadFile = (id: string) => {
        const url = fileHistoryData[id].url;
        const fileName = fileHistoryData[id].fileName;

        updateLoadingId({ type: 'add', id });
        handleDownloadFile_d(url, (response: any) => {
            if (response?.ok) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        fileName,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                });
            }
            else {
                ns.error('Failed to download the file');
            }
            updateLoadingId({ type: 'remove', id });
        })
    }

    return (
        <SimpleTable
            columnHeaders={columns}
            tableData={fileHistoryData}
            loadingIds={loadingIds}
            renderRowActions={(row, isLoading, loadingIds) => {
                const id = row.id;
                const loading = loadingIds.includes(id);
                const showDownloadButton = !fileHistoryData[row?.id]?.newFile ? true : false;

                return <>
                    <Tooltip arrow title="Download File" placement="bottom">
                        <span>
                            <LoadingButton
                                onClick={() => handleDownloadFile(id)}
                                size="small"
                                disabled={isLoading || !showDownloadButton}
                                loading={loading}
                                className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaDownload />
                            </LoadingButton>
                        </span>
                    </Tooltip>
                </>
            }}

            renderBottomTableActions={(table, isLoading) => {
                return <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }} />
            }}

            pagination={true}
            isLoading={isTableLoading}
        />
    );
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleDownloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

export default connect(null, mapDispatchToProps)(BulkUploaderFileUploadTable);