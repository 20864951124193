import React, { memo, FC } from "react";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
import { FaFilePdf } from "react-icons/fa";

interface GetAuctionCataloguePDFProps {
	catalogueRoute: () => void;
}

const GetAuctionCataloguePDF: FC<GetAuctionCataloguePDFProps> = ({
	catalogueRoute,
}) => {
	const { settings } = useSelector((state: any) => state.settings);

	return (
		<Tooltip
			title={"Download the " + settings.AuctionName + " Catalogue in PDF"}>
			<Button
				onClick={catalogueRoute}
				variant="text"
				className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize">
				<FaFilePdf />
			</Button>
		</Tooltip>
	);
};

export default memo(GetAuctionCataloguePDF);
