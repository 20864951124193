import React, { useEffect, useRef } from "react";
import { default as OriginalCountdown, CountdownProps as OriginalCountdownProps } from "react-countdown";

type CountdownProps = Omit<OriginalCountdownProps, "ref">;

const Countdown = (props: CountdownProps) => {
    const ref = useRef<OriginalCountdown>(null);

    useEffect(() => {
        if (props.date) ref.current?.start();
    }, [props.date]);

    return <OriginalCountdown ref={ref} {...props} />;
};

export default Countdown;
