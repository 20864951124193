import { FC, memo } from "react";
import useStyles from "../AuctionsOverviewFactsFigures.styles";
import { Box, Grid, Typography } from "@mui/material";
import { LotAmounts } from "../AuctionsOverviewFactsFigures.types";
import { Skeleton } from "../../library/Skeleton";

interface LotTotalsProps {
    settings: any;
    lotAmounts: LotAmounts;
    isTender: boolean;
    isLoading: boolean;
}

const LotTotals: FC<LotTotalsProps> = ({ settings, lotAmounts, isTender, isLoading }) => {

    const { classes } = useStyles();

    return (
        <>
            <Grid
                container
                classes={{ root: classes.subContainer }}>
                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.lineText }}>Number of {settings?.LotName}s</Typography>
                                <Typography>{lotAmounts?.lotsTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.successText }}>Reserve Met {settings?.LotName}s</Typography>
                                <Typography>{lotAmounts?.lotsReserveMetTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.lineText }}>{settings?.LotName}s with {isTender ? "Tenders" : "Bids"}</Typography>
                                <Typography>{lotAmounts?.lotsWithBidsTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>

                <Grid item md={12}>
                    <Box className={classes.gridRow}>
                        {
                            !isLoading ? <>
                                <Typography variant="body2" classes={{ root: classes.dangerText }}>Reserve Not Met {settings?.LotName}s</Typography>
                                <Typography>{lotAmounts?.lotsReserveNotMetTotal}</Typography>
                            </> : <>
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                                <Skeleton animation="wave" variant="text" component="h4" width="45%" />
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(LotTotals);
