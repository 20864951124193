import React, { FC, ForwardedRef, forwardRef, memo } from "react";

interface NestedAccordionCellProps {
    cellEl?:any;
    image?:string;
    text?:string | number;
    ref?:ForwardedRef<HTMLElement>;
}

const NestedAccordionCell:FC<NestedAccordionCellProps> = forwardRef((props,ref) => {
    if (props.image) {
        return <div className="avatar-icon-wrapper avatar-icon-xl">
            <div className="avatar-icon rounded">
                <img alt="..." src={props.image} /></div></div>
    } else if (props.cellEl) {
        return props.cellEl
    }
    else if (props.text) {
        return <span ref={ref}>{props.text}</span>
    }
    else {
        return ''
    }
})

export default memo(NestedAccordionCell);