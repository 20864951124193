import React from 'react';
import { connect } from "react-redux";

interface Props {
    priceWithSymbol?: any,
    prefixString?: string,
    noWrap?: boolean,
    style?: any,
    settings: any,
    hideCurrencySymbol?: boolean,
    hidePrefixString?: boolean,
    hidePriceWithSymbol?: boolean,
    textColour?: string,
}

const CurrencyComponent: React.FC<Props> = (props) => {
    const { priceWithSymbol, prefixString, style, settings, hideCurrencySymbol = false, hidePrefixString = false, hidePriceWithSymbol = false, textColour } = props;

    const [currency] = React.useState({
        firstCurrency: settings.CurrencySymbol,
    });

    return (
        <span style={{
            whiteSpace: "nowrap",
            wordWrap: "initial",
            color: textColour ? textColour : "#000000",
            ...(style || {})
        }}>{!hidePrefixString && prefixString} {!hideCurrencySymbol && currency.firstCurrency} {!hidePriceWithSymbol && priceWithSymbol}
        </span>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});


export default connect(mapStateToProps, mapDispatchToProps)(CurrencyComponent);
