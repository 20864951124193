import { useCallback, useEffect, useState, FC, memo, useMemo, useReducer } from "react";
import { useSelector } from 'react-redux';
import { Button, Tooltip } from '@mui/material';
import { FaRedo } from "react-icons/fa";
import { zeroPad } from "react-countdown";
import { sortBy, uniq } from "lodash";

import ns from "@/helpers/NotificationService";
import { Lot, RegisteredAuctionUser, Auction, Pagination as PaginationPayload } from 'prembid-shared-library-npm/types';
import { FileExportType } from "@/helpers/FileExportTypeEnum";
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import { regUsersFetchSuccess } from '@/pages/AuctionManagement/store/AuctionManagement';

import AuctionStatusBadgeTimer from "@/components/AuctionManagement/AuctionStatusBadgeTimer";
import GetAuctionCatalogueCSV from "@/components/AuctionsOverviewGrid/sub-components/GetAuctionCatalogueCSV";
import Thumbnail from "@/components/AuctionsOverviewGrid/sub-components/Thumbnail";
import GetAuctionCataloguePDF from "@/components/AuctionsOverviewGrid/sub-components/GetAuctionCataloguePDF";
import ActionsCell from "@/components/AuctionsOverviewGrid/sub-components/ActionsCell";
import ReserveCell from "@/components/AuctionsOverviewGrid/sub-components/ReserveCell";
import { BidRow, BidTableAction, ChangedLots, CurrentLotReserves, LockedLots, LotsAskingBids, LotsBidRows, LotsExtendedBy, LotsIncrementTables } from "./AuctionsOverviewGrid.types";
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, KeyValue, CustomData } from '@/components/NestedAccordionGrid';
import {
    useRetrieveLotBidsHistory,
    useRetrieveAuctionCatalogReport,
    useRetrieveLotsOverviewDataWithPagination,
    useRetrieveAllRegisteredUsersByAuctionId,
    usePrefetchLotBidsHistory,
    useValidateRegisteredProfile,
    useFinishRegistration
} from "prembid-shared-library-npm/networking";
import BidsPopover from "./sub-components/BidsPopover";
import { makeStyles } from "tss-react/mui";
import { formatDateTime } from "../../helpers/Utils";
import { RootState, useAppDispatch } from "../../store/ConfigureStore";
import { onReceiveMessage, clearOnReceiveMessage } from 'prembid-shared-library-npm/signalr';
import { MessageSubject } from 'prembid-shared-library-npm/types';
import ReOrderButton from "./sub-components/ReOrderButton";
import { PrembidLocalStorage } from "../../helpers/PrembidLocalStorage";
import { IdentityRoles } from "../../dto/User";
import { RegisteredProfileStatusEnum } from "../../helpers/RegisteredProfileStatusEnum";

const useStyles = makeStyles()((theme) => ({

}))

interface AuctionOverviewGridProps {
    auction: Auction,
};

const sortByOptions: string[] = ["Number: Ascending", "Number: Descending", "Opening Bid: Low to High", "Opening Bid: High to Low", "Time: Remaining"];

const AuctionOverviewGrid: FC<AuctionOverviewGridProps> = ({ auction }) => {
    const { classes, cx } = useStyles();
    const { id: auctionId, auctionType } = auction;

    const dispatch = useAppDispatch();
    const { users = [] }: { users: RegisteredAuctionUser[] } = useSelector((state: any) => state.auctionManagement);

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);
    const settings = useSelector((state: RootState) => state.settings.settings);

    const [lockedLots, setLockedLots] = useState<LockedLots>({});
    const [changedLots, setChangedLots] = useState<ChangedLots>({});
    const [lotsExtendedBy, setLotsExtendedBy] = useState<LotsExtendedBy>({});
    const [lotsAskingBids, setLotsAskingBids] = useState<LotsAskingBids>({});
    const [lotsIncrementTables, setLotsIncrementTables] = useState<LotsIncrementTables>({});
    const [currentLotReserves, setCurrentLotReserves] = useState<CurrentLotReserves>({});

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [clearSearch, setClearSearch] = useState(false);
    const [pagination, setPagination] = useState<PaginationPayload>();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedLotId, setSelectedLotId] = useState('');

    const isTender = Number(auctionType) === Number(AuctionTypeEnum.Tender.toString());

    const { mutate: finishRegistration, isLoading: isFinishingRegistration } = useFinishRegistration();

    const [adminBiddingProfile, setAdminBiddingProfile] = useState<any>();
    useEffect(() => { setAdminBiddingProfile(undefined) }, [auctionId])

    const { isFetching: isValidatingRegistereProfile, invalidateRegisteredProfile } = useValidateRegisteredProfile(
        {
            auctionIdList: [auctionId],
            profileId: PrembidLocalStorage?.currentProfile,
        },
        {
            enabled: (
                auctionId !== undefined &&
                auctionId !== '' &&
                PrembidLocalStorage?.currentProfile !== undefined &&
                PrembidLocalStorage?.currentProfile !== '' &&
                PrembidLocalStorage.CurrentUserInRole(IdentityRoles.AdminPortalBiddingUser) &&
                !isTender
            ),
            cacheTime: 0,
            onSuccess(response) {
                if (response[0].status !== RegisteredProfileStatusEnum["Approved"]) {
                    finishRegistration({
                        auctionId: auctionId,
                        profileId: PrembidLocalStorage?.currentProfile,
                        profileType: PrembidLocalStorage?.currentProfileType ?? "",
                    }, {
                        onSuccess() {
                            invalidateRegisteredProfile();
                        }
                    });
                }
                else if (response[0].status === RegisteredProfileStatusEnum["Approved"]) {
                    setAdminBiddingProfile(response[0]);
                }
            }
        }
    );

    const calculateBidderName = (profile) => {
        if (!profile) return "";
        if (profile.profileType === "CompanyProfile") {
            return [
                profile.companyProfileFirstName,
                profile.companyProfileLastName,
            ].join(" ");
        } else {
            return [
                profile.individualProfileFirstName,
                profile.individualProfileLastName,
            ].join(" ");
        }
    }

    const calculateBidderContact = (profile) => {
        return profile?.companyProfileEmail ?? profile?.individualProfileEmail ?? "";
    }

    const { invalidateRegisteredUsers } = useRetrieveAllRegisteredUsersByAuctionId({ queryParams: { auctionId } }, {
        enabled: !!auctionId,
        refetchOnWindowFocus: false,
        onSuccess(response) {
            dispatch(regUsersFetchSuccess(response))
        }
    });


    const { data: lotBidHistory,
        isFetched: lotBidHistoryIsFetched,
        isLoading: isLoadingLotBidsHistory,
        isFetching: isFetchingLotBidsHistory,
        isRefetching: isRefetchingLotBidsHistory,
        invalidateLotBidsHistory
    } = useRetrieveLotBidsHistory({
        queryParams: {
            auctionId,
            lotId: selectedLotId,
        }
    }, {
        enabled: selectedLotId !== '' && !!auctionId,
        refetchOnWindowFocus: false,
    });

    const [tableRowData, setTableRowData] = useReducer(
        (state: LotsBidRows, action: any): LotsBidRows => {
            switch (action.type) {
                case BidTableAction.Clear:
                    return { ...state, [action.data.lotId]: [] };
                case BidTableAction.Replace:
                    return { ...state, [action.data.lotId]: sortBy(action.data.bidRows, ["amount"]).reverse().slice(0, 15) };
                case BidTableAction.Upsert:
                    let newState = { ...state };
                    const { amount: incomingAmount, paddleNumber: incomingPaddleNumber, profileId } =
                        action.data;
                    const { lotId, ...lotIdExcluded } = action.data;
                    const incomingAmountAsNumber = Number(incomingAmount);
                    const indexMatchedEntry = newState[lotId]?.findIndex(
                        ({ amount, paddleNumber }) =>
                            amount === incomingAmountAsNumber &&
                            paddleNumber === incomingPaddleNumber
                    );

                    if (!lotIdExcluded?.name && incomingPaddleNumber) {
                        const profile = users.find(x => x.paddleNumber === incomingPaddleNumber);

                        if (profile) {
                            lotIdExcluded.name = calculateBidderName(profile);
                            lotIdExcluded.contact = calculateBidderContact(profile);
                        }
                        else if (!isLoadingLotBidsHistory) {
                            console.log("HERE=> ", action.data)
                            invalidateLotBidsHistory();
                        }
                    }

                    if (indexMatchedEntry !== undefined && indexMatchedEntry !== -1) {
                        newState[lotId][indexMatchedEntry] = {
                            ...lotIdExcluded,
                            amount: incomingAmountAsNumber,
                        };
                    } else {
                        if (newState[lotId]) {
                            newState[lotId].push({ ...lotIdExcluded, amount: incomingAmountAsNumber });
                        } else {
                            newState[lotId] = [];
                            newState[lotId].push({ ...lotIdExcluded, amount: incomingAmountAsNumber });
                        }
                    }

                    return { ...state, [lotId]: sortBy(newState[lotId], ["amount"]).reverse().slice(0, 15) };
                case BidTableAction.RemoveBid:
                    return { ...state, [action.data.lotId]: state[action.data.lotId]?.filter(({ bidId }) => bidId !== action.data.bidId) };
                case BidTableAction.UpdateBidAmount:

                    const { lotId: lotIdToUpdate, ...lotIdToUpdateExcluded } = action.data;

                    return {
                        ...state, [lotIdToUpdate]: state[lotIdToUpdate]?.map((tableRow) => {
                            if (tableRow.bidId === action.data.bidId) {
                                return {
                                    ...tableRow,
                                    ...lotIdToUpdateExcluded,
                                    amount: Number(action.data.amount),
                                };
                            }
                            return tableRow;
                        })
                    };
                default:
                    return { ...state };
            }
        },
        {}
    );

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }

    const handlePageChange = (pagination: any) => {
        setPagination(pagination);
    }

    const { prefetchLotBidsHistory } = usePrefetchLotBidsHistory();

    const { data: { list: lots = [] } = {}, isFetching: isFetchingLots, refetch: refreshList } = useRetrieveLotsOverviewDataWithPagination({
        auctionId,
        pagination: {
            currentPage: pagination?.currentPage ?? 1,
            pageSize: pagination?.pageSize ?? 100,
        },
        ...selectedFilters,
    },
        {
            enabled: !!auctionId && !!selectedFilters,
            refetchOnWindowFocus: false,
            onSuccess({ list: lots, pagination }) {
                setPagination(pagination);

                setLotsIncrementTables(lots?.reduce((accumulator, lot) => ({
                    ...accumulator,
                    [lot.id]: {
                        incrementTableId: lot.incrementTableId,
                        incrementTableName: lot.incrementTableName,
                        incrementTableDataCaptured: lot.incrementTableDataCaptured,
                    }

                }), {}) ?? {})
            }
        });

    const lotSelectedForPopover = useMemo(() => lots.find(({ id }) => id === selectedLotId), [lots, selectedLotId]);




    useEffect(() => {
        if (!lotBidHistoryIsFetched || isFetchingLotBidsHistory || isRefetchingLotBidsHistory || !lotBidHistory) return;

        const bidRows: BidRow[] = [];

        let profileIdlist = lotBidHistory.map((bid) => bid.registeredProfileId);

        const uniqueProfileIds = uniq(profileIdlist);

        let paddleNotFound = false;

        if (users) {
            const profiles = users.filter((user) =>
                uniqueProfileIds.includes(user.id)
            );
            const containsBidsForLotId = lotBidHistory.filter(
                (b) => b.lotId === selectedLotId
            )[0];

            if (containsBidsForLotId) {
                lotBidHistory.forEach((bid) => {
                    const profile = profiles.filter(
                        (p) => p.id === bid.registeredProfileId
                    )[0];
                    let paddleNumber = "Loading...";

                    if (profile?.paddleNumber) {
                        paddleNumber = profile.paddleNumber
                    } else paddleNotFound = true;

                    const createdDateTime = new Date(bid.createdAt);
                    const dateString =
                        createdDateTime.getFullYear() +
                        "-" +
                        zeroPad(createdDateTime.getMonth() + 1) +
                        "-" +
                        zeroPad(createdDateTime.getDate());
                    const timeString =
                        zeroPad(createdDateTime.getHours()) +
                        ":" +
                        zeroPad(createdDateTime.getMinutes()) +
                        ":" +
                        zeroPad(createdDateTime.getSeconds());

                    let bidderName = calculateBidderName(profile);
                    let bidderEmail = calculateBidderContact(profile);

                    bidRows.push({
                        bidId: bid.id,
                        name: bidderName,
                        contact: bidderEmail,
                        paddleNumber: paddleNumber,
                        date: formatDateTime(dateString, "yyyy/MM/dd"),
                        time: timeString,
                        amount: Number(bid.amount),
                        auctionId: bid.auctionId,
                        maxBidAmount: bid.maxBidAmount,
                        maxBidCreatedAt: bid.maxBidCreatedAt,
                        type: bid.type
                    });
                });
            }
        }

        if (paddleNotFound) {
            invalidateRegisteredUsers()
        }
        else {
            setTableRowData({
                type: BidTableAction.Replace,
                data: { bidRows, lotId: selectedLotId },
            });
        }
    }, [lotBidHistoryIsFetched, lotBidHistory, users, selectedLotId, isFetchingLotBidsHistory, isRefetchingLotBidsHistory]);


    const { isFetching: isFetchingAuctionCatalogReport, refetch: retrieveAuctionCatalogReport } = useRetrieveAuctionCatalogReport({
        pathParams: { auctionId, exportType: FileExportType.CSV }
    }, {
        enabled: false,
        onSuccess(response) {
            const binaryString = window.atob(response.data);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: response.contentType });
            const fileURL = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute(
                'download',
                response.filename,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    });

    const lotIsLockedNotificationMessage = useCallback((lotName?: string) => {
        return `${isTender ? "Tendering" : "Bidding"} currently locked${lotName ? ` on ${lotName}` : ''}.`;
    }, [isTender]);

    const lotIsUnlockedNotificationMessage = useCallback((lotName?: string) => {
        return `${isTender ? "Tendering" : "Bidding"}${lotName ? ` on ${lotName}` : ''} is now unlocked.`;
    }, [isTender]);

    const loadBiddingData = useCallback((lotId: string, startingPrice?: number, increment?: number) => {
        prefetchLotBidsHistory({ queryParams: { auctionId, lotId } });

        if (startingPrice && increment) {
            if (startingPrice > 0) {
                setLotsAskingBids(prev => ({ ...prev, [lotId]: Number(startingPrice) }));
            } else {
                setLotsAskingBids(prev => ({ ...prev, [lotId]: Number(increment) }));
            }
        }
    }, [auctionId, prefetchLotBidsHistory])

    const catalogueRoute = useCallback(() => {
        if (auction) {
            const win: any = window.open('/CataloguePrint/' + auction.id, "_blank");
            win.focus();
        }
    }, [auction])

    const handleFiltersChange = useCallback((filters: any) => {
        setSelectedFilters(filters);
    }, [])

    const headerActionbuttons = useMemo(() => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList()} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>,
            <GetAuctionCataloguePDF catalogueRoute={catalogueRoute} />,
            <GetAuctionCatalogueCSV handleRetrieveAuctionCatalogReport={retrieveAuctionCatalogReport} isLoading={isFetchingAuctionCatalogReport} />
        ]
    }, [catalogueRoute, cx, isFetchingAuctionCatalogReport, refreshList, retrieveAuctionCatalogReport])

    const tagUnlockedNoLiveNotifications = useCallback((lotId: string, lotName: string) => {
        setLockedLots(prev => ({ ...prev, [lotId]: false }));
    }, [lotIsUnlockedNotificationMessage]);

    const tagLockedNoLiveNotifications = useCallback((lotId: string, lotName: string) => {
        setLockedLots(prev => ({ ...prev, [lotId]: true }));
    }, [lotIsLockedNotificationMessage]);

    const handleClosePopover = useCallback(() => {
        setAnchorEl(null);
        setSelectedLotId('');
    }, []);

    const handleClickPopover = useCallback((event, { defaultMinimumIncrement: increment, id: lotId, startingPrice }: Lot) => {
        const element = event.currentTarget;
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        loadBiddingData(lotId, startingPrice, increment);

        setTimeout(() => {
            setSelectedLotId(lotId);
            setAnchorEl(element);
        }, 200);
    }, [loadBiddingData]);

    useEffect(() => {
        setPagination(undefined);
        setSelectedFilters({ searchQuery: "", lotSearchQuery: "", sortByQuery: sortByOptions[sortByOptions.indexOf((settings.DefaultLotOrdering && settings.DefaultLotOrdering.length > 0) ? settings.DefaultLotOrdering : "Number: Ascending")] });
        setClearSearch(true);
        setTimeout(() => setClearSearch(false), 100);
    }, [auctionId])

    useEffect(() => {
        const callbackId = onReceiveMessage((message: string) => {
            if (message) {
                var payload = JSON.parse(message);

                if (payload?.data) {
                    const {
                        messageSubject,
                        extendedBy,
                        entityId, // lotId - lock/release of this lot has taken place
                        lotId: notificationLotId,
                        auctionId: notificationAuctionId,
                        messageId,
                        amount,
                        topBidder: notificationTopBidder,
                        message: notificationMessage,
                        bidAmount, // bid amount updated for specific lot,
                        deleted, // bid deleted from specific lot
                        bidId,
                        reservePrice,
                        maxBidCreatedAt = null,
                        maxBidAmount = null,
                        type,
                    } = payload.data;

                    const isNotificationThisAuction = notificationAuctionId === auctionId;
                    const isExtendedBy = extendedBy !== "" && extendedBy !== undefined;

                    try {
                        if (messageSubject === MessageSubject.BidUpdated) {
                            if (deleted) {
                                setTableRowData({
                                    type: BidTableAction.RemoveBid,
                                    data: {
                                        lotId: notificationLotId,
                                        bidId,
                                    },
                                });
                            }

                            if (bidAmount) {
                                setTableRowData({
                                    type: BidTableAction.UpdateBidAmount,
                                    data: {
                                        lotId: notificationLotId,
                                        bidId,
                                        amount: bidAmount,
                                    },
                                });
                            }
                        }

                        if (messageSubject === MessageSubject.CreateBid) {
                            setChangedLots(prev => ({ ...prev, [notificationLotId]: true }));
                            setTimeout(() => {
                                setChangedLots(prev => ({ ...prev, [notificationLotId]: false }));
                            }, 1000);
                        }

                        if (isNotificationThisAuction) {
                            if (messageSubject === MessageSubject.CreateBid) {
                                if (amount || notificationTopBidder) {
                                    setTableRowData({
                                        type: BidTableAction.Upsert,
                                        data: {
                                            lotId: notificationLotId,
                                            bidId,
                                            amount: amount,
                                            paddleNumber: notificationTopBidder,
                                            date: formatDateTime(new Date(), "yyyy/MM/dd"),
                                            time: formatDateTime(new Date(), "HH:mm:ss"),
                                            maxBidAmount: maxBidAmount && maxBidAmount !== '' ? maxBidAmount : null,
                                            maxBidCreatedAt: maxBidCreatedAt && maxBidCreatedAt !== '' ? maxBidCreatedAt : null,
                                            type: type,
                                        },
                                    });
                                }

                                if (isExtendedBy) {
                                    setLotsExtendedBy(prev => ({ ...prev, [notificationLotId]: extendedBy }));
                                }
                            }

                            if (messageSubject === MessageSubject.EntityLocked) {
                                const isLocked = true;
                                ns.information(lotIsLockedNotificationMessage(lots.find(lot => lot.id === entityId)?.name));
                                setLockedLots(prev => ({ ...prev, [entityId]: isLocked }));
                            }

                            if (messageSubject === MessageSubject.EntityReleased) {
                                const isLocked = false;
                                ns.information(lotIsUnlockedNotificationMessage(lots.find(lot => lot.id === entityId)?.name));
                                setLockedLots(prev => ({ ...prev, [entityId]: isLocked }));
                            }

                            if (messageSubject === MessageSubject.ManuallyExtendedLot) {
                                setLotsExtendedBy(prev => ({ ...prev, [notificationLotId]: extendedBy }));
                            }
                        }
                    } catch (e) { }
                }
            }
        });

        return () => clearOnReceiveMessage(callbackId);
    }, [auctionId, lotIsLockedNotificationMessage, lotIsUnlockedNotificationMessage, lots]);

    const handleExtendLotOptimisticUpdate = useCallback((lotId: string, extendedBy: number) => {
        setLotsExtendedBy(prev => ({ ...prev, [lotId]: extendedBy }));
    }, []); 

    const lotsDisplayModel: NestedGridDataModel = useMemo(() => {
        let rows: Row[] = [];
        let headers: Header[] = [];
        let lotIdTopics: string[] = [];
        let cells: Cell[];

        if (auction) {
            headers = [
                { title: "Number", align: "inherit" },
                { title: "Image", align: "inherit", canSort: false },
                { title: "Name", align: "inherit" },
                { title: Number(auction.auctionType) === Number(AuctionTypeEnum.Tender.toString()) ? "Top Tender/Time Remaining" : "Top Bid/Time Remaining", align: "inherit", canSort: false },
                { title: "Status", align: "inherit", canFilter: true, canSort: false },
                { title: "Reserve Amount/Met", align: "center", canSort: false },
            ];

            lots?.forEach((lot) => {
                const custom: KeyValue[] = [];

                lot?.dataCaptured?.additionalProperties?.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        custom.push({ ...x, key: x.name });
                });

                let lotName = lot.name?.substring(0, 35);
                if (lot.name.length > 35) lotName += "...";

                const profile = users?.find(x => lot.paddleNumber && x.paddleNumber === lot.paddleNumber);
                const winningProfile = profile ? {
                    name: calculateBidderName(profile),
                    paddleNumber: profile.paddleNumber
                } : undefined;

                cells = [
                    { text: Number.parseInt(lot.number), align: "inherit" },
                    { text: "", element: <Thumbnail imageUrl={lot.thumbnailUrl} />, align: "inherit" },
                    { text: lotName, align: "inherit" },
                    {
                        text: "", element: (
                            <ActionsCell
                                selectedAuction={auction}
                                lot={lot}
                                isTender={isTender}
                                lotBidsLoading={isLoadingLotBidsHistory}
                                bidRows={tableRowData[lot.id] ?? []}
                                hasChanges={changedLots[lot.id] ?? false}
                                extendedBy={lotsExtendedBy[lot.id] ?? lot.extendedBy}
                                handleClickPopover={handleClickPopover}
                                buttonSelected={lot.id === lotSelectedForPopover?.id}
                                winningProfile={winningProfile}
                                auctionType={auctionType}
                            />
                        ), align: "inherit"
                    },
                    {
                        text: "", element: <AuctionStatusBadgeTimer
                            lotId={lot.id}
                            isActive={lot.isActive}
                            startDateTimeAt={lot.startDateTimeAt}
                            endDateTimeAt={lot.endDateTimeAt}
                            extendedBy={lotsExtendedBy[lot.id] ?? lot.extendedBy}
                            dateTimeOffset={currentDateTimeOffset}
                        />, align: "inherit"
                    },
                    {
                        text: "", element: (
                            <ReserveCell
                                bidRows={tableRowData[lot.id]}
                                currentReserve={currentLotReserves[lot.id] ?? lot.reservePrice ?? 0}
                                topBid={(lot?.overrideWinningBidAmount ?? lot?.winningRegisteredProfileAmount) as number}
                            />
                        ), align: "inherit"
                    },
                ];

                const customData: CustomData[] = [
                    {
                        title: "Custom Data", values: custom
                    }
                ];

                rows.push({
                    id: lot.id,
                    cells: cells,
                    customData: customData
                });

                lotIdTopics.push(lot.id);
            });
        }

        return {
            headers,
            rows,
            headerButtons: headerActionbuttons,
        };
    }, [users,
        auction,
        changedLots,
        currentDateTimeOffset,
        currentLotReserves,
        handleClickPopover,
        headerActionbuttons,
        isLoadingLotBidsHistory,
        isTender,
        lotSelectedForPopover?.id,
        lots,
        lotsExtendedBy,
        tableRowData
    ]);


    return (
        <>
            <NestedAccordionGrid
                selectedFilters={selectedFilters}
                dataModel={{ ...lotsDisplayModel, rows: lotsDisplayModel.rows }}
                pagination={pagination}
                onFiltersChange={handleFiltersChange}
                pageSizeIsFixed={false}
                loading={isFetchingLots || isValidatingRegistereProfile}
                lotSorting={true}
                lotNoSearch={true}
                clearSearch={clearSearch}
                onHandleChangePage={handlePageChange}
                showTopDivider={false}
                rowsPerPageOptions={[50, 100, 150, 200, 250]}
            />

            {lotSelectedForPopover && anchorEl && <BidsPopover
                auctionId={auctionId}
                auctionType={auctionType}
                anchorEl={anchorEl}
                refreshList={() => refreshList()}
                handleClosePopover={handleClosePopover}
                bidRows={tableRowData[selectedLotId] ?? []}
                loadBiddingData={loadBiddingData}
                initialLotAskingBid={lotsAskingBids[selectedLotId] ?? 0}
                lot={lotSelectedForPopover}
                incrementTable={lotsIncrementTables[selectedLotId] ?? []}
                isLocked={lockedLots[selectedLotId] ?? lotSelectedForPopover.locked ?? false}

                tagUnlockedNoLiveNotifications={tagUnlockedNoLiveNotifications}
                tagLockedNoLiveNotifications={tagLockedNoLiveNotifications}
                setLockedLots={setLockedLots}
                setTableRowData={setTableRowData}
                setLotsIncrementTables={setLotsIncrementTables}
                setLotsAskingBids={setLotsAskingBids}
                setCurrentLotReserves={setCurrentLotReserves}

                lotBidsLoading={isLoadingLotBidsHistory}
                lotBidsFetching={isFetchingLotBidsHistory}
                isTender={isTender}
                adminBiddingProfile={adminBiddingProfile}
                onExtendSuccess={handleExtendLotOptimisticUpdate}
            />}

            {
                selectedFilters?.sortByQuery === "Time: Remaining" && !isFetchingLots &&
                <ReOrderButton onClick={() => { refreshList(); scrollToTop(); }} settings={settings} />
            }
        </>
    );
}

export default memo(AuctionOverviewGrid);

