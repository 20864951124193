import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from './store/NotificationServiceWrapper'

let displayed: any[] = [];

interface Props {
    notifications: any[]
    removeSnackbar_d: (key) => void
}

const NotificationServiceWrapper: React.FC<Props> = props => {
    const { notifications = [], removeSnackbar_d } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                className: 'bg-' + options.variant + ' btn-primary',
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    removeSnackbar_d(myKey);
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar]);

    return null;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeSnackbar_d: (key) => dispatch(removeSnackbar(key)),
    }
}

const mapStateToProps = (state: any) => ({
    notifications: state.notificationServiceWrapper.notifications
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationServiceWrapper)
