import React, { LabelHTMLAttributes, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { clear } from '@/pages/Integration/store/Integration';
import { Card, List, ListItem } from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';

import ActionList from '@/components/Integration/ActionList';
import EntityUpsertRequestList from '@/components/Integration/EntityUpsertRequestList';
import ImageUpsertRequestList from '@/components/Integration/ImageUpsertRequestList';
import WebhookRequestList from '@/components/Integration/WebhookRequestList';
import Configuration from '@/components/Integration/Configuration';
import useHistory from "../../hooks/useHistory";

interface Props {
    clear_d: () => void,
    loading: boolean,
    settings: any,
}

const Integration: React.FC<Props> = (props) => {
    const history = useHistory();

    const {
        clear_d,
        loading,
        settings,
    } = props

    const [activeTab, setActiveTab] = useState<string>('0');

    useEffect(() => {
        return (() => {
            clear_d();
        });
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        let index = params.get('index');
        if (index) setActiveTab(index + '');
    }, [history.location])

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div className="bg-white">
            <div className="card-header py-3">
                <div className="card-header--title font-size-lg">Integration</div>
            </div>
            <div className="divider" />
            <div className="p-3">
                <List className="nav-tabs nav-tabs-primary d-flex">
                    <ListItem button disableRipple selected={activeTab === '0'} onClick={() => { toggle('0'); }}>Actions</ListItem>
                    <ListItem button disableRipple selected={activeTab === '1'} onClick={() => { toggle('1'); }}>Entity Upsert Requests</ListItem>
                    <ListItem button disableRipple selected={activeTab === '2'} onClick={() => { toggle('2'); }}>Image Upsert Requests</ListItem>
                    <ListItem button disableRipple selected={activeTab === '3'} onClick={() => { toggle('3'); }}>Webhook Requests</ListItem>
                    <ListItem button disableRipple selected={activeTab === '4'} onClick={() => { toggle('4'); }}>Configuration</ListItem>
                </List>

                {activeTab === '0' && <div className={"tab-item-wrapper active"} key={0}>
                    <div className="text-centre p-3">
                        <ActionList />
                    </div>
                </div>}

                {activeTab === '1' && <div className={"tab-item-wrapper active"} key={1}>
                    <div className="text-centre p-3">
                        <EntityUpsertRequestList />
                    </div>
                </div>}

                {activeTab === '2' && <div className={"tab-item-wrapper active"} key={2}>
                    <div className="text-centre p-3">
                        <ImageUpsertRequestList />
                    </div>
                </div>}

                {activeTab === '3' && <div className={"tab-item-wrapper active"} key={3}>
                    <div className="text-centre p-3">
                        <WebhookRequestList />
                    </div>
                </div>}

                {activeTab === '4' && <div className={"tab-item-wrapper active"} key={4}>
                    <div className="text-centre p-3">
                        <Configuration />
                    </div>
                </div>}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clear_d: () => dispatch(clear()),
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(Integration);