import React, { useState } from "react";
import useHistory from '@/hooks/useHistory';
import { connect } from "react-redux";
import {
    clearError,
    handleDelete,
    handleLotTypesFetchWithPagination,
} from "./store/LotTypes";
import {
    NestedAccordionGrid,
    CustomData,
    Cell,
    Header,
    Row,
    KeyValue,
    NestedGridDataModel,
} from "@/components/NestedAccordionGrid";
import ConfirmationDialog from "@/components/Modal/ConfirmationDialog";
import ns from '@/helpers/NotificationService';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { makeStyles } from "tss-react/mui";
import { Button, Tooltip } from "@mui/material";
import { FaRedo } from "react-icons/fa";

const useStyles = makeStyles()((theme) => ({

}))


interface Props {
    clearError_d: () => void,
    handleLotTypesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => void,
    handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => void,
    error: boolean,
    errorText: string | any,
    reload: boolean,
    lotTypes: any,
    loading: boolean,
    settings: any,
    pagination: any,
}

const LotTypes: React.FC<Props> = (props) => {
    const {
        clearError_d,
        handleLotTypesFetchWithPagination_d,
        handleDelete_d,
        error,
        errorText,
        reload,
        lotTypes,
        loading,
        settings,
        pagination
    } = props;

    const { classes, cx } = useStyles();

    const history = useHistory();

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingLotTypes, setLoadingLotTypes] = useState<boolean>(true);
    const [lotTypesDisplayModel, setLotTypesDisplayModel] =
        React.useState<NestedGridDataModel>();
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: "",
    });


    React.useEffect(() => {
        showProgressSpinner({ description: `Retrieving ${settings.LotName} Types...` });
        refreshList(selectedFilters, pagination, false);
    }, [reload]);

    React.useEffect(() => {
        if (lotTypes) {
            let model: NestedGridDataModel;
            let rows: Row[] = [];
            let headers: Header[];

            headers = [
                { title: "Name", align: "inherit" },
                { title: "Description", align: "inherit" },
            ];

            lotTypes.forEach((lotType) => {
                const custom: KeyValue[] = lotType?.dataCaptured?.defaultAdditionalProperties?.map(item => {
                    return {
                        key: item.name,
                        value: item.value
                    }
                });

                let lotTypeName = lotType.name?.substring(0, 35);
                if (lotType.name.length > 35) lotTypeName += "...";

                let lotTypeDescription = lotType.description?.substring(0, 35);
                if (lotType.description.length > 35) lotTypeDescription += "...";

                const cells: Cell[] = [
                    { text: lotTypeName, align: "inherit" },
                    { text: lotTypeDescription, align: "inherit" },
                ];

                const customData: CustomData[] = [
                    {
                        title: "Custom Data",
                        values: custom,
                    },
                ];

                rows.push({
                    id: lotType.id,
                    cells: cells,
                    customData: customData,
                });
            });

            model = {
                headers: headers,
                rows: rows,
                headerButtons: headerActionbuttons()
            };

            setLotTypesDisplayModel(model);
        }

        var waitForGridRender = new Promise((r) => setTimeout(r, 500));
        waitForGridRender.then(() => {
            hideProgressSpinner();
        });
    }, [lotTypes]);

    React.useEffect(() => {
        if (error) {
            clearError_d()
            ns.error(errorText);
        }
    }, [error]);

    const headerActionbuttons = () => {
        return [
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshList(selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ]
    }

    const onDelete = (id: string) => {
        showDeleteModal(true, id);
    };

    const onEdit = (id: any) => {
        navAddEdit(id);
    };

    const navAddEdit = (lotTypeId) => {
        let path = "/LotTypeEdit";
        if (lotTypeId !== "") path += "?lotTypeId=" + lotTypeId;
        history.push(path);
    };

    //Modal
    const onDeleteModalClosed = () => {
        showDeleteModal(false, "");
    };
    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting " + settings.LotName + " Type..." });
        handleDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Lot type deleted successfully");
                refreshList();
            }
            else {
                ns.error("Failed to delete the lot type")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    };
    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    };

    const refreshList = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingLotTypes(true);
        else showProgressSpinner({ description: `Retrieving ${settings.LotName} Types...` });
        handleLotTypesFetchWithPagination_d(filters, pagination, (res) => {
            setLoadingLotTypes(false);
        });
    }

    const handlePageChange = (pagination?: any) => {
        refreshList(selectedFilters, pagination);
    }

    const handleFiltersChange = (filters?: any) => {
        setSelectedFilters(filters);
        refreshList(filters, null);
    }

    const LotTypesNavigation = () => {
        if (settings.ProductName === "PremBid") {
            return <>
                <div className="card-header py-3">
                    <div className="card-header--title font-size-lg">
                        {settings.LotName + " Types"}
                    </div>
                </div>
                <div className="table-responsive-md">
                    <NestedAccordionGrid
                        dataModel={lotTypesDisplayModel}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onAdd={() => {
                            navAddEdit("");
                        }}
                        selectedFilters={selectedFilters}
                        pagination={pagination}
                        onHandleChangePage={handlePageChange}
                        onFiltersChange={handleFiltersChange}
                        loading={loadingLotTypes}
                    />
                    {deleteModal.open && <ConfirmationDialog
                        open={deleteModal.open}
                        payload={deleteModal.id}
                        okButtonText="Ok"
                        cancelButtonText="Cancel"
                        description={"Are you sure you want to delete the " + settings.LotName + " type?"}
                        title={"Delete " + settings.LotName + " Type"}
                        onCancel={onDeleteModalClosed}
                        onOk={onDeleteModalOk}
                    />}

                </div>
            </>
        } else {
            return <></>
        }
    }

    return (
        <div>
            {!loading && (
                <LotTypesNavigation />
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        handleDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleDelete(Id, onCompletedCallback)),
        handleLotTypesFetchWithPagination_d: (filters: any, pagination: any, onCompletedCallback: (res: any) => void) => dispatch(handleLotTypesFetchWithPagination(filters, pagination, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    error: state.lotTypes.error,
    errorText: state.lotTypes.errorText,
    reload: state.lotTypes.reload,
    lotTypes: state.lotTypes.lotTypes,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    pagination: state.lotTypes.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(LotTypes);
