import React, { FC, memo, useMemo } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Pagination as PaginationPayload } from 'prembid-shared-library-npm/types';

interface PaginationProps {
    onHandleChangePage?: (pagination: PaginationPayload) => void;
    pagination: PaginationPayload;
    rowsPerPageOptions?: number[];
    userFilters?: any;
    loading?: boolean;
    pageSizeIsFixed?: boolean;
}

const Pagination: FC<PaginationProps> = (props) => {
    const { pagination, onHandleChangePage, rowsPerPageOptions = [10, 15, 20, 25, 35, 50, 100], loading = false, pageSizeIsFixed = false } = props;

    const pageSize = useMemo(() => pagination?.pageSize ?? 10, [pagination?.pageSize]);
    const currentPage = useMemo(() => pagination?.currentPage ?? 1, [pagination?.currentPage]);

    const rowsPerPageOptionsToUse = useMemo(() => pageSizeIsFixed ? [] : rowsPerPageOptions, [pageSizeIsFixed, rowsPerPageOptions])

    const hasPreviousPage = useMemo(() => currentPage !== 1, [currentPage]);

    const hasNextPage = useMemo(() => {

        let maxPage = 1;

        if (pagination?.total && pagination?.pageSize) {
            maxPage = Math.ceil(pagination.total / pagination.pageSize);
        }

        return currentPage < maxPage;

    }, [currentPage, pagination?.total, pagination?.pageSize]);

    const cannotNavigate = useMemo(() => !hasPreviousPage && !hasNextPage, [hasNextPage, hasPreviousPage]);

    const handleChangePage = (event: any, newPage: any) => {
        let newCurrentPage = newPage || 0;
        newCurrentPage++;
        if (onHandleChangePage) onHandleChangePage({ currentPage: newCurrentPage, pageSize });
    };

    const handleChangeRowsPerPage = (event: any) => {
        let newPageSize = event?.target?.value || 10;
        const firstEntry = (currentPage - 1) * pageSize + 1;
        const newCurrentPage = Math.ceil(firstEntry / newPageSize);
        if (onHandleChangePage)
            onHandleChangePage({ currentPage: newCurrentPage, pageSize: newPageSize });
    };

    return <>
        <TablePagination
            SelectProps={{
                disabled: loading
            }}
            backIconButtonProps={{
                disabled: loading,
                style: {
                    opacity: hasPreviousPage ? '1' : '0.5',
                    pointerEvents: hasPreviousPage ? 'all' : 'none',
                    display: cannotNavigate ? 'none' : 'inline'
                }
            }}
            nextIconButtonProps={{
                disabled: loading,
                style: {
                    opacity: hasNextPage ? '1' : '0.5',
                    pointerEvents: hasNextPage ? 'all' : 'none',
                    display: cannotNavigate ? 'none' : 'inline'
                }
            }}
            component='div'
            count={pagination?.total || 0}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptionsToUse}
            labelRowsPerPage={"Items per page"}
            sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                    marginTop: 0,
                    marginBottom: 0
                },
                ".MuiTablePagination-displayedRows": {
                    display: 'flex',
                    margin: 0
                }
            }}
        />
    </>
}

export default memo(Pagination);
