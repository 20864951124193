import React, { Component, ErrorInfo, ReactNode, CSSProperties, memo  } from 'react';
import { useErrorBoundaryStyles } from './ErrorBoundary.styles';
import { Box } from '@mui/material';

export interface ErrorBoundaryProps {
  children?: ReactNode;
  containerStyle?: CSSProperties;
  FallbackComponent?: React.ComponentType<{ error: Error; errorInfo: ErrorInfo }>;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    const { children, containerStyle, FallbackComponent } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError && FallbackComponent) {
      return <FallbackComponent error={error!} errorInfo={errorInfo!} />;
    }

    return <Box style={containerStyle}>{children}</Box>;
  }
}

export default memo(ErrorBoundary);