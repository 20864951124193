import React, { ReactNode, useEffect, useState } from 'react';
import MaterialReactTable, { MRT_ColumnDef, MRT_DensityState, MRT_Row, MRT_TableInstance, MRT_SortingState, MRT_ColumnFiltersState, MRT_RowSelectionState, MRT_PaginationState } from 'material-react-table';
import SearchField from '../SearchField/SearchField';
import { useSelector } from 'react-redux';
import { forEach, merge } from 'lodash';
import { Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FaRedo, FaWifi } from 'react-icons/fa';
import { ErrorOutlined } from '@mui/icons-material';

export type ColumnProps<T extends Record<string, any>> = MRT_ColumnDef<T>;
export type RowProps<T extends Record<string, any>> = MRT_Row<T>;
export type TableProps<T extends Record<string, any>> = MRT_TableInstance<T>;
export type TableType = MRT_DensityState;
export type ColumnSort = MRT_SortingState;

type SimpleTableProps<T extends Record<string, any>> = {
    columnHeaders: ColumnProps<T>[];
    tableData: T[];

    enableRowSelection?: boolean;
    isLoading?: boolean;
    isSaving?: boolean;

    onAddClick?: () => void;
    onEditClick?: (uniqueId: any) => void;
    onDeleteClick?: (uniqueIds: any[]) => void;

    renderRowActions?: (row: RowProps<T>, isLoading: boolean, loadingIds: string[]) => ReactNode;
    renderTopTableActions?: (table: TableProps<T>, isLoading: boolean) => ReactNode;
    renderBottomTableActions?: (table: TableProps<T>, isLoading: boolean) => ReactNode;

    searchPlaceholder?: string;
    onSearch?: (searchQuery: string) => void;

    onRefresh?: () => void;

    type?: TableType;

    pagination?: any;

    sorting?: ColumnSort;

    hideColumns?: string[];

    tableStyle?: React.CSSProperties;
    tableContainerStyle?: React.CSSProperties;

    muiTableProps?: any;

    searchString?: string;

    loadingIds?: string[];

    errors?: {
        [id: string]: string;
    };

    enableRowSelectionOnErrorsChange?: boolean;

    groupBy?: string[];
    expandedByDefault?: true | Record<string, boolean>;
    rowVirtualization?: boolean;
    showExpandAllButton?: boolean;

    extraButtons?: ReactNode[];
}

const defaultErrors = {};

function SimpleTable<T extends Record<string, any>>({
    columnHeaders,
    tableData,
    enableRowSelection = false,
    isLoading = false,
    isSaving = false,
    renderRowActions,
    renderTopTableActions,
    renderBottomTableActions,
    searchPlaceholder,
    onSearch,
    type,
    onAddClick,
    onEditClick,
    onDeleteClick,
    pagination,
    sorting = [],
    hideColumns = [],
    tableStyle,
    tableContainerStyle,
    muiTableProps,
    searchString,
    onRefresh,
    loadingIds = [],
    errors = defaultErrors,
    enableRowSelectionOnErrorsChange = false,
    groupBy,
    expandedByDefault,
    rowVirtualization,
    showExpandAllButton = false,
    extraButtons
}: SimpleTableProps<T>) {

    const { settings } = useSelector((state: any) => state.settings);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const handleAddClick = () => {
        if (onAddClick) {
            onAddClick();
        }
    }

    const handleEditClick = (uniqueId: any) => {
        if (onEditClick) {
            onEditClick(uniqueId);
        }
    }

    const handleDeleteClick = (uniqueIds: any[]) => {
        if (onDeleteClick) {
            onDeleteClick(uniqueIds);
        }
    }

    const handleSearchKeyPress = (e) => {
        if (e.key === "Enter" && !!onSearch) onSearch(searchQuery);
    }

    const [showColumnFilters, setShowColumnFilters] = useState<boolean>(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const filtersEnabled = columnHeaders?.some(x => x.enableColumnFilter);

    useEffect(() => setColumnFilters([]), [columnHeaders]);
    useEffect(() => {
        if (enableRowSelectionOnErrorsChange) {
            var newSelection: any = {};

            forEach(Object.keys(errors), (key) => {
                newSelection[key] = true;
            });

            setRowSelection(newSelection);
        }
    }, [enableRowSelectionOnErrorsChange, errors]);

    const [columnVisibility, setColumnVisibility] = useState<any>({ error: false, id: false });

    useEffect(() => {
        setColumnVisibility(merge(hideColumns.reduce((acc, curr) => {
            acc[curr] = false;
            return acc;
        }, { id: false, error: Object.keys(errors).length > 0 })));
    }, [hideColumns, errors]);

    const [tablePagination, setPagination] = useState<MRT_PaginationState>();

    return (<>
        <div className="d-flex align-items-center justify-content-between bg-white">
            <div className="d-flex align-items-left">
                {!!onSearch && <SearchField
                type="text"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                value={searchQuery}
                disabled={isLoading}
                placeholder={searchPlaceholder}
                />}
            </div>
            <div className="d-flex align-items-right py-3">
                <div
                    className="d-flex align-items-center"
                    style={{
                        opacity: isLoading ? 0.5 : 1,
                        pointerEvents: isLoading ? "none" : "all",
                    }}>
                    {extraButtons && extraButtons}
                    {!!filtersEnabled && <Tooltip title="Toggle Filters" placement="left">
                        <LoadingButton disabled={isLoading} loading={isLoading} onClick={() => setShowColumnFilters(!showColumnFilters)} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                            <FaWifi />
                        </LoadingButton>
                    </Tooltip>}
                    {!!onRefresh && <Tooltip title="Refresh Table" placement="left">
                        <LoadingButton disabled={isLoading} loading={isLoading} onClick={onRefresh} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                            <FaRedo />
                        </LoadingButton>
                    </Tooltip>}
                </div>
            </div>
        </div>
        <MaterialReactTable
            columns={[{
                id: "error",
                header: '',
                accessorKey: 'error',
                enableSorting: false,
                maxSize: 20,
                muiTableBodyCellProps: {
                    sx: {
                        padding: 0
                    }
                },
                Cell: ({ row }) => errors[row.id] && <Tooltip title={errors[row.id]} style={{ padding: 0 }}><ErrorOutlined className='text-danger' /></Tooltip>
            }, ...(columnHeaders?.map(x => ({ enableColumnFilter: false, ...x })) ?? [])]}

            data={tableData}

            getRowId={(row) => row.id}

            enableRowSelection={enableRowSelection}

            onRowSelectionChange={setRowSelection}

            enableRowActions={!!renderRowActions}
            renderRowActions={({ table, row }) =>
                <div style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '0.5rem',
                    justifyContent: 'flex-end',
                }}>
                    {renderRowActions && renderRowActions(row, table.getState().isLoading, loadingIds)}
                </div>
            }

            muiTableProps={muiTableProps}

            renderTopToolbarCustomActions={({ table }) => {
                return (!!renderTopTableActions ? <div style={{ display: 'flex', flex: 1, gap: '0.5rem', justifyContent: 'flex-start', padding: '0.5rem' }}>
                    {renderTopTableActions(table, isLoading)}
                </div> : undefined);
            }}

            renderBottomToolbarCustomActions={({ table }) => {
                if (!renderBottomTableActions && pagination) return <div style={{ display: 'flex', flex: 1, gap: '0.5rem', justifyContent: 'flex-start', padding: '0.5rem' }}>
                </div>
                return (!!renderBottomTableActions ? <div style={{ display: 'flex', flex: 1, gap: '0.5rem', justifyContent: 'flex-start', padding: '0.5rem' }}>
                    {renderBottomTableActions(table, isLoading)}
                </div> : undefined);
            }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'right'
                    },
                    muiTableBodyCellProps: {
                        align: 'right',
                    }
                },
            }}
            enableToolbarInternalActions={false}
            onColumnFiltersChange={setColumnFilters}
            enableTopToolbar={true}
            enableBottomToolbar={!!renderBottomTableActions || pagination}
            enableColumnActions={false}
            enablePagination={!!!rowVirtualization}
            enableFullScreenToggle={false}
            enableGlobalFilter={true}
            enableGlobalFilterModes={true}
            enableGlobalFilterRankedResults={false}
            enableDensityToggle={false}
            enableColumnFilters={true}
            enableHiding={false}
            enableStickyHeader={true}
            enableStickyFooter={false}
            positionActionsColumn="last"
            manualPagination={!!!pagination}
            initialState={{
                isLoading: true,
                density: type,
                pagination: { pageSize: 10, pageIndex: 0 },
                showSkeletons: true,
                showAlertBanner: false,
                showGlobalFilter: false,
                showColumnFilters: showColumnFilters,
                sorting: sorting,
                columnFilters: columnFilters,
                columnVisibility: columnVisibility,
                grouping: [...(groupBy ?? [])],
                expanded: expandedByDefault
            }}
            state={{
                isLoading: isLoading,
                showProgressBars: isSaving || isLoading,
                showSkeletons: isLoading,
                showAlertBanner: false,
                globalFilter: searchString,
                columnFilters: columnFilters,
                showColumnFilters: showColumnFilters,
                columnVisibility: columnVisibility,
                rowSelection,
                grouping: [...(groupBy ?? [])],
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [10, 15, 25, 50, 100],
                showFirstButton: true,
                showLastButton: true,
            }}
            muiSelectCheckboxProps={{
                color: 'primary',
            }}
            muiSelectAllCheckboxProps={{
                color: 'primary',
            }}
            muiToolbarAlertBannerProps={{
                sx: {
                    display: 'none'
                },
            }}
            positionPagination={!!pagination ? 'bottom' : 'none'}

            muiTableBodyProps={{
                sx: {
                    ...(tableStyle ?? {})
                }
            }}

            muiTableContainerProps={{
                sx: {
                    ...(tableContainerStyle ?? {})
                }
            }}

            muiTableHeadCellProps={{
                sx: {
                    padding: 1
                }
            }}

            muiTableBodyCellProps={{
                sx: {
                    padding: 1
                }
            }}
            enableGrouping={(groupBy?.length ?? 0) > 0}
            enableColumnDragging={false}
            enableRowVirtualization={!!rowVirtualization}
            rowVirtualizerProps={{
                overscan: 5
            }}
            enableExpandAll={showExpandAllButton}

            autoResetPageIndex={false}
        />
    </>);
};

export default SimpleTable;