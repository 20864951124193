import React, { useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { Card, Button, Grid, TextField, Tooltip } from '@mui/material';
import { FaTimes, FaSave, FaBackspace } from "react-icons/fa";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import { clearContactsState, handleContactFetch, handleContactImageUpsert, handleContactUpsert } from './store/Contacts';
import AvatarUpload from '@/components/AvatarUpload/AvatarUpload';
import { formatDateTime, isValidEmail, isValidPhoneNumber } from '@/helpers/Utils';
import { Alert } from '@mui/lab';

interface Props {
    handleContactImageUpsert_d: (imageData: any, onCompletedCallback?: (res: any) => void) => void,
    handleContactUpsert_d: (contact: any, onCompletedCallback?: (res: any) => void) => void,
    handleContactFetch_d: (contactId: any, onCompletedCallback?: (res: any) => void) => void,
    clearContactsState_d: () => void,
    handleClose?: (data?: any) => void,
    contact: any,
    loading?: boolean,
    isDialog?: boolean,
}

const ContactEditAdd: React.FC<Props> = props => {

    const {
        handleContactImageUpsert_d,
        handleContactUpsert_d,
        handleContactFetch_d,
        clearContactsState_d,
        handleClose = null,
        contact,
        loading = false,
        isDialog = false,
    } = props;

    const history = useHistory();

    const [formFields, setFormFields] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    });
    const [formFieldErrors, setFormFieldErrors] = useState<any>({
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
    });

    const [imageData, setImageData] = useState<any>(undefined);

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (location) {
            const contactId = new URLSearchParams(history.location.search).get("contactId")
            if (contactId) {
                refresh(contactId);
            } else {
                hideProgressSpinner();
            }
        }
        return (() => {
            if(Boolean(isDialog) === false) clearContactsState_d();
        })
    }, []);

    const refresh = (contactId) => {
        showProgressSpinner({ description: "Retrieving Contact/Agent..." });
        handleContactFetch_d(contactId, (res) => {
            hideProgressSpinner();
        });
    }

    useEffect(() => {
        if (contact) {
            setFormFields(contact)
        }
    }, [contact]);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const handleCancel = () => {
        if (isDialog === true && handleClose) handleClose();
        else {
            history.push('/Contacts');
            clearContactsState_d()
        }
    }

    const handleSubmit = () => {
        let isAllValid = validateAll()
        if (isAllValid) {
            showProgressSpinner({ description: "Saving Contact..." });

            handleContactUpsert_d({ ...contact, ...formFields, email: formFields.email.replace(/\s/g, '').replace(" ", "") }, (res) => {
                if (res.success) {
                    const contact = res.response;
                    setFormFields({ ...formFields, ...contact });
                    if (imageData) {
                        handleContactImageUpsert_d({ ...imageData, contactId: contact.id }, imageUpsertResponse => {
                            if (imageUpsertResponse.success) {
                                hideProgressSpinner();
                                ns.success("Contact/Agent successfully saved");

                                if (isDialog === true && handleClose) handleClose(contact.id);
                                else refresh(contact.id);
                            }
                            else {
                                hideProgressSpinner();
                                ns.information('Contact/Agent details were updated, but failed to save the image');
                            }
                        });
                    }
                    else {
                        hideProgressSpinner();
                        ns.success("Contact/Agent successfully saved");

                        if (isDialog === true && handleClose) handleClose(contact.id);
                        else refresh(contact.id);
                    }
                }
                else {
                    hideProgressSpinner();
                    ns.error(res?.error)
                }
            });
        }
        else {
            ns.error('Please fill in all required fields');
        }
    }

    const validateField = (e: any) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id === 'email' && !isValidEmail(e.target.value.replace(" ", ""))) {
            showError = true
        }

        if (e.target.id === 'phoneNumber' && !isValidPhoneNumber(e.target.value)) {
            showError = true
        }

        if (e.target.id !== undefined)
            setFormFieldErrors({ ...formFieldErrors, [e.target.id]: showError })
        else
            setFormFieldErrors({ ...formFieldErrors, [e.target.name]: showError })
    }

    const handleImageChange = (file: any) => {
        setImageData({ ...imageData, ...file })
    }

    const validateAll = () => {
        let isAllFormFieldsValid = true;
        let newState = {
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
        }
        for (const [key, value] of Object.entries(formFieldErrors)) {
            const formFieldsValue = formFields[key];
            if (formFieldsValue === "") {
                isAllFormFieldsValid = false;
                newState[key] = true;
            } else {
                newState[key] = false;
            }
        }
        setFormFieldErrors(newState)
        return isAllFormFieldsValid;
    }

    return (<>
        {!loading && (<>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Card className="card-box mb-spacing-6-x2">
                        <div className="card-header dpy-3">
                            <div className="card-header--title font-size-lg">
                                Contact/Agent Information
                            </div>
                        </div>
                        <div className="p-3">
                            {contact?.source && contact.source !== 'Internal' && <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Alert severity="warning" style={{ marginLeft: 8, marginRight: -6 }}>The source for this data is {contact.source} and therefor editing of this contact is disabled.
                                    <br />
                                    <br />To make data changes to this contact please use the {contact.source} portal.
                                    <br />
                                    <br />Last updated: {contact.modifiedAt ? formatDateTime(contact.modifiedAt, 'yyyy/MM/dd HH:mm') : formatDateTime(contact.createdAt, 'yyyy/MM/dd HH:mm')}
                                    <br />
                                </Alert>
                            </Grid>}
                            <AvatarUpload onChange={handleImageChange} imageUrl={contact?.image?.url} disabled={contact?.source && contact.source !== 'Internal'} />
                            <TextField
                                margin="normal"
                                autoFocus
                                value={formFields.firstName}
                                required
                                error={formFieldErrors.firstName}
                                onChange={onChange}
                                id="firstName"
                                fullWidth
                                className="m-2"
                                label="First Name"
                                onBlur={validateField}
                                disabled={contact?.source && contact.source !== 'Internal'}
                            />
                            <TextField
                                margin="normal"
                                value={formFields.lastName}
                                required
                                error={formFieldErrors.lastName}
                                onChange={onChange}
                                id="lastName"
                                fullWidth
                                className="m-2"
                                label="Last Name"
                                onBlur={validateField}
                                disabled={contact?.source && contact.source !== 'Internal'}
                            />
                            <TextField
                                margin="normal"
                                id="email"
                                fullWidth
                                className="m-2"
                                label="Email Address"
                                type="email"
                                value={formFields.email}
                                onChange={onChange}
                                required
                                onBlur={validateField}
                                error={formFieldErrors.email}
                                onKeyDown={(e) =>
                                    (e.key === " ") && e.preventDefault()
                                }
                                disabled={contact?.source && contact.source !== 'Internal'}
                            />
                            <TextField
                                margin="normal"
                                defaultValue={formFields.phoneNumber}
                                id="phoneNumber"
                                fullWidth
                                className="m-2"
                                label="Phone Number"
                                type="tel"
                                value={formFields.phoneNumber}
                                onChange={onChange}
                                onKeyDown={(e) =>
                                    (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
                                }
                                required
                                onBlur={validateField}
                                error={formFieldErrors.phoneNumber}
                                disabled={contact?.source && contact.source !== 'Internal'}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {
                Boolean(isDialog) === false ? (
                    <FloatyButtonGroup>
                        {(!contact?.source || contact?.source === 'Internal') && <Tooltip arrow title="Save" placement="left">
                            <Button size="large" variant="contained" onClick={() => handleSubmit()}
                                className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                                <span className="btn-wrapper--icon">
                                    <FaSave />
                                </span>
                            </Button>
                        </Tooltip>}
                        <Tooltip arrow title="Back" placement="left">
                            <Button size="large" variant="contained" onClick={() => handleCancel()}
                                className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared text-capitalize">
                                <span className="btn-wrapper--icon">
                                    <FaBackspace />
                                </span>
                            </Button>
                        </Tooltip>
                    </FloatyButtonGroup>
                ) :
                    (<div className="text-right">
                        <Button onClick={() => handleCancel()} variant="contained" className="btn-danger p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaTimes />
                            </span>
                            <span className="btn-wrapper--label">Cancel</span>
                        </Button>

                        {(!contact?.source || contact?.source === 'Internal') && <Button onClick={() => handleSubmit()} variant="contained" className="btn-primary p-3 text-white m-1 text-capitalize" size="large">
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                            <span className="btn-wrapper--label">Save</span>
                        </Button>}
                    </div>)
            }

        </>)}
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleContactImageUpsert_d: (imageData: any, onCompletedCallback?: (res: any) => void) => dispatch(handleContactImageUpsert(imageData, onCompletedCallback)),
        handleContactUpsert_d: (contact: any, onCompletedCallback?: (res: any) => void) => dispatch(handleContactUpsert(contact, onCompletedCallback)),
        handleContactFetch_d: (contactId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleContactFetch(contactId, onCompletedCallback)),
        clearContactsState_d: () => dispatch(clearContactsState())
    }
}

const mapStateToProps = (state: any) => ({
    contact: state.contacts.contact,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditAdd);