import { IdentityRoles, User } from "../dto/User"
import { UserProfiles } from "../dto/UserProfiles";

export class PrembidLocalStorage {
    private static _keys = {
        current_user: "current_user"
    }

    public static get currentUser() {
        const userString = sessionStorage.getItem(this._keys.current_user);
        if (userString === null)
            return null;
        const user = JSON.parse(userString) as User;
        return user;
    }
    public static set currentUser(user: User | null) {
        const userJson = JSON.stringify(user);
        if (userJson)
            sessionStorage.setItem(this._keys.current_user, userJson);
    }
    public static clearCurrentUser() {
        sessionStorage.removeItem(this._keys.current_user);
    }

    public static set currentProfile(profileId: string) {
        const userString = sessionStorage.getItem(this._keys.current_user);

        if (userString !== null) {
            const user = JSON.parse(userString) as User;
            user.currentProfileId = profileId

            const userJson = JSON.stringify(user)
            sessionStorage.setItem(this._keys.current_user, userJson);
        }
    }

    public static set defaultProfile(profileId: string) {
        const userString = sessionStorage.getItem(this._keys.current_user);

        if (userString !== null) {
            const user = JSON.parse(userString) as User;
            const profiles = user.profiles?.map(profile => ({ ...profile, isDefaultProfile: (profile.profileId === profileId) }))
            const userJson = JSON.stringify({ ...user, profiles: profiles ?? [] });
            sessionStorage.setItem(this._keys.current_user, userJson);
        }
    }

    public static get currentProfile() {
        const userString = sessionStorage.getItem(this._keys.current_user);

        if (userString === null) {
            return '';
        } else {
            const user = JSON.parse(userString) as User;
            return user.currentProfileId;
        }
    }

    public static get currentProfileType() {
        const userString = sessionStorage.getItem(this._keys.current_user);

        if (userString === null) {
            return '';
        } else {
            const user = JSON.parse(userString) as User;
            return user?.profiles?.filter(x => x.profileId === user.currentProfileId)[0].profileType;
        }
    }

    public static get currentProfileName() {
        const userString = sessionStorage.getItem(this._keys.current_user);

        if (userString === null) {
            return '';
        } else {
            const user = JSON.parse(userString) as User;
            const profile = user.profiles?.filter(profile => profile.profileId === user.currentProfileId) as UserProfiles[];

            if (profile && profile[0]) {
                return profile[0].profileName;
            } else {
                return '';
            }
        }
    }

    public static removeAllData() {
        sessionStorage.clear();
    }

    public static CurrentUserInRole(role: IdentityRoles): boolean {
        let currentUser = PrembidLocalStorage.currentUser;
        if (currentUser?.roles?.includes(role))
            return true;
        else return false;
    }
}