import React from 'react';
import { Chip as MaterialChip } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const CustomChip = (props: any) => {
    const StyledChip = withStyles(MaterialChip, {
        root: {
            'color': 'white',
            'backgroundColor': `${props.color} !important`,
            '&:active': {
                boxShadow: 'none',
                backgroundColor: props.color,
                borderColor: props.color,
            },
        },
        outlined: {
            color: props.color,
            border: `1px solid ${props.color}`,
            backgroundColor: `transparent !important`,
        },
        icon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
        deleteIcon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
    });

    return <StyledChip {...props} />;
};

export default CustomChip;