import React, { FC, memo, MouseEventHandler, ChangeEvent } from "react";
import {
	Button,
	Grid,
	Menu,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
    SelectChangeEvent,
    PopoverVirtualElement,
    PopoverProps,
} from "@mui/material";
import {
	AvailableFilters,
	SelectedFilters,
} from "../NestedAccordionGrid.types";

interface FiltersModalProps {
	onSubmit?: MouseEventHandler<HTMLButtonElement> | undefined;
	onClose?: () => void;
	anchorEl?: PopoverProps['anchorEl'];
	loading: boolean;
	availableFilters?: AvailableFilters;
	selectedFilters: SelectedFilters;
	onSelectionChange: (
		event: SelectChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) => void;
	filterLists: any[];
}

const FiltersModal: FC<FiltersModalProps> = ({
	onSubmit,
	onClose,
	anchorEl,
	loading,
	availableFilters,
	selectedFilters,
	onSelectionChange,
	filterLists,
}) => {
	return (
		<Menu
			anchorEl={anchorEl}
			keepMounted
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			open={Boolean(anchorEl)}
			classes={{ list: "p-0" }}
			onClose={onClose}>
			<div
				className="dropdown-menu-xxl overflow-hidden p-0"
				style={{
					opacity: loading ? 0.5 : 1,
					pointerEvents: loading ? "none" : "all",
				}}>
				<div className="p-3">
					<Grid container spacing={4}>
						{availableFilters &&
							availableFilters.map((filter, index) => (
								<Grid key={index} item md={6}>
									<FormControl
										className="w-100">
										<InputLabel>{filter.description}</InputLabel>
										<Select
											name={filter.propertyMap}
											id={filter.propertyMap}
											fullWidth
											label={filter.description}
											value={
												selectedFilters[filter.propertyMap] !== undefined &&
												selectedFilters[filter.propertyMap] !== null
													? selectedFilters[filter.propertyMap].toString()
													: "All"
											}
											onChange={(e, child) => onSelectionChange(e)}>
											<MenuItem value="All">All</MenuItem>
											{filter.options.map((option, index) => {
												return (
													<MenuItem key={index} value={option.value.toString()}>
														{option.label}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							))}
						{!availableFilters &&
							filterLists.map((filter, index) => (
								<Grid key={index} item md={6}>
									<FormControl
										variant="outlined"
										size="small"
										className="w-100">
										<InputLabel>{filter.key}</InputLabel>
										<Select
											name={filter.key}
											id={filter.key}
											fullWidth
											label={filter.key}
											value={
												selectedFilters[filter.key]
													? selectedFilters[filter.key]
													: "All"
											}
											onChange={onSelectionChange}>
											<MenuItem value="All">All</MenuItem>
											{filter.values.map((filterVal, index) => {
												return (
													<MenuItem key={index} value={filterVal}>
														{filterVal}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							))}
					</Grid>
				</div>
			</div>
			<div
				className="p-3 text-center bg-secondary"
				style={{
					opacity: loading ? 0.5 : 1,
					pointerEvents: loading ? "none" : "all",
				}}>
				<Button
					onClick={onSubmit}
					variant="contained"
					className="btn-primary p-3 text-white m-1 text-capitalize"
					size="large">
					Filter results
				</Button>
			</div>
		</Menu>
	);
};

export default memo(FiltersModal);
