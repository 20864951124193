import { CircularProgress } from "@mui/material";
import { CSSProperties, ReactNode, Suspense } from "react";
import { useSelector } from "react-redux";

type LoadingProps = {
    loading: boolean;
    size?: string | number;
    thickness?: number;
    children?: ReactNode;
    type?: "spinner" | "text" | "both";
    style?: CSSProperties;
}

const Loading = (props: LoadingProps) => {
    const { settings } = useSelector((state: any) => state.settings);
    const { loading = "spinner", size, thickness, children, style } = props;

    const renderLoading = () => <div
        className='d-flex flex-direction-column align-items-center justify-content-center'
        style={style}>
        <CircularProgress
            className="text-center"
            size={size}
            thickness={thickness}
            style={{
                margin: 'auto',
                color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary
            }} />
    </div>

    return <Suspense fallback={renderLoading()}>
        {loading ? renderLoading() : children}
    </Suspense>
}

export default Loading;