import { useDropzone } from 'react-dropzone';
import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { MdClose, MdCheck } from "react-icons/md";

interface Props {
    onFileUploaded: (file: any) => void,
}


const BulkUploadFileUploader: React.FC<Props> = props => {
    const { onFileUploaded } = props;

    const {
        acceptedFiles, isDragActive,
        isDragAccept,
        isDragReject, getRootProps, getInputProps
    } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
        },
    });

    useEffect(() => {
        onFileUploaded(acceptedFiles?.[0]);
    }, [acceptedFiles])

    return (
        <div>
            <div className="dropzone">
                <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                    <input {...getInputProps()} />
                    <div className="dropzone-inner-wrapper">
                        {isDragAccept && (
                            <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success rounded-circle text-white">
                                    <MdCheck className="d-50" />
                                </div>
                                <div className="font-size-sm text-success">Drop to add</div>
                            </div>
                        )}
                        {isDragReject && (
                            <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger rounded-circle text-white">
                                    <MdClose className="d-50" />
                                </div>
                                <div className="font-size-sm text-danger">
                                    An invalid file was selected. Please ensure to use the previously generated template.
                                </div>
                            </div>
                        )}

                        <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            spacing={0}>
                            <Grid item xs={12}>
                                {!isDragActive && (
                                    <span className="text-black">
                                        Please drag and drop or browse to add your completed bulk upload template.
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Button className="btn-primary p-3 text-white mt-3 text-capitalize">
                                    <span className="px-2">Browse Completed Bulk Upload Template</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BulkUploadFileUploader