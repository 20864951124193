import { FC, memo, useState, useEffect } from "react";
import { useCreateMaximumBid, useRetrieveMaxBid } from "prembid-shared-library-npm/networking";
import ns from "@/helpers/NotificationService";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import { ceil } from "lodash";
import { LoadingButton } from "@mui/lab";
import { FaTimes } from "react-icons/fa";
interface MaximumBidButtonProps {
    auctionId: any;
    lotId: any;
    profileId: any;
    automaticBidAmount: number;
    maximumBidAmount: number;
    onBidSuccessfullySubmitted: (maxBidAmount: number) => void;
}

const MaximumBidButton: FC<MaximumBidButtonProps> = (props) => {
    const { auctionId, lotId, profileId, automaticBidAmount, maximumBidAmount, onBidSuccessfullySubmitted } = props;
    const [confirm, setConfirm] = useState(false);

    const [maxBidAmountField, setMaxBidAmountField] = useState<any>({
        maxBidAmount: 0,
    });

    const { data: serverMaxBid, isLoading: loadingMaxBid } = useRetrieveMaxBid({
        pathParams: {
            auctionId,
            lotId,
            profileId,
        }
    });

    useEffect(() => {
        if (!loadingMaxBid && serverMaxBid) {
            setMaxBidAmountField({ maxBidAmount: serverMaxBid.amount });
        }
    }, [serverMaxBid, loadingMaxBid]);

    const { mutate: createMaximumBid, isLoading: loading } = useCreateMaximumBid({
        onSuccess(data: any) {
            ns.success("Maximum bid successfully submitted");
            onBidSuccessfullySubmitted(Number(maxBidAmountField.maxBidAmount));
            setConfirm(false);
        },
        onError() {
            setConfirm(false);
        },
    });

    useEffect(() => {
        if (maximumBidAmount > 0) setMaxBidAmountField({ maximumBidAmount: 0 });
    }, [maximumBidAmount])

    const handleChangeMaxBid = (e: { target: { id: any; value: string } }) => {
        setMaxBidAmountField({
            ...maxBidAmountField,
            [e.target.id]: e.target.value,
        });
    };

    const handleMaxBidButton = () => {
        if (confirm) {
            const maximumBid = {
                auctionId: auctionId,
                lotId: lotId,
                amount: ceil(Number(maxBidAmountField.maxBidAmount), 2),
                automaticBidAmount: automaticBidAmount,
                profileId: profileId,
            };

            createMaximumBid(maximumBid);
        } else {
            setConfirm(true);
        }
    };

    return (
        <>
            <div style={{ flexDirection: "row" }}>
                <Grid
                    className="mt-1"
                    container
                    spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            disabled={loadingMaxBid}
                            value={maxBidAmountField.maxBidAmount}
                            onChange={(e) => handleChangeMaxBid(e)}
                            id="maxBidAmount"
                            fullWidth
                            variant="outlined"
                            label="Maximum Bid"
                            InputProps={{
                                style: { height: 56 },
                                inputComponent: CurrencyInput,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Currency priceWithSymbol={""} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                            <LoadingButton
                                style={{ height: 56 }}
                                onClick={handleMaxBidButton}
                                fullWidth
                                variant="contained"
                                className={
                                    confirm
                                        ? "btn-success p-3 text-white text-capitalize"
                                        : "btn-primary p-3 text-white text-capitalize"
                                }
                                loading={loading}
                            >
                                {confirm ? (
                                    "Confirm"
                                ) : (
                                    "Submit"
                                )}
                            </LoadingButton>
                            {
                                confirm &&
                                <Button onClick={() => setConfirm(false)}
                                    style={{ height: 56, width: 56 }}
                                    variant="contained"
                                    className="btn-danger p-3 text-white text-capitalize">
                                    <span className="btn-wrapper--icon">
                                        <FaTimes />
                                    </span>
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default memo(MaximumBidButton);
