import React, { useEffect, useState, memo, FC } from "react";
import { useSelector } from "react-redux";
import { Button, Badge, Typography, Grid } from "@mui/material";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { BidRow, TopBidderState } from "../AuctionsOverviewGrid.types";
import TopBid from "@/components/AuctionsOverviewGrid/sub-components/TopBid";
import TimeRemainingCountdown from "./TimeRemainingCountdown";
import { Auction, Lot } from "prembid-shared-library-npm/types";
import { withStyles } from 'tss-react/mui';
import { RootState } from "../../../store/ConfigureStore";
import { useFinalizing } from 'prembid-shared-library-npm/hooks';
import { AuctionTypeEnum } from "../../../helpers/AuctionTypeEnum";
import Loading from "../../Loading";

const StyledBadge = withStyles(Badge, {
    badge: {
        backgroundColor: "var(--success)",
        color: "var(--success)",
        boxShadow: "0 0 0 2px #fff",
        "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
});

interface ActionsCellProps {
    selectedAuction: Auction;
    lot: Lot;
    refreshStatus?: (lot: Lot) => void;
    isTender: boolean;
    lotBidsLoading: boolean,
    bidRows: BidRow[];
    hasChanges: boolean;
    extendedBy: number;
    handleClickPopover: (event: any, lot: Lot) => void;
    buttonSelected: boolean;
    winningProfile?: {
        paddleNumber: string;
        name: string;
    },
    auctionType: AuctionTypeEnum;
}

const ActionsCell: FC<ActionsCellProps> = (props) => {

    const {
        lot,
        refreshStatus,
        isTender,
        lotBidsLoading,
        bidRows,
        hasChanges,
        extendedBy,
        handleClickPopover,
        buttonSelected,
        winningProfile,
        auctionType
    } = props;

    const {
        startDateTimeAt,
        isActive,
        endDateTimeAt,
        winningRegisteredProfileAmount: topBid,
        paddleNumber,
    } = lot;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const { settings } = useSelector((state: any) => state.settings);
    const [newTopBid, setNewTopBid] = useState<TopBidderState>({ amount: topBid, bidder: paddleNumber, name: winningProfile?.name });

    const { validating, setValidating, finalizingText } = useFinalizing({
        auctionType: auctionType,
        lotLocale: settings.LotName
    });

    useEffect(() => {
        const { amount, paddleNumber: bidder, name } = bidRows[0] ?? {};

        if (Number((topBid ?? 0)) > Number((amount ?? 0)))
            setNewTopBid({ amount: topBid, bidder: paddleNumber, name: winningProfile?.name })
        else {
            if (amount && bidder) {
                setNewTopBid({ amount, bidder, name });
            } else {
                if (buttonSelected && !lotBidsLoading) {
                    setNewTopBid({ amount: null, bidder: null, name: undefined });
                }
            }
        }
    }, [winningProfile, lotBidsLoading, buttonSelected, bidRows, topBid, paddleNumber]);

    useEffect(() => {
        setValidating(false);
    }, [endDateTimeAt, extendedBy]);

    useEffect(() => {
        if (validating) setTimeout(() => setValidating(false), 4000);
    }, [setValidating, validating]);

    return (
        <Grid
            flexDirection="column"
            container style={{ maxWidth: 300 }}>
            {!validating && <Grid item xs={12}>
                <Typography variant="subtitle2"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                    }}>
                    <div>
                        {hasChanges ? (
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent=""
                                classes={{ badge: "bg-success badge-circle border-0" }}
                                variant="dot"
                            />
                        ) : (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent=""
                                classes={{ badge: "bg-dark badge-circle border-0" }}
                                variant="dot"
                            />
                        )}
                    </div>
                    <div className="ml-2" style={{ display: 'flex', flexDirection: 'row' }}>
                        <TopBid
                            topBid={newTopBid.amount}
                            topBidder={newTopBid.bidder}
                            isTender={isTender}
                            name={newTopBid.name}
                        />
                    </div>
                </Typography>
            </Grid>}
            <Grid item xs={12}>
                {!validating && <div className="ml-2">
                    <TimeRemainingCountdown
                        isActive={isActive}
                        startDateTimeAt={startDateTimeAt}
                        endDateTimeAt={endDateTimeAt}
                        extendedBy={extendedBy}
                        onComplete={() => setValidating(true)}
                        dateTimeOffset={currentDateTimeOffset}
                    />
                </div>}
                {validating && <div>
                    <Loading loading={true} />
                    <div style={{ textAlign: 'center', paddingTop: 10 }}>{finalizingText}</div>
                </div>}
                <Button
                    onClick={event => handleClickPopover(event, lot)}
                    fullWidth
                    variant="contained"
                    className="btn-primary p-3 mt-2 text-white text-capitalize"
                    size="large"
                    style={{
                        background: !buttonSelected ? settings?.Styles?.Primary : "#2b2b2b",
                    }}
                    endIcon={!buttonSelected ? <FaAngleDown /> : <FaAngleUp />}>
                    Actions
                </Button>
            </Grid>
        </Grid>
    );
};

export default memo(ActionsCell);
