import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const ADD_DOCUMENT_TEMPLATE_SUCCESS = 'ADD_DOCUMENT_TEMPLATE_SUCCESS';
export const UPDATE_DOCUMENT_TEMPLATE_SUCCESS = 'UPDATE_DOCUMENT_TEMPLATE_SUCCESS';
export const DELETE_DOCUMENT_TEMPLATE_SUCCESS = 'DELETE_DOCUMENT_TEMPLATE_SUCCESS';
export const DOCUMENT_TEMPLATES_FETCH_SUCCESS = 'DOCUMENT_TEMPLATES_FETCH_SUCCESS';
export const DOCUMENT_TEMPLATES_WITH_PAGINATION_FETCH_SUCCESS = 'DOCUMENT_TEMPLATES_WITH_PAGINATION_FETCH_SUCCESS';
export const DOCUMENT_TEMPLATE_FETCH_SUCCESS = 'DOCUMENT_TEMPLATE_FETCH_SUCCESS';
export const SET_DOCUMENT_TEMPLATE = 'SET_DOCUMENT_TEMPLATE';
export const CLEAR_DOCUMENT_TEMPLATE = 'CLEAR_DOCUMENT_TEMPLATE';
export const NEW_DOCUMENT_TEMPLATE = 'NEW_DOCUMENT_TEMPLATE';

export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DOCUMENT_GET_ALL_SUCCESS = 'DOCUMENT_GET_ALL_SUCCESS';

export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const FETCH_ERROR = 'FETCH_ERROR';

export const CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS";

export const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    documentTemplates: [],
    documentTemplate: undefined,
    documentTemplateRequest: 'Add',
    documentTemplateId: '',
    selectDocumentTemplate: undefined,
    documents: undefined,
}
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ADD_DOCUMENT_TEMPLATE_SUCCESS: return { ...state, error: false, errorText: '', documentTemplateId: action.payload, reload: !state.reload, documentTemplateRequest: 'Edit', success: true }
        case UPDATE_DOCUMENT_TEMPLATE_SUCCESS: return { ...state, error: false, errorText: false, success: true }
        case DELETE_DOCUMENT_TEMPLATE_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, documentTemplateRequest: 'Add' }
        case SET_DOCUMENT_TEMPLATE: return { ...state, error: false, errorText: null, documentTemplate: action.payload, documentTemplateRequest: 'Edit' }
        case DOCUMENT_TEMPLATES_FETCH_SUCCESS: return { ...state, error: false, errorText: null, documentTemplates: action.payload, success: false, documentTemplateRequest: 'Add', documentTemplateId: '', documentTemplate: undefined }
        case DOCUMENT_TEMPLATES_WITH_PAGINATION_FETCH_SUCCESS: return { ...state, error: false, errorText: null, documentTemplates: action.payload.list, pagination: action.payload.pagination, success: false, documentTemplateRequest: 'Add', documentTemplateId: '', documentTemplate: undefined }
        case DOCUMENT_TEMPLATE_FETCH_SUCCESS: return { ...state, error: false, errorText: null, documentTemplate: action.payload, success: false, documentTemplateId: '', documentTemplateRequest: 'Edit' }
        case CLEAR_DOCUMENT_TEMPLATE: return { ...state, documentTemplate: undefined, reverseGeoResult: undefined }
        case NEW_DOCUMENT_TEMPLATE: return { ...state, selectDocumentTemplate: action.payload }

        case ADD_DOCUMENT_SUCCESS: return { ...state, error: false, errorText: '', documentId: action.payload, success: true }
        case DELETE_DOCUMENT_SUCCESS: return { ...state, error: false, errorText: null }
        case DOCUMENT_GET_ALL_SUCCESS: return { ...state, documents: action.payload }

        case ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_ERROR: return { ...state, error: false, errorText: false, success: false }
        case CLEAR_DOCUMENTS: return { ...state, documents: undefined }
        case CLEAR_API_RESPONSES: return { ...state, success: false, error: false, documentTemplateId: '' }
        default: return state;
    }
}

//Action creator
export const addDocumentTemplateSuccess = (id: string) => {
    return { type: ADD_DOCUMENT_TEMPLATE_SUCCESS, payload: id }
}

export const documentTemplateUpdateSuccess = () => {
    return { type: UPDATE_DOCUMENT_TEMPLATE_SUCCESS, payload: UPDATE_DOCUMENT_TEMPLATE_SUCCESS }
}

export const deleteDocumentTemplateSuccess = (success: string) => {
    return { type: DELETE_DOCUMENT_TEMPLATE_SUCCESS, payload: success }
}

export const documentTemplatesFetchSuccess = (documentTemplates: any) => {
    return { type: DOCUMENT_TEMPLATES_FETCH_SUCCESS, payload: documentTemplates }
}

export const documentTemplatesFetchSuccessWithPagination = (documentTemplates: any) => {
    return { type: DOCUMENT_TEMPLATES_WITH_PAGINATION_FETCH_SUCCESS, payload: documentTemplates, documentTemplatesTotal: 0 }
}

export const documentTemplateFetchSuccess = (documentTemplate: any) => {
    return { type: DOCUMENT_TEMPLATE_FETCH_SUCCESS, payload: documentTemplate }
}

export const setdocumentTemplate = (documentTemplate: any) => {
    return { type: SET_DOCUMENT_TEMPLATE, payload: documentTemplate }
}

export const clearDocumentTemplate = () => {
    return { type: CLEAR_DOCUMENT_TEMPLATE, payload: CLEAR_DOCUMENT_TEMPLATE }
}

export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearError = () => {
    return { type: CLEAR_ERROR, payload: CLEAR_ERROR }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const newDocumentTemplateId = (selectDocumentTemplate) => {
    return { type: NEW_DOCUMENT_TEMPLATE, payload: selectDocumentTemplate }
}

export const addDocumentSuccess = (id: string) => {
    return { type: ADD_DOCUMENT_SUCCESS, payload: id }
}

export const retrieveAllDocumentsSuccess = (files) => {
    return { type: DOCUMENT_GET_ALL_SUCCESS, payload: files }
}

export const clearDocuments = () => {
    return { type: CLEAR_DOCUMENTS, payload: CLEAR_DOCUMENTS }
}

export const deleteDocumentSuccess = () => {
    return { type: DELETE_DOCUMENT_SUCCESS, payload: deleteDocumentSuccess }
}

export const handleDocumentTemplateAdd = (documentTemplateInfo: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.documentTemplate.createDocumentTemplate, documentTemplateInfo)
            .then(function (res) {
                if (res.success) {
                    const id = res.response
                    dispatch(addDocumentTemplateSuccess(id))

                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleDocumentTemplateUpdate = (documentTemplateInfo: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.documentTemplate.updateDocumentTemplate, documentTemplateInfo)
            .then(function (res) {
                if (res.success) {
                    dispatch(documentTemplateUpdateSuccess())
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleDelete = (id: string, onCompletedCallback?: (result: any) => void) => {
    return (dispatch: any) => {

        return requestClient.post(apiPaths.documentTemplate.deleteDocumentTemplate, { id })
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            })
    }
}

export const handleDocumentDelete = (documentId: string) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.document.deleteDocument, { id: documentId })
            .then(function (resp) {
                if (resp.success) {

                } else {
                    dispatch(error(resp.error))
                }
            });
    }
}

export const handleRegistrationDocumentDelete = (documentId: string) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.document.deleteRegistrationDocument, { id: documentId })
            .then(function (resp) {
                if (resp.success) {

                } else {
                    dispatch(error(resp.error))
                }
            });
    }
}

export const handleDocumentTemplatesFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.documentTemplate.retrieveAllDocumentTemplates, null, null)
            .then(function (res) {
                if (res.success) {
                    const jsonData = res.response
                    dispatch(documentTemplatesFetchSuccess(jsonData))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleDocumentTemplatesFetchWithPagination = (filters?: any, pagination?: any, onCompletedCallback?: (response: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.documentTemplate.retrieveAllDocumentTemplatesWithPagination, {
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (res.success) {

                dispatch(documentTemplatesFetchSuccessWithPagination(res.response))
            }
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleDocumentTemplateFetch = (id: string) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.documentTemplate.retrieveDocumentTemplate, null, { documentTemplateId: id })
            .then(function (res) {
                if (res.success) {
                    const documentTemplate = res.response
                    dispatch(documentTemplateFetchSuccess(documentTemplate))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleDocumentAdd = (relatedTableRecordId: string, document: any) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.document.createDocument, { documentTemplateId: document.documentTemplateId, relatedTableRecordId: relatedTableRecordId, documentUploadData: [{ documentBase64String: document.fileUploadData.base64 }] })
            .then(function (resp) {
                if (resp.success) {
                    const id = resp.response
                    dispatch(addDocumentSuccess(id))

                } else {
                    dispatch(error(resp.error))
                }
            });
    }
}

export const handleDocumentsForRelatedTableRecordFetch = (relatedTableRecordId: string) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord, null, { relatedTableRecordId: relatedTableRecordId })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

export const handleRetrieveRegistrationDocuments = (auctionId: string, lotId?: string) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveRegistrationDocuments, null, auctionId ? { auctionId: auctionId, lotId: lotId } : { lotId })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

export const handleRetrieveAllDocumentsForRelatedTableRecord = (relatedTableRecordId: string) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveAllDocumentsForRelatedTableRecord, null, { relatedTableRecordId: relatedTableRecordId })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

export const handleRetrieveAllDocumentTemplatesByDocumentType = (currentDocumentType: any, onCompletedCallback?: (response: any) => void) => {

    return (dispatch: any) => {
        return requestClient.get(apiPaths.documentTemplate.retrieveAllDocumentTemplatesByDocumentType, null, { documentType: currentDocumentType })
            .then(function (resp) {
                if (resp.success) {
                    const jsonData = resp.response
                    dispatch(documentTemplatesFetchSuccess(jsonData))
                }
                else {
                    dispatch(error(resp.error));
                }

                onCompletedCallback?.(resp);
            });
    }
}

export const downloadFile = (url: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.downloadFile(apiPaths.document.downloadSecureFile, { url: encodeURIComponent(url) }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                if (onCompletedCallback) onCompletedCallback(null);
            })
    }
}