import React from 'react';
import { CircularProgress, TextField as MUITextField, TextFieldProps as MUITextFieldProps, Typography, Tooltip } from '@mui/material';


export type TextFieldProps = {
    loading?: boolean;
    tooltip?: string;
} & MUITextFieldProps

function TextField(props: TextFieldProps) {

    const combinedInputProps: MUITextFieldProps['InputProps'] = {
        style: { width: '100%' },
        ...(props.InputProps ?? {})
    }

    if (props.loading) {
        combinedInputProps.startAdornment = <React.Fragment>
            <CircularProgress color="inherit" size={20} style={{ marginRight: 10 }} className='primary' />
        </React.Fragment>
    }

    return <Tooltip title={props.tooltip}>
        <MUITextField
            margin="normal"
            fullWidth
            className="mt-2"
            InputProps={combinedInputProps}
            disabled={props.loading}
            defaultValue=''
            {...props}
            value={props.select && props.loading ? '' : props.value ?? ''}
            select={props.loading ? false : props.select}
        />
    </Tooltip>
}

export default TextField;