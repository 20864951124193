import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { handleSubmit } from './store/ConfirmAccountCard';
import { showProgressSpinner, hideProgressSpinner } from '@/helpers/ProgressSpinnerService'
import ns from '@/helpers/NotificationService';

interface Props {
    handleSubmit_d: (userId: string, tokenId: string, onCompletedCallback: (res: any) => void) => void
}

const ConfirmAccountCard: React.FC<Props> = props => {
    const history = useHistory();
    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const {
        handleSubmit_d
    } = props;

    useEffect(() => {
        showProgressSpinner({ description: 'Confirming Account...' });
        handleSubmit_d(userId, tokenId, (res) => {
            if (res.success) {
                redirect();
                hideProgressSpinner();
            }
            else {
                ns.error(res.error);
                redirectHome();
            }
        })
    }, []);

    const redirect = () => {
        let path = '/ConfirmAccountConfirmation';
        history.push(path);
    };

    const redirectHome = () => {
        let path = '/login';
        history.push(path);
    };

    return (<></>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (userId: string, tokenId: string, onCompletedCallback: (res: any) => void) => dispatch(handleSubmit(userId, tokenId, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountCard)