import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { handleSubmit, resetPasswordResetState } from './store/ResetPasswordCard';
import useStyles from './ResetPasswordCardStyles';
import ns from '@/helpers/NotificationService';
import {
    Button,
    Typography,
    IconButton,
    InputAdornment,
    OutlinedInput,
    CssBaseline,
    Container,
    FormControl,
    InputLabel
} from '@mui/material';
import { Alert } from '@mui/lab';
import { passwordStrongRegex, passwordValidationError } from "@/helpers/Regex"
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import PasswordChecklist from "@/components/PasswordCheckList/PasswordCheckList"
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

interface Props {
    handleSubmit_d: (userId: string, tokenId: string, password: string) => void,
    resetPasswordResetState_d: () => void,
    error: boolean,
    errorText: string,
    success: boolean,
    settings: any,
    loading: boolean
}

const ResetPasswordCard: React.FC<Props> = props => {
    const history = useHistory();
    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const {
        handleSubmit_d,
        resetPasswordResetState_d,
        error,
        errorText,
        success,
        settings,
        loading
    } = props;

    const { classes, cx } = useStyles();

    const intialValues = {
        password: "",
        confirmPassword: "",
        errorPasswordMessage: "",
        errorEmailMessage: "",
        passwordLength: [] as any
    };

    const [formFields, setFormFields] = useState(intialValues);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        let passwordError = "";

        if (e.target.id === "password" && formFields.password.length < 7) {
            passwordError = "Password is too short";
        }

        setFormFields({
            ...formFields,
            [e.target.id]: e.target.value,
            errorPasswordMessage: passwordError
        });
    }

    const isPasswordValid = (password: any, passwordConfirmation: any) => {
        if (!password || !passwordConfirmation) return false;
        return password === passwordConfirmation;
    };


    const passLengthValidation = (value: any) => {
        const re = passwordStrongRegex;
        return re.test(value);
    };

    const onSubmit = () => {
        setFormFields({
            ...formFields,
            errorPasswordMessage: isPasswordValid(formFields.password, formFields.confirmPassword)
                ? ''
                : ' Password did not match',
            passwordLength: passLengthValidation(formFields.password) ? '' : passwordValidationError
        });

        if (
            !isPasswordValid(formFields.password, formFields.confirmPassword) ||
            !passLengthValidation(formFields.password)
        ) {
            return;

        }

        showProgressSpinner({ description: 'Resetting Password...' });
        handleSubmit_d(userId, tokenId, formFields.password)
    }

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            redirect();
        }
        resetPasswordResetState_d();

    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) ns.error(errorText);

        resetPasswordResetState_d();
    }, [error]);

    const redirect = () => {
        let path = '/ResetPasswordConfirmation';
        history.push(path);
    };

    const navigateBack = () => {
        let path = '/login';
        history.push(path);
    };

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
    const handleToggleConfirmPassword = () => setShowConfirmPassword(showConfirmPassword => !showConfirmPassword);
    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <>
            {!loading && (<>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper} style={{ marginLeft: "29" }}>
                    <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                    <h3 className="text-black text-center">Reset Password</h3>

                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                                value={formFields.password}
                                onChange={onChange}
                                onKeyDown={onKeyPress}
                                fullWidth
                                margin="dense"
                                required
                                id="password"
                                autoComplete="password"
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                placeholder="Password"
                                autoFocus={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleTogglePassword}
                                        >
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        {/*{formFields.passwordLength.length > 0 && (<Alert severity="error">*/}
                        {/*    <div className="d-flex align-items-center align-content-center">*/}
                        {/*        <span>*/}
                        {/*            {formFields.passwordLength[0]} <br />*/}
                        {/*            {formFields.passwordLength[1]} <br />*/}
                        {/*            {formFields.passwordLength[2]} <br />*/}
                        {/*            {formFields.passwordLength[3]} <br />*/}
                        {/*            {formFields.passwordLength[4]}*/}
                        {/*        </span>*/}
                        {/*    </div>*/}
                        {/*</Alert>)}*/}

                        <FormControl fullWidth margin="dense" variant="outlined">
                            <InputLabel htmlFor="password">Confirm Password</InputLabel>
                            <OutlinedInput
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                fullWidth
                                margin="dense"
                                required
                                id="confirmPassword"
                                autoComplete="password"
                                label='Confirm Password'
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleToggleConfirmPassword}
                                        >
                                            {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {/*                        {formFields.errorPasswordMessage && (<Alert severity='error'>{formFields.errorPasswordMessage}</Alert>)}*/}

                        <PasswordChecklist
                            className="text-black font-size-sm mt-1"
                            rules={["minLength", "capital", "lowercase", "number", "specialChar", "match"]}
                            minLength={6}
                            value={formFields.password}
                            valueAgain={formFields.confirmPassword}
                            messages={{
                                minLength: "Min 6 Characters",
                                capital: "One Uppercase Letter",
                                lowercase: "One Lowercase Letter",
                                number: "One Number",
                                specialChar: "One Special Character",
                                match: "Passwords Must Match",
                            }}
                            iconComponents={{
                                ValidIcon: <FaCheckCircle className="text-success mr-2" size={22} />,
                                InvalidIcon: <MdCancel className="text-danger mr-2" size={22} />,
                            }}
                        />

                        <Button
                            className="btn-primary p-3 mt-4 text-white text-capitalize"
                            type="submit"
                            onClick={onSubmit}>
                            Submit
                        </Button>
                        <Button
                            variant="contained" className="btn-primary p-3 mt-2 text-white text-capitalize"
                            style={{background: '#2b2b2b'}}
                            type="submit"
                            onClick={navigateBack}>
                            Cancel
                        </Button>
                    </div>
                </Container>
            </>)
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (userId: string, tokenId: string, password: string) => dispatch(handleSubmit(userId, tokenId, password)),
        resetPasswordResetState_d: () => dispatch(resetPasswordResetState())
    }
}

const mapStateToProps = (state: any) => ({
    error: state.resetPasswordCard.error,
    errorText: state.resetPasswordCard.errorText,
    success: state.resetPasswordCard.success,
    token: state.resetPasswordCard.token,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCard)
