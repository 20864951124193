import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const CLEAR = 'CLEAR';
const FETCH_ACTIONS = 'FETCH_ACTIONS';
const FETCH_ACTION = 'FETCH_ACTION';
const FETCH_ENTITY_UPSERT_REQUESTS = 'FETCH_ENTITY_UPSERT_REQUESTS';
const FETCH_IMAGE_UPSERT_REQUESTS = 'FETCH_IMAGE_UPSERT_REQUESTS';
const FETCH_WEBHOOK_REQUESTS = 'FETCH_WEBHOOK_REQUESTS';
const FETCH_CONFIG = 'FETCH_CONFIG';
const FETCH_USERS = 'FETCH_USERS';

const initialState = {
    actions: [],
    entityUpsertRequests: [],
    imageUpsertRequests: [],
    webhookRequests: [],

    config: undefined,
    users: [],
    action: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case FETCH_ACTIONS: return { ...state, actions: action.payload };
        case FETCH_ACTION: return { ...state, action: action.payload };
        case FETCH_ENTITY_UPSERT_REQUESTS: return { ...state, entityUpsertRequests: action.payload };
        case FETCH_IMAGE_UPSERT_REQUESTS: return { ...state, imageUpsertRequests: action.payload };
        case FETCH_WEBHOOK_REQUESTS: return { ...state, webhookRequests: action.payload };
        case FETCH_CONFIG: return { ...state, config: action.payload };
        case FETCH_USERS: return { ...state, users: action.payload };

        case CLEAR: return { ...initialState };
        default: return state;
    }
}

export const clear = () => {
    return { type: CLEAR }
}

export const updateAction = (action) => {
    return { type: FETCH_ACTION, payload: action }
}

export const updateActions = (actions) => {
    return { type: FETCH_ACTIONS, payload: actions }
}

export const updateEntityUpsertRequests = (requests) => {
    return { type: FETCH_ENTITY_UPSERT_REQUESTS, payload: requests }
}

export const updateImageUpsertRequests = (requests) => {
    return { type: FETCH_IMAGE_UPSERT_REQUESTS, payload: requests }
}

export const updateWebhookRequests = (requests) => {
    return { type: FETCH_WEBHOOK_REQUESTS, payload: requests }
}

export const updateConfig = (config) => {
    return { type: FETCH_CONFIG, payload: config }
}

export const updateUsers = (users) => {
    return { type: FETCH_USERS, payload: users }
}

export const handleFetchAction = (id: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveAction, { id }, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateAction(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchActions = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveActions, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateActions(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchEntityUpsertRequests = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveEntityUpsertRequests, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateEntityUpsertRequests(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchImageUpsertRequests = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveImageUpsertRequests, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateImageUpsertRequests(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchWebhookRequests = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveWebhookRequests, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateWebhookRequests(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchIntegrationConfig = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveIntegrationConfig, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateConfig(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleFetchIntegrationUsers = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.integration.retrieveIntegrationUsers, null, null)
            .then(function (resp) {
                if (resp.success) dispatch(updateUsers(resp.response));
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleUpsertIntegrationConfig = (config, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.integration.upsertIntegrationConfig, config)
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleGenerateIntegrationToken = (userId: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.integration.generateIntegrationToken, { userId })
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleAddIntegrationUser = (username: string, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.integration.addIntegrationUser, { username })
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleManualOverride = (data: any, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.integration.manualOverride, data)
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleManualSync = (data: any, onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.integration.manualSynchronize, data)
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}