import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BiddingCalculationsAddEdit from "@/pages/BiddingCalculations/BiddingCalculationsAddEdit";

export default function BiddingCalculationsAddDialog({ isDialogOpened, handleCloseDialog }) {

    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps['maxWidth']>('lg');

    const handleClose = (data?: any) => {
        handleCloseDialog(data);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                onClose={() => handleClose()}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Bidding Calculations</DialogTitle>
                <DialogContent>
                    <BiddingCalculationsAddEdit isDialog={true} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}