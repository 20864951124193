import React, { useState, useEffect, useCallback } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import ImageUploader, { Image } from "@/components/ImageUploader/ImageUploader";
import FloatyButtonGroup from "@/components/FloatyButtonGroup/FloatyButtonGroup";
import {
    handleAuctionAdd, handleAuctionUpdate, clearAuction, clearApiResponses, clearAuctionImages,
    handleAuctionFetch, handleImageFetch, handleAuctionImageDelete, clearLots, auctionImageFetchSuccess,
    handleLotsFetchWithPagination
} from './store/Auctions';
import { handleLotTypesFetch } from '../LotTypes/store/LotTypes';
import {
    setLotTypeId,
    clearLotTypeId,
    setAuction,
    handleLotDelete,
    setLot,
    clearLot,
    handleLotsFetch as handleIndividualLotsFetch,
    clearLots as clearIndividualLots,
    handleIndividualLotsLinking,
    handleIndividualLotsUnlinking
} from '../Lots/store/Lots';
import { AuctionTypeEnum, AuctionTypesList } from '@/helpers/AuctionTypeEnum';
import {
    Card, Button, Grid, MenuItem, FormControlLabel, Switch, IconButton, List, ListItem, FormLabel, InputAdornment, Tooltip, Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Divider,
    CircularProgress
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FaSave, FaPlus, FaBackspace, FaLink, FaUnlink, FaArrowsAltV, FaRedo, FaFileUpload } from 'react-icons/fa';
import { NestedAccordionGrid, CustomData, Cell, Header, Row, VenueData, KeyValue, NestedGridDataModel } from '@/components/NestedAccordionGrid';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog'
import AddIcon from "@mui/icons-material/Add";
import LotTypeDialog from "@/components/Modal/LotTypeDialog";
import VenueAddDialog from "@/components/Modal/VenueAddDialog";
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import { handleVenuesFetch } from "../Venue/store/Venue";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ns from '@/helpers/NotificationService';
import DocumentTemplateAddDialog from '@/components/Modal/DocumentTemplateAddDialog'
import {
    clearDocuments,
    handleRegistrationDocumentDelete,
    handleRetrieveRegistrationDocuments
} from "@/pages/Documents/store/Documents";
import { DocumentTypeEnum } from '@/helpers/DocumentTypeEnum';
import FileUploader from "@/components/FileUploader/FileUploader";
import { handleContactLookupFetch } from '@/pages/Contacts/store/Contacts';
import ContactAddDialog from "@/components/Modal/ContactAddDialog";
import { forEach } from 'lodash';
import SetObjectValue from 'lodash/set';
import { validateYoutubeUrl, validateMultipleWordsInString } from '../../helpers/Utils';
import Chip from '@/components/Chip/Chip'
import { MdClose, MdSearch } from "react-icons/md";
import { reset as resetVenueState } from '@/pages/Venue/store/Venue';
import BankDetailsAddDialog from '../../components/Modal/BankDetailsAddDialog';
import BiddingCalculationsAddDialog from '../../components/Modal/BiddingCalculationsAddDialog';
import { retrieveBankDetailsLookup } from '../BankDetails/store/BankDetails';
import { handleFetchBiddingCalculationsLookup } from '../BiddingCalculations/store/BiddingCalculations';
import { downloadFile } from "@/pages/Documents/store/Documents";
import IncrementTableAddDialog from '../../components/Modal/IncrementTableAddDialog';
import { handleIncrementTablesLookupFetch } from '../IncrementTable/store/IncrementTable';
import TextField from '../../components/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { zeroToHundredPattern } from '../../helpers/Regex';

const useStyles = makeStyles()({
    "@global": {
        ".MuiPickersToolbarText-toolbarTxt": {
            fontSize: '30px',
        },
    },
    root: {}
});
interface Props {
    handleDocumentDelete_d: (documentId: string) => void,
    handleRetrieveRegistrationDocuments_d: (auctionId: string) => void,
    handleAuctionAdd_d: (auction: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => void,
    handleAuctionUpdate_d: (formFields: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => void,
    handleIndividualLotsLinking_d: (auctionId, lotIds: string[], onCompletedCallback: (res: any) => void) => void,
    handleIndividualLotsUnlinking_d: (id: string, onCompletedCallback: (res: any) => void) => void,
    handleLotsFetchWithPagination_d: (auctionId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void,
    handleImageFetch_d: (id: string) => void,
    handleAuctionImageDelete_d: (id: string) => void,
    handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) => void,
    clearDocuments_d: () => void,
    clearAuction_d: () => void,
    clearAuctionImages_d: () => void,
    setAuctionImages_d: (images: any) => void,
    clearLots_d: () => void,
    setLot_d: (lot: any) => void,
    setAuction_d: (auction: any) => void,
    setLotTypeId_d: (id: any) => void,
    clearLotTypeId_d: () => void,
    handleLotDelete_d: (id: string, onCompletedCallback?: (result: any) => void) => void,
    clearResponses_d: () => void,
    handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => void,
    clearLot_d: () => void,
    handleVenuesFetch_d: (onCompletedCallback?: (res: any) => void) => void,
    auctionImage: Image[],
    error: boolean,
    errorText: string,
    lots: any,
    lotTypesData: any,
    auctionId: any,
    auction: any,
    lotsReload: boolean,
    selectLotTypeAuction: any,
    selectVenueAuction?: any,
    venuesData: any,
    //selectNewVenueAuction: any,
    loading: boolean,
    settings: any,
    documents: any,
    contacts: any[],
    handleContactsFetch_d: (onCompletedCallback?: (res: any) => void) => void,
    individualLots: any[],
    handleIndividualLotsFetch_d: (searchString: string | null, onCompletedCallback?: (res: any) => void) => void
    clearIndividualLots_d: () => void,
    pagination: any,
    resetVenueState_d: () => void,
    bankDetailsLookup: any[],
    biddingCalculationsLookup: any[],
    incrementTableLookup: any[],
    retrieveBankDetailsLookup_d: (onCompletedCallback: (res: any) => void) => void,
    retrieveBiddingCalculationsLookup_d: (onCompletedCallback: (res: any) => void) => void,
    retrieveIncrementTableLookup_d: (onCompletedCallback: (res: any) => void) => void,
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
}

const AuctionsEditAdd: React.FC<Props> = props => {
    const { classes, cx } = useStyles();
    const {
        handleDocumentDelete_d,
        handleRetrieveRegistrationDocuments_d,
        handleAuctionAdd_d,
        handleAuctionUpdate_d,
        handleLotsFetchWithPagination_d,
        handleLotTypesFetch_d,
        handleImageFetch_d,
        handleAuctionImageDelete_d,
        setAuction_d,
        clearDocuments_d,
        clearAuctionImages_d,
        clearAuction_d,
        setLotTypeId_d,
        clearLotTypeId_d,
        handleLotDelete_d,
        clearResponses_d,
        handleAuctionFetch_d,
        clearLot_d,
        clearLots_d,
        handleVenuesFetch_d,
        auctionImage,
        lots,
        lotTypesData,
        auctionId,
        auction,
        lotsReload,
        selectLotTypeAuction,
        selectVenueAuction,
        venuesData,
        //selectNewVenueAuction,
        settings,
        error,
        errorText,
        documents,
        contacts,
        handleContactsFetch_d,
        individualLots,
        handleIndividualLotsFetch_d,
        clearIndividualLots_d,
        handleIndividualLotsLinking_d,
        handleIndividualLotsUnlinking_d,
        pagination,
        resetVenueState_d,
        bankDetailsLookup,
        biddingCalculationsLookup,
        incrementTableLookup,
        retrieveBankDetailsLookup_d,
        retrieveBiddingCalculationsLookup_d,
        retrieveIncrementTableLookup_d,
        downloadFile_d
    } = props;

    const emptyState = {
        id: '',
        name: '',
        description: '',
        depositAmount: 0,
        lotTypeId: '',
        venueId: '',
        auctionType: 0,
        increment: 0,
        stcStatus: true,
        defaultShowReserve: true,
        defaultShowPaddleNumber: true,
        defaultWatermarkImages: false,
        defaultApplyInvoicing: false,
        commission: '0',
        startTime: new Date(),
        finishTime: new Date(),
        isActive: false,
        hours: 0,
        minutes: 0,
        seconds: 0,
        contactId: '',
        streamUrl: '',
        bankDetailsId: '',
        defaultBiddingCalculationId: '',
        defaultIncrementTableId: '',
        rollingTimerHours: 0,
        rollingTimerMinutes: 0,
        rollingTimerSeconds: 0,
        timeWindowHours: 0,
        timeWindowMinutes: 0,
        timeWindowSeconds: 0,
    }

    const [documentTemplateModal, setDocumentTemplateModal] = React.useState<any>({
        open: false,
        id: ''
    })
    const [auctionDocuments, setAuctionDocuments] = React.useState<any>([] as any);
    const [hideFieldsForAuctionType, setHideFieldsForAuctionType] = React.useState(false);
    const [isVenueOpen, setIsVenueOpen] = useState(false);
    const [loadingIndividualLots, setLoadingIndividualLots] = useState(false);
    const [loadingReorder, setLoadingReorder] = useState(false);
    const [showCreateAnother, setShowCreateAnother] = React.useState(false);
    const [formFields, setFormFields] = React.useReducer((state, action) => {
        let tempData = { ...state };

        switch (action.type) {
            case 'set': return action.data;
            case 'update':
                SetObjectValue(tempData, action.path, action.value);
                break;
        }
        return tempData;

    }, emptyState);
    const [lotsDisplayModel, setLotsDisplayModel] = React.useState<NestedGridDataModel>();
    const [lotTypes, setLotTypes] = React.useState<any>([]);
    const [imagesTabError, setImagesTabError] = React.useState<boolean>(false);
    const [auctionInformationTabError, setAuctionInformationTabError] = React.useState<boolean>(false);
    const [deleteModal, setDeleteModal] = React.useState<any>({
        open: false,
        id: ''
    });
    const [isLotTypeOpen, setIsLotTypeOpen] = useState(false);
    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] = React.useState<any>({
        name: false,
        auctionType: false,
        lotTypeId: false,
        contactId: false,
        bankDetailsId: false
    });
    const [activeTab, setActiveTab] = React.useState();
    const [heroFiles, setHeroFiles] = React.useState<Image[]>([]);
    const history = useHistory()
    const auctionIdParam: any = new URLSearchParams(history.location.search).get("auctionId");
    const tabIndex = new URLSearchParams(history.location.search).get("tabIndex");
    const backRedirect = new URLSearchParams(history.location.search).get("backRedirect");
    const [isContactAddOpen, setIsContactAddOpen] = React.useState(false);
    const [isBankDetailsAddOpen, setIsBankDetailsAddOpen] = React.useState(false);
    const [isBiddingCalculationsAddOpen, setIsBiddingCalculationsAddOpen] = React.useState(false);
    const [isIncrementTableAddOpen, setIsIncrementTableAddOpen] = React.useState(false);

    const [imagesToBeDeleted, setImagesToBeDeleted] = useState<any[]>([]);
    const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<any[]>([]);
    const [submittedAlready, setSubmittedAlready] = useState<boolean>(false);

    const [selectedFilters, setSelectedFilters] = useState<any>();
    const [loadingLots, setLoadingLots] = useState<boolean>(false);

    const [loadingStates, setLoadingStates] = useState({
        venues: true,
        bankDetails: true,
        lotTypes: true,
        contacts: true,
        auctionTypes: true,
        biddingCalculations: true,
        incrementTables: true
    })

    const [commissionValid, setCommissionValid] = useState(false);

    const statusBadge = (active: boolean) => {
        switch (active) {
            case true: {
                return <div className="badge badge-success badge-pill text-capitalize">Yes</div>
            }
            case false: {
                return <div className="badge badge-danger badge-pill text-capitalize">No</div>
            }
        }
    }

    useEffect(() => {
        return (() => {
            clearIndividualLots_d();
            ns.resetDialog();
        });
    }, [])

    //React.useEffect(() => {
    //    if (selectNewVenueAuction)
    //        setFormFields({ type: 'update', path: 'venueId', value: selectNewVenueAuction });
    //}, [selectNewVenueAuction])

    React.useEffect(() => {
        resetVenueState_d();
        handleLotTypesFetch_d(() => {
            setLoadingStates(state => ({ ...state, lotTypes: false }));
        });
        handleVenuesFetch_d(() => {
            setLoadingStates(state => ({ ...state, venues: false }));
        });
        handleContactsFetch_d(() => {
            setLoadingStates(state => ({ ...state, contacts: false }));
        });
        retrieveBankDetailsLookup_d(() => {
            setLoadingStates(state => ({ ...state, bankDetails: false }));
        });
        retrieveBiddingCalculationsLookup_d(() => { setLoadingStates(state => ({ ...state, biddingCalculations: false })); });
        retrieveIncrementTableLookup_d(() => { setLoadingStates(state => ({ ...state, incrementTables: false })); });

        if (location) {
            if (auctionIdParam !== '' && auctionIdParam !== null) {
                showProgressSpinner({ description: "Retrieving " + settings.AuctionName + "..." });
                handleAuctionFetch_d(auctionIdParam)
                refreshLotsList(auctionIdParam, selectedFilters, pagination);
                clearLot_d();
            }
        }
    }, [auctionIdParam])

    React.useEffect(() => {
        if (tabIndex === null)
            toggle('0');
        else
            toggle(tabIndex);
    }, [tabIndex])

    React.useEffect(() => {
        if (auctionId && auctionId !== '') {
            if (formFields)
                formFields['id'] = auctionId

            if (auctionIdParam === null)
                navAddEdit(auctionId, Number(tabIndex))

            refreshLotsList(auctionId, selectedFilters, pagination);
            clearLot_d()
        } else {
            clearAuctionImages_d()
            clearLots_d()
        }
    }, [auctionId])

    useEffect(() => {
        if (lots === undefined) setLotsDisplayModel(undefined);
    }, [lots])

    useEffect(() => {
        if (formFields.id) {
            showProgressSpinner();
            handleLotsFetchWithPagination_d(auctionId);
            clearLot_d();
        }
    }, [lotsReload])

    useEffect(() => {
        if (auction) {
            const time = getDefaultTimes(auction?.defaultBidExtension);
            const timeWindow = getDefaultTimes(auction?.defaultBidExtensionWindow);
            const rollingTime = getDefaultTimes(auction?.defaultRollingTimerExtention);

            const newAuction = {
                id: auction.id,
                depositAmount: auction.depositAmount ? auction.depositAmount : 0,
                name: auction.name,
                description: auction.description,
                lotTypeId: auction.defaultLotTypeId,
                venueId: auction.defaultVenueId,
                auctionType: auction.auctionType,
                streamUrl: auction.streamUrl,
                increment: auction.defaultMinimumIncrement ? auction.defaultMinimumIncrement : 0,
                stcStatus: auction.defaultSTCStatus,
                defaultShowReserve: !!auction.defaultShowReserve,
                defaultShowPaddleNumber: !!auction.defaultShowPaddleNumber,
                defaultWatermarkImages: !!auction.defaultWatermarkImages,
                defaultApplyInvoicing: !!auction.defaultApplyInvoicing,
                commission: auction.defaultCommission ? auction.defaultCommission : 0,
                startTime: auction.startDateTimeAt,
                finishTime: auction.endDateTimeAt,
                isActive: !!auction.isActive,
                hours: time?.hours ?? 0,
                minutes: time?.minutes ?? 0,
                seconds: time?.seconds ?? 0,
                success: false,
                contactId: auction.defaultContactId,
                bankDetailsId: auction.bankDetailsId ?? '',
                defaultBiddingCalculationId: auction.defaultBiddingCalculationId ?? '',
                defaultIncrementTableId: auction.defaultIncrementTableId ?? '',
                rollingTimerHours: rollingTime?.hours ?? 0,
                rollingTimerMinutes: rollingTime?.minutes ?? 0,
                rollingTimerSeconds: rollingTime?.seconds ?? 0,
                timeWindowHours: timeWindow?.hours ?? 0,
                timeWindowMinutes: timeWindow?.minutes ?? 0,
                timeWindowSeconds: timeWindow?.seconds ?? 0
            }

            setFormFields({ type: 'set', data: newAuction });
            handleImageFetch_d(auction.id)
            handleRetrieveRegistrationDocuments_d(auction.id);

            hideFieldsForAuctionTypeFunc(auction.auctionType);
        }
    }, [auction])

    useEffect(() => {
        setLotTypes(lotTypesData)
    }, [lotTypesData])

    useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];

        headers = [
            { title: "Number", align: "inherit" },
            { title: "Unique Code", align: "inherit" },
            { title: "Name", align: "inherit" },
            { title: "Description", align: "inherit" },
            { title: "Available", align: "inherit", canFilter: true },
            { title: "Sold", align: "inherit", canFilter: true }
        ];

        if (lots) {
            lots.forEach((lot) => {
                let custom: KeyValue[] = [];

                lot?.dataCaptured?.additionalProperties?.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        custom.push({ ...x, key: x.name });
                });

                let lotName = lot.name?.substring(0, 35);
                if (lot.name.length > 35) lotName += "...";

                let lotDescription = lot.description?.substring(0, 35);
                if (lot.description.length > 35) lotDescription += "...";

                const cells: Cell[] = [
                    { text: lot.number, align: "inherit" },
                    { text: lot.uniqueCode, align: "inherit" },
                    { text: lotName, align: "inherit" },
                    { text: lotDescription, align: "inherit" },
                    { text: lot.isActive ? 'Available' : 'Unavailable', align: "inherit", element: statusBadge(lot.isActive) },
                    { text: lot.sold ? 'Sold' : 'Not Sold', align: "inherit", element: statusBadge(lot.sold) },
                ];

                const customData: CustomData[] = [
                    {
                        title: "Custom Data", values: custom
                    },
                    {
                        title: `${settings.LotName} Data`, values: [
                            { key: `${settings.LotName} Type`, value: lot.lotType.name },
                            { key: "STC Status", value: lot.stcStatus == '0' ? 'No' : 'Yes' },
                            { key: `Registration Deposit Amount (${settings.CurrencySymbol})`, value: lot.depositAmount },
                            { key: `Default Minimum Increment (${settings.CurrencySymbol})`, value: lot.defaultMinimumIncrement },
                            { key: "Buyer's Commission %", value: lot.commission },
                            { key: `Opening Bid (${settings.CurrencySymbol})`, value: lot.startingPrice },
                            { key: `Reserve Price (${settings.CurrencySymbol})`, value: lot.reservePrice },
                            { key: "SKU Number", value: lot.skuNumber },
                        ]
                    }
                ];

                rows.push({
                    id: lot.id,
                    cells: cells,
                    customData: customData,
                    actionButtons: [
                        <Tooltip title={`Unlink ${settings.LotName} from ${settings.AuctionName}`}>
                            <Button onClick={() => {

                                ns.showDialog({
                                    title: `Confirm Unlink ${settings.LotName}`,
                                    content: <div>
                                        <p>Are you sure you want to unlink the {settings.LotName}?</p>
                                    </div>,
                                    onSubmit: () => {
                                        showProgressSpinner({ description: `Unlinking ${settings.LotName}s from ${settings.AuctionName}...` })
                                        handleIndividualLotsUnlinking_d(lot.id, (res: any) => {
                                            hideProgressSpinner();
                                            if (res.success) {
                                                clearIndividualLots_d();
                                                setLinks([]);
                                                handleLotsFetchWithPagination_d(lot.auctionId, selectedFilters, pagination, (res: any) => {

                                                })

                                                ns.success(`Successfully unlinked the ${settings.LotName}`);
                                            }
                                            else {
                                                ns.error(`Failed to unlink the ${settings.LotName}s`);
                                            }
                                        })
                                    },
                                    onCancel: () => { },
                                    locales: {
                                        submitText: 'Ok'
                                    }
                                });

                            }} size="small" className="btn-danger mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                <FaUnlink />
                            </Button>
                        </Tooltip>]
                });
            });
        }

        model = {
            headers: headers,
            rows: rows,
            headerButtons: headerActionbuttons(hideFieldsForAuctionType)
        };

        setLotsDisplayModel(model);
        hideProgressSpinner();
    }, [lots, hideFieldsForAuctionType])

    const navigateToBulkUploads = () => {
        history.push(`/BulkUploads/${auctionId || auctionIdParam}`);
    }

    const headerActionbuttons = (hideFieldsForAuctionType) => {
        return [
            !hideFieldsForAuctionType ?
                <Tooltip title="Bulk Uploader" placement="bottom">
                    <Button onClick={() => navigateToBulkUploads()} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                        <FaFileUpload />
                    </Button>
                </Tooltip> : null,
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={() => refreshLotsList(auctionIdParam, selectedFilters, pagination)} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        ].filter(x => x);
    }

    React.useEffect(() => {
        if (documents !== undefined) {
            const newDocumentsData: any = documents.map(doc => {
                return {
                    documentId: doc.id,
                    documentUrl: doc.documentUrl === undefined || doc.documentUrl === null ? '' : doc.documentUrl,
                    documentTemplateId: doc.documentTemplate.id,
                    documentTemplateName: doc.documentTemplate.name,
                    documentTemplate: doc.documentTemplate,
                    fileUploadData: ''
                };
            });

            setAuctionDocuments(newDocumentsData);
        }
    }, [documents]);

    React.useEffect(() => {
        if (error) {
            hideProgressSpinner()
            clearResponses_d();
            ns.error(errorText);
        }
    }, [error])

    React.useEffect(() => {
        if (selectLotTypeAuction)
            setFormFields({ type: 'update', path: 'lotTypeId', value: selectLotTypeAuction });

    }, [selectLotTypeAuction])

    React.useEffect(() => {
        if (selectVenueAuction)
            setFormFields({ type: 'update', path: 'venueId', value: selectVenueAuction });
    }, [selectVenueAuction])

    useEffect(() => {
        if (submittedAlready) {
            validateAll();
        }
    }, [formFields]);

    const refreshLotsList = (auctionId: string, filters?: any, pagination?: any) => {
        setLoadingLots(true);
        handleLotsFetchWithPagination_d(auctionId, filters, pagination, (res) => {
            setLoadingLots(false);
        });
    }

    const hideFieldsForAuctionTypeFunc = (selectedAuctionType: number) => {
        if (Number(selectedAuctionType) === Number(AuctionTypeEnum.Tender.toString()) || Number(selectedAuctionType) === Number(AuctionTypeEnum.Streamed.toString())) {
            setHideFieldsForAuctionType(true);
        } else {
            setHideFieldsForAuctionType(false);
        }
    }

    const getDefaultTimes = (seconds: number | undefined | null) => {
        if (seconds != undefined && seconds != null) {
            const time = {
                hours: 0,
                minutes: 0,
                seconds: 0
            }

            time.hours = ~~(seconds / 3600);
            time.minutes = ~~((seconds % 3600) / 60);
            time.seconds = ~~seconds % 60;

            return time
        }
        return undefined;
    }

    const navAddEdit = (auctionId: string, tabIndex: number) => {
        let path = '/AuctionEdit'
        if (auctionId !== '') {
            path += '?auctionId=' + auctionId
            if (tabIndex !== undefined && tabIndex !== null)
                path += '&tabIndex=' + tabIndex

            history.replace(path);
        } else if (tabIndex !== undefined && tabIndex !== null) {
            path += '?tabIndex=' + tabIndex
            history.replace(path);
        }
    }

    const newHeroImageFilesAdded = (images: Image[]) => {
        setHeroFiles(images);
    };

    const deleteImage = (imageId: string) => {
        let temp = [...imagesToBeDeleted];
        if (imageId && imageId !== '')
            temp.push(imageId);
        setImagesToBeDeleted(temp);
    };

    const deleteDocument = (documentId: string) => {
        let temp = [...documentsToBeDeleted];
        if (documentId && documentId !== '')
            temp.push(documentId);
        setDocumentsToBeDeleted(temp);
    };

    const auctionTypes = AuctionTypesList();

    const onChange = (e: any) => {
        setFormFields({ type: 'update', path: e.target.id, value: e.target.value });
    }

    const onIncrementChange = (e: any) => {
        setFormFields({ type: 'update', path: e.target.id, value: e.target.value });

        if (e.target.value && e.target.value > 0) {
            setFormFields({ type: 'update', path: 'defaultIncrementTableId', value: '' });
        }
    }

    const onChangeCommission = (e: any) => {
        if (e.target.value !== "") {
            var numericValue = Number(e.target.value)
            var valid = numericValue >= 0 && numericValue <= 100

            if (valid)
                setFormFields({ type: 'update', path: e.target.id, value: e.target.value });
            else
                setFormFields({ type: 'update', path: e.target.id, value: formFields.commission });
        }
        else {
            setFormFields({ type: 'update', path: e.target.id, value: '' });
        }
    }

    const onChangeNumeric = (e: any) => {
        if (e.target.value !== "") {
            var numericValue = Number(e.target.value)
            var type = e.target.id
            var valid = false

            switch (type) {
                case "timeWindowHours":
                case "rollingTimerHours":
                case "hours":
                    valid = numericValue > -1 && numericValue <= 72
                    break;
                case "timeWindowMinutes":
                case "rollingTimerMinutes":
                case "minutes":
                    valid = numericValue > -1 && numericValue <= 59
                    break;
                case "timeWindowSeconds":
                case "rollingTimerSeconds":
                case "seconds":
                    valid = numericValue > -1 && numericValue <= 59
                    break;
            }

            if (valid)
                setFormFields({ type: 'update', path: e.target.id, value: e.target.value });
            else
                setFormFields({ type: 'update', path: e.target.id, value: 0 });
        }
    }

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean; }; }) => {
        setFormFields({ type: 'update', path: e.target.id, value: e.target.checked });
    }

    const onAuctionTypeSelectChange = (e: any) => {
        hideFieldsForAuctionTypeFunc(e.target.value);
        setFormFields({ type: 'update', path: 'auctionType', value: e.target.value });
    };

    const onLotTypeSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'lotTypeId', value: e.target.value });
    };

    const onVenueSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'venueId', value: e.target.value });
    };

    const onContactSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'contactId', value: e.target.value });
    };

    const onBankDetailsSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'bankDetailsId', value: e.target.value });
    };

    const onDefaultBiddingCalculationSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'defaultBiddingCalculationId', value: e.target.value });
    };

    const onDefaultIncrementTableSelectChange = (e: any) => {
        setFormFields({ type: 'update', path: 'defaultIncrementTableId', value: e.target.value });

        if (e.target.value) {
            setFormFields({ type: 'update', path: 'increment', value: 0 });
        }
    };

    const onStartTimeChange = (date: any) => {
        setFormFields({ type: 'update', path: 'startTime', value: date });
    }

    const onFinishTimeChange = (date: any) => {
        setFormFields({ type: 'update', path: 'finishTime', value: date });
    }

    const validateAll = () => {
        const isValid = validateRequiredFields();
        const dateValid = validateDates();

        if (isValid && dateValid) {
            setAuctionInformationTabError(false);
            setImagesTabError(false);
        }
    }

    const onSubmit = () => {
        const commValid = validateCommission();
        const isValid = validateRequiredFields();
        const dateValid = validateDates();
        const incrementsValid = validateIncrements();

        let isStreamUrlValid = true;
        if (formFields.auctionType === 1)
            isStreamUrlValid = validateStreamUrlField(undefined, formFields.streamUrl);

        if (isValid && dateValid && incrementsValid && isStreamUrlValid && !commValid) {
            setAuctionInformationTabError(false);
            setImagesTabError(false);

            const isAuctionId = new URLSearchParams(history.location.search).get("auctionId")
            showProgressSpinner({ description: "Saving " + settings.AuctionName + "..." });

            let temp = [...imagesToBeDeleted];
            forEach(imagesToBeDeleted, image => {
                if (image && image !== '') {
                    handleAuctionImageDelete_d(image);
                }
                temp.shift();
            });
            setImagesToBeDeleted(temp);

            temp = [...documentsToBeDeleted];
            forEach(documentsToBeDeleted, document => {
                if (document && document !== '') {
                    handleDocumentDelete_d(document);
                }
                temp.shift();
            });
            setDocumentsToBeDeleted(temp);

            if (isAuctionId && isAuctionId !== '') {
                handleAuctionUpdate_d(formFields, heroFiles, auctionDocuments, (res) => {
                    if (res.success) {
                        clearResponses_d();
                        ns.success(settings.AuctionName + " Successfully Updated")
                        setShowCreateAnother(true);
                        handleAuctionFetch_d(res.response, (res: any) => {
                            hideProgressSpinner();
                        });
                    }
                    else {
                        ns.error(settings.AuctionName + res.error);
                        hideProgressSpinner();
                    }
                })
            } else {
                handleAuctionAdd_d(formFields, heroFiles, auctionDocuments, (res) => {
                    if (res.success) {
                        ns.success(settings.AuctionName + " successfully saved");
                        let redirect = `/AuctionEdit?auctionId=${res.response}`;
                        history.replace(redirect);
                    }
                    else {
                        ns.error(settings.AuctionName + res.error);
                        hideProgressSpinner();
                    }
                })
            }
        } else {
            hideProgressSpinner();
            if (!isValid) {
                ns.error("Please fill in all required fields");
            } else {
                if (!dateValid) {
                    ns.error("Please ensure that the Start Time is less than End Time");
                } else {
                    if (!isStreamUrlValid) {
                        ns.error(`Please make sure that the YouTube Stream URL is a valid`);
                    } else {
                        if (!incrementsValid) {
                            ns.error(`Please ensure to either use a default increment table or specify an actual default increment`);
                        } else {
                            if (commValid) {
                                ns.error("Please ensure to capture an appropriate buyer's commission");
                            }
                        }
                    }
                }
            }
        }
        setSubmittedAlready(true);
    }

    const onCancel = () => {
        clearAuction_d()
        clearDocuments_d()
        clearResponses_d()
        if (backRedirect)
            history.push(`/${backRedirect}`);
        else
            history.push('/Auctions');
    }

    const onAdd = () => {
        clearLot_d();
        clearDocuments_d();
        let currentAuctionInfo = formFields;
        setAuction_d(currentAuctionInfo)

        if (formFields.lotTypeId !== null) {
            setLotTypeId_d(formFields.lotTypeId)
            lotNavAddEdit('');
        } else {
            clearLotTypeId_d()
            lotNavAddEdit('');
        }
    }

    const onLotEdit = (id: any) => {
        clearLot_d();
        clearDocuments_d();
        let currentAuctionInfo = formFields;
        setAuction_d(currentAuctionInfo)

        lotNavAddEdit(id);
    }

    const lotNavAddEdit = (lotId) => {
        let path = '/LotEdit'
        path += '?auctionId=' + auctionIdParam

        if (lotId && lotId !== '') {
            path += '&lotId=' + lotId
        }
        history.push(path);
    }

    const onLotDelete = (id: any) => {
        showDeleteModal(true, id);
    }

    //Modal
    const onDeleteModalClosed = () => {
        showDeleteModal(false, '');
    }

    const onDeleteModalOk = (id: string) => {
        showProgressSpinner({ description: "Deleting " + settings.LotName + "..." });
        handleLotDelete_d(id, (response) => {
            if (response.success) {
                ns.success("Lot deleted successfully");
                refreshLotsList(auctionIdParam, selectedFilters, pagination);
            }
            else {
                ns.error("Failed to delete the lot")
                hideProgressSpinner();
            }
        });
        showDeleteModal(false, "");
    }

    const showDeleteModal = (open: boolean, id: string) => {
        setDeleteModal({ ...deleteModal, open: open, id: id });
    }

    const onCancelDocumentTemplateModal = () => {
        setDocumentTemplateModal({ ...documentTemplateModal, open: false });
    }

    const onShowDocumentTemplateModal = () => {
        setDocumentTemplateModal({ ...documentTemplateModal, open: true });
    }

    const onAddDocumentTemplateModal = (selectedDocumentTemplates) => {
        let addingDocuments = selectedDocumentTemplates.map(doc => {
            return {
                documentId: '',
                documentUrl: '',
                documentTemplateId: doc.id,
                documentTemplateName: doc.name,
                documentTemplate: doc.documentTemplate,
                fileUploadData: ''
            };
        });

        setAuctionDocuments([...auctionDocuments, ...addingDocuments]);
        onCancelDocumentTemplateModal();
    }

    const validateCommission = () => {
        if ((formFields.commission) > 0) {
            if (!zeroToHundredPattern.test(formFields.commission)) {
                setCommissionValid(true);
                return true;
            } else {
                setCommissionValid(false);
                return false;
            }
        } else {
            setCommissionValid(false);
            return false;
        }

    }

    const validateField = (e) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e !== undefined && e?.target.id !== undefined)
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError })
    }

    const validateStreamUrlField = (e: any, streamUrl: string) => {
        if ((streamUrl?.length || 0) === 0)
            return true;

        let isValid = !validateMultipleWordsInString(streamUrl, "http");
        isValid = isValid ? validateYoutubeUrl(streamUrl) : isValid;

        if (e !== undefined && e.target.id !== undefined) {
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: !isValid });
        }

        return isValid;
    }

    React.useEffect(() => {
        handleHeroImageValidation()
    }, [heroFiles])

    const handleHeroImageValidation = () => {
        const hasHeroImage = heroFiles.findIndex(f => f.isDisplayImage);
        if (hasHeroImage >= -1) {
            setImagesTabError(false);
        }
    }

    const validateRequiredFields = () => {
        let isAllValid = true;
        setAuctionInformationTabError(false);

        let newState: any = {
            name: false,
            auctionType: false,
            lotTypeId: false,
            contactId: false
        }


        for (const [key, value] of Object.entries(newState)) {
            const formFieldsvalue = formFields[key];
            if (formFieldsvalue === undefined || formFieldsvalue === "" || formFieldsvalue === null) {
                isAllValid = false;
                newState[key] = true;
            }
        }

        if (formFields.defaultApplyInvoicing && (formFields.bankDetailsId === undefined || formFields.bankDetailsId === "" || formFields.bankDetailsId === null)) {
            newState.bankDetailsId = true;
            isAllValid = false;
        }
        else {
            newState.bankDetailsId = false;
        }

        if (isAllValid) {
            setAuctionInformationTabError(false);
        } else {
            setAuctionInformationTabError(true);
        }

        const hasHeroImage = heroFiles.filter(f => f.isDisplayImage)[0]
        if (hasHeroImage === undefined) {
            isAllValid = false;
            setImagesTabError(true);
        }

        setFormFieldsValidationShowErrors(newState)

        return isAllValid;
    }

    const validateIncrements = () => {
        const incrementTable = formFields.defaultIncrementTableId;
        const increment = formFields.increment;

        if (!hideFieldsForAuctionType) {
            if ((incrementTable === '' || incrementTable === undefined || incrementTable === null) &&
                (increment === '' || increment === undefined || increment === null || Number.parseInt(increment.toString()) === 0)) {
                return false;
            }
        }

        return true;
    }

    const validateDates = () => {
        let areDatesValid = true;
        const startDate = new Date(formFields.startTime).getTime();
        const finishDate = new Date(formFields.finishTime).getTime();

        if (Number(startDate) > 0 && Number(finishDate) > 0) {
            if (Number(finishDate) <= Number(startDate)) {
                setAuctionInformationTabError(true);
                areDatesValid = false;
            }
        } else {
            setAuctionInformationTabError(true);
            areDatesValid = false;
        }

        return areDatesValid;
    }

    const handleLotTypeOpen = () => {
        setIsLotTypeOpen(!isLotTypeOpen);
    };

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);

        if (auctionIdParam !== '' && auctionIdParam !== null) {
            let redirect = `/AuctionEdit?auctionId=${auctionIdParam}&tabIndex=${tab}`
            if (backRedirect) redirect += `&backRedirect=${backRedirect}`;
            history.replace(redirect);
        }
    };

    const handleVenueOpen = () => {
        setIsVenueOpen(!isVenueOpen);
    };

    const handleContactOpen = () => {
        setIsContactAddOpen(!isContactAddOpen);
    };

    const handleBankDetailsOpen = () => {
        setIsBankDetailsAddOpen(!isBankDetailsAddOpen);
    };

    const handleBiddingCalculationsOpen = () => {
        setIsBiddingCalculationsAddOpen(!isBiddingCalculationsAddOpen);
    };

    const handleIncrementTableOpen = () => {
        setIsIncrementTableAddOpen(!isIncrementTableAddOpen);
    };

    const handleViewDocument = (url: string) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
        })
    }

    const mapAuctionRow = (row: any, index: any) => {

        return <TableRow key={'' + index}>
            <TableCell hidden component="th" scope="row">
                {row.documentId}
            </TableCell>
            <TableCell hidden component="th" scope="row">
                {row.documentTemplateId}
            </TableCell>
            <TableCell scope="row">
                {row.documentTemplateName}
            </TableCell>
            <TableCell scope="row">
                {row.documentUrl.length > 0 && (
                    <a
                        className="text-info"
                        href={undefined}
                        onClick={() => handleViewDocument(row.documentUrl)}
                        style={{
                            textDecoration: 'underline', cursor: 'pointer'
                        }}
                    >View</a>
                )}
            </TableCell>
            <TableCell scope="row">
                {row.documentTemplate !== undefined && (!row.documentTemplate.dataCaptured.auction.isUploadMandatory || row.documentTemplate.dataCaptured.auction.isRegistrationDocument) ? (
                    <FileUploader
                        uploadedFile={row.fileUploadData}
                        allowedToUpload={true}
                        buttonFileUploadedText={"Attached"}
                        acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                        onFileUpload={(file) => handleFileUpload(file, index)}
                        onDelete={() => handleDeleteDocument(index)}
                        maxImageSize={10240}
                        maxFileSize={10240} />
                ) :
                    <FileUploader
                        uploadedFile={row.fileUploadData}
                        allowedToUpload={false}
                        buttonFileUploadedText={"Attached"}
                        acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                        onFileUpload={(file) => handleFileUpload(file, index)}
                        onDelete={() => handleDeleteDocument(index)}
                        maxImageSize={10240}
                        maxFileSize={10240} />
                }
            </TableCell>
        </TableRow>
    };

    const handleDeleteDocument = (rowIndex) => {
        let tempDoc = { ...auctionDocuments[rowIndex] }

        if (tempDoc.documentId) {
            deleteDocument(tempDoc.documentId)
        }

        let tempList = [...auctionDocuments];
        tempList.splice(rowIndex, 1);
        setAuctionDocuments(tempList)
    }

    const handleFileUpload = (file, rowIndex) => {
        let tempDoc = { ...auctionDocuments[rowIndex] }

        if (file) {
            tempDoc.fileUploadData = file;

            if (tempDoc.documentId) {
                deleteDocument(tempDoc.documentId);
                tempDoc.documentId = '';
            }
        } else {
            tempDoc.fileUploadData = "";
        }

        let tempList = [...auctionDocuments];
        tempList.splice(rowIndex, 1, tempDoc);
        setAuctionDocuments(tempList)
    }

    const handleCloseLotTypeDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving " + settings.LotName + " Type..." });
            handleLotTypesFetch_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'lotTypeId', value: data });
                hideProgressSpinner();
            })
            setIsLotTypeOpen(false)
        }
        else setIsLotTypeOpen(false);
    }

    const handleCloseVenueDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Venue..." });
            handleVenuesFetch_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'venueId', value: data });
                hideProgressSpinner();
            })
            setIsVenueOpen(false)
        }
        else setIsVenueOpen(false);
    }

    const handleCloseContactDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Contact/Agent..." });
            handleContactsFetch_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'contactId', value: data });
                hideProgressSpinner();
            })
            setIsContactAddOpen(false)
        }
        else setIsContactAddOpen(false);
    }

    const handleCloseBankDetailsDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Bank Details..." });
            retrieveBankDetailsLookup_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'bankDetailsId', value: data });
                hideProgressSpinner();
            })
            setIsBankDetailsAddOpen(false)
        }
        else setIsBankDetailsAddOpen(false);
    }

    const handleCloseBiddingCalculationsDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Bidding Calculations..." });
            retrieveBiddingCalculationsLookup_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'defaultBiddingCalculationId', value: data });
                hideProgressSpinner();
            })
            setIsBiddingCalculationsAddOpen(false)
        }
        else setIsBiddingCalculationsAddOpen(false);
    }

    const handleCloseIncrementTableDialog = (data?: any) => {
        if (data) {
            showProgressSpinner({ description: "Saving Increment Table..." });
            retrieveIncrementTableLookup_d((res) => {
                if (res.success) setFormFields({ type: 'update', path: 'defaultIncrementTableId', value: data });
                hideProgressSpinner();
            })
            setIsIncrementTableAddOpen(false)
        }
        else setIsIncrementTableAddOpen(false);
    }

    const renderDocumentsTable = (auctionDocuments) => {
        return <TableBody>
            {auctionDocuments.map((row, index) => mapAuctionRow(row, index))}
        </TableBody>
    }

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [tempSearchQuery, setTempSearchQuery] = useState<string>('');
    const [links, setLinks] = useState<any[]>([]);

    const addTempLink = (links, lotId) => {
        let tempLinks = [...links];
        tempLinks.push(lotId);
        setLinks(tempLinks);
    }

    const removeTempLink = (links, lotId) => {
        let tempLinks = [...links];
        let index = tempLinks.findIndex(x => x === lotId);
        if (index > -1) tempLinks.splice(index, 1);
        setLinks(tempLinks);
    }

    const onLotsFilterChange = (e) => {
        setTempSearchQuery(e.target.value)
    }

    const onApplySearchQuery = (e) => {
        if (e.key === 'Enter') setSearchQuery(tempSearchQuery);
    }

    const [linkModalOpen, setLinkModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (linkModalOpen) {
            if (individualLots?.length > 0) handleLinkIndividualLot(links, searchQuery);
            else if (loadingIndividualLots) {
                setLinkModalOpen(false);
                ns.information(`No individual ${settings.LotName} available`);
            }

            setLoadingIndividualLots(false);
        }
    }, [individualLots, links, tempSearchQuery, searchQuery, linkModalOpen]);

    const handleLinkIndividualLot = (links, searchQuery) => {
        let elements: any[] = [];

        individualLots.filter(x => (searchQuery.toLowerCase() === '' || x.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) || links.findIndex(y => y === x.id) > -1)
            .sort(x => links.findIndex(y => y === x.id))
            .forEach((lot, index, list) => {
                var existingLink = links.findIndex(x => x === lot.id) > -1;

                elements.push(<ListItem button onClick={() => {
                    if (!existingLink) addTempLink(links, lot.id);
                    else removeTempLink(links, lot.id);
                }}>
                    <div style={{ width: 'calc(100% - 70px', paddingRight: 15 }}>{lot.name}</div>
                    <div style={{ width: '70px', textAlign: 'right' }}>
                        {!existingLink && <Button variant="contained" className="btn-primary text-white text-capitalize" fullWidth>Link</Button>}
                        {existingLink && <Button variant="contained" className="btn-danger text-white text-capitalize" fullWidth>Unlink</Button>}
                    </div>
                </ListItem>);

                if (index !== list.length - 1)
                    elements.push(<Divider />);
            });

        ns.showDialog({
            title: `Link Individual ${settings.LotName}s`,
            content: <>
                {individualLots?.length > 0 && <div className={"search-wrapper search-wrapper--grow w-100 is-active"}>
                    <div className="px-4 py-3 bg-white d-flex align-items-right">
                        <TextField
                            fullWidth
                            onKeyPress={onApplySearchQuery}
                            onChange={onLotsFilterChange}
                            value={tempSearchQuery}
                            placeholder={`Search ${settings.LotName}s`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdSearch />
                                    </InputAdornment>
                                ),
                                endAdornment: tempSearchQuery.length > 0 && <IconButton size="small" onClick={() => {
                                    setSearchQuery('');
                                    setTempSearchQuery('');
                                }}>
                                    <MdClose />
                                </IconButton>
                            }}
                        />
                    </div>
                </div>}
                <List
                    component="div"
                    className="nav-neutral-primary text-left d-block px-3 pb-3"
                    style={{ maxHeight: 550, minWidth: 400 }}>
                    {elements.length > 0 ? elements : <p>No Individual {settings.LotName}s Available</p>}
                </List>
            </>,
            locales: {
                submitText: "Done",
                cancelText: "Cancel"
            },
            onSubmit: () => {
                showProgressSpinner({ description: `Linking Individual ${settings.LotName}s to ${settings.AuctionName}...` })
                handleIndividualLotsLinking_d(auction.id, links, (res: any) => {
                    hideProgressSpinner();
                    if (res.success && res.response.length === 0) {
                        clearIndividualLots_d();
                        setLinks([]);
                        refreshLotsList(auction.id, selectedFilters, pagination);
                        ns.success(`Successfully linked all ${settings.LotName}s`);
                        setLinkModalOpen(false);
                        setSearchQuery('');
                        setTempSearchQuery('');
                    }
                    else if (res.success && res.response.length > 0) {
                        clearIndividualLots_d();
                        setLinks(res.response);
                        refreshLotsList(auction.id, selectedFilters, pagination);
                        ns.information(`Some ${settings.LotName}s could not be linked`);
                    }
                    else {
                        ns.error(`Failed to link the ${settings.LotName}s`);
                    }
                })
            },
            onCancel: () => {
                setLinkModalOpen(false);
                setSearchQuery('');
                setTempSearchQuery('');
            },
            contentStyle: {
                padding: 0
            }
        });
    }

    const handleReorderClicked = () => {
        history.push(`/ReorderAuction/${auctionId || auctionIdParam}`);
    }

    const renderLotsAdditionalActions = [
        <Tooltip arrow title={"Reorder " + settings.LotName + "s"} placement="bottom">
            <LoadingButton loading={loadingReorder} color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" style={{ marginLeft: 10 }} onClick={() => {
                setLoadingReorder(true); //Setting the loading order to true
                handleReorderClicked();
            }} disableFocusRipple={true}>
                <FaArrowsAltV size={15} />
            </LoadingButton>
        </Tooltip>,
        <Tooltip arrow title={"Link Individual " + settings.LotName + "s"} placement="bottom">
            <LoadingButton loading={loadingIndividualLots} color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" style={{ marginLeft: 10 }} onClick={() => {
                setLoadingIndividualLots(true);
                handleIndividualLotsFetch_d(null, (res) => {
                    if (res.success) {
                        setLinkModalOpen(true);
                    }
                });
            }} disableFocusRipple={true}>
                <FaLink size={15} />
            </LoadingButton>
        </Tooltip>
    ];


    const AddLotTypeModal = () => {
        if (settings.ProductName === "PremBid") {
            return <Grid item xs={1} className='mt-2'>
                <IconButton aria-label="Add" className="p-3" onClick={() => {
                    handleLotTypeOpen();
                }}>
                    <AddIcon />
                </IconButton>
            </Grid>
        } else {
            return <Grid item xs={1}></Grid>
        }
    }

    const generateLotFilters = [
        {
            description: 'Available',
            propertyMap: 'available',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        },
        {
            description: 'Sold',
            propertyMap: 'sold',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true,
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        }
    ]

    const handlePageChange = (pagination: any) => {
        refreshLotsList(auctionIdParam, selectedFilters, pagination)
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        refreshLotsList(auctionIdParam, filters, null);
    }


    const renderInnerComponents = () => {
        return (<>
            <Card className="shadow-xxl p-3">
                <List className="nav-tabs nav-tabs-primary d-flex">
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "0"}
                        onClick={() => {
                            toggle("0");
                        }}>
                        {settings.AuctionName + " Information"}
                        {auctionInformationTabError && (
                            <div className="badge badge-danger m-1 badge-circle">
                                Danger
                            </div>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "1"}
                        onClick={() => {
                            toggle("1");
                        }}>
                        {settings.AuctionName + " Image"}
                        {imagesTabError && (
                            <div className="badge badge-danger m-1 badge-circle">
                                Danger
                            </div>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "2"}
                        onClick={() => {
                            toggle("2");
                        }}
                    >
                        {settings.AuctionName + " Documents"}

                    </ListItem>
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "3"}
                        onClick={() => {
                            toggle("3");
                        }}>
                        {settings.LotName + "s Information"}
                    </ListItem>
                </List>

                {/* Code that I am current working on */}
                <div className="p-1">
                    <div className={"tab-item-wrapper px-3 " + (activeTab === '0' ? 'active' : '')} key={0}>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            spacing={0}>
                            <Grid item xs={5}>
                                <TextField
                                    value={formFields.name}
                                    onChange={onChange}
                                    id="name"
                                    label="Name (Max 100 Characters)"
                                    required
                                    onBlur={validateField}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                    error={formFieldsValidationShowErrors.name} />
                            </Grid>
                            <Grid item xs={1} />

                            <Grid item xs={11}>
                                <TextField
                                    value={formFields.description}
                                    onChange={onChange}
                                    id="description"
                                    label="Description"
                                    multiline
                                    rows={4} />
                            </Grid>
                            <Grid item xs={1} />

                            <Grid item xs={5}>
                                <TextField
                                    value={formFields.lotTypeId}
                                    onChange={onLotTypeSelectChange}
                                    name='lotTypeId'
                                    label={"Default " + settings.LotName + " Type"}
                                    select
                                    required
                                    onBlur={validateField}
                                    error={formFieldsValidationShowErrors.lotTypeId}
                                    loading={loadingStates.lotTypes}
                                >
                                    {lotTypes.map((lotType) => (
                                        <MenuItem key={lotType.id} value={lotType.id}>
                                            {lotType.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <AddLotTypeModal />

                            <Grid item xs={5}>
                                <TextField
                                    onChange={onVenueSelectChange}
                                    value={formFields.venueId}
                                    name="venueId"
                                    label="Venue"
                                    select
                                    loading={loadingStates.venues}
                                >
                                    <MenuItem key={'_blank'} value=''>
                                        None
                                    </MenuItem>
                                    {venuesData?.map(venue => <MenuItem key={venue.id} value={venue.id}>
                                        {venue.name}
                                    </MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={1} className='mt-2'>
                                <IconButton
                                    aria-label="Add"
                                    className="p-3"
                                    onClick={() => {
                                        handleVenueOpen();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={5}>
                                <TextField
                                    onChange={onContactSelectChange}
                                    value={formFields.contactId}
                                    name="contactId"
                                    required
                                    error={formFieldsValidationShowErrors.contactId}
                                    onBlur={validateField}
                                    label="Default Contact/Agent"
                                    loading={loadingStates.contacts}
                                    select>
                                    {contacts?.map(contact => <MenuItem key={contact.id} value={contact.id} disabled={contact.deleted} hidden={contact.deleted}>
                                        {contact.name} {contact.source && contact.source !== 'Internal' && <Chip color="primary" size="small" label={contact.source} style={{ marginLeft: 6 }} />} {contact.deleted && <Chip color="error" size="small" label='Deleted' style={{ marginLeft: 6 }} />}
                                    </MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={1} className='mt-2'>
                                <IconButton
                                    aria-label="Add"
                                    className="p-3"
                                    onClick={() => {
                                        handleContactOpen();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={5}>
                                <TextField
                                    required={formFields.defaultApplyInvoicing}
                                    onChange={onBankDetailsSelectChange}
                                    value={formFields.bankDetailsId}
                                    name="bankDetailsId"
                                    label="Bank Details"
                                    select
                                    loading={loadingStates.bankDetails}
                                    onBlur={formFields.defaultApplyInvoicing ? validateField : undefined}
                                    error={formFields.defaultApplyInvoicing ? formFieldsValidationShowErrors.bankDetailsId : false}
                                >
                                    <MenuItem key={'_blank'} value=''>
                                        None
                                    </MenuItem>
                                    {bankDetailsLookup?.map(details => <MenuItem key={details.id} value={details.id} disabled={details.deleted} hidden={details.deleted}>
                                        {details.name} {details.deleted && <Chip color="error" size="small" label='Deleted' style={{ marginLeft: 6 }} />}
                                    </MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={1} className='mt-2'>
                                <IconButton
                                    aria-label="Add"
                                    className="p-3"
                                    onClick={() => {
                                        handleBankDetailsOpen();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={5}>
                                <TextField
                                    value={formFields.auctionType}
                                    onChange={onAuctionTypeSelectChange}
                                    name="auctionType"
                                    label={settings.AuctionName + " Type"}
                                    select
                                    required
                                    onBlur={validateField}
                                    error={formFieldsValidationShowErrors.auctionType}
                                >
                                    {auctionTypes.map((auctionType) => (
                                        <MenuItem key={auctionType.value} value={auctionType.value} >
                                            {auctionType.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1} />

                            {formFields.auctionType == 1 ? <Grid item xs={5}>
                                <TextField
                                    margin='dense'
                                    value={formFields.streamUrl}
                                    onChange={onChange}
                                    id="streamUrl"
                                    fullWidth
                                    label="YouTube Stream URL"
                                    onBlur={(e) => validateStreamUrlField(e, e.target.value)}
                                    error={formFieldsValidationShowErrors.streamUrl}>
                                </TextField>
                            </Grid> : <Grid container xs={6} className='mt-2'>
                                <Grid item xs={10}>
                                    <FormLabel hidden={hideFieldsForAuctionType} className="mb-0 text-primary">Please either create/use a default increment table or set a default increment</FormLabel>
                                    <TextField
                                        required={!hideFieldsForAuctionType}
                                        hidden={hideFieldsForAuctionType}
                                        onChange={onDefaultIncrementTableSelectChange}
                                        value={formFields.defaultIncrementTableId}
                                        name="defaultIncrementTableId"
                                        label="Default Increment Table"
                                        select
                                        loading={loadingStates.incrementTables}
                                    >
                                        <MenuItem key={'_blank'} value=''>
                                            None
                                        </MenuItem>
                                        {incrementTableLookup?.map(incrementTable => <MenuItem key={incrementTable.id} value={incrementTable.id}>
                                            {incrementTable.name}
                                        </MenuItem>)}
                                    </TextField>
                                </Grid>

                                <Grid xs={2} display='flex' alignItems='flex-end' className='mb-1'>
                                    <IconButton
                                        hidden={hideFieldsForAuctionType}
                                        aria-label="Add"
                                        className="p-3"
                                        onClick={() => {
                                            handleIncrementTableOpen();
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>}

                            <Grid item xs={6} />

                            <Grid item xs={5} className="text-center">
                                <FormLabel hidden={hideFieldsForAuctionType} className="text-primary text-center">OR</FormLabel>
                            </Grid>
                            <Grid item xs={1} />

                            <Grid item xs={5}>
                                <TextField
                                    value={formFields.depositAmount}
                                    onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                    onChange={onChange}
                                    id='depositAmount'
                                    label='Default Registration Deposit'
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Currency priceWithSymbol={""} />
                                            </InputAdornment>
                                        )
                                    }} />

                            </Grid>
                            <Grid item xs={1} />

                            <Grid item xs={5}>
                                <TextField
                                    required={!hideFieldsForAuctionType}
                                    hidden={hideFieldsForAuctionType}
                                    value={formFields.increment}
                                    onChange={onIncrementChange}
                                    onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                    id="increment"
                                    label="Default Increment"
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Currency priceWithSymbol={""} />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item xs={1}></Grid>

                            <Grid item xs={5}>
                                <TextField
                                    onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                                    onChange={onChange}
                                    id="commission"
                                    value={formFields.commission}
                                    error={commissionValid}
                                    helperText={commissionValid ? "Please capture an amount between 0 - 100%" : null}
                                    label="Default Buyer's Commission"
                                    InputProps={{
                                        inputComponent: CurrencyInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Currency prefixString={"%"} hideCurrencySymbol priceWithSymbol={""} />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item xs={1}></Grid>

                            <Grid item xs={5}>
                                <TextField
                                    hidden={hideFieldsForAuctionType}
                                    onChange={onDefaultBiddingCalculationSelectChange}
                                    value={formFields.defaultBiddingCalculationId}
                                    name="defaultBiddingCalculationId"
                                    label="Default Bidding Calculation"
                                    select
                                    loading={loadingStates.biddingCalculations}
                                >
                                    <MenuItem key={'_blank'} value=''>
                                        None
                                    </MenuItem>
                                    {biddingCalculationsLookup?.map(biddingCalculation => <MenuItem key={biddingCalculation.id} value={biddingCalculation.id}>
                                        {biddingCalculation.name}
                                    </MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={1} className='mt-2'>
                                <IconButton
                                    hidden={hideFieldsForAuctionType}
                                    aria-label="Add"
                                    className="p-3"
                                    onClick={() => {
                                        handleBiddingCalculationsOpen();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Grid>

                            <Grid item xs={5}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={5}>
                                        <FormLabel className="mt-2 mb-2 font-size-xs">Stagger {settings.LotName} End Time</FormLabel>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField value={formFields.rollingTimerHours} onChange={onChangeNumeric} id="rollingTimerHours" className="mt-1 mb-1 mr-1" label="Hours" type='number' />
                                            <TextField value={formFields.rollingTimerMinutes} onChange={onChangeNumeric} id="rollingTimerMinutes" className="m-1" label="Mins" type='number' />
                                            <TextField value={formFields.rollingTimerSeconds} onChange={onChangeNumeric} id="rollingTimerSeconds" className="m-1" label="Secs" type='number' />
                                        </div>
                                    </Grid>
                                    <Grid item xs={7} />
                                </Grid>
                            </Grid>
                            <Grid item xs={7} />

                            <Grid item xs={5}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    <Grid item xs={5}>
                                        {
                                            !hideFieldsForAuctionType &&
                                            <div>
                                                <FormLabel className="mt-2 mb-2 font-size-xs">Default Late Bid Extension Window</FormLabel>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <TextField value={formFields.timeWindowHours} onChange={onChangeNumeric} id="timeWindowHours" className="mt-1 mb-1 mr-1" label="Hours" type='number' />
                                                        <TextField value={formFields.timeWindowMinutes} onChange={onChangeNumeric} id="timeWindowMinutes" className="m-1" label="Mins" type='number' />
                                                        <TextField value={formFields.timeWindowSeconds} onChange={onChangeNumeric} id="timeWindowSeconds" className="m-1" label="Secs" type='number' />
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={5}>
                                        {
                                            !hideFieldsForAuctionType &&
                                            <div>
                                                <FormLabel className="mt-2 mb-2 font-size-xs">Default Late Bid Extension</FormLabel>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <TextField value={formFields.hours} onChange={onChangeNumeric} id="hours" className="mt-1 mb-1 mr-1" label="Hours" type='number' />
                                                    <TextField value={formFields.minutes} onChange={onChangeNumeric} id="minutes" className="m-1" label="Mins" type='number' />
                                                    <TextField value={formFields.seconds} onChange={onChangeNumeric} id="seconds" className="mt-1 mb-1 ml-1" label="Secs" type='number' />
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={7} />

                            <Grid item xs={5}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    spacing={1}
                                    className="mt-2">
                                    <Grid item xs={6}>
                                        <DateTimePicker
                                            key="startTime"
                                            ampm={false}
                                            label='Start Time'
                                            value={new Date(formFields.startTime)}
                                            onChange={onStartTimeChange}
                                            format='yyyy/MM/dd HH:mm'
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <DateTimePicker
                                            key="finishTime"
                                            ampm={false}
                                            label='End Time'
                                            value={new Date(formFields.finishTime)}
                                            onChange={onFinishTimeChange}
                                            format='yyyy/MM/dd HH:mm'
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} />

                            <Grid item xs={6}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    spacing={0}>
                                    {/*<Grid item xs={5}>*/}
                                    {/*    <FormControlLabel className="ml-0 mt-2" control={<></>} labelPlacement="start" label="Default Show Bidders the Bidding Calculations" />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={1}>*/}
                                    {/*    <Switch checked={formFields.defaultShowBiddingCalculation} onChange={onChangeCheckbox} id="defaultShowBiddingCalculation" className="p-2 switch-medium toggle-switch-success" />*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={4}>
                                        {Number(formFields?.auctionType) !== Number(AuctionTypeEnum.Streamed.toString()) && <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label={`Do you require invoicing for this ${settings.AuctionName}?`} />}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {Number(formFields?.auctionType) !== Number(AuctionTypeEnum.Streamed.toString()) && <Switch checked={formFields.defaultApplyInvoicing} onChange={onChangeCheckbox} id="defaultApplyInvoicing" className="p-2 switch-medium toggle-switch-success" />}
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={4}>
                                        <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Default Show when the Reserve is Met" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Switch checked={formFields.defaultShowReserve} onChange={onChangeCheckbox} id="defaultShowReserve" className="p-2 switch-medium toggle-switch-success" />
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={4}>
                                        <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Default STC Status" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Switch checked={formFields.stcStatus} onChange={onChangeCheckbox} id="stcStatus" className="p-2 switch-medium toggle-switch-success" />
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={4}>
                                        <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Default Watermark Images" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Switch checked={formFields.defaultWatermarkImages} onChange={onChangeCheckbox} id="defaultWatermarkImages" className="p-2 switch-medium toggle-switch-success" />
                                    </Grid>
                                    <Grid item xs={1} />
                                    {
                                        !hideFieldsForAuctionType &&
                                        <>
                                            <Grid item xs={4}>
                                                <FormControlLabel className="ml-0 mt-2 mr-2" control={<></>} labelPlacement="start" label="Default Show Paddle Number" />
                                            </Grid><Grid item xs={1}>
                                                <Switch checked={formFields.defaultShowPaddleNumber} onChange={onChangeCheckbox} id="defaultShowPaddleNumber" className="p-2 switch-medium toggle-switch-success" />
                                            </Grid>
                                            <Grid item xs={1} />
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel className="ml-0 mt-2 mr-2 mb-2" control={<span className='ml-2'><Switch checked={formFields.isActive} onChange={onChangeCheckbox} id="isActive" className="p-2 switch-medium toggle-switch-success" /></span>} labelPlacement="start" label="Active " id="isActive" />
                            </Grid>
                        </Grid>
                    </div>

                    <div className={"px-3 tab-item-wrapper " + (activeTab === '1' ? 'active' : '')} key={1}  >
                        <ImageUploader
                            acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                            lotImages={auctionImage}
                            newFilesAdded={newHeroImageFilesAdded}
                            deleteImage={deleteImage}
                            isDisplayImage={true}
                            header={settings.AuctionName + " Image"}
                            description={"This is the image that will display when viewing a list of " + settings.AuctionName + "s and on the home page of the Client Portal."}
                            min={1}
                            max={1}
                        />
                    </div>

                    <div
                        className={
                            "tab-item-wrapper px-3 " + (activeTab === "2" ? "active" : "")
                        }
                        key={2}>
                        <div className="table-responsive-md mb-4">
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableCell width="80%">
                                            Document Name
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell align="right" width="20%">
                                            <Tooltip arrow title="Add" placement="bottom">
                                                <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" onClick={onShowDocumentTemplateModal} disableFocusRipple={true}>
                                                    <FaPlus />
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableHead>
                                    {renderDocumentsTable(auctionDocuments)}
                                </Table>
                            </TableContainer>
                            {documentTemplateModal.open && <DocumentTemplateAddDialog
                                documentType={DocumentTypeEnum.Auction}
                                open={documentTemplateModal.open}
                                payload={documentTemplateModal.id}
                                okButtonText="Ok"
                                cancelButtonText="Cancel"
                                description="Select documents you want to add"
                                title="Documents"
                                onCancel={onCancelDocumentTemplateModal}
                                onOk={onAddDocumentTemplateModal}
                            />}
                        </div>
                    </div>

                    {
                        auctionIdParam ? (
                            <div className={"tab-item-wrapper px-3 " + (activeTab === '3' ? 'active' : '')} key={3}>
                                <NestedAccordionGrid
                                    showTopDivider={false}
                                    selectedFilters={selectedFilters}
                                    pagination={pagination}
                                    onFiltersChange={handleFiltersChange}
                                    availableFilters={generateLotFilters}
                                    onHandleChangePage={handlePageChange}
                                    dataModel={lotsDisplayModel}
                                    onDelete={onLotDelete}
                                    onEdit={onLotEdit}
                                    onAdd={onAdd}
                                    tableActions={renderLotsAdditionalActions}
                                    loading={loadingLots} />
                                {deleteModal.open && <ConfirmationDialog
                                    open={deleteModal.open}
                                    payload={deleteModal.id}
                                    okButtonText="Ok"
                                    cancelButtonText="Cancel" description={"Are you sure you want to delete this " + settings.LotName + "?"} title={"Delete " + settings.LotName} onCancel={onDeleteModalClosed} onOk={onDeleteModalOk} />}
                            </div>
                        )
                            :
                            (
                                <div className={"tab-item-wrapper " + (activeTab === '3' ? 'active' : '')} key={3}>
                                    <p className="pt-3 text-center text-black-50" >{"You need to create the " + settings.AuctionName + " before you can add " + settings.LotName + "s to it."}</p>
                                </div>
                            )
                    }
                </div>
                {/* End of code I am current working */}
                <FloatyButtonGroup>
                    <Tooltip arrow title="Save" placement="left">
                        <Button size="large" variant="contained" onClick={onSubmit}
                            className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                            <span className="btn-wrapper--icon">
                                <FaSave />
                            </span>
                        </Button>
                    </Tooltip>
                    <Tooltip arrow title="Back" placement="left">
                        <Button size="large" variant="contained" onClick={onCancel}
                            className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                            <span className="btn-wrapper--icon">
                                <FaBackspace />
                            </span>
                        </Button>
                    </Tooltip>
                </FloatyButtonGroup>

                <LotTypeDialog
                    isDialogOpened={isLotTypeOpen}
                    handleCloseDialog={(data) => handleCloseLotTypeDialog(data)}
                />

                <VenueAddDialog
                    isDialogOpened={isVenueOpen}
                    handleCloseDialog={(data) => handleCloseVenueDialog(data)}
                />

                <ContactAddDialog
                    isDialogOpened={isContactAddOpen}
                    handleCloseDialog={(data) => handleCloseContactDialog(data)}
                />

                <BankDetailsAddDialog
                    isDialogOpened={isBankDetailsAddOpen}
                    handleCloseDialog={(data) => handleCloseBankDetailsDialog(data)}
                />

                <BiddingCalculationsAddDialog
                    isDialogOpened={isBiddingCalculationsAddOpen}
                    handleCloseDialog={(data) => handleCloseBiddingCalculationsDialog(data)}
                />

                <IncrementTableAddDialog
                    isDialogOpened={isIncrementTableAddOpen}
                    handleCloseDialog={(data) => handleCloseIncrementTableDialog(data)}
                />
            </Card>
        </>)
    };

    return <>{renderInnerComponents()}</>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleAuctionAdd_d: (auction: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => dispatch(handleAuctionAdd(auction, images, documents, onCompletedCallback)),
        handleAuctionUpdate_d: (formFields: any, images: any[], documents: any[], onCompletedCallback: (res: any) => void) => dispatch(handleAuctionUpdate(formFields, images, documents, onCompletedCallback)),
        handleImageFetch_d: (id: string) => dispatch(handleImageFetch(id)),
        handleAuctionImageDelete_d: (id: string) => dispatch(handleAuctionImageDelete(id)),
        handleLotsFetchWithPagination_d: (auctionId: string, filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleLotsFetchWithPagination(auctionId, filters, pagination, onCompletedCallback)),
        clearAuction_d: () => dispatch(clearAuction()),
        clearAuctionImages_d: () => dispatch(clearAuctionImages()),
        clearLots_d: () => dispatch(clearLots()),
        setAuction_d: (auction: any) => dispatch(setAuction(auction)),
        setLot_d: (lot: any) => dispatch(setLot(lot)),
        handleLotTypesFetch_d: (onCompletedCallback?: (res: any) => void) => dispatch(handleLotTypesFetch(onCompletedCallback)),
        setLotTypeId_d: (id: string) => dispatch(setLotTypeId(id)),
        clearLotTypeId_d: () => dispatch(clearLotTypeId()),
        handleLotDelete_d: (Id: string, onCompletedCallback?: (result: any) => void) => dispatch(handleLotDelete(Id, onCompletedCallback)),
        clearResponses_d: () => dispatch(clearApiResponses()),
        handleAuctionFetch_d: (id: string, onCompletedCallback?: (res: any) => void) => dispatch(handleAuctionFetch(id, onCompletedCallback)),
        clearLot_d: () => dispatch(clearLot()),
        handleVenuesFetch_d: (onCompletedCallback?: (res: any) => void) => dispatch(handleVenuesFetch(onCompletedCallback)),
        setAuctionImages_d: (images: any) => dispatch(auctionImageFetchSuccess(images)),
        handleDocumentDelete_d: (documentId: string) => dispatch(handleRegistrationDocumentDelete(documentId)),
        handleRetrieveRegistrationDocuments_d: (auctionId: string) => dispatch(handleRetrieveRegistrationDocuments(auctionId)),
        clearDocuments_d: () => dispatch(clearDocuments()),
        handleContactsFetch_d: (onCompletedCallback?: (res: any) => void) => dispatch(handleContactLookupFetch(true, onCompletedCallback)),
        handleIndividualLotsFetch_d: (searchString: string | null, onCompletedCallback?: (res: any) => void) => dispatch(handleIndividualLotsFetch(searchString, true, onCompletedCallback)),
        clearIndividualLots_d: () => dispatch(clearIndividualLots()),
        handleIndividualLotsLinking_d: (auctionId, lotIds: string[], onCompletedCallback: (res: any) => void) => dispatch(handleIndividualLotsLinking(auctionId, lotIds, onCompletedCallback)),
        handleIndividualLotsUnlinking_d: (id: string, onCompletedCallback: (res: any) => void) => dispatch(handleIndividualLotsUnlinking(id, onCompletedCallback)),
        resetVenueState_d: () => dispatch(resetVenueState()),
        retrieveBankDetailsLookup_d: (onCompletedCallback: (res: any) => void) => dispatch(retrieveBankDetailsLookup(onCompletedCallback)),
        retrieveBiddingCalculationsLookup_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchBiddingCalculationsLookup(onCompletedCallback)),
        retrieveIncrementTableLookup_d: (onCompletedCallback: (res: any) => void) => dispatch(handleIncrementTablesLookupFetch(onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    auctionId: state.auctions.auctionId,
    auction: state.auctions.auction,
    lots: state.auctions.lots,
    pagination: state.auctions.pagination,
    lotTypesData: state.lotTypes.lotTypes,
    lotsReload: state.lots.reload,
    auctionImage: state.auctions.auctionImage,
    selectLotTypeAuction: state.lotTypes.selectLotTypeAuction,
    //selectNewVenueAuction: state.venue.selectNewVenueAuction,
    venuesData: state.venue.venues,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    error: state.auctions.error,
    errorText: state.auctions.errorText,
    documents: state.documents.documents,
    contacts: state.contacts.lookups,
    individualLots: state.lots.lots,
    bankDetailsLookup: state.bankDetails.bankDetailsLookup,
    biddingCalculationsLookup: state.biddingCalculations.biddingCalculationsLookup,
    incrementTableLookup: state.incrementTable.lookups,
})

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsEditAdd);
