export const apiPaths = {
    account: {
        login: "/api/login",
        register: "/api/register",
        confirmAccount: "/api/confirmAccount",
        registerConfirmation: "/api/registerConfirmation",
        confirmAccountConfirmation: "/api/confirmAccountConfirmation",
        sendResetPasswordLink: "/api/sendResetPasswordLink",
        forgotPasswordConfirmation: "/api/forgotPasswordConfirmation",
        resetPassword: "/api/resetPassword",
        resetPasswordConfirmation: "/api/resetPasswordConfirmation",
        retrieveAllAccounts: "/api/retrieveAllAccounts",
        retrieveAllAccountsWithPagination: "/api/retrieveAllAccountsWithPagination",
        updateAccountDetails: "/api/UpdateIdentityDetails",
        updateAccountStatus: "/api/UpdateStatus",
        retrieveAccount: "/api/retrieveAccount",
        retrieveProfileAccount: "/api/User/Retrieve",
        confirmEmailChange: "/api/confirmEmailChange",
        upsertUserDevice: "/api/UpsertUserDevice",
        getUserTermsAcceptance: '/api/getTermsAccepted',
        upsertTermsAcceptance: '/api/upsertTermsAcceptance',
        termsandconditions: "/api/termsandconditions",
        resendConfirmAccountEmail: "/api/resendConfirmAccountEmail",
        resendAdminConfirmAccountEmail: '/api/resendAdminConfirmAccountEmail',
        addUser: '/api/addUser',
        updateUser: '/api/updateUser'
    },

    auction: {
        getAuctionById: "/api/Auction/Retrieve",
        getAuctions: "/api/Auction/RetrieveAll",
        Add: "/api/Auction/Create",
        Update: "/api/Auction/Update",
        Delete: "/api/Auction/Delete",
        createImage: "/api/Auction/CreateAuctionImage",
        retrieveAuctionImage: "/api/Auction/RetrieveAuctionImage",
        retrieveAllAuctionImagesByAuctionId: "/api/Auction/RetrieveAllAuctionImagesByAuctionId/:auctionId",
        createAuctionImage: "/api/Auction/CreateAuctionImage",
        deleteAuctionImage: "/api/Auction/DeleteAuctionImage",
        retrieveAuctionHistoryReport: "api/reports/auctionHistory/:auctionId/:exportType",
        retrieveLotBidHistoryReport: "api/reports/bidHistory/:auctionId/:lotId/:exportType",
        retrieveAuctionCatalogReport: "api/reports/auctionCatalog/:auctionId/:exportType",
        retrieveRegisteredUsersReport: "api/reports/registeredUsers/:auctionId/:exportType/:profileType/:registeredProfileStatus",
        retrieveClientUsersReport: "api/reports/clientUsers/:exportType",
        retrieveFinanceDocumentsForAuctionReport: "api/reports/financeDocuments/:auctionId/:type/:exportType",

        financeDocument: {
            generateInvoice: '/api/Auction/FinanceDocument/Generate/Invoice/:proformaInvoiceId',
            generateConsolidatedProformaInvoice: '/api/Auction/FinanceDocument/Generate/ConsolidatedProformaInvoice',
            generateReleaseNote: '/api/Auction/FinanceDocument/Generate/ReleaseNote/:invoiceId',
            process: '/api/Auction/FinanceDocument/Process/:id',
            download: '/api/Auction/FinanceDocument/Download/:id',
            update: '/api/Auction/FinanceDocument/Update/:id',
            cancel: '/api/Auction/FinanceDocument/Cancel/:id',
            send: '/api/Auction/FinanceDocument/Send/:id',
            retrieve: '/api/Auction/FinanceDocument/Retrieve/:id',
            retrieveAll: '/api/Auction/FinanceDocument/Retrieve/All',
            retrieveAllConsolidatable: '/api/Auction/FinanceDocument/Retrieve/Consolidatable',
            bulkProcess: '/api/Auction/FinanceDocument/Process/Bulk',
            bulkDownload: '/api/Auction/FinanceDocument/Download/Bulk',
            bulkSend: '/api/Auction/FinanceDocument/Send/Bulk',
        }
    },
    bids: {
        subscribeToBidUpdates: "/api/Bid/SubscribeToBidUpdates",
        unsubscribeToBidUpdates: "/api/Bid/UnsubscribeToBidUpdates",
        getFirebaseConfig: "/api/Bid/GetFirebaseConfig",
        updateOrDeleteBid: "/api/Bid/UpdateOrDeleteBid",
        retrieveDeletedBidAuditHistory: "/api/Bid/RetrieveDeletedBidAuditHistory/:lotId",
    },
    profile: {
        retrieveAllProfiles: "/api/Profile/RetrieveAllProfiles",
        create: "/api/Profile/RegisteredProfile",
        updateComplianceStatus: "/api/Profile/UpdateComplianceStatus",
        notifyUserOfMissingDocuments: "/api/Profile/NotifyUserOfMissingDocuments",
        updateProfileAutoApproveRegistrationStatus: "/api/updateProfileAutoApproveRegistrationStatus",
    },
    venue: {
        create: "/api/Venue/Create",
        update: "/api/Venue/Update",
        delete: "/api/Venue/Delete",
        retrieve: "/api/Venue/Retrieve",
        retrieveAll: "/api/Venue/RetrieveAll",
        retrieveAllWithPagination: "/api/Venue/RetrieveAllWithPagination",
    },
    documentTemplate: {
        createDocumentTemplate: "/api/Document/CreateDocumentTemplate",
        updateDocumentTemplate: "/api/Document/UpdateDocumentTemplate",
        deleteDocumentTemplate: "/api/Document/DeleteDocumentTemplate",
        retrieveDocumentTemplate: "/api/Document/RetrieveDocumentTemplate",
        retrieveAllDocumentTemplates: "/api/Document/RetrieveAllDocumentTemplates",
        retrieveAllDocumentTemplatesWithPagination: "/api/Document/RetrieveAllDocumentTemplatesWithPagination",
        retrieveAllDocumentTemplatesByDocumentType: "/api/Document/RetrieveAllDocumentTemplatesByDocumentType",
    },
    document: {
        createDocument: "/api/Document/CreateDocument",
        deleteDocument: "/api/Document/DeleteDocument",
        retrieveAllDocumentsForRelatedTableRecord: "/api/Document/RetrieveAllDocumentsForRelatedTableRecord",
        retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord: "/api/Document/RetrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord",
        retrieveRegistrationDocuments: "/api/Document/RetrieveRegistrationDocuments",
        upsertRegistrationDocument: "/api/Document/UpsertRegistrationDocument",
        deleteRegistrationDocument: "/api/Document/DeleteRegistrationDocument",
        invalidateRegistrationProcessDocument: "/api/Document/InvalidateRegistrationProcessDocument",
        requestAdditionalRegistrationProcessDocuments: "/api/Document/RequestAdditionalRegistrationProcessDocuments",
        downloadSecureFile: "/api/Document/DownloadSecureFile/:url",
    },
    vendor: {
        retrieve: "/api/Vendor/Retrieve",
        update: '/api/Vendor/Update',
        //retrieveWhiteLabelling: "/api/Vendor/RetrieveWhiteLabelling",
        retrieveConfigVersion: "/api/Vendor/RetrieveConfigVersion",
        retrieveConfigByVersion: "/api/Vendor/RetrieveConfigByVersion",

        addBankingDetails: "/api/Vendor/AddBankDetails",
        updateBankingDetails: "/api/Vendor/UpdateBankDetails/:id",
        retrieveBankDetails: "/api/Vendor/RetrieveBankDetails/:id",
        retrieveBankDetailsList: "/api/Vendor/ListVendorBankDetails",
        retrieveBankDetailsLookup: "/api/Vendor/LookupVendorBankDetails",
        deleteBankDetails: '/api/Vendor/DeleteBankDetails/:id',

        retrieveBiddingCalculation: "api/VendorBiddingCalculation/Retrieve/:id",
        retrieveAllBiddingCalculation: "api/VendorBiddingCalculation/Retrieve/All",
        retrieveBiddingCalculationLookup: "api/VendorBiddingCalculation/Retrieve/Lookup",
        addVendorBiddingCalculation: "api/VendorBiddingCalculation/Add",
        updateVendorBiddingCalculation: "api/VendorBiddingCalculation/Update/:id",
        deleteVendorBiddingCalculation: "api/VendorBiddingCalculation/Delete/:id",
    },
    lot: {
        addLot: "/api/Lot/CreateLot",
        getLots: '/api/Lot/RetrieveAll',
        retrieve: '/api/Lot/Retrieve',
        updateLot: "/api/Lot/UpdateLot",
        deleteLot: '/api/Lot/DeleteLot',
        linkLots: '/api/Lot/Link',
        unlinkLot: '/api/Lot/Unlink',
        updateLotRealtimeBiddingData: "/api/Lot/UpdateLotRealtimeBiddingData",
        retrieveLotsOverviewData: "/api/Lot/RetrieveLotsOverviewData",
        retrieveLotsOverviewDataWithPagination: "/api/Lot/RetrieveLotsOverviewDataWithPagination",
        retrieveLotBiddingHistory: "/api/Lot/RetrieveLotBiddingHistory",
        retrieveAuctionLotsForPostAuction: "/api/Lot/RetrieveAuctionLotsForPostAuction",
        retrieveLotPageData: "/api/Lot/RetrieveLotPageData",
        overrideWinningBidId: "/api/Lot/OverrideLotWinner",
        markLotAsSold: '/api/Lot/MarkLotAsSold',
        markLotAsUnsold: '/api/Lot/MarkLotAsUnsold',
        unlinkAllLotsFromAuction: '/api/Lot/UnlinkAllLotsFromAuction',
        retrieveWishlistProfilesByLotId: '/api/Lot/RetrieveWishlistProfilesByLotId',
        retrieveWishlistLotsByAuctionId: '/api/Lot/RetrieveWishlistLotsByAuctionId',
        retrieveAllRegisterdUsersByAuctionIdWithPagination: '/api/Lot/RetrieveAllRegisterdUsersByAuctionIdWithPagination',

        addLotType: "/api/Lot/CreateLotType",
        getLotTypes: "/api/Lot/RetrieveAllLotTypes",
        getLotTypesWithPagination: "/api/Lot/RetrieveAllLotTypesWithPagination",
        retrieveLotType: "/api/Lot/RetrieveLotType",
        updateLotType: "/api/Lot/UpdateLotType",
        deleteLotTypes: "/api/Lot/DeleteLotType",

        imagesRetrieveAllByLotId: "/api/LotImages/RetrieveAllByLot",
        imagesCreate: "/api/LotImages/Create",
        imagesUpdate: "/api/LotImages/Update",
        imagesDelete: "/api/LotImages/Delete",
        imagesRetrieve: "/api/LotImages/Retrieve",

        upsertLotVideo: "/api/LotVideos/UpsertLotVideo",
        deleteLotVideoById: "/api/LotVideos/DeleteLotVideoById/:lotVideoId",

        reorderLotsForAuction: "/api/Lot/ReorderLotsForAuction",
        extendLot: "/api/Lot/ExtendLot",
        updateReservePrice: "/api/Lot/UpdateReservePrice",
        updateStartPrice: "/api/Lot/UpdateStartPrice",
        lock: "/api/Lot/Lock",
        unlock: "/api/Lot/UnLock",

        generateBulkUploadTemplate: "/api/Lot/BulkUpload/Template",
        retrieveBulkUpload: "/api/Lot/BulkUpload/Retrieve/:id",
        retrieveAllBulkUploads: "/api/Lot/BulkUpload/Retrieve/All",
        retrieveAllBulkUploadImages: "/api/Lot/BulkUpload/Image/Retrieve/All",
        upsertBulkUpload: "/api/Lot/BulkUpload/Upsert",
        processBulkUpload: "/api/Lot/BulkUpload/Process/:id",
        deleteBulkUpload: "/api/Lot/BulkUpload/Delete/:id",

        addBulkUploadImage: "/api/Lot/BulkUpload/Image/Add",
        deleteBulkUploadImage: "/api/Lot/BulkUpload/Image/Delete/:id",

        determineWinningAmount: "/api/Lot/DetermineWinningAmount/:lotId",
    },
    mapping: {
        reverseGeo: "/api/map/reversegeo",
        search: "/api/map/search"
    },
    user: {
        retrieveAllRegisterdUsersByAuctionId: "/api/User/RetrieveAllRegisterdUsersByAuctionId",
        retrieveAllRegisterdUsersByAuctionIdWithPagination: "/api/User/RetrieveAllRegisterdUsersByAuctionIdWithPagination",
        update: "/api/User/RegisteredProfileUpdate"
    },
    contact: {
        upsertContact: "/api/Contact/UpsertContact",
        deleteContact: "/api/Contact/DeleteContact/:contactId",
        retrieveContact: "/api/Contact/RetrieveContact/:contactId",
        retrieveContactsList: "/api/Contact/RetrieveContactsList",
        retrieveContactsListWithPagination: "/api/Contact/RetrieveContactsListWithPagination",
        retrieveContactsLookup: "/api/Contact/RetrieveContactsLookup",

        upsertContactImage: "/api/Image/UpsertContactImage",
        retrieveContactImage: "/api/Image/RetrieveContactImage/:imageId",
        deleteContactImage: "/api/Image/DeleteContactImage/:imageId",
    },
    subscriptions: {
        retrieveActiveSubscriptions: "/api/Subscription/Active"
    },
    integration: {
        retrieveIntegrationConfig: "/api/Integration/RetrieveConfig",
        upsertIntegrationConfig: "/api/Integration/UpsertConfig",

        addIntegrationUser: "/api/Integration/AddIntegrationUser",
        generateIntegrationToken: "/api/Integration/GenerateIntegrationToken",
        retrieveIntegrationUsers: "/api/Integration/RetrieveIntegrationUsers",

        retrieveAction: "/api/Integration/RetrieveAction/:id",
        retrieveActions: "/api/Integration/RetrieveActions",
        retrieveWebhookRequests: "/api/Integration/RetrieveWebhookRequests",
        retrieveEntityUpsertRequests: "/api/Integration/RetrieveEntityUpsertRequests",
        retrieveImageUpsertRequests: "/api/Integration/RetrieveImageUpsertRequest",

        manualOverride: "/api/Integration/ManualOverride",
        manualSynchronize: "/api/Integration/ManualSynchronize"
    },
    vendorIncrementTable: {
        upsertVendorIncrementTable: "/api/VendorIncrementTable/UpsertVendorIncrementTable",
        retrieveVendorIncrementTableLookup: "/api/VendorIncrementTable/RetrieveVendorIncrementTableLookUp",
        retrieveVendorIncrementTableListWithPagination: "/api/VendorIncrementTable/RetrieveVendorIncrementTableListWithPagination",
        deleteVendorIncrementTableById: "/api/VendorIncrementTable/DeleteVendorIncrementTableById/:incrementTableId",
        retrieveVendorIncrementTable: "/api/VendorIncrementTable/RetrieveVendorIncrementTable/:incrementTableId",
    }
}