export enum RegisteredProfileStatusEnum {
    'Declined' = 0,
    'Approved' = 1,
    'Awaiting' = 2,
    'Not Registered' = 3,
    'Registration Started' = 4
}

export const RegisteredProfileStatusList = (): any[] => {
    const types = [] as any;
    for (var enumMember in RegisteredProfileStatusEnum) {
        var isValueProperty = parseInt(enumMember, 10) >= 0
        if (isValueProperty) {
            types.push({
                value: enumMember,
                label: RegisteredProfileStatusEnum[enumMember]
            });
        }
    }
    return types;
}