import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { clearError, handleUsersFetch, handleResendConfirmAccountEmail, handleResendAdminConfirmAccountEmail, handleUsersFetchWithPagination } from './store/Users';
import { handleRegisteredUserCreate, handleUserAccountUpdateStatus, handleUserProfilesFetch, resetAccountState } from '../../pages/Profile/store/Profile';
import {
    Collapse,
    IconButton,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { MdClose } from "react-icons/md";
import { NestedAccordionGrid, Cell, Header, Row, NestedGridDataModel, CustomData  } from '@/components/NestedAccordionGrid';
import ns from '@/helpers/NotificationService';
import ConfirmationDialog from '@/components/Modal/ConfirmationDialog';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import RegisterManualUserSelect from './RegisterManualUserSelect';

interface Props {
    clearError_d?: () => void | undefined,
    handleDelete_d?: (Id: string) => void | undefined,
    handleUsersFetch_d?: () => void | undefined,
    handleUsersFetchWithPagination_d?: (filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => void | undefined,
    handleUserProfilesFetch_d: (onCompletedCallback?: (response: any) => void) => void | undefined,
    resetAccountState_d?: () => void | undefined,
    handleRegisteredUserCreate_d?: (auctionId: string, profileId: any, profileType: string, onCompletedCallback: (response: any) => void) => void | undefined,
    setSelectedProfile?: (profile: any, onCompletedCallback?: (response: any) => void) => void | undefined,
    error?: boolean,
    errorText?: string,
    users?: any,
    profilesState?: any,
    pagination?: any,
    userFilters?: any,
    usersPayload?: any,
    currentUser?: any,
    selectedAuction?: any
    selectedProfileId?: string,
}

const RegisterManualUser: React.FC<Props> = props => {
    const {
        clearError_d,
        handleUsersFetchWithPagination_d,
        handleUserProfilesFetch_d,
        resetAccountState_d,
        handleRegisteredUserCreate_d,
        setSelectedProfile,
        error,
        errorText,
        users,
        profilesState,
        pagination,
        selectedAuction,
        selectedProfileId
    } = props;

    const [userDisplayModel, setUserDisplayModel] = useState<NestedGridDataModel>();
    const [confirmationState, setConfirmationState] = useState<any>({
        open: false,
        payload: null
    });

    const [selectedFilters, setSelectedFilters] = useState<any>();

    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);

    useEffect(() => {
        handleUserProfilesFetch_d((res) => {
            if (res.success) {
                reloadData(null, null, false);
            }
            else ns.error("Failed to load user profiles");
        });
        return () => {
            if (resetAccountState_d) { resetAccountState_d(); }
        };
    }, [])

    const reloadData = (filters?: any, pagination?: any, withTableLoading: boolean = true) => {
        if (withTableLoading) setLoadingUsers(true);

        filters = {
            ...(filters || {})
        }

        if (handleUsersFetchWithPagination_d) {
            handleUsersFetchWithPagination_d(filters, pagination, (res) => {
                setLoadingUsers(false);
            });
        }
    }

    const selectedProfileRef = useRef(undefined);

    useEffect(() => {
        let model: NestedGridDataModel;
        let rows: Row[] = [];
        let headers: Header[];

        headers = [
            { title: "Email", align: "inherit" },
            { title: "Phone Number", align: "inherit" },
        ];

        selectedProfileRef.current = undefined;

        if (users)
            rows = users?.map((user: any) => {
                const cells: Cell[] = [
                    { text: user.email, align: "inherit" },
                    { text: user.phoneNumber, align: "inherit" },
                ];

                let profileModel: NestedGridDataModel;
                let profileRows: Row[] = [];
                let profileHeaders: Header[];

                profileHeaders = [
                    { title: "Name", align: "inherit" },
                    { title: "Email", align: "inherit" },
                    { title: "Profile Type", align: "inherit" },
                    { title: " ", align: "inherit" },
                ];

                profileRows = profilesState?.profiles?.filter((profile: any) => profile.userId === user.id && profile.isActive).map((profile: any) => {
                    const profileCells: Cell[] = [
                        { text: profile.name, align: "inherit" },
                        { text: profile.email, align: "inherit" },
                        { text: profile.profileType, align: "inherit" },
                        {
                            text: '',
                            align: "inherit",
                            element: <RegisterManualUserSelect
                                profile={profile}
                                selectedProfileRef={selectedProfileRef}
                                setSelectedProfile={setSelectedProfile} />
                        }
                    ];

                    return {
                        id: profile.id,
                        cells: profileCells
                    };
                });

                profileModel = {
                    headers: profileHeaders,
                    rows: profileRows,
                };

                const customData: CustomData[] = [
                    {
                        title: "Bidding Profiles",
                        element: <>
                            <NestedAccordionGrid dataModel={profileModel} hideSearch />
                        </>
                    }
                ];

                return {
                    id: user.id,
                    cells: cells,
                    actionButtons: [],
                    customData: customData
                };
            });

        model = {
            headers: headers,
            rows: rows,
        };

        setUserDisplayModel(model);

        hideProgressSpinner();
    }, [users])

    const handleConfirmationOk = () => {
        showProgressSpinner({ description: "Registering Profile..." });
        //TODO: This does not ever get called? Why is this code here?
        //If this is needed we need to update call below with correct params
        //if (handleRegisteredUserCreate_d) {
        //    handleRegisteredUserCreate_d(selectedAuction?.id, selectedProfileId, (res) => {
        //        if (res.success) {
        //            ns.success("Profile Successfully Registered");
        //        } else {
        //            ns.error(res.error);
        //        }
        //        hideProgressSpinner();
        //        setConfirmationState({ ...confirmationState, open: false, payload: null })
        //        reloadData(selectedFilters, pagination);
        //    });
        //}
    }

    const handlePageChange = (pagination: any) => {
        reloadData(selectedFilters, pagination);
    }

    const handleFiltersChange = (filters: any) => {
        setSelectedFilters(filters);
        reloadData(filters, null);
    }

    const generateAvailableFilters = [
        {
            description: 'Active',
            propertyMap: 'isActive',
            type: 'boolean',
            options: [
                {
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                }
            ]
        }
    ]

    return (
        <div>
            {confirmationState.open && <ConfirmationDialog
                cancelButtonText='Cancel'
                okButtonText='Confirm'
                title='Confirm'
                description='Are you sure you want to change the users status?'
                onOk={handleConfirmationOk}
                onCancel={() => setConfirmationState({ ...confirmationState, open: false, payload: null })}
                open={confirmationState.open}
                payload={() => { }}
            />}
            <React.Fragment>
                <div className='tab-item-wrapper active'>
                    <div className="text-centre p-3">
                        <NestedAccordionGrid
                            pagination={pagination}
                            selectedFilters={selectedFilters}
                            onFiltersChange={handleFiltersChange}
                            onHandleChangePage={handlePageChange}
                            availableFilters={generateAvailableFilters}
                            onAdd={undefined}
                            onEdit={undefined}
                            dataModel={userDisplayModel}
                            loading={loadingUsers}
                        />
                    </div>
                </div>
            </React.Fragment>

            {error && (
                <Collapse in={error}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    if (clearError_d) {
                                        clearError_d()
                                    }
                                }}
                            >
                                <MdClose fontSize="inherit" />
                            </IconButton>
                        }
                        className="mb-4" severity="error">
                        {errorText}
                    </Alert>
                </Collapse>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearError_d: () => dispatch(clearError()),
        handleUsersFetch_d: () => dispatch(handleUsersFetch()),
        handleUsersFetchWithPagination_d: (filters?: any, pagination?: any, onCompletedCallback?: (res: any) => void) => dispatch(handleUsersFetchWithPagination(filters, pagination, onCompletedCallback)),
        handleResendConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => dispatch(handleResendConfirmAccountEmail(userId, onCompletedCallback)),
        handleResendAdminConfirmAccountEmail_d: (userId: string, onCompletedCallback: (response: any) => void) => dispatch(handleResendAdminConfirmAccountEmail(userId, onCompletedCallback)),
        handleUserProfilesFetch_d: (onCompletedCallback?: (response: any) => void) => dispatch(handleUserProfilesFetch(onCompletedCallback)),
        handleUserAccountUpdateStatus_d: (userId, status, onCompletedCallback: (response: any) => void) => dispatch(handleUserAccountUpdateStatus(userId, status, onCompletedCallback)),
        handleRegisteredUserCreate_d: (auctionId, profileId, profileType, onCompletedCallback: (response: any) => void) => dispatch(handleRegisteredUserCreate(auctionId, profileId, profileType, true, onCompletedCallback)),
        resetAccountState_d: () => dispatch(resetAccountState())
    }
}

const mapStateToProps = (state: any) => ({
    error: state.users.error,
    errorText: state.users.errorText,
    users: state.users.users,
    pagination: state.users.pagination,
    profilesState: state.account.profilesState
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterManualUser);