import React from "react";
import { FinanceDocumentStatus, FinanceDocumentType } from "@/dto/FinanceDocument";

type FinanceDocumentStatusBadgeProps = {
    status: FinanceDocumentStatus;
    type: FinanceDocumentType;
}

export function determineFinanceDocumentStatus(status?: FinanceDocumentStatus, type?: FinanceDocumentType) {
    if (status === FinanceDocumentStatus.NotSent) return 'Not Sent';
    else if (status === FinanceDocumentStatus.Consolidated) return 'Consolidated';
    else if (status === FinanceDocumentStatus.Cancelled) return 'Cancelled';

    else if (type === FinanceDocumentType.ConsolidatedProfomaInvoice && status === FinanceDocumentStatus.Sent) return 'Sent/Unpaid';
    else if (type === FinanceDocumentType.ConsolidatedProfomaInvoice && status === FinanceDocumentStatus.Paid) return 'Paid/Invoiced';

    else if (type === FinanceDocumentType.ProFormaInvoice && status === FinanceDocumentStatus.Sent) return 'Sent/Unpaid';
    else if (type === FinanceDocumentType.ProFormaInvoice && status === FinanceDocumentStatus.Paid) return 'Paid/Invoiced';

    else if (type === FinanceDocumentType.Invoice && status === FinanceDocumentStatus.Sent) return 'Sent/Not-Marked For Release';
    else if (type === FinanceDocumentType.Invoice && status === FinanceDocumentStatus.MarkedForRelease) return 'Marked for Release';

    else if (type === FinanceDocumentType.ReleaseNote && status === FinanceDocumentStatus.Sent) return 'Sent';

    return '-';
}


function FinanceDocumentStatusBadge({ type, status }: FinanceDocumentStatusBadgeProps) {
    return <>{determineFinanceDocumentStatus(status, type)}</>;
}

export default FinanceDocumentStatusBadge;