import React, { } from "react";
import { BidHistoryGrid } from "@/components/BidHistoryGrid";

interface Props {
    selectedAuction: any,
}

const BidHistory: React.FC<Props> = ({ selectedAuction }) => {
    return <BidHistoryGrid auction={selectedAuction} />
};

export default BidHistory;