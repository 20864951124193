import React from 'react';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../../store/reducers/ThemeOptions';

import {
    SidebarHeader,
    SidebarMenu,
    SidebarFooter
} from '../../layout-components';

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))


const Sidebar = (props) => {
    const toggleSidebarMobile = () => {
        setSidebarToggleMobile(!sidebarToggleMobile);
    };

    const {
        sidebarStyle,
        sidebarShadow,
        sidebarFooter,
        sidebarToggleMobile,
        setSidebarToggleMobile,
        settings
    } = props;

    const { classes, cx } = useStyles();
    return (
        <>
            <div
                className={cx('app-sidebar', sidebarStyle, {
                    'app-sidebar--shadow': sidebarShadow
                })}>
                <div className="app-sidebar" style={{ background: settings?.Styles?.OverrideSidebarBackgroundColor ? settings?.Styles?.OverrideSidebarBackgroundColor : settings?.Styles?.Primary }}>
                    <SidebarHeader />
                    <div className="app-sidebar--content" style={{ background: settings?.Styles?.OverrideSidebarBackgroundColor ? settings?.Styles?.OverrideSidebarBackgroundColor : settings?.Styles?.Primary }}>
                        <SidebarMenu />
                    </div>
                </div>
                {sidebarFooter && <SidebarFooter />}
            </div>
            <div
                onClick={toggleSidebarMobile}
                className={cx('app-sidebar-overlay', {
                    'is-active': sidebarToggleMobile
                })}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarStyle: state.ThemeOptions.sidebarStyle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
