import React, { FormEvent, useEffect, useState } from 'react';
import useHistory from '@/hooks/useHistory';
import { connect } from 'react-redux';
import { handleSubmit, loginResetState } from './store/LoginForm';
import useStyles from './LoginFormStyles';
import ns from '@/helpers/NotificationService';
import {
    Button,
    IconButton,
    InputAdornment,
    CssBaseline,
    Container,
    Link,
} from '@mui/material';
import { Alert } from '@mui/lab';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { isValidEmail, parseJwt } from '../../helpers/Utils';
import { PrembidLocalStorage } from '../../helpers/PrembidLocalStorage';
import TextField from '@/components/TextField'
import Version from '../Version';

interface Props {
    handleSubmit_d: (email: string, password: string) => any,
    loginResetState_d: () => void,
    error: boolean,
    errorText: string,
    token: any,
    success: boolean,
    settings: any,
    loading: boolean
}

const LoginCard: React.FC<Props> = props => {

    const history = useHistory();

    const {
        handleSubmit_d,
        loginResetState_d,
        error,
        errorText,
        success,
        settings,
        loading
    } = props;

    const { classes, cx } = useStyles();

    const intialValues = { email: "", password: "" };

    const [submitted, setSubmitted] = useState<boolean>(false);

    const [formFields, setFormFields] = useState(intialValues);
    const [formErrors, setFormErrors] = useState(intialValues);
    const [showPassword, setShowPassword] = useState(false);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value })
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSubmitted(true);
        const onSubmitErrors = validate(formFields);


        if (onSubmitErrors.email.length === 0 && onSubmitErrors.password.length === 0) {
            showProgressSpinner({ description: 'Logging In...' });
            handleSubmit_d(formFields.email, formFields.password);
        }
    }

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            login();
        }
        loginResetState_d();
        setTimeout(() => {
            window.focus();
        }, 2000)
    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) {
            if (errorText.includes("Command=>")) {
                let url = errorText.replace("Command=>", '/');
                history.push(url);
            } else {
                ns.error(errorText);
            }
        }
        loginResetState_d();
    }, [error]);

    const validate = (values) => {
        let errors = { email: '', password: '' };

        if (!values.email) {
            errors.email = "Email field cannot be blank";
        } else if (!isValidEmail(values.email.replace(" ", ""))) {
            errors.email = "Please enter a valid email address.";
        }

        if (!values.password) {
            errors.password = "Password field cannot be blank";
        }

        setFormErrors(errors);

        return errors;
    };

    useEffect(() => {
        if (PrembidLocalStorage.currentUser?.jwtToken) {
            let token = parseJwt(PrembidLocalStorage.currentUser?.jwtToken);
            if (((token.exp * 1000) - 3600000 - Date.now()) > 0) {
                login();
            }
            else { hideProgressSpinner(); }
        }
    }, []);

    const login = () => {
        const params = new URLSearchParams(history.location.search);

        let redirect = params.get('redirect');

        if (redirect) {
            let path = decodeURIComponent(redirect);
            history.push(redirect);
        }
        else {
            let path = '/auctions';
            history.push(path);
        }
    }

    const forgotPassword = () => {
        let path = '/forgotPassword';
        history.push(path);
    }

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

    return (
        <>
            {!loading && (<>
                <form onSubmit={onSubmit}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginLeft: "29" }}>
                            <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                            <h3 className=" text-black text-center">Log In</h3>

                            <TextField
                                margin="normal"
                                id="email"
                                value={formFields.email}
                                onChange={onChange}
                                label={"Email"}
                                placeholder={"Email"}
                                onKeyDown={(e) =>
                                    (e.key === " ") && e.preventDefault()
                                }
                                required
                                autoComplete='username'
                                InputLabelProps={{ shrink: true }}
                                onBlur={() => submitted && validate(formFields)}
                            />

                            {formErrors.email && (<Alert severity='error' style={{ marginBottom: 5 }}>{formErrors.email}</Alert>)}

                            <TextField
                                margin="normal"
                                id="password"
                                value={formFields.password}
                                onChange={onChange}
                                label="Password"
                                placeholder={"Password"}
                                onKeyDown={(e) =>
                                    (e.key === " ") && e.preventDefault()
                                }
                                required
                                autoComplete='current-password'
                                type={showPassword ? "text" : "password"}
                                InputLabelProps={{ shrink: true }}
                                onBlur={() => submitted && validate(formFields)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleTogglePassword}
                                            edge="end"
                                        >
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />

                            {formErrors.password && (<Alert severity='error'>{formErrors.password}</Alert>)}
                            <Button
                                variant="contained" className="btn-primary p-3 mt-4 text-white text-capitalize"
                                type="submit"
                            >
                                Log In
                            </Button>
                            <Link className={classes.link + " text-info text-center mt-2 mb-2"} onClick={forgotPassword} style={{ textDecoration: 'underline' }}>Forgot Password?</Link>
                        </div>
                        <Version style={{ marginTop: 40 }} />
                    </Container>
                </form>
            </>)
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (email: string, password: string) => dispatch(handleSubmit(email, password)),
        loginResetState_d: () => dispatch(loginResetState())
    }
}

const mapStateToProps = (state: any) => ({
    success: state.loginForm.success,
    error: state.loginForm.error,
    errorText: state.loginForm.errorText,
    token: state.loginForm.token,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard)
