import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Dialog, DialogContent, Grid, InputAdornment, MenuItem } from '@mui/material';
import { FinanceDocument, FinanceDocumentItem, FinanceDocumentStatus, FinanceDocumentType } from '@/dto/FinanceDocument';
import { useSelector } from 'react-redux';
import FinanceDocumentTypeBadge from './FinanceDocumentTypeBadge';
import HeaderText from '@/components/HeaderText/HeaderText';
import TextFieldWithLabel from '@/components/TextFieldWithLabel';
import SimpleTable, { ColumnProps } from '@/components/SimpleTable/SimpleTable';
import TextField from '@/components/TextField';
import CurrencyInput from '@/components/CurrencyComponent/CurrencyInput';
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyLabel from '@/components/CurrencyComponent/CurrencyLabel';
import { setJsonValue } from '@/helpers/Utils';
import { handleBankDetailsFetch, retrieveBankDetailsLookup } from '@/pages/BankDetails/store/BankDetails';
import ns from '@/helpers/NotificationService';
import { handleBulkSendFinanceDocument, handleUpdateFinanceDocument, retrieveFinanceDocument } from './store/Invoicing';
import Image from '@/components/Image';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';
import CustomChip from '@/components/Chip/Chip';
import { useAppDispatch } from '../../store/ConfigureStore';
import LoadingButton from '@/components/LoadingButton'
import { handleContactLookupFetch } from '../../pages/Contacts/store/Contacts';
import _ from 'lodash';
import { emailPattern } from '../../helpers/Regex';

type FinanceDocumentModalProps = {
    onClose: (reload?: boolean) => void;
    financeDocumentId: string;
    financeDocumentType: FinanceDocumentType;
    bankDetailsId?: string;
}

const editableTypes = [FinanceDocumentType.ProFormaInvoice, FinanceDocumentType.ConsolidatedProfomaInvoice];
const nonEditableStatusses = [FinanceDocumentStatus.Paid, FinanceDocumentStatus.Cancelled, FinanceDocumentStatus.Consolidated];

function FinanceDocumentModal({
    onClose,
    financeDocumentId,
    financeDocumentType,
    bankDetailsId
}: FinanceDocumentModalProps) {

    const dispatch = useAppDispatch();
    const { settings } = useSelector((state: any) => state.settings);
    const { bankDetailsLookup } = useSelector((state: any) => state.bankDetails);
    const { lookups: contactsLookup } = useSelector((state: any) => state.contacts);

    const [data, setData] = useState<FinanceDocument | null>(null);
    const [bankDetails, setBankDetails] = useState<any>(null);

    const [loadingFinanceDocument, setLoadingFinanceDocument] = useState<boolean>(true);
    const [loadingBankingDetails, setLoadingBankingDetails] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [groupData, setGroupData] = useState<any[]>([]);

    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        if (financeDocumentType !== FinanceDocumentType.ReleaseNote)
            dispatch(retrieveBankDetailsLookup((res) => {
                if (!res.success) ns.error("Failed to retrieve banking details lookup");
            }));

        if (financeDocumentType === FinanceDocumentType.ReleaseNote)
            dispatch(handleContactLookupFetch(true, (res) => {
                if (!res.success) ns.error("Failed to retrieve contacts lookup");
            }));
    }, [])

    useEffect(() => {
        if (financeDocumentId) {
            setLoadingFinanceDocument(true);
            dispatch(retrieveFinanceDocument(financeDocumentId, (res) => {
                if (res.success) {
                    setData({ ...res.response, items: sortBy(res.response.items, 'order') })
                    if (res?.response?.extraData?.groupData) setGroupData(JSON.parse(res.response.extraData.groupData))
                }
                else {
                    ns.error("Failed to retrieve banking details");
                }
                setLoadingFinanceDocument(false);
            }))
        }
    }, [financeDocumentId])

    useEffect(() => {
        if (data?.bankDetailsId) {
            setLoadingBankingDetails(true);
            dispatch(handleBankDetailsFetch(data.bankDetailsId, (res) => {
                if (res.success) {
                    setBankDetails(res.response)
                    setData(oldValues => ({ ...oldValues, bankDetailsId: res.response.id } as FinanceDocument))
                }
                else {
                    ns.error("Failed to retrieve banking details");
                }
                setLoadingBankingDetails(false);
            }))
        }
    }, [data?.bankDetailsId])


    const validate = () => {
        const tempErrors:any = {};

        const email = data?.customerEmail?.trim();
        if (!email || email === '' || !emailPattern.test(email)) {
            tempErrors.email = "Please enter a valid email address.";
        }

        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    }

    useEffect(() => {
        validate();
    }, [data])

    const handleSubmit = (sendEmail = false) => {
        //Add safeguard to not allow sending of email if email is invalid
        if(sendEmail && !validate()) return;

        setSubmitting(true);
        setTimeout(() => {
            dispatch(handleUpdateFinanceDocument(data as FinanceDocument, (res) => {
                if (sendEmail) {
                    dispatch(handleBulkSendFinanceDocument({
                        "bulkSendRequests": [{
                            toOverride: [data?.customerEmail],
                            ccOverride: [],
                            sendToCustomer: null,
                            financeDocumentIds: [data?.id]
                        }]
                    }, (res) => {
                        if (res.success) {
                            switch ((data as FinanceDocument).type) {
                                case FinanceDocumentType.ConsolidatedProfomaInvoice:
                                case FinanceDocumentType.ProFormaInvoice: ns.success("Successfully saved and sent the Pro Forma Invoice"); break;
                                case FinanceDocumentType.Invoice: ns.success("Successfully saved and sent the Tax Invoice"); break;
                                case FinanceDocumentType.ReleaseNote: ns.success("Successfully saved and sent the Release Note"); break;
                            }
                            onClose(true);
                        }
                        else {
                            switch ((data as FinanceDocument).type) {
                                case FinanceDocumentType.ConsolidatedProfomaInvoice:
                                case FinanceDocumentType.ProFormaInvoice: ns.error("Failed to send the Pro Forma Invoice"); break;
                                case FinanceDocumentType.Invoice: ns.error("Failed to send  the Tax Invoice"); break;
                                case FinanceDocumentType.ReleaseNote: ns.error("Failed to send the Release Note"); break;
                                default: ns.error("Failed to send the finance document"); break;
                            }
                        }
                        setSubmitting(false);
                    }))
                }
                else {
                    if (res.success) {
                        switch ((data as FinanceDocument).type) {
                            case FinanceDocumentType.ConsolidatedProfomaInvoice:
                            case FinanceDocumentType.ProFormaInvoice: ns.success("Successfully saved Pro Forma Invoice"); break;
                            case FinanceDocumentType.Invoice: ns.success("Successfully saved the Tax Invoice"); break;
                            case FinanceDocumentType.ReleaseNote: ns.success("Successfully saved the Release Note"); break;
                        }
                        onClose(true);
                    }
                    else {
                        switch ((data as FinanceDocument).type) {
                            case FinanceDocumentType.ConsolidatedProfomaInvoice:
                            case FinanceDocumentType.ProFormaInvoice: ns.error("Failed to save the Pro Forma Invoice"); break;
                            case FinanceDocumentType.Invoice: ns.error("Failed to save  the Tax Invoice"); break;
                            case FinanceDocumentType.ReleaseNote: ns.error("Failed to save the Release Note"); break;
                            default: ns.error("Failed to save the finance document"); break;
                        }
                    }
                    setSubmitting(false);
                }
            }))
        }, 500)
    }

    const emailOnChange = (e) => setData(oldValues => {
        const updatedData = { ...oldValues };
        setJsonValue(updatedData, e.target.id ?? e.target.name, e.target.value.replace(" ", ""));
        return (updatedData as FinanceDocument)
    });

    const onChange = (e) => setData(oldValues => {
        const updatedData = { ...oldValues };
        setJsonValue(updatedData, e.target.id ?? e.target.name, e.target.value);
        return (updatedData as FinanceDocument)
    });

    const canEdit = useMemo(() => editableTypes.includes(financeDocumentType) && !nonEditableStatusses.includes(data?.status ?? FinanceDocumentStatus.Cancelled), [data?.status, financeDocumentType]);
    const canEditBankingDetails = financeDocumentType !== FinanceDocumentType.ReleaseNote;

    const updateRowDataValue = (id: string, property: string, value: any) => {
        setData(oldValues => {
            const existingRowIndex = oldValues?.items?.findIndex(x => x.id === id);
            if (existingRowIndex !== undefined && existingRowIndex > -1) {
                const existingRow = oldValues?.items[existingRowIndex];
                if (existingRow) {
                    setJsonValue(existingRow, property, value);
                    const existingItems = oldValues?.items;
                    existingItems?.splice(existingRowIndex, 1, existingRow)
                    const updatedItems = (existingItems ?? []).map((x, order) => ({ ...x, order }))
                    return { ...oldValues, items: updatedItems } as FinanceDocument
                }
            }
            return oldValues;
        });
    }

    const columns = useMemo<ColumnProps<FinanceDocumentItem>[]>(() => ([
        {
            id: "id",
            header: 'Id',
            accessorKey: 'id',
        },
        {
            id: "group",
            header: 'Group',
            accessorKey: 'group',
        },
        {
            id: "order",
            header: 'Order',
            accessorKey: 'order',
        },
        {
            id: "description",
            header: 'Description',
            accessorKey: 'description',
            enableSorting: false,
            Cell: ({ row, cell }) => (<TextField margin="normal"
                value={cell.getValue<string>()}
                onChange={e => updateRowDataValue(row.getValue('id'), 'description', e.target.value)}
                disabled={!canEdit}
            />),
        },
        {
            id: "applyVat",
            header: 'Zero Rate VAT',
            accessorKey: 'applyVat',
            enableSorting: false,
            Cell: ({ row, cell }) => {
                const value = cell.getValue<boolean>();
                return (<Checkbox
                    checked={!value}
                    onChange={(e, checked) => updateRowDataValue(row.getValue('id'), 'applyVat', !value)}
                    disabled={!canEdit}
                />)
            },
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            id: "totalExclVat",
            header: 'Total Excl.',
            accessorKey: 'totalExclVat',
            enableSorting: false,
            Cell: ({ row, cell }) => (<TextField margin="normal"
                onChange={e => updateRowDataValue(row.getValue('id'), 'totalExclVat', Number(e.target.value))}
                value={cell.getValue<number>()}
                disabled={!canEdit}
                InputProps={{
                    inputComponent: CurrencyInput,
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            disableTypography={false}>
                            <Currency priceWithSymbol={""} />
                        </InputAdornment>
                    ),
                }}
            />),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
    ]), [canEdit])

    const releaseNoteColumns = useMemo<ColumnProps<FinanceDocumentItem>[]>(() => ([
        {
            id: "id",
            header: 'Id',
            accessorKey: 'id',
        },
        {
            id: "group",
            header: 'Group',
            accessorKey: 'group',
        },
        {
            id: "order",
            header: 'Order',
            accessorKey: 'order',
        },
        {
            id: "description",
            header: 'Description',
            accessorKey: 'description',
            enableSorting: false,
        },
        {
            id: "contact",
            header: 'Contact',
            accessorKey: 'extraData.contactId',
            enableSorting: false,
            Cell: ({ row, cell }) => {
                const value = cell.getValue<boolean>();
                return (<TextField margin="normal"
                    onChange={(e) => updateRowDataValue(row.getValue('id'), 'extraData.contactId', e.target.value)}
                    value={value}
                    select
                >
                    {contactsLookup?.map(details => <MenuItem key={details.id} value={details.id} disabled={details.deleted} hidden={details.deleted}>
                        {details.name} {details.deleted && <CustomChip color="error" size="small" label='Deleted' style={{ marginLeft: 6 }} />}
                    </MenuItem>)}
                </TextField>)
            },
        },
        {
            id: "address",
            header: 'Address',
            accessorKey: 'extraData.address',
            enableSorting: false,
            Cell: ({ row, cell }) => (<TextField margin="normal"
                onChange={e => updateRowDataValue(row.getValue('id'), 'extraData.address', e.target.value)}
                value={cell.getValue<string>()}
            />),
        },
    ]), [contactsLookup])

    const totals = useMemo(() => {
        const totalExclVat = data?.items.reduce((acc, item) => acc + item.totalExclVat, 0) ?? 0;
        const vat = (data?.items?.filter(x => x.applyVat).reduce((acc, item) => acc + item.totalExclVat, 0) ?? 0) * (0.15); //TODO: Retrieve VAT from settings
        const totalInclVat = totalExclVat + vat;

        return {
            totalExclVat,
            vat,
            totalInclVat
        }
    }, [data]);

    const renderTotal = (label: string, value: number) => <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div style={{ width: '100%', maxWidth: 140, }}>{label}:</div>
        <CurrencyLabel value={value} style={{ width: '100%', maxWidth: 160, fontWeight: 'bold', textAlign: 'right' }} />
    </div>

    const renderBankDetailItem = (label: string, value: number) => <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div style={{ width: '100%', maxWidth: 140, }}>{label}:</div>
        <div style={{ fontWeight: 'bold' }}>{value}</div>
    </div>

    const handleAddClicked = (index: number, lineItem: FinanceDocumentItem) => {
        setData(oldValues => {
            const clickedItemIndex = oldValues?.items.findIndex(x => x.id === lineItem.id) ?? 0;
            const clickedItem = oldValues?.items[clickedItemIndex];

            const existingItems = [...(oldValues?.items ?? [])];

            const newItemToAdd = {
                id: uuidv4(),
                order: (clickedItem?.order ?? 0) + 1,
                description: "",
                applyVat: true,
                totalExclVat: 0,
                financeDocumentId,
                releaseable: false,
                group: clickedItem?.group ?? 0,
            }

            existingItems?.splice(index + 1, 0, newItemToAdd);

            let group = 0;
            let order = 0;

            const updatedItems = (existingItems ?? []).map((x, i) => {
                if (x.group !== group) {
                    group = x.group;
                    order = 0;
                }
                x.order = order;
                order++;
                return x;
            });

            const orderedItems = _.orderBy(updatedItems, ['group', 'order'])

            return { ...oldValues, items: orderedItems } as FinanceDocument
        });
    }

    const handleRemoveClicked = (index: number) => {
        setData(oldValues => {
            if ((oldValues?.items?.length ?? 0) > 1) {
                const existingItems = [...(oldValues?.items ?? [])];
                existingItems?.splice(index, 1);

                let group = 0;
                let order = 0;

                const updatedItems = (existingItems ?? []).map((x, i) => {
                    if (x.group !== group) {
                        group = x.group;
                        order = 0;
                    }
                    x.order = order;
                    order++;
                    return x;
                });

                const orderedItems = _.orderBy(updatedItems, ['group', 'order'])

                return { ...oldValues, items: orderedItems } as FinanceDocument
            }
            return oldValues;
        });
    }

    const calculateStatusLabel = (status: FinanceDocumentStatus | undefined) => {
        switch (status) {
            case FinanceDocumentStatus.Paid: return "PAID";
            case FinanceDocumentStatus.Cancelled: return "CANCELLED";
            case FinanceDocumentStatus.Consolidated: return "CONSOLIDATED";
            default: return "BALANCE DUE";
        }
    }

    const multipleGroups = useMemo(() => groupData.length > 1, [groupData]);

    return <Dialog
        open={true}
        onClose={(event, reason) => {
            if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
                return;
            onClose();
        }}
        fullWidth
        maxWidth="lg"
    >
        <DialogContent style={{ padding: 20 }}>
            <Grid container spacing={4}>
                <Grid item xs={6} md={4}>
                    <HeaderText header={<FinanceDocumentTypeBadge type={financeDocumentType} />} />
                    <TextFieldWithLabel
                        value={data?.number}
                        id="number"
                        label="Number"
                        disabled={true}
                    />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.reference}
                        id="reference"
                        label="Reference"
                        disabled={!canEdit}
                        required
                    />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.date}
                        id="date"
                        label="Date"
                        disabled={!canEdit}
                        required
                    />
                    <Image
                        src={settings?.VendorLogoUrl}
                        height={200}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <HeaderText header="From" />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.billingCompany}
                        id="billingCompany"
                        label="Company"
                        disabled={!canEdit}
                        required
                    />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.billingVatNumber}
                        id="billingVatNumber"
                        label="Vat No"
                        disabled={!canEdit}
                        required
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.billingAddress?.addressLine1}
                        id="billingAddress.addressLine1"
                        label="Address"
                        placeholder="Address Line 1"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.billingAddress?.addressLine2}
                        id="billingAddress.addressLine2"
                        placeholder="Address Line 2"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.billingAddress?.city}
                        id="billingAddress.city"
                        placeholder="City"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.billingAddress?.state}
                        id="billingAddress.state"
                        placeholder="State/Province"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.billingAddress?.zip}
                        id="billingAddress.zip"
                        placeholder="Postal Code"
                        disabled={!canEdit}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <HeaderText header="To" />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.customerName}
                        id="customerName"
                        label="Customer"
                        required
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        onChange={emailOnChange}
                        value={data?.customerEmail}
                        id="customerEmail"
                        label="Email"
                        required
                        onBlur={validate}
                    />
                    <TextFieldWithLabel
                        onChange={onChange}
                        value={data?.customerVatNumber}
                        id="customerVatNumber"
                        label="Vat No"
                        disabled={!canEdit}
                        required
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerAddress?.addressLine1}
                        id="customerAddress.addressLine1"
                        label="Address"
                        placeholder="Address Line 1"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerAddress?.addressLine2}
                        id="customerAddress.addressLine2"
                        placeholder="Address Line 2"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerAddress?.city}
                        id="customerAddress.city"
                        placeholder="City"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerAddress?.state}
                        id="customerAddress.state"
                        placeholder="State/Province"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerAddress?.zip}
                        id="customerAddress.zip"
                        placeholder="Postal Code"
                        disabled={!canEdit}
                    />
                    <TextFieldWithLabel
                        required
                        onChange={onChange}
                        value={data?.customerPhoneNumber}
                        id="customerPhoneNumber"
                        placeholder="Phone Number"
                        disabled={!canEdit}
                    />
                </Grid>
            </Grid>
            <br />
            {data && <SimpleTable
                columnHeaders={financeDocumentType !== FinanceDocumentType.ReleaseNote ? columns : releaseNoteColumns}
                hideColumns={['group', 'order']}
                sorting={[{ id: 'group', desc: false }, { id: 'order', desc: false }]}
                tableData={financeDocumentType !== FinanceDocumentType.ReleaseNote ? data?.items ?? [] : (data?.items ?? []).filter(x => x.releaseable)}
                type='compact'
                renderRowActions={canEdit ? (row, isLoading) => {
                    return <>
                        <IconButton
                            type="success"
                            onClick={() => handleAddClicked(row.index, row.original)}
                            toolTip='Add'
                            icon={<FaPlus />}
                        />
                        <IconButton
                            type="danger"
                            onClick={() => {
                                handleRemoveClicked(row.index);
                            }}
                            toolTip='Remove'
                            icon={<FaMinus />}
                        />
                    </>
                } : undefined}

                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                expandedByDefault={true}
                groupBy={groupData ? ['group'] : undefined}
                showExpandAllButton={true}
            />}
            <br />

            <Grid container spacing={2}>
                <Grid item md={6}>
                    <TextField
                        margin="normal"
                        onChange={onChange}
                        value={data?.note}
                        id="note"
                        placeholder='Enter a note'
                        multiline
                        minRows={5}
                        size="small"
                    />
                </Grid>
                <Grid item md={6}>
                    {financeDocumentType !== FinanceDocumentType.ReleaseNote && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap: 10, padding: 10 }}>
                        {renderTotal("Total Excl. VAT", totals.totalExclVat)}
                        {renderTotal("VAT", totals.vat)}
                        {renderTotal("Total Incl. VAT", totals.totalInclVat)}
                        {(financeDocumentType === FinanceDocumentType.ProFormaInvoice || financeDocumentType === FinanceDocumentType.ConsolidatedProfomaInvoice) && <div style={{ width: '100%', textAlign: 'right', paddingTop: 10 }}>{calculateStatusLabel(data?.status)}</div>}
                        {financeDocumentType === FinanceDocumentType.Invoice && <div style={{ width: '100%', textAlign: 'right', paddingTop: 10 }}>PAID</div>}
                    </div>}
                    {financeDocumentType === FinanceDocumentType.ReleaseNote && <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'flex-end', gap: 10, padding: 10 }}>
                        <div style={{ width: '100%', textAlign: 'right', paddingTop: 10, fontSize: 20, fontWeight: 'bold', color: settings?.Styles?.Primary }}>Approved for Release</div>
                    </div>}
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={6}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        {financeDocumentType !== FinanceDocumentType.ReleaseNote && <>
                            {data && bankDetailsLookup && canEditBankingDetails && <TextField
                                margin="normal"
                                onChange={onChange}
                                value={data?.bankDetailsId}
                                id="bankDetailsId"
                                name='bankDetailsId'
                                label="Bank"
                                select
                            >
                                {bankDetailsLookup?.map(details => <MenuItem key={details.id} value={details.id} disabled={details.deleted} hidden={details.deleted}>
                                    {details.name} {details.deleted && <CustomChip color="error" size="small" label='Deleted' style={{ marginLeft: 6 }} />}
                                </MenuItem>)}
                            </TextField>}
                            <div>Please make Payment to:</div>
                            {renderBankDetailItem("Bank", bankDetails?.bankName)}
                            {renderBankDetailItem("Account Name", bankDetails?.name)}
                            {renderBankDetailItem("Branch Code", bankDetails?.branchCode)}
                            {renderBankDetailItem("Branch Name", bankDetails?.branchName)}
                            {renderBankDetailItem("Account Number", bankDetails?.accountNumber)}
                            {renderBankDetailItem("SWIFT Code", bankDetails?.swiftCode)}
                        </>}
                        <span className="btn-wrapper--icon">
                            <h6>Powered by <img style={{ cursor: 'pointer', maxWidth: '200px' }} src={settings?.ProductLogoUrl} /></h6>
                        </span>
                    </div>
                </Grid>
                <Grid item md={2}
                    style={{ display: 'flex', paddingBottom: 20 }}
                    alignItems='flex-end'
                    justifyContent='flex-end'
                >
                    <LoadingButton loading={submitting} disabled={submitting} onClick={() => handleSubmit()} label='Save' fullHeight={false} />
                </Grid>
                <Grid item md={2}
                    style={{ display: 'flex', paddingBottom: 20 }}
                    alignItems='flex-end'
                    justifyContent='flex-end'
                >
                    <LoadingButton loading={submitting} type='secondary' disabled={submitting || !!errors.email} disabledToolTip={!!errors.email ? "Please enter a valid email address." : ""} onClick={() => handleSubmit(true)} label='Save + Email' fullHeight={false} />
                </Grid>
                <Grid item md={2}
                    style={{ display: 'flex', paddingBottom: 20 }}
                    alignItems='flex-end'
                    justifyContent='flex-end'
                >
                    <Button onClick={() => onClose()} type="danger" label="Cancel" fullHeight={false} />
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog >
}

export default FinanceDocumentModal;