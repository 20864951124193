import React from "react";
import { NumericFormat } from 'react-number-format';
import Currency from '../CurrencyComponent/CurrencyComponent';

interface Props {
    value: string | number | undefined
    prefixString?: string,
    textColour?: string,
    style?: any;
    hideCurrencySymbol?: boolean
}

const CurrencyLabel: React.FC<Props> = (props) => {
    const { value, prefixString, textColour, style, hideCurrencySymbol } = props;

    return (
        <NumericFormat
            value={value}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            thousandSeparator=","
            style={style}
            renderText={(value) => <Currency style={style} priceWithSymbol={value} prefixString={prefixString} textColour={textColour} hideCurrencySymbol={hideCurrencySymbol} />} />
    );
};

export default CurrencyLabel;