import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import { User } from '@/dto/User';

//Action
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOGIN_IN_RESET_STATE = 'LOGIN_IN_RESET_STATE'
export const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false,
    user: {
        jwtToken:'',
        userId:''
    }
}


export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case LOG_IN_SUCCESS: return { ...state, success: true, error: false, user:{...state.user,jwtToken:action.payload.jwtToken,userId:action.payload.userId}  }
        case LOG_IN_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        case LOGIN_IN_RESET_STATE: return { ...state, success: false, error: false }
        case CHANGE_PROFILE_SUCCESS: return { ...state, changeProfileId: action.payload, loginSuccess: true, logoutSuccess: false, success: true, error: false, user: null }
        default: return state;
    }
}

//Action creator
export const loginSuccess = (user: User) => {
    return { type: LOG_IN_SUCCESS, payload: user }

}

export const loginError = (errorText: string) => {
    return { type: LOG_IN_ERROR, payload: errorText }
}

export const loginResetState = () => {
    return { type: LOGIN_IN_RESET_STATE, payload: LOGIN_IN_RESET_STATE }
}

export const changeProfileId = (profileId: string) => {
    return { type: CHANGE_PROFILE_SUCCESS, payload: profileId }
}

//Thunks
export const handleSubmit = (email: string, password: string) => {

    let userInfo = {
        Email: email.replace(" ", ""),
        Password: password,
        FirebaseToken: ""
    };

    return (dispatch: any) => {
        return requestClient.post(apiPaths.account.login + "?platform=adminportal", userInfo)
            .then(function (resp) {
                if (resp.success) {
                    console.log(resp.response)
                    const user = resp.response;
                    const { profiles } = user;
                    PrembidLocalStorage.currentUser = (user) as User;
                    dispatch(loginSuccess(resp.response));

                    const defaultProfile = profiles.find(({ isDefaultProfile }) => isDefaultProfile === true);

                    if (defaultProfile) {
                        dispatch(handleChangeProfile(defaultProfile.profileId));
                    }
                }
                else {
                    dispatch(loginError(resp.error));
                }
            });
    }
}

export const handleChangeProfile = (profileId: string) => {
    PrembidLocalStorage.currentProfile = profileId;

    return (dispatch: any) => {
        return dispatch(changeProfileId(profileId));
    }
}