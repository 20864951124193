// @s-nocheck
// TODO Need to convert this file to full typescript instead of javascript by defining types

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { handleConfigsFetch, handleConfigVersionFetch, serverDateTimeDifferenceFetchError, serverDateTimeDifferenceFetchSuccess } from './store/Settings';
import { AuthRoute } from './AuthRoute';

import Login from './pages/Login/Login';
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount';
import ConfirmAccountConfirmation from './pages/ConfirmAccountConfirmation/ConfirmAccountConfirmation';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import EmailChangeConfirmation from './pages/EmailChangeConfirmation/EmailChangeConfirmation';
import ForgotPasswordConfirmation from './pages/ForgotPasswordConfirmation/ForgotPasswordConfirmation';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ResetPasswordConfirmation from './pages/ResetPasswordConfirmation/ResetPasswordConfirmation';
import Account from './pages/Profile/ProfileEdit';

import Dashboard from "./pages/Dashboard/Dashboard";
import Auctions from "./pages/Auctions/Auctions";
import AuctionsEditAdd from './pages/Auctions/AuctionsEditAdd';
import LotsEditAdd from './pages/Lots/LotsEditAdd';
import LotTypes from "./pages/LotTypes/LotTypes";
import LotTypeEditAdd from "./pages/LotTypes/LotTypeEditAdd";
import DocumentTemplate from "./pages/Documents/DocumentTemplate";
import DocumentTemplateEditAdd from "./pages/Documents/DocumentTemplateEditAdd";
import Venue from "./pages/Venue/Venue";
import VenueEdit from "./pages/Venue/VenueEdit";
import User from "./pages/Users/User";
import UserEditAdd from "./pages/Users/UserEditAdd";
import LeftSidebar from './components/LeftSidebar/LeftSidebar';
import AuctionManagement from './pages/AuctionManagement/AuctionManagement';
import Settings from './pages/Settings/Settings';
import CataloguePrint from '../src/pages/AuctionManagement/CataloguePrint';
import { hideProgressSpinner } from './helpers/ProgressSpinnerService';
import Contacts from '@/pages/Contacts/Contacts';
import ContactEditAdd from './pages/Contacts/ContactEditAdd';
import Lots from './pages/Lots/Lots';
import Integration from './pages/Integration/Integration';
import ActionEdit from './pages/Integration/ActionEdit';
import ReorderAuction from '@/pages/Auctions/ReorderAuction';
import BankDetails from './pages/BankDetails/BankDetails';
import BankDetailsAddEdit from './pages/BankDetails/BankDetailsAddEdit';
import BiddingCalculations from './pages/BiddingCalculations/BiddingCalculations';
import BiddingCalculationsAddEdit from './pages/BiddingCalculations/BiddingCalculationsAddEdit';
import IncrementTable from './pages/IncrementTable/IncrementTable';
import IncrementTableEditAdd from './pages/IncrementTable/IncrementTableEditAdd';
import BulkUploads from './pages/BulkUpload/BulkUploads';
import BulkUploadsAddEdit from './pages/BulkUpload/BulkUploadAddEdit'
import { useFetchServerDateTimeDifference } from "prembid-shared-library-npm/networking";
import ns from "@/helpers/NotificationService";
import { DialogContentText } from '@mui/material';
import { RootState } from './store/ConfigureStore';
import { PrembidLocalStorage } from 'prembid-shared-library-npm/helpers';
import { onReceiveMessage, clearOnReceiveMessage } from 'prembid-shared-library-npm/signalr';
import useHistory from './hooks/useHistory';

export const activeRouteConfig = {
    auctions: [
        "/auctions",
        "/auctionadd",
        "/auctionedit",
        "/bulkuploads",
        "/bulkuploadsedit",
        "/bulkuploadsadd"
    ],
    auctionManagement: ["/auctionmanagement"],
    lots: [
        "/lots",
        "/lotedit"
    ],
    contacts: [
        "/contacts",
        "/contactedit",
        "/contactadd"
    ],
    users: [
        "/users",
        "/useredit"
    ],
    listingTypes: [
        "/lottypes",
        "/lottypeedit"
    ],
    documents: [
        "/documenttemplates",
        "/documenttemplateedit"
    ],
    bankDetails: [
        "/bankdetails",
        "/bankdetailsedit",
        "/bankdetailsadd"
    ],
    biddingCalculations: [
        "/biddingcalculations",
        "/biddingcalculationsedit",
        "/biddingcalculationsadd"
    ],
    venues: [
        "/venue",
        "/venueedit"
    ],
    integration: [
        "/integration",
        "/action"
    ],
    incrementTable: [
        "/incrementtable",
        "/incrementtableadd",
        "/incrementtableedit"
    ]
}

const unAuthorisedRoutes = {
    '/login': <Login />,
    '/confirmAccount/:userId/:tokenId': <ConfirmAccount />,
    '/confirmAccountConfirmation': <ConfirmAccountConfirmation />,
    '/confirmEmail/:userId/:tokenId': <EmailChangeConfirmation />,
    '/forgotPassword': <ForgotPassword />,
    '/forgotPasswordConfirmation': <ForgotPasswordConfirmation />,
    '/resetPassword/:userId/:tokenId': <ResetPassword />,
    '/ResetPasswordConfirmation': <ResetPasswordConfirmation />,
    '/cataloguePrint/:auctionId': <CataloguePrint />,
}

const authorisedRoutes = {
    "/": <Auctions />,
    "/Dashboard": <Dashboard />,
    "/Auctions": <Auctions />,
    "/AuctionAdd": <AuctionsEditAdd />,
    "/AuctionEdit": <AuctionsEditAdd />,
    "/Venue": <Venue />,
    "/LotAdd": <LotsEditAdd />,
    "/LotEdit": <LotsEditAdd />,
    "/LotTypes": <LotTypes />,
    "/LotTypeAdd": <LotTypeEditAdd />,
    "/LotTypeEdit": <LotTypeEditAdd />,
    "/DocumentTemplates": <DocumentTemplate />,
    "/DocumentTemplateAdd": <DocumentTemplateEditAdd />,
    "/DocumentTemplateEdit": <DocumentTemplateEditAdd />,
    "/VenueEdit": <VenueEdit />,
    "/Users": <User />,
    "/UserEdit": <UserEditAdd />,
    "/AuctionManagement": <AuctionManagement />,
    '/Account': <Account />,
    '/Settings': <Settings />,
    '/Contacts': <Contacts />,
    '/ContactEdit': <ContactEditAdd />,
    '/ContactAdd': <ContactEditAdd />,
    '/Lots': <Lots />,
    '/Integration': <Integration />,
    '/Action/:id': <ActionEdit />,
    '/ReorderAuction/:auctionId': <ReorderAuction />,
    '/BankDetailsEdit/:id': <BankDetailsAddEdit />,
    '/BankDetailsAdd': <BankDetailsAddEdit />,
    '/BankDetails': <BankDetails />,
    '/BiddingCalculationsEdit/:id': <BiddingCalculationsAddEdit />,
    '/BiddingCalculationsAdd': <BiddingCalculationsAddEdit />,
    '/BiddingCalculations': <BiddingCalculations />,
    '/IncrementTable': <IncrementTable />,
    '/IncrementTableAdd': <IncrementTableEditAdd />,
    '/IncrementTableEdit/:id': <IncrementTableEditAdd />,
    '/BulkUploadsEdit/:id': <BulkUploadsAddEdit />,
    '/BulkUploadsAdd': <BulkUploadsAddEdit />,
    '/BulkUploads/:auctionId': <BulkUploads />
}

interface Props {
    handleConfigVersionFetch_d: () => void,
    handleConfigsFetch_d: (version: any) => void,
    configVersionRetrieved: boolean,
    configsRetrieved: boolean,
    settings: any,
    configVersion: any,
}

//This must stay as new Date() to get the local time. Do not change
const clientTimestamp: number = new Date().getTime();

const RoutesComponent: React.FC<Props> = (props) => {
    const {
        handleConfigVersionFetch_d,
        handleConfigsFetch_d,
        configVersionRetrieved,
        configsRetrieved,
        settings,
        configVersion,
    } = props;

    let modalOpened: boolean = false;
    let retryValidator: number = 0;

    const dispatch = useDispatch();
    const history = useHistory();

    const navigateLogout = () => {
        PrembidLocalStorage.clearCurrentUser();
        let path = "/login";
        history.push(path);
    };

    useEffect(() => {
        const callbackId = onReceiveMessage((message: string) => {
            if (message) {
                var payload = JSON.parse(message);

                if (payload?.data) {
                    const { messageSubject = undefined, userId = undefined } = payload.data;

                    console.log(`${messageSubject}=>`, PrembidLocalStorage.currentUser?.userId, userId);

                    if (messageSubject === "UserRolesUpdated" && PrembidLocalStorage.currentUser?.userId === userId) {
                        navigateLogout();

                        ns.showDialog({
                            title: "Session Expired",
                            message: "Your session has been expired by an administrator. Please login again or contact the administrator.",
                            onSubmit: () => { },
                            locales: { submitText: "Ok" }
                        })
                    }
                }
            }
        });

        return () => clearOnReceiveMessage(callbackId);
    }, []);

    const { refetch: refreshServerTimeOffset } = useFetchServerDateTimeDifference({ pathParams: { localDateTimeTicks: clientTimestamp } },
        {
            enabled: false,
            onSuccess(res) {
                //This must stay as new Date() to get the local time. Do not change
                const nowTimeStamp: number = new Date().getTime();

                const diff: number = res?.diff;
                const roundTrip = Math.abs(nowTimeStamp - clientTimestamp);
                const estimatedRequestTime = roundTrip / 2;

                const finalOffset = diff - estimatedRequestTime;

                dispatch(serverDateTimeDifferenceFetchSuccess(Math.abs(finalOffset) <= 400 ? 0 : finalOffset));
                handleConfigVersionFetch_d();
            },
            onError(error) {

                //TODO: We need a new Error Page that we redirect to incase this fails. Failure could be due to internet, server etc
                dispatch(serverDateTimeDifferenceFetchError(error?.message))
            }
        }
    );

    const serverTimeOffsetTimerTickInterval = () => {
        let oldTime = new Date().getTime();
        let isWorkerInitialized = false;

        const newWorker = new Worker('/worker.js');
        newWorker.onmessage = function (e) {
            const time = new Date().getTime();

            if (Math.abs(time - oldTime) > 20000 && isWorkerInitialized) {
                if (retryValidator > 2) {
                    retryValidator = 0;

                    if (!modalOpened) {
                        modalOpened = true;
                        ns.showDialog({
                            title: "Sync Your Clock for Optimal Experience",
                            content: (
                                <>
                                    <DialogContentText className="mt-3 mb-3">To ensure the best user experience, we've noticed your device's time is out of sync with our server.</DialogContentText>

                                    <DialogContentText className="mb-3">Please click the 'Sync' button to resync your clock and maintain seamless access to all features. Please note this will refresh your browser.</DialogContentText>

                                    <DialogContentText>Thank you for helping us enhance your experience! </DialogContentText>
                                </>
                            ),
                            locales: {
                                submitText: "Sync",
                            },
                            onSubmit: () => { window.location.reload(); },
                            allowCloseOnBackDropClick: false
                        });
                    }
                } else {
                    retryValidator++;
                }
            } else {
                isWorkerInitialized = true;
                oldTime = new Date().getTime();
            }
        };

        newWorker.postMessage({ action: 'start', interval: 1000 });

        return () => {
            newWorker.postMessage({ action: 'stop' });
            newWorker.terminate();
        };
    }

    //This post is used to get the difference between the client and server time and thereafter used to set the timers in the app
    useEffect(() => {
        refreshServerTimeOffset();
    }, []);

    useEffect(() => {
        if (configVersionRetrieved && configsRetrieved) {
            serverTimeOffsetTimerTickInterval();
        }
    }, [configVersionRetrieved, configsRetrieved]);

    useEffect(() => {
        if (configVersionRetrieved) {
            handleConfigsFetch_d(configVersion);
        }
    }, [configVersionRetrieved]);

    useEffect(() => {
        if (configVersionRetrieved && configsRetrieved) {
            if (document?.getElementById("favicon") && settings?.VendorName && settings?.VendorFaviconUrl) {
                document.title = settings.VendorName

                var link = document.querySelector("link[id~='favicon']");
                if (!link) {
                    link = document.createElement('link');
                    link.id = 'favicon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                (link as HTMLAnchorElement).href = settings.VendorFaviconUrl;
            }
            hideProgressSpinner();
        }
    }, [configVersionRetrieved, configsRetrieved, settings?.VendorFaviconUrl, settings?.VendorName]);

    useEffect(() => {
        if (settings?.Styles) {
            Object.keys(settings.Styles).forEach(x => {
                if (x.toLowerCase() === 'fontfilename') {
                    var newStyle = document.createElement('style');
                    newStyle.appendChild(document.createTextNode("\
                        @font-face {\
                            font-family: customFont;\
                            src: url('/api/Vendor/RetrieveFont/" + settings.VendorName + "/" + settings.Styles[x] + "');\
                        }\
                    "));

                    document.head.appendChild(newStyle);
                } else {
                    document.documentElement.style.setProperty(`--${x.toLowerCase()}`, settings.Styles[x]);
                }
            })
        }
    }, [settings]);

    return (<>
        {configVersionRetrieved && configsRetrieved && <div>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                {Object.keys(unAuthorisedRoutes).map(path => (<Route path={path} element={unAuthorisedRoutes[path]} />))}

                <Route element={<AuthRoute />}>
                    <Route element={<LeftSidebar />}>
                        {Object.keys(authorisedRoutes).map((path) => (
                            <Route path={path} element={authorisedRoutes[path]} />
                        ))}
                    </Route>
                </Route>
                <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
        </div>}
    </>
    )
}

const mapStateToProps = (state: any) => ({
    configsRetrieved: state.settings.configsRetrieved,
    configVersionRetrieved: state.settings.configVersionRetrieved,
    settings: state.settings.settings,
    configVersion: state.settings.configVersion,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleConfigVersionFetch_d: () => dispatch(handleConfigVersionFetch()),
        handleConfigsFetch_d: (version: any) => dispatch(handleConfigsFetch(version))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesComponent);
