import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Sidebar from '../../bamburgh-theme/layout-components/Sidebar';
import Header from '../../bamburgh-theme/layout-components/Header';
import Footer from '../../bamburgh-theme/layout-components//Footer';
import { getUserTermsAcceptance, handleSubmitTermsAcceptance } from '@/pages/Profile/store/Profile'
import TermsOfUseModal from "@/components/Modal/TermsOfUseModal";
import { makeStyles } from 'tss-react/mui';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({

}))

const LeftSidebar = props => {
    const {
        children,
        sidebarToggle,
        sidebarToggleMobile,
        sidebarFixed,
        headerFixed,
        headerSearchHover,
        headerDrawerToggle,
        footerFixed,
        contentBackground,
        getTermsAcceptance_d,
        handleSubmitTermsAcceptance_d,
        userTermsAcceptance,
    } = props;

    const { classes, cx } = useStyles();

    const [termsOfUseModal, setTermsOfUseModal] = React.useState<boolean>(false);


    useEffect(() => {
        getTermsAcceptance_d();
    }, []);

    useEffect(() => {
        if (userTermsAcceptance === false) {
            setTermsOfUseModal(true);
        }
    }, [userTermsAcceptance])


    const onSubmit = () => {
        setTermsOfUseModal(false);
        handleSubmitTermsAcceptance_d();
    };

    return (
        <>
            <TermsOfUseModal
                onSubmit={() => onSubmit()}
                isDialogOpened={termsOfUseModal}
                handleCloseDialog={() => setTermsOfUseModal(false)}
            />
            <div
                className={cx('app-wrapper', contentBackground, {
                    'headerDrawerOpen': headerDrawerToggle,
                    'app-sidebar-collapsed': !sidebarToggle,
                    'app-sidebar-mobile-open': sidebarToggleMobile,
                    'app-sidebar-fixed': sidebarFixed,
                    'app-header-fixed': headerFixed,
                    'app-footer-fixed': footerFixed,
                    'search-wrapper-open': headerSearchHover
                })}>

                <div>
                    <Sidebar />
                </div>
                <div className="app-main">
                    <Header />
                    <div className='app-content'>
                        <div className='app-content--inner'>
                            <div className='app-content--inner__wrapper' >
                                <Outlet />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

LeftSidebar.propTypes = {
    children: PropTypes.node
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getTermsAcceptance_d: () => dispatch(getUserTermsAcceptance()),
        handleSubmitTermsAcceptance_d: () => dispatch(handleSubmitTermsAcceptance()),
    }
}

const mapStateToProps = state => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    headerFixed: state.ThemeOptions.headerFixed,
    headerSearchHover: state.ThemeOptions.headerSearchHover,
    headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

    footerFixed: state.ThemeOptions.footerFixed,

    contentBackground: state.ThemeOptions.contentBackground,
    userTermsAcceptance: state.account.userTermsAcceptance
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);