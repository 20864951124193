import React from 'react';
import { connect } from 'react-redux';
import ProgressSpinner from '@/components/ProgressSpinner/ProgressSpinner';
import { handleSettingsFetch, handleSettingsUpdate, clearApiResponses, clearError } from './store/Settings';
import {
    Button,
    Collapse,
    IconButton,
    Card,
    List,
    ListItem,
    Grid,
    Typography,
    MenuItem,
    TextField,
    InputAdornment
} from '@mui/material';
import {
    Alert
} from '@mui/lab';
import ImageUploader, { Image } from '@/components/ImageUploader/ImageUploader';
import ns from '@/helpers/NotificationService';
import { FaFacebook, FaYoutube, FaTwitter, FaInstagram, FaTimes, FaSave } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { ChromePicker } from 'react-color';
import useStyles from './SettingsStyles';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';


interface Props {
    handleSettingsFetch_d: () => void,
    handleSettingsUpdate_d: (formFields: any) => void,
    clearApiResponses_d: () => void,
    clearError_d: () => void,
    error: boolean,
    errorText: string,
    success: boolean,
    reload: boolean,
    accountSettings: any,
    loading: boolean
}

const Settings: React.FC<Props> = props => {

    const [activeTab, setActiveTab] = React.useState('0');
    const [formData, setFormData] = React.useState<any>({
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            country: '',
            state: '',
            zip: '',
        },
        vendorStyling: {
            primaryColour: '#3228BE',
            secondaryColour: '#991818',
            tertiaryColour: '#47CA19',
            fontFamily: '',
        },
        vendorLogoImages: {
            imageUrl: ''
        },
        vendorClientWebPortalBackgroundImages: {
            imageUrl: '',
        },
        name: '',
        slogan: '',
        description: '',
        currency: '',
        timezone: '',
        lotNumberRangeStart: 0,
        lotNumberRangeEnd: 0,
        lotNumberPrefix: '',
        bidExtenstionTime: 0,
        numberOfLotImages: 0,
        vendorEmail: '',
        vendorPhone: '',
        twitter: '',
        instagram: '',
        youtube: '',
        facebook: ''
    });
    const [showPrimaryColourPicker, setShowPrimaryColourPicker] = React.useState(false);
    const [showSecondaryColourPicker, setShowSecondaryColourPicker] = React.useState(false);
    const [showTertiaryColourPicker, setShowTertiaryColourPicker] = React.useState(false);
    const [tabsErrors, setTabsErrors] = React.useState({
        settingsTabError: false,
        contactTabError: false,
        addressTabError: false,
        imagesTabError: false
    });
    const [settingsFormFieldsValidationShowErrors, setSettingsFormFieldsValidationShowErrors] = React.useState<any>({
        name: false,
        slogan: false,
        description: false,
        fontFamily: false,
        currency: false,
        timeZone: false,
        numberOfLotImages: false,
        lotNumberPrefix: false,
        lotNumberRangeStart: false,
        lotNumberRangeEnd: false,
        bidExtentionTime: false,
    });
    const [contactFormFieldsValidationShowErrors, setContactFormFieldsValidationShowErrors] = React.useState({
        email: false,
        number: false,
        instgram: false,
        twitter: false,
        facebook: false,
        youtube: false
    });
    const [addressFormFieldsValidationShowErrors, setAddressFormFieldsValidationShowErrors] = React.useState({
        addressLine1: false,
        addressLine2: false,
        country: false,
        city: false,
        state: false,
        zip: false
    });
    const [imageValidationShowErrors, setImageShowErrors] = React.useState({
        hero: false
    });

    const {
        handleSettingsFetch_d,
        handleSettingsUpdate_d,
        clearError_d,
        error,
        errorText,
        success,
        clearApiResponses_d,
        accountSettings,
        loading
    } = props;

    const { classes, cx } = useStyles();

    React.useEffect(() => {
        showProgressSpinner();
        handleSettingsFetch_d()
    }, [])

    React.useEffect(() => {
        if (accountSettings) {
            const newFormFields = {
                id: accountSettings.id,
                address: accountSettings.address,
                vendorStyling: accountSettings.vendorStyling,
                vendorLogoImages: accountSettings.vendorLogoImages,
                vendorClientWebPortalBackgroundImages: accountSettings.vendorClientWebPortalBackgroundImages,
                name: accountSettings.name,
                slogan: accountSettings.slogan,
                description: accountSettings.description,
                currency: '',
                timezone: '',
                lotNumberRangeStart: 0,
                lotNumberRangeEnd: 0,
                lotNumberPrefix: '',
                bidExtenstionTime: 0,
                numberOfLotImages: 0,
                vendorEmail: '',
                vendorPhone: '',
                twitter: '',
                instagram: '',
                youtube: '',
                facebook: ''
            }

            setFormData(newFormFields)
        }

        var waitForGridRender = new Promise(r => setTimeout(r, 500));
        waitForGridRender.then(() => { hideProgressSpinner() });

    }, [accountSettings])

    React.useEffect(() => {
        if (success) {
            clearApiResponses_d()
            ns.success("Setting successfully saved")
            hideProgressSpinner();
        }
    }, [success]);

    const timezones = [
        {
            "value": -12,
            "text": "(GMT -12:00) Eniwetok, Kwajalein"
        },
        {
            "value": -11,
            "text": "(GMT -11:00) Midway Island, Samoa"
        },
        {
            "value": -10,
            "text": "(GMT -10:00) Hawaii"
        },
        {
            "value": -9,
            "text": "(GMT -9:00) Alaska"
        },
        {
            "value": -8,
            "text": "(GMT -8:00) Pacific Time (US & Canada)"
        },
        {
            "value": -7,
            "text": "(GMT -7:00) Mountain Time (US & Canada)"
        },
        {
            "value": -6,
            "text": "(GMT -6:00) Central Time (US & Canada), Mexico City"
        },
        {
            "value": -5,
            "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
        },
        {
            "value": -4,
            "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
        },
        {
            "value": -3.5,
            "text": "(GMT -3:30) Newfoundland"
        },
        {
            "value": -3,
            "text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
        },
        {
            "value": -2,
            "text": "(GMT -2:00) Mid-Atlantic"
        },
        {
            "value": -1,
            "text": "(GMT -1:00) Azores, Cape Verde Islands"
        },
        {
            "value": 0,
            "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca"
        },
        {
            "value": 1,
            "text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
        },
        {
            "value": 2,
            "text": "(GMT +2:00) Kaliningrad, South Africa"
        },
        {
            "value": 3,
            "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
        },
        {
            "value": 3.5,
            "text": "(GMT +3:30) Tehran"
        },
        {
            "value": 4,
            "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
        },
        {
            "value": 4.5,
            "text": "(GMT +4:30) Kabul"
        },
        {
            "value": 5,
            "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
        },
        {
            "value": 5.5,
            "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
        },
        {
            "value": 5.75,
            "text": "(GMT +5:45) Kathmandu"
        },
        {
            "value": 6,
            "text": "(GMT +6:00) Almaty, Dhaka, Colombo"
        },
        {
            "value": 7,
            "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta"
        },
        {
            "value": 8,
            "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
        },
        {
            "value": 9,
            "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
        },
        {
            "value": 9.5,
            "text": "(GMT +9:30) Adelaide, Darwin"
        },
        {
            "value": 10,
            "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
        },
        {
            "value": 11,
            "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
        },
        {
            "value": 12,
            "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
        }
    ]

    const currencyList = [
        { "cc": "AED", "symbol": "\u062f.\u0625;", "name": "UAE dirham" },
        { "cc": "AFN", "symbol": "Afs", "name": "Afghan afghani" },
        { "cc": "ALL", "symbol": "L", "name": "Albanian lek" },
        { "cc": "AMD", "symbol": "AMD", "name": "Armenian dram" },
        { "cc": "ANG", "symbol": "NA\u0192", "name": "Netherlands Antillean gulden" },
        { "cc": "AOA", "symbol": "Kz", "name": "Angolan kwanza" },
        { "cc": "ARS", "symbol": "$", "name": "Argentine peso" },
        { "cc": "AUD", "symbol": "$", "name": "Australian dollar" },
        { "cc": "AWG", "symbol": "\u0192", "name": "Aruban florin" },
        { "cc": "AZN", "symbol": "AZN", "name": "Azerbaijani manat" },
        { "cc": "BAM", "symbol": "KM", "name": "Bosnia and Herzegovina konvertibilna marka" },
        { "cc": "BBD", "symbol": "Bds$", "name": "Barbadian dollar" },
        { "cc": "BDT", "symbol": "\u09f3", "name": "Bangladeshi taka" },
        { "cc": "BGN", "symbol": "BGN", "name": "Bulgarian lev" },
        { "cc": "BHD", "symbol": ".\u062f.\u0628", "name": "Bahraini dinar" },
        { "cc": "BIF", "symbol": "FBu", "name": "Burundi franc" },
        { "cc": "BMD", "symbol": "BD$", "name": "Bermudian dollar" },
        { "cc": "BND", "symbol": "B$", "name": "Brunei dollar" },
        { "cc": "BOB", "symbol": "Bs.", "name": "Bolivian boliviano" },
        { "cc": "BRL", "symbol": "R$", "name": "Brazilian real" },
        { "cc": "BSD", "symbol": "B$", "name": "Bahamian dollar" },
        { "cc": "BTN", "symbol": "Nu.", "name": "Bhutanese ngultrum" },
        { "cc": "BWP", "symbol": "P", "name": "Botswana pula" },
        { "cc": "BYR", "symbol": "Br", "name": "Belarusian ruble" },
        { "cc": "BZD", "symbol": "BZ$", "name": "Belize dollar" },
        { "cc": "CAD", "symbol": "$", "name": "Canadian dollar" },
        { "cc": "CDF", "symbol": "F", "name": "Congolese franc" },
        { "cc": "CHF", "symbol": "Fr.", "name": "Swiss franc" },
        { "cc": "CLP", "symbol": "$", "name": "Chilean peso" },
        { "cc": "CNY", "symbol": "\u00a5", "name": "Chinese/Yuan renminbi" },
        { "cc": "COP", "symbol": "Col$", "name": "Colombian peso" },
        { "cc": "CRC", "symbol": "\u20a1", "name": "Costa Rican colon" },
        { "cc": "CUC", "symbol": "$", "name": "Cuban peso" },
        { "cc": "CVE", "symbol": "Esc", "name": "Cape Verdean escudo" },
        { "cc": "CZK", "symbol": "K\u010d", "name": "Czech koruna" },
        { "cc": "DJF", "symbol": "Fdj", "name": "Djiboutian franc" },
        { "cc": "DKK", "symbol": "Kr", "name": "Danish krone" },
        { "cc": "DOP", "symbol": "RD$", "name": "Dominican peso" },
        { "cc": "DZD", "symbol": "\u062f.\u062c", "name": "Algerian dinar" },
        { "cc": "EEK", "symbol": "KR", "name": "Estonian kroon" },
        { "cc": "EGP", "symbol": "\u00a3", "name": "Egyptian pound" },
        { "cc": "ERN", "symbol": "Nfa", "name": "Eritrean nakfa" },
        { "cc": "ETB", "symbol": "Br", "name": "Ethiopian birr" },
        { "cc": "EUR", "symbol": "\u20ac", "name": "European Euro" },
        { "cc": "FJD", "symbol": "FJ$", "name": "Fijian dollar" },
        { "cc": "FKP", "symbol": "\u00a3", "name": "Falkland Islands pound" },
        { "cc": "GBP", "symbol": "\u00a3", "name": "British pound" },
        { "cc": "GEL", "symbol": "GEL", "name": "Georgian lari" },
        { "cc": "GHS", "symbol": "GH\u20b5", "name": "Ghanaian cedi" },
        { "cc": "GIP", "symbol": "\u00a3", "name": "Gibraltar pound" },
        { "cc": "GMD", "symbol": "D", "name": "Gambian dalasi" },
        { "cc": "GNF", "symbol": "FG", "name": "Guinean franc" },
        { "cc": "GQE", "symbol": "CFA", "name": "Central African CFA franc" },
        { "cc": "GTQ", "symbol": "Q", "name": "Guatemalan quetzal" },
        { "cc": "GYD", "symbol": "GY$", "name": "Guyanese dollar" },
        { "cc": "HKD", "symbol": "HK$", "name": "Hong Kong dollar" },
        { "cc": "HNL", "symbol": "L", "name": "Honduran lempira" },
        { "cc": "HRK", "symbol": "kn", "name": "Croatian kuna" },
        { "cc": "HTG", "symbol": "G", "name": "Haitian gourde" },
        { "cc": "HUF", "symbol": "Ft", "name": "Hungarian forint" },
        { "cc": "IDR", "symbol": "Rp", "name": "Indonesian rupiah" },
        { "cc": "ILS", "symbol": "\u20aa", "name": "Israeli new sheqel" },
        { "cc": "INR", "symbol": "\u20B9", "name": "Indian rupee" },
        { "cc": "IQD", "symbol": "\u062f.\u0639", "name": "Iraqi dinar" },
        { "cc": "IRR", "symbol": "IRR", "name": "Iranian rial" },
        { "cc": "ISK", "symbol": "kr", "name": "Icelandic kr\u00f3na" },
        { "cc": "JMD", "symbol": "J$", "name": "Jamaican dollar" },
        { "cc": "JOD", "symbol": "JOD", "name": "Jordanian dinar" },
        { "cc": "JPY", "symbol": "\u00a5", "name": "Japanese yen" },
        { "cc": "KES", "symbol": "KSh", "name": "Kenyan shilling" },
        { "cc": "KGS", "symbol": "\u0441\u043e\u043c", "name": "Kyrgyzstani som" },
        { "cc": "KHR", "symbol": "\u17db", "name": "Cambodian riel" },
        { "cc": "KMF", "symbol": "KMF", "name": "Comorian franc" },
        { "cc": "KPW", "symbol": "W", "name": "North Korean won" },
        { "cc": "KRW", "symbol": "W", "name": "South Korean won" },
        { "cc": "KWD", "symbol": "KWD", "name": "Kuwaiti dinar" },
        { "cc": "KYD", "symbol": "KY$", "name": "Cayman Islands dollar" },
        { "cc": "KZT", "symbol": "T", "name": "Kazakhstani tenge" },
        { "cc": "LAK", "symbol": "KN", "name": "Lao kip" },
        { "cc": "LBP", "symbol": "\u00a3", "name": "Lebanese lira" },
        { "cc": "LKR", "symbol": "Rs", "name": "Sri Lankan rupee" },
        { "cc": "LRD", "symbol": "L$", "name": "Liberian dollar" },
        { "cc": "LSL", "symbol": "M", "name": "Lesotho loti" },
        { "cc": "LTL", "symbol": "Lt", "name": "Lithuanian litas" },
        { "cc": "LVL", "symbol": "Ls", "name": "Latvian lats" },
        { "cc": "LYD", "symbol": "LD", "name": "Libyan dinar" },
        { "cc": "MAD", "symbol": "MAD", "name": "Moroccan dirham" },
        { "cc": "MDL", "symbol": "MDL", "name": "Moldovan leu" },
        { "cc": "MGA", "symbol": "FMG", "name": "Malagasy ariary" },
        { "cc": "MKD", "symbol": "MKD", "name": "Macedonian denar" },
        { "cc": "MMK", "symbol": "K", "name": "Myanma kyat" },
        { "cc": "MNT", "symbol": "\u20ae", "name": "Mongolian tugrik" },
        { "cc": "MOP", "symbol": "P", "name": "Macanese pataca" },
        { "cc": "MRO", "symbol": "UM", "name": "Mauritanian ouguiya" },
        { "cc": "MUR", "symbol": "Rs", "name": "Mauritian rupee" },
        { "cc": "MVR", "symbol": "Rf", "name": "Maldivian rufiyaa" },
        { "cc": "MWK", "symbol": "MK", "name": "Malawian kwacha" },
        { "cc": "MXN", "symbol": "$", "name": "Mexican peso" },
        { "cc": "MYR", "symbol": "RM", "name": "Malaysian ringgit" },
        { "cc": "MZM", "symbol": "MTn", "name": "Mozambican metical" },
        { "cc": "NAD", "symbol": "N$", "name": "Namibian dollar" },
        { "cc": "NGN", "symbol": "\u20a6", "name": "Nigerian naira" },
        { "cc": "NIO", "symbol": "C$", "name": "Nicaraguan c\u00f3rdoba" },
        { "cc": "NOK", "symbol": "kr", "name": "Norwegian krone" },
        { "cc": "NPR", "symbol": "NRs", "name": "Nepalese rupee" },
        { "cc": "NZD", "symbol": "NZ$", "name": "New Zealand dollar" },
        { "cc": "OMR", "symbol": "OMR", "name": "Omani rial" },
        { "cc": "PAB", "symbol": "B./", "name": "Panamanian balboa" },
        { "cc": "PEN", "symbol": "S/.", "name": "Peruvian nuevo sol" },
        { "cc": "PGK", "symbol": "K", "name": "Papua New Guinean kina" },
        { "cc": "PHP", "symbol": "\u20b1", "name": "Philippine peso" },
        { "cc": "PKR", "symbol": "Rs.", "name": "Pakistani rupee" },
        { "cc": "PLN", "symbol": "z\u0142", "name": "Polish zloty" },
        { "cc": "PYG", "symbol": "\u20b2", "name": "Paraguayan guarani" },
        { "cc": "QAR", "symbol": "QR", "name": "Qatari riyal" },
        { "cc": "RON", "symbol": "L", "name": "Romanian leu" },
        { "cc": "RSD", "symbol": "din.", "name": "Serbian dinar" },
        { "cc": "RUB", "symbol": "R", "name": "Russian ruble" },
        { "cc": "SAR", "symbol": "SR", "name": "Saudi riyal" },
        { "cc": "SBD", "symbol": "SI$", "name": "Solomon Islands dollar" },
        { "cc": "SCR", "symbol": "SR", "name": "Seychellois rupee" },
        { "cc": "SDG", "symbol": "SDG", "name": "Sudanese pound" },
        { "cc": "SEK", "symbol": "kr", "name": "Swedish krona" },
        { "cc": "SGD", "symbol": "S$", "name": "Singapore dollar" },
        { "cc": "SHP", "symbol": "\u00a3", "name": "Saint Helena pound" },
        { "cc": "SLL", "symbol": "Le", "name": "Sierra Leonean leone" },
        { "cc": "SOS", "symbol": "Sh.", "name": "Somali shilling" },
        { "cc": "SRD", "symbol": "$", "name": "Surinamese dollar" },
        { "cc": "SYP", "symbol": "LS", "name": "Syrian pound" },
        { "cc": "SZL", "symbol": "E", "name": "Swazi lilangeni" },
        { "cc": "THB", "symbol": "\u0e3f", "name": "Thai baht" },
        { "cc": "TJS", "symbol": "TJS", "name": "Tajikistani somoni" },
        { "cc": "TMT", "symbol": "m", "name": "Turkmen manat" },
        { "cc": "TND", "symbol": "DT", "name": "Tunisian dinar" },
        { "cc": "TRY", "symbol": "TRY", "name": "Turkish new lira" },
        { "cc": "TTD", "symbol": "TT$", "name": "Trinidad and Tobago dollar" },
        { "cc": "TWD", "symbol": "NT$", "name": "New Taiwan dollar" },
        { "cc": "TZS", "symbol": "TZS", "name": "Tanzanian shilling" },
        { "cc": "UAH", "symbol": "UAH", "name": "Ukrainian hryvnia" },
        { "cc": "UGX", "symbol": "USh", "name": "Ugandan shilling" },
        { "cc": "USD", "symbol": "US$", "name": "United States dollar" },
        { "cc": "UYU", "symbol": "$U", "name": "Uruguayan peso" },
        { "cc": "UZS", "symbol": "UZS", "name": "Uzbekistani som" },
        { "cc": "VEB", "symbol": "Bs", "name": "Venezuelan bolivar" },
        { "cc": "VND", "symbol": "\u20ab", "name": "Vietnamese dong" },
        { "cc": "VUV", "symbol": "VT", "name": "Vanuatu vatu" },
        { "cc": "WST", "symbol": "WS$", "name": "Samoan tala" },
        { "cc": "XAF", "symbol": "CFA", "name": "Central African CFA franc" },
        { "cc": "XCD", "symbol": "EC$", "name": "East Caribbean dollar" },
        { "cc": "XDR", "symbol": "SDR", "name": "Special Drawing Rights" },
        { "cc": "XOF", "symbol": "CFA", "name": "West African CFA franc" },
        { "cc": "XPF", "symbol": "F", "name": "CFP franc" },
        { "cc": "YER", "symbol": "YER", "name": "Yemeni rial" },
        { "cc": "ZAR", "symbol": "R", "name": "South African rand" },
        { "cc": "ZMK", "symbol": "ZK", "name": "Zambian kwacha" },
        { "cc": "ZWR", "symbol": "Z$", "name": "Zimbabwean dollar" }
    ]

    const fontFamilyList = [
        {
            name: 'Roboto'
        },
        {
            name: 'Sans-Serif'
        },
        {
            name: 'American Uncial'
        },
        {
            name: 'Book Antiqua'
        },
        {
            name: 'Cambria'
        },
        {
            name: 'Didot'
        },
        {
            name: 'Flexure'
        }
    ]

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setFormData({ ...formData, [e.target.id]: e.target.value })
    }

    const onAddressChange = (e: { target: { id: any; value: string; }; }) => {
        setFormData({ ...formData, address: { ...formData.address, [e.target.id]: e.target.value } })
    }

    const onVendorStylingChange = (e: { target: { name: any; value: string; }; }) => {
        setFormData({ ...formData, vendorStyling: { ...formData.vendorStyling, [e.target.name]: e.target.value } })
    }

    const onSelectChange = (e: { target: { name: any; value: string; }; }) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }



    const newLogoImageAdded = () => {

    }

    const newBackgroundImageAdded = () => {

    }

    const deleteBackgroundImage = () => {

    }

    const deleteLogoImage = () => {

    }

    const images = [

    ]


    const handleShowPrimaryColourPicker = () => {
        setShowPrimaryColourPicker(true)
    }

    const handleHidePrimaryColourPicker = () => {
        setShowPrimaryColourPicker(false)
    }

    const handleShowSecondaryColourPicker = () => {
        setShowSecondaryColourPicker(true)
    }

    const handleHideSecondaryColourPicker = () => {
        setShowSecondaryColourPicker(false)
    }

    const handleShowTertiaryColourPicker = () => {
        setShowTertiaryColourPicker(true)
    }

    const handleHideTertiaryColourPicker = () => {
        setShowTertiaryColourPicker(false)
    }

    const handlePrimaryColourChange = (color) => {
        setFormData({ ...formData, vendorStyling: { ...formData.vendorStyling, ['primaryColour']: color.hex } })
    }

    const handleSecondaryColourChange = (color) => {
        setFormData({ ...formData, vendorStyling: { ...formData.vendorStyling, ['secondaryColour']: color.hex } })
    }

    const handleTertiaryColourChange = (color) => {
        setFormData({ ...formData, vendorStyling: { ...formData.vendorStyling, ['tertiaryColour']: color.hex } })
    }

    const validateSettingsFields = (e) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id !== undefined)
            setSettingsFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setSettingsFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.name]: showError })
    }

    const validateContactFields = (e) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id !== undefined)
            setContactFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setContactFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.name]: showError })
    }

    const validateAddressFields = (e) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id !== undefined)
            setAddressFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setAddressFormFieldsValidationShowErrors({ ...settingsFormFieldsValidationShowErrors, [e.target.name]: showError })
    }

    const validateAll = () => {
        let isAllValid = true;

        setTabsErrors({ ...tabsErrors, ['settingsTabError']: false })
        setTabsErrors({ ...tabsErrors, ['contactTabError']: false })
        setTabsErrors({ ...tabsErrors, ['addressTabError']: false })



        for (const [key, value] of Object.entries(settingsFormFieldsValidationShowErrors)) {
            const formFieldsvalue = settingsFormFieldsValidationShowErrors[key];
            if (formFieldsvalue === true) {
                isAllValid = false;
                setTabsErrors({ ...tabsErrors, ['settingsTabError']: true })
            }
        }

        for (const [key, value] of Object.entries(contactFormFieldsValidationShowErrors)) {
            const formFieldsvalue = contactFormFieldsValidationShowErrors[key];
            if (formFieldsvalue === true) {
                isAllValid = false;
                setTabsErrors({ ...tabsErrors, ['contactTabError']: true })
            }
        }

        for (const [key, value] of Object.entries(addressFormFieldsValidationShowErrors)) {
            const formFieldsvalue = addressFormFieldsValidationShowErrors[key];
            if (formFieldsvalue === true) {
                isAllValid = false;
                setTabsErrors({ ...tabsErrors, ['addressTabError']: true })
            }
        }

        return isAllValid;
    }

    const onSubmit = () => {

        const isFormValid = validateAll();

        if (isFormValid) {
            handleSettingsUpdate_d(formData);
            showProgressSpinner();
        } else {
            hideProgressSpinner();
            ns.error("Please enter in all required fields and images");
        }
    }


    // function selectCurrency(e){
    //     const name = e.target.name;
    //     const val = e.target.value;
    //     setCurrency(prevState => ({ ...prevState, [name]:val }));
    // };



    return (
        <div>
            {!loading && <>
                <Card className="shadow-xxl p-3 ">
                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem button disableRipple
                            selected={activeTab === '0'}
                            onClick={() => {
                                toggle('0');
                            }}>Settings
                            {tabsErrors.settingsTabError === true && (<div className="badge badge-danger m-1 badge-circle">Danger</div>)}
                        </ListItem>
                        <ListItem button disableRipple
                            selected={activeTab === '1'}
                            onClick={() => {
                                toggle('1');
                            }}>Vendor Contact
                            {tabsErrors.contactTabError === true && (<div className="badge badge-danger m-1 badge-circle">Danger</div>)}
                        </ListItem>
                        <ListItem button disableRipple
                            selected={activeTab === '2'}
                            onClick={() => {
                                toggle('2');
                            }}>Vendor Address
                            {tabsErrors.addressTabError === true && (<div className="badge badge-danger m-1 badge-circle">Danger</div>)}
                        </ListItem>
                        <ListItem button disableRipple
                            selected={activeTab === '3'}
                            onClick={() => {
                                toggle('3');
                            }}>Images
                            {tabsErrors.imagesTabError === true && (<div className="badge badge-danger m-1 badge-circle">Danger</div>)}
                        </ListItem>
                    </List>

                    <div className={"tab-item-wrapper " + (activeTab === '0' ? 'active' : '')} key={0}>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            spacing={0}
                        >
                            <Grid item xs={4} className='d-flex direction-row align-items-center justify-content-center mt-3' >
                                <Typography >Primary Colour</Typography>
                                <div className='d-flex direction-row align-items-center justify-content-center ' >
                                    <Button style={{ backgroundColor: formData.vendorStyling.primaryColour, border: '1px solid #000000', borderRadius: '100%' }} className='p-3 ml-2' id='primary' onClick={handleShowPrimaryColourPicker}></Button>
                                    {showPrimaryColourPicker ? <div className={classes.popover + ' shadow-xxl p-0 m-0'}>
                                        <div className={classes.cover} onClick={handleHidePrimaryColourPicker} />
                                        <ChromePicker onChange={handlePrimaryColourChange} color={formData.vendorStyling.primaryColour} />
                                    </div> : null}
                                </div>
                            </Grid>
                            <Grid item xs={4} className='d-flex direction-row align-items-center justify-content-center mt-3'  >
                                <Typography >Secondary Colour</Typography>
                                <div>
                                    <Button style={{ backgroundColor: formData.vendorStyling.secondaryColour, border: '1px solid #000000', borderRadius: '100%' }} className='p-3 ml-2' onClick={handleShowSecondaryColourPicker}></Button>
                                    {showSecondaryColourPicker ? <div className={classes.popover + ' shadow-xxl p-0 m-0'}>
                                        <div className={classes.cover} onClick={handleHideSecondaryColourPicker} />
                                        <ChromePicker onChange={handleSecondaryColourChange} color={formData.vendorStyling.secondaryColour} />
                                    </div> : null}
                                </div>
                            </Grid>
                            <Grid item xs={4} className='d-flex direction-row align-items-center justify-content-center mt-3'  >
                                <Typography >Tertiary Colour</Typography>
                                <div>
                                    <Button style={{ backgroundColor: formData.vendorStyling.tertiaryColour, border: '1px solid #000000', borderRadius: '100%' }} className='p-3 ml-2' onClick={handleShowTertiaryColourPicker}></Button>
                                    {showTertiaryColourPicker ? <div className={classes.popover + ' shadow-xxl p-0 m-0'}>
                                        <div className={classes.cover} onClick={handleHideTertiaryColourPicker} />
                                        <ChromePicker onChange={handleTertiaryColourChange} color={formData.vendorStyling.tertiaryColour} />
                                    </div> : null}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="p-3">
                                    <TextField value={formData.name} required onBlur={validateSettingsFields} error={settingsFormFieldsValidationShowErrors.name} id='name' onChange={onChange} fullWidth label='Name' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="p-3">
                                    <TextField value={formData.slogan} required onBlur={validateSettingsFields} error={settingsFormFieldsValidationShowErrors.slogan} id='slogan' onChange={onChange} fullWidth label='Slogan' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="p-3">
                                    <TextField value={formData.description} required onBlur={validateSettingsFields} error={settingsFormFieldsValidationShowErrors.description} id='description' onChange={onChange} fullWidth label='Desccription' margin="normal"  multiline rows={3} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <div className="p-3">
                                    <TextField name='fontFamily' id='fontFamily' onChange={onVendorStylingChange} value={formData.vendorStyling.fontFamily} required onBlur={validateSettingsFields} error={settingsFormFieldsValidationShowErrors.fontFamily} select fullWidth label='Font Family' margin="normal">
                                        {fontFamilyList.map((fontFamily, idx) => (
                                            <MenuItem key={idx} value={fontFamily.name} >
                                                {fontFamily.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <div className="p-3">
                                    <TextField id='currency' name='currency' value={formData.currency} onChange={onSelectChange} select fullWidth label='Currency' margin="normal">
                                        {currencyList.map((currency, idx) => (
                                            <MenuItem key={idx} value={currency.name} >
                                                {currency.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6} >
                                <div className="p-3">
                                    <TextField id='timezone' name='timezone' disabled value={formData.timezone} onChange={onSelectChange} select fullWidth label='Timezone' margin="normal">
                                        {timezones.map((timezone, idx) => (
                                            <MenuItem key={idx} value={timezone.text} >
                                                {timezone.text}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <div className="p-3">
                                    <TextField type='number' id='numberOfLotImages' disabled value={formData.numberOfLotImages} onChange={onChange} label='Number of Lot Images' fullWidth margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <div className="p-3">
                                    <TextField value={formData.lotNumberPrefix} disabled id='lotNumberPrefix' onChange={onChange} fullWidth label='Lot Number Prefix' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <div className="p-3">
                                    <TextField type='number' id='lotNumberRangeStart' disabled value={formData.lotNumberRangeStart} onChange={onChange} label='Lot Number Range Start' fullWidth margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <div className="p-3">
                                    <TextField type='number' id='lotNumberRangeEnd' disabled value={formData.lotNumberRangeEnd} onChange={onChange} label='Lot Number Range End' fullWidth margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <div className="p-3">
                                    <TextField InputLabelProps={{
                                        shrink: true,
                                    }} label='Bid Extention Time in seconds' fullWidth disabled type='number' value={formData.bidExtenstionTime} onChange={onChange} margin="normal" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '1' ? 'active' : '')} key={1}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={0}>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.vendorEmail} disabled error={contactFormFieldsValidationShowErrors.email} onBlur={validateContactFields} id='vendorEmail' onChange={onChange} fullWidth label='Vendor Email' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.vendorPhone} disabled error={contactFormFieldsValidationShowErrors.number} onBlur={validateContactFields} id='vendorPhone' onChange={onChange} fullWidth label='Vendor Number' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.instagram} disabled InputProps={{
                                        startAdornment: <InputAdornment position="start"><FaInstagram /></InputAdornment>,
                                    }} id='instagram' onChange={onChange} fullWidth label='Instagram' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.twitter} disabled InputProps={{
                                        startAdornment: <InputAdornment position="start"><FaTwitter /></InputAdornment>,
                                    }} id='twitter' onChange={onChange} fullWidth label='Twitter' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.facebook} disabled InputProps={{
                                        startAdornment: <InputAdornment position="start"><FaFacebook /></InputAdornment>,
                                    }} id='facebook' onChange={onChange} fullWidth label='Facebook' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.youtube} disabled InputProps={{
                                        startAdornment: <InputAdornment position="start"><FaYoutube /></InputAdornment>,
                                    }} id='youtube' onChange={onChange} fullWidth label='Youtube' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '2' ? 'active' : '')} key={2}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={0}>
                            <Grid item xs={12}>
                                <div className="p-3">
                                    <TextField value={formData.address.addressLine1} id='addressLine1' required onChange={onAddressChange} onBlur={validateAddressFields} error={addressFormFieldsValidationShowErrors.addressLine1} fullWidth label='Address Line 1' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="p-3">
                                    <TextField value={formData.address.addressLine2} id='addressLine2' onChange={onAddressChange} error={addressFormFieldsValidationShowErrors.addressLine2} fullWidth label='Address Line 2' margin="normal" />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.address.country} id='country' required onChange={onAddressChange} onBlur={validateAddressFields} error={addressFormFieldsValidationShowErrors.country} fullWidth label='Country' margin="normal" />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.address.city} id='city' required onChange={onAddressChange} onBlur={validateAddressFields} error={addressFormFieldsValidationShowErrors.city} fullWidth label='City' margin="normal"  />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.address.state} id='state' required onChange={onAddressChange} onBlur={validateAddressFields} error={addressFormFieldsValidationShowErrors.state} fullWidth label='State / Province' margin="normal" />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-3">
                                    <TextField value={formData.address.zip} id='zip' required onChange={onAddressChange} onBlur={validateAddressFields} error={addressFormFieldsValidationShowErrors.zip} fullWidth label='Zip / Postal Code' margin="normal" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '3' ? 'active' : '')} key={3}>
                        <ImageUploader
                            acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                            lotImages={images} newFilesAdded={newLogoImageAdded} deleteImage={deleteLogoImage} isDisplayImage={true}
                            header={"Logo Image"}
                            description={"The company's logo that will display throughout Prembid"} />
                        <ImageUploader
                            acceptedFileExtensions={"image/jpeg, image/jpg, image/png"}
                            lotImages={images} newFilesAdded={newBackgroundImageAdded} deleteImage={deleteBackgroundImage} isDisplayImage={false}
                            header={"Background Image"}
                            description={"The company's background image that will display on the Prembid Client site"} />
                    </div>

                </Card>
                <div className="text-right">
                    <Button variant="contained" className="btn-danger p-3 text-white m-1" size="large">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">Cancel</span>
                    </Button>

                    <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white m-1" size="large">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                        <span className="btn-wrapper--label">Save</span>
                    </Button>
                </div>
            </>}

            {
                error && (
                    <Collapse in={error}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        clearError_d()
                                    }}
                                >
                                    <MdClose fontSize="inherit" />
                                </IconButton>
                            }
                            className="mb-4" severity="error">
                            {errorText}
                        </Alert>
                    </Collapse>
                )
            }

        </div >
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSettingsFetch_d: () => dispatch(handleSettingsFetch()),
        handleSettingsUpdate_d: (formFields: any) => dispatch(handleSettingsUpdate(formFields)),
        clearError_d: () => dispatch(clearError()),
        clearApiResponses_d: () => dispatch(clearApiResponses()),
    }
}


const mapStateToProps = (state: any) => ({
    error: state.settings.error,
    errorText: state.settings.errorText,
    success: state.settings.success,
    reload: state.settings.reload,
    accountSettings: state.settings.accountSettings,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);