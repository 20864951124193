import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TableBody,
    Switch,
} from "@mui/material";
import { FaTimes, FaSave } from "react-icons/fa";
import { connect } from "react-redux";
import { handleRetrieveAllDocumentTemplatesByDocumentType } from "@/pages/Documents/store/Documents";
import { DocumentTypeEnum } from "../../helpers/DocumentTypeEnum";
import ns from "../../helpers/NotificationService";
import Loading from "@/components/Loading";
import LoadingButton from "@/components/LoadingButton";

interface DocumentTemplateAddDialogProps {
    title: string;
    description: string;
    okButtonText: string;
    cancelButtonText: string;
    open: boolean;
    onOk: (any: any) => void;
    onCancel: () => void;
    documentType: any;
    handleRetrieveAllDocumentTemplatesByDocumentType_d: (
        currentDocumentType: any,
        onCompletedCallback?: (response: any) => void
    ) => void;
    filterOutUploadMandatory?: boolean;
    payload: any;
}

export const DocumentTemplateAddDialog: React.FC<
    DocumentTemplateAddDialogProps
> = (props) => {
    const {
        title,
        description,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk,
        documentType,
        handleRetrieveAllDocumentTemplatesByDocumentType_d,
        filterOutUploadMandatory = false,
    } = props;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [documentTemplates, setDocumentTemplates] = React.useState<any>([]);

    const [documentTemplateData, setDocumentTemplateData] = React.useState<any>(
        [] as any
    );

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            handleRetrieveAllDocumentTemplatesByDocumentType_d(documentType, (res) => {
                if (res.success) {
                    setDocumentTemplates(res.response);
                }
                else {
                    ns.error("Unable to retrieve document templates...");
                }
                setLoading(false);
            });
        }, 50)
    }, [documentType]);

    const onDocumentTypeCheckChanged = (checked, id, name, documentTemplate) => {
        let tempList = [...documentTemplateData];
        let index = tempList.findIndex((x) => x.id === id);

        if (checked) {
            const newFields: any = {
                id: id,
                name: name,
                documentTemplate: documentTemplate,
            };

            if (index === -1) tempList.push(newFields);
        } else {
            if (index > -1) tempList.splice(index, 1);
        }

        setDocumentTemplateData(tempList);
    };

    const mapDocumentTemplateRow = (row: any, index: any) => {
        let mappedRow;
        mappedRow = (
            <TableRow key={index}>
                <TableCell hidden component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell scope="row">
                    <div style={{ width: 330, wordWrap: "break-word" }}>{row.name}</div>
                </TableCell>
                <TableCell>
                    <Switch
                        id={index}
                        onChange={(e) =>
                            onDocumentTypeCheckChanged(
                                e.target.checked,
                                row.id,
                                row.name,
                                row
                            )
                        }
                        className="m-2 p-2 switch-medium toggle-switch-success"
                    />
                </TableCell>
            </TableRow>
        );
        return mappedRow;
    };

    const applyFilter = (templates, docType): any[] => {
        if (docType === DocumentTypeEnum.Lot && filterOutUploadMandatory) {
            return templates.filter(
                (x) => x?.dataCaptured?.lot?.isUploadMandatory === false
            );
        } else return templates;
    };

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContentText
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingTop: 20,
                    paddingLeft: 30,
                }}
            >
                {description}
            </DialogContentText>
            <DialogContent>
                <Loading loading={loading} style={{ minWidth: 460 }} >
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell hidden></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applyFilter(documentTemplates, documentType).map((row, index) =>
                                    mapDocumentTemplateRow(row, index)
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Loading>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={onCancel}
                    type='danger'
                    icon={<FaTimes />}
                    label={cancelButtonText}
                    width={120}
                />

                <LoadingButton
                    loading={loading}
                    onClick={() => {
                        if (documentTemplateData.length === 0) {
                            ns.error("You have not selected any documents to add");
                        } else {
                            onOk(documentTemplateData);
                        }
                    }}
                    type='primary'
                    icon={<FaSave />}
                    label={okButtonText}
                    width={120}
                />
            </DialogActions>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRetrieveAllDocumentTemplatesByDocumentType_d: (
            currentDocumentType: any,
            onCompletedCallback?: (response: any) => void
        ) =>
            dispatch(
                handleRetrieveAllDocumentTemplatesByDocumentType(currentDocumentType, onCompletedCallback)
            ),
    };
};

const mapStateToProps = (state: any) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentTemplateAddDialog);
