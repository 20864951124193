import { Avatar, Button as MuiButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
    CloudUpload as MuiCloudUpload,
    Delete as MuiDelete,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import React, { createRef, useState } from "react";
import styled from "styled-components";
import ns from '@/helpers/NotificationService';

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto 10px;
  ${({ $withBorder }) =>
        $withBorder &&
        `border: 1px solid ${grey[500]};
     box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
`;

const AvatarUpload = props => {
    const [image, _setImage] = useState(null);
    const inputFileRef = createRef(null);

    const cleanup = () => {
        URL.revokeObjectURL(image);
        inputFileRef.current.value = null;
    };

    const setImage = (newImage) => {
        if (image) {
            cleanup();
        }
        _setImage(newImage);
    };

    const handleOnChange = (event) => {
        const newImage = event.target?.files?.[0];

        if (newImage?.size / 1024 / 1024 <= 1) {

            const reader = new FileReader();

            reader.readAsDataURL(newImage);
            reader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    const base64String = evt.target.result;
                    if (props.onChange) {
                        props.onChange({
                            fileName: newImage && newImage.name,
                            imageData: base64String
                        })
                    }
                }
            }

            if (newImage) {
                setImage(URL.createObjectURL(newImage));
            }
        } else {
            ns.error("Image '" + newImage.name + "' is too big. A maximum image size of 1MB is allowed");
        }
    };

    /**
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
     */
    const handleClick = (event) => {
        if (image) {
            event.preventDefault();
            setImage(null);
        }
    };

    return (
        <CenteredContent>
            <BigAvatar
                $withBorder
                alt="Avatar"
                src={image || props.imageUrl}
                imgProps={{
                    style: {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                    },
                }}
            >A</BigAvatar>
            <input
                ref={inputFileRef}
                accept="image/*"
                hidden
                id="avatar-image-upload"
                type="file"
                onChange={handleOnChange}
            />
            <label htmlFor="avatar-image-upload">
                <Button
                    variant="contained"
                    className="btn-primary p-3 text-white text-capitalize"
                    component="span"
                    mb={2}
                    onClick={handleClick}
                    disabled={props.disabled ?? false}
                >
                    {image ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
                    {image ? "Delete" : props.imageUrl ? "Replace" : "Upload"}
                </Button>
            </label>
            <Typography variant="caption" display="block" gutterBottom>
                {props.description}
            </Typography>
        </CenteredContent>
    );
};

export default AvatarUpload;