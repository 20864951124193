import React, { LabelHTMLAttributes, useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux';
import {
    handleFetchIntegrationConfig,
    handleUpsertIntegrationConfig,
    handleFetchIntegrationUsers,
    handleGenerateIntegrationToken,
    handleAddIntegrationUser
} from '@/pages/Integration/store/Integration';
import {
    Card, Grid, TextField, CardContent, CardHeader, Button, Tooltip, MenuItem, Box, Chip
} from '@mui/material';
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import ns from '@/helpers/NotificationService';
import SetObjectValue from 'lodash/set';
import FloatyButtonGroup from "../FloatyButtonGroup/FloatyButtonGroup";
import { FaBackspace, FaSave } from "react-icons/fa";
import { NestedAccordionGrid, Header, NestedGridDataModel, Row } from "@/components/NestedAccordionGrid";
import { AiOutlineReload } from "react-icons/ai";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
    handleFetchIntegrationConfig_d: (onCompletedCallback: (res: any) => void) => void,
    handleUpsertIntegrationConfig_d: (config: any, onCompletedCallback: (res: any) => void) => void,
    handleFetchIntegrationUsers_d: (onCompletedCallback: (res: any) => void) => void,
    handleGenerateIntegrationToken_d: (userId: string, onCompletedCallback: (res: any) => void) => void,
    handleAddIntegrationUser_d: (username: string, onCompletedCallback: (res: any) => void) => void,
    loading: boolean,
    settings: any,
    integration: any
}

const Configuration: React.FC<Props> = (props) => {
    const {
        handleFetchIntegrationConfig_d,
        handleUpsertIntegrationConfig_d,
        handleFetchIntegrationUsers_d,
        handleGenerateIntegrationToken_d,
        handleAddIntegrationUser_d,
        loading,
        settings,
        integration,
        integration: { users, config }
    } = props;

    const [usersModel, setUsersModel] = React.useState<NestedGridDataModel>();

    const emptyState = {
        id: '',
        webhookProcessor: 'Default',
        whiteListedDomains: '',
        mode: 'Test',
        unsavedChanges: false
    }

    const [generatingTokens, setGeneratingTokens] = useReducer((state, action) => {
        let tempData = [...state];

        let index = tempData.findIndex(x => x === action.userId);

        if (action.action === 'add') {
            tempData.push(action.userId);
        } else if (index > -1) tempData.splice(index, 1);

        return tempData;

    }, []);

    const [formFields, setFormFields] = useReducer((state, action) => {
        let tempData = { ...state };

        switch (action.type) {
            case 'set': return action.data;
            case 'update':
                SetObjectValue(tempData, action.path, action.value);
                SetObjectValue(tempData, 'unsavedChanges', true);
                break;
        }
        return tempData;

    }, emptyState)

    useEffect(() => {
        reload();
    }, [])

    useEffect(() => {
        resetConfig();
    }, [config])

    useEffect(() => {
        resetUsers();
    }, [users, generatingTokens])

    const resetConfig = () => {
        if (config)
            setFormFields({
                type: 'set', data: {
                    ...emptyState,
                    id: integration.config?.id,
                    webhookProcessor: integration.config?.webhookProcessor,
                    whiteListedDomains: integration.config?.whiteListedDomains?.join('; '),
                    mode: integration.config?.isTest ? 'Test' : 'Live'
                }
            })
        if (users) renderUsersTable();
    }

    const resetUsers = () => {
        if (users) renderUsersTable();
    }

    const reload = (withLoading = true) => {
        if (withLoading) showProgressSpinner({ description: 'Loading integration configuration...' });
        handleFetchIntegrationConfig_d((res) => {
            if (!res.success) {
                ns.error('Failed to load the integration configuration');
            }
            reloadUsers(withLoading)
        })
    }

    const reloadUsers = (withLoading = true) => {
        if (withLoading) showProgressSpinner({ description: 'Loading integration users...' });
        handleFetchIntegrationUsers_d((res) => {
            if (!res.success) {
                ns.error('Failed to load the integration users');
            }
            hideProgressSpinner();
        })
    }

    const onChange = (e: any) => {
        setFormFields({ type: 'update', path: e.target.id ?? e.target.name, value: e.target.value });
    }

    const handleSaveConfig = () => {
        const { unsavedChanges, mode, ...rest } = formFields;
        let config = {
            ...rest,
            whiteListedDomains: formFields.whiteListedDomains.split(";").map(x => x.trim()),
            isTest: mode === 'Test' ? true : false
        }
        showProgressSpinner({ description: 'Saving integration configuration...' });
        handleUpsertIntegrationConfig_d(config, (res) => {
            if (res.success) {
                ns.success('Successfully updated the integration config');
                reload();
            }
            else {
                ns.error('Failed to update the integration configuration');
            }
            hideProgressSpinner();
        })
    }

    const generateToken = (userId: string) => {
        ns.showDialog({
            title: 'Confirm Token Generation',
            content: <>The existing token will expire when a new token is generated. Are you sure you want to generate a new token?</>,
            locales: {
                submitText: 'Generate Token'
            },
            onSubmit: () => {
                if (!generatingTokens?.find(x => x === userId)) {
                    setGeneratingTokens({ action: 'add', userId });
                    handleGenerateIntegrationToken_d(userId, (res) => {
                        setGeneratingTokens({ userId });
                        if (res.success) reloadUsers(false);
                    })
                }
            },
            onCancel: () => { }
        })
    }

    const renderUsersTable = () => {
        let headers: Header[] = [
            { title: "Username", align: "inherit" },
            { title: "Integration Token", align: "inherit" },
        ];

        let rows: Row[] = users.map(user => {
            return {
                id: user.id,
                cells: [
                    { text: user.username, align: "inherit" },
                    { text: user.integrationToken || '* Not Generated *', align: "inherit" },
                ],
                actionButtons: [
                    <Tooltip title={`Generate Integration Token`} >
                        <LoadingButton onClick={() => generateToken(user.id)} loading={generatingTokens?.find(x => x === user.id)} size="small" className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                            <AiOutlineReload />
                        </LoadingButton>
                    </Tooltip>
                ]
            }
        });

        setUsersModel({
            headers: headers,
            rows: rows
        });
    }

    const [tempUsername, setTempUsername] = useState<string>();

    const submitAddUsername = () => {
        let element: any = document.getElementById("tempUsername");
        if (element.value) {
            showProgressSpinner({ description: 'Adding integration user...' });
            handleAddIntegrationUser_d(element.value, (res) => {
                if (!res.success) {
                    ns.error('Failed to add the integration user');
                }
                reloadUsers(true);
            });
        }
    }

    const handleAddUser = () => {
        ns.showDialog({
            title: "Add Integration User",
            content: <div style={{ width: 350 }}>
                <TextField
                    margin="normal"
                    value={tempUsername}
                    onChange={e => setTempUsername(e.target.value)}
                    id="tempUsername"
                    fullWidth
                    className="m-2"
                    label="Username"
                    autoFocus
                    required
                />
            </div>,
            locales: { submitText: "Submit" },
            onCancel: () => { },
            onSubmit: submitAddUsername
        })
    }

    const renderAllowedEntityTypes = () => {
        let chips: any[] = [];
        if (config?.allowedEntities?.users) chips.push("Users");
        if (config?.allowedEntities?.contacts) chips.push("Contacts");
        if (config?.allowedEntities?.venues) chips.push("Venue");
        if (config?.allowedEntities?.lotTypes) chips.push(settings.LotName + ' Types');
        if (config?.allowedEntities?.auctions) chips.push(settings.AuctionName + 's');
        if (config?.allowedEntities?.lots) chips.push(settings.LotName + 's');

        if (chips.length === 0) chips.push("None");

        return <Box sx={{ m: 2 }}>{chips.map(x => <Chip style={{ marginRight: 10 }} label={x} />)}</Box>;
    }

    return <>
        <Card className='card-transparent'>
            <CardHeader title='Settings' className='pb-0' />
            <CardContent className='pt-0'>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={0}>
                    <Grid item xs={12}>
                        <div className="p-3">
                            <TextField
                                margin="normal"
                                value={formFields.webhookProcessor}
                                onChange={onChange}
                                id="webhookProcessor"
                                fullWidth
                                className="m-2"
                                label="Webhook Processor"
                                autoFocus
                                disabled
                            />
                            <TextField
                                margin="normal"
                                value={formFields.whiteListedDomains}
                                onChange={onChange}
                                id="whiteListedDomains"
                                fullWidth
                                className="m-2"
                                label="Whitelisted Domains"
                                autoFocus />
                            <TextField
                                margin="normal"
                                value={formFields.mode}
                                onChange={onChange}
                                name="mode"
                                id="mode"
                                fullWidth
                                className="m-2"
                                label="Mode"
                                select
                            >
                                <MenuItem key='testMode' value='Test'>Test</MenuItem>
                                <MenuItem key='liveMode' value='Live'>Live</MenuItem>
                            </TextField>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card className='card-transparent'>
            <CardHeader title='Allowed Entity Types' className='pb-0' />
            <CardContent className='pt-0'>
                {renderAllowedEntityTypes()}
            </CardContent>
        </Card>
        <Card className='card-transparent'>
            <CardHeader title='Users' className='pb-0' />
            <CardContent className='pt-0'>
                <NestedAccordionGrid dataModel={usersModel} onAdd={() => handleAddUser()} showTopDivider={false} />
            </CardContent>
        </Card>
        {formFields.unsavedChanges && <FloatyButtonGroup>
            <Tooltip arrow title="Save" placement="left">
                <Button size="large" variant="contained" onClick={handleSaveConfig}
                    className="btn-primary text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                </Button>
            </Tooltip>
            <Tooltip arrow title="Cancel" placement="left">
                <Button size="large" variant="contained" onClick={resetConfig}
                    className="btn-danger text-white m-1 d-flex align-items-center justify-content-center btn-squared">
                    <span className="btn-wrapper--icon">
                        <FaBackspace />
                    </span>
                </Button>
            </Tooltip>
        </FloatyButtonGroup>}
    </>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleFetchIntegrationConfig_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchIntegrationConfig(onCompletedCallback)),
        handleUpsertIntegrationConfig_d: (config: any, onCompletedCallback: (res: any) => void) => dispatch(handleUpsertIntegrationConfig(config, onCompletedCallback)),

        handleFetchIntegrationUsers_d: (onCompletedCallback: (res: any) => void) => dispatch(handleFetchIntegrationUsers(onCompletedCallback)),
        handleGenerateIntegrationToken_d: (userId: string, onCompletedCallback: (res: any) => void) => dispatch(handleGenerateIntegrationToken(userId, onCompletedCallback)),
        handleAddIntegrationUser_d: (username: string, onCompletedCallback: (res: any) => void) => dispatch(handleAddIntegrationUser(username, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    integration: state.integration
})

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);