import React from 'react'
import useStyles from './EmailChangeConfirmationStyles';
import EmailChangeConfirmationCard from '@/components/EmailChangeConfirmationCard/EmailChangeConfirmationCard';

const EmailChangeConfirmation: React.FC = () => {

    const { classes, cx } = useStyles();

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }, []);

    return (
        <EmailChangeConfirmationCard />
    )
}

export default EmailChangeConfirmation
