import React from 'react';
import { Typography } from '@mui/material';
import TextField, { TextFieldProps } from '@/components/TextField';

type TextFieldWithLabelProps = {
    labelWidth?: number | string;
    label?: string | undefined;
} & TextFieldProps

function TextFieldWithLabel({
    labelWidth = '35%',
    placeholder,
    label,
    ...props
}: TextFieldWithLabelProps) {
    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography style={{ width: labelWidth }}>{label}</Typography>
        <TextField
            margin="normal"
            fullWidth
            className="mt-1"
            placeholder={placeholder ?? label}
            InputProps={{
                style: { width: '100%' }
            }}
            {...props}
        />
    </div>
}

export default TextFieldWithLabel;