import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { generatePath } from 'react-router-dom';

const FETCH_BANK_DETAILS_LIST = 'FETCH_BANK_DETAILS_LIST';
const FETCH_BANK_DETAILS_LOOKUP = 'FETCH_BANK_DETAILS_LOOKUP';
const CLEAR_BANK_DETAILS = 'CLEAR_BANK_DETAILS';

const initialState = {
    bankDetails: [],
    pagination: null,
    bankDetailsLookup: []
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case FETCH_BANK_DETAILS_LIST: return { ...state, bankDetails: action.payload.list, pagination: action.payload.pagination }
        case FETCH_BANK_DETAILS_LOOKUP: return { ...state, bankDetailsLookup: action.payload }
        case CLEAR_BANK_DETAILS: return { ...initialState }
        default: return state;
    }
}

export const clearBankDetailsState = () => {
    return { type: CLEAR_BANK_DETAILS }
}

export const bankDetailsFetchList = (payload: any) => {
    return { type: FETCH_BANK_DETAILS_LIST, payload }
}

export const bankDetailsFetchLookup = (payload: any) => {
    return { type: FETCH_BANK_DETAILS_LOOKUP, payload }
}

export const handleBankDetailsAdd = (data: any, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {

        return requestClient.post(apiPaths.vendor.addBankingDetails, data)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleBankDetailsUpdate = (id: string, data: any, onCompletedCallback: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(generatePath(apiPaths.vendor.updateBankingDetails, { id }), data)
            .then(function (res) {
                onCompletedCallback(res)
            })
    }
}

export const handleBankDetailsFetch = (id: string, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveBankDetails, { id }, null)
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const handleBankDetailsDelete = (id: string, onCompletedCallback: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.delete(apiPaths.vendor.deleteBankDetails, null, { id })
            .then(function (res) {
                onCompletedCallback(res);
            })
    }
}

export const retrieveBankDetailsList = (filters: any, pagination: any, onCompletedCallback: (result: any) => void) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.vendor.retrieveBankDetailsList, {
            pagination,
            ...(filters || {})
        }).then(function (res) {
            dispatch(bankDetailsFetchList(res.response))
            onCompletedCallback(res)
        })
    }
}

export const retrieveBankDetailsLookup = (onCompletedCallback: (result: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.vendor.retrieveBankDetailsLookup, null, null)
            .then(function (res) {
                dispatch(bankDetailsFetchLookup(res.response))
                onCompletedCallback(res)
            })
    }
}