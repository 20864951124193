import React, {ReactNode, memo} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { FaTimes, FaSave, FaUnlink } from "react-icons/fa";

export interface ConfirmationOptions {
    title: string;
    description: string | ReactNode;
    okButtonText: string;
    cancelButtonText: string;
    additionalButtonText?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean;
    onOk: (payload: any) => void;
    onCancel: () => void;
    onAdditionalButton?: (payload: any) => void;
    payload?: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
    const {
        title,
        description,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk,
        onAdditionalButton,
        additionalButtonText,
        payload
    } = props

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">{cancelButtonText}</span>
                </Button>

                {
                    additionalButtonText && additionalButtonText !== undefined && onAdditionalButton && onAdditionalButton !== undefined &&
                    <Button onClick={() => onAdditionalButton(payload)} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ padding: 10, background: '#2b2b2b' }}>
                        <span className="btn-wrapper--icon">
                            <FaUnlink />
                        </span>
                        <span className="btn-wrapper--label">{additionalButtonText}</span>
                    </Button>
                }

                <Button onClick={() => onOk(payload)} variant="contained" className="btn-primary p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                    <span className="btn-wrapper--label">{okButtonText}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default memo(ConfirmationDialog);