import { FC, memo } from "react";
import useStyles from "../AuctionsOverviewFactsFigures.styles";
import { Button, Tooltip } from "@mui/material";
import { FaRedo } from "react-icons/fa";

interface RefreshButtonProps {
    isLoading: boolean;
    onClick: () => void,
}

const RefreshButton: FC<RefreshButtonProps> = ({ onClick, isLoading }) => {

    const { cx } = useStyles();

    return (
        <div
            className="d-flex align-items-center"
            style={{
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "all",
            }}>
            <Tooltip title="Refresh" placement="bottom">
                <Button onClick={onClick} variant="text" className={cx("btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill text-capitalize", { 'btn-outline-success': false })}>
                    <FaRedo />
                </Button>
            </Tooltip>
        </div>
    );
};

export default memo(RefreshButton);
