import React from "react";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import useHistory from "@/hooks/useHistory";
import { PrembidLocalStorage } from "../src/helpers/PrembidLocalStorage";

type AuthRouteProps = {} & RouteProps;

function AuthRoute({ element, ...rest }: AuthRouteProps) {
  const history = useHistory();
  const url = `/login?redirect=${encodeURIComponent(
    history.location.pathname + history.location.search
  )}`;
  const isAuthenticated = PrembidLocalStorage.currentUser?.jwtToken;

  return isAuthenticated ? <Outlet /> : <Navigate to={url} />;
}

export { AuthRoute };