export enum FinanceDocumentType {
    ProFormaInvoice = 0,
    Invoice = 1,
    ReleaseNote = 2,
    ConsolidatedProfomaInvoice = 3
}
export enum FinanceDocumentStatus {
    NotSent = 0,
    Sent = 1,
    Paid = 2,
    MarkedForRelease = 3,
    Cancelled = 4,
    Consolidated = 5
}

export type FinanceDocument = {
    id: string;
    parentFinanceDocumentId?: string;
    number: string;

    type: FinanceDocumentType;

    reference: string;
    date: string;
    note: string;

    billingCompany: string;
    billingVatNumber: string;

    billingAddress: any;

    customerType: string;
    customerName: string;
    customerVatNumber: string;
    customerEmail: string;

    customerAddress: any;
    customerPhoneNumber: string;

    totalExclVat: number;
    totalVat: number;
    totalInclVat: number;

    bankDetailsId: string;

    status: FinanceDocumentStatus;

    entityReferences: any[];

    items: FinanceDocumentItem[];

    extraData?: any;
}

export type FinanceDocumentItem = {
    id: string;
    description: string;
    applyVat: boolean;
    totalExclVat: number;
    financeDocumentId: string;
    order: number;
    releaseable: boolean;
    extraData?: any;
    group: number;
}
