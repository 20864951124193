import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { clearLots, resetAuctionManagementState } from './store/AuctionManagement';
import { TextField, List, ListItem, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import BidHistory from './BidHistory';
import RegisteredUsers from './RegisteredUsers';
import Wishlist from "./Wishlist";
import AuctionOverview from "./AuctionOverview";
import InvoiceTab from "./InvoiceTab";
import Popper from '@mui/material/Popper';
import useHistory from "../../hooks/useHistory";
import { useAuctionLookup } from "prembid-shared-library-npm/networking/network-hooks/useAuctionLookup";
import { formatDateTime } from "../../helpers/Utils";
import { useHandleLoadAuctionById } from "prembid-shared-library-npm/networking";
const CustomPopper = function (props) {
    return <Popper {...props} style={{ zIndex: 999, ...(props.style ?? {}) }} placement="bottom-start" >
        {props.children}
    </Popper>
};

interface Props {
    resetAuctionManagementState_d: () => void,
    clearLots_d: () => void,
    settings: any,
}

const AuctionManagement: React.FC<Props> = (props) => {
    const history = useHistory();
    const {
        resetAuctionManagementState_d,
        settings,
    } = props

    const [selectedAuction, setSelectedAuction] = React.useState<any>(null);

    const [activeTab, setActiveTab] = React.useState('0');

    const { data: auctionLookup, isFetching: loadingAuctionsLookup } = useAuctionLookup({
        pagination: {
            currentPage: 1,
            pageSize: -1
        }
    });

    useEffect(() => {
        return (() => {
            resetAuctionManagementState_d();
        })
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);

        let auctionId = params.get('auctionId');
        let index = params.get('index');

        if (auctionId) {
            let auction = auctionLookup?.list?.find(x => x.id === auctionId);
            if (auction) onAuctionChange(null, auction);
        }

        if (index) setActiveTab(index + '');
    }, [auctionLookup])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const onAuctionChange = (event, auction) => {
        if (auction) {
            resetAuctionManagementState_d();
            setSelectedAuction(auction);

            if (activeTab === '4' && !auction.defaultApplyInvoicing) setActiveTab('3');
        }
    }

    const { data: auction, isFetching: loadingAuctionData } = useHandleLoadAuctionById(selectedAuction?.id, {
        enabled: !!selectedAuction?.id
    });

    return (
        <div className="bg-white">
            <div className="card-header py-3">
                <div className="card-header--title font-size-lg">
                    {settings.AuctionName + " Management"}
                </div>
            </div>
            <div className="divider" />
            <div className="pl-3 pr-3">
                <Autocomplete
                    className="mb-2"
                    onChange={onAuctionChange}
                    options={auctionLookup?.list ?? []}
                    getOptionLabel={(option: any) => option.name}
                    loading={loadingAuctionsLookup}
                    disabled={loadingAuctionsLookup}
                    value={selectedAuction}
                    PopperComponent={CustomPopper}
                    renderOption={(props, option, { selected, index }) => (
                        <li {...props}>
                            <div style={{ flex: 1 }}>
                                {option.name}
                            </div>
                            <div>
                                {formatDateTime(option.startDateTimeAt, "yyyy/MM/dd HH:mm")}
                            </div>
                        </li>
                    )}
                    groupBy={() => ("all")}
                    renderGroup={(props) => (<div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignContent: 'center',
                                gap: 10,
                                borderBottom: '1px lightgray solid',
                                marginLeft: 15,
                                marginRight: 15,
                                paddingBottom: 5,
                                marginBottom: 5
                            }}>
                            <div style={{ flex: 1 }}>Name</div>
                            <div style={{}}>Start Time</div>
                        </div>
                        {props.children}
                    </div>
                    )}
                    renderInput={params => (
                        <TextField {...params}
                            fullWidth margin="normal" label={"Select " + settings.AuctionName}

                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingAuctionsLookup ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                                autoComplete: "new-password"
                            }} />
                    )} />
                {auction && <>
                    <List className="nav-tabs nav-tabs-primary d-flex">
                        <ListItem button disableRipple selected={activeTab === '0'} onClick={() => { toggle('0'); }}>{settings.AuctionName + " Overview"}</ListItem>
                        <ListItem button disableRipple selected={activeTab === '1'} onClick={() => { toggle('1'); }}>Registered Users</ListItem>
                        <ListItem button disableRipple selected={activeTab === '2'} onClick={() => { toggle('2'); }}>Wishlist</ListItem>
                        <ListItem button disableRipple selected={activeTab === '3'} onClick={() => { toggle('3'); }}>{settings.AuctionName + " History"}</ListItem>
                        {selectedAuction.defaultApplyInvoicing && <ListItem button disableRipple selected={activeTab === '4'} onClick={() => { toggle('4'); }}>Invoicing</ListItem>}
                    </List>

                    <div className={"tab-item-wrapper " + (activeTab === '0' ? 'active' : '')} key={0}>
                        <div className="text-centre p-3">
                            {activeTab === '0' && <AuctionOverview selectedAuction={auction} />}
                        </div>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '1' ? 'active' : '')} key={1}>
                        <div className="text-centre p-3">
                            {activeTab === '1' && <RegisteredUsers selectedAuction={auction} activeTab={activeTab} />}
                        </div>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '2' ? 'active' : '')} key={1}>
                        <div className="text-centre p-3">
                            {activeTab === '2' && <Wishlist selectedAuction={auction} />}
                        </div>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '3' ? 'active' : '')} key={2}>
                        <div className="text-centre p-3">
                            {activeTab === '3' && <BidHistory selectedAuction={auction} />}
                        </div>
                    </div>

                    <div className={"tab-item-wrapper " + (activeTab === '4' ? 'active' : '')} key={3}>
                        <div className="text-centre p-3">
                            {activeTab === '4' && <InvoiceTab auction={auction} />}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearLots_d: () => dispatch(clearLots()),
        resetAuctionManagementState_d: () => dispatch(resetAuctionManagementState())
    }
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(AuctionManagement);