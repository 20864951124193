import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Actions
const SETTINGS_ERROR = 'SETTINGS_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const SET_SETTINGS = 'SET_SETTINGS';
const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';

const initialState =  {
    error: false,
    reload: false,
    success: false,
    settings: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SETTINGS_ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_ERROR: return { ...state, error: false, errorText: false, success: false }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false }
        case GET_SETTINGS_SUCCESS: return { ...state, error: false, errorText: '', settings: action.payload }
        case UPDATE_SETTINGS_SUCCESS: return { ...state, success: true }
        default: return state;
    }
}

//Action creator
export const error = (errorText: string) => {
    return { type: SETTINGS_ERROR, payload: errorText }
}

export const clearError = () => {
    return { type: CLEAR_ERROR, payload: CLEAR_ERROR }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const getSettingsSuccess = (settings: any) => {
    return { type: GET_SETTINGS_SUCCESS, payload: settings }
}

export const updateSettingsSuccess = () => {
    return { type: UPDATE_SETTINGS_SUCCESS, payload: UPDATE_SETTINGS_SUCCESS }
}

//Thunks
export const handleSettingsFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveConfigVersion, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(getSettingsSuccess(res.response))
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleSettingsUpdate = (formFields: any) => {

    const vendor = {
        Id: formFields.id,
        Name: formFields.name,
        Description: formFields.description,
        Slogan: formFields.slogan,
        Address: {
            AddressLine1: formFields.address.addressLine1,
            AddressLine2: formFields.address.addressLine2,
            Country: formFields.address.country,
            State: formFields.address.state,
            City: formFields.address.city,
            Zip: formFields.address.zip,
            Longitude: formFields.address.longitude,
            Latitude: formFields.address.latitude
        },
        VendorStyling: formFields.vendorStyling
    }

    return function (dispatch: any) {
        return requestClient.post(apiPaths.vendor.update, vendor)
            .then(function (res) {
                if (res.success) {
                    dispatch(updateSettingsSuccess())
                } else {
                    dispatch(error(res.error))
                }
            })
    }

}