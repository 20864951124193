import { QueryClient } from "@tanstack/react-query";
import ns from '@/helpers/NotificationService';

export const queryClient = new QueryClient({
    defaultOptions:{
        mutations: {
           networkMode: "always",
           retry: 3,
           retryDelay: 3000,
        },
         queries: {
           networkMode: "always",
           retry: 3,
           retryDelay: 3000,
           refetchOnWindowFocus:false,
        }
    }
});
