import React, { memo, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem } from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import CurrencyLabel from '../../../components/CurrencyComponent/CurrencyLabel';
import HeaderText from '../../../components/HeaderText/HeaderText';
import { orderBy } from 'lodash';

interface Props {
    open: boolean;
    onClose: () => void;
    lot: any;
    images: any;
    settings: any;
}

const BulkUploaderPreviewLotsModal: React.FC<Props> = props => {
    const { open, onClose, lot, images, settings } = props;

    const [activeTab, setActiveTab] = React.useState('0');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const renderAmount = (title, value, prefix: string | undefined = undefined, allowFallback = false) => <>
        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
            <div style={{ width: 150, paddingRight: 20 }} >{title}</div>
            <div style={{ flex: 1 }}>
                {value ? <CurrencyLabel value={value} prefixString={prefix} hideCurrencySymbol={prefix ? true : undefined} /> : allowFallback ? <div>(Use auction default)</div> : <div>-</div>}
            </div>
        </div>
        <Divider style={{ marginBottom: 10 }} />
    </>

    const renderItem = (title, value, allowFallback = false) => <>
        <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
            <div style={{ width: 150, paddingRight: 20 }} >{title}</div>
            <div style={{ flex: 1 }}>
                {value ? <div>{value}</div> : allowFallback ? <div>(Use auction default)</div> : <div>-</div>}
            </div>
        </div>
        <Divider style={{ marginBottom: 10 }} />
    </>

    const orderedImages = useMemo(() => {
        const bannerImages = images?.filter(x => (x.order % 1) === 0);
        const otherImages = images?.filter(x => (x.order % 1) > 0);

        return {
            bannerImage: bannerImages?.[0],
            otherImages: orderBy(otherImages ?? [], "order")
        }
    }, [images])

    const renderImage = (props) => <img src={props.fileData ?? props.imageUrl} className="card-img-top rounded" alt="..." style={{ maxHeight: 100, maxWidth: 100 }} />

    return !open ? null :
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={open}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">{lot.name}</DialogTitle>
            <DialogContent>
                <List className="nav-tabs nav-tabs-primary d-flex">
                    <ListItem button disableRipple selected={activeTab === '0'} onClick={() => { toggle('0'); }}>Data</ListItem>
                    <ListItem button disableRipple selected={activeTab === '1'} onClick={() => { toggle('1'); }}>Images</ListItem>
                    <ListItem button disableRipple selected={activeTab === '2'} onClick={() => { toggle('2'); }}>
                        Validation Errors
                        {(lot.validationErrors?.length > 0 || lot.bannerImageCount !== 1 || lot.otherImageCount === 0 || lot.otherImageCount > 50) && (
                            <div className="badge badge-danger m-1 badge-circle">
                                Danger
                            </div>
                        )}
                    </ListItem>
                </List>
            </DialogContent>
            <DialogContent style={{ height: '60vh', overflowY: 'auto' }}>
                {activeTab === '0' && <div className={"tab-item-wrapper p-4 active"} key={0}>
                    {renderItem("Description", lot.description)}
                    {renderItem(settings.LotName + " Type", lot.lotTypeName)}
                    {renderItem("STC Status", lot.stcStatus === 1 ? "Yes" : lot.stcStatus === 0 ? "No" : undefined, true)}
                    {renderItem("Display Address on Client Portal", lot.locationVisible === true ? "Yes" : lot.locationVisible ? "No" : undefined)}
                    {renderItem("Location", lot.locationSearch)}
                    {renderItem("Latitude", lot.locationLatitude)}
                    {renderItem("Longitude", lot.locationLongitude)}
                    {renderAmount("Buyers Commission %", lot.commission, "%", true)}
                    {renderAmount("Opening Bid", lot.startingPrice)}
                    {renderAmount("Increment", lot.defaultMinimumIncrement, undefined, true)}
                    {renderAmount("Reserve Price", lot.reservePrice)}
                    {renderItem("Show when the Reserve is Met", lot.showReserve === true ? "Yes" : lot.showReserve ? "No" : undefined)}
                    {renderAmount("Guide Price", lot.guidePrice)}
                    {renderItem("SKU Number", lot.skuNumber)}
                    {renderItem(settings.LotName + " Video URL", lot.videoUrl)}
                    {renderItem("Late Bid Extension (seconds)", lot.defaultBidExtension, true)}
                    {renderItem("Late Bid Extension Window (seconds)", lot.defaultBidExtensionWindow, true)}
                </div>}

                {activeTab === '1' && <div className={"tab-item-wrapper p-4 active"} key={1}>
                    <div className="pb-4">
                        <HeaderText header='Banner Image' description={lot.bannerImageCount === 0 ? "No image uploaded" : undefined} />
                        {orderedImages?.bannerImage && renderImage(orderedImages.bannerImage)}
                    </div>
                    <div className="pb-4">
                        <HeaderText header={settings.LotName + ' Images'} description={lot.otherImageCount === 0 ? "No image uploaded" : undefined} />
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>{orderedImages?.otherImages && orderedImages.otherImages.map(x => renderImage(x))}</div>
                    </div>
                </div>}

                {activeTab === '2' && <div className={"tab-item-wrapper p-4 active"} key={2}>
                    {lot.validationErrors?.length > 0 && lot.validationErrors.map(e => <div style={{ paddingBottom: 10 }}>- {e}</div>)}
                    {lot.bannerImageCount !== 1 && <div style={{ paddingBottom: 10 }}>- Please upload a mimimum of 1 image for the banner image</div>}
                    {(lot.otherImageCount === 0 || lot.otherImageCount > 50) && <div style={{ paddingBottom: 10 }}>- Please upload a mimimum of 1 and a maximum of 50 images for the {settings.LotName} images</div>}
                    {!(lot.validationErrors?.length > 0 || lot.bannerImageCount !== 1 || lot.otherImageCount === 0 || lot.otherImageCount > 50) && <div style={{ paddingBottom: 10 }}>No validation errors</div>}
                </div>}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                    className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">Close</span>
                </Button>
            </DialogActions>
        </Dialog>
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})


const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BulkUploaderPreviewLotsModal);