import React from 'react';

import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { Button, Tooltip } from '@mui/material';

import { connect } from 'react-redux';

import {
    setSidebarToggle,
    setSidebarToggleMobile
} from '../../../store/reducers/ThemeOptions';

import Version from '../../../components/Version';

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

}))


const SidebarHeader = (props) => {
    const toggleSidebarMobile = () => {
        setSidebarToggleMobile(!sidebarToggleMobile);
    };
    const toggleSidebar = () => {
        setSidebarToggle(!sidebarToggle);
    };
    const {
        sidebarToggleMobile,
        setSidebarToggleMobile,

        sidebarToggle,
        setSidebarToggle,

        settings
    } = props;

    const { classes, cx } = useStyles();

    return (
        <>
            <div className="app-sidebar--header">
                <div className="app-sidebar-logo">
                    <div className="app-sidebar-logo--icon">
                        <img
                            src={settings.VendorLogoUrl}
                        />
                    </div>
                    <div className="app-sidebar-logo--text">
                        <span></span>

                        <b className="text-white">{settings.VendorName}</b>
                        <Version shortened />
                    </div>

                </div>
                <Tooltip title="Collapse sidebar" placement="right" arrow>
                    <Button
                        onClick={toggleSidebar}
                        className="btn btn-sm collapse-sidebar-btn">
                        <FaArrowCircleLeft />
                    </Button>
                </Tooltip>
                <Button
                    className={cx(
                        'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                        { 'is-active': sidebarToggleMobile }
                    )}
                    onClick={toggleSidebarMobile}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner" />
                    </span>
                </Button>
                <Tooltip title="Expand sidebar" placement="right" arrow>
                    <Button
                        onClick={toggleSidebar}
                        className="expand-sidebar-btn btn btn-sm">
                        <FaArrowCircleRight />
                    </Button>
                </Tooltip>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    settings: state.settings.settings
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
    setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
