import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import { FaSave, FaTimes } from "react-icons/fa";
import { Alert } from '@mui/lab';

export interface TextConfirmationOptions {
    title: string;
    description: string;
    okButtonText: string;
    cancelButtonText: string;
}

interface TextConfirmationDialogProps extends TextConfirmationOptions {
    open: boolean;
    onOk: (text: string, payload: any) => void;
    onCancel: () => void;
    payload: any
}

export const TextConfirmationDialog: React.FC<TextConfirmationDialogProps> = props => {
    const {
        title,
        description,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk,
        payload
    } = props

    const [text, setText] = React.useState<string>('')
    const [textError, setTextError] = React.useState<string>('')

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setText(e.target.value)
    }

    const validate = (password) => {

        if (password === '') {
            setTextError('Text field cannot be blank');
            return false;
        }

        setTextError('');
        return true;
    }

    const onSubmit = () => {
        if (validate(text)) {
            onOk(text, payload);
            setText('');
        }
    }

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                <TextField value={text}
                    margin="normal"
                    autoFocus
                    onChange={onChange}
                    name="text"
                    fullWidth
                    className="m-2"
                    required
                    autoComplete='new-password'
                    onKeyPress={onKeyPress}
                    multiline
                    rows="4"/>
                {textError && (<Alert severity='error'>{textError}</Alert>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">{cancelButtonText}</span>
                </Button>

                <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white mr-1 text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                    <span className="btn-wrapper--label">{okButtonText}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TextConfirmationDialog