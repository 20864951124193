import React from 'react';
import { connect } from 'react-redux';
import ns from '@/helpers/NotificationService';
import { handleMarkAsUnsold } from '../../pages/AuctionManagement/store/AuctionManagement';
import Button from '../Button';
import LoadingButton from '../LoadingButton';

interface MarkAsUnsoldProps {
    lot: any,
    settings: any,
    handleMarkAsUnsold_d: (lotId: string, CompletedCallback: (response: any) => void) => void,
    refetchLotsData: (options: any) => Promise<any>
}

class MarkAsUnsold extends React.PureComponent<MarkAsUnsoldProps, { isLoading: boolean }> {
    private lot;
    private settings;
    private handleMarkAsUnsold_d;
    private refetchLotsData;

    constructor(props) {
        super(props);

        this.lot = props.lot;
        this.settings = props.settings;
        this.handleMarkAsUnsold_d = props.handleMarkAsUnsold_d;
        this.refetchLotsData = props.refetchLotsData;

        this.state = {
            isLoading: false
        };
    }

    componentWillUpdate(nextProps) {
        this.lot = nextProps.lot;
        this.settings = nextProps.settings;
        this.handleMarkAsUnsold_d = nextProps.handleMarkAsUnsold_d;
        this.refetchLotsData = nextProps.refetchLotsData;
    }


    private onClick = () => {
        ns.showDialog({
            title: 'Confirm Mark As Unsold',
            content: <div>
                <p className="mt-3">Are you sure you want to mark this {this?.settings.LotName} as unsold?</p>
                <p>
                    {this?.settings.LotName} Number: {this?.lot.number}<br />
                    {this?.settings.LotName} SKU Number: {this?.lot.skuNumber}<br />
                    {this?.settings.LotName} Unique Code: {this?.lot.uniqueCode}
                </p>
            </div>,
            onSubmit: () => {
                this.setState({ isLoading: true})
                this?.handleMarkAsUnsold_d(this.lot?.id, async (res) => {
                    if (res.success) {
                        await this.refetchLotsData();
                        ns.success(`Successfully marked the ${this?.settings?.LotName} as unsold`);
                    }
                    else {
                        if (res.error) ns.error(res.error)
                        else ns.error(`Failed to mark the ${this?.settings?.LotName} as unsold`);
                        this.setState({ isLoading: false })
                    }
                })
            },
            onCancel: () => { },
            locales: {
                submitText: 'Mark As Unsold'
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <LoadingButton
                    loading={this?.state?.isLoading}
                    disabled={false}
                    type="success"
                    onClick={this?.onClick}
                    label={`Mark as Unsold`}
                    fullWidth={true}
                    width={200}
                />
            </React.Fragment>)
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleMarkAsUnsold_d: (lotId: string, onCompletedCallback: (response: any) => void) => dispatch(handleMarkAsUnsold(lotId, onCompletedCallback)),
    }
}

export default connect(null, mapDispatchToProps)(MarkAsUnsold);