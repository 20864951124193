import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import SimpleTable, { ColumnProps } from "../SimpleTable/SimpleTable";
import { Tooltip, Typography } from "@mui/material";
import Button from '@/components/Button';
import { FaDownload, FaEdit, FaFileCsv, FaFilePdf } from "react-icons/fa";
import { HiOutlineMail, HiOutlineNewspaper, HiOutlineTruck, HiX } from "react-icons/hi";
import { handleBulkDownloadFinanceDocument, handleBulkProcessFinanceDocument, handleBulkSendFinanceDocument, handleCancelFinanceDocument, handleDownloadFinanceDocument, handleGenerateInvoice, handleGenerateReleaseNote, handleProcessFinanceDocument, handleSendFinanceDocument, retrieveAllFinanceDocuments } from "./store/Invoicing";
import { FinanceDocument, FinanceDocumentStatus, FinanceDocumentType } from "@/dto/FinanceDocument";
import CurrencyLabel from "../CurrencyComponent/CurrencyLabel";
import { determineFinanceDocumentStatus } from "./FinanceDocumentStatusBadge";
import ns from '@/helpers/NotificationService';
import { useAppDispatch } from "../../store/ConfigureStore";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Pagination } from "prembid-shared-library-npm/types";
import { EmailInvoiceDialog } from "../Modal/EmailInvoiceDialog";
import { handleContactsFetchWithPagination } from "../../pages/Contacts/store/Contacts";
import FinanceDocumentConsolidationModal from "./FinanceDocumentConsolidationModal";
import { limitConsolidatedLotNumbers } from "@/helpers/Utils";
import { FileExportType } from "../../helpers/FileExportTypeEnum";
import { handleRetrieveFinanceDocumentsForAuctionReport } from "../../pages/AuctionManagement/store/AuctionManagement";

interface FinanceDocumentTableProps {
    auction: any;
    type?: FinanceDocumentType;
    onView: (financeDocumentId: string, financeDocumentType: FinanceDocumentType) => void;
    lastReloadDate: Date;
}

const disabledStatus = {
    [determineFinanceDocumentStatus(FinanceDocumentStatus.Consolidated, FinanceDocumentType.ProFormaInvoice)]: true,
    [determineFinanceDocumentStatus(FinanceDocumentStatus.Paid, FinanceDocumentType.ProFormaInvoice)]: true,
    [determineFinanceDocumentStatus(FinanceDocumentStatus.Cancelled, FinanceDocumentType.ProFormaInvoice)]: true,
    [determineFinanceDocumentStatus(FinanceDocumentStatus.MarkedForRelease, FinanceDocumentType.Invoice)]: true,
}

const excludedStatusesFromTotals = [FinanceDocumentStatus.Consolidated, FinanceDocumentStatus.Cancelled];

const generateFinDocNumbers = (finDoc: FinanceDocument, settings: any) => {
    const proformaReference = finDoc.entityReferences?.find(er => er.name.startsWith('ProformaInvoice:'));
    const invoiceReference = finDoc.entityReferences?.find(er => er.name.startsWith('Invoice:'));
    const releaseNoteReference = finDoc.entityReferences?.find(er => er.name.startsWith('ReleaseNote:'));

    const consolidatedProformaInvoiceNumber = finDoc.extraData?.["consolidatedProformaInvoiceNumber"];

    switch (finDoc.type) {
        case FinanceDocumentType.ConsolidatedProfomaInvoice: return <>
            <span style={{ color: settings.Styles.Primary }}>{finDoc.number}</span><br />
            {invoiceReference && <><span>{invoiceReference.name.replace('Invoice:', '')}</span><br /></>}
            {releaseNoteReference && <><span>{releaseNoteReference.name.replace('ReleaseNote:', '')}</span><br /></>}
        </>;
        case FinanceDocumentType.ProFormaInvoice: return <>
            <span style={{ color: settings.Styles.Primary }}>{finDoc.number}</span><br />
            {consolidatedProformaInvoiceNumber && <><span>{consolidatedProformaInvoiceNumber}</span><br /></>}
            {invoiceReference && <><span>{invoiceReference.name.replace('Invoice:', '')}</span><br /></>}
            {releaseNoteReference && <><span>{releaseNoteReference.name.replace('ReleaseNote:', '')}</span><br /></>}
        </>;
        case FinanceDocumentType.Invoice: return <>
            {proformaReference && <><span>{proformaReference.name.replace('ProformaInvoice:', '')}</span><br /></>}
            <span style={{ color: settings.Styles.Primary }}>{finDoc.number}</span><br />
            {releaseNoteReference && <><span>{releaseNoteReference.name.replace('ReleaseNote:', '')}</span><br /></>}
        </>;
        case FinanceDocumentType.ReleaseNote: return <>
            {proformaReference && <><span>{proformaReference.name.replace('ProformaInvoice:', '')}</span><br /></>}
            {invoiceReference && <><span>{invoiceReference.name.replace('Invoice:', '')}</span><br /></>}
            <span style={{ color: settings.Styles.Primary }}>{finDoc.number}</span>
        </>;
        default: return <>{finDoc.number}</>
    }
}

const FinanceDocumentTable: FC<FinanceDocumentTableProps> = props => {
    const dispatch = useAppDispatch();
    const { auction, type, onView, lastReloadDate } = props;

    const { settings } = useSelector((state: any) => state.settings);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [loadingIds, updateLoadingId] = useReducer((state: string[], action: { type: 'add' | 'remove' | 'clear'; id: string; }) => {
        switch (action.type) {
            case 'add': return [...state, action.id];
            case 'remove': return state.filter(id => id !== action.id);
            case 'clear': return [];
            default: return state;
        }
    }, []);

    const [data, setData] = useState<FinanceDocument[]>([]);
    const [pagination, setPagination] = useState<Pagination>();

    const [searchString, setSearchString] = useState<string>("");
    const [contacts, setContacts] = useState([]);

    const [errors, setErrors] = useState<{ [id: string]: string }>({});

    const grandTotalExclVat = useMemo(() => {
        const total = (data ?? [0]).reduce((acc, row) => {
            if (excludedStatusesFromTotals.includes(row.status)) return acc;
            else return acc + row.totalExclVat
        }, 0);
        return total;
    }, [data]);

    const grandTotalInclVat = useMemo(() => {
        const total = (data ?? [0]).reduce((acc, row) => {
            if (excludedStatusesFromTotals.includes(row.status)) return acc;
            else return acc + row.totalInclVat
        }, 0);
        return total;
    }, [data]);

    const initialBulkEmailObject = {
        id: "",
        toEmail: "",
        ccEmail: [""],
    }
    const initialEmailObject: {
        to: any,
        cc: any,
        isValid: boolean,
        errorMessage: string,
        ccTyped: string
    } = {
        to: "",
        cc: [],
        isValid: false,
        errorMessage: "",
        ccTyped: ""
    }

    const [emailModalState, setEmailModalState] = useState({ open: false, isBulk: false, seedValues: {} });

    const [selectedRowsForModal, setSelectedRowsForModal] = useState<any>([]);

    const statusFilters: string[] = useMemo(() => {
        switch (type) {
            case FinanceDocumentType.ProFormaInvoice: return [
                determineFinanceDocumentStatus(FinanceDocumentStatus.NotSent, FinanceDocumentType.ProFormaInvoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Sent, FinanceDocumentType.ProFormaInvoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Paid, FinanceDocumentType.ProFormaInvoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Consolidated, FinanceDocumentType.ProFormaInvoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Cancelled, FinanceDocumentType.ProFormaInvoice),
            ];
            case FinanceDocumentType.Invoice: return [
                determineFinanceDocumentStatus(FinanceDocumentStatus.NotSent, FinanceDocumentType.Invoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Sent, FinanceDocumentType.Invoice),
                determineFinanceDocumentStatus(FinanceDocumentStatus.MarkedForRelease, FinanceDocumentType.Invoice)
            ];
            case FinanceDocumentType.ReleaseNote: return [
                determineFinanceDocumentStatus(FinanceDocumentStatus.NotSent, FinanceDocumentType.ReleaseNote),
                determineFinanceDocumentStatus(FinanceDocumentStatus.Sent, FinanceDocumentType.ReleaseNote),
            ];
            default: return [];
        }
    }, [type]);

    const columns = useMemo<ColumnProps<FinanceDocument>[]>(() => ([
        { id: "type", header: 'Type', accessorKey: 'type' },
        {
            id: "number", header: 'Doc.No.', accessorKey: 'number',
            accessorFn: (row) => generateFinDocNumbers(row, settings),
            sortingFn: (a, b) => {
                return a.getValue<string>('finDocNumber')?.localeCompare(b.getValue<string>('finDocNumber')) ?? 0
            },
            enableSorting: true
        },
        { id: "finDocNumber", header: 'Doc.No.', accessorKey: 'number' },
        { id: "lotNumber", header: 'Lot Number', accessorFn: (row) => row.extraData?.lotNumber ?? limitConsolidatedLotNumbers(row.extraData?.consolidatedLotNumbers) },
        { id: "customerName", header: 'Customer Name', accessorKey: 'customerName' },
        { id: "customerType", header: 'Entity Type', accessorKey: 'customerType' },
        { id: "reference", header: 'Cust.Ref.', accessorKey: 'reference' },
        { id: "date", header: 'Date', accessorKey: 'date' },
        {
            id: "total",
            header: 'Total',
            accessorKey: 'totalInclVat',
            Cell: ({ cell }) => (<CurrencyLabel value={cell.getValue<number>()} />),
        },
        {
            id: "status",
            header: 'Status',
            accessorKey: 'status',
            filterSelectOptions: statusFilters,
            filterVariant: 'multi-select',
            enableColumnFilter: true,
            accessorFn: (row) => determineFinanceDocumentStatus(row.status, row.type),
        },
    ]), [statusFilters, grandTotalExclVat, grandTotalInclVat]);

    useEffect(() => {
        reload();
    }, [type, auction, dispatch, lastReloadDate, searchString])


    const handleEmailResponse = (res: any) => {
        if (res.success) {
            switch (type) {
                case FinanceDocumentType.ProFormaInvoice: ns.success("Successfully sent the Pro Forma Invoice"); break;
                case FinanceDocumentType.Invoice: ns.success("Successfully sent the Tax Invoice"); break;
                case FinanceDocumentType.ReleaseNote: ns.success("Successfully sent the Release Note"); break;
            }
            reload();
        }
        else {
            switch (type) {
                case FinanceDocumentType.ProFormaInvoice: ns.error("Failed to send the Pro Forma Invoice"); break;
                case FinanceDocumentType.Invoice: ns.error("Failed to send  the Tax Invoice"); break;
                case FinanceDocumentType.ReleaseNote: ns.error("Failed to send the Release Note"); break;
                default: ns.error("Failed to email the finance document"); break;
            }
        }
    }

    const reload = () => {
        setLoading(true);
        setPagination(undefined);
        setData([]);
        dispatch(retrieveAllFinanceDocuments({
            auctionId: auction.id,
            type,
            searchString
        }, (res) => {

            setLoading(false);
            if (res?.success) {
                setData(res.response.list);
                setPagination(res.response.pagination);
            }
        }));

        dispatch(handleContactsFetchWithPagination(null, { pageSize: -1 }, (res) => {
            if (res?.response) {
                let tempObject
                const contacts = res.response.list.map((item) => {
                    tempObject = {
                        name: "",
                        email: ""
                    };
                    tempObject.name = item.firstName + " " + item.lastName;
                    tempObject.email = item.email;
                    return tempObject;
                });

                setContacts(contacts);
            }
        }));
    }


    const handleBulkDownloadClicked = (selectedRows: any[]) => {
        if (selectedRows.length > 0) {
            setLoading(true)
            dispatch(handleBulkDownloadFinanceDocument(selectedRows.map(x => x.id), (response) => {
                if (response.ok) {
                    const header = response.headers.get('Content-Disposition');
                    const parts = header!.split(';');
                    let filename = parts[1].split('=')[1];

                    response.blob().then(blob => {
                        const fileURL = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute(
                            'download',
                            filename
                        );
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode?.removeChild(link);
                    });
                }
                else {
                    ns.error("Failed to download");
                }
                reload();
            }));
        }
    }

    const handleBulkProgressClicked = (selectedRows: any[]) => {
        if (selectedRows.length > 0) {
            let confirmationMessage: string;

            switch (type) {
                case FinanceDocumentType.ProFormaInvoice: confirmationMessage = "Are you sure you want mark the selected pro-forma invoices as paid and generate invoices?"; break;
                case FinanceDocumentType.Invoice: confirmationMessage = "Are you sure you want to mark the selected invoices for release and generate the release notes?"; break;
                default: confirmationMessage = "Are you sure you want bulk process the selected documents?";
            }

            ns.showDialog({
                title: "Confirmation Required",
                message: confirmationMessage,

                onSubmit: () => {
                    setLoading(true)
                    dispatch(handleBulkProcessFinanceDocument(selectedRows.map(x => x.id), (res) => {
                        if (res.success) {
                            const errors = res.response ?? {};
                            if (Object.keys(errors).length === 0) {
                                switch (type) {
                                    case FinanceDocumentType.ProFormaInvoice: ns.success("Successfully generated the Tax Invoices"); break;
                                    case FinanceDocumentType.Invoice: ns.success("Successfully marked the Tax Invoices for release"); break;
                                }
                            }
                            else {
                                switch (type) {
                                    case FinanceDocumentType.ProFormaInvoice: ns.error("Failed to generate some of the Tax Invoices"); break;
                                    case FinanceDocumentType.Invoice: ns.error("Failed to mark some of the Tax Invoices for release"); break;
                                }
                            }
                            setErrors(errors)
                        }
                        reload();
                    }));
                },
                onCancel: () => {
                },
                locales: {
                    submitText: "Yes",
                    cancelText: "No"
                }
            });
        }
    }

    const handleViewClicked = (id: string, type: FinanceDocumentType) => {
        onView(id, type);
    }

    const handleDownloadClicked = (id: string, number: string) => {
        updateLoadingId({ type: 'add', id });
        dispatch(handleDownloadFinanceDocument(id, (response) => {
            if (response.ok) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute(
                        'download',
                        number + '.pdf',
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                });
            }
            else {
                ns.error("Failed to download the finance document");
            }
            updateLoadingId({ type: 'remove', id });
        }));
    }

    const handleSubmitEmail = (isBulk: boolean, payload?: any) => {
        setLoading(true);

        if (isBulk) {
            dispatch(handleBulkSendFinanceDocument(payload, (res) => {
                reload();
                setLoading(false)
                handleEmailResponse(res);
                setEmailModalState(currentValues => ({ ...currentValues, open: false }));
            }));
        }
        else {
            updateLoadingId({ type: 'add', id: payload.id });
            dispatch(handleSendFinanceDocument(payload, (res) => {
                updateLoadingId({ type: 'remove', id: payload.id });
                handleEmailResponse(res);
                setEmailModalState(currentValues => ({ ...currentValues, open: false }));
                setLoading(false)
            }));
        }
    }


    const handleShowEmailModal = (isBulk: boolean, id: string | undefined = undefined) => {
        const seedValues: any = {};

        if (isBulk) {
            selectedRowsForModal.forEach((item) => {
                seedValues[item.original.id + "_to"] = item.original.customerEmail;
            });
        }
        else {
            seedValues.selectedRowId = id;
            seedValues.toEmail = data.find(item => item.id === id)?.customerEmail;
        }

        setEmailModalState(currentValues => ({ ...currentValues, open: true, isBulk, seedValues }));
    }

    const handleProcess = (id: string) => {
        dispatch(handleProcessFinanceDocument(id, (res) => {
            if (res.success) {
                switch (type) {
                    case FinanceDocumentType.ProFormaInvoice: ns.success("Successfully generated the Tax Invoice"); break;
                    case FinanceDocumentType.Invoice: ns.success("Successfully marked for release"); break;
                }
                reload();
            }
            else {
                if (res.error) ns.error(res.error);
                else {
                    switch (type) {
                        case FinanceDocumentType.ProFormaInvoice: ns.error("Failed to generate the Tax Invoice"); break;
                        case FinanceDocumentType.Invoice: ns.error("Failed to mark for release"); break;
                    }
                }
            }
            updateLoadingId({ type: 'remove', id });
        }));
    }

    const handleProgressClicked = (id: string, type: FinanceDocumentType, status: FinanceDocumentStatus, reference: string) => {
        if ((type === FinanceDocumentType.ProFormaInvoice || type === FinanceDocumentType.ConsolidatedProfomaInvoice) && status !== FinanceDocumentStatus.Paid) {
            ns.showDialog({
                title: "Confirmation Required",
                message: `Are you sure you want to mark the pro-forma invoice ${reference} as paid and generate an invoice?`,

                onSubmit: () => {
                    updateLoadingId({ type: 'add', id });
                    dispatch(handleGenerateInvoice(id, (res) => {
                        if (res.success) handleProcess(id);
                        else {
                            if (res.error) ns.error(res.error);
                            else ns.error("Failed to generate the Tax Invoice");
                            updateLoadingId({ type: 'remove', id });
                        }
                    }));
                },
                onCancel: () => {
                },
                locales: {
                    submitText: "Yes",
                    cancelText: "No"
                }
            });
        }
        else if (type === FinanceDocumentType.Invoice && status !== FinanceDocumentStatus.MarkedForRelease) {
            ns.showDialog({
                title: "Confirmation Required",
                message: `Are you sure you want to mark the invoice ${reference} for release and generate an release note?`,

                onSubmit: () => {
                    updateLoadingId({ type: 'add', id });
                    dispatch(handleGenerateReleaseNote(id, (res) => {
                        if (res.success) handleProcess(id);
                        else {
                            if (res.error) ns.error(res.error);
                            else ns.error("Failed to generate the Release Note");
                            updateLoadingId({ type: 'remove', id });
                        }
                    }));
                },
                onCancel: () => {
                },
                locales: {
                    submitText: "Yes",
                    cancelText: "No"
                }
            });
        }
    }

    const handleCancelClicked = (id: string, reference: string) => {
        ns.showDialog({
            title: "Confirmation Required",
            message: `Are you sure you want to cancel the Pro-Forma Invoice ${reference}?`,

            onSubmit: () => {
                updateLoadingId({ type: 'add', id });
                dispatch(handleCancelFinanceDocument(id, (res) => {
                    if (res.success) {
                        ns.success("Successfully cancelled the Pro-Forma Invoice");
                    }
                    else {
                        ns.error("Failed to cancel the Pro-Forma Invoice");
                    }
                    updateLoadingId({ type: 'remove', id });
                    reload();
                }));
            },
            onCancel: () => {
            },
            locales: {
                submitText: "Yes",
                cancelText: "No"
            }
        });
    }


    const handleOnSearch = (searchQuery: string) => {
        setSearchString(searchQuery);
    }

    const generateSelectedText = (type: FinanceDocumentType | undefined) => {
        switch (type) {
            case FinanceDocumentType.ProFormaInvoice: return 'Pro-Formas';
            case FinanceDocumentType.Invoice: return 'Tax Invoices';
            case FinanceDocumentType.ReleaseNote: return 'Release Notes';
            default: return 'Finance Documents';
        }
    }

    const handleSelectionModelChange = (selectionModel) => {
        setSelectedRowsForModal(selectionModel);
    };

    const [consolidateModalOpen, setConsolidateModalOpen] = useState(false);
    const handleOpenConsolidateModal = () => {
        setConsolidateModalOpen(true);
    }

    const generateCancelButtonToolTip = (row: any) => {
        if (row.original?.status === FinanceDocumentStatus.Paid) return "Pro-Forma Invoice has already been paid";
        if (row.original?.status === FinanceDocumentStatus.Cancelled) return "Pro-Forma Invoice has already been cancelled";
        if (row.original?.type !== FinanceDocumentType.ConsolidatedProfomaInvoice) return "Only consolidated Pro-Forma Invoices may be cancelled";
        return "Cancel Pro-Forma Invoice";
    }

    const [downloading, setDownloading] = useState(false);

    const handleDownload = (exportType: FileExportType) => {
        setDownloading(true)

        dispatch(handleRetrieveFinanceDocumentsForAuctionReport(auction.id, type as FinanceDocumentType, exportType, (res) => {
            setDownloading(false)
        }))
    }

    return (
        <>
            <SimpleTable
                enableRowSelection
                columnHeaders={columns}
                hideColumns={['type', 'finDocNumber']}
                tableData={data}
                onEditClick={handleSelectionModelChange}
                loadingIds={loadingIds}
                errors={errors}
                enableRowSelectionOnErrorsChange
                extraButtons={[
                    <Tooltip title="Download as PDF" placement="left">
                        <LoadingButton loading={loading || downloading} disabled={loading || downloading} onClick={() => handleDownload(FileExportType.PDF)} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                            <FaFilePdf />
                        </LoadingButton>
                    </Tooltip>,
                    <Tooltip title="Download as CSV" placement="left">
                        <LoadingButton loading={loading || downloading} disabled={loading || downloading} onClick={() => handleDownload(FileExportType.CSV)} variant="text" className={"btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill text-capitalize"}>
                            <FaFileCsv />
                        </LoadingButton>
                    </Tooltip>
                ]}
                renderTopTableActions={(table, isLoading) => {
                    const selectedRows = table.getSelectedRowModel()?.flatRows ?? [];
                    setSelectedRowsForModal(selectedRows);
                    const totalRows = table.getTotalSize();
                    const isRowsSelected = !!(selectedRows?.length > 0);
                    return <>
                        <Button
                            disabled={!isRowsSelected || isLoading}
                            type="primary"
                            onClick={() => handleShowEmailModal(true)}
                            label="Email"
                            fullWidth={false}
                        />
                        <Button
                            disabled={!isRowsSelected || isLoading}
                            type="primary"
                            onClick={() => handleBulkDownloadClicked(selectedRows)}
                            label="Download"
                            fullWidth={false}
                        />
                        {type === FinanceDocumentType.ProFormaInvoice && <Button
                            disabled={!isRowsSelected || isLoading}
                            type="primary"
                            onClick={() => handleBulkProgressClicked(selectedRows)}
                            label='Mark as Paid and Generate Invoice'
                            fullWidth={false}
                        />}
                        {type === FinanceDocumentType.Invoice && <Button
                            disabled={!isRowsSelected || isLoading}
                            type="primary"
                            onClick={() => handleBulkProgressClicked(selectedRows)}
                            label='Mark For Release'
                            fullWidth={false}
                        />}
                        {selectedRows?.length > 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{selectedRows?.length} of {pagination?.total} {generateSelectedText(type)}</div>}
                        {type === FinanceDocumentType.ProFormaInvoice && <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}><Button
                            type="primary"
                            onClick={handleOpenConsolidateModal}
                            label='Consolidate Pro-Formas'
                            fullWidth={false}
                        /></div>}
                    </>
                }}

                renderRowActions={(row, isLoading, loadingIds) => {
                    const id = row.id;
                    const loading = loadingIds.includes(id);

                    return <>
                        <Tooltip arrow title="View/Edit" placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleViewClicked(row.id, row.getValue<FinanceDocumentType>('type'))}
                                    size="small"
                                    disabled={isLoading}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <FaEdit />
                                </LoadingButton>
                            </span>
                        </Tooltip>
                        <Tooltip arrow title="Download" placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleDownloadClicked(row.id, row.getValue<string>('finDocNumber'))}
                                    size="small"
                                    disabled={isLoading}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <FaDownload />
                                </LoadingButton>
                            </span>
                        </Tooltip>
                        <Tooltip arrow title="Email to Customer" placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleShowEmailModal(false, row.id)}
                                    size="small"
                                    disabled={isLoading}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <HiOutlineMail />
                                </LoadingButton>
                            </span>
                        </Tooltip>

                        {(type === FinanceDocumentType.ProFormaInvoice || type === FinanceDocumentType.ConsolidatedProfomaInvoice) && <Tooltip arrow title="Mark as Paid and Generate Invoice" placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleProgressClicked(row.id, row.getValue<FinanceDocumentType>('type'), row.getValue<FinanceDocumentStatus>('status'), row.original?.number)}
                                    size="small"
                                    disabled={isLoading || disabledStatus[row.getValue<string>('status')]}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <HiOutlineNewspaper />
                                </LoadingButton>
                            </span>
                        </Tooltip>}

                        {type === FinanceDocumentType.Invoice && <Tooltip arrow title="Mark For Release" placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleProgressClicked(row.id, row.getValue<FinanceDocumentType>('type'), row.getValue<FinanceDocumentStatus>('status'), row.original?.number)}
                                    size="small"
                                    disabled={isLoading || disabledStatus[row.getValue<string>('status')]}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <HiOutlineTruck />
                                </LoadingButton>
                            </span>
                        </Tooltip>}
                        {type === FinanceDocumentType.ProFormaInvoice && <Tooltip arrow title={generateCancelButtonToolTip(row)} placement="bottom">
                            <span>
                                <LoadingButton
                                    onClick={() => handleCancelClicked(row.id, row.original?.number)}
                                    size="small"
                                    disabled={isLoading || row.original?.type !== FinanceDocumentType.ConsolidatedProfomaInvoice || row.original?.status === FinanceDocumentStatus.Cancelled || row.original?.status === FinanceDocumentStatus.Paid}
                                    loading={loading}
                                    className="btn-primary rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize">
                                    <HiX />
                                </LoadingButton>
                            </span>
                        </Tooltip>}
                    </>
                }}

                renderBottomTableActions={(table, isLoading) => {
                    return <>
                        <span style={{ fontWeight: 'bold' }}>TOTAL EXCL VAT</span><CurrencyLabel value={grandTotalExclVat} style={{ color: settings.Styles.Primary }} />
                        <span className='ml-5' style={{ fontWeight: 'bold' }}>TOTAL INCL VAT</span><CurrencyLabel value={grandTotalInclVat} style={{ color: settings.Styles.Primary }} />
                    </>
                }}

                sorting={[
                    { id: 'number', desc: false }
                ]}

                pagination={true}
                onSearch={handleOnSearch}
                isSaving={loading}
                searchString={searchString}
                onRefresh={() => {
                    setErrors({})
                    reload()
                }}
            />

            {emailModalState.open && contacts && <EmailInvoiceDialog
                data={selectedRowsForModal}
                contacts={contacts}

                seedValues={emailModalState?.seedValues}
                onValuesChanged={(val) => setEmailModalState(currentValues => ({ ...currentValues, seedValues: val }))}

                onSubmit={handleSubmitEmail}

                onClose={() => setEmailModalState(currentState => ({ ...currentState, open: false }))}
                isBulk={emailModalState.isBulk}

                loading={loading}
            />}

            {consolidateModalOpen && <FinanceDocumentConsolidationModal
                onClose={(shouldReload?: boolean) => {
                    setConsolidateModalOpen(false)
                    if (shouldReload) reload();
                }}
                auctionId={auction.id}
            />}
        </>
    );
}

export default FinanceDocumentTable;