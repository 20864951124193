import { useState, useCallback, useMemo, useEffect } from "react";
import { Countdown } from '@/components/library/Countdown'
import { calculateAuctionStatus, getEndTime } from "./Utils";
import AuctionStatusBadge from "./AuctionStatusBadge";
import { getNewDateWithOffset } from "prembid-shared-library-npm/helpers";
import { useFinalizing } from "prembid-shared-library-npm/hooks";
function AuctionStatusBadgeTimer(props) {
    const [lastRefreshed, setLastRefreshed] = useState(new Date().getTime());
    const status = useMemo(() => lastRefreshed ? calculateAuctionStatus(props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset) : undefined,
        [props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset, lastRefreshed]);

    const [validating, setValidating] = useState(false);
    const [previousStatus, setPreviousStatus] = useState<string>();

    const CountdownRenderer = useCallback(() => {
        return (status && lastRefreshed) ? <AuctionStatusBadge
            status={status}
            validating={validating}
        /> : undefined
    }, [status, lastRefreshed, validating])

    useEffect(() => {
        setValidating(false);
    }, [props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset]);

    useEffect(() => {
        if (validating) setTimeout(() => {
            setValidating(false)
            setLastRefreshed(new Date().getTime())
        }, 4000);
    }, [validating]);

    return <Countdown
        date={getEndTime(props.endDateTimeAt, props.extendedBy)}
        renderer={CountdownRenderer}
        now={() => getNewDateWithOffset(props.dateTimeOffset).getTime()}
        onComplete={() => {
            if (status !== 'Completed') setTimeout(() => {
                const newStatus = calculateAuctionStatus(props.isActive, props.startDateTimeAt, props.endDateTimeAt, props.extendedBy, props.dateTimeOffset);
                if (newStatus === 'Completed') setValidating(true);
                else { setLastRefreshed(new Date().getTime()) }
            }, 500)
        }}
    />
}

export default AuctionStatusBadgeTimer;